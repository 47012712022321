import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerStreet: "",
  customerApt: "",
  customerCity: "",
  customerState: "",
  customerZipcode: "",
  customerPosition: {
    lat: null,
    lng: null,
  },
};

const serviceMethodSlice = createSlice({
  name: "serviceMethod",
  initialState,
  reducers: {
    setCustomerStreet: (state, { payload }) => {
      state.customerStreet = payload;
    },
    setCustomerApt: (state, { payload }) => {
      state.customerApt = payload;
    },
    setCustomerCity: (state, { payload }) => {
      state.customerCity = payload;
    },
    setCustomerState: (state, { payload }) => {
      state.customerState = payload;
    },
    setCustomerZipcode: (state, { payload }) => {
      state.customerZipcode = payload;
    },
    setCustomerPosition: (state, { payload }) => {
      state.customerPosition = payload;
    },
    resetServiceMethodState: () => initialState,
  },
});

export const {
  setCustomerStreet,
  setCustomerApt,
  setCustomerCity,
  setCustomerState,
  setCustomerZipcode,
  setCustomerPosition,
  setCurrentStore,
  resetServiceMethodState,
} = serviceMethodSlice.actions;
export default serviceMethodSlice.reducer;

// Selectors
export const selectCustomerStreet = (state) =>
  state.serviceMethod.customerStreet;
export const selectCustomerApt = (state) => state.serviceMethod.customerApt;
export const selectCustomerCity = (state) => state.serviceMethod.customerCity;
export const selectCustomerState = (state) => state.serviceMethod.customerState;
export const selectCustomerZipcode = (state) =>
  state.serviceMethod.customerZipcode;
export const selectCustomerPosition = (state) =>
  state.serviceMethod.customerPosition;
