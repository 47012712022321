import { translate } from "react-i18next";
import { connect } from "react-redux";

import { everythingElseRoute, pizzasRoute } from "routes";

import { getCurrentOrder, getCurrentOrderId } from "ducks/order/selectors";
import { getOrderCoupons } from "ducks/orderCoupon/selectors";
import { resetActiveOrderProduct } from "ducks/orderProduct";
import { getPayloadCouponId } from "selectors/location";

import Fulfiller from "components/Fulfiller";

const mapStateToProps = (state) => ({
  currentOrder: getCurrentOrder(state),
  currentOrderId: getCurrentOrderId(state),
  orderCoupons: getOrderCoupons(state),
  couponId: getPayloadCouponId(state),
});

const mapDispatchToProps = (dispatch) => ({
  goToEverythingElse: (category) => dispatch(everythingElseRoute(category)),
  goToPizzas: () => dispatch(pizzasRoute()),
  resetActiveOrderProduct: () => dispatch(resetActiveOrderProduct()),
});

const mergeProps = (
  { currentOrder, currentOrderId, orderCoupons, couponId, ...state },
  { ...dispatch },
  props
) => {
  const orderCoupon =
    Object.values(orderCoupons).find(
      ({ orderId, orderCouponId }) =>
        orderCouponId === couponId && currentOrderId === orderId
    ) || {};

  return Object.assign({ orderCoupon }, state, dispatch, props);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["fulfiller", "shared"])(Fulfiller));
