import { AREA, defaultArea } from "constants/order";
import { WEIGHT } from "constants/topping";

const { className: wholeClass } = AREA[defaultArea];

const getIsWhole = (areas) => {
  let isWhole = false;

  areas.some((area) => {
    const { count } = AREA[area] || AREA[defaultArea];
    isWhole = count === areas.length;
    return isWhole;
  });

  return isWhole;
};

const getAreaClasses = (areas) => {
  const classNames = [];
  const isWhole = getIsWhole(areas);

  if (isWhole) classNames.push(wholeClass);
  else
    areas.forEach((area) => {
      const { className } = AREA[area] || AREA[defaultArea];
      classNames.push(className);
    });

  return classNames;
};

const defaultClassNames = "btn";

export const getProductClasses = (productId, products = {}) => {
  const classNames = [defaultClassNames];
  const areas = (
    Object.entries(products).filter(
      ([, currentProductId]) => currentProductId === productId
    ) || []
  ).map(([area]) => area);

  getAreaClasses(areas).forEach((className) => classNames.push(className));

  return classNames.join(" ");
};

export const getDimensionClasses = (dimensionId, activeDimensionId) => {
  const classNames = [defaultClassNames];
  if (dimensionId === activeDimensionId) classNames.push(wholeClass);

  return classNames.join(" ");
};

export const getOptionClasses = (option = {}) => {
  const classNames = [defaultClassNames];

  if (typeof option === "object") {
    const areas = Object.entries(option)
      .filter(([, weight]) => weight !== WEIGHT[0].value)
      .map(([area]) => area);
    getAreaClasses(areas).forEach((className) => classNames.push(className));
  } else if (option) {
    classNames.push(wholeClass);
  }

  return classNames.join(" ");
};

export default getOptionClasses;
