import { css } from "@emotion/react";

export const specialtyPizzaAlertStyles = css`
  font-family: PizzaPress, "Arial Narrow", Arial, Helvetica, sans-serif;
  color: var(--dpzBlue);
  display: flex;
  justify-content: center;
  font-size: 2rem;
  line-height: 2rem;
  padding: 1rem 0;
`;
