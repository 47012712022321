import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/places/";

export const GET_PLACES = `${SCOPE}GET_PLACES`;
export const GET_PLACES_ERROR = `${SCOPE}GET_PLACES_ERROR`;
export const SET_PLACES = `${SCOPE}SET_PLACES`;

export function getPlaces({ url, query } = {}) {
  return {
    type: GET_PLACES,
    url,
    query,
  };
}

export function getPlacesError(error) {
  return {
    type: GET_PLACES_ERROR,
    error,
  };
}

export function setPlaces(
  places = { data: { attributes: { placesByStreetAndCityAndMarket: [] } } }
) {
  return {
    type: SET_PLACES,
    places,
  };
}

export const initialState = {
  data: { attributes: { placesByStreetAndCityAndMarket: [] } },
};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_PLACES:
      return { ...state, ...action.places };
    default:
      return state;
  }
}

export const getPlacesEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_PLACES),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        map(setPlaces),
        catchError((error) => of(getPlacesError(error)))
      )
    )
  );

export const epics = combineEpics(getPlacesEpic);
