import React from "react";

import { DotLoader } from "@dpz/frankensteiner";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  getCurrentOrderId,
  getCurrentOrderProducts,
} from "ducks/order/selectors";

import { useGetAutoCouponsQuery } from "rtk_redux/api/autoCoupons";

import CouponGroup from "./CouponGroup";

const FilteredCouponGroup = ({
  coupons = [],
  createOrderCoupon = () => {},
  isCarplayCustomer = false,
  marketCode,
  t = () => {},
}) => {
  const currentOrderId = useSelector(getCurrentOrderId);
  const currentOrderProducts = useSelector(getCurrentOrderProducts);
  const isEmptyOrder = Object.keys(currentOrderProducts).length === 0;
  const { data, error, isLoading } = useGetAutoCouponsQuery(currentOrderId, {
    refetchOnMountOrArgChange: true,
  });
  const filteredCoupons = coupons.map((coupon) => ({
    ...coupon,
    canApplyToOrder:
      data?.fulfilledCoupons?.includes(coupon.couponCode) ||
      data?.unfulfilledCoupons?.includes(coupon.couponCode),
  }));
  // business decision to display first three coupons as "preferred"
  const preferredCoupons = filteredCoupons.slice(0, 3);
  const remainingCoupons = filteredCoupons.slice(3);
  return isLoading ? (
    <DotLoader />
  ) : (
    <div>
      <p>
        <b>
          {error
            ? t("coupons:filtered_coupons_error")
            : t("coupons:filtered_coupons_message")}
        </b>
      </p>

      <CouponGroup
        coupons={preferredCoupons}
        createOrderCoupon={createOrderCoupon}
        isCarplayCustomer={isCarplayCustomer}
        isFiltered={!isEmptyOrder}
        marketCode={marketCode}
        t={t}
      />

      <div className="dashed">
        <span>{t("coupons:preferred_coupons_above")}</span>
      </div>

      <CouponGroup
        coupons={remainingCoupons}
        createOrderCoupon={createOrderCoupon}
        isCarplayCustomer={isCarplayCustomer}
        isFiltered={!isEmptyOrder}
        marketCode={marketCode}
        t={t}
      />
    </div>
  );
};

FilteredCouponGroup.propTypes = {
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      couponCode: PropTypes.string,
      couponDescription: PropTypes.string,
      couponName: PropTypes.string,
      couponPrice: PropTypes.string,
    })
  ),
  createOrderCoupon: PropTypes.func,
  isCarplayCustomer: PropTypes.bool,
  marketCode: PropTypes.string.isRequired,
  t: PropTypes.func,
};
export default FilteredCouponGroup;
