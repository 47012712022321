import React, { Component } from "react";

import PropTypes from "prop-types";

import TypeAhead from "components/StoreLocator/Strategy/TypeAhead";

class GoogleMapsTypeAhead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
    };

    this.autocompleteCallback = this.autocompleteCallback.bind(this);
    this.clearSuggestions = this.clearSuggestions.bind(this);
    this.fetchPredictions = this.fetchPredictions.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { maps } = this.props;

    this.autocompleteService = new maps.places.AutocompleteService();
    this.autocompleteOK = maps.places.PlacesServiceStatus.OK;
  }

  autocompleteCallback(predictions, status) {
    if (status !== this.autocompleteOK) {
      this.props.onError(status);
      this.clearSuggestions();
      return;
    }

    this.setState({
      suggestions: predictions.map(
        ({
          description,
          id,
          /* eslint-disable camelcase */
          matched_substrings,
          place_id,
          structured_formatting,
          /* eslint-enable camelcase */
          terms,
          types,
        }) => ({
          description,
          id,
          matchedSubstrings: matched_substrings,
          placeId: place_id,
          suggestion: structured_formatting,
          terms,
          types,
        })
      ),
    });
  }

  clearSuggestions() {
    this.setState({
      suggestions: [],
    });
  }

  fetchPredictions() {
    const { input, options } = this.props;
    if (input.length) {
      this.autocompleteService.getPlacePredictions(
        Object.assign({}, options, {
          input,
        }),
        this.autocompleteCallback
      );
    }
  }

  handleSelect(result) {
    const { name, onChange, handleSelect } = this.props;
    if (handleSelect) handleSelect(result);
    else {
      const { description, placeId } = result;
      onChange({ [name]: description, placeId });
    }
  }

  render() {
    const {
      className,
      debounce,
      disabled,
      input,
      label,
      name,
      quidBase,
      onChange,
      required,
    } = this.props;

    const { suggestions } = this.state;

    return (
      <TypeAhead
        className={className}
        debounce={debounce}
        disabled={disabled}
        input={input}
        label={label}
        fetchPredictions={this.fetchPredictions}
        onChange={onChange}
        clearSuggestions={this.clearSuggestions}
        handleSelect={this.handleSelect}
        name={name}
        quidBase={quidBase}
        suggestions={suggestions}
        required={required}
      />
    );
  }
}

GoogleMapsTypeAhead.propTypes = {
  className: PropTypes.string,
  debounce: PropTypes.number,
  disabled: PropTypes.bool,
  handleSelect: PropTypes.func,
  input: PropTypes.string,
  label: PropTypes.string,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  quidBase: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  options: PropTypes.objectOf(PropTypes.any),
  required: PropTypes.bool,
};

GoogleMapsTypeAhead.defaultProps = {
  className: "",
  debounce: 500,
  disabled: false,
  handleSelect: null,
  input: "",
  label: "",
  name: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    process.env.NODE_ENV !== "production" && console.error(error);
  },
  options: {},
  required: false,
};

export default GoogleMapsTypeAhead;
