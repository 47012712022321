import { ACI } from "constants/finish";

import { baseApi } from "./baseApi";

export const paymentGatewayServiceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTokenizeTemplate: builder.query({
      async queryFn(
        {
          storeId,
          cardType,
          failureTokenType,
          retryCount,
          cardNum,
          expirationMonth,
          expirationYear,
        },
        _api,
        _options,
        baseQuery
      ) {
        const { data, error } = await baseQuery({
          url: "/power/paymentGatewayService/tokenizeTemplate",
          params: { storeID: storeId, cardType, failureTokenType, retryCount },
        });
        if (error) return { error };
        const { TokenType = "", Enabled = true, Request = {} } = data;
        // On retryCount 3, Enabled should be false and tokenization calls will stop
        if (!Enabled) return { data: { tokenizationDisabled: true } };
        const { Body = "", Headers = "", Method = "", Uri = "" } = Request;
        const body = JSON.parse(Body);
        if (TokenType === ACI) {
          body.accountNumber = cardNum;
          body.cardExpiryDate = expirationMonth + expirationYear;
        } else {
          body.cardInfo.cardNumber = cardNum;
          body.cardInfo.cardExpirationMonth = expirationMonth;
          body.cardInfo.cardExpirationYear === "%cardExpYear2%"
            ? (body.cardInfo.cardExpirationYear = expirationYear)
            : (body.cardInfo.cardExpirationYear = "20" + expirationYear);
        }
        return {
          data: {
            url: Uri,
            method: Method,
            headers: Headers,
            body,
            tokenType: TokenType,
          },
        };
      },
    }),
  }),
});

export const { useLazyGetTokenizeTemplateQuery } = paymentGatewayServiceApi;
