import { css } from "@emotion/react";

export default css`
  .customer {
    flex-direction: row-reverse;

    .coupon {
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 5px;
      cursor: pointer;

      &.is-active {
        background-color: var(--dom-blue);
        color: #fff;
      }

      &:not(.is-active) {
        background-color: var(--interactive-bg);
        color: var(--interactive-fg);

        &:hover {
          color: var(--dom-red);
        }
      }

      .body {
        margin-bottom: 1rem;
        transition: color 0.1s ease;
      }

      .footer {
        display: flex;

        > * {
          flex: 1;
          font-weight: bold;

          &:last-child {
            text-align: right;
          }

          &:first-child {
            text-align: left;

            &::before {
              content: "(";
            }

            &::after {
              content: ")";
            }
          }
        }
      }
    }
  }
`;
