import { createSelector } from "reselect";

import { getMarketCode, getMarketProfileFields } from "ducks/market/selectors";
import { getAcceptableCreditCards } from "ducks/store/selectors";

import { PROFILE } from "constants/customer";
import { CC_TYPES_STORE_TO_CUSTOMER_MAP } from "constants/finish";
import doValidation from "modules/doValidation";
import formatPhone from "modules/formatPhone";
import sortCardsByDefaultAndLastUsed from "modules/sortCardsByDefaultAndLastUsed";

export const getCustomer = ({ customer = {} } = {}) => customer;

export const getCustomerId = createSelector(
  [getCustomer],
  ({ customerId }) => customerId
);

export const getCustomerFirstName = createSelector(
  [getCustomer],
  ({ firstName }) => firstName || ""
);

export const getCustomerLastName = createSelector(
  [getCustomer],
  ({ lastName }) => lastName || ""
);

export const getCustomerEmail = createSelector(
  [getCustomer],
  ({ email }) => email || ""
);

export const getCustomerPhone = createSelector(
  [getCustomer],
  ({ phone }) => phone || ""
);

export const getCustomerPrefix = createSelector(
  [getCustomer],
  ({ prefix }) => prefix || ""
);

export const getCustomerExtension = createSelector(
  [getCustomer],
  ({ extension }) => extension || ""
);

export const getCustomerFormattedPhone = createSelector(
  [getCustomerPhone],
  (phone) => formatPhone(phone)
);

export const getCustomerName = createSelector(
  [getCustomerFirstName, getCustomerLastName],
  (firstName, lastName) => `${firstName} ${lastName}`.trim()
);

export const getCustomerComments = createSelector(
  [getCustomer],
  ({ comments = "" }) => comments || ""
);

export const getCustomerTaxCodes = createSelector(
  [getCustomer],
  ({ taxCodes }) => taxCodes || []
);

export const getCustomerSavedCreditCards = createSelector(
  [getCustomer, getAcceptableCreditCards],
  ({ creditCards = [] }, acceptableStoreCreditCards = []) => {
    const normalizedStoreCards = acceptableStoreCreditCards.map(
      (card) => CC_TYPES_STORE_TO_CUSTOMER_MAP[card]
    );

    return creditCards
      .slice()
      .filter(({ isExpired }) => !isExpired)
      .filter(({ cardType }) => normalizedStoreCards.includes(cardType))
      .sort(sortCardsByDefaultAndLastUsed);
  }
);

export const getIsMinimumCustomerDataComplete = (customerData = {}) =>
  createSelector(
    [getCustomer, getMarketCode, getMarketProfileFields],
    (customer, marketCode, profileFields) => {
      const orderCustomer = { customer, ...customerData };
      return Object.entries(profileFields)
        .filter(
          ([, { required = false, hidden = false }]) => required && !hidden
        )
        .every(([key]) => {
          const isPhone = profileFields[key].type === PROFILE.PHONE;
          const prefix = orderCustomer[PROFILE.PREFIX];

          return (
            !!orderCustomer[key] &&
            doValidation(
              isPhone && prefix
                ? `+${prefix}${orderCustomer[key]}`
                : orderCustomer[key],
              profileFields[key].type,
              marketCode
            )
          );
        });
    }
  );

export default getCustomerName;
