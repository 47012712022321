import { MESSAGE, MESSAGE_TYPE, STATUS } from "constants/message";
import {
  buildErrorFromInvalidProps,
  buildErrorFromStatusItems,
} from "modules/errorMessageHelpers";
import uuid from "modules/uuid";

const SCOPE = "order-entry/message/";

export const SET_MESSAGE = `${SCOPE}SET_MESSAGE`;
export const CLEAR_MESSAGE = `${SCOPE}CLEAR_MESSAGE`;

export function setMessage({
  interpolation = {},
  message = "",
  messageType = MESSAGE_TYPE.TOASTER,
  status = STATUS.SUCCESS,
  title = "",
  showExpandedOrderConfirmationMessage,
  expandedOrderConfirmationMessageData,
} = {}) {
  return {
    type: SET_MESSAGE,
    interpolation,
    message,
    messageType,
    status,
    title,
    showExpandedOrderConfirmationMessage,
    expandedOrderConfirmationMessageData,
  };
}

export function clearMessage(id) {
  return {
    type: CLEAR_MESSAGE,
    id,
  };
}

export const initialState = {};

export default function reducer(state = initialState, action = {}) {
  let result;
  switch (action.type) {
    case SET_MESSAGE:
      return Object.assign({}, state, {
        [uuid()]: action,
      });
    case CLEAR_MESSAGE:
      result = Object.assign({}, state);
      delete result[action.id];
      return result;
    default:
      return state;
  }
}

const setErrorMessages = ({ dispatch, error, type, status = STATUS.ERROR }) => {
  const { response } = error;
  const { errors } = response || {};

  errors
    ? errors.map(({ detail, title }) =>
        dispatch(
          setMessage({
            status,
            message:
              detail === "CouponExclusivityViolation"
                ? [`${status}:${detail}`]
                : [`${status}:${title}`, `${status}:${detail}`],
          })
        )
      )
    : dispatch(
        setMessage({
          message: error.toString(),
          status,
          title: type,
        })
      );
};

export const messageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const { invalidProps, statusItems, type } = action;
    let { error } = action;
    let status;

    if (error && process.env.NODE_ENV !== "production")
      console.error(type, error); // eslint-disable-line no-console

    // removes unhelpful error messages with failed tokenization requests
    if (
      type === "externalApi/executeQuery/rejected" ||
      type === "baseApi/executeQuery/rejected"
    ) {
      return next(action);
    }

    //TODO: improve coverage
    if (invalidProps) error = buildErrorFromInvalidProps(invalidProps);

    if (statusItems && statusItems.length > 0) {
      error = buildErrorFromStatusItems({ statusItems });
      status = STATUS.WARNING;
      // removing statusItems so they are not saved in state
      delete action.statusItems;
    }

    // overwrite start order message to be a warning, not an error
    if (invalidProps?.orderId?.[0] === MESSAGE.ORDER_NOT_STARTED) {
      status = STATUS.WARNING;
    }

    if (error)
      setErrorMessages({
        dispatch,
        error,
        status,
        type,
      });

    return next(action);
  };
