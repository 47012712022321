import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import getCoupons from "ducks/coupons/selectors";
import { setMessage } from "ducks/message";

import { selectIsCarplayCustomer } from "rtk_redux/slices/customerPageSlice";

import { STATUS } from "constants/message";

const CouponEntry = ({ createOrderCoupon = () => {}, t = () => {} }) => {
  const dispatch = useDispatch();
  const menuCoupons = useSelector(getCoupons);
  const isCarplayCustomer = useSelector(selectIsCarplayCustomer);

  const [couponCode, setCouponCode] = useState("");

  const handleChange = (e) => setCouponCode(e.target.value.toUpperCase());

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isCarplayCustomer && menuCoupons[couponCode]?.digitalOnly) {
      return dispatch(
        setMessage({
          message: "coupons:digital_only",
          status: STATUS.WARNING,
        })
      );
    }

    createOrderCoupon({ couponCode });
  };

  return (
    <div className="grid">
      <form
        className="coupon-entry-form form__control-group grid__cell--1 grid__cell--3/5@desktop"
        data-quid="coupon-entry"
        onSubmit={handleSubmit}
      >
        <label
          className="coupon-entry-form__label"
          data-quid="coupon-entry-label"
          htmlFor="enter-coupon-code"
        >
          {t("coupons:coupon_code")}
        </label>

        <input
          className="coupon-entry-form__input"
          data-quid="coupon-entry-input"
          id="enter-coupon-code"
          onChange={handleChange}
          placeholder={t("coupons:coupon_code")}
          type="text"
          value={couponCode}
        />

        <button
          className="btn btn--primary coupon-entry-form__button"
          data-quid="coupon-entry-submit"
          type="submit"
        >
          {t("coupons:add_coupon")}
        </button>
      </form>
    </div>
  );
};

CouponEntry.propTypes = {
  createOrderCoupon: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default CouponEntry;
