import {
  RNC_COMPANY_NAME,
  RNC_FINAL_CONSUMER,
  RNC_TAX_ID,
} from "constants/rnc";

// The Dominican Republic requires an orderInfoCollection array on placeOrder.
// The array contains three objects of the form { keyCode, response }: one for
// each of the three RNC fields (receipt type, RNC number, and company name).

export const formatOrderInfoCollection = ({
  rncReceiptType,
  rncNumber,
  rncCompanyName,
}) =>
  rncReceiptType === RNC_FINAL_CONSUMER
    ? [
        { keyCode: RNC_FINAL_CONSUMER, response: RNC_FINAL_CONSUMER },
        { keyCode: RNC_TAX_ID, response: "" },
        { keyCode: RNC_COMPANY_NAME, response: "" },
      ]
    : [
        { keyCode: rncReceiptType, response: rncReceiptType },
        { keyCode: RNC_TAX_ID, response: rncNumber },
        { keyCode: RNC_COMPANY_NAME, response: rncCompanyName },
      ];

export default formatOrderInfoCollection;
