import { css } from "@emotion/react";

export default css`
  @media (max-width: 45rem) {
    .app {
      .app__body {
        .body__menu {
          flex-basis: auto;
          width: auto;

          .item {
            padding: 0;
          }
        }
      }
    }

    .coupons.body__page {
      .page__section {
        .coupon__group {
          .coupon {
            flex-basis: 100%;
            max-width: 100%;
          }
        }
      }
    }

    // FO Adjustments

    .btn-group {
      &.btn-group--unlimited {
        > * {
          flex-basis: 100%;
          max-width: 100%;
        }

        &.toggleable {
          > * {
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(3) {
              &.btn,
              & ~ .btn {
                flex-basis: 100%;
                max-width: 100%;
              }
            }
          }
        }

        &:not(.toggleable) {
          > * {
            flex-basis: 100%;
            max-width: 100%;

            &:not(:first-child):not(:last-child),
            & {
              margin: 0 0 1rem;
            }
          }
        }
      }
    }

    table {
      tr {
        margin: 0;

        > td {
          align-items: center;

          &:empty {
            display: none;
          }

          &[data-th] {
            &::before {
              text-align: left;
            }
          }

          &:not([data-th]) {
            &::before {
              display: none;
            }

            > * {
              margin: 0 auto;
            }
          }

          .collapsible__item {
            box-sizing: border-box;
            width: 100%;
          }

          .btn--icon-only[data-icon] {
            padding: 0;
          }
        }
      }
    }

    .table--border-bottom {
      tr:first-child {
        th,
        td {
          &:not(:last-child):not(:empty) {
            border-bottom-width: 0;
          }
        }
      }

      tr:not(:first-child) {
        th,
        td {
          border-right-width: 1px;
        }
      }
    }
  }
`;
