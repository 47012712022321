import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { ROUTE_TITLES } from "routes";

import kebabCase from "modules/kebabCase";

const Page = ({
  id,
  header,
  actions = null,
  children = null,
  className = "",
  footer = null,
  sidebar = null,
  tabs = null,
  title = "",
  seoTitle = ROUTE_TITLES.HOME,
}) => (
  <div className={classNames(id, "body__page")}>
    <Helmet>
      <title>{seoTitle}</title>
    </Helmet>
    {sidebar}

    <div className="page__section">
      {tabs && <div className="section__banner">{tabs}</div>}

      <div className="section__content">
        <div
          className={classNames(
            "content__pane",
            footer && "content__pane--split"
          )}
        >
          <div className="pane__content">
            <header className="title-group">
              <h1
                className="title-group__section"
                data-quid={`heading-${kebabCase(title)}`}
              >
                {title}
              </h1>
              {header && header}
              {actions && <div className="title-group__section">{actions}</div>}
            </header>

            {children}
          </div>

          {footer && (
            <div className={classNames("pane__footer text--center", className)}>
              <hr />

              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

Page.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.node,
  id: PropTypes.string.isRequired,
  sidebar: PropTypes.node,
  tabs: PropTypes.node,
  title: PropTypes.string,
  header: PropTypes.node,
  seoTitle: PropTypes.string,
};

export default Page;
