import { createSelector } from "reselect";

import { MESSAGE_TYPE } from "constants/message";

export const getMessage = ({ message = {} } = {}) => message;

export const getMessages = (filterCallback = () => {}) =>
  createSelector([getMessage], (messages) =>
    Object.entries(messages)
      .filter(filterCallback)
      .reduce(
        (all, [id, message]) =>
          Object.assign(all, {
            [id]: message,
          }),
        {}
      )
  );

const defaultMessageType = MESSAGE_TYPE.TOASTER;

export const getToasterMessages = getMessages(
  ([, { messageType = defaultMessageType }]) =>
    messageType !== MESSAGE_TYPE.OVERLAY
);

export const getOverlayMessages = getMessages(
  ([, { messageType = defaultMessageType }]) =>
    messageType === MESSAGE_TYPE.OVERLAY
);

export default getMessage;
