import { css } from "@emotion/react";

export const spaceBetweenY = css`
  & > * + * {
    margin-top: 0.5rem;
  }
`;

export const nonMeatsWrapperStyles = css`
  flex: 2;

  &:not(:first-of-type) {
    padding-left: 1rem;
  }

  @media screen and (max-width: 70em) {
    & {
      flex-direction: column;

      &:not(:first-of-type) {
        padding-left: 0;
        padding-top: 1rem;
      }
    }
  }
`;
