import { CC, CC_TYPES } from "constants/finish";
import { COUNTRY_CODES } from "constants/market";

// credit card masking function taken from NOLO
export default (id, marketCode, creditCardType = "") => {
  switch (id) {
    case CC.CARD_NUMBER:
      switch (creditCardType) {
        case CC_TYPES.MAESTRO:
          return "0000-0000-0000-0000-000";
        case CC_TYPES.OPTIMA:
        case CC_TYPES.AMEX:
        case CC_TYPES.DINERS:
          return "0000-000000-00000";
        default:
          return "0000-0000-0000-0000";
      }
    case CC.ZIP_CODE:
      if (marketCode === COUNTRY_CODES.UNITED_STATES) {
        // only add dash if agent enters 6 or more characters for the zip code
        return "00000-0000";
      }
    case CC.TIP_AMOUNT:
      return "$num";
    default:
      // allow any digits to be added for expiration date and cvv
      return "0000";
  }
};
