/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getMarketConfigs } from "ducks/market/selectors";

import { SERVICE_METHOD } from "constants/order";
import formatDateTime from "modules/formatDateTime";
import isDelivery from "modules/isDelivery";

const getTrackerMessage = ({ isCarplay, isDCD }) => {
  if (isCarplay && isDCD)
    return "finish:order_confirmation.tracker_carplay_dcd";
  if (isCarplay) return "finish:order_confirmation.tracker_carplay";
  if (isDCD) return "finish:order_confirmation.tracker_dcd";

  return "finish:order_confirmation.tracker_cca";
};

const boldStyle = css`
  font-weight: bold;
`;

const ExpandedOrderConfirmationMessage = ({
  currentServiceMethod = SERVICE_METHOD.CARRYOUT,
  customerAddress = "",
  customerName = "",
  futureOrderTime = "",
  isCarplay = false,
  isFutureOrder = false,
  receiptQuantity = 0,
  storeAddress = "",
  storeOrderId = "",
  waitTime = { Delivery: { Min: 0, Max: 0 }, Carryout: { Min: 0, Max: 0 } },
  t = () => {},
}) => {
  const { DEFAULT_DATE_FORMAT } = useSelector(getMarketConfigs);
  const isDCD = currentServiceMethod === SERVICE_METHOD.DCD;

  const { Min: min = 0, Max: max = 0 } =
    waitTime[
      isDelivery(currentServiceMethod)
        ? SERVICE_METHOD.DELIVERY
        : SERVICE_METHOD.CARRYOUT
    ];

  const trackerMessage = getTrackerMessage({ isCarplay, isDCD });

  const formattedOrderTime = isFutureOrder
    ? DOMPurify.sanitize(
        formatDateTime({
          dateTime: new Date(futureOrderTime),
          dateFormat: DEFAULT_DATE_FORMAT,
          timeZoneAware: false,
        })
      )
    : t("shared:order_timing.now");

  return (
    <div>
      <p css={boldStyle}>
        {isDelivery(currentServiceMethod)
          ? t(
              "finish:order_confirmation.customer_delivery_order_has_been_placed",
              {
                customerName,
                customerAddress,
              }
            )
          : t("finish:order_confirmation.customer_order_has_been_placed", {
              customerName,
              storeAddress,
            })}
      </p>
      {max !== 0 && (
        <p css={boldStyle}>
          {t("finish:order_confirmation.order_ready_time", {
            min,
            max,
          })}
        </p>
      )}
      <p css={boldStyle}>{t(trackerMessage)}</p>
      <p>{t("finish:order_confirmation.order_number", { storeOrderId })}</p>
      <p>
        {t("finish:order_confirmation.number_of_products", { receiptQuantity })}
      </p>
      <p>{t("finish:order_confirmation.date_time", { formattedOrderTime })}</p>
      <p css={boldStyle}>{t("finish:order_confirmation.thank_you")}</p>
    </div>
  );
};

ExpandedOrderConfirmationMessage.propTypes = {
  currentServiceMethod: PropTypes.string,
  customerAddress: PropTypes.string,
  customerName: PropTypes.string,
  futureOrderTime: PropTypes.string,
  isCarplay: PropTypes.bool,
  isFutureOrder: PropTypes.bool,
  receiptQuantity: PropTypes.number,
  storeAddress: PropTypes.string,
  storeOrderId: PropTypes.string,
  waitTime: PropTypes.shape({
    Delivery: PropTypes.shape({
      Max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    Carryout: PropTypes.shape({
      Max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  t: PropTypes.func,
};

export default ExpandedOrderConfirmationMessage;
