/** @jsxImportSource @emotion/react */
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Tab.styles";

const Tab = ({ active, disabled, icon, onClick, quidBase, text }) => (
  <button
    css={styles}
    className={classNames("tab tabs__tab", active && "is-active")}
    data-icon={icon}
    data-quid={quidBase}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    {text}
  </button>
);

Tab.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  quidBase: PropTypes.string.isRequired,
  text: PropTypes.string,
};

Tab.defaultProps = {
  active: false,
  disabled: false,
  icon: null,
  onClick: () => {},
  text: "",
};

export default Tab;
