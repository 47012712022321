export const RNC_ADD_NEW_NUMBER = "New";

export const RNC_COMPANY_NAME = "CompanyName";

export const RNC_FINAL_CONSUMER = "FinalConsumer";

export const RNC_GOVERNMENT = "Government";

export const RNC_SPECIAL_REGIME = "SpecialRegime";

export const RNC_TAX_ID = "TaxID";

export const RNC_THIRD_PARTY = "3rdParty";

export const RNC_USE_SAVED_NUMBER = "Saved";
