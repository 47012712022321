import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";

import { updateCustomer } from "ducks/customer";
import { lookupCustomer } from "ducks/customer";
import { getCustomer } from "ducks/customer/selectors";
import { updateOrderServiceMethod, updateOrderStore } from "ducks/order";
import { getCurrentOrder } from "ducks/order/selectors";
import { getCustomersLink } from "ducks/root/selectors";
import { getCurrentStoreId, getHasSelectedStore } from "ducks/store/selectors";
import { getLinkFromRelationship } from "selectors/related";

import {
  selectExtension,
  selectNumericPhoneNumber,
  selectPrefix,
} from "rtk_redux/slices/customerInformationFormSlice";
import { setLookupPending } from "rtk_redux/slices/customerPageSlice";
import { resetOrderHistory } from "rtk_redux/slices/orderHistorySlice";

import {
  STJUDE_CHECKOUT_ROUND_UP_ENABLED,
  STJUDE_ENABLED,
} from "constants/launchDarkly";

export const useIsCurrentStore = (storeId) => {
  const currentStoreId = useSelector(getCurrentStoreId);
  const hasUserSelectedStore = useSelector(getHasSelectedStore);
  return storeId === currentStoreId && hasUserSelectedStore;
};

export const useHandleCustomerUpdate = () => {
  const dispatch = useDispatch();
  const customer = useSelector(getCustomer);
  const currentOrder = useSelector(getCurrentOrder);
  const customerLink = getLinkFromRelationship("customer")(currentOrder);
  const handleCustomerUpdate = (valuesToUpdate) => {
    return dispatch(
      updateCustomer({
        ...customer,
        ...valuesToUpdate,
        url: customerLink,
      })
    );
  };
  return handleCustomerUpdate;
};

export const useHandleCustomerLookup = () => {
  const {
    [STJUDE_ENABLED]: stJudeEnabled,
    [STJUDE_CHECKOUT_ROUND_UP_ENABLED]: stJudeCheckoutRoundUpEnabled,
  } = useFlags();
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const phoneNumber = useSelector(selectNumericPhoneNumber);
  const extension = useSelector(selectExtension);
  const customersLink = useSelector(getCustomersLink);
  const customer = useSelector(getCustomer);

  const lookupParams = {
    phone: phoneNumber,
    prefix,
    extension,
  };

  const currentParams = {
    phone: customer?.phone,
    prefix: customer?.prefix,
    extension: customer?.extension,
  };

  const handleCustomerLookup = () => {
    if (JSON.stringify(lookupParams) !== JSON.stringify(currentParams)) {
      dispatch(resetOrderHistory());
      dispatch(setLookupPending());
      dispatch(
        lookupCustomer({
          phone: phoneNumber,
          prefix,
          extension,
          stJudeRoundUpEnabled: stJudeEnabled && stJudeCheckoutRoundUpEnabled,
          url: customersLink,
        })
      );
    }
  };

  return handleCustomerLookup;
};

export const useSetCurrentOrderStoreAndMethod = () => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(getCurrentOrder);
  const orderStoreLink = getLinkFromRelationship("store")(currentOrder);
  const orderAddressLink = getLinkFromRelationship("address")(currentOrder);
  const orderServiceMethodLink =
    getLinkFromRelationship("serviceMethod")(currentOrder);

  const setCurrentOrderStoreAndServiceMethod = ({ storeId, serviceMethod }) =>
    dispatch(
      updateOrderStore({
        addressId: currentOrder.addressId,
        orderAddressLink,
        orderId: currentOrder.orderId,
        storeId,
        url: orderStoreLink,
        updateServiceMethod: true,
        serviceMethodInfo: {
          ...currentOrder,
          serviceMethod,
          url: orderServiceMethodLink,
        },
      })
    );

  const setCurrentOrderStore = (storeId) =>
    dispatch(
      updateOrderStore({
        addressId: currentOrder.addressId,
        orderAddressLink,
        orderId: currentOrder.orderId,
        storeId,
        url: orderStoreLink,
      })
    );

  const setServiceMethod = (serviceMethod) =>
    dispatch(
      updateOrderServiceMethod({
        ...currentOrder,
        serviceMethod,
        url: orderServiceMethodLink,
      })
    );

  return {
    setCurrentOrderStoreAndServiceMethod,
    setCurrentOrderStore,
    setServiceMethod,
  };
};

export const useMarkerPosition = ({ lat, lng }) => {
  const position = {
    lat: Number(lat),
    lng: Number(lng),
  };
  const showMarker = Boolean(lat) && Boolean(lng);

  return [showMarker, position];
};
