export const DEFAULT_COUPON_CATEGORY_ID = "All";

export const COUPON_STATUS = Object.freeze({
  FULFILLED: "Fulfilled",
  UNFULFILLED: "Unfulfilled",
});

export const NATIONAL = "national";

export const ALL = "All";

export const NEW_TAB_LIST = Object.freeze([
  {
    categoryCode: NATIONAL,
    categoryId: NATIONAL,
    categoryName: "coupons:national",
  },
  {
    categoryCode: "AllStoreCoupons",
    categoryId: "AllStoreCoupons",
    categoryName: "coupons:local",
  },
  {
    categoryCode: ALL,
    categoryId: ALL,
    categoryName: "coupons:filter",
  },
]);

export default DEFAULT_COUPON_CATEGORY_ID;
