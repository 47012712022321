export const excludedStatuses = Object.freeze([
  "AutoAddedOrderId",
  "MitigationRequired",
  "PriceInformationRemoved",
]);

export const MESSAGE = {
  ORDER_CONFIRMATION: "shared:messages.order_confirmation",
  ORDER_NOT_STARTED: "order_not_started",
  ORDER_STARTED: "shared:messages.order_started",
  ORDER_PLACED: "shared:messages.order_placed",
  ORDER_EXITED: "shared:messages.order_exited",
};

export const ERROR_PREFIX = {
  VALIDATE_ORDER: "validate_order",
  PRICE_ORDER: "price_order-",
  PLACE_ORDER: "place_order-",
};

export const MESSAGE_TYPE = Object.freeze({
  OVERLAY: "overlay",
  TOASTER: "toaster",
});

export const STATUS = Object.freeze({
  ERROR: "negative",
  SUCCESS: "positive",
  WARNING: "attention",
});

export default MESSAGE_TYPE;
