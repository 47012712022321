import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/neighborhoods/";

export const GET_NEIGHBORHOODS = `${SCOPE}GET_NEIGHBORHOODS`;
export const GET_NEIGHBORHOODS_ERROR = `${SCOPE}GET_NEIGHBORHOODS_ERROR`;
export const SET_NEIGHBORHOODS = `${SCOPE}SET_NEIGHBORHOODS`;

const STORE_LOCATOR_NEIGHBORHOODS_URL =
  "/store-locator-international/locations/neighborhood";

export function getNeighborhoods({ query = {} } = {}) {
  return {
    type: GET_NEIGHBORHOODS,
    power: true,
    query,
    url: STORE_LOCATOR_NEIGHBORHOODS_URL,
  };
}

export function getNeighborhoodsError(error) {
  return {
    type: GET_NEIGHBORHOODS_ERROR,
    error,
  };
}

export function setNeighborhoods(neighborhoods = {}) {
  return {
    type: SET_NEIGHBORHOODS,
    neighborhoods,
  };
}

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_NEIGHBORHOODS:
      return action.neighborhoods;
    default:
      return state;
  }
}

export const getNeighborhoodsEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_NEIGHBORHOODS),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        map((neighborhoods) =>
          setNeighborhoods(
            neighborhoods.reduce(
              (all, { name }) =>
                Object.assign(all, {
                  [name]: name,
                }),
              {}
            )
          )
        ),
        catchError((error) => of(getNeighborhoodsError(error)))
      )
    )
  );

export const epics = combineEpics(getNeighborhoodsEpic);
