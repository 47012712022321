import { css } from "@emotion/react";

export const receiptListStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0.3rem 0 !important;
  &:not(last-child) {
    border-bottom: 1px solid rgb(219, 233, 240);
  }
`;
