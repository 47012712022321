import { translate } from "react-i18next";
import { connect } from "react-redux";

import { everythingElseRoute } from "routes";

import { getMarketConfigs } from "ducks/market/selectors";
import { getEverythingElseCategories } from "ducks/menu/selectors";
import {
  getActiveOrderProduct,
  getActiveOrderProductAvailableOptionsByArea,
  getActiveOrderProductBaseId,
  getActiveOrderProductOptionIds,
  getActiveOrderProductSizeId,
  getCurrentOrder,
  getCurrentOrderId,
} from "ducks/order/selectors";
import {
  createOrderProduct,
  deleteOrderProductOption,
  updateOrderProduct,
} from "ducks/orderProduct";
import {
  getActiveOrderProductDefaultOptions,
  getActiveOrderProductProducts,
  getActiveOrderProductSides,
  getActiveOrderProductToppings,
} from "ducks/orderProduct/selectors";
import { getPayloadCategory } from "selectors/location";
import { getLinkFromRelationship, getSelfLink } from "selectors/related";

import { AREA, defaultArea } from "constants/order";
import arbitrarySortByKey from "modules/arbitrarySortByKey";
import categorizeToppings from "modules/categorizeToppings";
import kebabCase from "modules/kebabCase";

import EverythingElse from "components/EverythingElse";

const mapStateToProps = (state) => ({
  activeArea: defaultArea,
  activeOrderProduct: getActiveOrderProduct(state),
  activeOrderProductAvailableOptionsByArea:
    getActiveOrderProductAvailableOptionsByArea(state),
  activeOrderProductBaseId: getActiveOrderProductBaseId(state),
  activeOrderProductDefaultOptions: getActiveOrderProductDefaultOptions(state),
  activeOrderProductOptionIds: getActiveOrderProductOptionIds(state),
  activeOrderProductProducts: getActiveOrderProductProducts(state),
  activeOrderProductSides: getActiveOrderProductSides(state),
  activeOrderProductSizeId: getActiveOrderProductSizeId(state),
  activeOrderProductToppings: getActiveOrderProductToppings(state),
  categories: getEverythingElseCategories(state),
  currentCategoryName: getPayloadCategory(state),
  currentOrder: getCurrentOrder(state),
  currentOrderId: getCurrentOrderId(state),
  marketConfigs: getMarketConfigs(state),
});

const mapDispatchToProps = (dispatch) => ({
  createOrderProduct: (orderProduct) =>
    dispatch(createOrderProduct(orderProduct)),
  deleteOrderProductOption: (option) =>
    dispatch(deleteOrderProductOption(option)),
  goToEverythingElse: (category) => dispatch(everythingElseRoute(category)),
  updateOrderProduct: (orderProduct) =>
    dispatch(updateOrderProduct(orderProduct)),
});

const mergeProps = (
  {
    activeOrderProduct,
    activeOrderProductDefaultOptions,
    categories,
    currentCategoryName,
    currentOrder,
    currentOrderId,
    marketConfigs: { NEW_EXPERIENCE_FEATURE_OPTIONS },
    ...state
  },
  {
    createOrderProduct: dispatchCreateOrderProduct,
    deleteOrderProductOption: dispatchDeleteOrderProductOption,
    updateOrderProduct: dispatchUpdateOrderProduct,
    ...dispatch
  },
  props
) => {
  const { activeArea, activeOrderProductAvailableOptionsByArea } = state;
  const { everythingElseTabOrder } = NEW_EXPERIENCE_FEATURE_OPTIONS;

  //TODO: write generic tab builder func
  const tabs = Object.values(categories)
    .sort(
      arbitrarySortByKey({
        key: "categoryName",
        order: everythingElseTabOrder,
      })
    )
    .map(({ categoryCode, categoryId, categoryName }) => ({
      id: categoryId,
      path: categoryCode.toLowerCase(),
      quidBase: `everything-else-${kebabCase(categoryName)}`,
      text: categoryName,
    }))
    .map(({ path, ...tab }, index) =>
      Object.assign(tab, {
        active: currentCategoryName
          ? currentCategoryName === path
          : index === 0,
        path,
      })
    );

  const { id: currentCategoryId, text: title } =
    tabs.find(({ active }) => active) || {};

  const {
    bases = [],
    products = [],
    sides = [],
    sizes = [],
    toppings = [],
    tags: { CCABaseBlock: mergeBasesAndProducts = false } = {},
  } = categories[currentCategoryId] || {};

  const { meats, nonMeats, sauces } = categorizeToppings(
    toppings.filter(({ toppingId }) =>
      Object.keys(activeOrderProductAvailableOptionsByArea).includes(toppingId)
    )
  );

  const hasToppings = [meats, nonMeats, sauces].some(
    (collection) => collection.length > 0
  );

  const [defaultProduct = {}] = Object.values(products);
  const { productId } = defaultProduct;

  const {
    categoryId: activeOrderProductCategoryId,
    orderProductId: activeOrderProductId,
  } = activeOrderProduct;

  const activeOrderProductLink = getSelfLink(activeOrderProduct);
  const productsLink = getLinkFromRelationship("products")(currentOrder);

  return Object.assign(
    {
      currentCategoryId,
      bases,
      hasToppings,
      meats,
      mergeBasesAndProducts,
      nonMeats,
      products,
      sauces,
      sides,
      sizes,
      tabs,
      title,
      createDefaultOrderProduct: () =>
        dispatchCreateOrderProduct({
          categoryId: currentCategoryId,
          orderId: currentOrderId,
          productId,
          url: productsLink,
        }),
      deleteOrderProductOption: (removedOptionCode) =>
        activeOrderProductId &&
        dispatchDeleteOrderProductOption(
          Object.assign({}, activeOrderProduct, {
            area: (AREA[activeArea] || AREA[defaultArea]).value,
            defaultOptions: activeOrderProductDefaultOptions,
            optionCode: removedOptionCode,
            url: activeOrderProductLink,
          })
        ),
      setOrderProduct: (orderProduct, { resetToppings = false } = {}) =>
        activeOrderProductId &&
        activeOrderProductCategoryId === currentCategoryId
          ? dispatchUpdateOrderProduct(
              Object.assign(
                {},
                activeOrderProduct,
                resetToppings ? { parts: {}, partIds: [] } : {},
                orderProduct,
                {
                  categoryId: currentCategoryId,
                  orderProductId: activeOrderProductId,
                  url: activeOrderProductLink,
                }
              )
            )
          : dispatchCreateOrderProduct(
              Object.assign({}, orderProduct, {
                categoryId: currentCategoryId,
                orderId: currentOrderId,
                url: productsLink,
              })
            ),
    },
    state,
    dispatch,
    props
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["everything_else", "food", "shared"])(EverythingElse));
