import React, { Component, Fragment } from "react";

import i18next from "i18next";
import { PropTypes } from "prop-types";
import { reactI18nextModule } from "react-i18next";
import { connect } from "react-redux";

import { getConfiguration } from "ducks/configuration/selectors";
import { getMarketCode, getMarketHeader } from "ducks/market/selectors";
import { getMenu } from "ducks/menu";
import { getRoot } from "ducks/root";

import Loader from "components/Loader";

const mapStateToProps = (state) =>
  Object.assign({}, getConfiguration(state), {
    marketCode: getMarketCode(state),
    marketHeader: getMarketHeader(state),
  });

const mapDispatchToProps = (dispatch) => ({
  getMenu: (language) => dispatch(getMenu(language)),
  getRoot: () => dispatch(getRoot()),
});

export default function ConfigureContainer(WrappedComponent) {
  class Configure extends Component {
    constructor() {
      super();

      this.state = {
        i18nInitialized: false,
        maps: {},
        mapsInitialized: false,
        mapsInitializing: false,
      };

      this.initMaps = this.initMaps.bind(this);
    }

    componentDidMount() {
      window.google && this.initMaps();

      this.props.hasBeenInitialized
        ? //eslint-disable-next-line react/no-did-mount-set-state
          this.setState({ i18nInitialized: true })
        : this.props.getRoot();
    }

    componentDidUpdate({ languages: prevLanguages }) {
      const { languages, languagesSet, market, marketCode, marketHeader } =
        this.props;
      const { i18nInitialized, mapsInitializing } = this.state;

      if (languagesSet && !i18nInitialized) {
        i18next.use(reactI18nextModule).init({
          debug: process.env.NODE_ENV !== "production",
          defaultNS: "application",
          fallbackLng: "en",
          languages: Object.keys(languages),
          ns: [
            "application",
            "coupons",
            "customer",
            "everything_else",
            "exit",
            "finish",
            "food",
            "pizzas",
            "receipt",
            "shared",
          ],
          resources: languages,
        });

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          i18nInitialized: true,
        });
      } else if (
        languagesSet &&
        prevLanguages !== languages &&
        i18nInitialized
      ) {
        const languagesToUpdateBundle = ([language, bundle]) => {
          const languageToTranslationBundle = ([namespace, translations]) => ({
            language,
            namespace,
            translations,
          });
          return Object.entries(bundle).map(languageToTranslationBundle);
        };
        const updateTranslationBundle = ({
          language,
          namespace,
          translations,
        }) => {
          i18next.addResourceBundle(
            language,
            namespace,
            translations,
            true,
            true
          );
        };

        Object.entries(languages)
          .map(languagesToUpdateBundle)
          .flat()
          .forEach(updateTranslationBundle);
      }

      if (market && !mapsInitializing) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          mapsInitializing: true,
        });

        window.initMaps = this.initMaps;

        const { document } = window;
        const ref = document.querySelectorAll("script")[0];
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?channel=${encodeURIComponent(
          marketHeader
        )}&client=gme-dominospizza&libraries=places&region=${encodeURIComponent(
          marketCode
        )}&callback=initMaps`;
        script.async = true;

        ref.parentNode.insertBefore(script, ref);
      }
    }

    initMaps() {
      const { maps } = window.google;

      this.setState({
        mapsInitialized: true,
        maps,
      });
    }

    render() {
      const { configured } = this.props;
      const { i18nInitialized, mapsInitialized, maps } = this.state;

      return i18nInitialized && mapsInitialized ? (
        <Fragment>
          {configured ? (
            <WrappedComponent getMenu={this.props.getMenu} maps={maps} />
          ) : (
            <Loader />
          )}
        </Fragment>
      ) : (
        <Loader />
      );
    }
  }

  Configure.propTypes = {
    configured: PropTypes.bool,
    getMenu: PropTypes.func.isRequired,
    getRoot: PropTypes.func.isRequired,
    hasBeenInitialized: PropTypes.bool.isRequired,
    languages: PropTypes.objectOf(PropTypes.object),
    languagesSet: PropTypes.bool,
    market: PropTypes.bool,
    marketCode: PropTypes.string,
    marketHeader: PropTypes.string,
  };

  Configure.defaultProps = {
    configured: false,
    languages: {
      en: {},
    },
    languagesSet: false,
    market: false,
    marketCode: "",
    marketHeader: "",
  };

  return connect(mapStateToProps, mapDispatchToProps)(Configure);
}
