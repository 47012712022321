import React from "react";

import PropTypes from "prop-types";

import { AREA, defaultArea } from "constants/order";
import { WEIGHT, defaultWeight } from "constants/topping";

const Area = ({ area, options, t }) => (
  <div>
    {t((AREA[area] || AREA[defaultArea]).name)}:{" "}
    {Object.entries(options)
      .map(
        ([toppingName, toppingWeight]) =>
          `${
            toppingWeight !== defaultWeight ? t(WEIGHT[toppingWeight].name) : ""
          } ${toppingName}`
      )
      .join(", ")}
  </div>
);

Area.propTypes = {
  area: PropTypes.string.isRequired,
  options: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.objectOf(PropTypes.number),
    ])
  ),
};

Area.defaultProps = {
  options: {},
};

export default Area;
