export const ORDER_RESULT_LIMIT = 5;

export const ORDER_COMPLETION_STATUS = Object.freeze({
  FULFILLED: "Complete",
  FULFILLING: "In Progress",
  UNFULFILLED: "Incomplete",
});

export const ORDER_STATUS = Object.freeze({
  ABANDONED: "Abandoned",
  APPROACHING_DESTINATION: "Approaching Destination",
  AT_THE_DOOR: "At the Door",
  BAD: "Bad",
  BEING_ASSIGNED: "Being Assigned",
  BEING_TAKEN: "Being Taken",
  COMPLETE: "Complete",
  DISPATCHED: "Dispatched",
  DRIVER_IN: "Driver In",
  FUTURE: "Future",
  GIFT: "Gift",
  GIFT_CARD_PURCHASE: "Gift Card Purchase",
  GIFT_CARD_VOID: "Gift Card Void",
  HISTORIC_ORDER: "Historic Order",
  LEFT_STORE: "Left Store",
  MAKELINE: "Makeline",
  OUT_THE_DOOR: "Out the Door",
  OVEN: "Oven",
  ROUTING_STATION: "Routing Station",
  SUSPENDED: "Suspended",
  VOID: "Void",
  WEB: "Web",
});

export const TRACKER_STATUS = Object.freeze({
  UNTRACKED: "Untracked",
  PLACE: "Place",
  PREP: "Prep",
  BAKE: "Bake",
  CHECK: "Check",
  READY: "Ready!",
});

export const UNTRACKED_STATUSES = new Map([
  [ORDER_STATUS.ABANDONED],
  [ORDER_STATUS.BAD],
  [ORDER_STATUS.GIFT],
  [ORDER_STATUS.GIFT_CARD_PURCHASE],
  [ORDER_STATUS.GIFT_CARD_VOID],
  [ORDER_STATUS.HISTORIC_ORDER],
  [ORDER_STATUS.SUSPENDED],
  [ORDER_STATUS.VOID],
]);

export const PLACE_STATUSES = new Map([
  [ORDER_STATUS.BEING_TAKEN],
  [ORDER_STATUS.FUTURE],
  [ORDER_STATUS.WEB],
]);

export const PREP_STATUSES = new Map([[ORDER_STATUS.MAKELINE]]);

export const BAKE_STATUSES = new Map([[ORDER_STATUS.OVEN]]);

export const CHECK_STATUSES = new Map([
  [ORDER_STATUS.BEING_ASSIGNED],
  [ORDER_STATUS.ROUTING_STATION],
]);

export const READY_STATUSES = new Map([
  [ORDER_STATUS.APPROACHING_DESTINATION],
  [ORDER_STATUS.AT_THE_DOOR],
  [ORDER_STATUS.COMPLETE],
  [ORDER_STATUS.DISPATCHED],
  [ORDER_STATUS.DRIVER_IN],
  [ORDER_STATUS.LEFT_STORE],
  [ORDER_STATUS.OUT_THE_DOOR],
]);

export const ORDER_CHANNEL = Object.freeze({
  OLO: "OLO",
});

export const ORDER_METHOD = Object.freeze({
  CCA: "CCA",
});

export const ORDER_META = Object.freeze({
  orderChannel: ORDER_CHANNEL.OLO,
  orderMethod: ORDER_METHOD.CCA,
});

export const ORDER_TIMING = Object.freeze({
  NOW: "Now",
  LATER: "Later",
});

export const SERVICE_METHOD = Object.freeze({
  CARRYOUT: "Carryout",
  DELIVERY: "Delivery",
  DINE_IN: "Dine In",
  DCD: "Carside",
  CARSIDE: "Carside",
  DTM: "DeliverToMe",
  PICKUP_WINDOW: "PickupWindow",
});

export const SERVICE_METHOD_SORT = Object.freeze([
  SERVICE_METHOD.CARRYOUT,
  SERVICE_METHOD.DELIVERY,
  SERVICE_METHOD.DINE_IN,
]);

export const OPTION = Object.freeze({
  TOPPING: "toppings",
  SIDE: "sides",
});

export const OPTION_DISPLAY = Object.freeze({
  toppings: "Toppings",
  sides: "Sides",
});

const ALIGNMENT = Object.freeze({
  CENTER: "center",
  LEFT: "left",
  RIGHT: "right",
});

export const AREA = Object.freeze({
  "1/1": {
    alignment: ALIGNMENT.CENTER,
    className: "btn--fill",
    count: 1,
    name: "food:whole",
    value: "1/1",
  },
  "1/2": {
    alignment: ALIGNMENT.LEFT,
    className: "btn--fill fill--left",
    count: 2,
    name: "food:left",
    value: "1/2",
  },
  "2/2": {
    alignment: ALIGNMENT.RIGHT,
    className: "btn--fill fill--right",
    count: 2,
    name: "food:right",
    value: "2/2",
  },
  "1/4": {
    alignment: ALIGNMENT.LEFT,
    className: "btn--fill fill--left",
    count: 4,
    name: "food:left",
    value: "1/4",
  },
  "2/4": {
    alignment: ALIGNMENT.LEFT,
    className: "btn--fill fill--left",
    count: 4,
    name: "food:top",
    value: "2/4",
  },
  "3/4": {
    alignment: ALIGNMENT.RIGHT,
    className: "btn--fill fill--right",
    count: 4,
    name: "food:right",
    value: "3/4",
  },
  "4/4": {
    alignment: ALIGNMENT.RIGHT,
    className: "btn--fill fill--right",
    count: 4,
    name: "food:bottom",
    value: "4/4",
  },
  "1/8": {
    alignment: ALIGNMENT.LEFT,
    className: "btn--fill fill--left",
    count: 8,
    name: "food:left",
    value: "1/8",
  },
  "2/8": {
    alignment: ALIGNMENT.LEFT,
    className: "btn--fill fill--left",
    count: 8,
    name: "food:top_left",
    value: "2/8",
  },
  "3/8": {
    alignment: ALIGNMENT.LEFT,
    className: "btn--fill fill--left",
    count: 8,
    name: "food:top",
    value: "3/8",
  },
  "4/8": {
    alignment: ALIGNMENT.RIGHT,
    className: "btn--fill fill--right",
    count: 8,
    name: "food:top_right",
    value: "4/8",
  },
  "5/8": {
    alignment: ALIGNMENT.RIGHT,
    className: "btn--fill fill--right",
    count: 8,
    name: "food:right",
    value: "5/8",
  },
  "6/8": {
    alignment: ALIGNMENT.RIGHT,
    className: "btn--fill fill--right",
    count: 8,
    name: "food:bottom_right",
    value: "6/8",
  },
  "7/8": {
    alignment: ALIGNMENT.RIGHT,
    className: "btn--fill fill--right",
    count: 8,
    name: "food:bottom",
    value: "7/8",
  },
  "8/8": {
    alignment: ALIGNMENT.LEFT,
    className: "btn--fill fill--left",
    count: 8,
    name: "food:bottom_left",
    value: "8/8",
  },
});

export const AREA_SORT = Object.freeze({
  "1/4": 1,
  "1/2": 2,
  "3/4": 3,
  "1/1": 4,
  "2/4": 5,
  "2/2": 6,
  "4/4": 7,
});

export const AREAS = {
  WHOLE: AREA["1/1"].value,
  LEFT: AREA["1/2"].value,
  RIGHT: AREA["2/2"].value,
};

export const HALF_AND_HALF_AREAS = [AREAS.LEFT, AREAS.RIGHT];

export const defaultAreas = Object.freeze([
  AREA["1/1"].value,
  AREA["1/2"].value,
  AREA["2/2"].value,
]);

export const [defaultArea] = defaultAreas; // "1/1"

const time = new Date().toLocaleTimeString([], {
  hour: "2-digit",
  minute: "2-digit",
});

export const recentOrders = Object.freeze({
  headings: [
    {
      key: "orderNumber",
      label: "customer:orders.order_number",
      type: "string",
    },
    {
      key: "dateTime",
      label: "customer:orders.date_time",
      type: "dateTime",
    },
    {
      key: "status",
      label: "customer:orders.status",
      type: "string",
    },
    {
      key: "serviceMethod",
      label: "customer:orders.service_method",
      type: "string",
    },
    {
      key: "orderMethod",
      label: "customer:orders.order_method",
      type: "string",
    },
    {
      key: "storeNumber",
      label: "customer:orders.store_number",
      type: "number",
    },
  ],
  subheadings: [
    {
      key: "start",
      label: "customer:orders.ordered",
      classes: "short",
    },
    {
      key: "prep",
      label: "customer:orders.prep",
      classes: "medium",
    },
    {
      key: "oven",
      label: "customer:orders.bake",
      classes: "long",
    },
    {
      key: "rack",
      label: "customer:orders.check",
      classes: "short",
    },
    {
      key: "delivery",
      label: "customer:orders.ready",
      classes: "medium",
    },
  ],
  body: [
    {
      orderNumber: "2017-11-3#1",
      dateTime: "11/3/2017 12:19",
      status: "Future Order",
      serviceMethod: "Delivery",
      orderMethod: "Phone",
      storeNumber: "9930",
      addresses: {
        store: "100 East Lake Drive, Phoenix City, AL 36870",
        customer: "123 Test Street, Ann Arbor, MI 48105",
      },
      timeStamps: [
        {
          stamp: "start",
          time,
          current: false,
          done: true,
        },
        {
          stamp: "prep",
          time,
          current: false,
          done: true,
        },
        {
          stamp: "oven",
          time,
          current: true,
          done: false,
        },
        {
          stamp: "rack",
        },
        {
          stamp: "delivery",
        },
      ],
      products: [
        {
          quantity: 1,
          name: 'Large (14") Pepperoni Pizza',
        },
        {
          quantity: 1,
          name: "Stuffed Cheesy Bread",
        },
      ],
    },
    {
      orderNumber: "2017-10-21#1",
      dateTime: "10/21/2017 18:24",
      status: "Closed",
      serviceMethod: "Delivery",
      orderMethod: "CCA",
      storeNumber: "9930",
      products: [
        {
          quantity: 1,
          name: 'Large (14") Pepperoni Pizza',
        },
        {
          quantity: 1,
          name: "Stuffed Cheesy Bread",
        },
      ],
    },
    {
      orderNumber: "2017-10-20#2",
      dateTime: "10/20/2017 14:19",
      status: "Closed",
      serviceMethod: "Carryout",
      orderMethod: "CCA",
      storeNumber: "9930",
    },
    {
      orderNumber: "2017-10-16#1",
      dateTime: "10/16/2017 12:19",
      status: "Closed",
      serviceMethod: "Carryout",
      orderMethod: "CCA",
      storeNumber: "9930",
    },
  ],
});

export default recentOrders;
