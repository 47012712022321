/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react";

import PropTypes from "prop-types";

import { modalBackground, modalContent } from "./Modal.styles";

const Modal = ({ open = false, children }) => {
  const refFocusableArea = useRef(null);

  useEffect(() => {
    if (refFocusableArea && open) {
      const focusableElementTypes = ["button", "input", "select"];
      const focusableElements = [
        ...refFocusableArea.current.querySelectorAll(focusableElementTypes),
      ];

      focusableElements.length > 0 && focusableElements[0].focus();
    }
  }, [refFocusableArea, open]);

  if (!open) {
    return null;
  }

  return (
    <div css={modalBackground}>
      <div
        ref={refFocusableArea}
        css={modalContent}
        className="modal-content"
        id="modal"
        role="dialog"
        aria-label="customer-selection-model"
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.element,
};
