/** @jsxImportSource @emotion/react */
import { Icons } from "@dpz/frankensteiner";
import { isValidNumber } from "libphonenumber-js";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  addInvalidCarsideValue,
  removeInvalidCarsideValue,
  selectInvalidCarsideValues,
  selectOptIntoTexts,
  selectPreferredLocation,
  selectTextPhoneNumber,
  selectVehicleColor,
  selectVehicleMake,
  selectVehicleModel,
  setPreferredLocation,
  setTextPhoneNumberValue,
  setVehicleColorValue,
  setVehicleMakeValue,
  setVehicleModelValue,
  toggleOptIntoTexts,
} from "rtk_redux/slices/carsideInformationSlice";
import { selectShowAllErrors } from "rtk_redux/slices/customerPageSlice";

import { CARSIDE_FIELDS } from "constants/carside";
import { COUNTRY_CODES } from "constants/market";
import { formatPhoneWhileTyping } from "modules/formatPhone";

import { DCDIcon, DCDMessage } from "components/Finish/Finish.styles";
import Select from "components/Select";

import { DCDMessageWrapper, customerFormRow } from "../NewCustomerPage.styles";

import FormField from "../utils/FormField";
import Switch from "../utils/Switch";
import { useHandleCustomerUpdate } from "../utils/hooks";
import { CarsideLookupTable } from "../utils/lookupTables";

const CarsideDeliveryForm = ({ t }) => {
  const dispatch = useDispatch();
  const vehicleMake = useSelector(selectVehicleMake);
  const vehicleModel = useSelector(selectVehicleModel);
  const vehicleColor = useSelector(selectVehicleColor);
  const preferredLocation = useSelector(selectPreferredLocation);
  const textPhoneNumber = useSelector(selectTextPhoneNumber);
  const optIntoTexts = useSelector(selectOptIntoTexts);
  const handleCustomerUpdate = useHandleCustomerUpdate();
  const invalidValues = useSelector(selectInvalidCarsideValues);
  const numericPhoneNumber = textPhoneNumber?.replace(/\D/g, "") || "";
  const showAllErrors = useSelector(selectShowAllErrors);

  const vehicleInfo = {
    [CARSIDE_FIELDS.VEHICLE_COLOR]: vehicleColor,
    [CARSIDE_FIELDS.VEHICLE_MODEL]: vehicleModel,
    [CARSIDE_FIELDS.VEHICLE_MAKE]: vehicleMake,
    [CARSIDE_FIELDS.PREFERRED_LOCATION]: preferredLocation,
    [CARSIDE_FIELDS.TEXT_PHONE_NUMBER]: numericPhoneNumber,
    saveVehicleInfo: true,
  };

  const handleProfileUpdate = (e) => {
    handleCustomerUpdate({
      vehicleInfo: {
        ...vehicleInfo,
        [e.target.id]: e.target.value,
      },
    });
    updateInvalidValues(e.target.id, checkValidity(e));
  };

  const updateInvalidValues = (id, isValid) => {
    isValid
      ? dispatch(removeInvalidCarsideValue(id))
      : dispatch(addInvalidCarsideValue(id));
  };

  const checkValidity = (e) => {
    const { id } = e.target;
    switch (id) {
      case CARSIDE_FIELDS.TEXT_PHONE_NUMBER:
        return isValidNumber(numericPhoneNumber, COUNTRY_CODES.UNITED_STATES);
      case CARSIDE_FIELDS.IS_TEXT_OPT_IN:
        return e.target.checked;
      default:
        return Boolean(e.target.value);
    }
  };

  const handlePhoneNumberChange = (e) => {
    dispatch(setTextPhoneNumberValue(formatPhoneWhileTyping(e.target.value)));
  };

  return (
    <form>
      {invalidValues.size ? (
        <div css={DCDMessageWrapper}>
          <p className="msg">
            <span css={DCDMessage}>
              <Icons.SAMDCDelivery css={DCDIcon} />
              {t("customer:locations.address.dcd_message")}
            </span>
          </p>
        </div>
      ) : null}
      <div css={customerFormRow}>
        <FormField
          t={t}
          label={t(CarsideLookupTable[CARSIDE_FIELDS.TEXT_PHONE_NUMBER].label)}
          id={CARSIDE_FIELDS.TEXT_PHONE_NUMBER}
          value={textPhoneNumber}
          required
          isInvalid={invalidValues.has(CARSIDE_FIELDS.TEXT_PHONE_NUMBER)}
          displayError={showAllErrors}
          handleBlur={handleProfileUpdate}
          handleChange={handlePhoneNumberChange}
        />
        <Switch
          t={t}
          id={CARSIDE_FIELDS.IS_TEXT_OPT_IN}
          checked={optIntoTexts}
          isInvalid={invalidValues.has(CARSIDE_FIELDS.IS_TEXT_OPT_IN)}
          displayError={showAllErrors}
          handleChange={(e) => {
            dispatch(toggleOptIntoTexts());
            handleProfileUpdate(e);
          }}
          label={t(CarsideLookupTable[CARSIDE_FIELDS.IS_TEXT_OPT_IN].label)}
        />
      </div>
      <div css={customerFormRow}>
        <Select
          t={t}
          id={CARSIDE_FIELDS.VEHICLE_MAKE}
          label={t(CarsideLookupTable[CARSIDE_FIELDS.VEHICLE_MAKE].label)}
          value={vehicleMake}
          isInvalid={invalidValues.has(CARSIDE_FIELDS.VEHICLE_MAKE)}
          required
          options={CarsideLookupTable[CARSIDE_FIELDS.VEHICLE_MAKE].options}
          displayError={showAllErrors}
          handleChange={(e) => {
            dispatch(setVehicleMakeValue(e.target.value));
            handleProfileUpdate(e);
          }}
        />
        <FormField
          t={t}
          label={t(CarsideLookupTable[CARSIDE_FIELDS.VEHICLE_MODEL].label)}
          id={CARSIDE_FIELDS.VEHICLE_MODEL}
          value={vehicleModel}
          isInvalid={invalidValues.has(CARSIDE_FIELDS.VEHICLE_MODEL)}
          required
          displayError={showAllErrors}
          handleBlur={handleProfileUpdate}
          handleChange={(e) => dispatch(setVehicleModelValue(e.target.value))}
        />
      </div>
      <div css={customerFormRow}>
        <Select
          t={t}
          label={t(CarsideLookupTable[CARSIDE_FIELDS.VEHICLE_COLOR].label)}
          id={CARSIDE_FIELDS.VEHICLE_COLOR}
          value={vehicleColor}
          required
          isInvalid={invalidValues.has(CARSIDE_FIELDS.VEHICLE_COLOR)}
          displayError={showAllErrors}
          options={CarsideLookupTable[CARSIDE_FIELDS.VEHICLE_COLOR].options}
          handleChange={(e) => {
            dispatch(setVehicleColorValue(e.target.value));
            handleProfileUpdate(e);
          }}
        />
        <Select
          t={t}
          id={CARSIDE_FIELDS.PREFERRED_LOCATION}
          options={
            CarsideLookupTable[CARSIDE_FIELDS.PREFERRED_LOCATION].options
          }
          label={t(CarsideLookupTable[CARSIDE_FIELDS.PREFERRED_LOCATION].label)}
          isInvalid={invalidValues.has(CARSIDE_FIELDS.PREFERRED_LOCATION)}
          required
          displayError={showAllErrors}
          value={preferredLocation}
          handleChange={(e) => {
            dispatch(setPreferredLocation(e.target.value));
            handleProfileUpdate(e);
          }}
        />
      </div>
    </form>
  );
};

export default CarsideDeliveryForm;

CarsideDeliveryForm.propTypes = {
  t: PropTypes.func.isRequired,
};
