import exists from "modules/exists";
import isDefined from "modules/isDefined";

export const formatAddressLine1 = ({
  unitNumber = "",
  streetNumber = "",
  streetName = "",
  neighborhood = "",
} = {}) => {
  const street = [
    isDefined(streetNumber) && streetNumber !== "0" && streetNumber,
    isDefined(unitNumber) && unitNumber,
    isDefined(streetName) && streetName,
  ]
    .filter(exists)
    .join(" ");
  return isDefined(neighborhood) && neighborhood
    ? `${street}, ${neighborhood}`
    : street;
};

export const formatStreetAddress = ({ street = "", unitNumber = "" } = {}) => {
  const streetAddress = [
    isDefined(street) && street,
    isDefined(unitNumber) && unitNumber,
  ]
    .filter(exists)
    .join(" ");
  return streetAddress;
};

export const formatComplexLine = ({
  organizationName = "",
  unitNumber = "",
} = {}) =>
  [
    isDefined(organizationName) && organizationName,
    isDefined(unitNumber) && unitNumber,
  ]
    .filter(exists)
    .join(", ");

export const formatAddressLine2 = ({ city, regionCode, postalCode }) => {
  let address = "";

  // fallback for null values
  city = city || "";
  regionCode = regionCode || "";
  postalCode = postalCode || "";

  if (city && regionCode) address = [city, regionCode].join(", ");
  else if (city) address = city;
  else if (regionCode) address = regionCode;

  address = `${address} ${postalCode}`;
  return address;
};

export const getAddressString = (address) =>
  [formatAddressLine1(address), formatAddressLine2(address)]
    .filter(exists)
    .join(", ");

export const buildAddressString = ({
  streetNumber = "",
  streetName = "",
  neighborhood = "",
  city = "",
} = {}) =>
  [
    formatAddressLine1({ streetNumber, streetName }),
    isDefined(neighborhood) && neighborhood,
    isDefined(city) && city,
  ]
    .filter(exists)
    .join(", ");

export default buildAddressString;
