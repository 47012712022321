import { combineEpics, ofType } from "redux-observable";
import { delay, map, mapTo, mergeMap, switchMap, take } from "rxjs/operators";

import { RESET_STATE } from "ducks/configuration";
import { getProductNameById } from "ducks/menu/selectors";
import {
  CREATE_ORDER_PRODUCT_SUCCESS,
  createOrderProduct,
} from "ducks/orderProduct";

import { sendTealiumUpsellProductAdded } from "rtk_redux/actions/tealium";

import { MESSAGE } from "constants/message";
import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

import { finishRoute } from "../../routes";

const SCOPE = "order-entry/waterfall-upsell/";

export const APPLY_WATERFALL_UPSELL = `${SCOPE}APPLY`;
export const RESUME_WATERFALL_UPSELL = `${SCOPE}RESUME`;
export const SET_WATERFALL_UPSELL = `${SCOPE}SET`;

export const applyWaterfallUpsell = withValidation(
  ({ upsellItems = [], orderId = "", url = "" } = {}) => ({
    upsellItems,
    orderId,
    type: APPLY_WATERFALL_UPSELL,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export const resumeWaterfallUpsell = withValidation(
  ({ upsellItems = [], orderId = "", url = "" } = {}) => ({
    upsellItems,
    orderId,
    type: RESUME_WATERFALL_UPSELL,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export function setWaterfallUpsellSeen({ seen = false } = {}) {
  return {
    type: SET_WATERFALL_UPSELL,
    seen,
  };
}

export const initialState = { seen: false };

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_WATERFALL_UPSELL:
      return { ...state, seen: action.seen };
    case RESET_STATE:
      return { ...initialState };
    default:
      return state;
  }
}

export const setWaterfallUpsellSeenEpic = (action$) =>
  action$.pipe(
    ofType(SET_WATERFALL_UPSELL),
    delay(300),
    map(() => finishRoute())
  );

export const applyWaterfallUpsellEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(APPLY_WATERFALL_UPSELL),
    mergeMap(({ upsellItems = [], url, orderId }) => {
      const state = getState();
      const [upsellItem, ...pendingUpsells] = upsellItems;
      const productName = getProductNameById(upsellItem?.productId)(state);
      const createProductActions = createOrderProduct({
        ...upsellItem,
        url,
        orderId,
      });
      if (upsellItem) {
        return [
          sendTealiumUpsellProductAdded({
            productName,
            quantity: upsellItem?.itemQuantity,
          }),
          resumeWaterfallUpsell({
            upsellItems: pendingUpsells,
            url,
            orderId,
          }),
          createProductActions,
        ];
      }
      return [setWaterfallUpsellSeen({ seen: true })];
    })
  );

export const resumeWaterfallUpsellEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(RESUME_WATERFALL_UPSELL),
    switchMap(({ upsellItems = [], url, orderId }) =>
      action$
        .ofType(CREATE_ORDER_PRODUCT_SUCCESS)
        .pipe(take(1), mapTo({ upsellItems, url, orderId }))
    ),
    mergeMap(({ upsellItems = [], url, orderId }) => {
      const state = getState();
      const [upsellItem, ...pendingUpsells] = upsellItems;
      const productName = getProductNameById(upsellItem?.productId)(state);
      const createProductActions = createOrderProduct({
        ...upsellItem,
        url,
        orderId,
      });
      if (upsellItem) {
        return [
          sendTealiumUpsellProductAdded({
            productName,
            quantity: upsellItem?.itemQuantity,
          }),
          resumeWaterfallUpsell({
            upsellItems: pendingUpsells,
            url,
            orderId,
          }),
          createProductActions,
        ];
      }
      return [setWaterfallUpsellSeen({ seen: true })];
    })
  );

export const epics = combineEpics(
  applyWaterfallUpsellEpic,
  resumeWaterfallUpsellEpic,
  setWaterfallUpsellSeenEpic
);
