import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import {
  catchError,
  filter,
  mergeMap,
  pluck,
  switchMap,
  throttleTime,
} from "rxjs/operators";

import {
  getMarketConfigs,
  getNewExperienceFeature,
} from "ducks/market/selectors";
import {
  CREATE_ORDER_COUPON_SUCCESS,
  DELETE_ORDER_COUPON_SUCCESS,
  updateOrderCouponFulfiller,
  updatePriceOrderCoupon,
} from "ducks/orderCoupon";
import { getOrderCoupons } from "ducks/orderCoupon/selectors";
import {
  CREATE_ORDER_PRODUCT_SUCCESS,
  DELETE_ORDER_PRODUCT_SUCCESS,
  UPDATE_ORDER_PRODUCT_SUCCESS,
} from "ducks/orderProduct";
import { getDataIdsFromRelationship, getSelfLink } from "selectors/related";

import { ERROR_PREFIX, MESSAGE } from "constants/message";
import { ORDER_META } from "constants/order";
import withValidation from "modules/elValidadore";
import { buildErrorFromStatusItems } from "modules/errorMessageHelpers";
import { idValidators, urlValidators } from "modules/validators";

import { getCurrentOrder, getCurrentOrderId } from "../selectors";

const SCOPE = "order-entry/order/";

export const VALIDATE_ORDER = `${SCOPE}VALIDATE_ORDER`;
export const VALIDATE_ORDER_ERROR = `${SCOPE}VALIDATE_ORDER_ERROR`;

export const validateOrder = withValidation(
  ({ orderId = null, url = "" } = {}) => ({
    type: VALIDATE_ORDER,
    orderId,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export function validateOrderError(error) {
  return {
    type: VALIDATE_ORDER_ERROR,
    error,
  };
}

export const validateOnOrderModifiedEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(
      CREATE_ORDER_COUPON_SUCCESS,
      CREATE_ORDER_PRODUCT_SUCCESS,
      DELETE_ORDER_COUPON_SUCCESS,
      DELETE_ORDER_PRODUCT_SUCCESS,
      UPDATE_ORDER_PRODUCT_SUCCESS
    ),
    filter(() => {
      const state = getState();
      const currentOrderId = getCurrentOrderId(state);
      return (
        !!currentOrderId &&
        !!Object.values(getOrderCoupons(state) || {}).filter(
          ({ orderId }) => orderId === currentOrderId
        ).length
      );
    }),
    throttleTime(500),
    switchMap(() => {
      const state = getState();
      const orderId = getCurrentOrderId(state);
      const { skipCombineLikeProducts } = getNewExperienceFeature(state);
      const url =
        `${getSelfLink(getCurrentOrder(state))}:validate` +
        (skipCombineLikeProducts ? "?combineLikeProducts=false" : "");

      return of(
        validateOrder({
          url,
          orderId,
        })
      );
    })
  );

export const validateOrderEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(VALIDATE_ORDER),
    mergeMap((action) => {
      const state = getState();
      const { SOURCE_ORG_URI } = getMarketConfigs(state);
      return fetch(
        Object.assign(action, {
          body: {
            data: {
              attributes: {
                ...ORDER_META,
                sourceOrgUri: SOURCE_ORG_URI,
              },
            },
          },
        }),
        {
          method: "PUT",
        }
      ).pipe(
        pluck("response", "data"),
        mergeMap(({ status, statusItems, ...order }) => {
          if (status < 0)
            return of(
              validateOrderError(
                buildErrorFromStatusItems({
                  statusItems,
                  errorPrefix: ERROR_PREFIX.PRICE_ORDER,
                })
              )
            );

          const state = getState();
          const { orderCoupon } = state;
          const currentOrderId = getCurrentOrderId(state);
          const { fulfillerProductHolders = {} } =
            order.attributes.fulfiller || {};

          const getUnfulfilledGroups = (orderCouponId) => {
            const couponGroups = fulfillerProductHolders[orderCouponId] || [];

            return couponGroups.map(
              ({ messages: { fulfillerProductGroups = {} } = {} }) =>
                Object.values(fulfillerProductGroups)
            );
          };

          const orderOrderCouponIds =
            getDataIdsFromRelationship("coupons")(order);

          const coupons = Object.values(getOrderCoupons(state) || {})
            .filter(({ orderId }) => orderId === currentOrderId)
            .map(({ orderCouponId }) => ({
              orderCouponId,
              unfulfilledGroups: getUnfulfilledGroups(orderCouponId),
            }));

          return coupons
            .map(({ orderCouponId, unfulfilledGroups }) =>
              updateOrderCouponFulfiller({
                orderCouponId,
                unfulfilledGroups,
              })
            )
            .concat(
              orderOrderCouponIds.map((orderCouponId) =>
                updatePriceOrderCoupon({
                  url: getSelfLink(orderCoupon[orderCouponId]),
                })
              )
            );
        }),
        catchError((error) => of(validateOrderError(error)))
      );
    })
  );
