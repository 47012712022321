import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, filter, map, mergeMap, pluck } from "rxjs/operators";

import { setMessage } from "ducks/message";
import { getOrder } from "ducks/order";
import { getLinkFromRelationship } from "selectors/related";

import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

const SCOPE = "order-entry/agent/";

export const GET_AGENT = `${SCOPE}GET_AGENT`;
export const GET_AGENT_ERROR = `${SCOPE}GET_AGENT_ERROR`;
export const SET_AGENT = `${SCOPE}SET_AGENT`;
export const SET_AGENT_HAS_ORDER = `${SCOPE}SET_AGENT_HAS_ORDER`;

export const getAgent = withValidation(
  ({ url = "" } = {}) => ({
    type: GET_AGENT,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function getAgentError(error) {
  return {
    type: GET_AGENT_ERROR,
    error,
  };
}

export const setAgent = withValidation(
  ({
    agentId = null,
    displayName = "",
    emailAddress = "",
    links = {},
    relationships = {},
    username = "",
  } = {}) => ({
    type: SET_AGENT,
    agentId,
    displayName,
    emailAddress,
    links,
    relationships,
    username,
  }),
  {
    agentId: idValidators,
  }
);

export function setAgentHasOrder(hasOrder = false) {
  return {
    type: SET_AGENT_HAS_ORDER,
    hasOrder,
  };
}

export const initialState = {
  agentId: "",
  displayName: "",
  emailAddress: "",
  hasOrder: false,
  links: {},
  relationships: {},
  username: "",
};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_AGENT:
    case SET_AGENT_HAS_ORDER:
      return Object.assign({}, state, action);
    default:
      return state;
  }
}

export const getAgentEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_AGENT),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "data"),
        map(
          ({
            attributes: { displayName, emailAddress, username },
            id,
            links,
            relationships,
          }) =>
            setAgent({
              agentId: id,
              displayName,
              emailAddress,
              links,
              relationships,
              username,
            })
        ),
        catchError((error) => of(getAgentError(error)))
      )
    )
  );

export const setAgentEpic = (action$) =>
  action$.pipe(
    ofType(SET_AGENT),
    map((agent) =>
      getOrder({
        agent: true, //temp: rework to be agent agnostic when service can comply
        url: getLinkFromRelationship("order")(agent),
      })
    )
  );

const ifHasOrder = (hasOrder) => hasOrder;

export const setAgentHasOrderEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(SET_AGENT_HAS_ORDER),
    pluck("hasOrder"),
    filter(ifHasOrder),
    map(getState),
    pluck("agent", "displayName"),
    map((name) =>
      setMessage({
        interpolation: {
          name: name ? `, ${name}` : "",
        },
        message: "application:agent_order_loaded",
      })
    )
  );

export const epics = combineEpics(
  getAgentEpic,
  setAgentEpic,
  setAgentHasOrderEpic
);
