import React from "react";

import PropTypes from "prop-types";

import { AREA, defaultArea } from "constants/order";
import exists from "modules/exists";

const OrderLineItem = ({
  isCoupon,
  description,
  itemNumber,
  quantity,
  options,
  t,
  weightTranslations,
}) => (
  <li
    className={[
      "list__item order-description__item",
      isCoupon && "order-description__coupon",
    ]
      .filter(exists)
      .join(" ")}
    data-quid={
      isCoupon
        ? `order-description-coupon-${itemNumber}`
        : `order-description-item-${itemNumber}`
    }
  >
    <div className="order-line-item">
      {isCoupon && <span data-icon="local_offer" />}
      {quantity && <span className="item__quantity">{quantity}</span>}
      <div className="item__description">
        <span className="item__description__name">{description}</span>
        {options
          .filter(({ options: areaOptions = [] }) => areaOptions.length)
          .map(({ part = defaultArea, options: areaOptions = [] }) => (
            <span className="item__options" key={`${part}`}>
              {t(AREA[part].name)}:{" "}
              {areaOptions
                .map(({ name: optionName, weight = 1 }) =>
                  `${
                    weight !== 1
                      ? t(`food:toppings:${weightTranslations[weight]}`)
                      : ""
                  } ${optionName}`.trim()
                )
                .join(",")}
            </span>
          ))}
      </div>
    </div>
  </li>
);

OrderLineItem.propTypes = {
  description: PropTypes.string,
  isCoupon: PropTypes.bool,
  itemNumber: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          name: PropTypes.string,
          weight: PropTypes.number,
        })
      ),
    })
  ),
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  t: PropTypes.func.isRequired,
  weightTranslations: PropTypes.objectOf(PropTypes.string),
};

OrderLineItem.defaultProps = {
  description: "",
  isCoupon: false,
  quantity: "",
  options: [],
  weightTranslations: Object.freeze({
    0: "no",
    0.5: "light",
    1: "normal",
    1.5: "extra",
    2: "double",
    3: "triple",
  }),
};

export default OrderLineItem;
