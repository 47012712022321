import React, { Fragment } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ROUTE_TITLES } from "routes";

import { getNewExperienceFeatureOptions } from "ducks/market/selectors";
import {
  getActiveOrderProduct,
  getActiveOrderProductAvailableSizes,
} from "ducks/order/selectors";
import { resetActiveOrderProduct } from "ducks/orderProduct";

import {
  STJUDE_CHECKOUT_ROUND_UP_ENABLED,
  STJUDE_ENABLED,
} from "constants/launchDarkly";
import { PASTA_CATEGORY, STJUDE_ROUND_UP_SIZE_CODE } from "constants/menu";
import { OPTION, defaultArea } from "constants/order";
import {
  filterStJudeProduct,
  filterStJudeRoundUpSize,
} from "modules/filterStJude";
import hasProps from "modules/hasProps";
import uuid from "modules/uuid";

import Base from "components/Base";
import Section from "components/EverythingElse/Section";
import Option from "components/Option";
import Page from "components/Page";
import PastaSauceButton from "components/PastaSauceButton";
import Product from "components/Product";
import Size from "components/Size";
import Tabs from "components/Tabs";

const FilteredBases = ({
  bases,
  products,
  setOrderProduct,
  activeOrderProductBaseId,
}) => {
  const newBases = bases.map((base) => {
    const productId = products.find((product) =>
      product.baseIds.includes(base.baseId)
    ).productId;

    return { ...base, productId };
  });

  return newBases.map((base) => (
    <Base
      key={base.baseId}
      activeOrderProductBaseId={activeOrderProductBaseId}
      setOrderProduct={setOrderProduct}
      swapProductsForBases={true}
      {...base}
    />
  ));
};

const NewEverythingElse = ({
  activeArea = defaultArea,
  activeOrderProductAvailableOptionsByArea = {},
  activeOrderProductOptionIds = [],
  activeOrderProductProducts = {},
  activeOrderProductSides = {},
  activeOrderProductBaseId = "",
  activeOrderProductSizeId = "",
  activeOrderProductToppings = {},
  currentCategoryId = "",
  deleteOrderProductOption,
  goToEverythingElse = () => {},
  hasToppings = false,
  bases = [],
  meats = [],
  mergeBasesAndProducts = false,
  nonMeats = [],
  products = [],
  sauces = [],
  setOrderProduct,
  sidebar = null,
  sides = [],
  sizes = [],
  t = () => {},
  tabs = [
    {
      active: true,
      id: uuid(),
      path: "everything-else",
      text: "Everything Else",
    },
  ],
  title = "",
}) => {
  const {
    [STJUDE_ENABLED]: stJudeEnabled,
    [STJUDE_CHECKOUT_ROUND_UP_ENABLED]: stJudeRoundUpEnabled,
  } = useFlags();
  const dispatch = useDispatch();

  const {
    showOnlyActiveOrderProductAvailableSizesForCategoryIds,
    swapProductsForBasesCategoryIds,
  } = useSelector(getNewExperienceFeatureOptions);
  const swapProductsForBases =
    swapProductsForBasesCategoryIds.includes(currentCategoryId);

  const activeOrderProduct = useSelector(getActiveOrderProduct);

  const activeOrderProductAvailableSizes = useSelector(
    getActiveOrderProductAvailableSizes
  );

  const {
    basesProductMergeExceptionIds,
    everythingElseSizeTooltipMessages,
    hideSizesForCategoryIds,
    utilizeCustomSideQuantityMaxForCategoryIds,
    customSideQuantityMax,
  } = useSelector(getNewExperienceFeatureOptions);

  const activeOrderProductAvailableSizeCodes =
    activeOrderProductAvailableSizes.map(({ sizeCode }) => sizeCode);
  const activeOrderProductSelected = hasProps(activeOrderProduct);

  const tabbar = <Tabs goTo={goToEverythingElse} t={t} tabs={tabs} />;

  const setOrderProductAndResetToppings = (orderProduct) =>
    setOrderProduct(orderProduct, { resetToppings: true });

  const actions = (
    <button
      className="btn btn--fill btn--primary"
      data-icon="add"
      data-quid="add-product"
      disabled={!activeOrderProductSelected}
      onClick={() => dispatch(resetActiveOrderProduct())}
    >
      {t("shared:action.add_to_order")}
    </button>
  );

  const activeOptionProps = {
    activeArea,
    activeOrderProductAvailableOptions:
      activeOrderProductAvailableOptionsByArea,
    activeOrderProductOptionIds,
    activeOrderProductOptions: Object.assign(
      {},
      activeOrderProductToppings,
      activeOrderProductSides
    ),
  };

  const optionActions = { deleteOrderProductOption, setOrderProduct };

  const getSizeTooltipMessage = (size) => {
    const tooltipMessage = everythingElseSizeTooltipMessages.find(
      ({ categoryId, sizeCode }) =>
        categoryId === currentCategoryId && sizeCode === size.sizeCode
    );

    return tooltipMessage ? t(tooltipMessage.message) : null;
  };

  const showOnlyActiveOrderProductAvailableSizes =
    showOnlyActiveOrderProductAvailableSizesForCategoryIds.includes(
      currentCategoryId
    );

  const productsFilteringStJude = stJudeEnabled
    ? products
    : products.filter(filterStJudeProduct);

  const sizesFilteringStJudeRoundUp = stJudeRoundUpEnabled
    ? activeOrderProductAvailableSizes
    : activeOrderProductAvailableSizes.filter(filterStJudeRoundUpSize);

  const showSizes = !hideSizesForCategoryIds.includes(currentCategoryId);

  const utilizeCustomSideQuantityMax =
    utilizeCustomSideQuantityMaxForCategoryIds.includes(currentCategoryId);
  const customSideQuantityRange = utilizeCustomSideQuantityMax
    ? new Array(customSideQuantityMax + 1).fill().map((_, i) => i)
    : [];

  return (
    <Page
      footer={actions}
      className="text--right"
      id="everything-else"
      sidebar={sidebar}
      tabs={tabbar}
      title={title}
      seoTitle={ROUTE_TITLES.EVERYTHING_ELSE}
    >
      {(!mergeBasesAndProducts ||
        basesProductMergeExceptionIds.includes(currentCategoryId)) &&
        !swapProductsForBases &&
        bases.length > 0 && (
          <Section heading={t("food:bases")}>
            <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
              {bases.map((base) => (
                <Base
                  activeOrderProductBaseId={activeOrderProductBaseId}
                  key={base.baseId}
                  setOrderProduct={setOrderProduct}
                  {...base}
                />
              ))}
            </div>
          </Section>
        )}

      {!swapProductsForBases && products.length > 0 && (
        <Section heading={t("food:products")}>
          <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
            {productsFilteringStJude.map((product) => (
              <Product
                activeOrderProductProducts={activeOrderProductProducts}
                key={product.productId}
                mergeBasesAndProducts={mergeBasesAndProducts}
                addSize={true}
                setOrderProduct={setOrderProductAndResetToppings}
                {...product}
              />
            ))}
          </div>
        </Section>
      )}

      {swapProductsForBases && bases.length > 0 && (
        <Section heading={t("food:products")}>
          <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
            <FilteredBases
              activeOrderProductBaseId={activeOrderProductBaseId}
              bases={bases}
              products={products}
              setOrderProduct={setOrderProduct}
            />
          </div>
        </Section>
      )}

      {showSizes && sizesFilteringStJudeRoundUp.length > 0 && (
        <Section heading={t("food:sizes")}>
          <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
            {(showOnlyActiveOrderProductAvailableSizes
              ? sizesFilteringStJudeRoundUp
              : sizes
            ).map((size) => (
              <Size
                activeOrderProductSizeId={activeOrderProductSizeId}
                key={size.sizeId}
                setOrderProduct={setOrderProduct}
                disabled={
                  !activeOrderProductAvailableSizeCodes.includes(size.sizeCode)
                }
                tooltipMessage={getSizeTooltipMessage(size)}
                {...size}
                {...(size.sizeId === STJUDE_ROUND_UP_SIZE_CODE
                  ? { sizeName: t("stjude:round_up") }
                  : {})}
              />
            ))}
          </div>
        </Section>
      )}

      {hasToppings && (
        <Section heading={t("food:toppings.title")}>
          {meats.length > 0 && (
            <Fragment>
              <header className="title-group">
                <h3 data-quid={`heading-meats`}>{t("food:toppings.meats")}</h3>
              </header>

              <div className="btn-group btn-group--justified btn-group--unlimited">
                {meats.map(
                  ({
                    toppingCode,
                    toppingDescription,
                    toppingId,
                    toppingName,
                  }) => (
                    <Option
                      key={toppingId}
                      optionCode={toppingCode}
                      optionDescription={toppingDescription}
                      optionId={toppingId}
                      optionName={toppingName}
                      optionType={OPTION.TOPPING}
                      {...optionActions}
                      {...activeOptionProps}
                    />
                  )
                )}
              </div>

              <br />
            </Fragment>
          )}

          {nonMeats.length > 0 && (
            <Fragment>
              <header className="title-group">
                <h3 data-quid={`heading-non-meats`}>
                  {t("food:toppings.non_meats")}
                </h3>
              </header>

              <div className="btn-group btn-group--justified btn-group--unlimited">
                {nonMeats.map(
                  ({
                    toppingCode,
                    toppingDescription,
                    toppingId,
                    toppingName,
                  }) => (
                    <Option
                      key={toppingId}
                      optionCode={toppingCode}
                      optionDescription={toppingDescription}
                      optionId={toppingId}
                      optionName={toppingName}
                      optionType={OPTION.TOPPING}
                      {...optionActions}
                      {...activeOptionProps}
                    />
                  )
                )}
              </div>

              <br />
            </Fragment>
          )}

          {sauces.length > 0 && (
            <Fragment>
              <header className="title-group">
                <h3 data-quid={`heading-sauces`}>
                  {t("food:toppings.sauces")}
                </h3>
              </header>

              {currentCategoryId === PASTA_CATEGORY ? (
                <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
                  {sauces.map(({ toppingCode, toppingId, toppingName }) => (
                    <PastaSauceButton
                      key={toppingId}
                      sauces={sauces}
                      sauceName={toppingName}
                      sauceId={toppingId}
                      sauceToppingCode={toppingCode}
                      onlyOneAvailableSauce={sauces.length === 1}
                    />
                  ))}
                </div>
              ) : (
                <div className="btn-group btn-group--justified btn-group--unlimited">
                  {sauces.map(
                    ({
                      toppingCode,
                      toppingDescription,
                      toppingId,
                      toppingName,
                    }) => (
                      <Option
                        key={toppingId}
                        optionCode={toppingCode}
                        optionDescription={toppingDescription}
                        optionId={toppingId}
                        optionName={toppingName}
                        optionType={OPTION.TOPPING}
                        {...optionActions}
                        {...activeOptionProps}
                      />
                    )
                  )}
                </div>
              )}

              <br />
            </Fragment>
          )}
        </Section>
      )}

      {activeOrderProductSelected && sides.length > 0 && (
        <Section heading={t("food:sides")}>
          <div className="btn-group btn-group--justified btn-group--unlimited">
            {sides.map(({ sideCode, sideDescription, sideId, sideName }) => (
              <Option
                key={sideId}
                optionCode={sideCode}
                optionDescription={sideDescription}
                optionId={sideId}
                optionName={sideName}
                optionType={OPTION.SIDE}
                disabled={
                  !Object.keys(
                    activeOrderProductAvailableOptionsByArea
                  ).includes(sideCode)
                }
                {...optionActions}
                {...activeOptionProps}
                customSideQuantityRange={customSideQuantityRange}
              />
            ))}
          </div>
        </Section>
      )}
    </Page>
  );
};

NewEverythingElse.propTypes = {
  activeArea: PropTypes.string,
  activeOrderProductAvailableOptionsByArea: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductOptionIds: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductProducts: PropTypes.objectOf(PropTypes.string),
  activeOrderProductSides: PropTypes.objectOf(PropTypes.number),
  activeOrderProductBaseId: PropTypes.string,
  activeOrderProductSizeId: PropTypes.string,
  activeOrderProductToppings: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.number)
  ),
  currentCategoryId: PropTypes.string,
  deleteOrderProductOption: PropTypes.func.isRequired,
  goToEverythingElse: PropTypes.func,
  hasToppings: PropTypes.bool,
  bases: PropTypes.arrayOf(PropTypes.object),
  meats: PropTypes.arrayOf(PropTypes.object),
  mergeBasesAndProducts: PropTypes.bool,
  nonMeats: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  sauces: PropTypes.arrayOf(PropTypes.object),
  setOrderProduct: PropTypes.func.isRequired,
  sidebar: PropTypes.node,
  sides: PropTypes.arrayOf(PropTypes.object),
  sizes: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      icon: PropTypes.string,
      id: PropTypes.string,
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};

export default NewEverythingElse;
