import { getCountry, getCountryByAbbreviation } from "country-currency-map";

export default (countryCode = "ZA", amount = "0", showDecimals = true) => {
  const { currency } = getCountry(getCountryByAbbreviation(countryCode));

  return parseFloat(amount).toLocaleString("en", {
    currency,
    style: "currency",
    ...(!showDecimals && {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
  });
};
