/** @jsxImportSource @emotion/react */
import { PureComponent } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Nav.styles";

class Nav extends PureComponent {
  render() {
    const {
      children,
      className,
      goToHome,
      hasSidebar,
      showSidebar,
      title,
      toggleSidebar,
    } = this.props;

    const doHardRefresh = (evt) => {
      evt.preventDefault();
      goToHome();
      window.location.reload();
    };

    return (
      <div css={styles} className={classNames(className, "sticky nav--header")}>
        <div className="nav__section">
          <button
            className={classNames(
              "nav__item nav__item--button nav__item--button--sidebar-toggle",
              !hasSidebar && "is-hidden"
            )}
            data-hook="toggle-nav"
            data-icon={showSidebar ? "close" : "menu"}
            onClick={toggleSidebar}
            title={showSidebar ? "Open Sidebar" : "Close Sidebar"}
            type="button"
          />
          <h1 className="nav__item nav__item--logo">
            <a
              data-quid="do-hard-refresh"
              href="#reload"
              onClick={doHardRefresh}
              title={title}
            >
              <span className="is-hidden@handheld">{title}</span>
            </a>
          </h1>
        </div>

        <div className="nav__section">{children}</div>
      </div>
    );
  }
}

Nav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  goToHome: PropTypes.func,
  hasSidebar: PropTypes.bool,
  showSidebar: PropTypes.bool,
  title: PropTypes.string,
  toggleSidebar: PropTypes.func,
};

Nav.defaultProps = {
  children: null,
  className: "",
  goToHome: () => {},
  hasSidebar: false,
  showSidebar: false,
  title: "Domino's",
  toggleSidebar: () => {},
};

export default Nav;
