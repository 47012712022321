/** @jsxImportSource @emotion/react */
// None of the arrays with change and this is display only, so it's safe to use index

/* eslint-disable react/no-array-index-key */
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { reorder } from "ducks/order";

import { setStoreQueryLastArgs } from "rtk_redux/slices/customerPageSlice";
import { setCustomerZipcode } from "rtk_redux/slices/serviceMethodSlice";

import { SERVICE_METHOD } from "constants/order";
import isDelivery from "modules/isDelivery";

import ExpandableToggleIcon from "components/ExpandableToggleIcon";
import ExpandedClickableArea from "components/ExpandedClickableArea";

import {
  addressSection,
  fadeTransition,
  footerStyles,
  itemsSection,
  orderInfoSection,
  orderWrapper,
  topSection,
} from "./NewOrderHistory.styles";

import OrderProduct from "./OrderProduct";
import {
  formatDateTime,
  formatDeliveryAddress,
  formatStoreAddress,
} from "./orderHistoryHelpers";

const Order = ({
  t,
  index,
  order,
  currentOrderId,
  expandedOrderId,
  handleExpandOrder,
}) => {
  const dispatch = useDispatch();
  const isDeliveryOrder = isDelivery(order.serviceMethod);

  // Doing it this way instead of nth-child so that the transition gradient will correctly match the div
  // when the component is collapsed
  const isOddNumber = index % 2 !== 0;

  const isExpanded = expandedOrderId === order.id;

  const placeReorder = () => {
    dispatch(
      reorder({
        currentOrderId,
        url: order.reorderLink,
      })
    );

    if (order.serviceMethod === SERVICE_METHOD.DCD) {
      dispatch(setCustomerZipcode(order.store.postalCode));
      dispatch(
        setStoreQueryLastArgs({
          type: SERVICE_METHOD.DCD,
          s: "",
          c: order.store.postalCode,
        })
      );
    }
  };

  const formatProducts = (products) =>
    products.map((product, index) => (
      <OrderProduct key={index} product={product} t={t} />
    ));

  const formatCoupons = (coupons) =>
    coupons.map((cpn, index) => (
      <p key={index}>
        <span data-icon="local_offer" />
        {cpn}
      </p>
    ));

  return (
    <div
      css={orderWrapper}
      className={classNames(
        isExpanded ? "expanded" : "hidden",
        isOddNumber && "alternateRow"
      )}
    >
      <ExpandedClickableArea css={topSection}>
        <>
          <div css={orderInfoSection}>
            <p className="date-or-easy">
              {order.easyOrder
                ? t("customer:orders:order_history.easy_order")
                : formatDateTime(order.orderDateTime)}
            </p>
            <p>{order.serviceMethod}</p>
            {!isDeliveryOrder && <p>{order.store.storeName}</p>}
          </div>
          {isExpanded && (
            <div css={addressSection}>
              {isDeliveryOrder && formatDeliveryAddress(order, t)}
              {formatStoreAddress(order, t)}
            </div>
          )}
          <div css={itemsSection}>
            {formatProducts(order.products)}
            <div className="coupons">{formatCoupons(order.orderCoupons)}</div>
          </div>
          <ExpandableToggleIcon
            onClick={() => handleExpandOrder(order.id)}
            t={t}
            isExpanded={isExpanded}
          />
        </>
      </ExpandedClickableArea>
      {isExpanded ? (
        <div css={footerStyles}>
          <button className="btn btn--primary" onClick={placeReorder}>
            {t("customer:orders:order_history:add_to_order")}
          </button>
        </div>
      ) : (
        <div
          css={fadeTransition}
          className={classNames(isOddNumber && "alternateRow")}
        />
      )}
    </div>
  );
};

export default Order;

Order.propTypes = {
  t: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  index: PropTypes.number,
  currentOrderId: PropTypes.string.isRequired,
  expandedOrderId: PropTypes.string,
  handleExpandOrder: PropTypes.func,
};
