import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/empty";
import { of } from "rxjs/observable/of";
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  pluck,
  take,
} from "rxjs/operators";

import { RESET_STATE } from "ducks/configuration";
import { getCurrentLanguage } from "ducks/configuration/selectors";
import { getCoupons } from "ducks/coupons/selectors";
import { getCheeseToppings, getCouponById } from "ducks/menu/selectors";
import { updateOrder } from "ducks/order";
import {
  getCurrentOrderProducts,
  getExtraCheeseAvailableSlots,
} from "ducks/order/selectors";
import { isFulfilled } from "ducks/orderCoupon/helpers";
import { getOrderCoupons } from "ducks/orderCoupon/selectors";
import { updateOrderProduct } from "ducks/orderProduct";
import { getCurrentStoreId } from "ducks/store/selectors";
import { getVariantCodeFromProduct } from "ducks/variants/selectors";

import { MESSAGE } from "constants/message";
import { RESOURCE_TYPE } from "constants/resource";
import withValidation from "modules/elValidadore";
import { buildErrorFromStatusItems } from "modules/errorMessageHelpers";
import {
  idValidators,
  stringValidators,
  urlValidators,
} from "modules/validators";

const SCOPE = "order-entry/orderCoupon/";

export const GET_COUPON_DETAILS = `${SCOPE}GET_COUPON_DETAILS`;
export const GET_COUPON_DETAILS_SUCCESS = `${SCOPE}GET_COUPON_DETAILS_SUCCESS`;
export const SET_GET_COUPON_DETAILS = `${SCOPE}SET_GET_COUPON_DETAILS`;
export const UPDATE_COUPON_DATA = `${SCOPE}UPDATE_COUPON_DATA`;

export const CREATE_ORDER_COUPON = `${SCOPE}CREATE_ORDER_COUPON`;
export const CREATE_ORDER_COUPON_ERROR = `${SCOPE}CREATE_ORDER_COUPON_ERROR`;
export const CREATE_ORDER_COUPON_SUCCESS = `${SCOPE}CREATE_ORDER_COUPON_SUCCESS`;
export const UPDATE_ORDER_COUPON = `${SCOPE}UPDATE_ORDER_COUPON`;
export const UPDATE_ORDER_COUPON_ERROR = `${SCOPE}UPDATE_ORDER_COUPON_ERROR`;
export const UPDATE_ORDER_COUPON_FULFILLER = `${SCOPE}UPDATE_ORDER_COUPON_FULFILLER`;
export const UPDATE_ORDER_COUPON_SUCCESS = `${SCOPE}UPDATE_ORDER_COUPON_SUCCESS`;
export const UPDATE_PRICE_ORDER_COUPON = `${SCOPE}UPDATE_PRICE_ORDER_COUPON`;
export const UPDATE_PRICE_ORDER_COUPON_SUCCESS = `${SCOPE}UPDATE_PRICE_ORDER_COUPON_SUCCESS`;
export const DELETE_ORDER_COUPON = `${SCOPE}DELETE_ORDER_COUPON`;
export const DELETE_ORDER_COUPON_ERROR = `${SCOPE}DELETE_ORDER_COUPON_ERROR`;
export const DELETE_ORDER_COUPON_SUCCESS = `${SCOPE}DELETE_ORDER_COUPON_SUCCESS`;

export const getCouponDetails = withValidation(
  ({ language = "en", storeId, couponCode, isActiveOrder } = {}) => ({
    type: GET_COUPON_DETAILS,
    language,
    power: true,
    storeId,
    couponCode,
    isActiveOrder,
    url: `/power/store/${storeId}/coupon/${couponCode}`,
  }),
  {
    storeId: idValidators,
  }
);

export const updateCouponData = withValidation(
  ({ Code = "", ...action } = {}) => ({
    ...action,
    type: UPDATE_COUPON_DATA,
    Code,
  }),
  { Code: stringValidators }
);

export const createOrderCoupon = withValidation(
  ({
    couponCode = "",
    description = "",
    name = "",
    orderId = null,
    orderCouponId = null,
    url = "",
  } = {}) => ({
    type: CREATE_ORDER_COUPON,
    couponCode,
    description,
    name,
    orderCouponId,
    orderId,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export function createOrderCouponError(error) {
  return {
    type: CREATE_ORDER_COUPON_ERROR,
    error,
  };
}

export const createOrderCouponSuccess = withValidation(
  ({
    couponCode = "",
    description = "",
    fulfilled = false,
    isPastOrder = false,
    links = {},
    name = "",
    orderCouponId = null,
    orderId = null,
    relationships = {},
    storeId = null,
  } = {}) => ({
    couponCode,
    description,
    fulfilled,
    isPastOrder,
    links,
    name,
    orderCouponId,
    orderId,
    relationships,
    storeId,
    type: CREATE_ORDER_COUPON_SUCCESS,
  }),
  {
    orderCouponId: idValidators,
    orderId: idValidators,
  }
);

export const updateOrderCoupon = withValidation(
  ({ url = "" } = {}) => ({
    type: UPDATE_ORDER_COUPON,
    url,
  }),
  { url: urlValidators }
);

export const updatePriceOrderCoupon = withValidation(
  ({ url = "" } = {}) => ({
    type: UPDATE_PRICE_ORDER_COUPON,
    url,
  }),
  { url: urlValidators }
);

export function updateOrderCouponError(error) {
  return {
    type: UPDATE_ORDER_COUPON_ERROR,
    error,
  };
}

export const updateOrderCouponSuccess = withValidation(
  ({ orderCouponId = null, ...orderCoupon } = {}) =>
    Object.assign({}, orderCoupon, {
      type: UPDATE_ORDER_COUPON_SUCCESS,
      orderCouponId,
    }),
  {
    orderCouponId: idValidators,
  }
);

export const updatePriceOrderCouponSuccess = withValidation(
  ({ orderCouponId = null, ...orderCoupon } = {}) =>
    Object.assign({}, orderCoupon, {
      type: UPDATE_PRICE_ORDER_COUPON_SUCCESS,
      orderCouponId,
    }),
  {
    orderCouponId: idValidators,
  }
);
export const updateOrderCouponFulfiller = withValidation(
  ({ orderCouponId = null, ...orderCoupon } = {}) =>
    Object.assign({}, orderCoupon, {
      type: UPDATE_ORDER_COUPON_SUCCESS,
      orderCouponId,
    }),
  {
    orderCouponId: idValidators,
  }
);

export const deleteOrderCoupon = withValidation(
  ({ orderCouponId = null, orderId = null, url = "" } = {}) => ({
    type: DELETE_ORDER_COUPON,
    orderCouponId,
    orderId,
    url,
  }),
  {
    orderCouponId: idValidators,
    orderId: idValidators,
    url: urlValidators,
  }
);

export function deleteOrderCouponError(error) {
  return {
    type: DELETE_ORDER_COUPON_ERROR,
    error,
  };
}

export const deleteOrderCouponSuccess = withValidation(
  ({ orderCouponId = null, orderId = null } = {}) => ({
    type: DELETE_ORDER_COUPON_SUCCESS,
    orderCouponId,
    orderId,
  }),
  {
    orderCouponId: idValidators,
    orderId: idValidators,
  }
);

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case CREATE_ORDER_COUPON_SUCCESS:
      return Object.assign({}, state, {
        [action.orderCouponId]: action,
      });
    case UPDATE_ORDER_COUPON_FULFILLER:
    case UPDATE_ORDER_COUPON_SUCCESS:
    case UPDATE_PRICE_ORDER_COUPON_SUCCESS:
      return Object.assign({}, state, {
        [action.orderCouponId]: Object.assign(
          {},
          state[action.orderCouponId],
          action
        ),
      });
    case DELETE_ORDER_COUPON_SUCCESS:
      return Object.keys(state)
        .filter((orderCouponId) => orderCouponId !== action.orderCouponId)
        .reduce(
          (all, orderCouponId) =>
            Object.assign(all, {
              [orderCouponId]: state[orderCouponId],
            }),
          {}
        );
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export const createOrderCouponEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(CREATE_ORDER_COUPON),
    filter(({ orderId }) => orderId),
    mergeMap(({ couponCode, description, name, orderId, ...action }) =>
      fetch(
        Object.assign(action, {
          body: {
            data: {
              type: RESOURCE_TYPE.COUPON,
              attributes: {
                couponCode,
                description,
                name,
              },
            },
          },
        }),
        {
          method: "POST",
        }
      ).pipe(
        pluck("response", "data"),
        map(
          ({
            attributes: {
              description: couponDescription,
              name: couponName,
              status,
              statusItems = [],
            },
            id,
            links,
            relationships,
          }) =>
            status >= 0
              ? createOrderCouponSuccess({
                  couponCode,
                  description: couponDescription,
                  fulfilled: isFulfilled(statusItems || []),
                  links,
                  name: couponName,
                  orderCouponId: id,
                  orderId,
                  relationships,
                })
              : createOrderCouponError(
                  buildErrorFromStatusItems({ statusItems })
                )
        ),
        catchError((error) => of(createOrderCouponError(error)))
      )
    )
  );

export const updateOrderCouponEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_COUPON),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "data"),
        map(({ attributes: { statusItems = [] }, id, links, relationships }) =>
          updateOrderCouponSuccess({
            fulfilled: isFulfilled(statusItems || []),
            links,
            orderCouponId: id,
            relationships,
          })
        ),
        catchError((error) => of(updateOrderCouponError(error)))
      )
    )
  );

export const createCouponDetailsEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(CREATE_ORDER_COUPON_SUCCESS, UPDATE_ORDER_COUPON_SUCCESS),
    mergeMap(({ isPastOrder, orderCouponId, orderId, storeId, ...action }) => {
      const state = getState();
      const storeIdOrCurrentStoreId = storeId ?? getCurrentStoreId(state);
      const language = getCurrentLanguage(state) || "en";
      const { couponCode } = getOrderCoupons(state)[orderCouponId];
      const menuCoupon = getCoupons(state)[couponCode] || {};
      const { couponCode: Code, productGroups = [] } = menuCoupon;

      // no need to fetch past order coupons as we are not checking the coupon product groups
      if (isPastOrder) return [];

      if (!productGroups.length) {
        return [
          getCouponDetails({
            storeId: storeIdOrCurrentStoreId,
            language,
            couponCode,
            orderId,
            ...action,
          }),
          updateOrder({
            orderId,
            amountBreakDown: {},
          }),
        ];
      } else if (Code) {
        return [
          updateCouponData({ Code, ...menuCoupon }),
          updateOrder({
            orderId,
            amountBreakDown: {},
          }),
        ];
      }

      return [];
    })
  );

export const getCouponDetailsEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_COUPON_DETAILS),
    mergeMap(({ language, isActiveOrder, ...action }) =>
      fetch(
        Object.assign(action, {
          query: {
            lang: language,
          },
          power: true,
        })
      ).pipe(
        pluck("response"),
        map(
          ({ Status, ProductGroups: productGroups = [], Code, ...response }) =>
            Status >= 0
              ? updateCouponData({ Code, productGroups })
              : createOrderCouponError(response)
        ),
        catchError((error) => {
          if (error.status === 404 && !isActiveOrder) return Observable.empty();
          return of(createOrderCouponError(error));
        })
      )
    )
  );

export const priceValidateUpdateOrderCouponEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_PRICE_ORDER_COUPON),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "data"),
        map(({ attributes: { statusItems = [] }, id, links, relationships }) =>
          updatePriceOrderCouponSuccess({
            fulfilled: isFulfilled(statusItems || []),
            links,
            orderCouponId: id,
            relationships,
          })
        ),
        catchError((error) => of(updateOrderCouponError(error)))
      )
    )
  );

export const deleteOrderCouponEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(DELETE_ORDER_COUPON),
    mergeMap(({ orderCouponId, orderId, ...action }) =>
      fetch(action, {
        method: "DELETE",
      }).pipe(
        map(() => deleteOrderCouponSuccess({ orderCouponId, orderId })),
        catchError((error) => of(deleteOrderCouponError(error)))
      )
    )
  );

export const extraCheeseCouponEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(CREATE_ORDER_COUPON),
    mergeMap(() =>
      action$.pipe(
        ofType(UPDATE_COUPON_DATA),
        delay(100),
        take(1),
        mergeMap(({ Code }) => {
          const state = getState();
          const { auto2C } = getCouponById(state, Code);
          if (!auto2C) return [];
          const [{ toppingCode = "C", toppingId } = {}] =
            getCheeseToppings(state);
          const extraCheeseSlots = getExtraCheeseAvailableSlots(state);

          const orderProductsWithVariants = Object.values(
            getCurrentOrderProducts(state) || {}
          )
            .filter(({ parts = {} }) =>
              Object.values(parts).every(
                ({ options = [] }) =>
                  !options.some(
                    ({ code = "", weight = 1 }) =>
                      code === toppingCode && weight === 1.5
                  )
              )
            )
            .map((product = {}) => {
              const { baseId, sizeId, parts = { "1/1": {} } } = product;
              const productCodes = Object.values(parts)
                .map(({ productCode = "" } = {}) => productCode)
                .filter(Boolean);
              const variantCodes = productCodes
                .map((productCode) =>
                  getVariantCodeFromProduct({
                    sizeCode: sizeId,
                    baseCode: baseId,
                    productCode,
                  })(state)
                )
                .filter(Boolean);
              return {
                ...product,
                variantCodes,
              };
            })
            .flat();

          return orderProductsWithVariants.reduce(
            (updateProductActions, { variantCodes = [], ...orderProduct }) => {
              const getIsExtraCheeseEligible = () =>
                extraCheeseSlots.find((applicableVariants) =>
                  variantCodes.every((variantCode) =>
                    applicableVariants.includes(variantCode)
                  )
                );
              const { itemQuantity } = orderProduct;

              if (getIsExtraCheeseEligible()) {
                Array.from(new Array(itemQuantity)).forEach(() => {
                  const index = extraCheeseSlots.findIndex(
                    (applicableVariants) =>
                      variantCodes.every((variantCode) =>
                        applicableVariants.includes(variantCode)
                      )
                  );
                  if (index >= 0) {
                    extraCheeseSlots.splice(index, 1);
                  }
                });

                return [
                  ...updateProductActions,
                  updateOrderProduct({
                    toppingId,
                    toppingWeight: 1.5,
                    url: orderProduct.links.self.href,
                    ...orderProduct,
                  }),
                ];
              }

              return updateProductActions;
            },
            []
          );
        })
      )
    )
  );

export const epics = combineEpics(
  createOrderCouponEpic,
  deleteOrderCouponEpic,
  updateOrderCouponEpic,
  priceValidateUpdateOrderCouponEpic,
  getCouponDetailsEpic,
  createCouponDetailsEpic,
  extraCheeseCouponEpic
);
