import React from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getNewExperienceFeature } from "ducks/market/selectors";
import { resetActiveOrderProduct } from "ducks/orderProduct";

import { OPTION, defaultArea } from "constants/order";
import uuid from "modules/uuid";

import Area from "components/Area";
import Option from "components/Option";
import Page from "components/Page";
import PizzaBuilder from "components/Pizzas/PizzaBuilder";
import Product from "components/Product";
import Tabs from "components/Tabs";

const OldPizzas = ({
  activeArea,
  activeOrderProductAvailableOptions,
  activeOrderProductBaseId,
  activeOrderProductOptionIds,
  activeOrderProductProducts,
  activeOrderProductSizeId,
  activeOrderProductToppings,
  areas,
  bases,
  currentCategoryId,
  deleteOrderProductOption,
  goToPizzas,
  meats,
  nonMeats,
  products,
  sauces,
  setActiveArea,
  setOrderProduct,
  sidebar,
  sizes,
  t,
  tabs,
  title,
}) => {
  const dispatch = useDispatch();
  const { productSelectionFirst: useProductSelectionFirst } = useSelector(
    getNewExperienceFeature
  );

  const tabbar = <Tabs goTo={goToPizzas} t={t} tabs={tabs} />;
  const actions = (
    <button
      className="btn btn--fill btn--positive"
      data-icon="add"
      data-quid="add-pizza"
      onClick={() => dispatch(resetActiveOrderProduct())}
    >
      {t("shared:action.new")}
    </button>
  );

  const footer = (
    <div className="btn-group btn-group--justified">
      {areas.map((area) => (
        <Area key={area.area} setActiveArea={setActiveArea} t={t} {...area} />
      ))}
    </div>
  );

  const activeToppingProps = {
    activeOrderProductAvailableOptions,
    activeOrderProductOptionIds,
    activeOrderProductOptions: activeOrderProductToppings,
  };

  const activePizzaBuilderProps = Object.assign(
    {
      activeOrderProductBaseId,
      activeOrderProductSizeId,
    },
    activeToppingProps
  );
  const hasMeats = meats.length > 0;
  const hasNonMeats = nonMeats.length > 0;
  const hasToppings = hasMeats || hasNonMeats;

  const pizzaBuilderProps = {
    activeArea,
    bases,
    deleteOrderProductOption,
    sauces,
    setOrderProduct,
    sizes,
    t,
    useProductSelectionFirst,
    ...activePizzaBuilderProps,
  };

  return (
    <Page
      actions={actions}
      footer={areas.length > 0 ? footer : undefined}
      id="pizzas"
      sidebar={sidebar}
      tabs={tabbar}
      title={title}
    >
      <div className="products">
        {!useProductSelectionFirst && (
          <>
            <PizzaBuilder {...pizzaBuilderProps} />
            <br />
          </>
        )}

        <header className="title-group">
          <h2 data-quid="heading-products">{t("food:products")}</h2>
        </header>
        <div
          className="btn-group btn-group--justified btn-group--unlimited toggleable"
          data-quid="products"
        >
          {products.map((product) => (
            <Product
              categoryId={currentCategoryId}
              activeOrderProductProducts={activeOrderProductProducts}
              key={product.productId}
              setOrderProduct={setOrderProduct}
              {...product}
            />
          ))}
        </div>
      </div>
      {useProductSelectionFirst && (
        <>
          <br />
          <PizzaBuilder {...pizzaBuilderProps} />
        </>
      )}
      {hasToppings && (
        <>
          <br />

          <header className="title-group">
            <h2 data-quid="heading-toppings">{t("food:toppings.title")}</h2>
          </header>

          <div className="toppings" data-quid="toppings">
            {hasMeats && (
              <>
                <header className="title-group">
                  <h3 data-quid="heading-meats">{t("food:toppings.meats")}</h3>
                </header>

                <div
                  className="btn-group btn-group--justified btn-group--unlimited"
                  data-quid="meats"
                >
                  {meats.map(
                    ({
                      toppingCode,
                      toppingDescription,
                      toppingId,
                      toppingName,
                    }) => (
                      <Option
                        activeArea={activeArea}
                        key={toppingId}
                        optionCode={toppingCode}
                        optionDescription={toppingDescription}
                        optionId={toppingId}
                        optionName={toppingName}
                        optionType={OPTION.TOPPING}
                        deleteOrderProductOption={deleteOrderProductOption}
                        setOrderProduct={setOrderProduct}
                        {...activeToppingProps}
                      />
                    )
                  )}
                </div>
              </>
            )}

            <br />

            {hasNonMeats && (
              <>
                <header className="title-group">
                  <h3 data-quid="heading-non-meats">
                    {t("food:toppings.non_meats")}
                  </h3>
                </header>

                <div
                  className="btn-group btn-group--justified btn-group--unlimited"
                  data-quid="non-meats"
                >
                  {nonMeats.map(
                    ({
                      toppingCode,
                      toppingDescription,
                      toppingId,
                      toppingName,
                    }) => (
                      <Option
                        activeArea={activeArea}
                        key={toppingId}
                        optionCode={toppingCode}
                        optionDescription={toppingDescription}
                        optionId={toppingId}
                        optionName={toppingName}
                        optionType={OPTION.TOPPING}
                        deleteOrderProductOption={deleteOrderProductOption}
                        setOrderProduct={setOrderProduct}
                        {...activeToppingProps}
                      />
                    )
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Page>
  );
};

OldPizzas.propTypes = {
  activeArea: PropTypes.string,
  activeOrderProductAvailableOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductBaseId: PropTypes.string,
  activeOrderProductOptionIds: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductProducts: PropTypes.objectOf(PropTypes.string),
  activeOrderProductSizeId: PropTypes.string,
  activeOrderProductToppings: PropTypes.objectOf(PropTypes.object),
  currentCategoryId: PropTypes.string,
  areas: PropTypes.arrayOf(
    PropTypes.shape({ active: PropTypes.bool, area: PropTypes.string })
  ),
  bases: PropTypes.arrayOf(PropTypes.object),
  goToPizzas: PropTypes.func.isRequired,
  meats: PropTypes.arrayOf(PropTypes.object),
  nonMeats: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  deleteOrderProductOption: PropTypes.func.isRequired,
  sauces: PropTypes.arrayOf(PropTypes.object),
  setActiveArea: PropTypes.func.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  sidebar: PropTypes.node,
  sizes: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      icon: PropTypes.string,
      id: PropTypes.string,
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};

OldPizzas.defaultProps = {
  activeArea: defaultArea,
  activeOrderProductAvailableOptions: {},
  activeOrderProductBaseId: "",
  activeOrderProductOptionIds: [],
  activeOrderProductProducts: {},
  activeOrderProductSizeId: "",
  activeOrderProductToppings: {},
  currentCategoryId: "",
  areas: [{ active: true, area: defaultArea }],
  bases: {},
  meats: [],
  nonMeats: [],
  products: [],
  sauces: [],
  sidebar: null,
  sizes: {},
  t: () => {},
  tabs: [
    {
      active: true,
      icon: "local_pizza",
      id: uuid(),
      path: "pizzas",
      text: "Pizzas",
    },
  ],
  title: "",
};

export default OldPizzas;
