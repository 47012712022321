const DEFAULT_INVALID_ACTION = "dpz/el-validadore/INVALID_ACTION";

function invalidAction({
  invalidProps,
  originator,
  type = DEFAULT_INVALID_ACTION,
} = {}) {
  return {
    invalidProps,
    originator,
    type,
  };
}

const errorPrefix = "Could not validate action creator!";

export const MESSAGE = Object.freeze({
  MISSING: "No message provided.",
  INVALID_ACTION_TYPE: `${errorPrefix} Invalid or missing action type.`,
  INVALID_CONSTRAINT_TYPE: `${errorPrefix} [validator] must be a function.`,
  INVALID_CONSTRAINT_RETURN_TYPE: `${errorPrefix} [validator] must return a bool.`,
});

const isArray = (test) => Array.isArray(test);
const isObject = (test) => test !== null && typeof test === "object";
const isFunction = (test) => typeof test === "function";

const doMultiValidation =
  (prop, constraints, props, breadcrumbs) => (doValidation) =>
    constraints.forEach((constraint) =>
      doValidation(prop, constraint, props, breadcrumbs)
    );

const doSiblingValidation = (siblings, props, breadcrumbs) => (doValidation) =>
  Object.entries(siblings).map(([siblingProp, siblingConstraint]) =>
    doValidation(
      siblingProp,
      siblingConstraint,
      props,
      breadcrumbs.concat(siblingProp)
    )
  );

const doSingleValidation =
  (prop, constraint, props = {}, breadcrumbs) =>
  (invalidProps, doValidation) => {
    let message = MESSAGE.MISSING;
    let validator = constraint;
    let siblings = {};

    if (isObject(constraint))
      ({
        message = MESSAGE.MISSING,
        validator = constraint,
        ...siblings
      } = constraint);

    const hasSiblings = Object.keys(siblings).length > 0;
    if (hasSiblings)
      doSiblingValidation(siblings, props[prop], breadcrumbs)(doValidation);

    const hasInvalidValidator = !isFunction(validator);

    if (!hasSiblings && hasInvalidValidator)
      throw new Error(MESSAGE.INVALID_CONSTRAINT_TYPE);
    else if (hasInvalidValidator) return;

    const valid = validator(props[prop]);

    if (typeof valid !== "boolean")
      throw new Error(MESSAGE.INVALID_CONSTRAINT_RETURN_TYPE);

    if (!valid)
      invalidProps[breadcrumbs.join(".")] = (invalidProps[prop] || []).concat(
        message
      );
  };

export default (actionCreator, constraints, invalidActionType) => (props) => {
  const originator = actionCreator(props) || {};
  const { type: actionType } = originator;

  if (!actionType) throw new Error(MESSAGE.INVALID_ACTION_TYPE);

  const INVALID_ACTION = invalidActionType || `${actionType}_INVALID`;
  const invalidProps = {};

  function doValidation(
    prop,
    constraint,
    currentProps = props,
    breadcrumbs = [prop]
  ) {
    if (isArray(constraint))
      doMultiValidation(
        prop,
        constraint,
        currentProps,
        breadcrumbs
      )(doValidation);
    else if (isObject(constraint)) {
      const { message, validator, ...siblings } = constraint;

      if (isArray(validator))
        doMultiValidation(
          prop,
          validator.map((v) => ({ message, validator: v })),
          currentProps,
          breadcrumbs
        )(doValidation);
      else
        doSingleValidation(
          prop,
          constraint,
          currentProps,
          breadcrumbs
        )(invalidProps, doValidation);

      const hasSiblings = Object.keys(siblings).length > 0;
      if (hasSiblings)
        doSiblingValidation(
          siblings,
          currentProps[prop],
          breadcrumbs
        )(doValidation);
    } else
      doSingleValidation(
        prop,
        constraint,
        currentProps,
        breadcrumbs
      )(invalidProps, doValidation);
  }

  Object.entries(constraints).forEach(([prop, constraint]) =>
    doValidation(prop, constraint)
  );

  const invalid = Object.keys(invalidProps).length > 0;
  if (invalid)
    return invalidAction({
      invalidProps,
      originator,
      type: INVALID_ACTION,
    });

  return originator;
};
