import { createSelector } from "reselect";

export const getStoreLocation = ({ storeLocation = {} } = {}) => storeLocation;

export const getCurrentRegionCode = createSelector(
  [getStoreLocation],
  ({ regionCode = "" }) => regionCode
);

export const getStoreLocatorQuery = createSelector(
  [getStoreLocation],
  ({ stores, ...query }) => query
);

export const getLocatedStores = createSelector(
  [getStoreLocation],
  ({ stores = {} }) => stores
);

export const getInVariance = ({ orderDateTime, storeId, serviceMethod }) =>
  createSelector([getLocatedStores], (stores) => {
    const [, { storeVariance }] = Object.entries(stores).find(
      ([id]) => id === storeId
    ) || ["", {}];

    if (!storeVariance) return false;

    const { storeHoursByServiceMethod, validFrom, validTo } = storeVariance;
    const { OpenTime, CloseTime } = storeHoursByServiceMethod[serviceMethod];
    const orderDate = orderDateTime ? new Date(orderDateTime) : new Date();
    const orderTime = orderDate.getTime();
    const varianceStartDate = new Date(`${validFrom}T${OpenTime}:00`);
    const varianceEndDate = new Date(`${validTo}T${CloseTime}:00`);
    const inVariancePeriod =
      orderTime >= varianceStartDate.getTime() &&
      orderTime <= varianceEndDate.getTime();

    const getStoreVarianceOrderTime = (varianceTime) => {
      const [varianceTimeHours, varianceTimeMinutes] = varianceTime.split(":");
      return new Date(
        orderDate.getFullYear(),
        orderDate.getMonth(),
        orderDate.getDate(),
        +varianceTimeHours,
        +varianceTimeMinutes,
        0
      ).getTime();
    };

    const orderStoreVarianceStartTime = getStoreVarianceOrderTime(OpenTime);
    const orderStoreVarianceEndTime = getStoreVarianceOrderTime(CloseTime);

    const inVarianceTime =
      orderTime >= orderStoreVarianceStartTime &&
      orderTime <= orderStoreVarianceEndTime;

    return inVariancePeriod && inVarianceTime;
  });

export default getCurrentRegionCode;
