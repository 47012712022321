/** @jsxImportSource @emotion/react */
import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getCurrentOrderCoupons } from "ducks/order/selectors";

import formatCurrency from "modules/formatCurrency";

import Tooltip from "components/Tooltip";

import { couponTitle, couponTitleWrapper } from "./Coupon.styles";

const Coupon = ({
  code = "",
  createOrderCoupon = () => {},
  description = "",
  disabledMessage = "",
  marketCode,
  name = "",
  price = null,
  t = () => {},
}) => {
  const currentOrderCoupons = useSelector(getCurrentOrderCoupons);
  const activeCoupon = Object.values(currentOrderCoupons).find(
    ({ couponCode }) => couponCode === code
  );
  const isDisabled = !!disabledMessage;

  const couponBody = (
    <div
      className={classNames(
        "coupon",
        activeCoupon &&
          (activeCoupon.fulfilled ? "status--positive" : "status--warning")
      )}
      data-icon={activeCoupon ? "local_offer" : null}
      data-quid={`coupon-${code}`}
      onClick={() =>
        !isDisabled &&
        createOrderCoupon({ couponCode: code, name, description })
      }
      onKeyDown={(e) => e}
      role="presentation"
      title={description}
      disabled={isDisabled}
    >
      <div className="coupon-info">
        <section
          className="body"
          data-quid={`coupon-${code}-name`}
          css={couponTitleWrapper}
        >
          {description && <h3 css={couponTitle}>{description}</h3>}
          <b>{name}</b>
        </section>

        <section className="footer">
          <span data-quid={`coupon-${code}-code`}>{code}</span>

          <span className="text--right" data-quid={`coupon-${code}-price`}>
            {price && `${formatCurrency(marketCode, price)}`}
          </span>
        </section>
      </div>
    </div>
  );

  if (activeCoupon) {
    return (
      <Tooltip
        title={t(
          activeCoupon.fulfilled ? "coupons:complete" : "coupons:incomplete"
        )}
      >
        {couponBody}
      </Tooltip>
    );
  }

  return isDisabled ? (
    <Tooltip title={disabledMessage}>{couponBody}</Tooltip>
  ) : (
    couponBody
  );
};

Coupon.propTypes = {
  canApplyToOrder: PropTypes.bool,
  code: PropTypes.string,
  createOrderCoupon: PropTypes.func,
  description: PropTypes.string,
  disabledMessage: PropTypes.string,
  isFiltered: PropTypes.bool,
  marketCode: PropTypes.string.isRequired,
  name: PropTypes.string,
  price: PropTypes.string,
  t: PropTypes.func,
};

export default Coupon;
