import { createSlice } from "@reduxjs/toolkit";

export const LOOKUP_STATUS = {
  INITAL: "initial",
  PENDING: "pending",
  FINISHED: "finished",
};

const initialState = {
  customerLookupStatus: LOOKUP_STATUS.INITAL,
  hasUserSelectedStore: false,
  areAllFieldsValid: false,
  showAllErrors: false,
  storeQueryLastArgs: {},
  isLoyaltyMember: false,
  isCarplayCustomer: false,
  possibleCustomerList: [],
  selectedCustomerId: "",
  carplayUserPosition: {
    lat: null,
    lng: null,
  },
};

const customerPageSlice = createSlice({
  name: "customerPage",
  initialState,
  reducers: {
    setLookupPending: (state) => {
      state.customerLookupStatus = LOOKUP_STATUS.PENDING;
    },
    setLookupFinished: (state) => {
      state.customerLookupStatus = LOOKUP_STATUS.FINISHED;
    },
    setHasUserSelectedStore: (state, { payload }) => {
      state.hasUserSelectedStore = payload;
    },
    setAreAllFieldsValid: (state, { payload }) => {
      state.areAllFieldsValid = payload;
    },
    setShowAllErrors: (state, { payload }) => {
      state.showAllErrors = payload;
    },
    setStoreQueryLastArgs: (state, { payload }) => {
      state.storeQueryLastArgs = payload;
    },
    setIsLoyaltyMember: (state, { payload }) => {
      state.isLoyaltyMember = payload;
    },
    setIsCarplayCustomer: (state, { payload }) => {
      state.isCarplayCustomer = payload;
    },
    setPossibleCustomerList: (state, { payload }) => {
      state.possibleCustomerList = payload;
    },
    removePossibleCustomerList: (state) => {
      state.possibleCustomerList = [];
    },
    setSelectedCustomerId: (state, { payload }) => {
      state.selectedCustomerId = payload;
    },
    setCarplayUserPosition: (state, { payload }) => {
      state.carplayUserPosition = payload;
    },
    resetCustomerPage: () => initialState,
  },
});

export const {
  setLookupPending,
  setLookupFinished,
  setHasUserSelectedStore,
  setAreAllFieldsValid,
  setShowAllErrors,
  setStoreQueryLastArgs,
  setIsLoyaltyMember,
  setIsCarplayCustomer,
  setPossibleCustomerList,
  setSelectedCustomerId,
  setCarplayUserPosition,
  resetCustomerPage,
  removePossibleCustomerList,
} = customerPageSlice.actions;

export default customerPageSlice.reducer;

export const selectCustomerLookupStatus = (state) =>
  state.customerPage.customerLookupStatus;

export const selectHasUserSelectedStore = (state) =>
  state.customerPage.hasUserSelectedStore;

export const selectAreAllFieldsValid = (state) =>
  state.customerPage.areAllFieldsValid;

export const selectShowAllErrors = (state) => state.customerPage.showAllErrors;

export const selectStoreQueryLastArgs = (state) =>
  state.customerPage.storeQueryLastArgs;

export const selectIsLoyaltyMember = (state) =>
  state.customerPage.isLoyaltyMember;

export const selectIsCarplayCustomer = (state) =>
  state.customerPage.isCarplayCustomer;

export const selectPossibleCustomerList = (state) =>
  state.customerPage.possibleCustomerList;

export const selectCarplayUserPosition = (state) =>
  state.customerPage.carplayUserPosition;
