import { css } from "@emotion/react";

export const ordersWrapper = css`
  border: 1px solid var(--border);
  p {
    margin: 0;
  }
`;

export const orderWrapper = css`
  position: relative;
  padding: 1rem;
  &.alternateRow {
    background-color: var(--bg);
  }
  &.expanded {
    border-left: 5px solid var(--dpzRed);
    padding-left: 1rem;
  }
  &.hidden {
    max-height: 3rem;
    overflow: hidden;
    padding-bottom: 3rem;
  }
`;

export const topSection = css`
  position: relative;
  display: grid;
  grid-template-columns: 10rem 1fr 2rem;
  grid-template-rows: auto;
  grid-template-areas:
    "order_info items icon"
    "address items .";
  grid-column-gap: 1rem;
  .icon {
    grid-area: "icon";
  }
`;

export const orderInfoSection = css`
  .date-or-easy {
    font-weight: bold;
    text-transform: capitalize;
  }
`;

export const addressSection = css`
  grid-area: address;
  margin-top: 1rem;
  address {
    margin-bottom: 1rem;
  }
`;

export const itemsSection = css`
  grid-area: items;
  .toppings {
    margin-left: 1.5rem;
  }
  .product {
    margin-bottom: 0.4rem;
  }
  .coupons {
    margin-top: 1rem;
  }
`;

export const footerStyles = css`
  display: flex;
  justify-content: flex-end;
  button {
    width: 10rem;
  }
`;

export const fadeTransition = css`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 2rem;
  background-color: red;
  background: linear-gradient(transparent, white 95%);
  &.alternateRow {
    background: linear-gradient(transparent, var(--bg) 95%);
  }
`;
