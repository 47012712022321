import React, { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";

import getMarketCode from "ducks/market/selectors";

import { selectCardType } from "rtk_redux/slices/finishSlice";

import { CC } from "constants/finish";
import blurOnEnter from "modules/blurOnEnter";
import getInputMaskById from "modules/getInputMaskById";

import RequiredMark from "components/RequiredMark";

const MASK_DISPLAY_CHAR = {
  displayChar: "*",
};

const TIP_MASK_BLOCK = {
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      thousandsSeparator: ",",
      radix: ".",
    },
  },
};

const CCField = ({
  className = "",
  errorMessage = "",
  handleBlur = () => {},
  handleChange = () => {},
  handleFocus,
  id,
  isInvalid = false,
  label,
  maxLength,
  placeHolder,
  quidBase,
  required = false,
  t,
  value = "",
}) => {
  const [controlledValue, setValue] = useState(value);
  const cardType = useSelector(selectCardType);
  const marketCode = useSelector(getMarketCode);

  const onChange = (value) => {
    setValue(value);
    handleChange(value, id);
  };

  const onBlur = () => {
    handleBlur(controlledValue, id);
  };

  return (
    <div
      className={classNames("form__control-group text-field", className, {
        ["error"]: isInvalid,
      })}
      data-quid={quidBase}
    >
      <label
        className="text-field__label"
        htmlFor={id}
        data-quid={`${quidBase}-label`}
      >
        {required ? <RequiredMark /> : null} {label}
      </label>
      <IMaskInput
        className={classNames({ ["parsley-error"]: isInvalid })}
        data-quid={`${quidBase}-input`}
        id={id}
        unmask={true}
        mask={getInputMaskById(
          id,
          marketCode,
          cardType,
          controlledValue.length
        )}
        maxLength={maxLength}
        onAccept={onChange}
        onBlur={onBlur}
        onFocus={handleFocus}
        onKeyPress={blurOnEnter}
        placeholder={placeHolder ?? label}
        type="text"
        required={required}
        {...([
          CC.CARD_NUMBER,
          CC.SECURITY_CODE,
          CC.SAVED_SECURITY_CODE,
        ].includes(id) && MASK_DISPLAY_CHAR)}
        {...(id === CC.TIP_AMOUNT && TIP_MASK_BLOCK)}
      />
      {isInvalid && (
        <p className="error-message parsley-required">
          {errorMessage ??
            t("negative:please_enter_a_valid_value", {
              value: label.toLowerCase(),
            })}
        </p>
      )}
    </div>
  );
};

CCField.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func,
  id: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  placeHolder: PropTypes.string,
  quidBase: PropTypes.string.isRequired,
  required: PropTypes.bool,
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default CCField;
