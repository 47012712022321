import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getNationalCoupons } from "ducks/coupons/selectors";
import { getCurrentOrder } from "ducks/order/selectors";
import { getCurrentStoreUTCOffset } from "ducks/store/selectors";

import { selectIsCarplayCustomer } from "rtk_redux/slices/customerPageSlice";

import { ALL, NATIONAL } from "constants/coupon";
import filterValidCoupons from "modules/filterValidCoupons";

import CouponGroup from "./CouponGroup";
import FilteredCouponGroup from "./FilteredCouponGroup";

const NewCouponGroups = ({
  coupons = [],
  createOrderCoupon = () => {},
  currentTabId,
  marketCode,
  t = () => {},
}) => {
  const currentOrder = useSelector(getCurrentOrder);
  const isCarplayCustomer = useSelector(selectIsCarplayCustomer);
  let storeUTCOffset = useSelector(getCurrentStoreUTCOffset);
  let nationalCoupons = useSelector(getNationalCoupons);
  nationalCoupons = nationalCoupons.filter(
    filterValidCoupons({
      currentServiceMethod: currentOrder.serviceMethod,
      storeUTCOffset,
    })
  );

  // business decision to display first three coupons as "preferred"
  const preferredCoupons = coupons.slice(0, 3);
  const remainingCoupons = coupons.slice(3);

  switch (currentTabId) {
    case NATIONAL:
      return (
        <CouponGroup
          coupons={nationalCoupons}
          createOrderCoupon={createOrderCoupon}
          marketCode={marketCode}
          isCarplayCustomer={isCarplayCustomer}
          isNationalCoupon={true}
          t={t}
        />
      );
    case ALL:
      return (
        <FilteredCouponGroup
          coupons={coupons}
          createOrderCoupon={createOrderCoupon}
          isCarplayCustomer={isCarplayCustomer}
          marketCode={marketCode}
          t={t}
        />
      );
    default:
      return (
        <div>
          <CouponGroup
            coupons={preferredCoupons}
            createOrderCoupon={createOrderCoupon}
            marketCode={marketCode}
            isCarplayCustomer={isCarplayCustomer}
            t={t}
          />

          <div className="dashed">
            <span>{t("coupons:preferred_coupons_above")}</span>
          </div>

          <CouponGroup
            coupons={remainingCoupons}
            createOrderCoupon={createOrderCoupon}
            marketCode={marketCode}
            isCarplayCustomer={isCarplayCustomer}
            t={t}
          />
        </div>
      );
  }
};

NewCouponGroups.propTypes = {
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      couponCode: PropTypes.string,
      couponDescription: PropTypes.string,
      couponName: PropTypes.string,
      couponPrice: PropTypes.string,
    })
  ),
  createOrderCoupon: PropTypes.func,
  currentTabId: PropTypes.string,
  isNationalTab: PropTypes.bool,
  marketCode: PropTypes.string.isRequired,
  t: PropTypes.func,
};

export default NewCouponGroups;
