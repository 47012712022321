import { ajax as rxAjax } from "rxjs/observable/dom/ajax";

import exists from "modules/exists";

export function actionForXhrError(actionCreator) {
  return (actionThatResultedInXhrError, errorFromXhrCall, ...opts) =>
    Object.assign(
      {},
      actionCreator(actionThatResultedInXhrError, errorFromXhrCall, ...opts),
      {
        originalAction: actionThatResultedInXhrError,
        error: errorFromXhrCall,
        xhr: errorFromXhrCall.xhr,
      }
    );
}

const getKeyValue = (pair) => pair.split("=");

const reduceKeyValue = (all, [key, value]) =>
  Object.assign(all, { [key]: value });

export function parseParams(queryString = "") {
  const params = queryString.replace("?", "").split("&").filter(exists);

  return params.length > 0
    ? params.map(getKeyValue).reduce(reduceKeyValue, {})
    : {};
}

export function buildParams(params = {}) {
  return Object.keys(params)
    .filter((key) => typeof params[key] !== "undefined")
    .map((key) => [key, encodeURIComponent(params[key])].join("="))
    .join("&");
}

const { serviceRoot = "", powerRoot = "" } = window.ENVCONFIG || {};

export const fetch = (action, options = {}) => {
  const { method = "GET" } = options;
  const { power = false } = action;
  let { url } = action;

  const PREFIX =
    process.env.REACT_APP_API_BASE ||
    (process.env.NODE_ENV === "test" && []) || // [] is truthy and coereces to "" when + a string
    power
      ? powerRoot
      : serviceRoot;

  if (!/^(https?:)?\/\//.test(url)) url = PREFIX + url;

  const params = buildParams(action.query);
  if (params.length) url += `${url.includes("?") ? "&" : "?"}${params}`;

  if (["POST", "PUT"].includes(method) && action.body)
    options.body = JSON.stringify(action.body);

  return rxAjax(
    Object.assign(
      {
        responseType: "json",
        method,
        url,
      },
      options,
      {
        headers: Object.assign(
          {
            "Content-Type": "application/vnd.api+json",
          },
          action.headers,
          options.headers
        ),
      }
    )
  );
};
