import { css } from "@emotion/react";

export default css`
  text-transform: capitalize;
  box-shadow: inset 0 -1px 0 -0.5px var(--border);
  font-weight: bold;
  line-height: 24px;
  transition: color 0.1s ease;

  &:not(.is-active) {
    background-color: var(--interactive-bg);
    color: var(--interactive-fg);

    &:hover {
      color: var(--dom-red);
    }
  }

  &.is-active {
    background-color: var(--interactive-fg);
    color: #fff;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }
`;
