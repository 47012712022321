import { excludedStatuses } from "constants/message";
import { MESSAGE } from "modules/elValidadore";
import flatten from "modules/flatten";

export const buildErrorFromStatusItems = ({
  statusItems = [],
  errorPrefix = "",
}) => ({
  response: {
    errors: statusItems
      .map((item) => {
        if (item.Code && !item.code) item.code = item.Code;
        return item;
      })
      .filter(({ code }) => !excludedStatuses.includes(code))
      .map((item) => {
        const detail = item.pulseCode || item.code;
        item.detail = `${errorPrefix}${detail}`;
        return item;
      }),
  },
});

export const buildErrorFromInvalidProps = (invalidProps = {}) => ({
  response: {
    errors: Object.values(invalidProps)
      .reduce(flatten, [])
      .filter((message) => message !== MESSAGE.MISSING)
      .map((message) => ({ detail: message })),
  },
});

export default buildErrorFromStatusItems;
