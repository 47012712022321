import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, filter, map, mergeMap, pluck } from "rxjs/operators";

import { updateOrder } from "ducks/order";

import { MESSAGE } from "constants/message";
import { ORDER_TIMING } from "constants/order";
import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

const SCOPE = "order-entry/order/";

export const UPDATE_ORDER_TIMING = `${SCOPE}UPDATE_ORDER_TIMING`;
export const UPDATE_ORDER_FUTURE_TIME = `${SCOPE}UPDATE_ORDER_FUTURE_TIME`;
export const UPDATE_ORDER_FUTURE_TIME_ERROR = `${SCOPE}UPDATE_ORDER_FUTURE_TIME_ERROR`;
export const DELETE_ORDER_FUTURE_TIME = `${SCOPE}DELETE_ORDER_FUTURE_TIME`;
export const DELETE_ORDER_FUTURE_TIME_ERROR = `${SCOPE}DELETE_ORDER_FUTURE_TIME_ERROR`;

export const updateOrderTiming = withValidation(
  ({
    currentOrderTiming = ORDER_TIMING.NOW,
    orderId = null,
    orderTiming = ORDER_TIMING.NOW,
    url = "",
  } = {}) => ({
    type: UPDATE_ORDER_TIMING,
    currentOrderTiming,
    orderId,
    orderTiming,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export const updateOrderFutureTime = withValidation(
  ({ futureTime = "", url = "" } = {}) => ({
    type: UPDATE_ORDER_FUTURE_TIME,
    futureTime,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function updateOrderFutureTimeError(error) {
  return {
    type: UPDATE_ORDER_FUTURE_TIME_ERROR,
    error,
  };
}

export const deleteOrderFutureTime = withValidation(
  ({ url = "" } = {}) => ({
    type: DELETE_ORDER_FUTURE_TIME,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function deleteOrderFutureTimeError(error) {
  return {
    type: DELETE_ORDER_FUTURE_TIME_ERROR,
    error,
  };
}

//TODO: write tests
export const updateOrderTimingEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_ORDER_TIMING),
    filter(
      ({ currentOrderTiming, orderTiming }) =>
        currentOrderTiming !== orderTiming
    ),
    map(({ orderId, orderTiming, currentOrderTiming, url }) =>
      orderTiming === ORDER_TIMING.NOW &&
      currentOrderTiming === ORDER_TIMING.LATER
        ? deleteOrderFutureTime({ url })
        : updateOrder({ orderId, orderTiming })
    )
  );

//TODO: write tests
export const updateOrderFutureTimeEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_FUTURE_TIME),
    mergeMap(({ futureTime, ...action }) =>
      fetch(
        Object.assign(action, {
          body: {
            data: {
              attributes: {
                futureTime,
              },
            },
          },
        }),
        {
          method: "PUT",
        }
      ).pipe(
        pluck("response", "data"),
        map(({ id }) => updateOrder({ orderId: id, futureTime })),
        catchError((error) => of(updateOrderFutureTimeError(error)))
      )
    )
  );

//TODO: write tests
export const deleteOrderFutureTimeEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(DELETE_ORDER_FUTURE_TIME),
    mergeMap((action) =>
      fetch(action, { method: "DELETE" }).pipe(
        pluck("response", "data", "id"),
        map((orderId) =>
          updateOrder({
            date: undefined,
            futureTime: "",
            orderId,
            orderTiming: ORDER_TIMING.NOW,
            time: "",
            timeList: [],
          })
        ),
        catchError((error) => of(deleteOrderFutureTimeError(error)))
      )
    )
  );
