import { combineEpics, ofType } from "redux-observable";
import { from } from "rxjs/observable/from";
import { of } from "rxjs/observable/of";
import { catchError, filter, map, mergeMap, pluck } from "rxjs/operators";

import { RESET_STATE } from "ducks/configuration";
import { getCustomer, getCustomerPrefix } from "ducks/customer/selectors";
import { SET_MARKET } from "ducks/market";
import { getMarket, getMarketProfileFields } from "ducks/market/selectors";
import { getNewExperienceFeature } from "ducks/market/selectors";
import { deleteOrphanedOrders, startOrder } from "ducks/order";
import { getCurrentOrder, getOrderStarted } from "ducks/order/selectors";
import { getCurrentStoreId } from "ducks/store/selectors";
import { getLinkFromRelationship } from "selectors/related";

import { selectPrefix } from "rtk_redux/slices/customerInformationFormSlice";
import {
  setCarplayUserPosition,
  setIsCarplayCustomer,
  setIsLoyaltyMember,
  setPossibleCustomerList,
} from "rtk_redux/slices/customerPageSlice";
import { setOrderHistory } from "rtk_redux/slices/orderHistorySlice";

import { ORDER_RESULT_LIMIT } from "constants/order";
import withValidation from "modules/elValidadore";
import exists from "modules/exists";
import flatten from "modules/flatten";
import {
  optionalUrlValidators,
  stringValidators,
  urlValidators,
} from "modules/validators";

import {
  getCreateAddressSuccessAction,
  getCreateOrderAction,
  getCreateOrderCouponSuccessActions,
  getCreateOrderProductSuccessActions,
  getSetStoreAction,
  normalizeServiceMethods,
} from "./helpers";

const SCOPE = "order-entry/customer/";

export const LOOKUP_CUSTOMER = `${SCOPE}LOOKUP_CUSTOMER`;
export const LOOKUP_CUSTOMER_SUCCESS = `${SCOPE}LOOKUP_CUSTOMER_SUCCESS`;
export const LOOKUP_CUSTOMER_ERROR = `${SCOPE}LOOKUP_CUSTOMER_ERROR`;
export const INITALIZE_CUSTOMER_DATA = `${SCOPE}INITIALIZE_CUSTOMER_DATA`;
export const UPDATE_CUSTOMER = `${SCOPE}UPDATE_CUSTOMER`;
export const UPDATE_CUSTOMER_SUCCESS = `${SCOPE}UPDATE_CUSTOMER_SUCCESS`;
export const UPDATE_CUSTOMER_COMMENTS_SUCCESS = `${SCOPE}UPDATE_CUSTOMER_COMMENTS_SUCCESS`;
export const UPDATE_CUSTOMER_ERROR = `${SCOPE}UPDATE_CUSTOMER_ERROR`;
export const GET_CUSTOMER_ORDERS = `${SCOPE}GET_CUSTOMER_ORDERS`;
export const GET_CUSTOMER_ORDERS_SUCCESS = `${SCOPE}GET_CUSTOMER_ORDERS_SUCCESS`;
export const GET_CUSTOMER_ORDERS_ERROR = `${SCOPE}GET_CUSTOMER_ORDERS_ERROR`;
export const GET_CUSTOMER_TAX_CODES = `${SCOPE}GET_CUSTOMER_TAX_CODES`;
export const GET_CUSTOMER_TAX_CODES_SUCCESS = `${SCOPE}GET_CUSTOMER_TAX_CODES_SUCCESS`;
export const GET_CUSTOMER_TAX_CODES_ERROR = `${SCOPE}GET_CUSTOMER_TAX_CODES_ERROR`;
export const SET_CUSTOMER_PROFILE = `${SCOPE}SET_CUSTOMER_PROFILE`;

export const lookupCustomer = withValidation(
  ({
    doUpdate = true,
    extension = "",
    phone = "",
    prefix = "",
    stJudeRoundUpEnabled = false,
    url = "",
  } = {}) => ({
    type: LOOKUP_CUSTOMER,
    doUpdate,
    extension,
    phone,
    prefix,
    stJudeRoundUpEnabled,
    url,
  }),
  {
    phone: stringValidators,
    url: urlValidators,
  }
);

export function lookupCustomerSuccess({
  comments = "",
  customerId = null,
  ecommCustomerId = null,
  doUpdate = true,
  email = "",
  extension = "",
  firstName = "",
  lastName = "",
  phone = "",
  prefix = "",
  creditCards = [],
  vehicles,
  loyalty,
  storeId,
  currentLocationPostalCode,
  currentLocationCity,
  currentLocationState,
  stJudeRoundUp = false,
  addStJudeRoundUp,
} = {}) {
  return {
    type: LOOKUP_CUSTOMER_SUCCESS,
    comments,
    customerId,
    ecommCustomerId,
    doUpdate,
    email,
    extension,
    firstName,
    lastName,
    phone,
    prefix,
    creditCards,
    vehicles,
    loyalty,
    storeId,
    currentLocationPostalCode,
    currentLocationCity,
    currentLocationState,
    stJudeRoundUp,
    addStJudeRoundUp,
  };
}

export function lookupCustomerError(error) {
  return {
    type: LOOKUP_CUSTOMER_ERROR,
    error,
  };
}

export const updateCustomer = withValidation(
  ({
    comments = "",
    customerId = null,
    ecommCustomerId = null,
    email = "",
    extension = "",
    firstName = "",
    lastName = "",
    phone = "",
    prefix = "",
    url = "",
    vehicleInfo = null,
    creditCards = [],
    stJudeRoundUp = false,
  } = {}) => ({
    type: UPDATE_CUSTOMER,
    comments,
    customerId,
    ecommCustomerId,
    email,
    extension,
    firstName,
    lastName,
    phone,
    prefix,
    url,
    vehicleInfo,
    creditCards,
    stJudeRoundUp,
  }),
  {
    url: optionalUrlValidators,
  }
);

export function updateCustomerSuccess({
  comments = "",
  customerId = null,
  ecommCustomerId = null,
  email = "",
  extension = "",
  firstName = "",
  lastName = "",
  links = {},
  phone = "",
  prefix = "",
  relationships = {},
  vehicleInfo = null,
  creditCards = [],
  stJudeRoundUp,
} = {}) {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
    comments,
    customerId,
    ecommCustomerId,
    email,
    extension,
    firstName,
    lastName,
    links,
    phone,
    prefix,
    relationships,
    vehicleInfo,
    creditCards,
    stJudeRoundUp,
  };
}

export function initializeCustomerData({
  attributes,
  id,
  doUpdate,
  extension,
  phone,
  prefix,
  profile,
  addStJudeRoundUp,
}) {
  return {
    type: INITALIZE_CUSTOMER_DATA,
    attributes,
    id,
    doUpdate,
    extension,
    phone,
    prefix,
    profile,
    addStJudeRoundUp,
  };
}

export function updateCustomerError(error) {
  return {
    type: UPDATE_CUSTOMER_ERROR,
    error,
  };
}

export const getCustomerOrders = withValidation(
  ({ url = "" } = {}) => ({
    type: GET_CUSTOMER_ORDERS,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function getCustomerOrdersSuccess(pastOrders = []) {
  return {
    type: GET_CUSTOMER_ORDERS_SUCCESS,
    pastOrders,
  };
}

export function getCustomerOrdersError(error) {
  return {
    type: GET_CUSTOMER_ORDERS_ERROR,
    error,
  };
}

export const getCustomerTaxCodes = withValidation(
  ({ url = "" } = {}) => ({
    type: GET_CUSTOMER_TAX_CODES,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function getCustomerTaxCodesSuccess(pastTaxCodes = []) {
  return {
    type: GET_CUSTOMER_TAX_CODES_SUCCESS,
    pastTaxCodes,
  };
}

export function getCustomerTaxCodesError(error) {
  return {
    type: GET_CUSTOMER_TAX_CODES_ERROR,
    error,
  };
}

export const initialState = {
  comments: "",
  customerId: null,
  ecommCustomerId: null,
  email: "",
  extension: "",
  firstName: "",
  lastName: "",
  links: {},
  phone: "",
  prefix: "",
  relationships: {},
  taxCodes: [],
};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case GET_CUSTOMER_TAX_CODES_SUCCESS:
      return Object.assign({}, state, {
        taxCodes: action.pastTaxCodes,
      });
    case UPDATE_CUSTOMER_SUCCESS:
      return Object.assign({}, state, action, {
        customerId: action.customerId || null,
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export const lookupCustomerEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(LOOKUP_CUSTOMER),
    mergeMap(
      ({
        doUpdate,
        extension,
        phone,
        prefix,
        stJudeRoundUpEnabled,
        ...action
      }) => {
        const state = getState();
        const {
          newCustomerPage: shouldUseNewCustomerPage,
          selectFromMultipleCustomers: shouldSelectFromMultipleCustomers,
        } = getNewExperienceFeature(state);
        prefix =
          prefix || !shouldUseNewCustomerPage ? prefix : selectPrefix(state);
        return fetch(
          Object.assign(action, {
            query: [
              {
                filterName: "filter[phone.phoneNumber]",
                value: phone,
              },
              {
                filterName: "filter[phone.extension]",
                value: extension,
              },
              { filterName: "filter[phone.prefix]", value: prefix },
            ]
              .filter(({ value }) => Boolean(value))
              .reduce(
                (query, { filterName, value }) => ({
                  ...query,
                  [filterName]: value,
                }),
                {}
              ),
          })
        ).pipe(
          pluck("response", "data"),
          map((data) => {
            const isArray = Array.isArray(data);
            const hasMultipleResults = isArray && data.length > 1;

            // If the feature is enabled and there is more than one customer with the given phone number
            // this information will be shown to the agent to choose between customers
            const shouldShowSelect =
              isArray &&
              hasMultipleResults &&
              shouldSelectFromMultipleCustomers;
            if (shouldShowSelect) {
              return setPossibleCustomerList(data);
            }

            const activeCustomer = isArray ? data[0] : data;
            const { attributes, id, ...profile } = activeCustomer;

            // The endpoint will return an extension even if just the phone number is used in search
            // This ensures that whatever is returned from lookup is displayed on the screen
            if (shouldUseNewCustomerPage) {
              phone = attributes.phone.phoneNumber;
              extension = attributes.phone.extension;
              prefix = attributes.phone.prefix;
            }
            return initializeCustomerData({
              attributes,
              id,
              doUpdate,
              phone,
              prefix,
              extension,
              profile,
              addStJudeRoundUp:
                stJudeRoundUpEnabled && attributes.stJudeRoundUp,
            });
          }),
          catchError((error) => {
            if (error.status === 404)
              return of(true).pipe(
                mergeMap(() => [
                  lookupCustomerSuccess(
                    Object.assign({}, getCustomer(getState()), {
                      customerId: null,
                      doUpdate,
                      email: "",
                      comments: "",
                      firstName: "",
                      lastName: "",
                      phone,
                      prefix,
                      extension,
                    })
                  ),
                ])
              );

            return of(lookupCustomerError(error));
          })
        );
      }
    )
  );

export const initializeCustomerDataEpic = (action$) =>
  action$.pipe(
    ofType(INITALIZE_CUSTOMER_DATA),
    mergeMap(
      ({
        attributes,
        id,
        doUpdate,
        extension,
        phone,
        prefix,
        profile,
        addStJudeRoundUp,
      }) => {
        return [
          getCustomerOrders({
            url: getLinkFromRelationship("pastOrders")(profile),
          }),
          getCustomerTaxCodes({
            url: getLinkFromRelationship("taxCodes")(profile),
          }),
          setIsLoyaltyMember(Boolean(attributes.loyalty)),
          setIsCarplayCustomer(attributes?.source?.toLowerCase() === "carplay"),
          setCarplayUserPosition({
            lat: attributes.carplayLatitude ?? null,
            lng: attributes.carplayLongitude ?? null,
          }),

          lookupCustomerSuccess(
            Object.assign({}, attributes, {
              customerId: id,
              doUpdate,
              extension,
              phone,
              prefix,
              addStJudeRoundUp,
            })
          ),
        ];
      }
    )
  );

export const lookupCustomerSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(LOOKUP_CUSTOMER_SUCCESS),
    filter(({ doUpdate }) => doUpdate),
    map(
      ({
        type,
        doUpdate,
        vehicles,
        storeId,
        currentLocationPostalCode,
        currentLocationCity,
        currentLocationState,
        addStJudeRoundUp,
        ...customer
      }) => {
        const state = getState();
        const isOrderStarted = getOrderStarted(state);

        // GOLO doesn't expect vehicleInfo as a property on customer
        // so only add the vehicleInfo key if vehicles exist (coming from carplay)
        if (vehicles && vehicles.length) {
          customer.vehicleInfo = vehicles[0];
        }
        if (!isOrderStarted) {
          const market = getMarket(state);
          const ordersLink = getLinkFromRelationship("orders")(market);
          const currentStoreId = getCurrentStoreId(state);
          const selectedStoreId = storeId ? storeId : currentStoreId;

          return startOrder({
            customer,
            storeId: selectedStoreId,
            url: ordersLink,
            addStJudeRoundUp,
            ...(currentLocationPostalCode && { currentLocationPostalCode }),
            ...(currentLocationCity && { currentLocationCity }),
            ...(currentLocationState && { currentLocationState }),
          });
        }

        const currentOrder = getCurrentOrder(state);
        const customerLink = getLinkFromRelationship("customer")(currentOrder);

        return updateCustomer(
          Object.assign({}, customer, {
            url: customerLink,
          })
        );
      }
    )
  );

export const updateCustomerEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_CUSTOMER),
    mergeMap(
      ({
        comments,
        customerId,
        ecommCustomerId,
        email,
        extension,
        firstName,
        lastName,
        phone,
        prefix,
        vehicleInfo,
        creditCards,
        stJudeRoundUp,
        ...action
      }) => {
        const state = getState();
        const isOrderStarted = getOrderStarted(state);
        const { newCustomerPage: shouldUseNewCustomerPage } =
          getNewExperienceFeature(state);
        const newCustomerPageAttributes = {
          vehicleInfo,
          ecommCustomerId,
        };
        if (isOrderStarted && action.url) {
          return fetch(
            Object.assign(action, {
              body: {
                data: {
                  id: phone,
                  attributes: {
                    comments,
                    customerId,
                    email,
                    extension,
                    firstName,
                    lastName,
                    prefix,
                    ...(shouldUseNewCustomerPage && {
                      ...newCustomerPageAttributes,
                    }),
                  },
                },
              },
            }),
            {
              method: "POST",
            }
          ).pipe(
            pluck("response", "data"),
            map(({ attributes, relationships, links }) =>
              updateCustomerSuccess(
                Object.assign({}, attributes, {
                  extension,
                  links,
                  phone,
                  relationships,
                  creditCards,
                  stJudeRoundUp,
                })
              )
            ),
            catchError((error) => of(updateCustomerError(error)))
          );
        }

        const market = getMarket(state);
        const ordersLink = getLinkFromRelationship("orders")(market);
        const storeId = getCurrentStoreId(state);
        const customer = {
          comments,
          customerId,
          email,
          extension,
          firstName,
          lastName,
          phone,
          prefix,
          creditCards,
          stJudeRoundUp,
        };
        return [
          updateCustomerSuccess(customer),
          startOrder({ customer, storeId, url: ordersLink }),
        ];
      }
    )
  );

export const updateCustomerSuccessEpic = (action$) =>
  action$.pipe(ofType(UPDATE_CUSTOMER_SUCCESS), map(deleteOrphanedOrders));

export const getCustomerOrdersEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(GET_CUSTOMER_ORDERS),
    mergeMap((action) => {
      const state = getState();
      const { newOrderHistory: shouldUseNewOrderHistory } =
        getNewExperienceFeature(state);
      return fetch(
        Object.assign(action, {
          query: {
            "page[size]": ORDER_RESULT_LIMIT,
          },
        })
      ).pipe(
        pluck("response", "data"),
        map((pastOrders) =>
          shouldUseNewOrderHistory
            ? setOrderHistory(pastOrders)
            : getCustomerOrdersSuccess(pastOrders)
        ),
        catchError((error) => of(getCustomerOrdersError(error)))
      );
    })
  );

export const getCustomerOrdersSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(GET_CUSTOMER_ORDERS_SUCCESS),
    pluck("pastOrders"),
    map(normalizeServiceMethods),
    mergeMap((pastOrders) =>
      //TODO: convert to state$ after rxjs upgrade
      of(getState()).pipe(
        mergeMap((state) =>
          from(pastOrders).pipe(
            map(({ attributes, id, links }) =>
              Object.assign({ id, links, state, isPastOrder: true }, attributes)
            ),
            mergeMap((props) =>
              [
                getSetStoreAction,
                getCreateOrderAction,
                getCreateAddressSuccessAction,
                getCreateOrderCouponSuccessActions,
                getCreateOrderProductSuccessActions,
              ]
                .map((action) => action(props))
                .reduce(flatten, [])
                .filter(exists)
            )
          )
        )
      )
    )
  );

export const getCustomerTaxCodesEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_CUSTOMER_TAX_CODES),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "data"),
        map((pastTaxCodes) =>
          getCustomerTaxCodesSuccess(
            pastTaxCodes.map(
              ({ attributes: { name: companyName }, id: number }) => ({
                companyName,
                number,
              })
            )
          )
        ),
        catchError((error) => of(getCustomerTaxCodesError(error)))
      )
    )
  );

export const setCustomerDefaultValuesEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(SET_MARKET, RESET_STATE),
    filter(() => {
      const state = getState();
      const customerPrefix = getCustomerPrefix(state);
      const marketProfileFields = getMarketProfileFields(state);

      return (
        customerPrefix === "" &&
        Boolean(marketProfileFields.prefix.defaultValue)
      );
    }),
    mergeMap(() => {
      const state = getState();
      const defaultPrefix = getMarketProfileFields(state).prefix.defaultValue;

      return [updateCustomerSuccess({ prefix: defaultPrefix })];
    })
  );

export const epics = combineEpics(
  initializeCustomerDataEpic,
  getCustomerOrdersEpic,
  getCustomerOrdersSuccessEpic,
  getCustomerTaxCodesEpic,
  lookupCustomerEpic,
  lookupCustomerSuccessEpic,
  setCustomerDefaultValuesEpic,
  updateCustomerEpic,
  updateCustomerSuccessEpic
);
