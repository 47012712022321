/** @jsxImportSource @emotion/react */
import { forwardRef } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getMarketConfigs } from "ducks/market/selectors";
import { getCurrentServiceMethod } from "ducks/order/selectors";

import { setHasUserSelectedStore } from "rtk_redux/slices/customerPageSlice";

import { SERVICE_METHOD } from "constants/order";
import {
  isCarryoutAvailable,
  isDCDAvailable,
  isDeliveryAvailable,
  isPickupAvailable,
} from "modules/availableServiceMethods";

import FutureTimedOrderSelection from "components/FutureTimedOrderSelection";

import { storeItemStyles, storeWrapper } from "../NewCustomerPage.styles";
import { optionsWrapper } from "../NewCustomerPage.styles";

import {
  useIsCurrentStore,
  useSetCurrentOrderStoreAndMethod,
} from "../utils/hooks";
import CarsideDeliveryForm from "./CarsideDeliveryForm";
import DeliveryInstructionsForm from "./DeliveryInstructionsForm";
import ServiceMethodButton from "./ServiceMethodButton";
import StoreMoreInfo from "./StoreMoreInfo";
import StoreWaitTimes from "./StoreWaitTimes";
import { formatStoreAddress } from "./helpers";

const StoreListItem = forwardRef(({ store, t }, ref) => {
  const dispatch = useDispatch();
  const { DEFAULT_DATE_FORMAT } = useSelector(getMarketConfigs);
  const isCurrentStore = useIsCurrentStore(store.StoreID);
  const currentServiceMethod = useSelector(getCurrentServiceMethod);
  const { setCurrentOrderStoreAndServiceMethod, setServiceMethod } =
    useSetCurrentOrderStoreAndMethod();

  const handleChooseStoreAndServiceMethod = (serviceMethod) => {
    dispatch(setHasUserSelectedStore(true));
    if (!isCurrentStore) {
      setCurrentOrderStoreAndServiceMethod({
        storeId: store.StoreID,
        serviceMethod,
      });
    } else {
      setServiceMethod(serviceMethod);
    }
  };

  const CARRYOUT_METHODS = [
    {
      value: SERVICE_METHOD.DCD,
      label: "shared:service_method.dcd",
      disabled: !isDCDAvailable(store),
    },
    {
      value: SERVICE_METHOD.CARRYOUT,
      label: "shared:service_method.carryout",
      disabled: !isCarryoutAvailable(store),
    },
    {
      value: SERVICE_METHOD.PICKUP_WINDOW,
      label: "shared:service_method.pickup_window",
      disabled: !isPickupAvailable(store),
    },
  ];

  const CarryoutMethodButtons = CARRYOUT_METHODS.map((method) => (
    <ServiceMethodButton
      key={method.value}
      serviceMethod={method.value}
      onClick={() => handleChooseStoreAndServiceMethod(method.value)}
      isCurrentStore={isCurrentStore}
      currentServiceMethod={currentServiceMethod}
      disabled={method.disabled}
      label={t(method.label)}
    />
  ));

  const extraInstructions = () => {
    switch (currentServiceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return <DeliveryInstructionsForm t={t} />;
      case SERVICE_METHOD.DCD:
        return <CarsideDeliveryForm t={t} />;
      default:
        return null;
    }
  };

  return (
    <div
      ref={ref}
      css={storeItemStyles}
      className={isCurrentStore ? "currentStore" : ""}
    >
      <div css={storeWrapper}>
        {formatStoreAddress(store.AddressDescription)}
        {store.IsOpen ? (
          <StoreWaitTimes store={store} t={t} />
        ) : (
          <p className="warning">
            {t("customer:locations.store.store_closed")}
          </p>
        )}
      </div>
      {store.IsDeliveryStore && (
        <div css={optionsWrapper}>
          <ServiceMethodButton
            serviceMethod={SERVICE_METHOD.DELIVERY}
            onClick={() =>
              handleChooseStoreAndServiceMethod(SERVICE_METHOD.DELIVERY)
            }
            isCurrentStore={isCurrentStore}
            currentServiceMethod={currentServiceMethod}
            disabled={!isDeliveryAvailable(store)}
            label={t("shared:service_method.delivery")}
          />
        </div>
      )}
      <div css={optionsWrapper}>{CarryoutMethodButtons}</div>
      {isCurrentStore && extraInstructions()}
      {isCurrentStore && (
        <FutureTimedOrderSelection dateFormat={DEFAULT_DATE_FORMAT} t={t} />
      )}
      <StoreMoreInfo store={store} t={t} />
    </div>
  );
});

export default StoreListItem;

StoreListItem.propTypes = {
  store: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  closeCarryoutSection: PropTypes.func,
  closeDeliverySection: PropTypes.func,
  currentStoreID: PropTypes.string,
  setServiceMethod: PropTypes.func,
};
