import { useEffect, useRef, useState } from "react";

import moment from "moment";
import { useSelector } from "react-redux";
import tealium from "tealium-adapter";

import { selectIsCarplayCustomer } from "rtk_redux/slices/customerPageSlice";

const initialUdo = {
  order_source: "", // eVar3
};

export default function useTealium(config) {
  const [initialized, setInitialized] = useState(false);
  const udo = useRef(initialUdo);
  const isCarplay = useSelector(selectIsCarplayCustomer);

  const prepareUDO = () => {
    const page_name = document?.title ?? "Call Center Web";
    const page_url = window?.location?.href ?? "";
    const timestamp_local = moment().format("YYYY-MM-DD HH:mm:ss.SSS Z");

    return {
      code_version_numbers: process.env.REACT_APP_VERSION,
      page_name,
      page_url,
      timestamp_local,
      ...udo.current,
    };
  };

  useEffect(() => {
    if (initialized) return;

    const { tealiumEnv = "dev" } = window.ENVCONFIG;
    const { profile } = config;

    tealium.init(profile, tealiumEnv, prepareUDO);
    setInitialized(true);
  }, [initialized, config]);

  useEffect(() => {
    if (!initialized) return;

    udo.current = {
      ...udo.current,
      order_source: isCarplay ? "CarPlay" : "Centris",
    };
  }, [initialized, isCarplay]);
}
