import React, { Component } from "react";

import PropTypes from "prop-types";

import "./PinDrop.css";

export const MAP_OPTIONS = Object.freeze({
  backgroundColor: "#d5dfe3",
  fullscreenControl: true,
  mapTypeControl: false,
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  zoomControl: true,
});

class PinDrop extends Component {
  constructor(props) {
    super(props);

    this.mapContainer = React.createRef();

    this.dropPin = this.dropPin.bind(this);
  }

  componentDidMount() {
    const { maps, mapCenter, mapOptions, mapZoom } = this.props;

    this.map = new maps.Map(
      this.mapContainer.current,
      Object.assign(
        {},
        {
          center: mapCenter,
          zoom: mapZoom,
        },
        mapOptions
      )
    );

    this.map.addListener("click", (event) => this.dropPin(event.latLng));

    this.marker = new maps.Marker({ position: mapCenter, map: this.map });
  }

  dropPin(latLng) {
    this.marker.setPosition(latLng);
    const position = this.marker.getPosition();
    this.map.panTo(position);
    if (typeof this.props.onChange === "function")
      this.props.onChange(position);
  }

  render() {
    return (
      <div className="grid__cell--1 pin-drop__wrapper">
        <div
          className="pin-drop__map"
          data-quid="store-locator-map"
          ref={this.mapContainer}
        />
      </div>
    );
  }
}

PinDrop.propTypes = {
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  mapCenter: PropTypes.objectOf(PropTypes.number).isRequired,
  mapOptions: PropTypes.objectOf(PropTypes.any),
  mapZoom: PropTypes.number.isRequired,
  onChange: PropTypes.func,
};

PinDrop.defaultProps = {
  mapOptions: MAP_OPTIONS,
  onChange: null,
};

export default PinDrop;
