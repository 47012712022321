import { createSelector, defaultMemoize } from "reselect";

import FOOD_CATEGORY, { COUPON_CATEGORY, PIZZA_CATEGORY } from "constants/menu";

const getId = (state, id = "") => id;

const getById = (collection, id) => collection[id] || {};

export const getAreas = ({ area = [] } = {}) => area;

export const getActiveArea = createSelector(
  [getAreas],
  (areas) => (areas.find(({ active }) => active) || {}).area
);

export const getBases = ({ base = {} } = {}) => base;

export const getBaseById = createSelector([getBases, getId], getById);

export const getBaseNameById = createSelector(
  [getBaseById],
  ({ baseName = "" }) => baseName
);

export const getCategories = ({ category = {} } = {}) => category;

export const getCookingInstructionGroups = ({
  cookingInstructionGroups = {},
} = {}) => cookingInstructionGroups;

export const getCookingInstructionOptions = ({
  cookingInstructionOptions = {},
} = {}) => cookingInstructionOptions;

export const getCoupons = ({ coupon = {} } = {}) => coupon;

export const getCouponById = createSelector([getCoupons, getId], getById);

export const getCouponNameById = createSelector(
  [getCouponById],
  ({ couponName = "" }) => couponName
);

export const getProducts = ({ product = {} } = {}) => product;

export const getProductNameById = (productId = "") =>
  createSelector(
    [getProducts],
    (products) => products[productId]?.productName ?? ""
  );

export const getSides = ({ side = {} } = {}) => side;

export const getSizes = ({ size = {} } = {}) => size;

export const getSizeById = createSelector([getSizes, getId], getById);

export const getSizeNameById = createSelector(
  [getSizeById],
  ({ sizeName = "" }) => sizeName
);

export const getToppings = ({ topping = {} } = {}) => topping;

export const getCheeseToppings = createSelector([getToppings], (toppings) =>
  Object.values(toppings).reduce(
    (cheeseToppings, topping) =>
      topping.isCheese ? [...cheeseToppings, topping] : [...cheeseToppings],
    []
  )
);

export const getVariants = ({ variants = {} } = {}) => variants;

export const getWeightedOptions = ({ weightedOption = {} } = {}) =>
  weightedOption;

export const getCategoriesByType = (filterCallback = () => true) =>
  createSelector(
    [
      getBases,
      getCategories,
      getCoupons,
      getProducts,
      getSides,
      getSizes,
      getToppings,
    ],
    (bases, categories, coupons, products, sides, sizes, toppings) =>
      Object.values(categories)
        .filter(filterCallback)
        .reduce(
          (
            all,
            {
              baseIds,
              categoryId,
              couponIds,
              parentCategory,
              productIds,
              sideIds,
              sizeIds,
              toppingIds,
              weightedOptionIds,
              ...category
            }
          ) =>
            Object.assign(all, {
              [categoryId]: {
                ...category,
                bases: baseIds.map((baseId) => bases[baseId]),
                categoryId,
                coupons: couponIds.map((couponId) => coupons[couponId]),
                parentCategory,
                products: productIds.map((productId) => products[productId]),
                sides: sideIds.map((sideId) => sides[sideId]),
                sizes: sizeIds.map((sizeId) => sizes[sizeId]),
                toppings: toppingIds.map((toppingId) => toppings[toppingId]),
              },
            }),
          {}
        )
  );

export const getCouponCategories = getCategoriesByType(
  ({ parentCategory }) => parentCategory === COUPON_CATEGORY
);

export const getPizzaCategories = getCategoriesByType(
  ({ parentCategory }) => parentCategory === PIZZA_CATEGORY
);

const NOT_EVERYTHING_ELSE = Object.freeze([
  "PreconfiguredProducts",
  "GroupOrdering",
  "PopularItems",
  "Pizza",
  "Coupons",
  "CouponsByProduct",
]);

export const getEverythingElseCategories = getCategoriesByType(
  ({ categoryId, parentCategory }) =>
    !NOT_EVERYTHING_ELSE.includes(categoryId) &&
    !NOT_EVERYTHING_ELSE.includes(parentCategory)
);

//TODO: write tests
export const getCategoryType = (categoryId) =>
  createSelector([getCategories], (categories) => {
    const { parentCategory } = categories[categoryId] || {};
    return parentCategory === FOOD_CATEGORY ? categoryId : parentCategory;
  });

export const getProductCategoryId = defaultMemoize((productId = "") =>
  createSelector([getCategories], (categories) => {
    const categoryProductIds = Object.entries(categories).reduce(
      (all, [categoryId, { productIds }]) =>
        Object.assign(all, {
          [categoryId]: productIds,
        }),
      {}
    );

    let foundCategoryId = "";
    Object.entries(categoryProductIds).some(([categoryId, productIds]) => {
      const foundCategory = productIds.includes(productId);
      if (foundCategory) foundCategoryId = categoryId;
      return foundCategory;
    });

    const { categoryId = "" } = categories[foundCategoryId] || {};
    return categoryId;
  })
);
