import { createSelector } from "reselect";

export const getPlaces = ({ places = {} } = {}) => places;

export const getPlacesValues = createSelector(
  [getPlaces],
  ({
    data: { attributes: { placesByStreetAndCityAndMarket } } = {
      attributes: { placesByStreetAndCityAndMarket: [] },
    },
  }) => placesByStreetAndCityAndMarket
);
