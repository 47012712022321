import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, filter, map, mergeMap, pluck } from "rxjs/operators";

import { RESET_STATE } from "ducks/configuration";

import withValidation from "modules/elValidadore";
import uuid from "modules/uuid";
import { idValidators, urlValidators } from "modules/validators";

import { buildAddressString } from "./helpers";

const SCOPE = "order-entry/address/";

export const CREATE_ADDRESS = `${SCOPE}CREATE_ADDRESS`;
export const CREATE_ADDRESS_SUCCESS = `${SCOPE}CREATE_ADDRESS_SUCCESS`;
export const CREATE_ADDRESS_ERROR = `${SCOPE}CREATE_ADDRESS_ERROR`;
export const UPDATE_ADDRESS = `${SCOPE}UPDATE_ADDRESS`;
export const UPDATE_ADDRESS_SUCCESS = `${SCOPE}UPDATE_ADDRESS_SUCCESS`;
export const UPDATE_ADDRESS_ERROR = `${SCOPE}UPDATE_ADDRESS_ERROR`;

export const createAddress = withValidation(
  ({
    active = false,
    addressId = uuid(),
    addressNickname = "",
    addressType = "",
    city = "",
    deliveryInstructions = "",
    latitude = null,
    longitude = null,
    neighborhood = "",
    orderId = null,
    organizationName = "",
    postalCode = "",
    regionCode = "",
    street = "",
    streetName = "",
    streetNumber = "",
    unitNumber = "",
    url = "",
  } = {}) => ({
    type: CREATE_ADDRESS,
    active,
    addressId,
    addressNickname,
    addressType,
    city,
    deliveryInstructions,
    latitude,
    longitude,
    neighborhood,
    orderId,
    organizationName,
    postalCode,
    region: regionCode,
    street,
    streetName,
    streetNumber,
    unitNumber,
    url,
  }),
  {
    orderId: idValidators,
    url: urlValidators,
  }
);

export const createAddressSuccess = withValidation(
  ({
    active = false,
    address = "",
    addressId = null,
    addressNickname = "",
    addressType = "",
    city = "",
    deliveryInstructions = "",
    latitude = null,
    longitude = null,
    neighborhood = "",
    orderId = null,
    organizationName = "",
    postalCode = "",
    region = "",
    street = "",
    streetName = "",
    streetNumber = "",
    unitNumber = "",
  } = {}) => ({
    type: CREATE_ADDRESS_SUCCESS,
    active,
    address,
    addressId,
    addressNickname,
    addressType,
    city,
    deliveryInstructions,
    latitude,
    longitude,
    neighborhood,
    orderId,
    organizationName,
    postalCode,
    regionCode: region,
    street,
    streetName,
    streetNumber,
    unitNumber,
  }),
  {
    addressId: idValidators,
    orderId: idValidators,
  }
);

export function createAddressError(error) {
  return {
    type: CREATE_ADDRESS_ERROR,
    error,
  };
}

export const updateAddress = withValidation(
  ({
    active = false,
    address = "",
    addressId = null,
    addressLine4 = "",
    addressNickname = "",
    addressType = "",
    city = "",
    deliveryInstructions = "",
    latitude = null,
    longitude = null,
    neighborhood = "",
    organizationName = "",
    postalCode = "",
    regionCode = "",
    street = "",
    streetName = "",
    streetNumber = "",
    unitNumber = "",
    url = "",
  } = {}) => ({
    type: UPDATE_ADDRESS,
    active,
    address,
    addressId,
    addressLine4,
    addressNickname,
    addressType,
    city,
    deliveryInstructions,
    latitude,
    longitude,
    neighborhood,
    organizationName,
    postalCode,
    region: regionCode,
    street,
    streetName,
    streetNumber,
    unitNumber,
    url,
  }),
  {
    addressId: idValidators,
    url: urlValidators,
  }
);

export const updateAddressSuccess = withValidation(
  ({
    active = false,
    address = "",
    addressId = null,
    addressNickname = "",
    addressType = "",
    city = "",
    deliveryInstructions = "",
    latitude = null,
    longitude = null,
    neighborhood = "",
    organizationName = "",
    postalCode = "",
    region = "",
    street = "",
    streetName = "",
    streetNumber = "",
    unitNumber = "",
  } = {}) => ({
    type: UPDATE_ADDRESS_SUCCESS,
    active,
    address,
    addressId,
    addressNickname,
    addressType,
    city,
    deliveryInstructions,
    latitude,
    longitude,
    neighborhood,
    organizationName,
    postalCode,
    regionCode: region,
    street,
    streetName,
    streetNumber,
    unitNumber,
  }),
  {
    addressId: idValidators,
  }
);

export function updateAddressError(error) {
  return {
    type: UPDATE_ADDRESS_ERROR,
    error,
  };
}

export const initialState = {};

export default function reducer(
  state = initialState,
  { orderId, type, url, ...action } = {}
) {
  switch (type) {
    case CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        [action.addressId]: action,
      });
    case UPDATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        [action.addressId]: Object.assign({}, state[action.addressId], action),
      });
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export const createAddressEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(CREATE_ADDRESS),
    filter(({ active }) => active),
    mergeMap(
      ({
        address,
        addressNickname,
        addressType,
        city,
        deliveryInstructions,
        latitude,
        longitude,
        neighborhood,
        orderId,
        organizationName,
        postalCode,
        region,
        street,
        streetName,
        streetNumber,
        unitNumber,
        ...action
      }) =>
        fetch(
          Object.assign(action, {
            body: {
              data: {
                attributes: {
                  addressNickname,
                  addressType,
                  city,
                  deliveryInstructions,
                  longitude,
                  latitude,
                  neighborhood,
                  organizationName,
                  postalCode,
                  region,
                  street,
                  streetName,
                  streetNumber,
                  unitNumber,
                },
              },
            },
          }),
          {
            method: "POST",
          }
        ).pipe(
          pluck("response", "data"),
          map(({ attributes, id }) =>
            createAddressSuccess(
              Object.assign({}, attributes, {
                address: buildAddressString(attributes),
                addressId: id,
                orderId,
              })
            )
          ),
          catchError((error) => of(createAddressError(error)))
        )
    )
  );

export const updateAddressEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ADDRESS),
    mergeMap(
      ({
        active,
        address,
        addressId,
        addressLine4,
        addressNickname,
        addressType,
        city,
        deliveryInstructions,
        latitude,
        longitude,
        neighborhood,
        orderId,
        organizationName,
        postalCode,
        region,
        street,
        streetName,
        streetNumber,
        unitNumber,
        ...action
      }) =>
        fetch(
          Object.assign(action, {
            body: {
              data: {
                attributes: {
                  addressLine4,
                  addressNickname,
                  addressType,
                  city,
                  deliveryInstructions,
                  latitude,
                  longitude,
                  neighborhood,
                  organizationName,
                  postalCode,
                  region,
                  street,
                  streetName,
                  streetNumber,
                  unitNumber,
                },
              },
            },
          }),
          {
            method: "PUT",
          }
        ).pipe(
          pluck("response", "data", "attributes"),
          map((attributes) =>
            updateAddressSuccess(
              Object.assign({}, attributes, {
                active,
                address: buildAddressString(attributes),
                addressId,
              })
            )
          ),
          catchError((error) => of(updateAddressError(error)))
        )
    )
  );

export const epics = combineEpics(createAddressEpic, updateAddressEpic);
