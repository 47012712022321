import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setMessage } from "ducks/message";
import { getActiveOrderProduct } from "ducks/order/selectors";
import { updateOrderProductPastaSauce } from "ducks/orderProduct";
import { getActiveOrderProductToppingsWithoutDefaults } from "ducks/orderProduct/selectors";
import { getSelfLink } from "selectors/related";

import { STATUS } from "constants/message";
import { AREAS } from "constants/order";

const PastaSauceButton = ({
  sauces,
  sauceName,
  sauceId,
  sauceToppingCode,
  onlyOneAvailableSauce = false,
}) => {
  const dispatch = useDispatch();
  const activeOrderProduct = useSelector(getActiveOrderProduct);
  const activeOrderProductLink = getSelfLink(activeOrderProduct);

  const activeOrderToppingsWithoutDefaults = useSelector(
    getActiveOrderProductToppingsWithoutDefaults
  );

  const isActive =
    onlyOneAvailableSauce ||
    activeOrderToppingsWithoutDefaults?.[sauceId]?.[AREAS.WHOLE] > 0;

  const handleClick = () => {
    if (isActive) {
      return dispatch(
        setMessage({
          message: "negative:pasta_sauce_cannot_be_removed",
          status: STATUS.WARNING,
        })
      );
    }

    dispatch(
      updateOrderProductPastaSauce({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        sauces,
        sauceToppingCode,
      })
    );
  };

  return (
    <button
      className={classNames("btn", { "btn--fill": isActive })}
      type="button"
      onClick={handleClick}
    >
      {sauceName}
    </button>
  );
};

PastaSauceButton.propTypes = {
  sauces: PropTypes.array.isRequired,
  sauceName: PropTypes.string.isRequired,
  sauceId: PropTypes.string.isRequired,
  sauceToppingCode: PropTypes.string.isRequired,
  onlyOneAvailableSauce: PropTypes.bool,
};

export default PastaSauceButton;
