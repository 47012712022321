/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import EstimatedWaitTimes from "components/EstimatedWaitTimes";
import { SERVICE_METHOD } from "constants/order";

const StoreWaitTimes = ({ store, t }) => {
  return (
    <div>
      {store.IsDeliveryStore && (
        <p>
          <b>
            <span>{`${SERVICE_METHOD.DELIVERY}: `}</span>
            <EstimatedWaitTimes
              currentEstimatedWaitMinutes={
                store.ServiceMethodEstimatedWaitMinutes?.Delivery
              }
              t={t}
            />
          </b>
        </p>
      )}
      <p>
        <b>
          <span>{`${SERVICE_METHOD.CARRYOUT}: `}</span>
          <EstimatedWaitTimes
            currentEstimatedWaitMinutes={
              store.ServiceMethodEstimatedWaitMinutes?.Carryout
            }
            t={t}
          />
        </b>
      </p>
    </div>
  );
};

export default StoreWaitTimes;

StoreWaitTimes.propTypes = {
  store: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};
