/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";

const visuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin-bottom: 16px;
`;

// Displays an toggle-open or closed icon depended on whether the parent component is expanded
// Use this as a descendant of ExpndedClickableArea to make an entire div clickable semantically
export const ExpandableToggleIcon = ({ t, onClick, isExpanded, ...props }) => {
  const toggleButtonText = isExpanded
    ? "shared:toggle.less"
    : "shared:toggle.more";
  return (
    <div {...props}>
      {/* Screen-reader only button to keep html semantically correct
          while ExpandedClickableArea makes the entire div clickable */}
      <button css={visuallyHidden} onClick={onClick}>
        {`${t(toggleButtonText)} ${t("shared:toggle.info")}`}
      </button>
      {isExpanded ? (
        <span
          className="text--bigger toggle--closed arrow_icon"
          data-icon="keyboard_arrow_down"
        />
      ) : (
        <span
          className="text--bigger toggle--open"
          data-icon="keyboard_arrow_up"
        />
      )}
    </div>
  );
};

export default ExpandableToggleIcon;

ExpandableToggleIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};
