/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

const ServiceMethodButton = ({
  serviceMethod,
  isCurrentStore = false,
  currentServiceMethod,
  label,
  ...props
}) => {
  const isSelectedMethod =
    isCurrentStore && currentServiceMethod === serviceMethod;
  return (
    <button
      className={`btn btn--fill ${
        isSelectedMethod ? "selected" : "btn-primary"
      } `}
      {...props}
    >
      {label}
    </button>
  );
};

export default ServiceMethodButton;

ServiceMethodButton.propTypes = {
  serviceMethod: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isCurrentStore: PropTypes.bool,
  currentServiceMethod: PropTypes.string,
  label: PropTypes.string,
};
