import { createSelector } from "reselect";

import { getMarketWaterfallUpsells } from "ducks/market/selectors";
import { getCategories, getProducts } from "ducks/menu/selectors";
import { getCurrentOrderProducts } from "ducks/order/selectors";

export const getWaterfallUpsell = ({
  waterfallUpsell = { seen: false },
} = {}) => waterfallUpsell;

export const getIsWaterfallUpsellSeen = createSelector(
  [getWaterfallUpsell],
  ({ seen }) => seen
);

export const getOrderWaterfallUpsells = createSelector(
  [
    getIsWaterfallUpsellSeen,
    getMarketWaterfallUpsells,
    getProducts,
    getCurrentOrderProducts,
    getCategories,
  ],
  (
    isWaterfallUpsellSeen,
    marketUpsells,
    menuProducts,
    orderProducts,
    categories
  ) => {
    if (isWaterfallUpsellSeen || !marketUpsells.length) return [];
    const orderProductCategories = [
      ...new Set(
        Object.values(orderProducts)
          .map(({ parts = {} } = {}) =>
            Object.values(parts)
              .map(({ productCode }) => {
                const [orderProductCategory] = Object.entries(categories).find(
                  ([, { productIds = [] }]) => productIds.includes(productCode)
                ) || [""];

                return orderProductCategory;
              })
              .filter(Boolean)
          )
          .flat()
      ),
    ];

    const upsellProducts =
      marketUpsells.find((upsells) => {
        const upsellsCategories = new Set(
          upsells.map(({ categoryId }) => categoryId)
        );

        return orderProductCategories.every(
          (category) => !upsellsCategories.has(category)
        );
      }) || [];

    return upsellProducts
      .filter(({ productId, sizeId }) => {
        const isValidProduct = Boolean(menuProducts[productId]);
        const isValidSize =
          !sizeId ||
          (isValidProduct && menuProducts[productId].sizeIds.includes(sizeId));
        return isValidProduct && isValidSize;
      })
      .map((upsellProduct) => {
        const { productDescription, productName } =
          menuProducts[upsellProduct.productId];
        return {
          ...upsellProduct,
          productDescription,
          productName,
        };
      });
  }
);
