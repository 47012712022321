export const exitOptions = Object.freeze([
  {
    key: "wrong-phone-line",
    label: "exit:reason.wrong_phone_line",
  },
  {
    key: "specials-inquiry",
    label: "exit:reason.specials_inquiry",
  },
  {
    key: "delivery-time-too-long",
    label: "exit:reason.delivery_time_too_long",
  },
  {
    key: "out-of-delivery-area",
    label: "exit:reason.out_of_delivery_area",
  },
  {
    key: "payment-declined",
    label: "exit:reason.payment_declined",
  },
  {
    key: "unknown",
    label: "exit:reason.unknown",
  },
]);

export const defaultExitLabel = "exit:reason.wrong_phone_line";

export default exitOptions;
