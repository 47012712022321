import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ACI } from "constants/finish";

export const externalApi = createApi({
  reducerPath: "externalApi",
  baseQuery: fetchBaseQuery({ baseUrl: "" }),
  endpoints: (builder) => ({
    tokenizeCardNumber: builder.query({
      async queryFn(
        { url, method, headers, body, tokenType },
        _api,
        _options,
        baseQuery
      ) {
        const { data, error } = await baseQuery({
          url,
          method,
          headers,
          body,
        });
        if (error) return { error };
        return tokenType === ACI
          ? {
              data: {
                token: data.TOKEN_ID,
                maskedPan: data.ACCT_NUM,
                tokenType,
              },
            }
          : {
              data: { token: data.token, maskedPan: data.maskedPan, tokenType },
            };
      },
    }),
  }),
});

export const { useLazyTokenizeCardNumberQuery } = externalApi;
