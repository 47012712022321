import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/rnc/";

export const RNC_LOOKUP_NUMBER = `${SCOPE}RNC_LOOKUP_NUMBER`;
export const RNC_LOOKUP_NUMBER_ERROR = `${SCOPE}RNC_LOOKUP_NUMBER_ERROR`;
export const RNC_LOOKUP_NUMBER_SUCCESS = `${SCOPE}RNC_LOOKUP_NUMBER_SUCCESS`;
export const RNC_SET_COMPANY_NAME = `${SCOPE}RNC_SET_COMPANY_NAME`;
export const RNC_SET_NUMBER = `${SCOPE}RNC_SET_NUMBER`;
export const RNC_SET_RECEIPT_TYPE = `${SCOPE}RNC_SET_RECEIPT_TYPE`;

export const COMMERCIAL_ENTITY_SERVICE_URL =
  "/commercial-entity-service/commercialEntity/commercialentity";

export function rncLookupNumber(rncNumber = "") {
  return {
    type: RNC_LOOKUP_NUMBER,
    power: true,
    rncNumber,
    url: `${COMMERCIAL_ENTITY_SERVICE_URL}/${rncNumber}`,
  };
}

export function rncLookupNumberError(error) {
  return {
    type: RNC_LOOKUP_NUMBER_ERROR,
    error,
  };
}

export function rncLookupNumberSuccess({ name = "", taxId = "" } = {}) {
  return {
    type: RNC_LOOKUP_NUMBER_SUCCESS,
    rncCompanyName: name,
    rncNumber: taxId,
  };
}

export function rncSetCompanyName(rncCompanyName = "") {
  return {
    type: RNC_SET_COMPANY_NAME,
    rncCompanyName,
  };
}

export function rncSetNumber(rncNumber = "") {
  return {
    type: RNC_SET_NUMBER,
    rncNumber,
  };
}

export function rncSetReceiptType(rncReceiptType = "") {
  return {
    type: RNC_SET_RECEIPT_TYPE,
    rncReceiptType,
  };
}

export const initialState = {
  rncCompanyName: "",
  rncNumber: "",
  rncReceiptType: "",
};

export default function reducer(state = initialState, action = {}) {
  const { rncCompanyName, rncNumber, rncReceiptType } = action;
  switch (action.type) {
    case RNC_SET_COMPANY_NAME:
      return Object.assign({}, state, { rncCompanyName });
    case RNC_SET_NUMBER:
      return Object.assign({}, state, { rncNumber });
    case RNC_SET_RECEIPT_TYPE:
      return Object.assign({}, state, { rncReceiptType });
    default:
      return state;
  }
}

export const rncLookupNumberEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(RNC_LOOKUP_NUMBER),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        mergeMap((response) =>
          response && response.name
            ? [rncLookupNumberSuccess(response)]
            : // Service returns a 204 with an empty response
              // for numbers that are properly formatted, but
              // fail to match a company.
              [
                rncLookupNumberError({
                  response: {
                    errors: [
                      {
                        title: "no_matching_company",
                      },
                    ],
                  },
                }),
              ]
        ),
        catchError((error) => of(rncLookupNumberError(error)))
      )
    )
  );

export const rncLookupNumberSuccessEpic = (action$) =>
  action$.pipe(
    ofType(RNC_LOOKUP_NUMBER_SUCCESS),
    mergeMap(({ rncCompanyName, rncNumber }) => [
      rncSetCompanyName(rncCompanyName),
      rncSetNumber(rncNumber),
    ])
  );

export const rncSetReceiptTypeEpic = (action$) =>
  action$.pipe(
    ofType(RNC_SET_RECEIPT_TYPE),
    mergeMap(() => [rncSetCompanyName(""), rncSetNumber("")])
  );

export const epics = combineEpics(
  rncLookupNumberEpic,
  rncLookupNumberSuccessEpic,
  rncSetReceiptTypeEpic
);
