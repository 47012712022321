import exists from "modules/exists";

export default function deepExtend(base = {}, ...sources) {
  let clone;
  const length = sources.length;

  if (length === 0) return base;

  const extendSource = (extendedObject, source) => {
    const needsCloning = (key) => extendedObject[key] !== source[key];
    const extendNode = (key) => {
      const src = extendedObject[key];
      const copy = source[key];

      if (copy && Object.prototype.toString.call(copy) === "[object Object]") {
        clone =
          src && Object.prototype.toString.call(src) === "[object Object]"
            ? src
            : {};
        extendedObject[key] = deepExtend(clone, copy);
      } else if (copy !== undefined) {
        extendedObject[key] = copy;
      }
    };

    if (Array.isArray(source)) return source;

    if (source !== null) {
      Object.keys(source).filter(needsCloning).forEach(extendNode);
    }

    return extendedObject;
  };

  return sources.filter(exists).reduce(extendSource, base);
}
