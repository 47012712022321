import React, { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import { orderHistorySubheadings } from "constants/customer";

import OrderDetails from "../OrderDetails";
import OrderLineItems from "../OrderLineItems";

class HistoricalOrder extends PureComponent {
  constructor(props) {
    super(props);

    this.handleReorderClick = this.handleReorderClick.bind(this);
  }

  handleReorderClick() {
    const { orderLink, reorder } = this.props;

    reorder(orderLink);
  }

  render() {
    const { storeOrderId, t } = this.props;
    return (
      <Fragment>
        <div className="grid order">
          <OrderLineItems {...this.props} />

          <OrderDetails headings={orderHistorySubheadings} {...this.props} />
        </div>

        <button
          className="btn btn--primary reorder"
          data-quid={`reorder-${storeOrderId}`}
          onClick={this.handleReorderClick}
          type="button"
        >
          {t("customer:orders:order_history:reorder")}
        </button>
      </Fragment>
    );
  }
}

HistoricalOrder.propTypes = {
  orderLink: PropTypes.string.isRequired,
  reorder: PropTypes.func.isRequired,
  storeOrderId: PropTypes.string.isRequired,
};

export default HistoricalOrder;
