import React, { Fragment } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getNewExperienceFeature } from "ducks/market/selectors";
import { getActiveOrderProductAvailableSizes } from "ducks/order/selectors";
import { resetActiveOrderProduct } from "ducks/orderProduct";

import { OPTION, defaultArea } from "constants/order";
import uuid from "modules/uuid";

import Base from "components/Base";
import Section from "components/EverythingElse/Section";
import Option from "components/Option";
import Page from "components/Page";
import Product from "components/Product";
import Size from "components/Size";
import Tabs from "components/Tabs";

const EverythingElse = ({
  activeArea,
  activeOrderProductAvailableOptions,
  activeOrderProductAvailableOptionsByArea,
  activeOrderProductBaseId,
  activeOrderProductOptionIds,
  activeOrderProductProducts,
  activeOrderProductSides,
  activeOrderProductSizeId,
  activeOrderProductToppings,
  bases,
  deleteOrderProductOption,
  goToEverythingElse,
  hasToppings,
  meats,
  mergeBasesAndProducts,
  nonMeats,
  products,
  sauces,
  setOrderProduct,
  sidebar,
  sides,
  sizes,
  t,
  tabs,
  title,
}) => {
  const handleDynamicSelection = ({
    data,
    activeOrderProduct = { baseIds: [], sizeIds: [] },
  }) => {
    if (data.baseId || data.sizeId) {
      const { baseIds, sizeIds } = activeOrderProduct;
      const id = data.baseId ?? data.sizeId;
      const idsType = data.baseId ? "baseIds" : "sizeIds";
      const choiceIdx = activeOrderProduct[idsType].findIndex(
        (base) => base === id
      );
      const baseId = baseIds[choiceIdx] || baseIds[0];
      const sizeId = sizeIds[choiceIdx] || sizeIds[0];
      const canSetNewBaseAndSize = choiceIdx >= 0 && baseId && sizeId;
      if (canSetNewBaseAndSize) {
        setOrderProduct({
          sizeId,
          baseId,
        });
      } else {
        setOrderProduct(data);
      }
    } else {
      const selectedProduct = products.find(
        (product) => product.productId === data.productId
      );

      const baseId =
        selectedProduct.baseIds.find(
          (base) => base === activeOrderProductBaseId
        ) || selectedProduct.baseIds[0];
      const sizeId =
        selectedProduct.sizeIds.find(
          (size) => size === activeOrderProductSizeId
        ) || selectedProduct.sizeIds[0];

      setOrderProduct({
        ...data,
        baseId,
        sizeId,
      });
    }
  };

  const handleSetOrderProduct = (data) => {
    const hasValidId = data.baseId || data.sizeId || data.productId;

    if (hasValidId) {
      const activeOrderProductId = activeOrderProductProducts["1/1"];
      const activeOrderProduct = products.find(
        (product) => product.productId === activeOrderProductId
      );
      handleDynamicSelection({ data, activeOrderProduct });
    } else {
      setOrderProduct(data);
    }
  };

  const dispatch = useDispatch();
  const { productSelectionFirst: useProductSelectionFirst } = useSelector(
    getNewExperienceFeature
  );
  const tabbar = <Tabs goTo={goToEverythingElse} t={t} tabs={tabs} />;
  const activeOrderProductAvailableSizes = useSelector(
    getActiveOrderProductAvailableSizes
  );

  const activeOrderProductAvailableSizeCodes =
    activeOrderProductAvailableSizes.map(({ sizeCode }) => sizeCode);

  const actions = (
    <button
      className="btn btn--fill btn--positive"
      data-icon="add"
      data-quid="add-product"
      onClick={() => dispatch(resetActiveOrderProduct())}
    >
      {t("shared:action.new")}
    </button>
  );

  const activeOptionProps = {
    activeArea,
    activeOrderProductAvailableOptions: Object.keys(
      activeOrderProductAvailableOptions
    ).length
      ? activeOrderProductAvailableOptions
      : activeOrderProductAvailableOptionsByArea,
    activeOrderProductOptionIds,
    activeOrderProductOptions: Object.assign(
      {},
      activeOrderProductToppings,
      activeOrderProductSides
    ),
  };
  const optionActions = { deleteOrderProductOption, setOrderProduct };
  const getProducts = () =>
    products.length > 0 && (
      <Section heading={t("food:products")}>
        <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
          {products.map((product) => (
            <Product
              activeOrderProductProducts={activeOrderProductProducts}
              key={product.productId}
              mergeBasesAndProducts={mergeBasesAndProducts}
              setOrderProduct={handleSetOrderProduct}
              {...product}
            />
          ))}
        </div>
      </Section>
    );

  const getSizes = () =>
    sizes.length > 0 && (
      <Section heading={t("food:sizes")}>
        <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
          {sizes.map((size) => (
            <Size
              activeOrderProductSizeId={activeOrderProductSizeId}
              key={size.sizeId}
              setOrderProduct={handleSetOrderProduct}
              disabled={
                !activeOrderProductAvailableSizeCodes.includes(size.sizeCode)
              }
              {...size}
            />
          ))}
        </div>
      </Section>
    );

  return (
    <Page
      actions={actions}
      id="everything-else"
      sidebar={sidebar}
      tabs={tabbar}
      title={title}
    >
      {useProductSelectionFirst ? getProducts() : getSizes()}

      {!mergeBasesAndProducts && bases.length > 0 && (
        <Section heading={t("food:bases")}>
          <div className="btn-group btn-group--justified btn-group--unlimited toggleable">
            {bases.map((base) => (
              <Base
                activeOrderProductBaseId={activeOrderProductBaseId}
                key={base.baseId}
                setOrderProduct={handleSetOrderProduct}
                {...base}
              />
            ))}
          </div>
        </Section>
      )}

      {useProductSelectionFirst ? getSizes() : getProducts()}

      {hasToppings && (
        <Section heading={t("food:toppings.title")}>
          {meats.length > 0 && (
            <Fragment>
              <header className="title-group">
                <h3 data-quid={`heading-meats`}>{t("food:toppings.meats")}</h3>
              </header>

              <div className="btn-group btn-group--justified btn-group--unlimited">
                {meats.map(
                  ({
                    toppingCode,
                    toppingDescription,
                    toppingId,
                    toppingName,
                  }) => (
                    <Option
                      key={toppingId}
                      optionCode={toppingCode}
                      optionDescription={toppingDescription}
                      optionId={toppingId}
                      optionName={toppingName}
                      optionType={OPTION.TOPPING}
                      {...optionActions}
                      {...activeOptionProps}
                    />
                  )
                )}
              </div>

              <br />
            </Fragment>
          )}

          {nonMeats.length > 0 && (
            <Fragment>
              <header className="title-group">
                <h3 data-quid={`heading-non-meats`}>
                  {t("food:toppings.non_meats")}
                </h3>
              </header>

              <div className="btn-group btn-group--justified btn-group--unlimited">
                {nonMeats.map(
                  ({
                    toppingCode,
                    toppingDescription,
                    toppingId,
                    toppingName,
                  }) => (
                    <Option
                      key={toppingId}
                      optionCode={toppingCode}
                      optionDescription={toppingDescription}
                      optionId={toppingId}
                      optionName={toppingName}
                      optionType={OPTION.TOPPING}
                      {...optionActions}
                      {...activeOptionProps}
                    />
                  )
                )}
              </div>

              <br />
            </Fragment>
          )}

          {sauces.length > 0 && (
            <Fragment>
              <header className="title-group">
                <h3 data-quid={`heading-sauces`}>
                  {t("food:toppings.sauces")}
                </h3>
              </header>

              <div className="btn-group btn-group--justified btn-group--unlimited">
                {sauces.map(
                  ({
                    toppingCode,
                    toppingDescription,
                    toppingId,
                    toppingName,
                  }) => (
                    <Option
                      key={toppingId}
                      optionCode={toppingCode}
                      optionDescription={toppingDescription}
                      optionId={toppingId}
                      optionName={toppingName}
                      optionType={OPTION.TOPPING}
                      {...optionActions}
                      {...activeOptionProps}
                    />
                  )
                )}
              </div>

              <br />
            </Fragment>
          )}
        </Section>
      )}

      {sides.length > 0 && (
        <Section heading={t("food:sides")}>
          <div className="btn-group btn-group--justified btn-group--unlimited">
            {sides.map(({ sideCode, sideDescription, sideId, sideName }) => (
              <Option
                key={sideId}
                optionCode={sideCode}
                optionDescription={sideDescription}
                optionId={sideId}
                optionName={sideName}
                optionType={OPTION.SIDE}
                {...optionActions}
                {...activeOptionProps}
              />
            ))}
          </div>
        </Section>
      )}
    </Page>
  );
};

EverythingElse.propTypes = {
  activeArea: PropTypes.string,
  activeOrderProductAvailableOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductAvailableOptionsByArea: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductBaseId: PropTypes.string,
  activeOrderProductOptionIds: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductProducts: PropTypes.objectOf(PropTypes.string),
  activeOrderProductSides: PropTypes.objectOf(PropTypes.number),
  activeOrderProductSizeId: PropTypes.string,
  activeOrderProductToppings: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.number)
  ),
  bases: PropTypes.arrayOf(PropTypes.object),
  deleteOrderProductOption: PropTypes.func.isRequired,
  goToEverythingElse: PropTypes.func,
  hasToppings: PropTypes.bool,
  meats: PropTypes.arrayOf(PropTypes.object),
  mergeBasesAndProducts: PropTypes.bool,
  nonMeats: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  sauces: PropTypes.arrayOf(PropTypes.object),
  setOrderProduct: PropTypes.func.isRequired,
  sidebar: PropTypes.node,
  sides: PropTypes.arrayOf(PropTypes.object),
  sizes: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      icon: PropTypes.string,
      id: PropTypes.string,
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};

EverythingElse.defaultProps = {
  activeArea: defaultArea,
  activeOrderProductAvailableOptions: {},
  activeOrderProductAvailableOptionsByArea: {},
  activeOrderProductBaseId: "",
  activeOrderProductOptionIds: [],
  activeOrderProductProducts: {},
  activeOrderProductSides: {},
  activeOrderProductSizeId: "",
  activeOrderProductToppings: {},
  bases: [],
  goToEverythingElse: () => {},
  hasToppings: false,
  meats: [],
  mergeBasesAndProducts: false,
  nonMeats: [],
  products: [],
  sauces: [],
  sidebar: null,
  sides: [],
  sizes: [],
  t: () => {},
  tabs: [
    {
      active: true,
      id: uuid(),
      path: "everything-else",
      text: "Everything Else",
    },
  ],
  title: "",
};

export default EverythingElse;
