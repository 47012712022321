/** @jsxImportSource @emotion/react */
import { useState } from "react";

import PropTypes from "prop-types";

import { moreInfoStyles, storeHours } from "../NewCustomerPage.styles";

const StoreMoreInfo = ({ store, t }) => {
  const [expanded, setExpanded] = useState(false);
  const { ServiceHoursDescription } = store;

  const formatHours = (hours) => {
    return hours
      ? hours.split("\n").map((item) => <dd key={item}>{item}</dd>)
      : null;
  };

  const hasServiceHours = store.hasOwnProperty("ServiceHoursDescription");
  const toggleButtonText = expanded
    ? "shared:toggle.less"
    : "shared:toggle.more";

  return (
    <div css={moreInfoStyles}>
      <button onClick={() => setExpanded(!expanded)}>
        {`${t(toggleButtonText)} ${t("shared:toggle.info")}`}
      </button>
      {expanded && (
        <>
          <address>
            <b>{store.Phone}</b>
          </address>
          {hasServiceHours && (
            <dl css={storeHours}>
              <div>
                <dt>{t("shared:service_method.carryout")}</dt>
                {formatHours(ServiceHoursDescription?.Carryout)}
              </div>
              <div>
                <dt>{t("shared:service_method.delivery")}</dt>
                {formatHours(ServiceHoursDescription?.Delivery)}
              </div>
              <div>
                <dt>{t("shared:service_method.dcd")}</dt>
                {formatHours(ServiceHoursDescription?.DriveUpCarryout)}
              </div>
            </dl>
          )}
        </>
      )}
    </div>
  );
};

export default StoreMoreInfo;

StoreMoreInfo.propTypes = {
  store: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};
