import { css } from "@emotion/react";

export default css`
  .coupons.body__page {
    .page__section {
      .btn-group {
        margin-bottom: 1rem;
      }

      .coupon-entry-form {
        display: flex;
        flex-flow: row wrap;
      }

      .coupon-entry-form__button {
        flex-shrink: 0;
        margin-left: 1rem;
      }

      .coupon-entry-form__input {
        flex: 1;
      }

      .coupon-entry-form__label {
        flex: 0 0 100%;
      }

      .coupon__group {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem 0 0;

        .coupon {
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          flex: 1;
          flex-basis: calc(33.3% - 3rem);
          margin: 0 1rem 1rem 0;
          margin-bottom: 1rem;
          max-width: calc(33.3% - 3rem);
          padding: 1rem;

          &.is-active {
            background-color: var(--dom-blue);
            color: #fff;
          }

          &.status--positive,
          &.status--warning {
            flex-direction: row;
          }

          &:not(.is-active, .status--warning, .status--positive) {
            background-color: var(--interactive-bg);
            color: var(--interactive-fg);

            &:hover {
              color: var(--dom-red);
            }
          }
          .coupon-info {
            display: flex;
            flex-direction: column;
            flex: 1;
          }

          .body {
            flex: 1;
            margin-bottom: 1rem;
            transition: color 0.1s ease;
          }

          .footer {
            display: flex;

            > * {
              flex: 1;
              font-weight: bold;

              &:first-of-type {
                &::before {
                  content: "(";
                }

                &::after {
                  content: ")";
                }
              }
            }
          }

          @media screen and (max-width: 80em) {
            flex-basis: calc(50% - 3rem);
            max-width: calc(50% - 3rem);
          }

          @media screen and (max-width: 40em) {
            flex-basis: 100%;
            max-width: unset;
          }
        }
      }
      .dashed {
        border-bottom: 5px dashed var(--dpzBlue);
        text-align: center;
        height: 8px;
        margin-bottom: 1.5rem;
      }
      .dashed span {
        background: #fff;
        color: var(--dpzBlue);
        font-weight: bold;
        padding: 0 5px;
      }
    }
  }
`;
