import { PROFILE } from "constants/customer";
import { PAYMENT_TYPES } from "constants/finish";
import { COUNTRY_CODES } from "constants/market";
import { BASES, SIZES } from "constants/pizza";
import { SORT_BY } from "constants/store";

const {
  DOMINICAN_REPUBLIC,
  KENYA,
  MALAYSIA,
  MEXICO,
  NIGERIA,
  SAUDI_ARABIA,
  // SOUTH_AFRICA,
  UNITED_STATES,
} = COUNTRY_CODES;

const defaultConfigs = {
  ALLOWED_PAYMENT_TYPES: {
    Carryout: [PAYMENT_TYPES.CASH],
    Delivery: [
      PAYMENT_TYPES.DOOR_DEBIT,
      PAYMENT_TYPES.CASH,
      PAYMENT_TYPES.DOOR_CREIDT,
    ],
  },
  ALLOWED_STORE_FIELDS: {
    addressDescription: true,
    distance: false,
    onlineStatus: true,
    phone: true,
    serviceMethodsDescription: true,
    storeId: true,
    storeName: true,
    storeStatus: true,
  },
  COUPONS_ALPHABETICALLY_SORTED: false,
  HAS_MAP_LOCATOR_WITH_STDF_FALLBACK: false,
  DEFAULT_CARRYOUT_WAIT_TIME: "",
  DEFAULT_DATE_FORMAT: "DD/MM/YYYY",
  DEFAULT_DELIVERY_WAIT_TIME: "",
  DEFAULT_COUPON_CATEGORY: "",
  DEFAULT_PIZZA_CATEGORY: "",
  DELIVERY_INSTRUCTIONS_MAX_LENGTH: 128,
  DISPLAY_STORE_NAME: true,
  FORCE_CUSTOMER_REQUIRED_FIELDS: false,
  IS_FILTER_CALL_CENTER_ENABLED: false,
  MANUAL_COUPON_ENTRY_TAB: {
    id: "enter",
    path: "enter",
    quidBase: "coupons-enter-code",
    text: "coupons:enter_code",
    translate: true,
  },
  mutateLocatorQuery: null,
  PARSE_CATEGORY_TITLE: false,
  PROFILE_FIELDS_OVERRIDES: {},
  SINGLE_LINE_ITEM_MAX_QTY: 25,
  SINGLE_LINE_ITEM_MIN_QTY: 1,
  STORE_SORT_PREFRENCE: SORT_BY.CONNECTIVITY,
  NEW_EXPERIENCE_FEATURES: {
    automaticPriceOrder: false,
    checkStoreCashLimit: false,
    filterAvailableServiceMethods: false,
    hideDefaultNationalStore: false,
    improvedSizesAndBases: false,
    newCouponPage: false,
    newCustomerPage: false,
    newEverythingElse: false,
    newOrderHistory: false,
    newPizzaBuilder: false,
    productSelectionFirst: false,
    randomDefaultPizzaSelection: false,
    rearrangedNavbar: false,
    reverseSpecialtyPizzaToppingDescription: false,
    showDefaultSidesInReceiptSidebar: false,
    showCarryoutEstimatedServiceTimeForCarsideAndPickupWindow: false,
    showDefaultStoreWarning: false,
    showExpandedOrderConfirmationMessage: false,
    showPriceBreakdownInReceiptSidebar: false,
    showTippingOnPayments: false,
    skipCombineLikeProducts: false,
    skipReceiptCouponFulfillerPage: false,
    selectFromMultipleCustomers: false,
  },
  NEW_EXPERIENCE_FEATURE_OPTIONS: {
    allowFutureOrderTimeMinutesFromNow: 30,
    everythingElseDisabledTooltipMessages: [],
    everythingElseTabOrder: [],
    hideBaseNameForCategoryIds: [],
    hideProductNameForCategoryIds: [],
    hideSizesForCategoryIds: [],
    newPizzaBuilder: {
      DEFAULT_PIZZA_BASEID: BASES.HANDTOSS,
      DEFAULT_PIZZA_SIZEID: SIZES.LARGE,
      DEFAULT_SPECIALTY_CATEGORYID: "Specialty",
      DEFAULT_SPECIALTY_SIZEID: SIZES.LARGE,
    },
    basesProductMergeExceptionIds: [],
    utilizeCustomSideQuantityMaxForCategoryIds: [],
    customSideQuantityMax: 0,
    showOnlyActiveOrderProductAvailableSizesForCategoryIds: [],
    showZeroDefaultSidesForCategoryIds: [],
    swapProductsForBasesCategoryIds: [],
  },
  FILTER_DELIVER_ONLY_STORES: true,
  SOURCE_ORG_URI: "cca-order.dominos.com",
  SUPPRESS_COORDINATES_DELIVERY_INSTRUCTIONS: false,
  TEALIUM_PROFILE: "call-center-app-golo",
  WATERFALL_UPSELLS: [],
};

const marketOverrides = {
  [UNITED_STATES]: {
    ALLOWED_PAYMENT_TYPES: {
      Carryout: [PAYMENT_TYPES.CASH, PAYMENT_TYPES.CREDIT_CARD],
      Carside: [PAYMENT_TYPES.CREDIT_CARD],
      Delivery: [PAYMENT_TYPES.CASH, PAYMENT_TYPES.CREDIT_CARD],
      PickupWindow: [PAYMENT_TYPES.CASH, PAYMENT_TYPES.CREDIT_CARD],
    },
    COUPONS_ALPHABETICALLY_SORTED: false,
    DEFAULT_DATE_FORMAT: "MM/DD/YYYY",
    DISPLAY_STORE_NAME: false,
    NEW_EXPERIENCE_FEATURES: {
      automaticPriceOrder: true,
      checkStoreCashLimit: true,
      filterAvailableServiceMethods: true,
      hideDefaultNationalStore: true,
      improvedSizesAndBases: true,
      newCouponPage: true,
      newCustomerPage: true,
      newOrderHistory: true,
      newEverythingElse: true,
      newPizzaBuilder: true,
      productSelectionFirst: true,
      randomDefaultPizzaSelection: true,
      rearrangedNavbar: true,
      reverseSpecialtyPizzaToppingDescription: true,
      showDefaultSidesInReceiptSidebar: true,
      showCarryoutEstimatedServiceTimeForCarsideAndPickupWindow: true,
      showExpandedOrderConfirmationMessage: true,
      showPriceBreakdownInReceiptSidebar: true,
      showTippingOnPayments: true,
      skipReceiptCouponFulfillerPage: true,
      selectFromMultipleCustomers: true,
      skipCombineLikeProducts: true,
    },
    MANUAL_COUPON_ENTRY_TAB: {
      ...defaultConfigs.MANUAL_COUPON_ENTRY_TAB,
      text: "coupons:coupon_code",
    },
    NEW_EXPERIENCE_FEATURE_OPTIONS: {
      ...defaultConfigs.NEW_EXPERIENCE_FEATURE_OPTIONS,
      allowFutureOrderTimeMinutesFromNow: 60,
      everythingElseSizeTooltipMessages: [
        {
          categoryId: "Wings",
          sizeCode: "12PCB",
          message: "negative:specialty_chicken_bites_only_12",
        },
      ],
      everythingElseTabOrder: [
        "chicken",
        "sandwiches",
        "hoagies",
        "pasta",
        "breads",
        "loaded tots",
        "oven-baked dips",
        "oven-baked dips & twists combos",
        "salads",
        "desserts",
        "drinks",
        "extras",
      ],
      hideBaseNameForCategoryIds: ["Pasta", "Wings"],
      hideProductNameForCategoryIds: ["BreadDipCombos"],
      hideSizesForCategoryIds: ["Tots"],
      basesProductMergeExceptionIds: ["Pasta"],
      utilizeCustomSideQuantityMaxForCategoryIds: [
        "Wings",
        "Bread",
        "Tots",
        "Dessert",
        "GSalad",
      ],
      customSideQuantityMax: 25,
      showOnlyActiveOrderProductAvailableSizesForCategoryIds: ["Sides"],
      showZeroDefaultSidesForCategoryIds: ["Wings", "Bread"],
      swapProductsForBasesCategoryIds: ["BreadDipCombos"],
    },
    SOURCE_ORG_URI: "cca.us.dominos.com",
    TEALIUM_PROFILE: "call-center-app",
    WATERFALL_UPSELLS: [
      [
        {
          productId: "F_PBITES",
          sizeId: "BRD16",
          categoryId: "Bread",
        },
        {
          productId: "F_PARMT",
          sizeId: "BRD8",
          categoryId: "Bread",
        },
        {
          productId: "F_GARLICT",
          sizeId: "BRD8",
          categoryId: "Bread",
        },
      ],
      [
        {
          productId: "F_LAVA",
          sizeId: "DRT3",
          categoryId: "Dessert",
        },
        {
          productId: "F_CINNAT",
          sizeId: "DRT8",
          categoryId: "Dessert",
        },
        {
          productId: "F_MRBRWNE",
          sizeId: "9PC",
          categoryId: "Dessert",
        },
      ],
      [
        {
          productId: "F_COKE",
          sizeId: "2LTB",
          categoryId: "Drinks",
        },
        {
          productId: "F_DIET",
          sizeId: "2LTB",
          categoryId: "Drinks",
        },
      ],
      [
        {
          productId: "S_SCSBBQ",
          sizeId: "12PCB",
          categoryId: "Wings",
        },
        {
          productId: "S_SCCHB",
          sizeId: "12PCB",
          categoryId: "Wings",
        },
      ],
      [
        {
          productId: "S_PHIL",
          categoryId: "Sandwich",
        },
        {
          productId: "S_CHIKK",
          categoryId: "Sandwich",
        },
      ],
      [
        {
          productId: "S_ALFR",
          baseId: "PASTA",
          categoryId: "Pasta",
        },
        {
          productId: "S_CARB",
          baseId: "PASTA",
          categoryId: "Pasta",
        },
      ],
      [
        {
          productId: "F_GARDEN",
          categoryId: "Salad",
        },
        {
          productId: "F_CCAESAR",
          categoryId: "Salad",
        },
      ],
    ],
  },
  [DOMINICAN_REPUBLIC]: {
    PROFILE_FIELDS_OVERRIDES: {
      [PROFILE.PREFIX]: {
        hidden: true,
      },
    },
  },
  [MALAYSIA]: {
    HAS_MAP_LOCATOR_WITH_STDF_FALLBACK: true,
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: 25,
    DEFAULT_CARRYOUT_WAIT_TIME: "15",
    DEFAULT_DELIVERY_WAIT_TIME: "30",
    FORCE_CUSTOMER_REQUIRED_FIELDS: true,
    PARSE_CATEGORY_TITLE: true,
    PROFILE_FIELDS_OVERRIDES: {
      [PROFILE.FIRST_NAME]: {
        hidden: true,
      },
      [PROFILE.LAST_NAME]: {
        required: true,
      },
      [PROFILE.PREFIX]: {
        hidden: true,
      },
    },
    SINGLE_LINE_ITEM_MAX_QTY: 200,
    SINGLE_LINE_ITEM_MIN_QTY: 1,
  },
  [SAUDI_ARABIA]: {
    ALLOWED_STORE_FIELDS: {
      addressDescription: true,
      distance: true,
      onlineStatus: true,
      phone: true,
      serviceMethodsDescription: true,
      storeId: true,
      storeName: true,
      storeStatus: true,
    },
    DEFAULT_COUPON_CATEGORY: "allstorecoupons",
    DEFAULT_PIZZA_CATEGORY: "legend",
    FILTER_DELIVER_ONLY_STORES: false,
    PROFILE_FIELDS_OVERRIDES: {
      [PROFILE.FIRST_NAME]: {
        required: true,
      },
      [PROFILE.LAST_NAME]: {
        required: true,
      },
    },
    STORE_SORT_PREFRENCE: SORT_BY.DISTANCE,
    SUPPRESS_COORDINATES_DELIVERY_INSTRUCTIONS: true,
    NEW_EXPERIENCE_FEATURES: {
      productSelectionFirst: true,
      filterAvailableServiceMethods: true,
      randomDefaultPizzaSelection: true,
      showDefaultStoreWarning: true,
    },
    WATERFALL_UPSELLS: [
      [
        {
          productId: "F_CTWISTS",
          baseId: "",
          sizeId: "8PCS",
          categoryId: "Dessert",
        },
        {
          productId: "F_CRWNS",
          baseId: "",
          sizeId: "9PCS",
          categoryId: "Dessert",
        },
        {
          productId: "F_CLAVA",
          baseId: "",
          sizeId: "2PCS",
          categoryId: "Dessert",
        },
      ],
      [
        {
          productId: "S_CKICK",
          baseId: "",
          sizeId: "8PC",
          categoryId: "Wings",
        },
        {
          productId: "S_DYNAMITE",
          baseId: "DYN1",
          sizeId: "DUB20PCS",
          categoryId: "Wings",
        },
      ],
      [
        {
          productId: "F_GTWISTS",
          baseId: "",
          sizeId: "8P",
          categoryId: "Bread",
        },
        {
          productId: "F_SCBF",
          baseId: "",
          sizeId: "8P",
          categoryId: "Bread",
        },
        {
          productId: "F_POT",
          baseId: "",
          sizeId: "PWRANCHDUP",
          categoryId: "Bread",
        },
      ],
      [
        {
          productId: "F_RUNCH",
          baseId: "",
          sizeId: "",
          categoryId: "Sides",
        },
        {
          productId: "F_HOTSUCE",
          baseId: "",
          sizeId: "",
          categoryId: "Sides",
        },
        {
          productId: "F_BBQSAUCE",
          baseId: "",
          sizeId: "",
          categoryId: "Sides",
        },
      ],
    ],
  },
  [NIGERIA]: {
    PROFILE_FIELDS_OVERRIDES: {
      [PROFILE.PHONE]: {
        max: 11,
      },
      [PROFILE.PREFIX]: {
        defaultValue: "234",
      },
      [PROFILE.FIRST_NAME]: {
        hidden: true,
      },
      [PROFILE.LAST_NAME]: {
        required: true,
      },
      [PROFILE.COMMENTS]: {
        max: 256,
      },
    },
    DELIVERY_INSTRUCTIONS_MIN_LENGTH: 128,
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: 256,
    WATERFALL_UPSELLS: [
      [
        {
          productId: "F_BRDSK",
          baseId: "",
          sizeId: "",
          categoryId: "Bread",
        },
        {
          productId: "F_CHYBRD",
          baseId: "",
          sizeId: "",
          categoryId: "Bread",
        },
        {
          productId: "F_CHSTBRD",
          baseId: "",
          sizeId: "",
          categoryId: "Bread",
        },
      ],
      [
        {
          productId: "S_CHKKK",
          baseId: "",
          sizeId: "",
          categoryId: "wings",
        },
        {
          productId: "S_CHKWG",
          baseId: "",
          sizeId: "",
          categoryId: "wings",
        },
        {
          productId: "S_SPDL01",
          baseId: "",
          sizeId: "",
          categoryId: "wings",
        },
      ],
      [
        {
          productId: "F_CSRM21",
          baseId: "",
          sizeId: "",
          categoryId: "Dessert",
        },
        {
          productId: "F_CSRM025",
          baseId: "",
          sizeId: "",
          categoryId: "Dessert",
        },
      ],
      [
        {
          productId: "F_CK05",
          baseId: "",
          sizeId: "",
          categoryId: "Drinks",
        },
      ],
    ],
  },
  [KENYA]: {
    HAS_MAP_LOCATOR_WITH_STDF_FALLBACK: true,
    mutateLocatorQuery(query) {
      const regions = {
        MOMBASA: "MB",
        NAIROBI: "NA",
      };
      return {
        ...query,
        regionCode: regions[query.city.toUpperCase()],
      };
    },
    PROFILE_FIELDS_OVERRIDES: {
      [PROFILE.FIRST_NAME]: {
        required: true,
      },
      [PROFILE.LAST_NAME]: {
        required: true,
      },
      [PROFILE.PHONE]: {
        max: 10,
      },
      [PROFILE.PREFIX]: {
        defaultValue: "254",
      },
    },
    WATERFALL_UPSELLS: [
      [
        {
          productId: "F__BRDSTX",
          baseId: "",
          sizeId: "",
          categoryId: "Bread",
        },
        {
          productId: "F__CHEBRD",
          baseId: "",
          sizeId: "",
          categoryId: "Bread",
        },
      ],
      [
        {
          productId: "F__COKE",
          baseId: "",
          sizeId: "",
          categoryId: "Drinks",
        },
        {
          productId: "F__FANTP2L",
          baseId: "",
          sizeId: "",
          categoryId: "Drinks",
        },
        {
          productId: "F__500MLCZ",
          baseId: "",
          sizeId: "",
          categoryId: "Drinks",
        },
      ],
      [
        {
          productId: "S__CLCCHXWIN",
          baseId: "",
          sizeId: "",
          categoryId: "Wings",
        },
        {
          productId: "S__BBQWING",
          baseId: "",
          sizeId: "",
          categoryId: "Wings",
        },
        {
          productId: "S__SWCHXWIN",
          baseId: "",
          sizeId: "",
          categoryId: "Wings",
        },
      ],
      [
        {
          productId: "_FRIES",
          baseId: "",
          sizeId: "",
          categoryId: "Ravioli",
        },
        {
          productId: "F_MFRIES",
          baseId: "",
          sizeId: "",
          categoryId: "Ravioli",
        },
        {
          productId: "F__PWEDGES",
          baseId: "",
          sizeId: "",
          categoryId: "Ravioli",
        },
      ],
      [
        {
          productId: "F__BBQCUP",
          baseId: "",
          sizeId: "",
          categoryId: "Extras",
        },
        {
          productId: "F__GARHERB",
          baseId: "",
          sizeId: "",
          categoryId: "Extras",
        },
        {
          productId: "F__HOTSAUC",
          baseId: "",
          sizeId: "",
          categoryId: "Extras",
        },
      ],
      [
        {
          productId: "_500MLVANI",
          baseId: "",
          sizeId: "",
          categoryId: "Dessert",
        },
        {
          productId: "F_STRAWBRY",
          baseId: "",
          sizeId: "",
          categoryId: "Dessert",
        },
        {
          productId: "F_CHOC",
          baseId: "",
          sizeId: "",
          categoryId: "Dessert",
        },
      ],
    ],
  },
  [MEXICO]: {
    IS_FILTER_CALL_CENTER_ENABLED: true,
  },
};

export default function marketConfigs(marketproductId) {
  const { NEW_EXPERIENCE_FEATURES: defaultExperienceFeatures } = defaultConfigs;
  const {
    NEW_EXPERIENCE_FEATURES: marketNewExperienceFeatures,
    ...marketConfigs
  } = marketOverrides[marketproductId] || {};

  return {
    ...defaultConfigs,
    ...marketConfigs,
    ...{
      NEW_EXPERIENCE_FEATURES: {
        ...defaultExperienceFeatures,
        ...marketNewExperienceFeatures,
      },
    },
  };
}
