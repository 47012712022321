import React from "react";

import PropTypes from "prop-types";

import { orderStatusHeadings } from "constants/customer";

import TableHeading from "components/TableHeading";

export const UTILITY_HEADING_COUNT = 1;

const OrderStatusesHeading = ({ t }) => (
  <TableHeading
    headings={orderStatusHeadings}
    t={t}
    utilityHeadingCount={UTILITY_HEADING_COUNT}
  />
);

OrderStatusesHeading.propTypes = {
  t: PropTypes.func,
};

OrderStatusesHeading.defaultProps = {
  t: () => {},
};

export default OrderStatusesHeading;
