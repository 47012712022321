import { createSelector } from "reselect";

import { getLinkFromRelationship } from "selectors/related";

export const getRoot = ({ root = {} } = {}) => root;

export const getCustomersLink = createSelector([getRoot], (root) =>
  getLinkFromRelationship("customers")(root)
);

export const getOrderStatusesLink = createSelector([getRoot], (root) =>
  getLinkFromRelationship("orderStatuses")(root)
);

export const getStoresLink = createSelector([getRoot], (root) =>
  getLinkFromRelationship("stores")(root)
);

export default getRoot;
