import { createSelector } from "reselect";

export const getLocation = ({ location = {} } = {}) => location;

export const getLocationPayload = createSelector(
  [getLocation],
  ({ payload = {} }) => payload
);

export const getPayloadSection = createSelector(
  [getLocationPayload],
  ({ section = "" }) => section
);

export const getPayloadCategory = createSelector(
  [getLocationPayload],
  ({ category = "" }) => category
);

export const getLocationQuery = createSelector(
  [getLocation],
  ({ query = {} }) => query
);

export const getQueryPhone = createSelector(
  [getLocationQuery],
  ({ phone = "" }) => phone
);

export const getQueryPrefix = createSelector(
  [getLocationQuery],
  ({ prefix = "" }) => prefix
);

export const getLocationType = createSelector(
  [getLocation],
  ({ type = "" }) => type
);

export const getPayloadCouponId = createSelector(
  [getLocation],
  ({ payload: { couponId } = {} }) => couponId
);

export default getLocation;
