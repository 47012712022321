import { createSelector } from "reselect";

import { normalizeServiceMethod } from "ducks/customer/helpers";
import { getDataIdFromRelationship } from "selectors/related";
import getState from "selectors/state";

import {
  BAKE_STATUSES,
  CHECK_STATUSES,
  ORDER_STATUS,
  PLACE_STATUSES,
  PREP_STATUSES,
  READY_STATUSES,
  TRACKER_STATUS,
} from "constants/order";
import exists from "modules/exists";
import { formatDateTime, formatTime } from "modules/formatDateTime";

export const getOrderStatuses = ({ orderStatus = {} } = {}) => orderStatus;

export const getOrderStatusId = (state, id = "") => id;

export const getOrderStatusesIds = createSelector(
  [getOrderStatuses],
  (orderStatuses) => Object.keys(orderStatuses)
);

export const getOrderStatus = createSelector(
  [getOrderStatuses, getOrderStatusId],
  (orderStatuses, orderStatusId) => orderStatuses[orderStatusId] || {}
);

export const formatOrderStatusDateTime = (dateTime) =>
  formatDateTime(
    dateTime
      ? {
          dateTime: new Date(dateTime),
          timeZoneAware: false,
        }
      : {}
  );

export const getOrderStatusFutureOrderTime = createSelector(
  [getOrderStatus],
  ({ futureOrderTime = "" }) =>
    futureOrderTime ? formatOrderStatusDateTime(futureOrderTime) : "N/A"
);

export const getOrderStatusPlaceTime = createSelector(
  [getOrderStatus],
  ({ placeTime = "" }) => placeTime
);

export const getOrderStatusFormattedPlaceTime = createSelector(
  [getOrderStatusPlaceTime],
  (placeTime) => formatOrderStatusDateTime(placeTime)
);

export const getOrderStatusStoreId = createSelector(
  [getOrderStatus],
  (orderStatus) => getDataIdFromRelationship("store")(orderStatus)
);

export const getOrderStatusStoreOrderId = createSelector(
  [getOrderStatus],
  ({ storeOrderId = "" }) => storeOrderId
);

export const getOrderStatusStatus = createSelector(
  [getOrderStatus],
  ({ status = "BAD" }) => ORDER_STATUS[status]
);

export const getOrderStatusTrackerStatus = createSelector(
  [getOrderStatusStatus],
  (status) => {
    if (PLACE_STATUSES.has(status)) return TRACKER_STATUS.PLACE;
    if (PREP_STATUSES.has(status)) return TRACKER_STATUS.PREP;
    if (BAKE_STATUSES.has(status)) return TRACKER_STATUS.BAKE;
    if (CHECK_STATUSES.has(status)) return TRACKER_STATUS.CHECK;
    if (READY_STATUSES.has(status)) return TRACKER_STATUS.READY;

    return TRACKER_STATUS.UNTRACKED;
  }
);

export const getOrderStatusTrackerSeconds = createSelector(
  [getOrderStatus, getOrderStatusTrackerStatus],
  (
    {
      bakeSeconds = 0,
      checkSeconds = 0,
      placeSeconds = 0,
      prepSeconds = 0,
      readySeconds = 0,
    },
    trackerStatus
  ) => {
    switch (trackerStatus) {
      case TRACKER_STATUS.PLACE:
        return {
          placeSeconds,
        };
      case TRACKER_STATUS.PREP:
        return {
          placeSeconds,
          prepSeconds,
        };
      case TRACKER_STATUS.BAKE:
        return {
          bakeSeconds,
          placeSeconds,
          prepSeconds,
        };
      case TRACKER_STATUS.CHECK:
        return {
          bakeSeconds,
          checkSeconds,
          placeSeconds,
          prepSeconds,
        };
      case TRACKER_STATUS.READY:
        return {
          bakeSeconds,
          checkSeconds,
          placeSeconds,
          prepSeconds,
          readySeconds,
        };
      default:
        return {};
    }
  }
);

const isNotNullOrUndefined = (value) => value !== null && value !== undefined;

export const getOrderStatusTrackerDateTimes = createSelector(
  [getOrderStatus, getOrderStatusTrackerSeconds],
  (
    {
      bakeTime = "",
      checkTime = "",
      placeTime = "",
      prepTime = "",
      readyTime = "",
    },
    { bakeSeconds, checkSeconds, placeSeconds, prepSeconds, readySeconds }
  ) =>
    [
      [placeTime, placeSeconds, "placeTime"],
      [prepTime, prepSeconds, "prepTime"],
      [bakeTime, bakeSeconds, "bakeTime"],
      [checkTime, checkSeconds, "checkTime"],
      [readyTime, readySeconds, "readyTime"],
    ].reduce(
      (all, [time, seconds, prop]) =>
        Object.assign(
          all,
          isNotNullOrUndefined(seconds) && {
            [prop]: time,
          }
        ),
      {}
    )
);

const convertToDate = ([prop, stringDateTime]) => [
  prop,
  new Date(stringDateTime),
];

const reduceAndFormatTime = (all, [prop, dateTime]) =>
  Object.assign(all, {
    [prop]: formatTime(dateTime),
  });

export const getOrderStatusTrackerStamps = createSelector(
  [getOrderStatusTrackerDateTimes],
  (dateTimes) =>
    Object.entries(dateTimes)
      .sort()
      .map(convertToDate)
      .reduce(reduceAndFormatTime, {})
);

const splitLineItemStrings = (lineItem, mapIndex) => {
  const index = lineItem.indexOf(" ");
  const description = lineItem.substr(index + 1);
  return {
    id: `${mapIndex}-${description}`,
    name: description,
    quantity: lineItem.substr(0, index),
    options: [],
  };
};

export const getOrderStatusProducts = createSelector(
  [getOrderStatus],
  ({ orderDescription = "" }) =>
    (orderDescription || "")
      .split("\n")
      .filter(exists)
      .map(splitLineItemStrings)
);

export const getOrderStatusSource = createSelector(
  [getOrderStatus],
  ({ orderSource = "" }) => orderSource
);

export const getOrderStatusStoreTime = createSelector(
  [getOrderStatus],
  ({ storeTime = "" }) => formatOrderStatusDateTime(storeTime)
);

export const getOrderStatusServiceMethod = createSelector(
  [getOrderStatus],
  ({ serviceMethod = "CARRYOUT" }) => normalizeServiceMethod(serviceMethod)
);

export const getAllOrderStatuses = createSelector(
  [getOrderStatusesIds, getState],
  (orderStatusIds, state) =>
    orderStatusIds
      .sort()
      .reverse()
      .reduce((all, orderStatusId) => {
        const [
          futureOrderTime,
          orderPlaceTime,
          orderProducts,
          serviceMethod,
          source,
          status,
          storeId,
          storeOrderId,
          storeTime,
          { bakeTime, checkTime, placeTime, prepTime, readyTime },
          trackerStatus,
        ] = [
          getOrderStatusFormattedPlaceTime,
          getOrderStatusFutureOrderTime,
          getOrderStatusProducts,
          getOrderStatusServiceMethod,
          getOrderStatusSource,
          getOrderStatusStatus,
          getOrderStatusStoreId,
          getOrderStatusStoreOrderId,
          getOrderStatusStoreTime,
          getOrderStatusTrackerStamps,
          getOrderStatusTrackerStatus,
        ].map((selector) => selector(state, orderStatusId));

        return Object.assign(all, {
          [orderStatusId]: {
            bakeTime,
            checkTime,
            futureOrderTime,
            orderPlaceTime,
            orderProducts,
            placeTime,
            prepTime,
            readyTime,
            serviceMethod,
            source,
            status,
            storeId,
            storeOrderId,
            storeTime,
            trackerStatus,
          },
        });
      }, {})
);

export default getOrderStatuses;
