import { createSelector } from "reselect";

export const getCoupons = ({ coupon = {} } = {}) => coupon;

export const getNationalCoupons = createSelector([getCoupons], (coupon) => {
  return Object.values(coupon).filter(
    ({ couponIsLocal = true }) => !couponIsLocal
  );
});

export default getCoupons;
