import { css } from "@emotion/react";

export default css`
  :root {
    --tracker-height: 5rem;
    --step-angle: 45deg;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  .tracker__wrapper {
    flex: none;
    margin-bottom: 1rem;
    text-align: center;
  }

  .tracker {
    border-radius: var(--tracker-height);
    display: flex;
    height: var(--tracker-height);
    overflow: hidden;
  }

  .tracker__step {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    // TODO: find sass alternative to 'tan' here - was using postcss-simple-trig previously
    padding-left: calc(tan(45deg) * var(--tracker-height));

    &::after {
      content: " ";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: skew(-45deg, 0deg);
      transform-origin: bottom right;
      width: 100%;
      z-index: 1;
    }

    &,
    > * {
      position: relative;
    }

    > * {
      display: block;
      position: relative;
      white-space: nowrap;
      z-index: 2;
    }
  }

  .tracker__step--label {
    font-weight: bold;
  }

  .step--short {
    flex: 1;
  }

  .step--medium {
    flex: 2;
  }

  .step--long {
    flex: 3;
  }

  .step--progress {
    &,
    &::after {
      background-color: var(--attention-bg);
      border-color: var(--attention-fg);
      color: var(--attention-fg);
    }

    [data-icon] {
      animation: 2s blink infinite ease-out;
    }
  }

  .step--complete,
  .step--progress:last-child {
    &,
    &::after {
      background-color: var(--positive-bg);
      border-color: var(--positive-fg);
      color: var(--positive-fg);
    }

    [data-icon] {
      animation: unset;

      &::before {
        content: "check";
      }
    }
  }

  .step--incomplete {
    &,
    &::after {
      background-color: var(--subtle-bg);
      border-color: var(--subtle-fg);
      color: var(--subtle-fg);
    }
  }

  @media (max-width: 60rem) {
    .tracker__step {
      padding: 1rem;

      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 45rem) {
    .tracker {
      display: inline-flex;
      flex-direction: column;
      height: unset;
      margin: 0 auto;
      min-width: var(--tracker-height);
    }

    .tracker__step {
      padding: 1rem;

      &:first-child {
        padding-top: 2rem;
      }

      &:last-child {
        padding-bottom: 2rem;
      }
    }
  }
`;
