import React from "react";

import PropTypes from "prop-types";

const Suggestion = ({ dataQuid, suggestion, input, handleSelect }) => {
  const { description = "" } = suggestion;
  return (
    <div
      aria-selected={description === input}
      className="type-ahead__option"
      data-quid={dataQuid}
      onClick={() => handleSelect(suggestion)}
      onKeyDown={() => {}}
      role="option"
      tabIndex="0"
    >
      {description}
    </div>
  );
};

Suggestion.propTypes = {
  dataQuid: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
  input: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default Suggestion;
