import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getCurrentLanguage } from "ducks/configuration/selectors";
import { getBases, getSizes } from "ducks/menu/selectors";
import {
  getActiveOrderProductAvailableBasesBySize,
  getActiveOrderProductAvailableSizesByBase,
} from "ducks/order/selectors";

import { OPTION, defaultArea } from "constants/order";

import Base from "components/Base";
import Option from "components/Option";
import Size from "components/Size";

const PizzaBuilder = ({
  activeArea,
  activeOrderProductAvailableOptions,
  activeOrderProductBaseId,
  activeOrderProductOptionIds,
  activeOrderProductOptions,
  activeOrderProductSizeId,
  bases,
  deleteOrderProductOption,
  sauces,
  setOrderProduct,
  sizes,
  t,
  useProductSelectionFirst,
}) => {
  const activeToppingProps = {
    activeArea,
    activeOrderProductAvailableOptions,
    activeOrderProductOptionIds,
    activeOrderProductOptions,
  };

  const allBases = useSelector(getBases);
  const allSizes = useSelector(getSizes);
  const getBaseNameById = (baseId) => allBases[baseId]?.baseName ?? "";
  const getSizeNameById = (sizeId) => allSizes[sizeId]?.sizeName ?? "";
  const currentLanguage = useSelector(getCurrentLanguage);
  const formatter = new Intl.ListFormat(currentLanguage);
  const getDisabledSizeTooltipMessage = () => {
    const baseName = getBaseNameById(activeOrderProductBaseId);
    const validSizes = activeOrderProductAvailableSizesByBase.map((sizeId) =>
      getSizeNameById(sizeId)
    );

    return t("negative:invalid_crust_or_size", {
      thing: baseName,
      list: formatter.format(validSizes),
    });
  };

  const activeOrderProductAvailableSizesByBase = useSelector(
    getActiveOrderProductAvailableSizesByBase
  );
  const activeOrderProductAvailableBasesBySize = useSelector(
    getActiveOrderProductAvailableBasesBySize
  );

  const getSizeComponents = () => (
    <div className="subsection">
      <header className="title-group">
        <h2 data-quid="heading-sizes" id="size">
          {t("food:sizes")}
        </h2>
      </header>

      <div className="btn-group btn-group--vertical" data-quid="sizes">
        {sizes.map((size) => {
          const isDisabled =
            activeOrderProductSizeId &&
            !activeOrderProductAvailableSizesByBase.includes(size.sizeId);
          return (
            <Size
              activeOrderProductSizeId={activeOrderProductSizeId}
              disabled={isDisabled}
              tooltipMessage={isDisabled ? getDisabledSizeTooltipMessage() : ""}
              key={size.sizeId}
              setOrderProduct={setOrderProduct}
              {...size}
            />
          );
        })}
      </div>
    </div>
  );

  return (
    <>
      <div className="columns shared">
        {!useProductSelectionFirst && getSizeComponents()}
        <div className="subsection">
          <header className="title-group">
            <h2 data-quid="heading-bases" id="crust">
              {t("food:bases")}
            </h2>
          </header>

          <div className="btn-group btn-group--vertical" data-quid="bases">
            {bases.map((base) => {
              const isDisabled =
                !activeOrderProductAvailableBasesBySize.includes(base.baseId);
              return (
                <Base
                  activeOrderProductBaseId={activeOrderProductBaseId}
                  disabled={isDisabled}
                  tooltipMessage={
                    isDisabled ? getDisabledSizeTooltipMessage() : ""
                  }
                  key={base.baseId}
                  setOrderProduct={setOrderProduct}
                  {...base}
                />
              );
            })}
          </div>
        </div>
        {useProductSelectionFirst && getSizeComponents()}
        {sauces.length > 0 && (
          <div className="subsection">
            <header className="title-group">
              <h2 data-quid="heading-sauces" id="sauce">
                {t("food:toppings.sauces")}
              </h2>
            </header>

            <div className="btn-group btn-group--vertical" data-quid="sauces">
              {sauces.map(
                ({
                  toppingCode,
                  toppingDescription,
                  toppingId,
                  toppingName,
                }) => (
                  <Option
                    activeArea={activeArea}
                    key={toppingId}
                    optionCode={toppingCode}
                    optionDescription={toppingDescription}
                    optionId={toppingId}
                    optionName={toppingName}
                    optionType={OPTION.TOPPING}
                    deleteOrderProductOption={deleteOrderProductOption}
                    setOrderProduct={setOrderProduct}
                    {...activeToppingProps}
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

PizzaBuilder.propTypes = {
  activeArea: PropTypes.string,
  activeOrderProductAvailableOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductBaseId: PropTypes.string,
  activeOrderProductOptionIds: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductOptions: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.number)
  ),
  activeOrderProductSizeId: PropTypes.string,
  bases: PropTypes.arrayOf(PropTypes.object),
  deleteOrderProductOption: PropTypes.func.isRequired,
  sauces: PropTypes.arrayOf(PropTypes.object),
  setOrderProduct: PropTypes.func.isRequired,
  sizes: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func,
  useProductSelectionFirst: PropTypes.bool,
};

PizzaBuilder.defaultProps = {
  activeArea: defaultArea,
  activeOrderProductAvailableOptions: {},
  activeOrderProductBaseId: "",
  activeOrderProductOptionIds: [],
  activeOrderProductOptions: {
    [defaultArea]: {},
  },
  activeOrderProductSizeId: "",
  bases: [],
  sauces: [],
  sizes: [],
  t: () => {},
  useProductSelectionFirst: false,
};

export default PizzaBuilder;
