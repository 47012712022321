import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/states/";

export const GET_STATES = `${SCOPE}GET_STATES`;
export const GET_STATES_ERROR = `${SCOPE}GET_STATES_ERROR`;
export const SET_STATES = `${SCOPE}SET_STATES`;

export function getStates({ url } = {}) {
  return {
    type: GET_STATES,
    url,
  };
}

export function getStatesError(error) {
  return {
    type: GET_STATES_ERROR,
    error,
  };
}

export function setStates(states = { data: [] }) {
  return {
    type: SET_STATES,
    states,
  };
}

export const initialState = { data: [] };

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_STATES:
      return action.states;
    default:
      return state;
  }
}

export const getStatesEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_STATES),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        map(setStates),
        catchError((error) => of(getStatesError(error)))
      )
    )
  );
export const epics = combineEpics(getStatesEpic);
