import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  priceOrderLoading: false,
  requireSavedCreditCardCVV: false,
};

const priceOrderSlice = createSlice({
  name: "priceOrder",
  initialState,
  reducers: {
    setPriceOrderLoading: (state, { payload }) => {
      state.priceOrderLoading = payload;
    },
    setRequireSavedCreditCardCVV: (state, { payload }) => {
      state.requireSavedCreditCardCVV = payload;
    },
    resetPriceOrderState: () => {
      return initialState;
    },
  },
});

export const {
  setPriceOrderLoading,
  setRequireSavedCreditCardCVV,
  resetPriceOrderState,
} = priceOrderSlice.actions;

export default priceOrderSlice.reducer;

// Selectors
export const selectPriceOrderLoading = (state) =>
  state.priceOrder.priceOrderLoading;

export const selectRequireSavedCreditCardCVV = (state) =>
  state.priceOrder.requireSavedCreditCardCVV;
