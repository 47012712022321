import { css } from "@emotion/react";

export const couponTitleWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const couponTitle = css`
  text-decoration: underline;
`;
