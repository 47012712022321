import { translate } from "react-i18next";
import { connect } from "react-redux";

import { exitRoute } from "routes";

import { exitOrder } from "ducks/order";
import { getCurrentOrder, getCurrentOrderId } from "ducks/order/selectors";
import { getSelfLink } from "selectors/related";

import Exit from "components/Exit";

const mapStateToProps = (state) => ({
  currentOrder: getCurrentOrder(state),
  currentOrderId: getCurrentOrderId(state),
});

const mapDispatchToProps = (dispatch) => ({
  exitOrder: (order) => dispatch(exitOrder(order)),
  goToExit: () => dispatch(exitRoute()),
});

const mergeProps = (
  { currentOrder, currentOrderId, ...state },
  { exitOrder: dispatchExitOrder, ...dispatch },
  props
) =>
  Object.assign(
    {
      exitOrder: () =>
        dispatchExitOrder({
          orderId: currentOrderId,
          url: getSelfLink(currentOrder),
        }),
    },
    state,
    dispatch,
    props
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["exit", "shared"])(Exit));
