import { initializeCustomerData } from "ducks/customer";
import { getNewExperienceFeature } from "ducks/market/selectors";
import { getCurrentServiceMethod } from "ducks/order/selectors";

import {
  REQUIRED_CARSIDE_FIELDS,
  addInvalidCarsideValue,
  removeInvalidCarsideValue,
  setPreloadedVehicleValues,
  setTextPhoneNumberValue,
} from "rtk_redux/slices/carsideInformationSlice";
import {
  US_REQUIRED_PROFILE_FIELDS,
  addInvalidValue,
  removeInvalidValue,
  setPreloadedFormValues,
} from "rtk_redux/slices/customerInformationFormSlice";
import {
  removePossibleCustomerList,
  selectPossibleCustomerList,
  setAreAllFieldsValid,
  setLookupFinished,
  setSelectedCustomerId,
  setShowAllErrors,
} from "rtk_redux/slices/customerPageSlice";

import { CARSIDE_FIELDS } from "constants/carside";
import { SERVICE_METHOD } from "constants/order";
import formatPhone from "modules/formatPhone";

import { defaultTabs } from "components/Menu";

export const customerMiddleware = (middleware) => {
  // Checks to see if the customerPhone has changed (which will happen after a lookup)
  middleware.startListening({
    predicate: (_action, currState, prevState) => {
      return (
        currState.customer.phone !== prevState.customer.phone ||
        currState.customer.customerId !== prevState.customer.customerId
      );
    },
    effect: ({}, { getState, dispatch }) => {
      const state = getState();
      const {
        links,
        relationships,
        taxCodes,
        phone,
        vehicleInfo,
        ...customer
      } = state.customer;

      customer.phone = formatPhone(phone) || "";
      US_REQUIRED_PROFILE_FIELDS.forEach((field) => {
        !customer[field]
          ? dispatch(addInvalidValue(field))
          : dispatch(removeInvalidValue(field));
      });

      dispatch(
        setPreloadedFormValues({
          ...customer,
        })
      );

      // If the customer object has vehicle info, put it into the carside slice
      const populateVehicleInfo = () => {
        const { optInPhoneNumber, ...carsideFields } = vehicleInfo;
        carsideFields.textPhoneNumber = formatPhone(optInPhoneNumber) || "";
        REQUIRED_CARSIDE_FIELDS.forEach((field) => {
          !carsideFields[field]
            ? dispatch(addInvalidCarsideValue(field))
            : dispatch(removeInvalidCarsideValue(field));
        });
        dispatch(setPreloadedVehicleValues({ ...carsideFields }));
      };

      vehicleInfo && populateVehicleInfo();

      // Hazelcast returns a list of vehicles, but it doesn't include the opt-in phone number
      // If we don't have opt in number, use the customer phone as the text phone number
      // and let them change it if they need a different number
      if (!vehicleInfo || !vehicleInfo.optInPhoneNumber) {
        dispatch(setTextPhoneNumberValue(formatPhone(phone)));
        dispatch(removeInvalidCarsideValue(CARSIDE_FIELDS.TEXT_PHONE_NUMBER));
      }

      dispatch(setLookupFinished());
    },
  });

  middleware.startListening({
    actionCreator: setSelectedCustomerId,
    effect: ({ payload }, { getState, dispatch }) => {
      const state = getState();
      const customerList = selectPossibleCustomerList(state);
      const selectedCustomer = customerList.find((cl) => cl.id === payload);
      const { attributes, id, ...profile } = selectedCustomer;
      const {
        phone: { phoneNumber, prefix, extension },
      } = attributes;
      dispatch(
        initializeCustomerData({
          attributes,
          id,
          doUpdate: true,
          phone: phoneNumber,
          prefix: prefix || "1",
          extension: extension || "",
          profile,
        })
      );
      dispatch(removePossibleCustomerList());
    },
  });

  middleware.startListening({
    predicate: (_action, currState, prevState) => {
      return currState.customer !== prevState.customer;
    },
    effect: ({}, { dispatch }) => {
      dispatch(setLookupFinished());
    },
  });

  middleware.startListening({
    predicate: (_action, currState, prevState) => {
      // defaultTabs array comes from the Menu component
      // It is used to determine whether to show the Customer page
      // and we want to run this check anytime the customer page is left
      const isLeavingCustomerPage = defaultTabs.includes(
        prevState.location.type
      );
      const isRouteChange =
        prevState.location.pathname !== currState.location.pathname;
      return isRouteChange && isLeavingCustomerPage;
    },
    effect: ({}, { dispatch, getState }) => {
      const state = getState();
      const hasProfileErrors = Boolean(
        state.customerInformationForm.invalid.size
      );
      const currentServiceMethod = getCurrentServiceMethod(state);
      const { newCustomerPage: showNewCustomerPage } =
        getNewExperienceFeature(state);

      const hasDCDErrors = Boolean(
        state.carsideInformation.invalidCarsideValues.size
      );

      const showDCDErrors =
        currentServiceMethod === SERVICE_METHOD.DCD && hasDCDErrors;

      // Ensure that fields are marked valid if experience feature is off
      showNewCustomerPage
        ? dispatch(setAreAllFieldsValid(!hasProfileErrors && !showDCDErrors))
        : dispatch(setAreAllFieldsValid(true));

      showNewCustomerPage && dispatch(setShowAllErrors(true));
    },
  });
};
