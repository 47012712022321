import React from "react";

import PropTypes from "prop-types";

import { orderStatusSubheadings } from "constants/customer";
import { TRACKER_STATUS } from "constants/order";

import Tracker from "components/Tracker";

import OrderDetails from "../OrderDetails";
import OrderLineItems from "../OrderLineItems";

const OrderStatus = ({ storeOrderId, trackerStatus, ...props }) => (
  <div className="grid order" data-quid={`order-status-${storeOrderId}`}>
    <Tracker {...props} status={trackerStatus} />

    <OrderLineItems storeOrderId={storeOrderId} {...props} />

    <OrderDetails
      headings={orderStatusSubheadings}
      storeOrderId={storeOrderId}
      {...props}
    />
  </div>
);

OrderStatus.propTypes = {
  storeOrderId: PropTypes.string.isRequired,
  trackerStatus: PropTypes.string,
};

OrderStatus.defaultProps = {
  trackerStatus: TRACKER_STATUS.UNTRACKED,
};

export default OrderStatus;
