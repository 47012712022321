const MAX_RETRIES = 5;
export async function tokenizeCreditCard({
  getTokenizeTemplate,
  tokenizeCardNumber,
  tokenizationRetries,
  totalRetries,
  ...tokenizationArgs
}) {
  if (totalRetries > MAX_RETRIES) {
    return {};
  }
  // totalRetries will increment no matter which call fails, power or ACI/CYBERSOURCE
  totalRetries++;
  const tokenizeTemplateResponse = await getTokenizeTemplate({
    ...tokenizationArgs,
    retryCount: tokenizationRetries,
  });
  if (tokenizeTemplateResponse.isError) {
    return tokenizeCreditCard({
      ...tokenizationArgs,
      getTokenizeTemplate,
      tokenizeCardNumber,
      tokenizationRetries,
      totalRetries,
    });
  }

  /* After three calls to paymentGatewayService, tokenization is disabled
  and we will stop making retry calls */
  if (tokenizeTemplateResponse.data.tokenizationDisabled) {
    return {};
  }
  const tokenizeCardResponse = await tokenizeCardNumber(
    tokenizeTemplateResponse.data
  );
  if (tokenizeCardResponse.isError) {
    tokenizationRetries++;
    return tokenizeCreditCard({
      ...tokenizationArgs,
      getTokenizeTemplate,
      tokenizeCardNumber,
      tokenizationRetries,
      totalRetries,
      failureTokenType: tokenizeTemplateResponse.data.tokenType,
    });
  }
  return {
    data: tokenizeCardResponse.data,
  };
}
