import React, { useMemo } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getActiveOrderProduct } from "ducks/order/selectors";
import { updateOrderProductSauce } from "ducks/orderProduct";
import { getActiveOrderProductSauce } from "ducks/orderProduct/selectors";
import { getSelfLink } from "selectors/related";

import { ROBUST_INSPIRED_TOMATO_SAUCE_CODE } from "constants/topping";
import { WEIGHT } from "constants/topping";

// TODO: refactor assuming all sauce changes take place in AREAS.WHOLE only
const getActiveOrderProductSauceWeight = (
  activeOrderProduct,
  sauceCodes,
  WEIGHT
) => {
  const [firstArea] = Object.keys(activeOrderProduct.parts);
  const activeOrderSauces = activeOrderProduct.parts[firstArea].options.filter(
    (topping) => sauceCodes.includes(topping.code)
  );

  if (
    activeOrderSauces.length === 1 &&
    activeOrderSauces[0].weight === WEIGHT[0].value
  ) {
    return WEIGHT[0].value;
  }

  return (
    activeOrderSauces.find((topping) => topping.weight !== 0)?.weight ||
    WEIGHT[1].value
  );
};

const SauceWeightSelection = ({ t, sauces }) => {
  const dispatch = useDispatch();
  const activeOrderProduct = useSelector(getActiveOrderProduct);
  const activeOrderProductLink = getSelfLink(activeOrderProduct);

  const sauceCodes = useMemo(
    () => sauces.map(({ toppingCode }) => toppingCode),
    [sauces]
  );

  const { code: activeSauceToppingCode } = useSelector(
    getActiveOrderProductSauce(sauces)
  );

  const activeOrderProductSauceWeight = getActiveOrderProductSauceWeight(
    activeOrderProduct,
    sauceCodes,
    WEIGHT
  );

  // TODO: get activeOrderProductSauce - send in with weight change
  const handleOptionWeightClick = (e) => {
    const sauceToppingWeight = parseFloat(e.target.value);

    dispatch(
      updateOrderProductSauce({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        sauces,
        sauceToppingCode:
          sauceToppingWeight > 0
            ? activeSauceToppingCode
            : ROBUST_INSPIRED_TOMATO_SAUCE_CODE,
        sauceToppingWeight,
      })
    );
  };

  return (
    <div className="text-field grid__cell--1 grid__cell--1/2@desktop">
      <select
        onChange={handleOptionWeightClick}
        className="text-field__input"
        name="sauce-weight-selection"
        data-quid="sauce-weight-selection"
        value={activeOrderProductSauceWeight}
      >
        <option
          data-quid={`sauce-weight-option-${WEIGHT[0].value}`}
          value={WEIGHT[0].value}
        >
          {t("food:toppings.no_sauce")}
        </option>
        <option
          data-quid={`sauce-weight-option-${WEIGHT[0.5].value}`}
          value={WEIGHT[0.5].value}
        >
          {t("food:toppings.light")}
        </option>
        <option
          data-quid={`sauce-weight-option-${WEIGHT[1].value}`}
          value={WEIGHT[1].value}
        >
          {t("food:toppings.normal")}
        </option>
        <option
          data-quid={`sauce-weight-option-${WEIGHT[1.5].value}`}
          value={WEIGHT[1.5].value}
        >
          {t("food:toppings.extra")}
        </option>
      </select>
    </div>
  );
};

SauceWeightSelection.propTypes = {
  t: PropTypes.func,
  sauces: PropTypes.array.isRequired,
};

SauceWeightSelection.defaultProps = {
  t: () => {},
  sauces: [],
};

export default SauceWeightSelection;
