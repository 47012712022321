import { createSelector } from "reselect";

import { SERVICE_METHOD_SORT } from "constants/order";
import formatPhone from "modules/formatPhone";

export const getStores = ({ store = {} } = {}) => store;

export const getStoreIds = createSelector([getStores], (stores) =>
  Object.keys(stores)
);

export const getCurrentStore = createSelector(
  [getStores],
  (stores) => Object.values(stores).find(({ active }) => active) || {}
);

export const getCurrentStoreId = createSelector(
  [getCurrentStore],
  ({ storeId = "" }) => storeId
);

export const getCurrentStorePostalCode = createSelector(
  [getCurrentStore],
  ({ postalCode = "" }) => postalCode
);

export const getCurrentStoreName = createSelector(
  [getCurrentStore],
  ({ storeName = "" }) => storeName
);

export const getCurrentStoreStreet = createSelector(
  [getCurrentStore],
  ({ streetName = "" }) => streetName
);

export const getCurrentStoreRegion = createSelector(
  [getCurrentStore],
  ({ city = "", region = "" }) => `${city ? `${city},` : ""} ${region}`.trim()
);

export const getCurrentStorePhone = createSelector(
  [getCurrentStore],
  ({ phone }) => formatPhone(phone)
);

export const getAcceptableCreditCards = createSelector(
  [getCurrentStore],
  ({ acceptableCreditCards = [] }) => acceptableCreditCards
);

export const getAcceptablePaymentTypes = createSelector(
  [getCurrentStore],
  ({ acceptablePaymentTypes = [] }) => acceptablePaymentTypes
);

export const getHasSelectedStore = createSelector(
  [getCurrentStore],
  ({ selected = false }) => selected
);

export const getCurrentStoreHoursList = createSelector(
  [getCurrentStore],
  ({ hoursDescription = "" }) => hoursDescription
);

export const getCurrentStoreIsOpen = createSelector(
  [getCurrentStore],
  ({ isOpen = false }) => isOpen
);

export const getCurrentStoreSummary = createSelector(
  [
    getCurrentStoreId,
    getCurrentStoreStreet,
    getCurrentStoreRegion,
    getCurrentStorePostalCode,
  ],
  (storeId, storeStreet, storeRegion, storePostalCode) =>
    `${storeId ? `#${storeId},` : ""} ${
      storeStreet ? `${storeStreet},` : ""
    } ${storeRegion} ${storePostalCode}`.trim()
);

export const getCurrentStoreCashLimit = createSelector(
  [getCurrentStore],
  ({ cashLimit = "" }) => cashLimit
);

export const getCurrentStoreUTCOffset = createSelector(
  [getCurrentStore],
  ({ timeZoneOffsetMinutes = "" }) => timeZoneOffsetMinutes
);

export const getCurrentStoreIsTippingAllowedAtCheckout = createSelector(
  [getCurrentStore],
  ({ isTippingAllowedAtCheckout = false }) => isTippingAllowedAtCheckout
);

export const getCurrentStoreAcceptableTipPaymentTypes = createSelector(
  [getCurrentStore],
  ({ acceptableTipPaymentTypes = [] }) => acceptableTipPaymentTypes
);

export const getCurrentStoreCarsideTippingEnabled = createSelector(
  [getCurrentStore],
  ({ carsideTippingEnabled = false }) => carsideTippingEnabled
);

export const getCurrentStoreAcceptAnonymousCreditCards = createSelector(
  [getCurrentStore],
  ({ acceptAnonymousCreditCards = false }) => acceptAnonymousCreditCards
);

export const getAvailableServiceMethods = createSelector(
  [getCurrentStore],
  ({ serviceMethods = [] }) =>
    (serviceMethods || []).sort((a, b) =>
      SERVICE_METHOD_SORT.indexOf(a) > SERVICE_METHOD_SORT.indexOf(b) ? 1 : -1
    )
);

export const getServiceHours = createSelector(
  [getCurrentStore],
  ({ serviceHours = {} }) => serviceHours
);

export const getEstimatedWaitMinutes = createSelector(
  [getCurrentStore],
  ({ serviceMethodEstimatedWaitMinutes = {} }) =>
    serviceMethodEstimatedWaitMinutes
);

export const getCarryoutWaitTimeReason = createSelector(
  [getCurrentStore],
  ({ carryoutWaitTimeReason = null }) => carryoutWaitTimeReason
);

export const getDeliveryWaitTimeReason = createSelector(
  [getCurrentStore],
  ({ deliveryWaitTimeReason = null }) => deliveryWaitTimeReason
);
export default getAvailableServiceMethods;
