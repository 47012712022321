import React from "react";

import PropTypes from "prop-types";

import kebabCase from "modules/kebabCase";

const Section = ({ children, heading }) => (
  <div>
    <header className="title-group">
      <h2 data-quid={`heading-${kebabCase(heading)}`}>{heading}</h2>
    </header>

    {children}

    <br />
  </div>
);

Section.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
};

Section.defaultProps = {
  children: null,
  heading: "",
};

export default Section;
