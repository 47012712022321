/** @jsxImportSource @emotion/react */
import { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateAddress } from "ducks/address";
import { getMarketConfigs } from "ducks/market/selectors";
import { getCurrentOrder, getCurrentOrderAddress } from "ducks/order/selectors";
import { getLinkFromRelationship } from "selectors/related";

import FormField from "../utils/FormField";

const DeliveryInstructionsForm = ({ t }) => {
  const dispatch = useDispatch();
  const { DELIVERY_INSTRUCTIONS_MAX_LENGTH } = useSelector(getMarketConfigs);
  const [deliveryInstructions, setDeliveryInstructions] = useState("");

  const currentOrder = useSelector(getCurrentOrder);

  const address = useSelector(getCurrentOrderAddress);
  const addressLink = getLinkFromRelationship("address")(currentOrder);

  const handleBlur = () => {
    dispatch(
      updateAddress({
        ...address,
        deliveryInstructions,
        url: addressLink,
      })
    );
  };

  return (
    <form>
      <FormField
        t={t}
        id="deliveryInstructions"
        name="deliveryInstructions"
        label={t("finish:delivery_instructions:title")}
        value={deliveryInstructions}
        maxLength={DELIVERY_INSTRUCTIONS_MAX_LENGTH}
        handleBlur={handleBlur}
        handleChange={(e) => {
          setDeliveryInstructions(e.target.value);
        }}
      />
    </form>
  );
};

export default DeliveryInstructionsForm;

DeliveryInstructionsForm.propTypes = {
  t: PropTypes.func.isRequired,
};
