import { createSelector } from "reselect";

import {
  formatAddressLine1,
  formatAddressLine2,
  formatStreetAddress,
} from "ducks/address/helpers";

export const getAddresses = ({ address = {} } = {}) => address;

export const getAddress = (addressId) =>
  createSelector([getAddresses], (address) => address[addressId] || {});

export const getAddressLine1 = (addressId) =>
  createSelector([getAddress(addressId)], formatAddressLine1);

export const getStreetAddress = (addressId) =>
  createSelector([getAddress(addressId)], formatStreetAddress);

export const getAddressLine2 = (addressId) =>
  createSelector([getAddress(addressId)], formatAddressLine2);

export const getDeliveryInstructions = (addressId) =>
  createSelector(
    [getAddress(addressId)],
    ({ deliveryInstructions = "" }) => deliveryInstructions || ""
  );
