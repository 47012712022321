import React from "react";

const Loader = () => (
  <div data-hook="demo-loading-container">
    <div className="loading-domino loading-domino--block">
      <div className="loading-domino__tile loading-domino__tile--ann-arbor" />
      <div className="loading-domino__tile loading-domino__tile--ypsi" />
    </div>
    <div className="overlay--matte" />
  </div>
);

export default Loader;
