import React, { useEffect, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  getNewExperienceFeature,
  getNewExperienceFeatureOptions,
} from "ducks/market/selectors";

import { selectPriceOrderLoading } from "rtk_redux/slices/priceOrderSlice";

import formatCurrency from "modules/formatCurrency";

import DotLoader from "components/DotLoader";
import QuantityButtons from "components/QuantityButtons";
import Tooltip from "components/Tooltip";

const QUANTITY = Object.freeze({
  MIN: 1,
  MAX: 25,
});

const ReceiptItem = ({
  active = false,
  categoryId = "",
  children = "",
  defaultSides = {},
  deleteOrderProduct,
  itemName = "",
  itemNumber,
  itemPrice = undefined,
  itemQuantity = 1,
  marketCode,
  marketConfigs,
  orderProductId = 0,
  setActiveOrderProduct,
  setOrderProduct,
  t = () => {},
}) => {
  const handleKeyDown = (e) => {
    return e;
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const [newItemQuantity, setNewItemQuantity] = useState(itemQuantity);
  const priceOrderLoading = useSelector(selectPriceOrderLoading);
  const {
    automaticPriceOrder: shouldUseAutomaticPriceOrder,
    showDefaultSidesInReceiptSidebar,
  } = useSelector(getNewExperienceFeature);
  const { showZeroDefaultSidesForCategoryIds } = useSelector(
    getNewExperienceFeatureOptions
  );

  useEffect(() => {
    itemQuantity !== newItemQuantity && setNewItemQuantity(itemQuantity);
  }, [itemQuantity, newItemQuantity]);

  const { SINGLE_LINE_ITEM_MAX_QTY, SINGLE_LINE_ITEM_MIN_QTY } = marketConfigs;

  const getMaxQuantity = () => {
    return SINGLE_LINE_ITEM_MAX_QTY || QUANTITY.MAX;
  };

  const getMinQuantity = () => {
    return SINGLE_LINE_ITEM_MIN_QTY || QUANTITY.MIN;
  };

  const handleDecrement = (e) => {
    e.stopPropagation();

    const newValue =
      newItemQuantity - 1 >= getMinQuantity()
        ? newItemQuantity - 1
        : newItemQuantity;

    updateQuantity(newValue);
  };

  const handleIncrement = (e) => {
    e.stopPropagation();

    const newValue =
      newItemQuantity + 1 <= getMaxQuantity()
        ? newItemQuantity + 1
        : newItemQuantity;

    updateQuantity(newValue);
  };

  const updateQuantity = (nextValue) => {
    setOrderProduct({
      orderProductId,
      itemQuantity: nextValue,
    });
  };

  const handleQuantityBlur = (value) => {
    const parsedQuantity = parseInt(value, 10);

    setOrderProduct({
      orderProductId,
      itemQuantity:
        parsedQuantity &&
        parsedQuantity >= getMinQuantity() &&
        parsedQuantity <= getMaxQuantity()
          ? parsedQuantity
          : newItemQuantity,
    });
  };

  const handleQuantityChange = (value) => {
    setNewItemQuantity(value);
  };

  const handleReceiptItemClick = () => {
    setActiveOrderProduct({ active: !active, orderProductId });
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteOrderProduct();
  };

  const numberOfDefaultSides = Object.values(defaultSides).reduce(
    (total, num) => total + num,
    0
  );

  const markup = (
    <div
      className={classNames("receipt__item split", active && "is-active")}
      data-quid={`receipt-item-${itemNumber}`}
      onClick={handleReceiptItemClick}
      onKeyDown={handleKeyDown}
      role="presentation"
    >
      <div className="split__left" data-quid="quantity">
        <b>{itemQuantity}</b>
      </div>

      <div className="split__middle" data-quid="name">
        <b>{itemName}</b>

        <br />

        {children}
      </div>

      <div
        className={classNames(
          shouldUseAutomaticPriceOrder && priceOrderLoading
            ? "price-loading"
            : "split__right"
        )}
        data-quid="price"
      >
        <b className={typeof itemPrice === "number" ? "" : "is-hidden"}>
          {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
            <DotLoader />
          ) : (
            formatCurrency(marketCode, itemPrice)
          )}
        </b>
      </div>

      <div className={classNames("split__footer", !active && "is-hidden")}>
        <div>
          <div className="split__footer--left">
            <QuantityButtons
              increment={handleIncrement}
              decrement={handleDecrement}
              update={handleQuantityBlur}
              set={handleQuantityChange}
              quantity={newItemQuantity}
              onClick={handleInputClick}
              minimum={getMinQuantity()}
              maximum={getMaxQuantity()}
            />
          </div>

          <div className="split__footer--right">
            <button
              className="btn btn--fill btn--negative clickable"
              data-quid="delete-item"
              onClick={handleDelete}
            >
              {t("shared:action.delete")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  if (
    showDefaultSidesInReceiptSidebar &&
    (showZeroDefaultSidesForCategoryIds.includes(categoryId) ||
      numberOfDefaultSides > 0)
  ) {
    return (
      <Tooltip
        title={t("finish:order_summary:default_sides_included", {
          number: numberOfDefaultSides,
        })}
      >
        {markup}
      </Tooltip>
    );
  }

  return markup;
};

ReceiptItem.propTypes = {
  active: PropTypes.bool,
  categoryId: PropTypes.string.isRequired,
  children: PropTypes.node,
  defaultSides: PropTypes.object.isRequired,
  deleteOrderProduct: PropTypes.func.isRequired,
  itemName: PropTypes.string,
  itemNumber: PropTypes.number.isRequired,
  itemPrice: PropTypes.number,
  itemQuantity: PropTypes.number,
  marketCode: PropTypes.string.isRequired,
  marketConfigs: PropTypes.shape({
    SINGLE_LINE_ITEM_MAX_QTY: PropTypes.number,
    SINGLE_LINE_ITEM_MIN_QTY: PropTypes.number,
  }).isRequired,
  orderProductId: PropTypes.number,
  setActiveOrderProduct: PropTypes.func.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default ReceiptItem;
