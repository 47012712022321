import { createSelector } from "reselect";

import { getVariantKey } from "ducks/variants/selectors";

import FOOD_CATEGORY, {
  COUPON_CATEGORY,
  SANDWICH_CATEGORY,
} from "constants/menu";
import { OPTION } from "constants/order";
import { defaultWeight } from "constants/topping";
import flatten from "modules/flatten";

const set = (array) => Array.from(new Set(array));

const getMenuBases = ({ Flavors: Bases = {} } = {}) => Bases;

const getMenuCategorization = ({ Categorization = {} } = {}) => Categorization;

const getMenuCoupons = ({ Coupons = {} } = {}) => Coupons;

const getMenuProducts = ({ Products = {} } = {}) => Products;

const getMenuSides = ({ Sides = {} } = {}) => Sides;

const getMenuSizes = ({ Sizes = {} } = {}) => Sizes;

const getMenuToppings = ({ Toppings = {} } = {}) => Toppings;

const getMenuVariants = ({ Variants = {} } = {}) => Variants;

const getMenuCookingInstructionGroups = ({
  CookingInstructionGroups = {},
} = {}) => CookingInstructionGroups;

const getMenuCookingInstructionOptions = ({ CookingInstructions = {} } = {}) =>
  CookingInstructions;

const getNewCategories = createSelector(
  [getMenuCategorization],
  (Categorization) =>
    Object.entries(Categorization)
      .map(([RootCategoryCode, { Categories: TopCategories }]) =>
        TopCategories.reduce(function flattenCategories(
          all,
          { Categories, ...Category } = {}
        ) {
          const { Code } = Category;

          Category.ParentCategory =
            this && this.ParentCategory
              ? this.ParentCategory
              : RootCategoryCode;

          Category.SubCategories = Categories.map(
            ({ Code: SubCategoryCode }) => SubCategoryCode
          );

          return all.concat(
            Category,
            Categories.reduce(
              flattenCategories.bind({
                ParentCategory: Code,
              }),
              []
            )
          );
        },
        []).reduce(flatten, [])
      )
      .reduce(flatten, [])
      .reduce(
        (all, { Code, ...Category }) =>
          Object.assign(all, {
            [Code]: Object.assign({ Code }, Category),
          }),
        {}
      )
);

export const getCategoriesWithMappings = createSelector(
  [getNewCategories, getMenuBases, getMenuSides, getMenuSizes, getMenuToppings],
  (Categories, Bases, Sides, Sizes, Toppings) =>
    Object.values(Categories)
      .sort(({ SubCategories: a }, { SubCategories: b }) => a.length - b.length)
      .map(
        ({
          BaseIds = [],
          Code,
          ParentCategory,
          Products: ProductIds,
          SideIds = [],
          SizeIds = [],
          SubCategories,
          Tags = {},
          ToppingIds = [],
          ...Category
        }) => {
          const ToppingCategoryCode =
            ParentCategory === SANDWICH_CATEGORY ? ParentCategory : Code;
          const CategoryProps = Object.assign(
            {
              Code,
              ParentCategory,
              ProductIds,
              SubCategories,
              Tags,
            },
            Category
          );

          switch (ParentCategory) {
            case COUPON_CATEGORY: {
              return Object.assign(
                {
                  CouponIds: ProductIds,
                },
                CategoryProps
              );
            }
            case SANDWICH_CATEGORY:
            case FOOD_CATEGORY: {
              BaseIds = set(BaseIds.concat(Object.keys(Bases[Code] || {})));

              SideIds = set(SideIds.concat(Object.keys(Sides[Code] || {})));

              SizeIds = set(SizeIds.concat(Object.keys(Sizes[Code] || {})));

              ToppingIds = set(
                ToppingIds.concat(
                  Object.keys(Toppings[ToppingCategoryCode] || {}).map(
                    (ToppingId) => `${ToppingCategoryCode}${ToppingId}`
                  )
                )
              );

              const ModifiedSubCategories = [];

              SubCategories.forEach((SubCategoryCode) => {
                const {
                  BaseIds: SubBaseIds = [],
                  SideIds: SubSideIds = [],
                  SizeIds: SubSizeIds = [],
                  Products: SubProductIds = [],
                  ToppingIds: SubToppingIds = [],
                  ...SubCategory
                } = Categories[SubCategoryCode];

                ModifiedSubCategories.push(
                  Object.assign({}, SubCategory, {
                    BaseIds: set(SubBaseIds.concat(BaseIds)),
                    SideIds: set(SubSideIds.concat(SideIds)),
                    SizeIds: set(SubSizeIds.concat(SizeIds)),
                    ProductIds: SubProductIds,
                    ToppingIds: set(SubToppingIds.concat(ToppingIds)),
                  })
                );
              });

              return [
                Object.assign(
                  {
                    BaseIds,
                    SideIds,
                    SizeIds,
                    ToppingIds,
                  },
                  CategoryProps
                ),
              ].concat(ModifiedSubCategories);
            }
            default:
              return CategoryProps;
          }
        }
      )
      .reduce(flatten, [])
      .filter(
        ({ ProductIds, SubCategories }) =>
          ProductIds.length > 0 || SubCategories.length > 0
      )
);

export const getNormalizedCategories = createSelector(
  [getCategoriesWithMappings],
  (Categories) =>
    Categories.reduce(
      (
        categories,
        {
          BaseIds = [],
          Code,
          CouponIds = [],
          Description,
          Name,
          ParentCategory,
          ProductIds = [],
          SideIds = [],
          SizeIds = [],
          SubCategories,
          Tags = {},
          ToppingIds = [],
        }
      ) =>
        Object.assign({}, categories, {
          [Code]: {
            baseIds: BaseIds,
            categoryCode: Code,
            categoryDescription: Description,
            categoryId: Code,
            categoryName: Name,
            couponIds: CouponIds,
            parentCategory: ParentCategory,
            productIds: ProductIds,
            sideIds: SideIds,
            sizeIds: SizeIds,
            subCategories: SubCategories,
            tags: Tags,
            toppingIds: ToppingIds,
          },
        }),
      {}
    )
);

export const getNewProducts = createSelector(
  [getMenuProducts, getMenuToppings, getMenuVariants],
  (Products, Toppings, Variants) =>
    Object.values(Products).map(
      ({
        AvailableSides,
        AvailableToppings,
        Code,
        DefaultToppings,
        DefaultSides,
        ProductType,
        Variants: ProductVariants,
        ...Product
      }) =>
        Object.assign(
          {
            Code,
            DefaultOptions: DefaultToppings.split(",")
              .concat(DefaultSides.split(","))
              .filter(([OptionId]) => OptionId)
              .reduce((all, toppingCodeAndWeight) => {
                const [OptionId, Weight = defaultWeight] =
                  toppingCodeAndWeight.split("=");

                return Object.assign(all, {
                  [`${ProductType}${OptionId}${Code}`]: parseFloat(Weight),
                });
              }, {}),
            WeightedOptionIds: set(
              AvailableToppings.split(",")
                .concat(AvailableSides.split(","))
                .filter(([OptionId]) => OptionId)
                .map((toppingCodeAndWeights) => {
                  const [OptionId] = toppingCodeAndWeights.split("=");

                  return `${ProductType}${OptionId}${Code}`;
                })
                .reduce(flatten, [])
            ),
          },
          ProductVariants.reduce(
            (options, VariantId) => {
              const {
                FlavorCode: BaseCode,
                SizeCode,
                Tags,
              } = Variants[VariantId];

              const { BaseIds, SizeIds } = options;
              const { DefaultSides, Donation } = Tags;

              const currentDefaultVariantOptions = DefaultSides.split(
                ","
              ).reduce((all, toppingCodeAndWeight) => {
                const [OptionId, Weight = defaultWeight] =
                  toppingCodeAndWeight.split("=");

                return Object.assign(all, {
                  [`${ProductType}${OptionId}${Code}`]: parseFloat(Weight),
                });
              }, {});

              const getSizeIds = () => {
                if (Donation) {
                  return set(SizeIds.concat(VariantId));
                }

                return SizeCode ? set(SizeIds.concat(SizeCode)) : SizeIds;
              };

              options.DefaultSizeVariantOptions[SizeCode] =
                currentDefaultVariantOptions;

              if (SizeCode && BaseCode) {
                options.SizesByBaseId[BaseCode] = [
                  ...(options.SizesByBaseId[BaseCode] ?? []),
                  SizeCode,
                ];

                options.BasesBySizeId[SizeCode] = [
                  ...(options.BasesBySizeId[SizeCode] ?? []),
                  BaseCode,
                ];
              }

              return {
                ...options,
                BaseIds: BaseCode ? set(BaseIds.concat(BaseCode)) : BaseIds,
                SizeIds: getSizeIds(),
              };
            },
            {
              BaseIds: [],
              SizeIds: [],
              DefaultSizeVariantOptions: {},
              SizesByBaseId: {},
              BasesBySizeId: {},
            }
          ),
          Product
        )
    )
);

export const getNormalizedProducts = createSelector(
  [getNewProducts],
  (Products) =>
    Products.reduce(
      (
        products,
        {
          BaseIds,
          BasesBySizeId,
          Code,
          DefaultOptions,
          DefaultSizeVariantOptions,
          Description,
          Name,
          SizeIds,
          SizesByBaseId,
          Tags: { PartCount = 1 },
          WeightedOptionIds,
        }
      ) =>
        Object.assign({}, products, {
          [Code]: {
            baseIds: BaseIds,
            basesBySizeId: BasesBySizeId,
            defaultOptions: DefaultOptions,
            defaultSizeVariantOptions: DefaultSizeVariantOptions,
            partCount: PartCount,
            productCode: Code,
            productDescription: Description,
            productId: Code,
            productName: Name,
            sizeIds: SizeIds,
            sizesByBaseId: SizesByBaseId,
            weightedOptionIds: WeightedOptionIds,
          },
        }),
      {}
    )
);

export const getNewSizes = createSelector(
  [getMenuSizes, getMenuVariants],
  (Sizes, Variants) =>
    Object.values(Sizes)
      .map((Category) =>
        Object.values(Category).map(({ Code, ...Size }) =>
          Object.assign(
            {
              Code,
            },
            Object.values(Variants)
              .filter(({ SizeCode }) => SizeCode === Code)
              .reduce(
                (options, { FlavorCode: BaseCode, ProductCode }) => {
                  const { BaseIds, ProductIds } = options;

                  return {
                    BaseIds: BaseCode ? set(BaseIds.concat(BaseCode)) : BaseIds,
                    ProductIds: ProductCode
                      ? set(ProductIds.concat(ProductCode))
                      : ProductIds,
                  };
                },
                {
                  BaseIds: [],
                  ProductIds: [],
                }
              ),
            Size
          )
        )
      )
      .reduce(flatten, [])
);

export const getNormalizedSizes = createSelector(
  [getNewSizes, getMenuVariants],
  (Sizes, Variants) => {
    const donationSizes = Object.entries(Variants)
      .filter(([, { Tags: { Donation } = {} }]) => Boolean(Donation))
      .reduce(
        (all, [Code, { Name, Price = 0, ProductCode }]) =>
          Object.assign(all, {
            [Code]: {
              isDonation: true,
              baseIds: [],
              sizeCode: Code,
              sizeId: Code,
              sizeName: Name,
              sizeDescription: Name,
              productIds: [ProductCode],
              price: parseFloat(Price),
            },
          }),
        {}
      );

    return Sizes.reduce(
      (sizes, { BaseIds, Code, Description, Name, ProductIds }) =>
        Object.assign({}, sizes, {
          [Code]: {
            baseIds: BaseIds,
            productIds: ProductIds,
            sizeCode: Code,
            sizeDescription: Description,
            sizeId: Code,
            sizeName: Name,
          },
        }),
      donationSizes
    );
  }
);

export const getNewBases = createSelector(
  [getMenuBases, getMenuVariants],
  (Bases, Variants) =>
    Object.values(Bases)
      .map((Category) =>
        Object.values(Category).map(({ Code, ...Base }) =>
          Object.assign(
            {
              Code,
            },
            Object.values(Variants)
              .filter(({ FlavorCode: BaseCode }) => BaseCode === Code)
              .reduce(
                (options, { ProductCode, SizeCode }) => {
                  const { ProductIds, SizeIds } = options;

                  return {
                    ProductIds: set(ProductIds.concat(ProductCode)),
                    SizeIds: set(SizeIds.concat(SizeCode)),
                  };
                },
                {
                  ProductIds: [],
                  SizeIds: [],
                }
              ),
            Base
          )
        )
      )
      .reduce(flatten, [])
);

export const getNormalizedBases = createSelector([getNewBases], (Bases) =>
  Bases.reduce(
    (bases, { Code, Description, Name, ProductIds, SizeIds }) =>
      Object.assign({}, bases, {
        [Code]: {
          baseCode: Code,
          baseDescription: Description,
          baseId: Code,
          baseName: Name,
          productIds: ProductIds,
          sizeIds: SizeIds,
        },
      }),
    {}
  )
);

export const getNewToppings = createSelector([getMenuToppings], (Toppings) =>
  Object.entries(Toppings)
    .map(([CategoryCode, Category]) =>
      Object.values(Category).map(({ Code, ...Topping }) =>
        Object.assign(
          {
            Code,
            ToppingId: `${CategoryCode}${Code}`,
          },
          Topping
        )
      )
    )
    .reduce(flatten, [])
);

export const getNormalizedToppings = createSelector(
  [getNewToppings],
  (Toppings) =>
    Toppings.reduce(
      (
        toppings,
        {
          Code,
          Description,
          Name,
          ToppingId,
          Tags: { Meat = false, Sauce = false, Cheese = false },
        }
      ) =>
        Object.assign({}, toppings, {
          [ToppingId]: {
            toppingCode: Code,
            toppingDescription: Description,
            toppingName: Name,
            toppingId: ToppingId,
            isMeat: Meat,
            isSauce: Sauce,
            isCheese: Cheese,
          },
        }),
      {}
    )
);

export const getNewWeightedOptions = createSelector(
  [getMenuProducts],
  (Products) =>
    Object.values(Products)
      .map(
        ({
          AvailableToppings,
          AvailableSides,
          Code: ProductId,
          ProductType,
          Tags: { OptionQtys = [1] },
        }) =>
          AvailableToppings.split(",")
            .filter(([OptionCode]) => OptionCode)
            .reduce((all, toppingCodeAndWeights) => {
              const [OptionCode, Weights = OptionQtys.join(":")] =
                toppingCodeAndWeights.split("=");

              return all.concat({
                ProductId,
                OptionCode,
                OptionId: `${ProductType}${OptionCode}`,
                OptionType: OPTION.TOPPING,
                WeightedOptionId: `${ProductType}${OptionCode}${ProductId}`,
                Weights: Weights.split(":"),
              });
            }, [])
            .concat(
              AvailableSides.split(",")
                .filter(([OptionCode]) => OptionCode)
                .reduce((all, sideCodeAndQuantities) => {
                  const [OptionCode, Weights = OptionQtys.join(":")] =
                    sideCodeAndQuantities.split("=");

                  return all.concat({
                    ProductId,
                    OptionCode,
                    OptionId: `${OptionCode}`,
                    OptionType: OPTION.SIDE,
                    WeightedOptionId: `${ProductType}${OptionCode}${ProductId}`,
                    Weights: Weights.split(":").filter(
                      (weight) => parseFloat(weight) % 1 === 0
                    ),
                  });
                }, [])
            )
      )
      .reduce(flatten, [])
);

export const getNormalizedWeightedOptions = createSelector(
  [getNewWeightedOptions],
  (WeightedOptions) =>
    WeightedOptions.reduce(
      (
        weightedOptions,
        { OptionCode, OptionId, ProductId, WeightedOptionId, Weights }
      ) =>
        Object.assign({}, weightedOptions, {
          [WeightedOptionId]: {
            optionCode: OptionCode,
            optionId: OptionId,
            productId: ProductId,
            weightedOptionId: WeightedOptionId,
            weights: Weights.map((weight = defaultWeight) =>
              parseFloat(weight)
            ),
          },
        }),
      {}
    )
);

export const getNewSides = createSelector([getMenuSides], (Sides) =>
  Object.values(Sides)
    .map((Category) => Object.values(Category).map((Size) => Size))
    .reduce(flatten, [])
);

export const getNormalizedSides = createSelector([getNewSides], (Sides) =>
  Sides.reduce(
    (sides, { Code, Description, Name }) =>
      Object.assign({}, sides, {
        [Code]: {
          sideCode: Code,
          sideDescription: Description,
          sideId: Code,
          sideName: Name,
        },
      }),
    {}
  )
);

export const getNormalizedVariants = createSelector(
  [getMenuVariants],
  (Variants) =>
    Object.values(Variants).reduce(
      (
        variant,
        {
          AllowedCookingInstructions,
          Code,
          DefaultCookingInstructions,
          FlavorCode: baseCode,
          ImageCode,
          Local,
          Name,
          Prepared,
          Price,
          Pricing,
          ProductCode: productCode,
          SizeCode: sizeCode,
          Surcharge,
        }
      ) => {
        const key = getVariantKey({
          sizeCode,
          baseCode,
          productCode,
        });

        return Object.assign({}, variant, {
          [key]: {
            allowedCookingInstructions: AllowedCookingInstructions.split(","),
            code: Code,
            defaultCookingInstructions: DefaultCookingInstructions.split(","),
            baseCode,
            imageCode: ImageCode,
            local: Local,
            name: Name,
            prepared: Prepared,
            price: Price,
            pricing: Pricing,
            productCode,
            sizeCode,
            surcharge: Surcharge,
          },
        });
      },
      {}
    )
);

export const getNormalizedCoupons = createSelector(
  [getMenuCoupons],
  (Coupons) =>
    Object.values(Coupons).reduce(
      (
        coupons,
        {
          Code,
          Description,
          Local,
          Name,
          Price,
          Tags: {
            Days = [],
            EffectiveOn = null,
            EffectiveAt = [],
            ExpiresAt = [],
            ValidServiceMethods = [],
            ServiceMethods = "",
            Auto2C: auto2C = false,
            DigitalOnly,
          },
        }
      ) => {
        const validServiceMethods = Array.isArray(ValidServiceMethods)
          ? ValidServiceMethods
          : [ValidServiceMethods];

        const serviceMethods = ServiceMethods.split(":");

        return Object.assign({}, coupons, {
          [Code]: {
            couponCode: Code,
            couponDescription: Description,
            couponId: Code,
            couponIsLocal: Local,
            couponName: Name,
            couponPrice: Price,
            auto2C,
            validServiceMethods: validServiceMethods || serviceMethods,
            startDate: EffectiveOn,
            validDays: Days,
            startTime: EffectiveAt,
            endTime: ExpiresAt,
            digitalOnly: DigitalOnly,
          },
        });
      },
      {}
    )
);

export const getNormalizedCookingInstructionGroups = createSelector(
  [getMenuCookingInstructionGroups],
  (CookingInstructionGroups) =>
    Object.values(CookingInstructionGroups).reduce(
      (all, { Code, Name }) => ({
        ...all,
        [Code]: {
          code: Code,
          name: Name,
        },
      }),
      {}
    )
);

export const getNormalizedCookingInstructionOptions = createSelector(
  [getMenuCookingInstructionOptions],
  (CookingInstructionOptions) =>
    Object.values(CookingInstructionOptions).reduce(
      (all, { Code, Name, Description, Group }) => ({
        ...all,
        [Code]: {
          code: Code,
          name: Name,
          description: Description,
          group: Group,
        },
      }),
      {}
    )
);

export const getMenuState = createSelector(
  [
    getNormalizedBases,
    getNormalizedCategories,
    getNormalizedCookingInstructionGroups,
    getNormalizedCookingInstructionOptions,
    getNormalizedCoupons,
    getNormalizedProducts,
    getNormalizedSides,
    getNormalizedSizes,
    getNormalizedToppings,
    getNormalizedVariants,
    getNormalizedWeightedOptions,
  ],
  (
    bases,
    categories,
    cookingInstructionGroups,
    cookingInstructionOptions,
    coupons,
    products,
    sides,
    sizes,
    toppings,
    variants,
    weightedOptions
  ) => ({
    bases,
    categories,
    cookingInstructionGroups,
    cookingInstructionOptions,
    coupons,
    products,
    sides,
    sizes,
    toppings,
    variants,
    weightedOptions,
  })
);

export default {};
