import React, { PureComponent } from "react";

import PropTypes from "prop-types";

class RangedButton extends PureComponent {
  constructor(props) {
    super(props);

    const sortedRange = this.props.range.sort((a, b) => a - b);

    const [highestValue] = sortedRange.slice(-1);
    const [lowestValue] = sortedRange;

    this.state = {
      highestValue,
      lowestValue,
      range: sortedRange,
    };

    this.getNextValue = this.getNextValue.bind(this);
    this.handleDecrement = this.handleDecrement.bind(this);
    this.handleIncrement = this.handleIncrement.bind(this);
  }

  getNextValue(increment) {
    const { range } = this.state;
    const { currentValue } = this.props;

    const currentValueIndex = range.indexOf(currentValue);

    const higherValue =
      currentValueIndex < range.length - 1
        ? range[currentValueIndex + 1]
        : currentValue;

    const lowerValue =
      currentValueIndex > 0 ? range[currentValueIndex - 1] : currentValue;

    return increment ? higherValue : lowerValue;
  }

  handleDecrement() {
    this.props.updateValue(this.getNextValue(false));
  }

  handleIncrement() {
    this.props.updateValue(this.getNextValue(true));
  }

  render() {
    const {
      className,
      currentValue,
      defaultValue,
      disabled,
      enumeration,
      onClick,
      quidBase,
      text,
    } = this.props;

    const {
      higherValue = 1,
      highestValue = 1,
      lowerValue = 0,
      lowestValue = 0,
    } = this.state;

    return (
      <div className="btn-group btn-group--nested text--center">
        {currentValue !== lowestValue && (
          <button
            className="btn btn--primary btn--icon-only"
            data-icon="remove"
            data-quid={`${quidBase}-less`}
            onClick={this.handleDecrement}
            title={enumeration[lowerValue.toString()]}
            type="button"
            disabled={disabled}
          />
        )}

        <button
          className={`btn btn--flex ${className}`}
          onClick={onClick}
          data-quid={quidBase}
          title={`${text} (${enumeration[currentValue.toString()]})`}
          type="button"
          disabled={disabled}
        >
          {text}

          {currentValue !== defaultValue && (
            <span>&nbsp;&times;{currentValue}</span>
          )}
        </button>

        {currentValue !== highestValue && (
          <button
            className="btn btn--primary btn--icon-only"
            data-icon="add"
            data-quid={`${quidBase}-more`}
            onClick={this.handleIncrement}
            title={enumeration[higherValue.toString()]}
            type="button"
            disabled={disabled}
          />
        )}
      </div>
    );
  }
}

RangedButton.propTypes = {
  className: PropTypes.string,
  currentValue: PropTypes.number,
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  enumeration: PropTypes.objectOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  quidBase: PropTypes.string.isRequired,
  range: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
};

RangedButton.defaultProps = {
  className: "",
  currentValue: 1,
  defaultValue: 1,
  disabled: false,
  enumeration: Object.freeze({
    0: "No",
    0.5: "Light",
    1: "Normal",
    1.5: "Extra",
    2: "Double",
    3: "Triple",
  }),
  range: ["1"],
};

export default RangedButton;
