/** @jsxImportSource @emotion/react */
import { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getCurrentStoreId } from "ducks/store/selectors";

import {
  selectCarplayUserPosition,
  selectHasUserSelectedStore,
} from "rtk_redux/slices/customerPageSlice";
import { selectCustomerPosition } from "rtk_redux/slices/serviceMethodSlice";

import Map from "components/Map";
import InfoWindow from "components/Map/InfoWindow";
import Marker from "components/Map/Marker";
import { mapIcons } from "components/Map/constants";

import { useMarkerPosition } from "../utils/hooks";
import StoreMapItem from "./StoreMapItem";

const StoresMap = ({ scrollToStore, stores, t }) => {
  const [showMap, setShowMap] = useState(true);
  const [activeMarker, setActiveMarker] = useState(null);
  const currentStoreId = useSelector(getCurrentStoreId);
  const userHasSelectedStore = useSelector(selectHasUserSelectedStore);

  const customerPosition = useSelector(selectCustomerPosition);
  const [showCustomerMarker, customerLatLng] =
    useMarkerPosition(customerPosition);

  const carplayUserPosition = useSelector(selectCarplayUserPosition);
  const [showCarplayUserMarker, carplayUserLatLng] =
    useMarkerPosition(carplayUserPosition);

  const getStoreIcon = ({ storeId }) => {
    if (!userHasSelectedStore) return mapIcons.defaultOrActive;

    return currentStoreId === storeId
      ? mapIcons.defaultOrActive
      : mapIcons.unselected;
  };

  const getMapCenter = () => {
    if (showCarplayUserMarker) return carplayUserLatLng;

    return customerLatLng;
  };

  const mapCenter = getMapCenter();

  const storeMarkers = stores.map((store) => {
    return (
      <Marker
        options={{
          position: {
            lat: Number(store.StoreCoordinates.StoreLatitude),
            lng: Number(store.StoreCoordinates.StoreLongitude),
          },
          icon: getStoreIcon({ storeId: store.StoreID }),
        }}
        onClick={() => setActiveMarker(store.StoreID)}
        key={store.StoreID}
      >
        {activeMarker === store.StoreID && (
          <InfoWindow onCloseClick={() => setActiveMarker(null)}>
            <StoreMapItem
              scrollToStore={scrollToStore}
              store={store}
              t={t}
              closeInfoWindow={() => setActiveMarker(null)}
            />
          </InfoWindow>
        )}
      </Marker>
    );
  });

  const carplayUserMarker = showCarplayUserMarker ? (
    <Marker
      key="carplay"
      options={{
        position: carplayUserLatLng,
        icon: mapIcons.carplay,
      }}
    />
  ) : null;

  const customerMarker =
    !showCarplayUserMarker && showCustomerMarker ? (
      <Marker
        key="customer"
        options={{
          position: customerLatLng,
          icon: mapIcons.customer,
        }}
      />
    ) : null;

  const goodLocation = showCarplayUserMarker || showCustomerMarker;

  if (!goodLocation) return null;

  return (
    <div>
      {showMap && (
        <Map
          options={{
            zoom: 10,
            center: mapCenter,
            clickableIcons: true,
            gestureHandling: "cooperative",
          }}
          style={{ width: "100%", height: "20rem", marginBottom: "1rem" }}
          onClick={() => setActiveMarker(null)}
        >
          {[carplayUserMarker, customerMarker, ...storeMarkers]}
        </Map>
      )}
      <button className="btn" onClick={() => setShowMap((m) => !m)}>
        {showMap ? t("customer:map.hide_map") : t("customer:map.show_map")}
      </button>
    </div>
  );
};

export default StoresMap;

StoresMap.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.object),
  scrollToStore: PropTypes.func,
};
