import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/streets/";

export const GET_STREETS = `${SCOPE}GET_STREETS`;
export const GET_STREETS_ERROR = `${SCOPE}GET_STREETS_ERROR`;
export const SET_STREETS = `${SCOPE}SET_STREETS`;

export function getStreets({ url, query, power = false } = {}) {
  return {
    type: GET_STREETS,
    url,
    query,
    power,
  };
}

export function getStreetsError(error) {
  return {
    type: GET_STREETS_ERROR,
    error,
  };
}

export function setStreets(streets = { data: [] }) {
  if (streets.Addresses) {
    streets = {
      data: streets.Addresses.map(
        ({ Cep, Neighborhood, State, City, Street }) => ({
          id: Street,
          attributes: {
            cep: Cep,
            city: City,
            neighborhood: Neighborhood,
            state: State,
            street: Street,
          },
          relationships: { places: { links: { related: { href: "" } } } },
        })
      ),
    };
  }

  return {
    type: SET_STREETS,
    streets,
  };
}

export const initialState = { data: [] };

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_STREETS:
      return { ...state, ...action.streets };
    default:
      return state;
  }
}

export const getStreetsEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_STREETS),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        map(setStreets),
        catchError((error) => of(getStreetsError(error)))
      )
    )
  );

export const epics = combineEpics(getStreetsEpic);
