import React, { useState } from "react";

import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { ROUTE_TITLES } from "routes";

import { sendTealiumExitReason } from "rtk_redux/actions/tealium";

import { defaultExitLabel, exitOptions } from "constants/exit";
import kebabCase from "modules/kebabCase";

import Page from "components/Page";

const Exit = ({ exitOrder, sidebar, t }) => {
  const dispatch = useDispatch();
  const [exitReason, setExitReason] = useState(t(defaultExitLabel));

  const handleExitReasonChange = ({ target: { value } }) => {
    setExitReason(value);
  };

  const handleExitOrder = () => {
    dispatch(
      sendTealiumExitReason({
        exitReason,
      })
    );

    exitOrder();
  };

  const footer = (
    <button
      className="btn btn--negative btn--fill"
      data-quid="exit-call"
      onClick={handleExitOrder}
      type="button"
    >
      <span> {t("exit:exit_call")} </span>
    </button>
  );

  return (
    <Page
      footer={footer}
      id="exit"
      sidebar={sidebar}
      title={t("exit:title")}
      seoTitle={ROUTE_TITLES.EXIT}
    >
      <p>{t("exit:subtitle")}</p>

      <ul className="list" data-quid="exit-reason">
        {exitOptions.map(({ addendum, key, label }) => (
          <li className="list__item list__item--with-controls" key={key}>
            <input
              className="list__item-controls"
              defaultChecked={label === defaultExitLabel}
              id={`exit-${key}-radio`}
              name="exit-radio"
              data-quid={`${kebabCase(key)}-input`}
              type="radio"
              onChange={handleExitReasonChange}
              value={t(label)}
            />

            <label
              className="list__item-content"
              data-quid={`${kebabCase(key)}-label`}
              htmlFor={`exit-${key}-radio`}
            >
              {t(label)}
            </label>

            {addendum && (
              <textarea
                className="list__item--addendum"
                placeholder={t(addendum)}
              />
            )}
          </li>
        ))}
      </ul>
    </Page>
  );
};

Exit.propTypes = {
  exitOrder: PropTypes.func.isRequired,
  sidebar: PropTypes.node,
  t: PropTypes.func,
};

Exit.defaultProps = {
  sidebar: null,
  t: () => {},
};

export default Exit;
