import is from "is_js";
import { combineEpics, ofType } from "redux-observable";
import { from } from "rxjs/observable/from";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck, reduce } from "rxjs/operators";

import { RESET_STATE } from "ducks/configuration";
import {
  getDataIdFromRelationship,
  getIncludedResourceById,
} from "selectors/related";

import { SERVICE_METHOD } from "constants/order";
import withValidation from "modules/elValidadore";

const SCOPE = "order-entry/orderStatus/";

export const GET_ORDER_STATUSES = `${SCOPE}GET_ORDER_STATUSES`;
export const GET_ORDER_STATUSES_ERROR = `${SCOPE}GET_ORDER_STATUSES_ERROR`;
export const SET_ORDER_STATUSES = `${SCOPE}SET_ORDER_STATUSES`;
export const CLEAR_ORDER_STATUSES = `${SCOPE}CLEAR_ORDER_STATUSES`;

export const getOrderStatuses = withValidation(
  ({ phone = "", url = "" } = {}) => ({
    type: GET_ORDER_STATUSES,
    phone,
    url,
  }),
  {
    url: [is.string, is.not.empty],
  }
);

export function getOrderStatusesError(error) {
  return {
    type: GET_ORDER_STATUSES_ERROR,
    error,
  };
}

const getOrderStatus = ({
  bakeSeconds = 0,
  bakeTime = "",
  checkSeconds = 0,
  checkTime = "",
  futureOrderTime = "",
  links = {},
  orderDescription = "",
  orderSource = "",
  orderStatusId = null,
  phone = "",
  placeSeconds = 0,
  placeTime = "",
  prepSeconds = 0,
  prepTime = "",
  readySeconds = 0,
  readyTime = "",
  relationships = {},
  serviceMethod = SERVICE_METHOD.CARRYOUT,
  status = "",
  storeOrderId = "",
  storeTime = "",
}) => ({
  bakeSeconds,
  bakeTime,
  checkSeconds,
  checkTime,
  futureOrderTime,
  links,
  orderDescription,
  orderSource,
  orderStatusId,
  phone,
  placeSeconds,
  placeTime,
  prepSeconds,
  prepTime,
  readySeconds,
  readyTime,
  relationships,
  serviceMethod,
  status,
  storeOrderId,
  storeTime,
});

export function setOrderStatuses(orderStatuses = {}) {
  return {
    type: SET_ORDER_STATUSES,
    orderStatuses,
  };
}

export function clearOrderStatuses() {
  return {
    type: CLEAR_ORDER_STATUSES,
  };
}

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_ORDER_STATUSES:
      return action.orderStatuses;
    case CLEAR_ORDER_STATUSES:
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

const reduceOrderStatuses = (all, { attributes, id, type, ...orderStatus }) =>
  Object.assign(all, {
    [id]: getOrderStatus(
      Object.assign({}, attributes, orderStatus, {
        orderStatusId: id,
      })
    ),
  });

export const getOrderStatusesEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_ORDER_STATUSES),
    mergeMap(({ phone, ...action }) =>
      fetch(
        Object.assign(action, {
          query: {
            "filter[phone]": phone,
            include: "orderStatus",
          },
        })
      ).pipe(
        pluck("response"),
        mergeMap(({ data, included }) =>
          from(data).pipe(
            mergeMap((orderDescription) =>
              of(orderDescription).pipe(
                map(getDataIdFromRelationship("orderStatus")),
                map((orderStatusId) =>
                  getIncludedResourceById(included, orderStatusId)
                )
              )
            ),
            reduce(reduceOrderStatuses, {}),
            map(setOrderStatuses)
          )
        ),
        catchError((error) => of(getOrderStatusesError(error)))
      )
    )
  );

export const epics = combineEpics(getOrderStatusesEpic);
