import { createSelector } from "reselect";

export const getSession = ({ session = {} } = {}) => session;

export const getToken = createSelector([getSession], ({ token = "" }) => token);

export const getLogout = createSelector(
  [getSession],
  ({ logout = false }) => logout
);
