import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { getNewExperienceFeature } from "ducks/market/selectors";
import {
  getCurrentOrderTotal,
  getCurrentServiceMethod,
} from "ducks/order/selectors";
import {
  getCurrentStoreAcceptableTipPaymentTypes,
  getCurrentStoreCarsideTippingEnabled,
  getCurrentStoreIsTippingAllowedAtCheckout,
} from "ducks/store/selectors";

import { PAYMENT_TYPES, TIP_KEYS } from "constants/finish";
import { SERVICE_METHOD } from "constants/order";
import parseCurrency from "modules/parseCurrency";

const initialState = {
  cardType: "",
  expirationMonth: "",
  expirationYear: "",
  invalid: [],
  paymentProcessingError: false,
  paymentType: "",
  paymentTypeError: false,
  previousCardType: "",
  securityCode: "",
  zipCode: "",
  savedCCSecurityCode: "",
  tip: TIP_KEYS.NONE,
  tipAmount: "0",
};

const finishSlice = createSlice({
  name: "finish",
  initialState,
  reducers: {
    setCardTypeValue: (state, { payload }) => {
      state.cardType = payload;
    },
    setExpirationMonthValue: (state, { payload }) => {
      state.expirationMonth = payload;
    },
    setExpirationYearValue: (state, { payload }) => {
      state.expirationYear = payload;
    },
    setInvalidValue: (state, { payload }) => {
      state.invalid = payload;
    },
    setPaymentProcessingErrorValue: (state, { payload }) => {
      state.paymentProcessingError = payload;
    },
    setPaymentTypeValue: (state, { payload }) => {
      state.paymentType = payload;
    },
    setPaymentTypeErrorValue: (state, { payload }) => {
      state.paymentTypeError = payload;
    },
    setPreviousCardType: (state, { payload }) => {
      state.previousCardType = payload;
    },
    setSecurityCodeValue: (state, { payload }) => {
      state.securityCode = payload;
    },
    setZipCodeValue: (state, { payload }) => {
      state.zipCode = payload;
    },
    setSavedCCSecurityCode: (state, { payload }) => {
      state.savedCCSecurityCode = payload;
    },
    setTip: (state, { payload }) => {
      state.tip = payload;
    },
    setTipAmount: (state, { payload }) => {
      state.tipAmount = payload;
    },
    resetFinishState: () => {
      return initialState;
    },
  },
});

export const {
  setCardTypeValue,
  setExpirationMonthValue,
  setExpirationYearValue,
  setInvalidValue,
  setPaymentProcessingErrorValue,
  setPaymentTypeValue,
  setPaymentTypeErrorValue,
  setPreviousCardType,
  setPriceOrderLoading,
  setSecurityCodeValue,
  setZipCodeValue,
  setSavedCCSecurityCode,
  setTip,
  setTipAmount,
  resetFinishState,
} = finishSlice.actions;

export default finishSlice.reducer;

// Selectors
export const selectCardType = (state) => state.finish.cardType;

export const selectExpirationMonth = (state) => state.finish.expirationMonth;

export const selectExpirationYear = (state) => state.finish.expirationYear;

export const selectInvalid = (state) => state.finish.invalid;

export const selectPaymentProcessingError = (state) =>
  state.finish.paymentProcessingError;

export const selectPaymentType = (state) => state.finish.paymentType;

export const selectPaymentTypeError = (state) => state.finish.paymentTypeError;

export const selectPreviousCardType = (state) => state.finish.previousCardType;

export const selectSecurityCode = (state) => state.finish.securityCode;

export const selectSavedCCSecurityCode = (state) =>
  state.finish.savedCCSecurityCode;

export const selectTip = (state) => state.finish.tip;

export const selectTipAmount = (state) => state.finish.tipAmount;

export const selectTipAmountNumeric = (state) =>
  parseCurrency(state.finish.tipAmount);

export const getShowTip = createSelector(
  [
    getNewExperienceFeature,
    getCurrentStoreIsTippingAllowedAtCheckout,
    getCurrentOrderTotal,
    selectPaymentType,
    getCurrentStoreAcceptableTipPaymentTypes,
    getCurrentServiceMethod,
    getCurrentStoreCarsideTippingEnabled,
  ],
  (
    { showTippingOnPayments },
    isTippingAllowedAtCheckout,
    currentOrderTotal,
    paymentType,
    acceptableTipPaymentTypes,
    currentServiceMethod,
    carsideTippingEnabled
  ) => {
    if (!showTippingOnPayments) return false;
    if (!isTippingAllowedAtCheckout) return false;
    if (!currentOrderTotal) return false;

    const currentPaymentType =
      paymentType === PAYMENT_TYPES.SAVED_CREDIT_CARD
        ? PAYMENT_TYPES.CREDIT_CARD
        : paymentType;
    if (!acceptableTipPaymentTypes.includes(currentPaymentType)) return false;

    if (currentServiceMethod === SERVICE_METHOD.DCD && carsideTippingEnabled)
      return true;

    if (currentServiceMethod !== SERVICE_METHOD.DELIVERY) return false;

    return true;
  }
);

export const selectZipCode = (state) => state.finish.zipCode;
