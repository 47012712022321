export default (...cookingInstructionGroups) =>
  cookingInstructionGroups
    .reduce((all, cookingInstructionGroup) => {
      cookingInstructionGroup.forEach((instruction) => {
        const foundGroupIndex = all.findIndex(
          ({ group }) => group === instruction?.group
        );

        if (foundGroupIndex > -1) {
          all.splice(foundGroupIndex, 1, instruction);
        } else {
          all.push(instruction);
        }
      });

      return all;
    }, [])
    .filter(Boolean);
