import { TRACKER_STATUS } from "constants/order";

export const tabs = Object.freeze([
  {
    id: "profile",
    path: "profile",
    icon: "person",
    quidBase: "customer-profile",
    text: "customer:profile.title",
    translate: true,
  },
  {
    id: "locations",
    path: "locations",
    icon: "location_on",
    quidBase: "customer-locations",
    text: "customer:locations.title",
    translate: true,
  },
  {
    id: "orders",
    path: "orders",
    icon: "history",
    quidBase: "customer-order-history",
    text: "customer:orders.title",
    translate: true,
  },
]);

export const PROFILE = Object.freeze({
  PHONE: "phone",
  PREFIX: "prefix",
  EXTENSION: "extension",
  EMAIL: "email",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  SERVICE_METHOD: "serviceMethod",
  ORDER_TIMING: "orderTiming",
  COMMENTS: "comments",
});

export const PROFILE_FIELDS = Object.freeze({
  [PROFILE.PREFIX]: {
    key: PROFILE.PREFIX,
    defaultValue: "",
    label: "customer:profile.phone_prefix",
    type: "any",
    required: true,
  },
  [PROFILE.PHONE]: {
    key: PROFILE.PHONE,
    label: "customer:profile.phone_number",
    type: "phone",
    max: 49,
    required: true,
  },
  [PROFILE.EXTENSION]: {
    key: PROFILE.EXTENSION,
    label: "customer:profile.extension",
    type: "any",
  },
  [PROFILE.EMAIL]: {
    key: PROFILE.EMAIL,
    label: "customer:profile.email_address",
    type: "email",
  },
  [PROFILE.FIRST_NAME]: {
    key: PROFILE.FIRST_NAME,
    label: "customer:profile.first_name",
    type: "alpha",
  },
  [PROFILE.LAST_NAME]: {
    key: PROFILE.LAST_NAME,
    label: "customer:profile.last_name",
    type: "alpha",
  },
  [PROFILE.COMMENTS]: {
    key: PROFILE.COMMENTS,
    label: "customer:profile.comments",
    type: "textarea",
  },
});

export const ONLY_LETTERS_INCLUDING_ACCENTS_REGEX = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/;

const recentLocations = Object.freeze([
  {
    key: "orderDateTime",
    label: "customer:profile.recent_locations.ordered_on",
  },
  {
    key: "storeId",
    label: "customer:profile.recent_locations.store_number",
  },
  {
    key: "storeStatus",
    label: "customer:profile.recent_locations.store_status",
  },
  {
    key: "onlineStatus",
    label: "customer:profile.recent_locations.online_status",
  },
  {
    key: "storeInformation",
    label: "customer:profile.recent_locations.store_information",
    values: ["storeName", "addressDescription", "phone"],
  },
]);

export const recentCarryoutLocations = Object.freeze(
  recentLocations.concat([
    {
      key: "customerAddress",
      label: "customer:profile.recent_locations.carryout_location",
    },
  ])
);

export const recentDeliveryLocations = Object.freeze(
  recentLocations.concat([
    {
      key: "customerAddressNickname",
      label: "customer:profile.recent_locations.delivery_address_nickname",
      values: ["customerAddressNickname"],
    },
    {
      key: "customerAddress",
      label: "customer:profile.recent_locations.delivery_address",
      values: ["customerAddress", "complexDescription"],
    },
  ])
);

export const orderHistoryHeadings = Object.freeze([
  {
    id: "storeOrderId",
    label: "customer:orders.order_status.store_order_id",
  },
  {
    id: "orderDateTime",
    label: "customer:orders.order_status.order_place_time",
  },
  {
    id: "storeId",
    label: "customer:orders.order_status.store_number",
  },
  {
    id: "storeName",
    label: "customer:orders.order_status.store_name",
  },
  {
    id: "serviceMethod",
    label: "customer:orders.order_status.service_method",
  },
]);

export const orderHistorySubheadings = Object.freeze([
  {
    icon: "home",
    id: "deliveryAddress",
    label: "customer:orders.order_status.delivery_address",
  },
  {
    icon: "store",
    id: "storeAddress",
    label: "customer:orders.order_status.store_address",
  },
  {
    icon: "payment",
    id: "paymentType",
    label: "customer:orders.order_status.payment_type",
  },
]);

export const orderStatusHeadings = Object.freeze([
  {
    id: "storeOrderId",
    label: "customer:orders.order_status.store_order_id",
  },
  {
    id: "storeId",
    label: "customer:orders.order_status.store_number",
  },
  {
    id: "status",
    label: "customer:orders.order_status.status",
  },
  {
    id: "orderPlaceTime",
    label: "customer:orders.order_status.order_place_time",
  },
  {
    id: "futureOrderTime",
    label: "customer:orders.order_status.future_order_time",
  },
]);

export const orderStatusTracker = Object.freeze([
  {
    className: "step--short",
    id: "placeTime",
    label: "customer:orders.order_status.place",
    value: TRACKER_STATUS.PLACE,
  },
  {
    className: "step--short",
    id: "prepTime",
    label: "customer:orders.order_status.prep",
    value: TRACKER_STATUS.PREP,
  },
  {
    className: "step--medium",
    id: "bakeTime",
    label: "customer:orders.order_status.bake",
    value: TRACKER_STATUS.BAKE,
  },
  {
    className: "step--short",
    id: "checkTime",
    label: "customer:orders.order_status.check",
    value: TRACKER_STATUS.CHECK,
  },
  {
    className: "step--long",
    id: "readyTime",
    label: "customer:orders.order_status.ready",
    value: TRACKER_STATUS.READY,
  },
]);

export const orderStatusSubheadings = Object.freeze([
  {
    icon: "layers",
    id: "source",
    label: "customer:orders.order_status.order_source",
  },
  {
    icon: "access_time",
    id: "storeTime",
    label: "customer:orders.order_status.store_time",
  },
  {
    icon: "phone",
    id: "serviceMethod",
    label: "customer:orders.order_status.service_method",
  },
]);

export const storeHeadings = Object.freeze([
  {
    key: "storeStatus",
    label: "customer:locations.store.store_status",
  },
  {
    key: "onlineStatus",
    label: "customer:locations.store.online_status",
  },
  {
    key: "serviceMethodsDescription",
    label: "customer:locations.store.service_methods",
  },
  {
    key: "storeId",
    label: "customer:locations.store.store_number",
  },
  {
    key: "storeName",
    label: "customer:locations.store.store_name",
  },
  {
    key: "addressDescription",
    label: "customer:locations.store.store_address",
  },
  {
    key: "phone",
    label: "customer:locations.store.phone_number",
  },
  {
    key: "distance",
    label: "customer:locations.store.distance",
  },
]);

export const storeSubheadings = Object.freeze([
  {
    key: "hoursDescription",
    label: "customer:locations.store.service_hours",
  },
]);

export default tabs;
