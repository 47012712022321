import { EmailValidator } from "commons-validator-js";
import { isValidNumber } from "libphonenumber-js";

import { CC_TYPES_FULL_NAME } from "constants/finish";
import checkCCLength from "modules/checkCCLength";
import checkLuhn from "modules/checkLuhn";

const validExpirationDate = (expirationMonth, expirationYear) => {
  const today = new Date();
  const currentYear2Digits = today.getFullYear().toString().slice(-2);
  const currentMonth = today.getMonth() + 1;
  // check that expiration month is 0-12
  if (expirationMonth && parseInt(expirationMonth, 10) > 12) {
    return false;
  }
  if (expirationYear === currentYear2Digits) {
    return expirationMonth
      ? parseInt(expirationMonth, 10) >= currentMonth
      : true;
  } else return expirationYear ? expirationYear > currentYear2Digits : true;
};

export default (
  value = "",
  type = "",
  countryCode = "ZA",
  cardType = "",
  acceptableCreditCards = [],
  expirationMonth = "",
  expirationYear = ""
) => {
  const emailValidator = new EmailValidator();
  switch (type) {
    case "any":
    case "textarea":
      return true;
    case "phone":
      return isValidNumber(value, countryCode);
    case "email":
      return !value || emailValidator.isValid(value);
    case "cardNumber":
      return (
        acceptableCreditCards.includes(CC_TYPES_FULL_NAME[cardType]) &&
        checkCCLength(value) &&
        checkLuhn(value)
      );
    case "number":
      return /^\d+$/.test(value);
    case "expirationMonth":
      return validExpirationDate(value, expirationYear);
    case "expirationYear":
      return validExpirationDate(expirationMonth, value);
    case "alpha":
    default:
      return !/\d/.test(value);
  }
};
