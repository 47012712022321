import React from "react";

import PropTypes from "prop-types";

import kebabCase from "modules/kebabCase";
import { getProductClasses } from "modules/optionHelpers";

const Product = ({
  activeOrderProductProducts = {},
  addSize = false,
  baseIds,
  categoryId,
  isSelected = false,
  mergeBasesAndProducts = false,
  productDescription = "",
  productId,
  productName,
  setOrderProduct,
  sizeIds = [],
}) => {
  const handleProductClick = () => {
    const [baseId] = baseIds;
    const [sizeId] = sizeIds;

    setOrderProduct({
      productId,
      categoryId,
      ...(mergeBasesAndProducts && { baseId }),
      ...(addSize && { sizeId }),
    });
  };

  return (
    <button
      className={
        isSelected
          ? "btn--fill"
          : getProductClasses(productId, activeOrderProductProducts)
      }
      data-quid={`product-${kebabCase(productName)}`}
      onClick={handleProductClick}
      title={productDescription}
      type="button"
    >
      {productName}
    </button>
  );
};

Product.propTypes = {
  activeOrderProductProducts: PropTypes.objectOf(PropTypes.string),
  addSize: PropTypes.bool,
  baseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  categoryId: PropTypes.string,
  isSelected: PropTypes.bool,
  mergeBasesAndProducts: PropTypes.bool,
  productDescription: PropTypes.string,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  sizeIds: PropTypes.arrayOf(PropTypes.string),
};

export default Product;
