import { css } from "@emotion/react";

export const selectModalStyles = css`
  padding: 1.5rem;
  width: 30rem;
`;

export const customerOptionWrapperStyles = css`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const loyaltyIconStyles = css`
  color: var(--dom-blue);
  width: 1rem;
`;
