import { translate } from "react-i18next";
import { connect } from "react-redux";

import { pizzasRoute } from "routes";

import { setActiveArea } from "ducks/area";
import { getMarketCode, getMarketConfigs } from "ducks/market/selectors";
import {
  getActiveArea,
  getPizzaCategories,
  getProducts,
} from "ducks/menu/selectors";
import {
  getActiveOrderProduct,
  getActiveOrderProductAvailableOptionsByArea,
  getActiveOrderProductBaseId,
  getActiveOrderProductOptionIds,
  getActiveOrderProductSizeId,
  getCurrentOrder,
} from "ducks/order/selectors";
import {
  createOrderProduct,
  deleteOrderProductOption,
  updateOrderProduct,
} from "ducks/orderProduct";
import {
  getActiveOrderProductDefaultOptions,
  getActiveOrderProductProducts,
  getActiveOrderProductToppings,
  getCurrentAreas,
} from "ducks/orderProduct/selectors";
import { getPayloadCategory } from "selectors/location";
import { getLinkFromRelationship, getSelfLink } from "selectors/related";

import { AREA, defaultArea } from "constants/order";
import { BYO } from "constants/pizza";
import categorizeToppings from "modules/categorizeToppings";
import kebabCase from "modules/kebabCase";

import Pizzas from "components/Pizzas";

const mapStateToProps = (state) => ({
  activeArea: getActiveArea(state),
  activeOrderProduct: getActiveOrderProduct(state),
  activeOrderProductAvailableOptionsByArea:
    getActiveOrderProductAvailableOptionsByArea(state),
  activeOrderProductBaseId: getActiveOrderProductBaseId(state),
  activeOrderProductDefaultOptions: getActiveOrderProductDefaultOptions(state),
  activeOrderProductOptionIds: getActiveOrderProductOptionIds(state),
  activeOrderProductProducts: getActiveOrderProductProducts(state),
  activeOrderProductSizeId: getActiveOrderProductSizeId(state),
  activeOrderProductToppings: getActiveOrderProductToppings(state),
  allProducts: getProducts(state),
  areas: getCurrentAreas(state),
  categories: getPizzaCategories(state),
  currentCategoryName: getPayloadCategory(state),
  currentOrder: getCurrentOrder(state),
  marketConfigs: getMarketConfigs(state),
  marketCode: getMarketCode(state),
});

const mapDispatchToProps = (dispatch) => ({
  createOrderProduct: (orderProduct) =>
    dispatch(createOrderProduct(orderProduct)),
  deleteOrderProductOption: (option) =>
    dispatch(deleteOrderProductOption(option)),
  goToPizzas: (category) => dispatch(pizzasRoute(category)),
  setActiveArea: (area) => dispatch(setActiveArea(area)),
  updateOrderProduct: (orderProduct) =>
    dispatch(updateOrderProduct(orderProduct)),
});

const mergeProps = (
  {
    activeOrderProduct,
    activeOrderProductDefaultOptions,
    allProducts,
    categories,
    currentCategoryName,
    currentOrder,
    marketCode,
    marketConfigs,
    ...state
  },
  {
    createOrderProduct: dispatchCreateOrderProduct,
    deleteOrderProductOption: dispatchDeleteOrderProductOption,
    updateOrderProduct: dispatchUpdateOrderProduct,
    ...dispatch
  },
  props
) => {
  const { activeArea, activeOrderProductAvailableOptionsByArea } = state;
  const { PARSE_CATEGORY_TITLE } = marketConfigs;
  const menuTitleParseRegExp = /^"([^"]*)/;
  //TODO: write generic tab builder func
  const tabs = Object.values(categories)
    .map(
      ({
        categoryCode,
        categoryId,
        categoryName,
        products: categoryProducts,
      }) => {
        let text = categoryName;
        if (PARSE_CATEGORY_TITLE) {
          const [, parsedCategoryName] = categoryName.match(
            menuTitleParseRegExp
          ) || ["", categoryName];
          text = parsedCategoryName || text;
        }
        return {
          icon:
            Object.keys(categoryProducts).length === 1 ? "local_pizza" : "star",
          id: categoryId,
          path: categoryCode.toLowerCase(),
          quidBase: `pizzas-${kebabCase(categoryName)}`,
          text,
          fullText: categoryName,
        };
      }
    )
    .map(({ path, ...tab }, index) =>
      Object.assign(tab, {
        active: currentCategoryName
          ? currentCategoryName === path
          : index === 0,
        path,
      })
    );

  const { id: currentCategoryId, fullText: title = "" } =
    tabs.find(({ active }) => active) || {};

  const {
    bases = [],
    parentCategory: currentParentCategory = "",
    products = [],
    sizes = [],
    toppings = [],
  } = categories[currentCategoryId] || {};

  const { meats, nonMeats, sauces } = categorizeToppings(
    toppings.filter(({ toppingId }) =>
      Object.keys(activeOrderProductAvailableOptionsByArea).includes(toppingId)
    )
  );

  const [defaultProduct] = Object.values(products);
  const { productId } = allProducts[BYO.PRODUCT_CODE] || defaultProduct || {};
  const { orderId } = currentOrder;

  const {
    categoryId: activeOrderProductCategoryId,
    orderProductId: activeOrderProductId,
  } = activeOrderProduct;

  const { parentCategory: activeOrderProductParentCategory } =
    categories[activeOrderProductCategoryId] || {};

  const activeOrderProductLink = getSelfLink(activeOrderProduct);
  const productsLink = getLinkFromRelationship("products")(currentOrder);

  return Object.assign(
    {
      sizes,
      bases,
      products,
      meats,
      nonMeats,
      sauces,
      toppings,
      tabs,
      title,
      orderId,
      currentParentCategory,
      currentCategoryId,
      productId,
      activeOrderProductDefaultOptions,
      createDefaultOrderProduct: () =>
        dispatchCreateOrderProduct({
          categoryId: currentCategoryId,
          orderId,
          productId,
          url: productsLink,
        }),
      deleteOrderProductOption: (removedOptionCode) =>
        activeOrderProductId &&
        dispatchDeleteOrderProductOption(
          Object.assign({}, activeOrderProduct, {
            area: (AREA[activeArea] || AREA[defaultArea]).value,
            defaultOptions: activeOrderProductDefaultOptions,
            optionCode: removedOptionCode,
            url: activeOrderProductLink,
          })
        ),
      setOrderProduct: (orderProduct) =>
        activeOrderProductId &&
        activeOrderProductParentCategory === currentParentCategory
          ? dispatchUpdateOrderProduct(
              Object.assign({}, activeOrderProduct, orderProduct, {
                categoryId: currentCategoryId,
                orderProductId: activeOrderProductId,
                url: activeOrderProductLink,
              })
            )
          : dispatchCreateOrderProduct(
              Object.assign(orderProduct, {
                categoryId: currentCategoryId,
                orderId,
                url: productsLink,
              })
            ),
    },
    state,
    dispatch,
    props
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["food", "pizzas", "shared"])(Pizzas));
