import { css } from "@emotion/react";

export const orderTotals = css`
  float: right;
  padding-right: 8rem;
  padding-top: 0.15rem;
`;

export const orderSavings = css`
  background-clip: content-box;
`;

export const DCDMessage = css`
  display: flex;
`;

export const DCDIcon = css`
  flex: 0 0 3rem;
  padding: 5px 15px 5px 2px;
`;

export const tipSection = css`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const tipButtonsWrapper = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
`;

export const customTipWrapper = css`
  display: flex;
  align-items: center;
  gap: 1em;
`;

export const textBlue = css`
  color: var(--dpzBlue);
`;
