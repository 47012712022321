/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import getMarketCode from "ducks/market/selectors";

import formatCurrency from "modules/formatCurrency";
import kebabCase from "modules/kebabCase";
import { getDimensionClasses } from "modules/optionHelpers";

import Tooltip from "components/Tooltip";

import { disabledStyles } from "styles/shared.styles";

const Size = ({
  activeOrderProductSizeId = "",
  disabled = false,
  isDonation = false,
  price = 0,
  setOrderProduct,
  sizeDescription = "",
  sizeId,
  sizeName,
  tooltipMessage = null,
}) => {
  const marketCode = useSelector(getMarketCode);

  const handleSizeClick = () => {
    setOrderProduct({ sizeId });
  };

  const dataQuid =
    isDonation && price > 0
      ? `size-${kebabCase(sizeName)}-${price}`
      : `size-${kebabCase(sizeName)}`;

  return tooltipMessage ? (
    <Tooltip title={tooltipMessage}>
      <button
        className={getDimensionClasses(sizeId, activeOrderProductSizeId)}
        css={disabled && disabledStyles}
        data-quid={dataQuid}
        key={sizeId}
        onClick={disabled ? () => {} : handleSizeClick}
        type="button"
      >
        {sizeName}
      </button>
    </Tooltip>
  ) : (
    <button
      className={getDimensionClasses(sizeId, activeOrderProductSizeId)}
      data-quid={dataQuid}
      key={sizeId}
      onClick={handleSizeClick}
      title={sizeDescription}
      disabled={disabled}
      type="button"
    >
      {isDonation && price > 0
        ? `${sizeName} ${formatCurrency(marketCode, price, false)}`
        : sizeName}
    </button>
  );
};

Size.propTypes = {
  activeOrderProductSizeId: PropTypes.string,
  disabled: PropTypes.bool,
  isDonation: PropTypes.bool,
  price: PropTypes.number,
  setOrderProduct: PropTypes.func.isRequired,
  sizeDescription: PropTypes.string,
  sizeId: PropTypes.string.isRequired,
  sizeName: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string,
};

export default Size;
