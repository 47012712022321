export default (input) =>
  input
    // Hyphenate camelCasedText
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    // Hyphenate camelCasedTextWithUPPERCASEDParts
    .replace(/([A-Z]{1})([A-Z]{1})([a-z])/g, "$1-$2$3")
    // Replace non-alphanumeric characters with hyphens
    .replace(/[^a-zA-ZÀ-ž0-9]/g, "-")
    // Collapse repeating hyphens
    .replace(/(-+)/g, "-")
    // Drop leading hyphens
    .replace(/^-/, "")
    // Drop trailing hyphens
    .replace(/-$/, "")
    // Lowercase the result
    .toLowerCase();
