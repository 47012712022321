import { css } from "@emotion/react";

export const modalTitle = css`
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 1rem;
  .primary-color {
    color: var(--dpzBlue);
  }
`;

export const modalActions = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 0.5rem;
  padding-top: 1.5rem;
`;

export const modalBackground = css`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const modalContent = css`
  background-color: white;
  opacity: 1;
  z-index: 9;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;
