/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getMarketConfigs } from "ducks/market/selectors";
import { getCurrentServiceMethod } from "ducks/order/selectors";
import {
  getCurrentAddressLine2,
  getCurrentStreetAddress,
} from "ducks/order/selectors";
import {
  getCarryoutWaitTimeReason,
  getDeliveryWaitTimeReason,
  getEstimatedWaitMinutes,
} from "ducks/store/selectors";

import {
  selectVehicleColor,
  selectVehicleMake,
  selectVehicleModel,
} from "rtk_redux/slices/carsideInformationSlice";

import { SERVICE_METHOD } from "constants/order";
import isDelivery from "modules/isDelivery";

import EstimatedWaitTimes from "components/EstimatedWaitTimes";

import { customerHeaderSection } from "./CustomerHeader.styles";

const HeaderServiceMethod = ({ t }) => {
  const currentServiceMethod = useSelector(getCurrentServiceMethod);
  const estimatedWaitMinutes = useSelector(getEstimatedWaitMinutes);
  const deliveryWaitTimeReason = useSelector(getDeliveryWaitTimeReason);
  const carryoutWaitTimeReason = useSelector(getCarryoutWaitTimeReason);
  const marketConfigs = useSelector(getMarketConfigs);

  const vehicleColor = useSelector(selectVehicleColor);
  const vehicleMake = useSelector(selectVehicleMake);
  const vehicleModel = useSelector(selectVehicleModel);

  const streetAddress = useSelector(getCurrentStreetAddress);
  const addressLine2 = useSelector(getCurrentAddressLine2);

  const isDeliveryMethod = isDelivery(currentServiceMethod);
  const { DEFAULT_DELIVERY_WAIT_TIME } = marketConfigs;

  const estimatedWaitTimeByService = isDeliveryMethod
    ? estimatedWaitMinutes?.Delivery
    : estimatedWaitMinutes?.Carryout;

  const vehicleInfo = (
    <p>
      {vehicleColor} {vehicleMake} {vehicleModel}
    </p>
  );
  const deliveryAddress = (
    <address>
      {`${streetAddress}`} <br />
      {`${addressLine2}`}
    </address>
  );

  const serviceMethodHash = {
    [SERVICE_METHOD.DELIVERY]: {
      label: "shared:service_method.delivery",
      info: deliveryAddress,
    },
    [SERVICE_METHOD.DCD]: {
      label: "shared:service_method.dcd",
      info: vehicleInfo,
    },
    [SERVICE_METHOD.PICKUP_WINDOW]: {
      label: "shared:service_method.pickup_window",
    },
  };

  return (
    <div css={customerHeaderSection}>
      <p className="sectionTitle">
        {t(
          serviceMethodHash[currentServiceMethod]?.label ||
            "shared:service_method.carryout"
        )}
      </p>
      <p className="emphasize">
        <EstimatedWaitTimes
          currentEstimatedWaitMinutes={estimatedWaitTimeByService}
          defaultWaitTime={DEFAULT_DELIVERY_WAIT_TIME}
          waitTimeReason={
            isDeliveryMethod ? deliveryWaitTimeReason : carryoutWaitTimeReason
          }
          t={t}
        />
      </p>
      {serviceMethodHash[currentServiceMethod]?.info}
    </div>
  );
};

export default HeaderServiceMethod;

HeaderServiceMethod.propTypes = {
  t: PropTypes.func,
};
