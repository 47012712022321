import { createSelector } from "reselect";

export const getRnc = ({ rnc = {} } = {}) => rnc;

export const getRncCompanyName = createSelector(
  [getRnc],
  ({ rncCompanyName = "" }) => rncCompanyName
);

export const getRncNumber = createSelector(
  [getRnc],
  ({ rncNumber = "" }) => rncNumber
);

export const getRncReceiptType = createSelector(
  [getRnc],
  ({ rncReceiptType = "" }) => rncReceiptType
);

export default getRnc;
