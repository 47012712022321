import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const Backdrop = ({ visible }) => (
  <div className={classNames(visible && "is-sidebar-visible")}>
    <div className="sidebar__drop" />
  </div>
);

Backdrop.propTypes = {
  visible: PropTypes.bool,
};

Backdrop.defaultProps = {
  visible: false,
};

export default Backdrop;
