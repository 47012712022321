import React, { Fragment } from "react";

import PropTypes from "prop-types";

import OrderLocation from "components/Customer/Profile/OrderLocation";

const OrderLocations = ({
  currentServiceMethod,
  heading,
  mappings,
  orderLocations,
  orderLocationsNotFound,
  setOrderLocation,
  t,
}) => (
  <Fragment>
    <header className="title-group">
      <h2 className="title-group__section" data-quid="heading-recent-locations">
        {t(...heading)}
      </h2>
    </header>

    {orderLocations.length > 0 ? (
      <table className="table--no-stripe table--border-bottom">
        <thead>
          <tr>
            {mappings.map(({ key, label }) => (
              <th key={key}>{t(label)}</th>
            ))}
          </tr>
        </thead>

        {orderLocations.map(({ orderId, ...order }) => (
          <OrderLocation
            currentServiceMethod={currentServiceMethod}
            key={orderId}
            mappings={mappings}
            orderId={orderId}
            setOrderLocation={setOrderLocation}
            t={t}
            {...order}
          />
        ))}
      </table>
    ) : (
      <span>{t(...orderLocationsNotFound)}</span>
    )}
  </Fragment>
);

OrderLocations.propTypes = {
  currentServiceMethod: PropTypes.string,
  heading: PropTypes.instanceOf(Array),
  mappings: PropTypes.arrayOf(PropTypes.object),
  orderLocations: PropTypes.arrayOf(PropTypes.object),
  orderLocationsNotFound: PropTypes.instanceOf(Array),
  setOrderLocation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

OrderLocations.defaultProps = {
  currentServiceMethod: "",
  heading: ["customer:profile:recent_locations:recent_locations"],
  orderLocations: [],
  orderLocationsNotFound: ["customer:profile:recent_locations:not_found"],
  mappings: [],
};

export default OrderLocations;
