import { AREAS } from "constants/order";
import RESOURCE_TYPE from "constants/resource";
import { ROBUST_INSPIRED_TOMATO_SAUCE_CODE, WEIGHTS } from "constants/topping";

const NO_ROBUST_INSPIRED_TOMATO_SAUCE = {
  code: ROBUST_INSPIRED_TOMATO_SAUCE_CODE,
  weight: WEIGHTS.ZERO,
};

const filterToppings =
  (codes) =>
  ({ code }) =>
    !codes.includes(code);

export default ({
  sauces,
  baseCode,
  parts,
  itemQuantity,
  sizeCode,
  sauceToppingCode,
  sauceToppingWeight,
  cookingInstructions,
  skipRITS = false,
} = {}) => {
  const sauceCodes = sauces.map((sauce) => sauce.toppingCode);

  // filter all sauces out
  parts[AREAS.WHOLE].options = parts[AREAS.WHOLE].options.filter(
    filterToppings(sauceCodes)
  );

  // push sauce to whole part
  parts[AREAS.WHOLE].options.push({
    code: sauceToppingCode,
    weight: sauceToppingWeight,
  });

  // push robust inspired sauce weight of zero if not robust inspired sauce
  if (!skipRITS && sauceToppingCode !== ROBUST_INSPIRED_TOMATO_SAUCE_CODE) {
    parts[AREAS.WHOLE].options.push(NO_ROBUST_INSPIRED_TOMATO_SAUCE);
  }

  return {
    data: {
      type: RESOURCE_TYPE.PRODUCT,
      attributes: {
        base: baseCode,
        cookingInstructions,
        parts,
        quantity: itemQuantity,
        sizeCode,
      },
    },
  };
};
