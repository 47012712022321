import { GET_MARKETS_ERROR } from "ducks/market";

import {
  clearSessionFromStorage,
  getSessionFromStorage,
  setSessionInStorage,
} from "modules/session";

const SCOPE = "order-entry/session/";

export const SET_SESSION = `${SCOPE}SET_SESSION`;
export const CLEAR_SESSION = `${SCOPE}CLEAR_SESSION`;

export function setSession(session) {
  return {
    type: SET_SESSION,
    session,
  };
}

export function clearSession() {
  return {
    type: CLEAR_SESSION,
    logout: true,
    token: "",
  };
}

export const initialState = {
  logout: false,
  token: getSessionFromStorage(),
};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_SESSION:
      return action.session;
    case CLEAR_SESSION:
      return action;
    default:
      return state;
  }
}

export const checkSessionMiddleware = () => (next) => (action) => {
  if (action.type === SET_SESSION) setSessionInStorage(action.session.token);

  if (action.type === CLEAR_SESSION) clearSessionFromStorage();

  return next(action);
};

export const addTokenMiddleware = () => (next) => (action) => {
  if (action.url) {
    const authHeader = {
      Authorization: `Bearer ${getSessionFromStorage()}`,
    };

    action.headers = action.headers
      ? Object.assign({}, action.headers, authHeader)
      : authHeader;
  }

  return next(action);
};

export const sessionErrorMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const { error = {}, type } = action;
    const { status } = error;

    const unauthorized =
      [401, 403].includes(status) || type === GET_MARKETS_ERROR;

    if (unauthorized) dispatch(clearSession());

    return next(action);
  };
