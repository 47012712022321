/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import getMarketCode from "ducks/market/selectors";
import {
  getCurrentStoreCashLimit,
  getServiceHours,
} from "ducks/store/selectors";

import { selectHasUserSelectedStore } from "rtk_redux/slices/customerPageSlice";

import formatCurrency from "modules/formatCurrency";
import { get12HourTime, getDay } from "modules/futureTime";

import {
  cashLimitAndHoursSection,
  customerHeaderSection,
} from "./CustomerHeader.styles";

import HeaderServiceMethod from "./HeaderServiceMethod";

const date = new Date();

const HeaderStoreInformation = ({ t }) => {
  const hasUserSelectedStore = useSelector(selectHasUserSelectedStore);

  const serviceHours = useSelector(getServiceHours);
  const marketCode = useSelector(getMarketCode);
  const storeCashLimit = useSelector(getCurrentStoreCashLimit);

  const day = getDay(date.getDay());

  const storeHours = (method = "Carryout") =>
    serviceHours &&
    serviceHours[method] &&
    serviceHours[method][day] &&
    serviceHours[method][day]?.map((item) => (
      <dd key={item?.openTime}>{`${get12HourTime(
        item?.openTime
      )}-${get12HourTime(item.closeTime)}`}</dd>
    ));

  if (!hasUserSelectedStore) {
    return null;
  }
  return (
    <>
      <HeaderServiceMethod t={t} />
      <div css={customerHeaderSection}>
        <dl css={cashLimitAndHoursSection}>
          <div>
            <dt>{t("shared:cash_limit")}</dt>
            <dd>{formatCurrency(marketCode, storeCashLimit)}</dd>
          </div>
          <div>
            <dt>{t("shared:service_method.carryout")}</dt>
            <>{storeHours("Carryout")}</>
          </div>
          <div>
            <dt>{t("shared:service_method.delivery")}</dt>
            <>{storeHours("Delivery")}</>
          </div>
          <div>
            <dt>{t("shared:service_method.dcd")}</dt>
            <>{storeHours("DriveUpCarryout")}</>
          </div>
        </dl>
      </div>
    </>
  );
};

export default HeaderStoreInformation;

HeaderStoreInformation.propTypes = {
  t: PropTypes.func,
};
