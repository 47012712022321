/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from "react";

import { DotLoader } from "@dpz/frankensteiner";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { useLazyGetStoreListQuery } from "rtk_redux/api/storeLocatorApi";
import {
  selectStoreQueryLastArgs,
  setStoreQueryLastArgs,
} from "rtk_redux/slices/customerPageSlice";

import { componentWrapper, storeListStyles } from "../NewCustomerPage.styles";

import StoreListItem from "./StoreListItem";
import StoreLocationForm from "./StoreLocationForm";
import StoresMap from "./StoresMap";

const StoreSection = ({ t }) => {
  const dispatch = useDispatch();
  const storesRef = useRef(null);
  const [fetchStoreList, { data, isLoading, isSuccess }, { lastArg }] =
    useLazyGetStoreListQuery();
  const storeQueryLastArgs = useSelector(selectStoreQueryLastArgs);

  // We need to store the store locator query params outside of this component
  // So that they will be saved when the component is unmounted
  useEffect(() => {
    if (lastArg.type) {
      dispatch(setStoreQueryLastArgs(lastArg));
    }
  }, [dispatch, lastArg]);

  // If the component mounts, and there are saved query parameters
  // The call will be refetched (from the cache if it's still there)
  useEffect(() => {
    if (Object.keys(storeQueryLastArgs).length && !data?.Stores) {
      fetchStoreList(storeQueryLastArgs);
    }
  }, [storeQueryLastArgs, fetchStoreList, data]);

  // Assign storesRef to hold a Map for containing store refs
  const getMap = () => {
    if (!storesRef.current) {
      // Initialize the Map on first usage.
      storesRef.current = new Map();
    }
    return storesRef.current;
  };

  // Scroll to bring the ref in view
  const scrollToStore = (storeId) => {
    const map = getMap();
    const node = map.get(storeId);
    node.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  // Create a ref for each store that can be used as an anchor for scrolling
  const storeList = data?.Stores?.map((store) => (
    <StoreListItem
      t={t}
      store={store}
      key={store.StoreID}
      ref={(node) => {
        const map = getMap();
        if (node) {
          map.set(store.StoreID, node);
        } else {
          map.delete(store.StoreID);
        }
      }}
    />
  ));

  const noResults = isSuccess && !data?.Stores?.length;

  const noResultsMessage = (
    <b>
      {data?.query?.line1.trim().length ? (
        <>
          <p>{`${t("customer:locations.store.no_results")}:`}</p>
          <address>
            {data?.query?.line1}
            <br />
            {data?.query?.line2}
          </address>
          <br />
        </>
      ) : (
        <p>{`${t("customer:locations.store.no_results")} "${
          data?.query.line2
        }."`}</p>
      )}
      <p>{`${t("customer:locations.store.please_try_again")}`}</p>
    </b>
  );

  return (
    <div css={componentWrapper}>
      <header className="title-group">
        <h2 className="title-group__section" data-quid="heading-stores">
          {t("customer:locations.store.title")}
        </h2>
      </header>
      <StoreLocationForm fetchStoreList={fetchStoreList} t={t} />
      {isLoading && <DotLoader />}
      {data && data.Stores && (
        <StoresMap scrollToStore={scrollToStore} stores={data.Stores} t={t} />
      )}
      {storeList && !isLoading && (
        <div css={storeListStyles}>
          {noResults ? noResultsMessage : storeList}
        </div>
      )}
    </div>
  );
};

export default StoreSection;

StoreSection.propTypes = {
  t: PropTypes.func.isRequired,
};
