import { css } from "@emotion/react";

export default css`
  @media (max-width: 79.999999999rem) {
    .coupons.body__page {
      .page__section {
        .coupon__group {
          .coupon {
            flex-basis: calc(50% - 3rem);
            max-width: calc(50% - 3rem);
          }
        }
      }
    }

    // FO Adjustments

    .btn-group {
      &.btn-group--unlimited {
        > * {
          flex-basis: 50%;
          max-width: 50%;
        }

        &.toggleable {
          > *:first-child:nth-last-child(3) {
            &.btn,
            & ~ .btn {
              flex-basis: 50%;
              max-width: 50%;
            }
          }
        }

        &:not(.toggleable) {
          > * {
            flex-basis: calc(50% - 0.5rem);
            max-width: calc(50% - 0.5rem);

            &:not(:first-child):not(:last-child),
            & {
              &:nth-child(4n) {
                margin-right: unset;
              }

              &:nth-child(2n) {
                margin-right: 0;
              }
            }
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }
`;
