import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getMarketConfigs } from "ducks/market/selectors";

import Coupon from "components/Coupons/Coupon";

const CouponGroup = ({
  coupons = [],
  createOrderCoupon = () => {},
  isCarplayCustomer = false,
  isFiltered = false,
  marketCode,
  t = () => {},
}) => {
  const { COUPONS_ALPHABETICALLY_SORTED } = useSelector(getMarketConfigs);
  const getDisabledMessage = ({ canApplyToOrder, digitalOnly }) => {
    if (isFiltered && !canApplyToOrder) return t("coupons:invalid");
    if (!isCarplayCustomer && digitalOnly) return t("coupons:digital_only");

    return "";
  };

  const sortByDescription = (
    { couponDescription: a = "" },
    { couponDescription: z = "" }
  ) => a.localeCompare(z);
  const sortByName = ({ couponName: a = "" }, { couponName: z = "" }) =>
    a.localeCompare(z);
  const sortByNameOrDescription = (couponA, couponZ) => {
    if (COUPONS_ALPHABETICALLY_SORTED) {
      if (couponA.couponName === couponZ.couponName)
        return sortByDescription(couponA, couponZ);
      else if (!couponA.couponName || !couponZ.couponName)
        return couponA.couponName ? -1 : 1;
      return sortByName(couponA, couponZ);
    }
  };

  const couponToComponent = ({
    couponCode,
    couponDescription,
    couponName,
    couponPrice,
    canApplyToOrder = false,
    digitalOnly,
  }) => {
    const disabledMessage = getDisabledMessage({
      canApplyToOrder,
      digitalOnly,
    });

    return (
      <Coupon
        code={couponCode}
        createOrderCoupon={createOrderCoupon}
        description={couponDescription}
        isFiltered={isFiltered}
        canApplyToOrder={canApplyToOrder}
        key={couponCode}
        marketCode={marketCode}
        name={couponName}
        price={couponPrice}
        disabledMessage={disabledMessage}
        t={t}
      />
    );
  };

  return (
    <div className="coupon__group" data-quid="coupon-group">
      {coupons.sort(sortByNameOrDescription).map(couponToComponent)}
    </div>
  );
};

CouponGroup.propTypes = {
  coupons: PropTypes.arrayOf(
    PropTypes.shape({
      couponCode: PropTypes.string,
      couponDescription: PropTypes.string,
      couponName: PropTypes.string,
      couponPrice: PropTypes.string,
    })
  ),
  createOrderCoupon: PropTypes.func,
  isCarplayCustomer: PropTypes.bool,
  isFiltered: PropTypes.bool,
  marketCode: PropTypes.string.isRequired,
  t: PropTypes.func,
};

export default CouponGroup;
