import { createSelector } from "reselect";

import exists from "modules/exists";

export const getStreets = ({ streets = {} } = {}) => streets;

export const getStreetsValues = createSelector([getStreets], ({ data = [] }) =>
  data.map((street = {}) => {
    const {
      id = "",
      attributes: { city, neighborhood, cep: postalCode, state } = {},
      relationships: {
        places: {
          links: {
            related: { href: placesURL },
          },
        },
      },
    } = street;
    const description = [id, neighborhood, city, state, postalCode]
      .filter(exists)
      .join(",")
      .trim();

    return {
      city,
      description,
      id,
      neighborhood,
      postalCode,
      placeId: description,
      placesURL,
    };
  })
);
