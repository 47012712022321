import { combineEpics, ofType } from "redux-observable";
import { combineLatest, filter, map } from "rxjs/operators";

import { UPDATE_CUSTOMER_SUCCESS } from "ducks/customer";
import {
  getCustomer,
  getIsMinimumCustomerDataComplete,
} from "ducks/customer/selectors";
import { SET_MARKET } from "ducks/market";
import { getOrderWaterfallUpsells } from "ducks/waterfallUpsell/selectors";

import {
  COUPONS,
  CUSTOMER,
  EVERYTHING_ELSE,
  FINISH,
  FULFILLER,
  PIZZAS,
  couponsRoute,
  customerRoute,
  finishRoute,
  pizzasRoute,
} from "../../../routes";

export const guardRoutesEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(CUSTOMER, PIZZAS, EVERYTHING_ELSE, COUPONS, FINISH, FULFILLER),
    combineLatest(action$.pipe(ofType(SET_MARKET))),
    filter(
      ([
        { type, payload: { section } = {} },
        { marketConfigs: { FORCE_CUSTOMER_REQUIRED_FIELDS = false } = {} },
      ]) => {
        const state = getState();

        if (!FORCE_CUSTOMER_REQUIRED_FIELDS) return false;

        const customer = getCustomer(state);
        const isMinimumCustomerDataComplete =
          getIsMinimumCustomerDataComplete(customer)(state);

        if (type !== CUSTOMER) return !isMinimumCustomerDataComplete;

        const isProfile =
          typeof section === "undefined" || section === "profile";
        return !(isMinimumCustomerDataComplete || isProfile);
      }
    ),
    map(() => customerRoute("profile"))
  );

export const guardProfileChangeEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(UPDATE_CUSTOMER_SUCCESS),
    combineLatest(action$.pipe(ofType(SET_MARKET))),
    filter(
      ([
        ,
        { marketConfigs: { FORCE_CUSTOMER_REQUIRED_FIELDS = false } = {} },
      ]) => {
        const state = getState();

        if (!FORCE_CUSTOMER_REQUIRED_FIELDS) return false;
        const customer = getCustomer(state);
        const isMinimumCustomerDataComplete =
          getIsMinimumCustomerDataComplete(customer)(state);

        const {
          type: routeType,
          payload: { section = "" },
        } = state.location || {};
        const guardedRoutes = [
          CUSTOMER,
          PIZZAS,
          EVERYTHING_ELSE,
          COUPONS,
          FINISH,
          FULFILLER,
        ];

        if (!guardedRoutes.includes(routeType)) return false;
        if (routeType !== CUSTOMER) return !isMinimumCustomerDataComplete;
        const isProfile =
          typeof section === "undefined" || section === "profile";
        return !(isMinimumCustomerDataComplete || isProfile);
      }
    ),
    map(() => customerRoute("profile"))
  );

export const routeToWaterfallUpsellEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(FINISH),
    filter(({ payload: { section } = {} }) => {
      const state = getState();
      const waterfallUpsellItems = getOrderWaterfallUpsells(state);

      return waterfallUpsellItems.length && !section;
    }),
    map(() => finishRoute("waterfall-upsell"))
  );

export const redirectToDefaultPizza = (action$) =>
  action$.pipe(
    ofType(PIZZAS),
    combineLatest(action$.pipe(ofType(SET_MARKET))),
    filter(
      ([
        { payload: { category } = {} },
        { marketConfigs: { DEFAULT_PIZZA_CATEGORY = "" } = {} },
      ]) => {
        return DEFAULT_PIZZA_CATEGORY && !category;
      }
    ),
    map(([, { marketConfigs: { DEFAULT_PIZZA_CATEGORY = "" } = {} }]) =>
      pizzasRoute(DEFAULT_PIZZA_CATEGORY)
    )
  );

export const redirectToDefaultCoupon = (action$) =>
  action$.pipe(
    ofType(COUPONS),
    combineLatest(action$.pipe(ofType(SET_MARKET))),
    filter(
      ([
        { payload: { category } = {} },
        { marketConfigs: { DEFAULT_COUPON_CATEGORY = "" } = {} },
      ]) => {
        return DEFAULT_COUPON_CATEGORY && !category;
      }
    ),
    map(([, { marketConfigs: { DEFAULT_COUPON_CATEGORY = "" } = {} }]) =>
      couponsRoute(DEFAULT_COUPON_CATEGORY)
    )
  );

export const epics = combineEpics(
  guardRoutesEpic,
  guardProfileChangeEpic,
  routeToWaterfallUpsellEpic,
  redirectToDefaultPizza,
  redirectToDefaultCoupon
);
