/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import kebabCase from "modules/kebabCase";
import { getDimensionClasses } from "modules/optionHelpers";

import Tooltip from "components/Tooltip";

import { disabledStyles } from "styles/shared.styles";

const Base = ({
  activeOrderProductBaseId = "",
  baseDescription,
  baseId,
  baseName,
  disabled = false,
  productId = "",
  setOrderProduct,
  swapProductsForBases = false,
  tooltipMessage = "",
}) => {
  const handleBaseClick = () => {
    setOrderProduct({ baseId, ...(swapProductsForBases && { productId }) });
  };

  return disabled && tooltipMessage ? (
    <Tooltip title={tooltipMessage}>
      <button
        className={getDimensionClasses(baseId, activeOrderProductBaseId)}
        css={disabledStyles}
        data-quid={`base-${kebabCase(baseName)}`}
        key={baseId}
        onClick={disabled ? () => {} : handleBaseClick}
        type="button"
      >
        {baseName}
      </button>
    </Tooltip>
  ) : (
    <button
      className={getDimensionClasses(baseId, activeOrderProductBaseId)}
      data-quid={`base-${kebabCase(baseName)}`}
      key={baseId}
      onClick={handleBaseClick}
      title={baseDescription}
      disabled={disabled}
      type="button"
    >
      {baseName}
    </button>
  );
};

Base.propTypes = {
  activeOrderProductBaseId: PropTypes.string,
  baseDescription: PropTypes.string,
  baseId: PropTypes.string.isRequired,
  baseName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  productId: PropTypes.string,
  setOrderProduct: PropTypes.func.isRequired,
  swapProductsForBases: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

export default Base;
