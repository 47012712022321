import React, { Fragment } from "react";

import OrderHistory from "./OrderHistory";
import OrderStatuses from "./OrderStatuses";

const Orders = (props) => (
  <Fragment>
    <OrderStatuses {...props} />

    <OrderHistory {...props} />
  </Fragment>
);

export default Orders;
