import { translate } from "react-i18next";
import { connect } from "react-redux";

import { WATERFALL_UPSELL_SECTION, finishRoute } from "routes";

import { updateAddress } from "ducks/address";
import { updateCustomer } from "ducks/customer";
import {
  getCustomer,
  getCustomerEmail,
  getCustomerTaxCodes,
  getIsMinimumCustomerDataComplete,
} from "ducks/customer/selectors";
import { getLoading } from "ducks/loading/selectors";
import {
  getMarketCode,
  getMarketConfigs,
  getMarketProfileFields,
} from "ducks/market/selectors";
import { placeOrder } from "ducks/order";
import {
  getCurrentDeliveryInstructions,
  getCurrentOrder,
  getCurrentOrderAddress,
  getCurrentOrderDeliveryFee,
  getCurrentOrderFoodAndBeverage,
  getCurrentOrderFutureTime,
  getCurrentOrderId,
  getCurrentOrderSavings,
  getCurrentOrderTax,
  getCurrentOrderTiming,
  getCurrentOrderTotal,
  getCurrentServiceMethod,
  getOrderStarted,
} from "ducks/order/selectors";
import {
  rncLookupNumber,
  rncSetCompanyName,
  rncSetNumber,
  rncSetReceiptType,
} from "ducks/rnc";
import {
  getRncCompanyName,
  getRncNumber,
  getRncReceiptType,
} from "ducks/rnc/selectors";
import {
  getAcceptableCreditCards,
  getAcceptablePaymentTypes,
  getCarryoutWaitTimeReason,
  getCurrentStoreSummary,
  getDeliveryWaitTimeReason,
  getEstimatedWaitMinutes,
} from "ducks/store/selectors";
import {
  applyWaterfallUpsell,
  setWaterfallUpsellSeen,
} from "ducks/waterfallUpsell";
import { getOrderWaterfallUpsells } from "ducks/waterfallUpsell/selectors";
import { getPayloadSection } from "selectors/location";
import { getLinkFromRelationship } from "selectors/related";

import Finish from "components/Finish";

const mapStateToProps = (state) => ({
  acceptableCreditCards: getAcceptableCreditCards(state),
  acceptablePaymentTypes: getAcceptablePaymentTypes(state),
  address: getCurrentOrderAddress(state),
  carryoutWaitTimeReason: getCarryoutWaitTimeReason(state),
  currentOrder: getCurrentOrder(state),
  currentOrderDeliveryFee: getCurrentOrderDeliveryFee(state),
  currentOrderFoodAndBeverage: getCurrentOrderFoodAndBeverage(state),
  currentOrderFutureTime: getCurrentOrderFutureTime(state),
  currentOrderId: getCurrentOrderId(state),
  currentOrderSavings: getCurrentOrderSavings(state),
  currentOrderTax: getCurrentOrderTax(state),
  currentOrderTiming: getCurrentOrderTiming(state),
  currentOrderTotal: getCurrentOrderTotal(state),
  currentSectionName: getPayloadSection(state),
  currentServiceMethod: getCurrentServiceMethod(state),
  currentStoreSummary: getCurrentStoreSummary(state),
  customer: getCustomer(state),
  customerEmail: getCustomerEmail(state),
  customerTaxCodes: getCustomerTaxCodes(state),
  currentDeliveryInstructions: getCurrentDeliveryInstructions(state),
  deliveryWaitTimeReason: getDeliveryWaitTimeReason(state),
  estimatedWaitMinutes: getEstimatedWaitMinutes(state),
  isMinimumCustomerDataComplete: getIsMinimumCustomerDataComplete(
    getCustomer(state)
  )(state),
  loading: getLoading(state),
  marketCode: getMarketCode(state),
  marketConfigs: getMarketConfigs(state),
  orderStarted: getOrderStarted(state),
  profileFields: getMarketProfileFields(state),
  rncCompanyName: getRncCompanyName(state),
  rncNumber: getRncNumber(state),
  rncReceiptType: getRncReceiptType(state),
  waterfallUpsells: getOrderWaterfallUpsells(state),
});

const mapDispatchToProps = (dispatch) => ({
  applyWaterfallUpsell: (upsell) => dispatch(applyWaterfallUpsell(upsell)),
  goToFinish: (section) => dispatch(finishRoute(section)),
  placeOrder: (order) => dispatch(placeOrder(order)),
  rncLookupNumber: (rncNumber) => dispatch(rncLookupNumber(rncNumber)),
  rncSetCompanyName: (rncCompanyName) =>
    dispatch(rncSetCompanyName(rncCompanyName)),
  rncSetNumber: (rncNumber) => dispatch(rncSetNumber(rncNumber)),
  rncSetReceiptType: (rncReceiptType) =>
    dispatch(rncSetReceiptType(rncReceiptType)),
  setWaterfallUpsellSeen: (seen) => dispatch(setWaterfallUpsellSeen({ seen })),
  updateAddress: (address) => dispatch(updateAddress(address)),
  updateCustomer: (customer) => dispatch(updateCustomer(customer)),
});

const mergeProps = (
  {
    address,
    currentOrder,
    currentOrderId,
    customer,
    currentSectionName,
    ...state
  },
  {
    placeOrder: dispatchPlaceOrder,
    updateAddress: dispatchUpdateAddress,
    updateCustomer: dispatchUpdateCustomer,
    ...dispatch
  },
  props
) => {
  const orderCustomerLink = getLinkFromRelationship("customer")(currentOrder);
  const placeOrderLink = getLinkFromRelationship("placeOrder")(currentOrder);
  const updateAddressLink = getLinkFromRelationship("address")(currentOrder);
  const productsLink = getLinkFromRelationship("products")(currentOrder);
  return Object.assign(
    {
      currentOrderId,
      productsLink,
      isWaterfallUpsell: currentSectionName === WATERFALL_UPSELL_SECTION,
      placeOrder: ({ orderInfoCollection, paymentInfo }) =>
        dispatchPlaceOrder({
          orderInfoCollection,
          paymentInfo,
          url: placeOrderLink,
        }),
      updateAddress: ({ deliveryInstructions }) =>
        dispatchUpdateAddress({
          ...address,
          deliveryInstructions,
          url: updateAddressLink,
        }),
      updateCustomerEmail: (email) =>
        dispatchUpdateCustomer({
          ...customer,
          email,
          url: orderCustomerLink,
        }),
    },
    state,
    dispatch,
    props
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["finish", "shared"])(Finish));
