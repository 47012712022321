import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/root/";

export const GET_ROOT = `${SCOPE}GET_ROOT`;
export const GET_ROOT_ERROR = `${SCOPE}GET_ROOT_ERROR`;
export const SET_ROOT = `${SCOPE}SET_ROOT`;

export function getRoot() {
  const { serviceRoot, serviceName } = window.ENVCONFIG;

  return {
    type: GET_ROOT,
    url: `${serviceRoot}${serviceName}`,
  };
}

export function getRootError(error) {
  return {
    type: GET_ROOT_ERROR,
    error,
  };
}

export function setRoot({ links = {}, relationships = {} } = {}) {
  return {
    type: SET_ROOT,
    links,
    relationships,
  };
}

export const initialState = {
  links: {},
  relationships: {},
};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_ROOT:
      return Object.assign({}, state, action);
    default:
      return state;
  }
}

export const getRootEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_ROOT),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "data"),
        map(setRoot),
        catchError((error) => of(getRootError(error)))
      )
    )
  );

export const epics = combineEpics(getRootEpic);
