import { baseApi } from "./baseApi";

export const storeLocatorApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStoreList: builder.query({
      query: (params) => ({
        url: `/power/store-locator`,
        params: params,
      }),
      tagTypes: ["storeLocator"],
      transformResponse: (response, _meta, args) => {
        return {
          Stores: response.Stores,
          serviceType: args.type,
          query: { line1: args.s, line2: args.c },
        };
      },
    }),
  }),
});

export const { useStoreListQuery, useLazyGetStoreListQuery } = storeLocatorApi;
