import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

import { resetState } from "ducks/configuration";
import { getCustomerName } from "ducks/customer/selectors";
import {
  getIsSuppressCoordinateDeliveryInstructionsEnabled,
  getMarketConfigs,
  getNewExperienceFeature,
} from "ducks/market/selectors";
import { setMessage } from "ducks/message";
import {
  getCurrentOrder,
  getCurrentOrderFutureTime,
  getCurrentServiceMethod,
  getCurrentStreetAddress,
  getIsFutureOrder,
  getOrderAddress,
} from "ducks/order/selectors";
import { getReceiptQuantity } from "ducks/orderProduct/selectors";
import {
  getCurrentStoreStreet,
  getEstimatedWaitMinutes,
} from "ducks/store/selectors";

import { sendTealiumPlaceOrderSuccess } from "rtk_redux/actions/tealium";
import { selectIsCarplayCustomer } from "rtk_redux/slices/customerPageSlice";

import { ERROR_PREFIX, MESSAGE, MESSAGE_TYPE } from "constants/message";
import { ORDER_META } from "constants/order";
import withValidation from "modules/elValidadore";
import { buildErrorFromStatusItems } from "modules/errorMessageHelpers";
import getIsDelivery from "modules/isDelivery";
import { urlValidators } from "modules/validators";

const SCOPE = "order-entry/order/";

export const PLACE_ORDER = `${SCOPE}PLACE_ORDER`;
export const PLACE_ORDER_SUCCESS = `${SCOPE}PLACE_ORDER_SUCCESS`;
export const PLACE_ORDER_ERROR = `${SCOPE}PLACE_ORDER_ERROR`;

export const placeOrder = withValidation(
  ({ orderInfoCollection = [], paymentInfo = {}, url = "" } = {}) => ({
    type: PLACE_ORDER,
    orderInfoCollection,
    paymentInfo,
    url,
  }),
  { url: urlValidators }
);

export function placeOrderError(error) {
  return {
    type: PLACE_ORDER_ERROR,
    error,
  };
}

export function placeOrderSuccess({
  storeOrderId = "",
  ecommOrderId = "",
} = {}) {
  return {
    type: PLACE_ORDER_SUCCESS,
    storeOrderId,
    ecommOrderId,
  };
}

export const placeOrderEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(PLACE_ORDER),
    mergeMap(({ orderInfoCollection, paymentInfo, ...action }) => {
      const state = getState();
      const isSuppressCoordinateDeliveryInstructionsEnabled =
        getIsSuppressCoordinateDeliveryInstructionsEnabled(state);
      const { orderId, serviceMethod } = getCurrentOrder(state);
      const isDelivery = getIsDelivery(serviceMethod);
      const address = getOrderAddress(orderId)(state);
      const { SOURCE_ORG_URI } = getMarketConfigs(state);
      const suppressCoordinateDeliveryInstructions = Boolean(
        isSuppressCoordinateDeliveryInstructionsEnabled &&
          isDelivery &&
          address.city
      );
      return fetch(
        Object.assign(action, {
          body: {
            data: {
              attributes: Object.assign(
                {},
                { orderInfoCollection },
                { payments: [paymentInfo] },
                ORDER_META,
                { sourceOrgUri: SOURCE_ORG_URI },
                suppressCoordinateDeliveryInstructions
                  ? { suppressCoordinateDeliveryInstructions }
                  : {}
              ),
            },
          },
        }),
        {
          method: "PUT",
        }
      ).pipe(
        pluck("response", "data", "attributes"),
        map(({ status, statusItems, storeOrderId, ecommOrderId }) =>
          //TODO: turn into a helper or something
          status >= 0
            ? placeOrderSuccess({ storeOrderId, ecommOrderId })
            : placeOrderError(
                buildErrorFromStatusItems({
                  errorPrefix: ERROR_PREFIX.PLACE_ORDER,
                  statusItems,
                })
              )
        ),
        catchError((error) => of(placeOrderError({ error })))
      );
    })
  );

export const placeOrderSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(PLACE_ORDER_SUCCESS),
    mergeMap(({ storeOrderId, ecommOrderId }) => {
      const state = getState();
      const { showExpandedOrderConfirmationMessage } =
        getNewExperienceFeature(state);

      const customerName = getCustomerName(state);
      const customerAddress = getCurrentStreetAddress(state);
      const storeAddress = getCurrentStoreStreet(state);
      const isFutureOrder = getIsFutureOrder(state);
      const futureOrderTime = getCurrentOrderFutureTime(state);

      let waitTime = getEstimatedWaitMinutes(state);
      if (Object.keys(waitTime).length === 0) {
        waitTime = {
          Carryout: { Min: 0, Max: 0 },
          Delivery: { Min: 0, Max: 0 },
        };
      }

      const receiptQuantity = getReceiptQuantity(state);

      const currentServiceMethod = getCurrentServiceMethod(state);
      const isCarplay = selectIsCarplayCustomer(state);

      return [
        resetState(),
        sendTealiumPlaceOrderSuccess({
          ecommOrderId,
        }),
        setMessage({
          interpolation: { storeOrderId },
          message: MESSAGE.ORDER_PLACED,
          messageType: MESSAGE_TYPE.OVERLAY,
          showExpandedOrderConfirmationMessage,
          expandedOrderConfirmationMessageData: {
            currentServiceMethod,
            customerAddress,
            customerName,
            futureOrderTime,
            isCarplay,
            isFutureOrder,
            receiptQuantity,
            storeAddress,
            storeOrderId,
            waitTime,
          },
          title: "shared:messages.order_confirmation",
        }),
      ];
    })
  );
