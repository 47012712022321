import { css } from "@emotion/react";

export default css`
  .pizzas.body__page .page__section .section__content .content__pane {
    &.content__pane--split .pane__content {
      padding-bottom: unset;
    }

    .btn-group:not(.btn-group--nested) {
      > *:not(.btn-group--nested) {
        padding: 1rem;
      }
    }

    .subsection {
      padding-left: 0;
      padding-top: 0;
    }

    .columns {
      display: flex;

      .subsection {
        flex: 1;
        min-width: unset;

        &:not(:first-of-type) {
          padding-left: 1rem;
        }
      }

      @media screen and (max-width: 70em) {
        & {
          flex-direction: column;

          .subsection:not(:first-of-type) {
            padding-left: 0;
            padding-top: 1rem;
          }
        }
      }
    }
  }
`;
