import React, { Component } from "react";

import PropTypes from "prop-types";

import marketConfigs from "ducks/market/marketSpecificConfig";

import { ADDRESS_FIELDS } from "constants/address";
import {
  DEFAULT_LOCATOR_STRATEGY,
  SECONDARY_LOCATOR_STRATEGY,
} from "constants/locatorStrategy";
import exists from "modules/exists";
import isDelivery from "modules/isDelivery";
import mapAddressComponentsToQuery from "modules/mapAddressComponentsToQuery";

import DO from "components/StoreLocator/Market/DO";
import KE from "components/StoreLocator/Market/KE";
import MY from "components/StoreLocator/Market/MY";
import NG from "components/StoreLocator/Market/NG";
import SA from "components/StoreLocator/Market/SA";
import ZA from "components/StoreLocator/Market/ZA";

class StoreLocator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locatorStrategy: DEFAULT_LOCATOR_STRATEGY,
      query: props.query,
      usesPlaces: false,
    };

    this.getDetailsCallback = this.getDetailsCallback.bind(this);
    this.setUsesPlaces = this.setUsesPlaces.bind(this);
    this.cleanQuery = this.cleanQuery.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setRegionAsCity = this.setRegionAsCity.bind(this);
    this.setLocatorStrategy = this.setLocatorStrategy.bind(this);
  }

  getDetailsCallback(place, status) {
    if (status === this.placesOK) {
      const {
        address_components: addressComponents,
        geometry: { location },
      } = Array.isArray(place) ? place[0] : place;

      let query = mapAddressComponentsToQuery(
        addressComponents,
        this.state.query
      );

      const { countryCode } = this.props;
      query.latitude = location.lat();
      query.longitude = location.lng();

      const { mutateLocatorQuery } = marketConfigs(countryCode);
      if (mutateLocatorQuery) query = mutateLocatorQuery(query);

      query = this.cleanQuery({ query });

      this.props.locateStores({
        query,
        serviceMethod: this.props.serviceMethod,
      });
    }
  }

  setUsesPlaces(usesPlaces) {
    this.setState({
      usesPlaces,
    });
  }

  setLocatorStrategy(locatorStrategy) {
    this.setState({
      usesPlaces: locatorStrategy === DEFAULT_LOCATOR_STRATEGY,
      locatorStrategy,
      query: {
        ...this.state.query,
        address: "",
        postalCode: "",
        regionCode: "",
        streetName: "",
        neighborhood: "",
        organizationName: "",
      },
    });
  }

  setRegionAsCity(cityCode, neighborhood) {
    this.setState({
      ...this.state,
      query: {
        ...this.state.query,
        regionCode: cityCode,
        neighborhood,
      },
    });
  }

  cleanQuery({ query = {} } = {}) {
    query = Object.assign({}, this.state.query, query);
    query.regionCode = query.regionCode || this.props.countryCode;

    if (this.props.countryCode === "MY") {
      const { addressType } = query;

      // Merge block level unit
      const unitNumber = ["block", "level", "unit"]
        .map((item) => query[item])
        .filter(exists)
        .join("/");

      query.unitNumber = unitNumber || query.unitNumber;

      // updates street number
      if (query.unitInfo) query.streetNumber = query.unitInfo;
      if (addressType === "flat") query.streetNumber = "0";
    }

    return query;
  }

  handleChange(updates, { props } = {}) {
    let query = !updates.addressType
      ? {}
      : // If we're changing addressType, reset all address fields
        Object.assign(
          {},
          ...Object.keys(ADDRESS_FIELDS).map((field) => ({
            [field]: "",
          })),
          updates
        );

    Object.entries(updates).forEach(([name, value]) => {
      query[name] = value;
    });

    query = this.cleanQuery({ query });

    if (props) {
      props.updateSource(query);
    }

    this.setState({
      query,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const query = this.cleanQuery();

    const { isFilterDeliveryOnlyEnabled, locateStores, maps, serviceMethod } =
      this.props;

    if (this.state.usesPlaces) {
      this.placesService = new maps.places.PlacesService(
        document.createElement("div")
      );

      this.placesOK = maps.places.PlacesServiceStatus.OK;

      const { placeId } = query;

      this.placesService.getDetails({ placeId }, this.getDetailsCallback);
    } else {
      locateStores({
        query,
        serviceMethod,
        isFilterDeliveryOnlyEnabled,
      });
    }
  }

  render() {
    const {
      cities,
      countryCode,
      getCities,
      getNeighborhoods,
      getRegions,
      getPlaces,
      getStates,
      getStreetCategories,
      getStreets,
      maps,
      marketConfigs,
      neighborhoods,
      regions,
      places,
      serviceMethod,
      t,
      states,
      streets,
      streetCategories,
    } = this.props;

    const {
      locatorStrategy,
      query: {
        address,
        addressNickname,
        addressType,
        block,
        buildingName,
        city,
        deliveryInstructions,
        latitude,
        level,
        longitude,
        locationName,
        neighborhood,
        organizationName,
        postalCode,
        regionCode,
        streetName,
        streetNumber,
        unit,
        unitInfo,
        unitNumber,
        placeId,
      },
    } = this.state;

    let market;
    switch (countryCode) {
      case "DO":
        market = (
          <DO
            address={address}
            addressNickname={addressNickname}
            addressType={addressType}
            city={city}
            handleChange={this.handleChange}
            latitude={latitude}
            longitude={longitude}
            maps={maps}
            neighborhood={neighborhood}
            organizationName={organizationName}
            serviceMethod={serviceMethod}
            setUsesPlaces={this.setUsesPlaces}
            streetName={streetName}
            streetNumber={streetNumber}
            t={t}
            unitNumber={unitNumber}
          />
        );
        break;
      case "KE":
        market = (
          <KE
            address={address}
            addressType={addressType}
            addressNickname={addressNickname}
            block={block}
            buildingName={buildingName}
            city={city}
            getRegions={getRegions}
            regions={regions}
            deliveryInstructions={deliveryInstructions}
            getPlaces={getPlaces}
            getStates={getStates}
            getStreets={getStreets}
            handleChange={this.handleChange}
            level={level}
            locationName={locationName}
            maps={maps}
            marketConfigs={marketConfigs}
            countryCode={countryCode}
            neighborhood={neighborhood}
            organizationName={organizationName}
            places={places}
            postalCode={postalCode}
            regionCode={regionCode}
            serviceMethod={serviceMethod}
            setUsesPlaces={this.setUsesPlaces}
            t={t}
            unit={unit}
            unitInfo={unitInfo}
            unitNumber={unitNumber}
            locatorStrategy={locatorStrategy}
            setLocatorStrategy={this.setLocatorStrategy}
            setRegionAsCity={this.setRegionAsCity}
            states={states}
            streetCategories={streetCategories}
            streetName={streetName}
            streets={streets}
            streetNumber={streetNumber}
          />
        );
        break;
      case "MY":
        market = (
          <MY
            address={address}
            addressType={addressType}
            addressNickname={addressNickname}
            block={block}
            buildingName={buildingName}
            deliveryInstructions={deliveryInstructions}
            getPlaces={getPlaces}
            getStates={getStates}
            getStreetCategories={getStreetCategories}
            getStreets={getStreets}
            handleChange={this.handleChange}
            level={level}
            maps={maps}
            marketConfigs={marketConfigs}
            countryCode={countryCode}
            neighborhood={neighborhood}
            organizationName={organizationName}
            places={places}
            postalCode={postalCode}
            regionCode={regionCode}
            serviceMethod={serviceMethod}
            setUsesPlaces={this.setUsesPlaces}
            t={t}
            unit={unit}
            unitInfo={unitInfo}
            unitNumber={unitNumber}
            locatorStrategy={locatorStrategy}
            setLocatorStrategy={this.setLocatorStrategy}
            states={states}
            streetCategories={streetCategories}
            streetName={streetName}
            streets={streets}
          />
        );
        break;
      case "NG":
        market = (
          <NG
            address={address}
            addressNickname={addressNickname}
            addressType={addressType}
            city={city}
            getCities={getCities}
            getPlaces={getPlaces}
            getStreets={getStreets}
            handleChange={this.handleChange}
            latitude={latitude}
            longitude={longitude}
            maps={maps}
            neighborhood={neighborhood}
            organizationName={organizationName}
            serviceMethod={serviceMethod}
            setUsesPlaces={this.setUsesPlaces}
            streetName={streetName}
            streets={streets}
            streetNumber={streetNumber}
            t={t}
            unitNumber={unitNumber}
          />
        );
        break;
      case "SA":
        market = (
          <SA
            address={address}
            addressType={addressType}
            addressNickname={addressNickname}
            block={block}
            buildingName={buildingName}
            city={city}
            cities={cities}
            deliveryInstructions={deliveryInstructions}
            getCities={getCities}
            getNeighborhoods={getNeighborhoods}
            getStreetCategories={getStreetCategories}
            getStreets={getStreets}
            handleChange={this.handleChange}
            level={level}
            maps={maps}
            marketConfigs={marketConfigs}
            countryCode={countryCode}
            neighborhood={neighborhood}
            neighborhoods={neighborhoods}
            organizationName={organizationName}
            places={places}
            placeId={placeId}
            postalCode={postalCode}
            regionCode={regionCode}
            serviceMethod={serviceMethod}
            setUsesPlaces={this.setUsesPlaces}
            t={t}
            unit={unit}
            unitInfo={unitInfo}
            unitNumber={unitNumber}
            locatorStrategy={locatorStrategy}
            setLocatorStrategy={this.setLocatorStrategy}
            states={states}
            streetCategories={streetCategories}
            streetName={streetName}
            streets={streets}
            latitude={latitude}
            longitude={longitude}
          />
        );
        break;
      case "ZA":
        market = (
          <ZA
            address={address}
            addressType={addressType}
            cities={cities}
            city={city}
            getCities={getCities}
            getRegions={getRegions}
            handleChange={this.handleChange}
            maps={maps}
            organizationName={organizationName}
            regionCode={regionCode}
            regions={regions}
            serviceMethod={serviceMethod}
            setUsesPlaces={this.setUsesPlaces}
            t={t}
            unitNumber={unitNumber}
          />
        );
        break;
      default:
        market = <p>No market specified</p>;
        break;
    }

    const { HAS_MAP_LOCATOR_WITH_STDF_FALLBACK } = marketConfigs;

    return (
      <form onSubmit={this.handleSubmit}>
        {market}
        <div className="text--center">
          <button
            className="btn btn--primary"
            data-icon={isDelivery(serviceMethod) ? "home" : "store"}
            data-quid="store-locator-submit"
            type="submit"
          >
            {t(
              isDelivery(serviceMethod)
                ? "customer:locations.use_address"
                : "customer:locations.find_stores"
            )}
          </button>
          {isDelivery(serviceMethod) &&
            HAS_MAP_LOCATOR_WITH_STDF_FALLBACK &&
            locatorStrategy === DEFAULT_LOCATOR_STRATEGY && (
              <button
                className="btn btn--link margin__left--1_25rem"
                data-quid="store-locator-switch-strategy"
                onClick={() =>
                  this.setLocatorStrategy(SECONDARY_LOCATOR_STRATEGY)
                }
                type="button"
              >
                {t("customer:locations.use_alternative_strategy")}
              </button>
            )}
        </div>
      </form>
    );
  }
}

StoreLocator.propTypes = {
  cities: PropTypes.objectOf(PropTypes.string),
  countryCode: PropTypes.string.isRequired,
  getCities: PropTypes.func,
  getPlaces: PropTypes.func.isRequired,
  getRegions: PropTypes.func,
  getNeighborhoods: PropTypes.func,
  getStates: PropTypes.func.isRequired,
  getStreetCategories: PropTypes.func.isRequired,
  getStreets: PropTypes.func.isRequired,
  isFilterDeliveryOnlyEnabled: PropTypes.bool,
  locateStores: PropTypes.func.isRequired,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  marketConfigs: PropTypes.shape({
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: PropTypes.number,
    HAS_MAP_LOCATOR_WITH_STDF_FALLBACK: PropTypes.bool,
  }).isRequired,
  neighborhoods: PropTypes.objectOf(PropTypes.string),
  places: PropTypes.arrayOf(PropTypes.string).isRequired,
  query: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ).isRequired,
  regions: PropTypes.objectOf(PropTypes.string),
  serviceMethod: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
    .isRequired,
  streetCategories: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, option: PropTypes.string })
  ).isRequired,
  streets: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      placeId: PropTypes.string,
    })
  ).isRequired,
};

StoreLocator.defaultProps = {
  cities: {},
  getNeighborhoods: () => {},
  getCities: () => {},
  getRegions: () => {},
  getStates: () => {},
  getStreetCategories: () => {},
  isFilterDeliveryOnlyEnabled: true,
  neighborhoods: {},
  regions: {},
};

export default StoreLocator;
