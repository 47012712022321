//TODO: write tests
import React from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import AppContainer from "containers/App";
import AuthContainer from "containers/Auth";
import ConfigurationContainer from "containers/Configuration";

import "index.css";

import { store } from "./redux";
import registerServiceWorker from "./registerServiceWorker";

const Configure = ConfigurationContainer(AppContainer);
const Authenticate = AuthContainer(Configure);

ReactDOM.render(
  <Provider store={store}>
    <Authenticate />
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
