/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getCurrentOrder } from "ducks/order/selectors";
import { createOrderProduct } from "ducks/orderProduct";
import getLinkFromRelationship from "selectors/related";

import {
  STJUDE_CATEGORY_ID,
  STJUDE_PRODUCT_CODE,
  STJUDE_ROUND_UP_SIZE_CODE,
} from "constants/menu";

import StJudeBaby from "assets/images/round-up.jpg";

import * as styles from "./StJudeRoundUp.styles";

const StJudeRoundUp = ({ t = () => {} }) => {
  const dispatch = useDispatch();
  const currentOrder = useSelector(getCurrentOrder);
  const { orderId } = currentOrder;
  const productsLink = getLinkFromRelationship("products")(currentOrder);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      createOrderProduct({
        orderId,
        categoryId: STJUDE_CATEGORY_ID,
        productId: STJUDE_PRODUCT_CODE,
        sizeId: STJUDE_ROUND_UP_SIZE_CODE,
        skipSetActiveOrderProduct: true,
        priceOrder: true,
        url: productsLink,
      })
    );
  };

  return (
    <div css={styles.container}>
      <h3 css={styles.title}>{t("stjude:be_the_hero")}</h3>
      <div css={styles.copyWrapper}>
        <img css={styles.img} src={StJudeBaby} alt={t("stjude:round_up")} />
        <p>{t("stjude:would_you_like_to_donate")}</p>
      </div>
      <button
        className="btn btn--primary"
        type="button"
        css={styles.button}
        onClick={handleClick}
      >
        {t("stjude:yes_round_up_today")}
      </button>
    </div>
  );
};

StJudeRoundUp.propTypes = {
  t: PropTypes.func,
};

export default StJudeRoundUp;
