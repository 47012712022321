import { translate } from "react-i18next";
import { connect } from "react-redux";

import { couponsRoute } from "routes";

import {
  getManualCouponEntryTab,
  getMarketCode,
  getNewExperienceFeature,
} from "ducks/market/selectors";
import { getCouponCategories } from "ducks/menu/selectors";
import { getCurrentOrder, getCurrentOrderId } from "ducks/order/selectors";
import { createOrderCoupon } from "ducks/orderCoupon";
import { getCurrentStoreUTCOffset } from "ducks/store/selectors";
import { getPayloadCategory } from "selectors/location";
import { getLinkFromRelationship } from "selectors/related";

import { DEFAULT_COUPON_CATEGORY_ID, NEW_TAB_LIST } from "constants/coupon";
import filterValidCoupons from "modules/filterValidCoupons";
import kebabCase from "modules/kebabCase";

import Coupons from "components/Coupons";

const mapStateToProps = (state) => ({
  categories: getCouponCategories(state),
  currentCategoryName: getPayloadCategory(state),
  currentOrder: getCurrentOrder(state),
  currentOrderId: getCurrentOrderId(state),
  manualCouponEntryTab: getManualCouponEntryTab(state),
  marketCode: getMarketCode(state),
  newExperienceFeatures: getNewExperienceFeature(state),
  storeUTCOffset: getCurrentStoreUTCOffset(state),
});

const mapDispatchToProps = (dispatch) => ({
  createOrderCoupon: (coupon) => dispatch(createOrderCoupon(coupon)),
  goToCoupons: (category) => dispatch(couponsRoute(category)),
});

const sortByDefaultCouponCategory =
  ({ dontSort }) =>
  ({ id: a }, { id: b }) => {
    if (dontSort) return 0;

    if (a === DEFAULT_COUPON_CATEGORY_ID) return 0;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

const mergeProps = (
  {
    categories,
    currentCategoryName,
    currentOrder,
    currentOrderId,
    manualCouponEntryTab,
    newExperienceFeatures,
    storeUTCOffset,
    ...state
  },
  { createOrderCoupon: dispatchCreateCoupon, ...dispatch },
  props
) => {
  const { newCouponPage: showNewCouponPage } = newExperienceFeatures;
  //TODO: write generic tab builder func
  const tabs = Object.values(showNewCouponPage ? NEW_TAB_LIST : categories)
    .map(({ categoryCode, categoryId, categoryName }) => ({
      id: categoryId,
      path: categoryCode.toLowerCase(),
      quidBase: `coupons-${kebabCase(categoryName)}`,
      text: categoryName,
      translate: showNewCouponPage,
    }))
    .sort(sortByDefaultCouponCategory({ dontSort: showNewCouponPage }))
    .concat(manualCouponEntryTab)
    .map(({ path, ...tab }, index) => ({
      ...tab,
      active: currentCategoryName ? currentCategoryName === path : index === 0,
      path,
    }));

  const { id: currentTabId } = tabs.find((tab) => tab.active) || {};
  let { coupons = [] } = categories[currentTabId] || {};
  const couponsLink = getLinkFromRelationship("coupons")(currentOrder);

  coupons = coupons.filter(
    filterValidCoupons({
      currentServiceMethod: currentOrder.serviceMethod,
      storeUTCOffset,
    })
  );

  return Object.assign(
    {
      coupons,
      currentTabId,
      manualCouponEntryTab,
      showNewCouponPage,
      tabs,
      createOrderCoupon: (orderCoupon) =>
        dispatchCreateCoupon(
          Object.assign({}, orderCoupon, {
            orderId: currentOrderId,
            url: couponsLink,
          })
        ),
    },
    state,
    dispatch,
    props
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["coupons", "shared"])(Coupons));
