import React, { Fragment } from "react";

import PropTypes from "prop-types";

import kebabCase from "modules/kebabCase";

import RequiredMark from "components/RequiredMark";

const DrivenField = ({
  children,
  className,
  drivers,
  handleChange,
  initializeSource,
  isChild,
  isReady,
  label,
  name,
  quidBase,
  required,
  source,
  type,
  value,
}) => {
  const hasSource = Object.keys(source).length > 0;
  const normalizeSource = () =>
    Array.isArray(source)
      ? source
      : Object.entries(source).map(([key, option]) => ({
          key,
          option,
        }));
  if ((!isChild || isReady) && !hasSource) initializeSource();

  const childProps = {
    drivers: Object.assign({}, drivers, { [name]: value }),
    isChild: true,
    isReady: !!value,
  };

  const inputProps = {
    className: "text-field__input",
    defaultValue: value,
    name,
    onChange: ({ target: { value: newValue } }) =>
      handleChange({ [name]: newValue }, children(childProps)),
  };

  let field;

  switch (type) {
    case "text":
      field = (
        <input
          {...inputProps}
          data-quid={`${quidBase}-input`}
          placeholder={label}
        />
      );
      break;
    case "select":
    default:
      field = (
        <select
          {...inputProps}
          data-quid={`${quidBase}-select`}
          required={required}
          disabled={!hasSource}
        >
          <option />
          {normalizeSource().map(({ key, option = "" }) => (
            <option data-quid={kebabCase(option)} key={key} value={key}>
              {option}
            </option>
          ))}
        </select>
      );
      break;
  }

  const Root = ({ children: cells }) =>
    isChild ? (
      <Fragment>{cells}</Fragment>
    ) : (
      <div className="grid">{cells}</div>
    );

  return (
    <Root>
      <div
        className={`form__control-group text-field ${className}`}
        data-quid={quidBase}
      >
        <label
          className="text-field__label"
          data-quid={`${quidBase}-label`}
          htmlFor={name}
        >
          {label}
          {required ? <RequiredMark /> : null}
        </label>

        {field}
      </div>

      {children(childProps)}
    </Root>
  );
};

DrivenField.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string,
  drivers: PropTypes.objectOf(PropTypes.string),
  handleChange: PropTypes.func.isRequired,
  initializeSource: PropTypes.func,
  isChild: PropTypes.bool,
  isReady: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  quidBase: PropTypes.string.isRequired,
  source: PropTypes.objectOf(PropTypes.string),
  type: PropTypes.oneOf(["text", "select"]),
  value: PropTypes.string,
  required: PropTypes.bool,
};

DrivenField.defaultProps = {
  children: () => {},
  className: "grid__cell--1",
  drivers: {},
  initializeSource: () => {},
  isChild: false,
  isReady: false,
  source: [],
  type: "select",
  value: "",
  required: false,
};

export default DrivenField;
