import { combineEpics, ofType } from "redux-observable";
import { empty } from "rxjs/observable/empty";
import { of } from "rxjs/observable/of";
import { catchError, concatMap, map, mergeMap, pluck } from "rxjs/operators";

import { CREATE_ADDRESS_SUCCESS } from "ducks/address";
//temp: rework to be agent agnostic when service can comply
import { setAgentHasOrder } from "ducks/agent";
import { RESET_STATE } from "ducks/configuration";
import {
  CREATE_ORDER_COUPON_SUCCESS,
  DELETE_ORDER_COUPON_SUCCESS,
} from "ducks/orderCoupon";
import {
  CREATE_ORDER_PRODUCT_SUCCESS,
  DELETE_ORDER_PRODUCT_SUCCESS,
} from "ducks/orderProduct";
import { getDataIdFromRelationship } from "selectors/related";

import {
  ORDER_COMPLETION_STATUS,
  ORDER_METHOD,
  ORDER_TIMING,
  SERVICE_METHOD,
} from "constants/order";
import withValidation from "modules/elValidadore";
import exists from "modules/exists";
import flatten from "modules/flatten";
import setState from "modules/setState";
import { idValidators, urlValidators } from "modules/validators";

import {
  EXIT_ORDER_SUCCESS,
  exitOrderEpic,
  exitOrderSuccessEpic,
} from "./ducklings/exit";
import { setOrderLocationEpic } from "./ducklings/location";
import { placeOrderEpic, placeOrderSuccessEpic } from "./ducklings/place";
import {
  priceValidateOrderEpic,
  priceValidateOrderErrorEpic,
} from "./ducklings/priceValidate";
import { reorderEpic } from "./ducklings/reorder";
import { updateOrderServiceMethodEpic } from "./ducklings/serviceMethod";
import { startOrderEpic, startOrderSuccessEpic } from "./ducklings/start";
import { setStoreEpic, updateOrderStoreEpic } from "./ducklings/store";
import {
  deleteOrderFutureTimeEpic,
  updateOrderFutureTimeEpic,
  updateOrderTimingEpic,
} from "./ducklings/timing";
import {
  validateOnOrderModifiedEpic,
  validateOrderEpic,
} from "./ducklings/validate";
import {
  getCreateAddressSuccessAction,
  getCreateOrderAction,
  getCreateOrderCouponSuccessActions,
  getCreateOrderProductSuccessActions,
  getGetStoreAction,
  getLocateStoresAction,
  getLookupCustomerAction,
  getOrderFinishAction,
  getUpdateCustomerSuccessAction,
} from "./helpers";

export { exitOrder } from "./ducklings/exit";
export { setOrderLocation } from "./ducklings/location";
export { placeOrder } from "./ducklings/place";
export { priceValidateOrder } from "./ducklings/priceValidate";
export { reorder } from "./ducklings/reorder";
export { updateOrderServiceMethod } from "./ducklings/serviceMethod";
export { startOrder } from "./ducklings/start";
export { updateOrderStore, UPDATE_ORDER_STORE } from "./ducklings/store";
export {
  deleteOrderFutureTime,
  updateOrderFutureTime,
  updateOrderTiming,
} from "./ducklings/timing";

const SCOPE = "order-entry/order/";

export const CREATE_ORDER = `${SCOPE}CREATE_ORDER`;
export const UPDATE_ORDER = `${SCOPE}UPDATE_ORDER`;
export const GET_ORDER = `${SCOPE}GET_ORDER`;
export const GET_ORDER_SUCCESS = `${SCOPE}GET_ORDER_SUCCESS`;
export const GET_ORDER_FINISH = `${SCOPE}GET_ORDER_FINISH`;
export const GET_ORDER_ERROR = `${SCOPE}GET_ORDER_ERROR`;
export const DELETE_ORPHANED_ORDERS = `${SCOPE}DELETE_ORPHANED_ORDERS`;

export const createOrder = withValidation(
  ({
    addressId = null,
    amountBreakDown = {},
    date = null,
    futureTime = "",
    links = {},
    orderCouponIds = [],
    orderDateTime = "",
    orderId = null,
    orderMethod = ORDER_METHOD.CCA,
    orderProductIds = [],
    orderStatus = ORDER_COMPLETION_STATUS.UNFULFILLED,
    orderTiming = ORDER_TIMING.NOW,
    relationships = {},
    serviceMethod = SERVICE_METHOD.CARRYOUT,
    storeId = null,
    storeOrderId = "",
    time = "",
    timeList = [],
    addStJudeRoundUp,
  } = {}) => ({
    type: CREATE_ORDER,
    addressId,
    amountBreakDown,
    date,
    futureTime,
    links,
    orderCouponIds,
    orderDateTime,
    orderId,
    orderMethod,
    orderProductIds,
    orderStatus,
    orderTiming,
    relationships,
    serviceMethod,
    storeId,
    storeOrderId,
    time,
    timeList,
    addStJudeRoundUp,
  }),
  {
    orderId: idValidators,
  }
);

export const updateOrder = withValidation(
  ({ orderId = null, ...order } = {}) =>
    Object.assign(
      {
        type: UPDATE_ORDER,
        orderId,
      },
      order
    ),
  {
    orderId: idValidators,
  }
);

export const getOrder = withValidation(
  ({ agent = false, url = "" } = {}) => ({
    type: GET_ORDER,
    agent, //temp: rework to be agent agnostic when service can comply
    url,
  }),
  {
    url: urlValidators,
  }
);

export function getOrderSuccess({
  data = {},
  included = [],
  skipCustomerLookup = false,
} = {}) {
  return {
    type: GET_ORDER_SUCCESS,
    data,
    included,
    skipCustomerLookup,
  };
}

export const getOrderFinish = withValidation(
  ({ orderId, priceOrderLink, skipPriceOrder } = {}) => ({
    type: GET_ORDER_FINISH,
    orderId,
    priceOrderLink,
    skipPriceOrder,
  }),
  {
    orderId: idValidators,
    priceOrderLink: urlValidators,
  }
);

export function getOrderError(error) {
  return {
    type: GET_ORDER_ERROR,
    error,
  };
}

export function deleteOrphanedOrders({ phone = "" } = {}) {
  return {
    type: DELETE_ORPHANED_ORDERS,
    phone,
  };
}

export const initialState = {};

const filterOutOrphanedOrders =
  ({ phone }) =>
  ([, { orderStatus = ORDER_COMPLETION_STATUS.FULFILLED, ...order }]) =>
    orderStatus !== ORDER_COMPLETION_STATUS.FULFILLED ||
    getDataIdFromRelationship("customer")(order) === phone;

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case CREATE_ORDER:
      return Object.assign({}, state, {
        [action.orderId]: action,
      });
    case UPDATE_ORDER:
      return Object.assign({}, state, {
        [action.orderId]: Object.assign({}, state[action.orderId], action),
      });
    case CREATE_ORDER_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        [action.orderId]: Object.assign({}, state[action.orderId], {
          orderProductIds: Array.from(
            new Set(
              state[action.orderId].orderProductIds.concat(
                action.orderProductId
              )
            )
          ),
        }),
      });
    case DELETE_ORDER_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        [action.orderId]: Object.assign({}, state[action.orderId], {
          orderProductIds: state[action.orderId].orderProductIds.filter(
            (orderProductId) => orderProductId !== action.orderProductId
          ),
        }),
      });
    case CREATE_ORDER_COUPON_SUCCESS:
      return Object.assign({}, state, {
        [action.orderId]: Object.assign({}, state[action.orderId], {
          orderCouponIds: Array.from(
            new Set(
              state[action.orderId].orderCouponIds.concat(action.orderCouponId)
            )
          ),
        }),
      });
    case DELETE_ORDER_COUPON_SUCCESS:
      return Object.assign({}, state, {
        [action.orderId]: Object.assign({}, state[action.orderId], {
          orderCouponIds: state[action.orderId].orderCouponIds.filter(
            (orderCouponId) => orderCouponId !== action.orderCouponId
          ),
        }),
      });
    case CREATE_ADDRESS_SUCCESS:
      return Object.assign({}, state, {
        [action.orderId]: Object.assign({}, state[action.orderId], {
          addressId: action.addressId,
        }),
      });
    case DELETE_ORPHANED_ORDERS:
      return Object.entries(state)
        .filter(filterOutOrphanedOrders(action))
        .reduce(
          (all, [orderId, order]) =>
            Object.assign(all, {
              [orderId]: order,
            }),
          {}
        );
    case EXIT_ORDER_SUCCESS:
      return Object.keys(state)
        .filter((orderId) => orderId !== action.orderId)
        .reduce(
          (all, orderId) =>
            Object.assign(all, {
              [orderId]: state[orderId],
            }),
          {}
        );
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
export const getOrderEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_ORDER),
    //temp: rework to be agent agnostic when service can comply
    mergeMap(({ agent, ...action }) =>
      fetch(
        Object.assign(action, {
          query: {
            include: [
              "address",
              "coupons",
              "customer",
              "futureTime",
              "products",
            ].join(","),
          },
        })
      ).pipe(
        pluck("response"),
        mergeMap((data) =>
          [getOrderSuccess(data)].concat(
            //temp: rework to be agent agnostic when service can comply
            agent ? setAgentHasOrder(true) : []
          )
        ),
        //temp, filter by 404 status when service is ready
        //temp: rework to be agent agnostic when service can comply
        catchError(() => (agent ? of(setAgentHasOrder(false)) : empty()))
      )
    )
  );

export const getOrderSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(GET_ORDER_SUCCESS),
    map(setState(getState)),
    concatMap(({ skipCustomerLookup, ...data }) => {
      const {
        state: {
          customer: { stJudeRoundUp = false },
        },
      } = data;

      return [
        getGetStoreAction({ setUserSelected: true }),
        getCreateOrderAction,
        getCreateAddressSuccessAction,
        getLocateStoresAction,
        ...(skipCustomerLookup ? [] : [getUpdateCustomerSuccessAction]),
        ...(skipCustomerLookup ? [] : [getLookupCustomerAction]),
        getCreateOrderProductSuccessActions({ stJudeRoundUp }),
        getCreateOrderCouponSuccessActions,
        getOrderFinishAction({ skipPriceOrder: stJudeRoundUp }),
      ]
        .map((action) => action(data))
        .reduce(flatten, [])
        .filter(exists);
    })
  );

export const epics = combineEpics(
  deleteOrderFutureTimeEpic,
  exitOrderEpic,
  exitOrderSuccessEpic,
  getOrderEpic,
  getOrderSuccessEpic,
  placeOrderEpic,
  placeOrderSuccessEpic,
  priceValidateOrderEpic,
  priceValidateOrderErrorEpic,
  reorderEpic,
  setOrderLocationEpic,
  setStoreEpic,
  startOrderEpic,
  startOrderSuccessEpic,
  updateOrderFutureTimeEpic,
  updateOrderServiceMethodEpic,
  updateOrderStoreEpic,
  updateOrderTimingEpic,
  validateOnOrderModifiedEpic,
  validateOrderEpic
);
