import { combineEpics, ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import { RESET_STATE } from "ducks/configuration";
import {
  CREATE_PART_OPTION,
  DELETE_PART_OPTION,
  createPartOption,
  deletePartOption,
} from "ducks/partOption";

import { OPTION, defaultArea } from "constants/order";
import withValidation from "modules/elValidadore";
import uuid from "modules/uuid";
import { idValidators } from "modules/validators";

const SCOPE = "order-entry/part/";

export const CREATE_PART = `${SCOPE}CREATE_PART`;
export const UPDATE_PART = `${SCOPE}UPDATE_PART`;
export const ADD_PART_PART_OPTION = `${SCOPE}ADD_PART_PART_OPTION`;
export const REMOVE_PART_PART_OPTION = `${SCOPE}REMOVE_PART_PART_OPTION`;
export const DELETE_PART = `${SCOPE}DELETE_PART`;
export const DELETE_PART_SUCCESS = `${SCOPE}DELETE_PART_SUCCESS`;

export const createPart = withValidation(
  ({
    area = defaultArea,
    categoryType = "",
    options = [],
    orderProductId = null,
    partId = uuid(),
    partOptionIds = [],
    productId = "",
    productName = "",
  } = {}) => ({
    type: CREATE_PART,
    area,
    categoryType,
    options,
    orderProductId,
    partId,
    partOptionIds,
    productId,
    productName,
  }),
  {
    orderProductId: idValidators,
  }
);

export const updatePart = withValidation(
  ({ partId = null, ...part } = {}) =>
    Object.assign({}, part, {
      type: UPDATE_PART,
      partId,
    }),
  {
    partId: idValidators,
  }
);

export const deletePart = withValidation(
  ({ orderProductId = null, partId = null } = {}) => ({
    type: DELETE_PART,
    orderProductId,
    partId,
  }),
  {
    orderProductId: idValidators,
    partId: idValidators,
  }
);

export const deletePartSuccess = withValidation(
  ({ orderProductId = null, partId = null } = {}) => ({
    type: DELETE_PART_SUCCESS,
    orderProductId,
    partId,
  }),
  {
    orderProductId: idValidators,
    partId: idValidators,
  }
);

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case CREATE_PART:
      return Object.assign({}, state, {
        [action.partId]: action,
      });
    case UPDATE_PART:
      return Object.assign({}, state, {
        [action.partId]: Object.assign({}, state[action.partId], action),
      });
    case DELETE_PART_SUCCESS:
      return Object.keys(state)
        .filter((partId) => partId !== action.partId)
        .reduce(
          (all, partId) =>
            Object.assign(all, {
              [partId]: state[partId],
            }),
          {}
        );
    case CREATE_PART_OPTION:
      return Object.assign({}, state, {
        [action.partId]: Object.assign({}, state[action.partId], {
          partOptionIds: Array.from(
            new Set(
              state[action.partId].partOptionIds.concat(action.partOptionId)
            )
          ),
        }),
      });
    case DELETE_PART_OPTION:
      return Object.assign(
        {},
        state,
        state[action.partId]
          ? {
              [action.partId]: Object.assign({}, state[action.partId], {
                partOptionIds: state[action.partId].partOptionIds.filter(
                  (partOptionId) => partOptionId !== action.partOptionId
                ),
              }),
            }
          : {}
      );
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export const createPartEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(CREATE_PART),
    mergeMap(({ categoryType, options, partId }) => {
      //TODO: replace with selectors
      const { topping, side } = getState();

      return options.map(({ code, weight }) => {
        const { toppingId } = topping[`${categoryType}${code}`] || {};
        const { sideId } = side[code] || {};

        const optionType = sideId ? OPTION.SIDE : OPTION.TOPPING;

        const optionId = optionType === OPTION.TOPPING ? toppingId : sideId;

        return createPartOption({
          optionId,
          optionType,
          optionWeight: weight,
          partId,
        });
      });
    })
  );

export const deletePartEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(DELETE_PART),
    mergeMap(({ partId, orderProductId }) => {
      //TODO: replace with selectors
      const { part } = getState();
      const { partOptionIds } = part[partId];

      return [deletePartSuccess({ partId, orderProductId })].concat(
        partOptionIds.map((partOptionId) =>
          deletePartOption({
            partId,
            partOptionId,
          })
        )
      );
    })
  );

export const epics = combineEpics(createPartEpic, deletePartEpic);
