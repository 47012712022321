/** @jsxImportSource @emotion/react */
import { Fragment, useMemo } from "react";

import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import {
  getMarketConfigs,
  getNewExperienceFeature,
  getNewExperienceFeatureOptions,
} from "ducks/market/selectors";
import { getCookingInstructionOptions } from "ducks/menu/selectors";

import { selectHasUserSelectedStore } from "rtk_redux/slices/customerPageSlice";
import {
  getShowTip,
  selectTipAmountNumeric,
} from "rtk_redux/slices/finishSlice";
import { selectPriceOrderLoading } from "rtk_redux/slices/priceOrderSlice";

import { AREA, SERVICE_METHOD, defaultArea } from "constants/order";
import formatCurrency from "modules/formatCurrency";

import DotLoader from "components/DotLoader";
import PriceBreakdown from "components/PriceBreakdown";
import Area from "components/Receipt/Area";
import ReceiptCoupon from "components/Receipt/ReceiptCoupon";
import ReceiptItem from "components/Receipt/ReceiptItem";

import styles, { boldStyle, zeroMarginStyle } from "./Receipt.styles";

const Receipt = ({
  currentServiceMethod,
  customerName,
  customerPhone,
  deleteOrderCoupon,
  deleteOrderProduct,
  deliveryAddressLine1,
  deliveryAddressLine2,
  deliveryStreetAddress,
  goToCustomer,
  goToEverythingElse,
  goToFulfiller,
  goToPizzas,
  marketCode,
  marketConfigs,
  orderCoupons,
  priceValidateOrder,
  receiptSummary,
  resetActiveOrderProduct,
  savings,
  setActiveOrderProduct,
  setOrderProduct,
  storeId,
  storeName,
  storePhone,
  storeRegion,
  storeStreet,
  t,
  total,
  shouldUseNewCustomerPage,
  deliveryInstructions,
}) => {
  const tipAmountNumeric = useSelector(selectTipAmountNumeric);
  const showTip = useSelector(getShowTip);
  const hasUserSelectedStore = useSelector(selectHasUserSelectedStore);
  const cookingInstructionOptions = useSelector(getCookingInstructionOptions);
  const { DISPLAY_STORE_NAME } = useSelector(getMarketConfigs);
  const priceOrderLoading = useSelector(selectPriceOrderLoading);
  const {
    hideDefaultNationalStore,
    automaticPriceOrder: shouldUseAutomaticPriceOrder,
    showDefaultSidesInReceiptSidebar,
    showPriceBreakdownInReceiptSidebar,
  } = useSelector(getNewExperienceFeature);
  const { hideBaseNameForCategoryIds, hideProductNameForCategoryIds } =
    useSelector(getNewExperienceFeatureOptions);

  const joinSides = (sides = {}) => {
    return Object.entries(sides)
      .map(([sideName, sideQuantity]) => `${sideName} (${sideQuantity})`)
      .join(", ");
  };

  const buildItemName = ({ orderProductId, categoryId }) => {
    // TODO: fix
    // eslint-disable-next-line react/prop-types
    const {
      sizeName = "",
      isDonation = false,
      baseName = "",
      parts,
    } = receiptSummary[orderProductId];

    if (isDonation) return sizeName;

    const updatedBaseName = hideBaseNameForCategoryIds.includes(categoryId)
      ? ""
      : baseName;

    const updatedProductName = hideProductNameForCategoryIds.includes(
      categoryId
    )
      ? ""
      : Object.entries(parts)
          .map(
            ([area, productName]) =>
              `${
                area !== defaultArea
                  ? t((AREA[area] || AREA[defaultArea]).name)
                  : ""
              } ${productName}`
          )
          .join(", ");

    return `${sizeName} ${updatedBaseName} ${updatedProductName}`;
  };

  const showStore = useMemo(
    () => !hideDefaultNationalStore || hasUserSelectedStore,
    [hideDefaultNationalStore, hasUserSelectedStore]
  );

  const name =
    DISPLAY_STORE_NAME && storeName
      ? t("receipt:store_name", { storeName, storeId })
      : t("receipt:store_id", { storeId });

  return (
    <div css={styles} className="receipt subsection">
      <div className="subsection__header text--center">
        <header className="title-group">
          <h2 className="title-group__section" data-quid="heading-receipt">
            {t("receipt:title")}
          </h2>

          <div className="title-group__section">
            <span className="text--center">
              <button
                className="btn btn--clear no-gutter"
                data-quid="receipt-service-method"
                onClick={goToCustomer}
                type="button"
              >
                <b>{currentServiceMethod}</b>
              </button>
            </span>
          </div>
        </header>

        <div className="split even">
          {showStore && (
            <div
              className="split__left text--left"
              data-quid="receipt-store-summary"
            >
              <b>{name}</b>
              <br />
              {storeStreet}
              <br />
              {storeRegion}
              <br />
              {storePhone}
            </div>
          )}

          <div
            className="split__right text--right"
            data-quid="receipt-customer-summary"
          >
            <b>{customerName}</b>
            {currentServiceMethod === SERVICE_METHOD.DELIVERY && (
              <Fragment>
                <br />
                {shouldUseNewCustomerPage
                  ? deliveryStreetAddress
                  : deliveryAddressLine1}
                <br />
                {deliveryAddressLine2}
              </Fragment>
            )}
            <br />
            {customerPhone}
          </div>
        </div>

        <br />
        {shouldUseNewCustomerPage &&
          currentServiceMethod === SERVICE_METHOD.DELIVERY && (
            <p style={{ textAlign: "left" }}>{deliveryInstructions}</p>
          )}
        <hr />
      </div>

      <div className="subsection__body">
        {Object.values(receiptSummary).map(
          (
            {
              active,
              itemPrice,
              itemQuantity,
              orderProductId,
              categoryId,
              sides,
              defaultSides,
              toppings,
              cookingInstructions = [],
            },
            index
          ) => {
            const updatedSides = showDefaultSidesInReceiptSidebar
              ? { ...defaultSides, ...sides }
              : sides;
            const updatedCookingInstructions = cookingInstructions
              .filter(Boolean)
              .map((code) => cookingInstructionOptions[code]?.name);

            return (
              <ReceiptItem
                active={active}
                deleteOrderProduct={deleteOrderProduct}
                itemName={buildItemName({ orderProductId, categoryId })}
                itemNumber={index + 1}
                itemPrice={itemPrice}
                itemQuantity={itemQuantity}
                key={orderProductId}
                marketCode={marketCode}
                marketConfigs={marketConfigs}
                orderProductId={orderProductId}
                setActiveOrderProduct={setActiveOrderProduct}
                setOrderProduct={setOrderProduct}
                defaultSides={defaultSides}
                categoryId={categoryId}
                t={t}
              >
                {Object.entries(toppings).map(([area, options]) => (
                  <Area area={area} key={area} options={options} t={t} />
                ))}

                {Object.keys(updatedSides).length > 0 && (
                  <div>
                    {t("receipt:sides")}: {joinSides(updatedSides)}
                  </div>
                )}

                {updatedCookingInstructions.length > 0 && (
                  <div>
                    {t("receipt:special_instructions")}:{" "}
                    {updatedCookingInstructions.join(", ")}
                  </div>
                )}
              </ReceiptItem>
            );
          }
        )}

        {Object.values(orderCoupons).map(
          (
            { couponCode, name, fulfilled, orderCouponId, unfulfilledGroups },
            index
          ) => (
            <ReceiptCoupon
              couponCode={couponCode}
              couponName={name}
              couponNumber={index + 1}
              deleteOrderCoupon={deleteOrderCoupon}
              fulfilled={fulfilled}
              key={orderCouponId}
              orderCouponId={orderCouponId}
              t={t}
              unfulfilledGroups={unfulfilledGroups}
              goToEverythingElse={goToEverythingElse}
              goToFulfiller={goToFulfiller}
              goToPizzas={goToPizzas}
              resetActiveOrderProduct={resetActiveOrderProduct}
            />
          )
        )}
      </div>

      <div className="subsection__footer">
        <hr css={showPriceBreakdownInReceiptSidebar && zeroMarginStyle} />
        {showPriceBreakdownInReceiptSidebar && <PriceBreakdown t={t} />}

        <div className="split even">
          <div className="split__left text--left">
            <button
              className="btn btn--primary"
              data-icon="attach_money"
              data-quid="price-order"
              onClick={priceValidateOrder}
              type="button"
            >
              {t("shared:action:price")}
            </button>
          </div>

          {!!total && (
            <div className="split__right text--right">
              {!showPriceBreakdownInReceiptSidebar && !!savings && (
                <div className="total-line" data-quid="receipt-savings">
                  {t("receipt:savings")}:{" "}
                  <b>
                    {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                      <DotLoader />
                    ) : (
                      formatCurrency(marketCode, savings)
                    )}
                  </b>
                </div>
              )}
              <div
                className="total-line text--bigger"
                data-quid="receipt-total"
                css={boldStyle}
              >
                {t("receipt:total")}:{" "}
                <b>
                  {shouldUseAutomaticPriceOrder && priceOrderLoading ? (
                    <DotLoader />
                  ) : (
                    formatCurrency(
                      marketCode,
                      showTip ? total + tipAmountNumeric : total
                    )
                  )}
                </b>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Receipt.propTypes = {
  currentServiceMethod: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  customerPhone: PropTypes.string,
  deleteOrderCoupon: PropTypes.func.isRequired,
  deleteOrderProduct: PropTypes.func.isRequired,
  deliveryAddressLine1: PropTypes.string,
  deliveryAddressLine2: PropTypes.string,
  deliveryStreetAddress: PropTypes.string,
  goToCustomer: PropTypes.func.isRequired,
  goToEverythingElse: PropTypes.func.isRequired,
  goToFulfiller: PropTypes.func.isRequired,
  goToPizzas: PropTypes.func.isRequired,
  marketCode: PropTypes.string.isRequired,
  marketConfigs: PropTypes.shape({
    SINGLE_LINE_ITEM_MAX_QTY: PropTypes.number,
    SINGLE_LINE_ITEM_MIN_QTY: PropTypes.number,
  }).isRequired,
  orderCoupons: PropTypes.objectOf(PropTypes.shape()),
  priceValidateOrder: PropTypes.func.isRequired,
  receiptSummary: PropTypes.objectOf(
    PropTypes.shape({
      active: PropTypes.bool,
      baseName: PropTypes.string,
      categoryId: PropTypes.string,
      isDonation: PropTypes.bool,
      itemPrice: PropTypes.number,
      itemQuantity: PropTypes.number,
      orderProductId: PropTypes.number,
      parts: PropTypes.objectOf(PropTypes.string),
      sides: PropTypes.objectOf(PropTypes.number),
      sizeName: PropTypes.string,
      toppings: PropTypes.objectOf(PropTypes.objectOf(PropTypes.number)),
    })
  ),
  resetActiveOrderProduct: PropTypes.func.isRequired,
  setActiveOrderProduct: PropTypes.func.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  storeId: PropTypes.string,
  storeName: PropTypes.string,
  storePhone: PropTypes.string,
  storeRegion: PropTypes.string,
  storeStreet: PropTypes.string,
  t: PropTypes.func,
  total: PropTypes.number,
  savings: PropTypes.number,
  shouldUseNewCustomerPage: PropTypes.bool,
  deliveryInstructions: PropTypes.string,
};

Receipt.defaultProps = {
  customerName: "",
  customerPhone: "",
  deliveryAddressLine1: "",
  deliveryAddressLine2: "",
  orderCoupons: {},
  receiptSummary: {},
  savings: 0,
  storeName: "",
  storePhone: "",
  storeRegion: "",
  storeStreet: "",
  t: () => {},
  total: 0,
  shouldUseNewCustomerPage: false,
};

export default Receipt;
