/** @jsxImportSource @emotion/react */
import { Component } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import {
  PROFILE,
  recentCarryoutLocations,
  recentDeliveryLocations,
} from "constants/customer";
import { ORDER_TIMING, SERVICE_METHOD } from "constants/order";
import doValidation from "modules/doValidation";
import { buildTimeList, getDay, toISOLocal } from "modules/futureTime";
import kebabCase from "modules/kebabCase";

import OrderLocations from "components/Customer/Profile/OrderLocations";
import DatePicker from "components/DatePicker";
import ProfileField from "components/ProfileField";
import RequiredMark from "components/RequiredMark";

import styles from "./Profile.styles";

const propToStateMap = Object.freeze({
  currentOrderId: "startedOrder",
  currentOrderTimeList: "timeList",
  currentOrderDate: "date",
  currentOrderTime: "time",
  currentServiceMethod: "serviceMethod",
});

class Profile extends Component {
  static showValidTimes({ date, time }) {
    const [hours, minutes] = time.original.split(":");
    const newDate = new Date(date.getTime());
    const MINUTE = 60000;

    newDate.setHours(parseInt(hours, 10));
    newDate.setMinutes(parseInt(minutes, 10));

    return new Date().getTime() + MINUTE * 30 < newDate.getTime();
  }

  constructor(props) {
    super(props);

    this.state = {
      startedOrder: false,
      invalid: [],
      timeList: [],
      date: new Date(),
      time: "",
      serviceMethod: SERVICE_METHOD.CARRYOUT,
    };

    this.setOrderPreferences = this.setOrderPreferences.bind(this);
    this.setTimeList = this.setTimeList.bind(this);
    this.handleProfileBlur = this.handleProfileBlur.bind(this);
    this.handleProfileFocus = this.handleProfileFocus.bind(this);
    this.isActive = this.isActive.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.updateFutureTime = this.updateFutureTime.bind(this);
    this.getProfileFieldClasses = this.getProfileFieldClasses.bind(this);
  }

  componentDidMount() {
    const { currentOrderDate } = this.props;

    currentOrderDate && this.setTimeList(currentOrderDate);
  }

  //TODO: upgrade react & swap to static getDerivedStateFromProps
  componentDidUpdate(nextProps) {
    const nextState = {};
    let propsHaveChanged = false;

    Object.entries(propToStateMap).forEach(([prop, stateProp]) => {
      if (nextProps[prop] !== this.state[stateProp]) {
        if (!propsHaveChanged) propsHaveChanged = true;
        nextState[stateProp] = nextProps[prop];
      }
    });

    if (propsHaveChanged) this.setState(nextState);
  }

  setOrderPreferences(key, value) {
    const { setServiceMethod, setOrderTiming } = this.props;
    const { date } = this.state;

    if (key === PROFILE.SERVICE_METHOD) {
      setServiceMethod(value);
      this.setState(
        { serviceMethod: value },
        () => date && this.setTimeList(date)
      );
    }

    key === PROFILE.ORDER_TIMING && setOrderTiming(value);
  }

  setTimeList(date) {
    const { serviceHours, updateOrder } = this.props;
    const { serviceMethod } = this.state;

    const day = getDay(date.getDay());

    const timeList = buildTimeList(serviceHours[serviceMethod][day]).filter(
      (time) => this.constructor.showValidTimes({ date, time })
    );

    updateOrder({ timeList });
    this.setState({ timeList });
  }

  getProfileFieldClasses({ field }) {
    const { profileFields } = this.props;
    const isPhoneComponent = [
      PROFILE.EXTENSION,
      PROFILE.PHONE,
      PROFILE.PREFIX,
    ].includes(field);

    if (isPhoneComponent && profileFields[PROFILE.PREFIX].hidden) {
      return "grid__cell--1/4@desktop";
    } else if (isPhoneComponent) {
      return field === PROFILE.PHONE
        ? "grid__cell--1/4@desktop"
        : "grid__cell--1/8@desktop";
    }

    if (field === PROFILE.LAST_NAME && this.props.marketCode === "NG")
      return "";

    if (
      field === PROFILE.COMMENTS ||
      (field === PROFILE.LAST_NAME && this.props.marketCode === "MY")
    )
      return "";
    return "grid__cell--1/2@desktop";
  }

  handleProfileBlur({ target: { id, value } }) {
    const {
      comments,
      email,
      extension,
      firstName,
      lastName,
      marketCode,
      phone,
      prefix,
      lookupCustomer,
      profileFields,
    } = this.props;
    // TODO: fix
    // eslint-disable-next-line react/prop-types
    const { required = false, excludeRequiredForMarkets = [] } =
      profileFields[id];
    const isRequired =
      required && !excludeRequiredForMarkets.includes(marketCode);
    const isPhone = profileFields[id].type === PROFILE.PHONE;
    const isPrefix = id === PROFILE.PREFIX;
    const newValue = isPrefix
      ? value.replace(/[^\d]/g, "").trim()
      : value.trim();
    const isNotRequiredOrHasValue = !isRequired || newValue.length;

    let valid =
      doValidation(value, profileFields[id].type, marketCode) &&
      isNotRequiredOrHasValue;

    if (isPhone && prefix) {
      valid =
        doValidation(
          `+${prefix}${value}`,
          profileFields[id].type,
          marketCode
        ) && isNotRequiredOrHasValue;
    }

    this.props.updateCustomer({
      comments,
      email,
      extension,
      firstName,
      lastName,
      phone,
      prefix,
      [id]: newValue,
    });

    if (valid) {
      if ([PROFILE.EXTENSION, PROFILE.PHONE, PROFILE.PREFIX].includes(id)) {
        lookupCustomer({
          extension,
          phone,
          prefix,
          [id]: newValue,
        });
      }
    } else {
      this.setState({
        invalid: [...this.state.invalid, id],
      });
    }
  }

  handleProfileFocus({ target: { id } }) {
    this.setState({
      invalid: this.state.invalid.filter((prop) => prop !== id),
    });
  }

  isActive(key, value) {
    const { currentServiceMethod, currentOrderTiming } = this.props;
    switch (key) {
      case PROFILE.SERVICE_METHOD:
        return value === currentServiceMethod;
      case PROFILE.ORDER_TIMING:
        return currentOrderTiming !== undefined && currentOrderTiming === value;
      default:
        return false;
    }
  }

  handleDateSelect(date) {
    const { updateOrder } = this.props;

    updateOrder({ date });
    this.setTimeList(date);

    this.setState({ date }, this.updateFutureTime);
  }

  handleTimeChange({ target: { value: time } }) {
    const { updateOrder, clearFutureTimeError } = this.props;

    updateOrder({ time });
    clearFutureTimeError();

    this.setState({ time }, this.updateFutureTime);
  }

  updateFutureTime() {
    const { date, time } = this.state;

    if (time && date) {
      const [hours, minutes] = time.split(":");
      const newDate = new Date(date.getTime());

      hours && newDate.setHours(parseInt(hours, 10));
      minutes && newDate.setMinutes(parseInt(minutes, 10));

      const futureTime = toISOLocal(newDate);
      this.props.updateOrderFutureTime({ futureTime });
    }
  }

  render() {
    const {
      availableOrderTimings,
      availableServiceMethods,
      carryoutOrderLocations,
      currentOrderTiming,
      currentServiceMethod,
      deliveryOrderLocations,
      isMinimumCustomerDataComplete,
      marketCode,
      marketConfigs: { FORCE_CUSTOMER_REQUIRED_FIELDS },
      profileFields,
      setOrderLocation,
      showFutureTimeError,
      t,
    } = this.props;

    const { invalid, timeList, date, time } = this.state;
    const { handleDateSelect, handleTimeChange } = this;

    const isDelivery = currentServiceMethod === SERVICE_METHOD.DELIVERY;

    const orderLocations = isDelivery
      ? deliveryOrderLocations
      : carryoutOrderLocations;

    const orderLocationMappings = isDelivery
      ? recentDeliveryLocations
      : recentCarryoutLocations;

    const orderLocationServiceMethod = currentServiceMethod
      ? t(`shared:service_method:${currentServiceMethod.toLowerCase()}`)
      : t(`shared:service_method:${SERVICE_METHOD.CARRYOUT.toLowerCase()}`);

    const orderLocationHeading = [
      "customer:profile:recent_locations:title",
      { serviceMethod: orderLocationServiceMethod },
    ];

    const orderLocationsNotFound = [
      "customer:profile:recent_locations:service_method_not_found",
      { serviceMethod: orderLocationServiceMethod.toLowerCase() },
    ];

    const disableOrderFields =
      FORCE_CUSTOMER_REQUIRED_FIELDS && !isMinimumCustomerDataComplete;

    return (
      <div css={styles} className="wrapper profile">
        <div className="grid" data-quid="customer-profile">
          {Object.values(profileFields)
            .filter(({ hidden = false }) => !hidden)
            .map(
              ({
                defaultValue,
                key,
                label,
                required = false,
                type,
                max,
                excludeRequiredForMarkets = [],
              }) => {
                const isRequired =
                  required && !excludeRequiredForMarkets.includes(marketCode);

                const fieldValue = this.props[key] || defaultValue || "";
                const addPlusToPrefix =
                  key === PROFILE.PREFIX &&
                  fieldValue &&
                  !fieldValue.startsWith("+");

                const value = `${addPlusToPrefix ? "+" : ""}${fieldValue}`;

                return (
                  <ProfileField
                    className={this.getProfileFieldClasses({
                      field: key,
                    })}
                    handleBlur={this.handleProfileBlur}
                    handleFocus={this.handleProfileFocus}
                    id={key}
                    isInvalid={invalid.includes(key)}
                    key={key}
                    label={t(label)}
                    quidBase={`customer-profile-${kebabCase(key)}`}
                    t={t}
                    inputType={type}
                    max={max}
                    value={value}
                    required={isRequired}
                  />
                );
              }
            )}
        </div>

        <p>
          <RequiredMark /> {t("shared:forms.indicates_required")}
        </p>

        <div className="grid" data-quid="customer-order-settings">
          {[
            {
              key: PROFILE.SERVICE_METHOD,
              label: "customer:profile.service_method",
              options: availableServiceMethods,
              quidBase: "service-method",
            },
            {
              key: PROFILE.ORDER_TIMING,
              label: "customer:profile.order_timing.title",
              options: availableOrderTimings,
              quidBase: "order-timing",
            },
          ].map(({ key, label, options, quidBase }) => (
            <div
              className="form__control-group grid__cell--1 grid__cell--1/2@desktop button-field text--center"
              key={key}
            >
              <label
                className="button-field__label"
                data-quid={`${quidBase}-label`}
                htmlFor={key}
              >
                {t(label)}
              </label>

              <div className="btn-group" id={key}>
                {options.map(({ optionType, title }) => (
                  <button
                    className={classNames(
                      "btn",
                      this.isActive(key, optionType) && "btn--primary"
                    )}
                    data-quid={`${quidBase}-${kebabCase(optionType)}`}
                    key={title}
                    onClick={() => this.setOrderPreferences(key, optionType)}
                    type="button"
                    disabled={disableOrderFields}
                  >
                    {t(title)}
                  </button>
                ))}
              </div>
            </div>
          ))}

          {currentOrderTiming === ORDER_TIMING.LATER && (
            <div className="grid__cell--1 grid__cell--1/2@desktop grid__cell--offset-1/2@desktop">
              <div className="grid__cell--1 grid__cell--1/2@desktop">
                <DatePicker
                  defaultDate={date}
                  onSelect={handleDateSelect}
                  label={t("shared:select_day")}
                  quidBase="future-order-date"
                />
              </div>

              <div
                className="grid__cell--1 grid__cell--1/2@desktop"
                data-quid="future-order-time"
              >
                <label
                  data-quid="future-order-time-label"
                  htmlFor="time-select"
                >
                  {t("shared:select_time")}
                </label>

                <select
                  id="time-select"
                  name="time-select"
                  data-quid="future-order-time-select"
                  className={classNames(
                    "text-field__input text-field--time__period",
                    showFutureTimeError && "error parsley-error"
                  )}
                  onChange={handleTimeChange}
                  value={time}
                >
                  <option value="" disabled />

                  {
                    //TODO: localize date format for market
                  }

                  {timeList &&
                    timeList.map(({ formatted, original }) => (
                      <option
                        data-quid={`future-order-time-${kebabCase(formatted)}`}
                        key={formatted}
                        value={original}
                      >
                        {formatted}
                      </option>
                    ))}
                </select>

                {showFutureTimeError && (
                  <p className="error-message">
                    {t("customer:profile:order_timing:error")}
                  </p>
                )}
              </div>
            </div>
          )}

          {!disableOrderFields && (
            <div className="grid__cell--1">
              <br />

              <OrderLocations
                currentServiceMethod={currentServiceMethod}
                heading={orderLocationHeading}
                mappings={orderLocationMappings}
                orderLocations={orderLocations}
                orderLocationsNotFound={orderLocationsNotFound}
                setOrderLocation={setOrderLocation}
                t={t}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Profile.propTypes = {
  availableOrderTimings: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string)
  ),
  availableServiceMethods: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.string)
  ),
  carryoutOrderLocations: PropTypes.arrayOf(PropTypes.object),
  clearFutureTimeError: PropTypes.func.isRequired,
  comments: PropTypes.string,
  currentOrderDate: PropTypes.instanceOf(Date),
  // eslint doesn't realize these props are used because of the propToStateMap const.
  /* eslint-disable react/no-unused-prop-types */
  currentOrderId: PropTypes.string,
  currentOrderTime: PropTypes.string,
  currentOrderTimeList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  /* eslint-enable react/no-unused-prop-types */
  currentOrderTiming: PropTypes.string,
  currentServiceMethod: PropTypes.string,
  deliveryOrderLocations: PropTypes.arrayOf(PropTypes.object),
  email: PropTypes.string,
  extension: PropTypes.string,
  firstName: PropTypes.string,
  isMinimumCustomerDataComplete: PropTypes.bool,
  lastName: PropTypes.string,
  lookupCustomer: PropTypes.func,
  marketCode: PropTypes.string.isRequired,
  marketConfigs: PropTypes.shape({
    FORCE_CUSTOMER_REQUIRED_FIELDS: PropTypes.bool,
  }),
  phone: PropTypes.string,
  prefix: PropTypes.string,
  serviceHours: PropTypes.objectOf(PropTypes.object),
  setOrderLocation: PropTypes.func.isRequired,
  setOrderTiming: PropTypes.func,
  setServiceMethod: PropTypes.func,
  showFutureTimeError: PropTypes.bool,
  t: PropTypes.func,
  updateCustomer: PropTypes.func,
  updateOrder: PropTypes.func,
  updateOrderFutureTime: PropTypes.func,
  profileFields: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

Profile.defaultProps = {
  availableOrderTimings: [
    {
      optionType: ORDER_TIMING.NOW,
      title: "shared:order_timing.now",
    },
    {
      optionType: ORDER_TIMING.LATER,
      title: "shared:order_timing.later",
    },
  ],
  availableServiceMethods: [
    {
      optionType: SERVICE_METHOD.CARRYOUT,
      title: "shared:service_method.carryout",
    },
    {
      optionType: SERVICE_METHOD.DELIVERY,
      title: "shared:service_method.delivery",
    },
  ],
  carryoutOrderLocations: [],
  comments: "",
  currentOrderDate: undefined,
  currentOrderId: "",
  currentOrderTime: "",
  currentOrderTimeList: [],
  currentOrderTiming: ORDER_TIMING.NOW,
  currentServiceMethod: SERVICE_METHOD.CARRYOUT,
  deliveryOrderLocations: [],
  email: "",
  extension: "",
  firstName: "",
  isMinimumCustomerDataComplete: false,
  lastName: "",
  lookupCustomer: () => {},
  marketConfigs: { FORCE_CUSTOMER_REQUIRED_FIELDS: false },
  phone: "",
  prefix: "",
  serviceHours: {},
  setOrderTiming: () => {},
  setServiceMethod: () => {},
  showFutureTimeError: false,
  startOrder: () => {},
  t: () => {},
  updateCustomer: () => {},
  updateOrder: () => {},
  updateOrderFutureTime: () => {},
  profileFields: {},
};

export default Profile;
