import { createSlice } from "@reduxjs/toolkit";

import { PROFILE } from "constants/customer";

export const DEFAULT_US_PREFIX = "1";

export const US_REQUIRED_PROFILE_FIELDS = [
  PROFILE.FIRST_NAME,
  PROFILE.LAST_NAME,
  PROFILE.PHONE,
];

const initialState = {
  [PROFILE.FIRST_NAME]: "",
  [PROFILE.LAST_NAME]: "",
  [PROFILE.PREFIX]: DEFAULT_US_PREFIX,
  [PROFILE.PHONE]: "",
  [PROFILE.EXTENSION]: "",
  invalid: new Set(US_REQUIRED_PROFILE_FIELDS),
};

const customerInformationFormSlice = createSlice({
  name: "customerInformationForm",
  initialState,
  reducers: {
    setFirstNameValue: (state, { payload }) => {
      state[PROFILE.FIRST_NAME] = payload;
    },
    setLastNameValue: (state, { payload }) => {
      state[PROFILE.LAST_NAME] = payload;
    },
    setPhoneNumberValue: (state, { payload }) => {
      state[PROFILE.PHONE] = payload;
    },
    setPrefixValue: (state, { payload }) => {
      state[PROFILE.PREFIX] = payload;
    },
    setExtensionValue: (state, { payload }) => {
      state[PROFILE.EXTENSION] = payload;
    },
    addInvalidValue: (state, { payload }) => {
      state.invalid.add(payload);
    },
    removeInvalidValue: (state, { payload }) => {
      state.invalid.delete(payload);
    },
    resetCustomerFormState: () => initialState,
    setPreloadedFormValues: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const {
  setFirstNameValue,
  setLastNameValue,
  setPhoneNumberValue,
  setPrefixValue,
  setExtensionValue,
  setVehicleMakeValue,
  setVehicleModelValue,
  setVehicleColorValue,
  setTextPhoneNumberValue,
  setPreferredLocation,
  toggleOptIntoTexts,
  addInvalidValue,
  removeInvalidValue,
  resetCustomerFormState,
  setPreloadedFormValues,
} = customerInformationFormSlice.actions;

export default customerInformationFormSlice.reducer;

// Selectors
export const selectFirstName = (state) =>
  state.customerInformationForm[PROFILE.FIRST_NAME];
export const selectLastName = (state) =>
  state.customerInformationForm[PROFILE.LAST_NAME];
export const selectPrefix = (state) =>
  state.customerInformationForm[PROFILE.PREFIX];
export const selectPhoneNumber = (state) =>
  state.customerInformationForm[PROFILE.PHONE];
export const selectExtension = (state) =>
  state.customerInformationForm[PROFILE.EXTENSION] || "";
export const selectNumericPhoneNumber = (state) =>
  state.customerInformationForm[PROFILE.PHONE]?.replace(/\D/g, "") || "";
export const selectInvalidValues = (state) =>
  state.customerInformationForm.invalid;
