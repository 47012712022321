import { createSelector } from "reselect";

import {
  getNewExperienceFeature,
  getNewExperienceFeatureOptions,
} from "ducks/market/selectors";
import { getAreaNames } from "ducks/menu/helpers";
import {
  getAreas,
  getBases,
  getCategories,
  getCookingInstructionGroups,
  getCookingInstructionOptions,
  getProducts,
  getSides,
  getSizes,
  getToppings,
  getWeightedOptions,
} from "ducks/menu/selectors";
import {
  getActiveOrderProduct,
  getActiveOrderProductPartIds,
  getCurrentOrderProducts,
  getPartOptions,
  getParts,
} from "ducks/order/selectors";
import { getVariantFromProduct } from "ducks/variants/selectors";
import getState from "selectors/state";

import { STJUDE_ROUND_UP_SIZE_CODE } from "constants/menu";
import {
  AREA,
  AREAS,
  AREA_SORT,
  HALF_AND_HALF_AREAS,
  OPTION,
  defaultArea,
} from "constants/order";
import { RESOURCE_TYPE } from "constants/resource";
import { ROBUST_INSPIRED_TOMATO_SAUCE_CODE, WEIGHTS } from "constants/topping";
import combineCookingInstructions from "modules/combineCookingInstructions";
import reverseSpecialtyPizzaToppings from "modules/reverseSpecialtyPizzaToppings";

export const getOrderIncludesStJudeRoundUp = createSelector(
  [getCurrentOrderProducts],
  (orderProducts) =>
    Object.values(orderProducts).some(
      ({ sizeId }) => sizeId === STJUDE_ROUND_UP_SIZE_CODE
    )
);

export const getReceiptSummary = createSelector(
  [
    getBases,
    getCurrentOrderProducts,
    getPartOptions,
    getParts,
    getProducts,
    getSides,
    getSizes,
    getToppings,
    getWeightedOptions,
    getNewExperienceFeature,
    getNewExperienceFeatureOptions,
  ],
  (
    bases,
    orderProducts,
    partOptions,
    parts,
    products,
    sides,
    sizes,
    toppings,
    weightedOptions,
    useNewExperienceFeature,
    newExperienceFeatureOptions
  ) => {
    const { reverseSpecialtyPizzaToppingDescription } = useNewExperienceFeature;
    const { newPizzaBuilder = {} } = newExperienceFeatureOptions;
    const { DEFAULT_SPECIALTY_CATEGORYID } = newPizzaBuilder;

    return Object.values(orderProducts)
      .map(
        ({
          active,
          baseId,
          categoryId,
          itemPrice,
          itemQuantity,
          orderProductId,
          partIds,
          sizeId,
          cookingInstructions = [],
        }) => {
          const { baseName } = bases[baseId] || {};
          const { sizeName, isDonation = false } = sizes[sizeId] || {};

          let orderProductPartOptions = {
            sides: {},
            toppings: {},
          };

          let defaults = {
            defaultOptions: {},
            defaultSides: {},
          };

          const sortOrderProductPartIds = (partId, nextPartId) => {
            const { area: a } = parts[partId];
            const { area: b } = parts[nextPartId];

            return AREA_SORT[a] - AREA_SORT[b];
          };

          const reduceOrderProductParts = (allParts, partId) => {
            const part = parts[partId] || {};
            const { area, productId, partOptionIds } = part;
            const {
              productName,
              defaultOptions = {},
              defaultSizeVariantOptions = {},
            } = products[productId] || {};

            const defaultSides = Object.entries(
              defaultSizeVariantOptions[sizeId] || []
            ).reduce((allDefaultSides, [defaultSideId, defaultSideWeight]) => {
              if (!weightedOptions[defaultSideId]) return allDefaultSides;

              const { optionId: weightedDefaultOptionId = "" } =
                weightedOptions[defaultSideId];

              return {
                ...allDefaultSides,
                [sides[weightedDefaultOptionId].sideName]: defaultSideWeight,
              };
            }, {});

            defaults = {
              defaultSides,
              defaultOptions,
            };

            const reduceOrderProductPartOptions = (
              allOptions,
              partOptionId
            ) => {
              const { optionId, optionType, optionWeight } =
                partOptions[partOptionId];

              const isTopping = optionType === OPTION.TOPPING;

              const optionName = isTopping
                ? toppings[optionId].toppingName
                : sides[optionId].sideName;

              const option = {
                [optionName]: optionWeight,
              };

              return Object.assign(
                allOptions,
                isTopping
                  ? {
                      toppings: Object.assign(allOptions.toppings, {
                        [area]: Object.assign(
                          allOptions.toppings[area] || {},
                          option
                        ),
                      }),
                    }
                  : {
                      sides: Object.assign(allOptions.sides, option),
                    }
              );
            };

            orderProductPartOptions = partOptionIds.reduce(
              reduceOrderProductPartOptions,
              orderProductPartOptions
            );

            return Object.assign(allParts, {
              [area]: productName,
            });
          };

          const orderProductParts = partIds
            .sort(sortOrderProductPartIds)
            .reduce(reduceOrderProductParts, {});

          const { sides: orderProductSides, toppings: orderProductToppings } =
            orderProductPartOptions;

          const { defaultOptions, defaultSides } = defaults;

          const weightedDefaultOptions = Object.entries(defaultOptions).reduce(
            (allOptions, [weightedOptionId, optionWeight]) => {
              if (!weightedOptions[weightedOptionId]) return allOptions;

              const { optionId } = weightedOptions[weightedOptionId];
              const toppingName = toppings[optionId]?.toppingName;

              allOptions[toppingName] = optionWeight;
              return allOptions;
            },
            {}
          );

          return {
            active,
            baseName,
            categoryId,
            itemPrice,
            itemQuantity,
            orderProductId,
            parts: orderProductParts,
            sizeName,
            isDonation,
            sides: orderProductSides,
            defaultSides,
            cookingInstructions,
            toppings:
              reverseSpecialtyPizzaToppingDescription &&
              categoryId === DEFAULT_SPECIALTY_CATEGORYID
                ? reverseSpecialtyPizzaToppings({
                    orderProductToppings,
                    weightedDefaultOptions,
                  })
                : orderProductToppings,
          };
        }
      )
      .reduce(
        (all, { orderProductId, ...orderProduct }) =>
          Object.assign(all, {
            [orderProductId]: Object.assign(orderProduct, {
              orderProductId,
            }),
          }),
        {}
      );
  }
);

export const getReceiptQuantity = createSelector(
  [getReceiptSummary],
  (receiptSummary) =>
    Object.values(receiptSummary).reduce(
      (total, { itemQuantity = 0 }) => total + itemQuantity,
      0
    )
);

export const getActiveOrderProductParts = createSelector(
  [getActiveOrderProductPartIds, getParts],
  (partIds, parts) => partIds.map((partId) => parts[partId])
);

export const getActiveOrderProductAreas = createSelector(
  [getActiveOrderProductParts, getProducts],
  (parts, products) =>
    Object.values(parts).reduce((all, { productId }) => {
      const { areas } = products[productId] || {};
      return Array.from(new Set(all.concat(areas)));
    }, [])
);

export const getCurrentAreas = createSelector(
  [getAreas, getActiveOrderProductAreas],
  (areas, activeAreas) => areas.filter(({ area }) => activeAreas.includes(area))
);

export const getActiveOrderProductPartOptions = createSelector(
  [getActiveOrderProductParts, getPartOptions],
  (parts, partOptions) =>
    parts.reduce((all, { area, partOptionIds = [] }) => {
      const areaPartOptions = partOptionIds.map((partOptionId) =>
        Object.assign(partOptions[partOptionId], { area })
      );

      return all.concat(areaPartOptions);
    }, [])
);

export const getActiveOrderProductProducts = createSelector(
  [getActiveOrderProductParts],
  (parts) =>
    parts.reduce(
      (all, { area, productId }) =>
        Object.assign(all, {
          [area]: productId,
        }),
      {}
    )
);

export const getActiveOrderProductProductIds = createSelector(
  [getActiveOrderProductProducts],
  (areas) => Object.values(areas)
);

export const getActiveOrderProductDefaultOptions = createSelector(
  [
    getActiveOrderProduct,
    getActiveOrderProductProducts,
    getProducts,
    getWeightedOptions,
  ],
  (activeOrderProduct, orderProducts, products, weightedOptions) =>
    Object.entries(orderProducts)
      .map(([area, productId]) => ({
        area,
        productId,
      }))
      .reduce((all, { area, productId }) => {
        const { defaultOptions = {}, defaultSizeVariantOptions = {} } =
          products[productId] || {};

        const combinedDefaultOptions = {
          ...defaultOptions,
          ...(defaultSizeVariantOptions[activeOrderProduct.sizeId] || {}),
        };

        const weightedDefaultOptions = Object.entries(
          combinedDefaultOptions
        ).reduce((allOptions, [weightedOptionId, optionWeight]) => {
          if (!weightedOptions[weightedOptionId]) return allOptions;

          const { optionCode, optionId } = weightedOptions[weightedOptionId];

          allOptions.push({
            area,
            optionCode,
            optionId,
            optionWeight,
          });

          return allOptions;
        }, []);

        return all.concat(weightedDefaultOptions);
      }, [])
);

export const getActiveOrderProductCookingInstructions = createSelector(
  [
    getActiveOrderProduct,
    getCookingInstructionGroups,
    getCookingInstructionOptions,
    getState,
  ],
  (
    activeOrderProduct,
    cookingInstructionGroups,
    cookingInstructionOptions,
    state
  ) => {
    const { baseId: baseCode, sizeId: sizeCode } = activeOrderProduct;
    const { productCode } = activeOrderProduct?.parts?.[AREAS.WHOLE];

    const variant = getVariantFromProduct({
      baseCode,
      sizeCode,
      productCode,
    })(state);

    const activeOrderProductCookingInstructions =
      activeOrderProduct.cookingInstructions.map(
        (code) => cookingInstructionOptions[code]
      );

    const allowedCookingInstructions = variant.allowedCookingInstructions.map(
      (instruction) => cookingInstructionOptions[instruction]
    );

    const allowedCookingInstructionGroups = allowedCookingInstructions.reduce(
      (all, { group, ...instruction }) => {
        if (!all[group]) {
          all[group] = {
            ...cookingInstructionGroups[group],
            instructions: [],
          };
        }

        all[group].instructions.push(instruction);
        return all;
      },
      {}
    );

    const defaultCookingInstructions = variant.defaultCookingInstructions.map(
      (instruction) => cookingInstructionOptions[instruction]
    );

    const cookingInstructions = combineCookingInstructions(
      defaultCookingInstructions,
      activeOrderProductCookingInstructions
    );

    return {
      cookingInstructionOptions,
      allowedCookingInstructionGroups,
      defaultCookingInstructions,
      cookingInstructions,
    };
  }
);

export const getActiveOrderProductOptions = (filterCallback) =>
  createSelector([getActiveOrderProductPartOptions], (partOptions) =>
    partOptions.filter(filterCallback)
  );

const filterSides = ({ optionType }) => optionType !== OPTION.TOPPING;

export const getActiveOrderProductSides = createSelector(
  [getActiveOrderProductOptions(filterSides)],
  (options) =>
    options.reduce(
      (all, { optionId, optionWeight }) =>
        Object.assign(all, {
          [optionId]: optionWeight,
        }),
      {}
    )
);

const filterToppings = ({ optionType }) => optionType === OPTION.TOPPING;

export const getActiveOrderProductToppingsWithoutDefaults = createSelector(
  [getActiveOrderProductOptions(filterToppings)],
  (options) =>
    options.reduce(
      (all, { area, optionId, optionWeight }) =>
        Object.assign(all, {
          [optionId]: Object.assign({}, all[optionId] || {}, {
            [area]: optionWeight,
          }),
        }),
      {}
    )
);

export const getActiveOrderProductToppings = createSelector(
  [
    getActiveOrderProductDefaultOptions,
    getActiveOrderProductOptions(filterToppings),
  ],
  (defaultOptions, options) =>
    defaultOptions.concat(options).reduce(
      (all, { area, optionId, optionWeight }) =>
        Object.assign(all, {
          [optionId]: Object.assign({}, all[optionId] || {}, {
            [area]: optionWeight,
          }),
        }),
      {}
    )
);

export const getActiveOrderProductSauce = (sauces = []) =>
  createSelector([getActiveOrderProductToppings], (productToppings = {}) => {
    const sauceIds = sauces.map(({ toppingId }) => toppingId);

    const foundSauceTopping = Object.entries(productToppings).find(
      ([toppingId, areasWithWeights]) =>
        sauceIds.includes(toppingId) && areasWithWeights[AREAS.WHOLE] > 0
    );

    // if no sauce with weight > 0 is found that means no sauce is selected
    if (!foundSauceTopping) {
      return {
        code: ROBUST_INSPIRED_TOMATO_SAUCE_CODE,
        weight: WEIGHTS.ZERO,
      };
    }

    const [sauceToppingId, sauceAreasWithWeights] = foundSauceTopping;
    const { toppingCode: sauceToppingCode } = sauces.find(
      (sauce) => sauceToppingId === sauce.toppingId
    );
    const sauceWeight = sauceAreasWithWeights[AREAS.WHOLE];

    return { code: sauceToppingCode, weight: sauceWeight };
  });

export const getValidCategoryIds = (ids, categoryIds) =>
  categoryIds.length > 0 ? ids.filter((id) => categoryIds.includes(id)) : ids;

const getDefaultsForBase = ({
  base,
  categoryProductIds = [],
  categorySizeIds,
  products,
  improvedSizesAndBases,
}) => {
  const { productIds = [], sizeIds = [] } = base;

  const [defaultProductId] = getValidCategoryIds(
    productIds,
    categoryProductIds
  );
  const [defaultSizeId] = improvedSizesAndBases
    ? getValidCategoryIds(
        sizeIds,
        products[defaultProductId]?.sizesByBaseId?.[base.baseCode] ?? []
      )
    : getValidCategoryIds(sizeIds, categorySizeIds);

  return [defaultProductId, defaultSizeId];
};

const getDefaultsForProduct = (
  product,
  categoryBaseIds = [],
  categorySizeIds = []
) => {
  const { baseIds = [], sizeIds = [] } = product;

  const [defaultBaseId] = getValidCategoryIds(baseIds, categoryBaseIds);
  const [defaultSizeId] = getValidCategoryIds(sizeIds, categorySizeIds);

  return [defaultBaseId, defaultSizeId];
};

const getDefaultsForSize = ({
  size,
  categoryBaseIds = [],
  categoryProductIds = [],
  products,
  randomDefaultPizzaSelection,
  improvedSizesAndBases,
}) => {
  const { baseIds = [], productIds = [] } = size;

  const defaultProductIds = getValidCategoryIds(productIds, categoryProductIds);

  const defaultProductId = randomDefaultPizzaSelection
    ? defaultProductIds[Math.floor(Math.random() * defaultProductIds.length)]
    : defaultProductIds[0];

  const [defaultBaseId] = improvedSizesAndBases
    ? getValidCategoryIds(
        baseIds,
        products[defaultProductId]?.basesBySizeId?.[size.sizeCode] ?? []
      )
    : getValidCategoryIds(baseIds, categoryBaseIds);

  return [defaultBaseId, defaultProductId];
};

export const getOrderProductWithDefaults = ({
  categoryId = "",
  suppliedProductId = "",
  suppliedSizeId = "",
  suppliedBaseId = "",
  suppliedToppingId = "",
} = {}) =>
  createSelector(
    [
      getBases,
      getCategories,
      getProducts,
      getSizes,
      getToppings,
      getNewExperienceFeature,
    ],
    (
      bases,
      categories,
      products,
      sizes,
      toppings,
      { improvedSizesAndBases, randomDefaultPizzaSelection }
    ) => {
      const category = categories[categoryId];

      const {
        baseIds: categoryBaseIds,
        productIds: categoryProductIds,
        sizeIds: categorySizeIds,
      } = category || {};

      let base = bases[suppliedBaseId];
      let size = sizes[suppliedSizeId];
      const topping = toppings[suppliedToppingId];

      if (!base && !size) {
        const [defaultCategoryProductCode] = categoryProductIds || [];

        suppliedProductId = suppliedProductId || defaultCategoryProductCode;
      }

      let product = products[suppliedProductId];

      const { baseCode: suppliedBaseCode } = base || {};
      const { productCode: suppliedProductCode } = product || {};
      const { sizeCode: suppliedSizeCode } = size || {};
      const { toppingCode: suppliedToppingCode = "" } = topping || {};

      if (product) {
        const [defaultBaseId, defaultSizeId] = getDefaultsForProduct(
          product,
          categoryBaseIds,
          categorySizeIds
        );

        base = bases[defaultBaseId];
        size = sizes[defaultSizeId];
      } else if (size) {
        const [defaultBaseId, defaultProductId] = getDefaultsForSize({
          size,
          categoryBaseIds,
          categoryProductIds,
          products,
          randomDefaultPizzaSelection,
          improvedSizesAndBases,
        });

        base = bases[defaultBaseId];
        product = products[defaultProductId];
      } else if (base) {
        const [defaultProductId, defaultSizeId] = getDefaultsForBase({
          base,
          categoryProductIds,
          categorySizeIds,
          products,
          improvedSizesAndBases,
        });

        product = products[defaultProductId];
        size = sizes[defaultSizeId];
      }

      const { baseCode: defaultBaseCode = "" } = base || {};
      const { productCode: defaultProductCode = "" } = product || {};
      const { sizeCode: defaultSizeCode = "" } = size || {};

      return {
        sizeCode: suppliedSizeCode || defaultSizeCode,
        baseCode: suppliedBaseCode || defaultBaseCode,
        productCode: suppliedProductCode || defaultProductCode,
        toppingCode: suppliedToppingCode,
      };
    }
  );

const filterOutExistingOptions =
  (options) =>
  ({ code }) =>
    !options.map(({ code: existingCode }) => existingCode).includes(code);

export const getOrderProductBody = ({
  area,
  baseCode,
  itemQuantity,
  parts = {},
  productCode,
  toppingCode,
  toppingWeight,
  sideCode,
  sideQuantity,
  sizeCode,
  cookingInstructions,
} = {}) => {
  const options = [];

  if (sideCode)
    options.push({
      code: sideCode,
      weight: sideQuantity,
    });

  if (toppingCode)
    options.push({
      code: toppingCode,
      weight: toppingWeight,
    });

  const isPartUpdate = !!productCode || options.length !== 0;

  if (isPartUpdate) {
    // Start with existing part or set up defaults
    const currentPart = parts[area] || {};
    const [defaultPart = {}] = Object.values(parts);

    const { options: copiedOptions = [], productCode: copiedProductCode = "" } =
      defaultPart;

    const {
      options: existingOptions = copiedOptions,
      productCode: existingProductCode = copiedProductCode,
    } = currentPart;

    // Create new part or update existing part for the current area
    parts = Object.assign(parts, {
      [area]: Object.assign(currentPart, {
        productCode: productCode || existingProductCode,
        options: options.concat(
          existingOptions.filter(filterOutExistingOptions(options))
        ),
      }),
    });

    // Get siblings for the current part
    const { count } = AREA[area] || AREA[defaultArea];
    const currentAreas = getAreaNames(count, false);

    // Copy properties from sibling part
    currentAreas.forEach((partArea) => {
      parts[partArea] = parts[partArea] || defaultPart;
    });

    // Remove non-sibling parts
    Object.keys(parts).forEach((partArea) => {
      if (!currentAreas.includes(partArea)) delete parts[partArea];
    });
  }

  return {
    data: {
      type: RESOURCE_TYPE.PRODUCT,
      attributes: {
        base: baseCode,
        cookingInstructions,
        parts,
        quantity: itemQuantity,
        sizeCode,
      },
    },
  };
};

const ensureAllPartsExist = (parts) => {
  const [defaultPart = {}] = Object.values(parts);
  const newParts = Object.values(AREAS).reduce((all, part) => {
    all[part] = parts[part] ?? { ...defaultPart, options: [] };
    return all;
  }, {});

  return newParts;
};

export const getOrderProductCheeseBody = ({
  areasWithWeights,
  cheeseToppingCode,
  baseCode,
  itemQuantity,
  parts,
  sizeCode,
  cookingInstructions,
} = {}) => {
  // ensure all parts exist
  let newParts = ensureAllPartsExist(parts);

  // filter cheese from all parts
  Object.keys(newParts).forEach((part) => {
    newParts[part].options = newParts[part].options.filter(
      filterTopping(cheeseToppingCode)
    );
  });

  // add cheese to parts needing to be updated
  Object.entries(areasWithWeights).forEach(([areaToUpdate, newWeight]) => {
    newParts[areaToUpdate].options.push({
      code: cheeseToppingCode,
      weight: newWeight,
    });
  });

  return {
    data: {
      type: RESOURCE_TYPE.PRODUCT,
      attributes: {
        base: baseCode,
        cookingInstructions,
        parts: newParts,
        quantity: itemQuantity,
        sizeCode,
      },
    },
  };
};

export const filterTopping = (toppingCode) => (option) =>
  toppingCode !== option.code;

export const getOrderProductToppingBody = ({
  area,
  toppingCode,
  toppingWeight,
  baseCode,
  itemQuantity,
  parts,
  sizeCode,
  isDefaultTopping,
  cookingInstructions,
} = {}) => {
  const newParts = ensureAllPartsExist(parts);

  // filter topping out of each existing area
  Object.keys(newParts).forEach((partArea) => {
    newParts[partArea].options = newParts[partArea].options.filter(
      filterTopping(toppingCode)
    );
  });

  // push updated weight to area to update
  newParts[area].options.push({
    code: toppingCode,
    weight: toppingWeight,
  });

  // if it's a default topping and half and half we also need to
  // push zero weight to the opposite side
  if (isDefaultTopping && HALF_AND_HALF_AREAS.includes(area)) {
    const oppositeArea = area === AREAS.LEFT ? AREAS.RIGHT : AREAS.LEFT;
    newParts[oppositeArea].options.push({
      code: toppingCode,
      weight: WEIGHTS.ZERO,
    });
  }

  return {
    data: {
      type: RESOURCE_TYPE.PRODUCT,
      attributes: {
        base: baseCode,
        cookingInstructions,
        parts: newParts,
        quantity: itemQuantity,
        sizeCode,
      },
    },
  };
};

export default getOrderProductWithDefaults;
