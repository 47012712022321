import { css } from "@emotion/react";

// Using pixels instead of rems here to align with foreign-object text
export const loyaltyWrapperStyles = css`
  background-color: var(--dpzBlue);
  height: 40px;
  min-width: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.8rem;
  svg {
    height: 25px;
    color: white;
  }
`;

export const customerHeaderSection = css`
  p {
    font-size: 1.2rem;
    margin: 0;
  }
  .customerName {
    color: var(--dpzRed);
    font-size: 1.4rem;
    font-weight: bold;
  }
  .sectionTitle {
    color: var(--dpzBlue);
    font-size: 1.2rem;
    font-weight: bold;
  }
  address {
    font-style: normal;
    font-size: 1.2rem;
  }
  .emphasize {
    font-weight: bold;
  }
  .warningText {
    color: var(--dpzRed);
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
  }
`;

export const rowWithLoyaltyIcon = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    height: 46px;
    margin-right: 0.5rem;
  }
`;

export const customerHeaderWrapper = css`
  display: flex;
  flex-wrap: wrap;
  background-color: var(--bg);
  width: 100%;
  gap: 1.5rem;
  padding: 1rem;
`;

export const cashLimitAndHoursSection = css`
  display: flex;
  column-gap: 2rem;
  margin: 0;
  p {
    font-size: 1rem;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin: 0;
  }
`;
