import React from "react";

import PropTypes from "prop-types";

import OrderLineItem from "./OrderLineItem";

const OrderLineItems = ({ orderCoupons, orderProducts, storeOrderId, t }) => (
  <ul
    className="grid__cell--1 grid__cell--1/2@desktop order-description"
    data-quid={`order-description-${storeOrderId}`}
  >
    {Array.isArray(orderProducts) &&
      orderProducts.map(({ name, quantity, options, id }, index) => (
        <OrderLineItem
          description={name}
          itemNumber={index + 1}
          key={id}
          quantity={quantity}
          options={options}
          t={t}
        />
      ))}

    {Array.isArray(orderCoupons) &&
      orderCoupons.map(({ name, id }, index) => (
        <OrderLineItem
          description={name}
          isCoupon
          itemNumber={index + 1}
          key={id}
          t={t}
        />
      ))}
  </ul>
);

OrderLineItems.propTypes = {
  orderCoupons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  orderProducts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.array,
      part: PropTypes.string,
    })
  ),
  storeOrderId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

OrderLineItems.defaultProps = {
  orderCoupons: [],
  orderProducts: [],
};

export default OrderLineItems;
