import { createSlice } from "@reduxjs/toolkit";

import { CARSIDE_FIELDS } from "constants/carside";

// All carside fields are required for DCD
export const REQUIRED_CARSIDE_FIELDS = Object.values(CARSIDE_FIELDS);

const initialState = {
  [CARSIDE_FIELDS.VEHICLE_MAKE]: "",
  [CARSIDE_FIELDS.VEHICLE_MODEL]: "",
  [CARSIDE_FIELDS.VEHICLE_COLOR]: "",
  [CARSIDE_FIELDS.TEXT_PHONE_NUMBER]: "",
  [CARSIDE_FIELDS.PREFERRED_LOCATION]: "",
  invalidCarsideValues: new Set(REQUIRED_CARSIDE_FIELDS),
  [CARSIDE_FIELDS.IS_TEXT_OPT_IN]: false,
};

const carsideInformationSlice = createSlice({
  name: "carsideInformation",
  initialState,
  reducers: {
    setVehicleMakeValue: (state, { payload }) => {
      state[CARSIDE_FIELDS.VEHICLE_MAKE] = payload;
    },
    setVehicleModelValue: (state, { payload }) => {
      state[CARSIDE_FIELDS.VEHICLE_MODEL] = payload;
    },
    setVehicleColorValue: (state, { payload }) => {
      state[CARSIDE_FIELDS.VEHICLE_COLOR] = payload;
    },
    setTextPhoneNumberValue: (state, { payload }) => {
      state[CARSIDE_FIELDS.TEXT_PHONE_NUMBER] = payload;
    },
    setPreferredLocation: (state, { payload }) => {
      state[CARSIDE_FIELDS.PREFERRED_LOCATION] = payload;
    },
    toggleOptIntoTexts: (state) => {
      state[CARSIDE_FIELDS.IS_TEXT_OPT_IN] = !state.textOptIn;
    },
    addInvalidCarsideValue: (state, { payload }) => {
      state.invalidCarsideValues.add(payload);
    },
    removeInvalidCarsideValue: (state, { payload }) => {
      state.invalidCarsideValues.delete(payload);
    },
    resetCarsideValues: () => initialState,
    setPreloadedVehicleValues: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const {
  setExtensionValue,
  setVehicleMakeValue,
  setVehicleModelValue,
  setVehicleColorValue,
  setTextPhoneNumberValue,
  setPreferredLocation,
  toggleOptIntoTexts,
  resetCarsideValues,
  addInvalidCarsideValue,
  removeInvalidCarsideValue,
  setPreloadedVehicleValues,
} = carsideInformationSlice.actions;

export default carsideInformationSlice.reducer;

export const selectVehicleMake = (state) =>
  state.carsideInformation[CARSIDE_FIELDS.VEHICLE_MAKE];
export const selectVehicleModel = (state) =>
  state.carsideInformation[CARSIDE_FIELDS.VEHICLE_MODEL];
export const selectVehicleColor = (state) =>
  state.carsideInformation[CARSIDE_FIELDS.VEHICLE_COLOR];
export const selectPreferredLocation = (state) =>
  state.carsideInformation[CARSIDE_FIELDS.PREFERRED_LOCATION];
export const selectTextPhoneNumber = (state) =>
  state.carsideInformation[CARSIDE_FIELDS.TEXT_PHONE_NUMBER];
export const selectOptIntoTexts = (state) =>
  state.carsideInformation[CARSIDE_FIELDS.IS_TEXT_OPT_IN];
export const selectInvalidCarsideValues = (state) =>
  state.carsideInformation.invalidCarsideValues;
