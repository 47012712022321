import { baseApi } from "./baseApi";

export const autoCouponsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAutoCoupons: builder.query({
      query: (orderId) => ({
        url: `/order-entry-service/orders/${orderId}/autoCoupons`,
      }),
    }),
  }),
});

export const { useGetAutoCouponsQuery } = autoCouponsApi;
