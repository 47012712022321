/** @jsxImportSource @emotion/react */
import { forwardRef, useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import RequiredMark from "components/RequiredMark";

import { labelSpace, visuallyHidden } from "../NewCustomerPage.styles";

const FormField = forwardRef(
  (
    {
      className = "",
      displayError = false,
      handleBlur,
      handleChange,
      hideLabel = false,
      id,
      isInvalid = false,
      label,
      required = false,
      t,
      type = "text",
      value,
      ...props
    },
    ref
  ) => {
    const [touched, setTouched] = useState(false);
    const shouldHideLabel = !value || hideLabel;
    const showError = (touched || displayError) && isInvalid;

    const handleFieldBlur = (e) => {
      setTouched(true);
      handleBlur && handleBlur(e);
    };

    return (
      <div
        className={classNames(
          "form__control-group grid__cell--1",
          className,
          showError && "error"
        )}
        data-quid={id}
      >
        <label
          className="text-field__label"
          css={shouldHideLabel && visuallyHidden}
          htmlFor={id}
          data-quid={`${id}-label`}
        >
          {required ? <RequiredMark /> : null}
          {label}
        </label>
        {shouldHideLabel && <div css={labelSpace} />}
        <input
          className={classNames(showError && "parsley-error")}
          data-quid={`${id}-input`}
          id={id}
          name={id}
          onChange={handleChange}
          onBlur={handleFieldBlur}
          placeholder={`${required ? "* " : ""}${label}`}
          type={type}
          value={value}
          required={required}
          ref={ref}
          {...props}
        />
        {showError && (
          <p className="error-message parsley-required">
            {t("negative:please_enter_a_valid_value", {
              value: label.toLowerCase(),
            })}
          </p>
        )}
      </div>
    );
  }
);

export default FormField;

FormField.propTypes = {
  className: PropTypes.string,
  displayError: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  size: PropTypes.string,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};
