import React, { useState } from "react";

import PropTypes from "prop-types";

import TypeAhead from "components/StoreLocator/Strategy/TypeAhead";

const LocalTypeAhead = ({
  className,
  disabled,
  input,
  label,
  name,
  onChange,
  onSelect,
  quidBase,
  required,
  suggestions,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const clearSuggestions = () => setShowSuggestions(false);
  const fetchPredictions = () => setShowSuggestions(!!input.length);
  const handleSelect = (suggestion) => {
    clearSuggestions();
    onSelect(suggestion);
  };

  return (
    <TypeAhead
      className={className}
      debounce={0}
      input={input}
      label={label}
      fetchPredictions={fetchPredictions}
      onChange={onChange}
      clearSuggestions={clearSuggestions}
      handleSelect={handleSelect}
      name={name}
      quidBase={quidBase}
      suggestions={
        showSuggestions
          ? suggestions.filter(({ description }) =>
              description.toLowerCase().includes(input.toLowerCase())
            )
          : []
      }
      disabled={disabled}
      required={required}
    />
  );
};
LocalTypeAhead.propTypes = {
  className: PropTypes.string,
  input: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  quidBase: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string.isRequired })
  ).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

LocalTypeAhead.defaultProps = {
  className: "",
  disabled: false,
  input: "",
  label: "",
  name: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    process.env.NODE_ENV !== "production" && console.error(error);
  },
  required: false,
};

export default LocalTypeAhead;
