import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const NavItem = ({
  className,
  dropdown,
  icon,
  id,
  quidBase,
  text,
  type,
  onClick,
}) => {
  switch (type) {
    case "button":
      return (
        <button
          className={classNames(
            "nav__item nav__item--button",
            dropdown && "dropdown",
            className
          )}
          data-hook="toggle-dropdown"
          data-icon={icon}
          data-quid={quidBase}
          title={text}
          onClick={onClick}
        >
          {dropdown && (
            <div className="dropdown__body">
              <ul className="list">
                {dropdown.map(({ action, languageCode, title }) => (
                  <li key={title} title={title}>
                    <a
                      data-quid={`${quidBase}-${languageCode}`}
                      href={`#${title}`}
                      onClick={action}
                    >
                      {title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </button>
      );

    case "search":
      return (
        <div className={classNames("nav__item", className)}>
          <input
            data-quid={`${quidBase}-input`}
            id={id}
            name={id}
            placeholder={text}
            type={type}
          />
        </div>
      );

    default:
      return (
        <span
          className={classNames("nav__item", className)}
          data-icon={icon}
          data-quid={quidBase}
        >
          <strong>{text}</strong>
        </span>
      );
  }
};

NavItem.propTypes = {
  className: PropTypes.string,
  dropdown: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      title: PropTypes.string,
    })
  ),
  icon: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  quidBase: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(["button", "label", "search"]),
};

NavItem.defaultProps = {
  className: "",
  dropdown: null,
  icon: "",
  id: "",
  onClick: () => {},
  text: "",
  type: "label",
};

export default NavItem;
