// Based off of NOLO CC validation
export default (value) => {
  if (/^(5[12345])/.test(value)) {
    // Mastercard
    return value.length === 16;
  }
  if (/^(4)/.test(value)) {
    // Visa
    return value.length === 16;
  }
  if (/^(3[47])/.test(value)) {
    // Amex
    return value.length === 15;
  }
  if (/^(3(0[012345]|[68]))/.test(value)) {
    // Dinersclub
    return value.length === 14;
  }
  if (/^(2(014|149))/.test(value)) {
    // Enroute
    return value.length === 15;
  }
  if (/^(6011)/.test(value)) {
    // Discover
    return value.length === 16;
  }
  if (/^(3)/.test(value)) {
    // Jcb
    return value.length === 16;
  }
  if (/^(2131|1800)/.test(value)) {
    // Jcb
    return value.length === 15;
  }
  return false;
};
