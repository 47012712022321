import { ONLINE_STATUS, SORT_BY, STORE_STATUS } from "constants/store";

export const isStoreOpen = ({ storeStatus = STORE_STATUS.CLOSED }) =>
  storeStatus === STORE_STATUS.OPEN;

export const isStoreOnline = ({ onlineStatus = ONLINE_STATUS.OFFLINE }) =>
  onlineStatus === ONLINE_STATUS.ONLINE;

const sortByConnectivity = ([, a], [, b]) =>
  isStoreOnline(b) - isStoreOnline(a) ||
  isStoreOpen(b) - isStoreOpen(a) ||
  a.sortOrder - b.sortOrder;

const sortByDistance = ([, { distance: a }], [, { distance: z }]) => a - z;

export const sortStores = (
  stores,
  { sortPreference = SORT_BY.CONNECTIVITY } = {}
) =>
  stores.sort(
    sortPreference === SORT_BY.CONNECTIVITY
      ? sortByConnectivity
      : sortByDistance
  );

export default sortStores;
