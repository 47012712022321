import { createSelector } from "reselect";

export const getVariants = ({ variants = {} } = {}) => variants;

export const getVariantKey = ({ sizeCode, baseCode, productCode }) =>
  [sizeCode, baseCode, productCode].filter(Boolean).join("_");

export const getVariantFromProduct = ({ sizeCode, baseCode, productCode }) =>
  createSelector([getVariants], (variants) => {
    const key = getVariantKey({ sizeCode, baseCode, productCode });
    if (!variants[key]) {
      return { allowedCookingInstructions: [], defaultCookingInstructions: [] };
    }
    return variants[key];
  });

export const getVariantCodeFromProduct = ({
  sizeCode,
  baseCode,
  productCode,
}) =>
  createSelector([getVariants], (variants) => {
    const key = getVariantKey({ sizeCode, baseCode, productCode });
    if (!variants[key]) {
      return "";
    }
    return variants[key].code;
  });
