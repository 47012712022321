import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/street-categories/";

export const GET_STREET_CATEGORIES = `${SCOPE}GET_STREET_CATEGORIES`;
export const GET_STREET_CATEGORIES_ERROR = `${SCOPE}GET_STREET_CATEGORIES_ERROR`;
export const SET_STREET_CATEGORIES = `${SCOPE}SET_STREET_CATEGORIES`;
export const SET_STREET_CATEGORY = `${SCOPE}SET_STREET_CATEGORY`;

export function getStreetCategories({ url } = {}) {
  return {
    type: GET_STREET_CATEGORIES,
    url,
  };
}

export function getStreetCategoriesError(error) {
  return {
    type: GET_STREET_CATEGORIES_ERROR,
    error,
  };
}

export function setStreetCategories(
  streetCategories = { data: { attributes: { streetCategories: {} } } }
) {
  return {
    type: SET_STREET_CATEGORIES,
    streetCategories,
  };
}

export const initialState = {
  data: { attributes: { streetCategories: {} } },
  value: "",
};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_STREET_CATEGORIES:
      return { ...state, ...action.streetCategories };
    default:
      return state;
  }
}

export const getStreetCategoriesEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_STREET_CATEGORIES),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        map(setStreetCategories),
        catchError((error) => of(getStreetCategoriesError(error)))
      )
    )
  );

export const epics = combineEpics(getStreetCategoriesEpic);
