import React, { PureComponent } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import ExpandedOrderConfirmationMessage from "./ExpandedOrderConfirmationMessage";

class Overlay extends PureComponent {
  constructor() {
    super();

    this.state = {
      hidden: true,
      mounting: null,
    };

    this.hideMessage = this.hideMessage.bind(this);
  }

  componentDidMount() {
    //eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      mounting: setTimeout(
        () =>
          this.setState({
            hidden: false,
          }),
        100
      ),
    });
  }

  componentWillUnmount() {
    clearTimeout(this.state.mounting);
  }

  hideMessage() {
    this.setState({
      hidden: true,
      mounting: setTimeout(this.props.unrender, 100),
    });
  }

  render() {
    const { hideMessage } = this;
    const { hidden } = this.state;
    const {
      confirmText,
      message,
      title,
      showExpandedOrderConfirmationMessage,
      expandedOrderConfirmationMessageData,
      t,
    } = this.props;

    return (
      !hidden && (
        <div
          className={classNames(hidden && "is-hidden")}
          data-hook="overlay-card-container"
        >
          <div
            className="card card--pop card--overlay"
            data-quid="card-overlay"
          >
            <header className="card__header">
              <h2 className="card__title" data-quid="title">
                {title}
              </h2>
            </header>

            <section
              className="card__body"
              data-hook="overlay-body"
              data-quid="message"
            >
              {showExpandedOrderConfirmationMessage && (
                <ExpandedOrderConfirmationMessage
                  t={t}
                  {...expandedOrderConfirmationMessageData}
                />
              )}
              {!showExpandedOrderConfirmationMessage && <p>{message}</p>}
            </section>

            <footer className="card__footer">
              <button
                type="button"
                className="btn btn--icon-only card--overlay__close"
                data-hook="close-overlay"
                data-icon="close"
                data-quid="close"
                onClick={hideMessage}
              />

              <button
                type="button"
                className="btn btn--primary"
                data-hook="close-overlay"
                data-icon="thumb_up"
                data-quid="confirm"
                onClick={hideMessage}
              >
                {confirmText}
              </button>
            </footer>
          </div>

          <div
            className="overlay--matte"
            data-hook="close-overlay"
            data-quid="card-overlay-matte"
          />
        </div>
      )
    );
  }
}

Overlay.propTypes = {
  confirmText: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  unrender: PropTypes.func,
  showExpandedOrderConfirmationMessage: PropTypes.bool,
  expandedOrderConfirmationMessageData: PropTypes.shape({
    customerName: PropTypes.string,
    customerAddress: PropTypes.string,
    storeAddress: PropTypes.string,
    isDCD: PropTypes.bool,
    isCarplay: PropTypes.bool,
    storeOrderId: PropTypes.string,
    receiptQuantity: PropTypes.number,
  }),
};

Overlay.defaultProps = {
  confirmText: "OK",
  message: "",
  title: "",
  unrender: () => {},
  t: () => {},
};

export default Overlay;
