import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isHalfAndHalf: [],
};

const pizzaSlice = createSlice({
  name: "pizza",
  initialState,
  reducers: {
    setIsHalfAndHalf: (state, { payload }) => {
      const { productId, isHalfAndHalf } = payload;

      state.isHalfAndHalf = isHalfAndHalf
        ? Array.from(new Set([...state.isHalfAndHalf, productId]))
        : state.isHalfAndHalf.filter((pI) => !pI);
    },
  },
});

export const { setIsHalfAndHalf } = pizzaSlice.actions;

export default pizzaSlice.reducer;

// Selectors
export const selectIsHalfAndHalf = (productId) => (state) =>
  state.pizza.isHalfAndHalf.includes(productId);
