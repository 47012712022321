import { css } from "@emotion/react";

export const toppingOptionWrapperStyles = css`
  display: flex;
  flex-direction: column;
`;

export const toppingAreaWrapperStyles = css`
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
`;

export const toppingButtonStyles = css`
  padding: 1rem;
`;

export const toppingAreaButtonWrapperStyles = css`
  display: flex;
  gap: 0.5rem;
  flex: 1;
  justify-content: space-around;
`;

export const areaButtonStyles = (selected) => css`
  padding: 0.5rem 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    transition: 0.1s ease;
    width: 2rem;
    color: ${selected ? `var(--dpzBlue)` : `var(--subtleBg)`};
  }

  &:hover svg {
    color: ${selected ? `var(--dpzBlue)` : `var(--subtle)`};
  }
`;
