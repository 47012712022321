import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getNewExperienceFeature } from "ducks/market/selectors";
import {
  getActiveOrderProductAvailableOptions,
  getActiveOrderProductAvailableOptionsByArea,
} from "ducks/order/selectors";

import categorizeToppings from "modules/categorizeToppings";

import NewPizzas from "./NewPizzas";
import OldPizzas from "./OldPizzas";

const Pizzas = ({ toppings, ...props }) => {
  const { newPizzaBuilder: useNewPizzaPage } = useSelector(
    getNewExperienceFeature
  );

  const activeOrderProductAvailableOptions = useSelector(
    getActiveOrderProductAvailableOptions
  );
  const activeOrderProductAvailableOptionsByArea = useSelector(
    getActiveOrderProductAvailableOptionsByArea
  );

  const { meats, nonMeats, sauces } = categorizeToppings(
    toppings.filter(({ toppingId }) =>
      Object.keys(
        useNewPizzaPage
          ? activeOrderProductAvailableOptions
          : activeOrderProductAvailableOptionsByArea
      ).includes(toppingId)
    )
  );
  const availableToppings = { meats, nonMeats, sauces };

  return useNewPizzaPage ? (
    <NewPizzas {...props} {...availableToppings} />
  ) : (
    <OldPizzas
      {...props}
      {...availableToppings}
      activeOrderProductAvailableOptions={
        activeOrderProductAvailableOptionsByArea
      }
    />
  );
};

Pizzas.propTypes = {
  toppings: PropTypes.array.isRequired,
};

export default Pizzas;
