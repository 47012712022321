import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/region/";

export const GET_REGIONS = `${SCOPE}GET_REGIONS`;
export const GET_REGIONS_ERROR = `${SCOPE}GET_REGIONS_ERROR`;
export const SET_REGIONS = `${SCOPE}SET_REGIONS`;

export function getRegions({ marketCode = "" } = {}) {
  return {
    type: GET_REGIONS,
    power: true,
    url: `/country/CountryLocator/country/${marketCode}`,
  };
}

export function getRegionsError(error) {
  return {
    type: GET_REGIONS_ERROR,
    error,
  };
}

export function setRegions(regions = {}) {
  return {
    type: SET_REGIONS,
    regions,
  };
}

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_REGIONS:
      return action.regions;
    default:
      return state;
  }
}

export const getRegionsEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_REGIONS),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "region"),
        map((regions) =>
          setRegions(
            regions.reduce(
              (all, { name, code }) =>
                Object.assign(all, {
                  [code]: name,
                }),
              {}
            )
          )
        ),
        catchError((error) => of(getRegionsError(error)))
      )
    )
  );

export const epics = combineEpics(getRegionsEpic);
