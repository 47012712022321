import { SET_MENU } from "ducks/menu";
import { getAreaNames } from "ducks/menu/helpers";

import { defaultArea } from "constants/order";

export const SET_ACTIVE_AREA = "order-entry/area/SET_ACTIVE_AREA";

export function setActiveArea({ area } = {}) {
  return {
    type: SET_ACTIVE_AREA,
    area,
  };
}

export const initialState = [];

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_MENU:
      return getAreaNames(
        Object.values(action.products).map(({ partCount }) => partCount)
      ).map((area) => ({
        active: area === defaultArea,
        area,
      }));
    case SET_ACTIVE_AREA:
      return state.reduce(
        (all, { area }) =>
          all.concat({
            active: area === action.area,
            area,
          }),
        []
      );
    default:
      return state;
  }
}
