import { createAddressSuccess } from "ducks/address";
import { getProductCategoryId } from "ducks/menu/selectors";
import { createOrder } from "ducks/order";
import { getPartProductCode } from "ducks/order/helpers";
import { createOrderCouponSuccess } from "ducks/orderCoupon";
import { isFulfilled } from "ducks/orderCoupon/helpers";
import { createOrderProductSuccess } from "ducks/orderProduct";
import { setStore } from "ducks/store";
import { getStoreIds } from "ducks/store/selectors";

import { ORDER_COMPLETION_STATUS, SERVICE_METHOD } from "constants/order";
import { RESOURCE_TYPE } from "constants/resource";
import removeEmptyProps from "modules/removeEmptyProps";
import uuid from "modules/uuid";

export const normalizeServiceMethod = (
  serviceMethod = SERVICE_METHOD.CARRYOUT
) => {
  switch (
    typeof serviceMethod === "string" ? serviceMethod.toUpperCase() : ""
  ) {
    case SERVICE_METHOD.DELIVERY.toUpperCase():
      return SERVICE_METHOD.DELIVERY;
    case SERVICE_METHOD.DINE_IN.toUpperCase():
      return SERVICE_METHOD.DINE_IN;
    default:
      return SERVICE_METHOD.CARRYOUT;
  }
};

const assignNormalizedServiceMethod = ({
  attributes: { serviceMethod, ...attributes } = {},
  ...pastOrder
} = {}) =>
  Object.assign(pastOrder, {
    attributes: Object.assign({}, attributes, {
      serviceMethod: normalizeServiceMethod(serviceMethod),
    }),
  });

export const normalizeServiceMethods = (pastOrders = []) =>
  pastOrders.map(assignNormalizedServiceMethod);

export const getSetStoreAction = ({ state, store } = {}) => {
  const { onlineNow, open, storeId } = store || {};

  const existingStoreIds = getStoreIds(state);
  const storeExists = existingStoreIds.includes(storeId);

  return storeExists
    ? undefined
    : setStore(
        Object.assign(
          {
            isOnlineNow: onlineNow,
            isOpen: open,
            selected: false,
          },
          store
        )
      );
};

export const getCreateOrderAction = ({
  id,
  links,
  orderDateTime,
  phoneNumber,
  serviceMethod,
  store,
  storeOrderId,
} = {}) => {
  const { storeId } = store || {};

  return createOrder({
    links,
    orderDateTime,
    orderId: id,
    orderStatus: ORDER_COMPLETION_STATUS.FULFILLED,
    relationships: {
      customer: {
        data: {
          id: phoneNumber,
          type: RESOURCE_TYPE.CUSTOMER,
        },
      },
    },
    serviceMethod,
    storeId,
    storeOrderId,
  });
};

const getAddress = ({ deliveryAddress, serviceMethod, store } = {}) => {
  const useStoreAddress = serviceMethod !== SERVICE_METHOD.DELIVERY;
  const { city, region } = store || {};

  return removeEmptyProps(
    useStoreAddress
      ? {
          city,
          region,
        }
      : deliveryAddress
  );
};

export const getCreateAddressSuccessAction = ({ id, ...props } = {}) =>
  createAddressSuccess(
    Object.assign(
      {
        active: false,
        addressId: uuid(),
        orderId: id,
      },
      getAddress(props)
    )
  );

function mapProducts(product, index) {
  const { orderId, state } = this;
  const { base, parts, price, quantity, sizeCode } = product;

  return createOrderProductSuccess({
    active: false,
    baseId: base,
    categoryId: getProductCategoryId(getPartProductCode(parts))(state),
    itemPrice: price,
    itemQuantity: quantity,
    lineNumber: index + 1,
    orderId,
    parts,
    sizeId: sizeCode,
  });
}

function mapCoupons(coupon) {
  const { isPastOrder, orderId } = this;
  const { couponCode, statusItems } = coupon;

  return createOrderCouponSuccess({
    couponCode,
    fulfilled: isFulfilled(statusItems || []),
    isPastOrder,
    orderCouponId: uuid(),
    orderId,
  });
}

export const getCreateOrderProductSuccessActions = ({
  id,
  products,
  state,
} = {}) =>
  products ? products.map(mapProducts, { orderId: id, state }) : undefined;

export const getCreateOrderCouponSuccessActions = ({
  id,
  isPastOrder,
  coupons,
} = {}) =>
  coupons ? coupons.map(mapCoupons, { orderId: id, isPastOrder }) : undefined;

export default normalizeServiceMethod;
