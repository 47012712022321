import { css } from "@emotion/react";

export default css`
  @media (max-width: 60rem) {
    .app {
      .app__body {
        .body__page {
          .page__sidebar {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            height: 100%;
            left: 0;
            position: absolute;
            transform: translate(-100%);
            width: 50%;
            z-index: 1;

            .sidebar__drop {
              height: 100%;
              transform: translate(100%);
              width: 100%;
            }

            &.is-open {
              transform: translate(0);

              .sidebar__drop {
                opacity: 1;
              }
            }
          }

          .page__section {
            .section__content {
              display: unset;

              .content__pane,
              > .subsection {
                &:not(.content__pane--split) {
                  box-sizing: border-box;
                  display: block;
                  height: unset;
                }
              }
            }
          }
        }
      }
    }

    // FO Adjustments

    .grid {
      .section:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
`;
