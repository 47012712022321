import { css } from "@emotion/react";

export const container = css`
  border: 2px solid var(--stjude-bg);
  border-radius: 2px;
  padding: 1em;
  margin: 0.5em 0;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export const title = css`
  color: var(--stjude-fg);
  text-align: center;
`;

export const copyWrapper = css`
  display: flex;
  align-items: flex-start;
`;

export const img = css`
  width: 8em;
  height: auto;
`;

export const button = css`
  width: 100%;
  background-color: var(--stjude-bg);
  color: black;
  text-transform: uppercase;
  font-family: PizzaPress, "Arial Narrow", Arial, Helvetica, sans-serif;
  border: none;
  letter-spacing: 0.025em;
`;
