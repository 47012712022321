/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { ROUTE_TITLES } from "routes";

import NewOrderHistory from "components/NewOrderHistory";
import Page from "components/Page";

import { customerPageWrapper, singleColumn } from "./NewCustomerPage.styles";

import CustomerHeader from "./CustomerHeader";
import CustomerSection from "./CustomerSection/index";
import StoreSection from "./StoreSection";

const NewCustomerPage = ({ t, sidebar }) => {
  return (
    <Page
      id="customer"
      sidebar={sidebar}
      header={<CustomerHeader t={t} />}
      seoTitle={ROUTE_TITLES.CUSTOMER}
    >
      <div css={customerPageWrapper}>
        <div css={singleColumn}>
          <CustomerSection t={t} />
          <NewOrderHistory t={t} />
        </div>
        <div css={singleColumn}>
          <StoreSection t={t} />
        </div>
      </div>
    </Page>
  );
};

export default NewCustomerPage;

NewCustomerPage.propTypes = {
  sidebar: PropTypes.node,
  t: PropTypes.func.isRequired,
};
