import { createSelector } from "reselect";

import { getLinkFromRelationship } from "../../selectors/related";
import { createAddressSuccess } from "ducks/address";
import { lookupCustomer, updateCustomerSuccess } from "ducks/customer";
import { getProductCategoryId } from "ducks/menu/selectors";
import { createOrder, getOrderFinish } from "ducks/order";
import { createOrderCouponSuccess } from "ducks/orderCoupon";
import { isFulfilled } from "ducks/orderCoupon/helpers";
import {
  createOrderProduct,
  createOrderProductSuccess,
} from "ducks/orderProduct";
import { getCustomersLink, getStoresLink } from "ducks/root/selectors";
import { getStore } from "ducks/store";
import { locateStores } from "ducks/storeLocation";
import {
  getDataId,
  getDataIdFromRelationship,
  getIncludedResourceByType,
  getIncludedResourcesByType,
} from "selectors/related";

import {
  STJUDE_CATEGORY_ID,
  STJUDE_PRODUCT_CODE,
  STJUDE_ROUND_UP_SIZE_CODE,
} from "constants/menu";
import { ORDER_TIMING } from "constants/order";
import { RESOURCE_TYPE } from "constants/resource";
import { formatTime } from "modules/formatDateTime";
import hasProps from "modules/hasProps";
import removeEmptyProps from "modules/removeEmptyProps";
import sortByProp from "modules/sortByProp";

export const getPartProductCode = (parts = {}) => {
  if (!hasProps(parts)) return "";

  const [{ productCode }] = Object.values(parts);
  return productCode;
};

export const getGetStoreAction =
  ({ setUserSelected = false } = {}) =>
  ({ data = {}, state = {} } = {}) => {
    const storeId = getDataIdFromRelationship("store")(data);
    if (!storeId) return undefined;

    return getStore({
      setAsActive: true,
      setUserSelected,
      url: `${getStoresLink(state)}/${storeId}`,
    });
  };

export const getCreateOrderAction = ({
  data: order = {},
  included = [],
} = {}) => {
  const { id: orderId } = order;

  const [serviceMethod, storeId] = ["serviceMethod", "store"].map((type) =>
    getDataIdFromRelationship(type)(order)
  );

  const { attributes: { futureTime } = {} } =
    getIncludedResourceByType(included, RESOURCE_TYPE.FUTURE_TIME) || {};

  let orderTiming;
  let time;

  if (futureTime) {
    orderTiming = ORDER_TIMING.LATER;
    time = formatTime(new Date(futureTime), "HH:MM", true);
  }

  return createOrder(
    Object.assign(order, {
      futureTime,
      orderId,
      orderTiming,
      serviceMethod,
      storeId,
      time,
    })
  );
};

export const getIncludedAddress = ({ included = [] } = {}) => {
  const {
    attributes: addressAttributes = {},
    id: addressId = "",
    ...address
  } = getIncludedResourceByType(included, RESOURCE_TYPE.ADDRESS);

  const { region: regionCode } = addressAttributes;
  addressAttributes.regionCode = regionCode;

  return Object.assign(
    {
      addressAttributes,
      addressId,
    },
    address
  );
};

export const getCreateAddressSuccessAction = createSelector(
  [getIncludedAddress, ({ data: order = {} } = {}) => order],
  ({ addressAttributes, addressId, ...address }, { id: orderId }) =>
    addressId
      ? createAddressSuccess(
          Object.assign(address, addressAttributes, {
            active: true,
            addressId,
            orderId,
          })
        )
      : undefined
);

export const getLocateStoresAction = createSelector(
  [getIncludedAddress],
  ({ addressAttributes, addressId }) =>
    addressId
      ? locateStores({
          executeQuery: false,
          query: removeEmptyProps(addressAttributes),
          setOrderStore: false,
        })
      : undefined
);

export const getIncludedCustomer = ({ included = [] } = {}) => {
  const {
    attributes: customerAttributes = {},
    id: phone = "",
    ...customer
  } = getIncludedResourceByType(included, RESOURCE_TYPE.CUSTOMER);

  return Object.assign(
    {
      customerAttributes,
      phone,
    },
    customer
  );
};

export const getUpdateCustomerSuccessAction = createSelector(
  [getIncludedCustomer],
  ({ customerAttributes, phone, ...customer }) =>
    updateCustomerSuccess(
      Object.assign(customer, customerAttributes, {
        phone,
      })
    )
);

export const getLookupCustomerAction = createSelector(
  [getIncludedCustomer, ({ state = {} } = {}) => state],
  ({ phone, prefix }, state) =>
    lookupCustomer({
      doUpdate: false,
      phone,
      prefix,
      url: getCustomersLink(state),
    })
);

function mapProducts({
  id = "",
  attributes: {
    base = "",
    parts = {},
    price = "",
    quantity = "0",
    sizeCode = "",
    cookingInstructions = [],
  } = {},
  type = {},
  ...orderProduct
}) {
  const { orderId = "", state = {} } = this || {};

  return createOrderProductSuccess(
    Object.assign(orderProduct, {
      active: false,
      baseId: base,
      cookingInstructions,
      categoryId: getProductCategoryId(getPartProductCode(parts))(state),
      itemPrice: price,
      itemQuantity: quantity,
      orderId,
      orderProductId: id,
      parts,
      resource: { id, type },
      sizeId: sizeCode,
    })
  );
}

export const getCreateOrderProductSuccessActions =
  ({ stJudeRoundUp = false } = {}) =>
  ({ data = {}, included = [], state = {} } = {}) => {
    const { id: orderId = "", relationships } = data;

    const products = getIncludedResourcesByType(
      included,
      RESOURCE_TYPE.PRODUCT
    );

    const mappedProducts = products
      .sort(sortByProp("id"))
      .map(mapProducts, { orderId, state })
      .concat(
        stJudeRoundUp
          ? createOrderProduct({
              orderId,
              categoryId: STJUDE_CATEGORY_ID,
              productId: STJUDE_PRODUCT_CODE,
              sizeId: STJUDE_ROUND_UP_SIZE_CODE,
              skipSetActiveOrderProduct: true,
              priceOrder: true,
              url: getLinkFromRelationship("products")({ relationships }),
            })
          : []
      );

    return products ? mappedProducts : undefined;
  };

function mapCoupons({
  id = {},
  attributes: { couponCode, description, name, statusItems = [] } = {},
  links = {},
  relationships = {},
}) {
  const storeId = getDataIdFromRelationship("store")({ relationships });
  const { orderId = "" } = this || {};

  return createOrderCouponSuccess({
    couponCode,
    description,
    fulfilled: isFulfilled(statusItems || []),
    links,
    name,
    orderCouponId: id,
    orderId,
    relationships,
    storeId,
  });
}

export const getCreateOrderCouponSuccessActions = ({
  data = {},
  included = [],
} = {}) => {
  const { id: orderId = "" } = data;
  const coupons = getIncludedResourcesByType(included, RESOURCE_TYPE.COUPON);

  return coupons ? coupons.map(mapCoupons, { orderId }) : undefined;
};

export const getOrderFinishAction =
  ({ skipPriceOrder = false } = {}) =>
  ({ data: order }) =>
    getOrderFinish({
      skipPriceOrder,
      orderId: getDataId(order),
      priceOrderLink: getLinkFromRelationship("priceOrder")(order),
    });

export default getPartProductCode;
