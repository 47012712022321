/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  resetCustomerPage,
  selectPossibleCustomerList,
  setSelectedCustomerId,
} from "rtk_redux/slices/customerPageSlice";

import Modal from "components/Modal";
import { modalActions, modalTitle } from "components/Modal/Modal.styles";

import { ReactComponent as LoyaltyIcon } from "assets/images/loyalty_icon.svg";

import {
  customerOptionWrapperStyles,
  loyaltyIconStyles,
  selectModalStyles,
} from "./CustomerSelection.styles";

const CustomerSelection = ({ t }) => {
  const dispatch = useDispatch();
  const possibleCustomerList = useSelector(selectPossibleCustomerList);
  const shouldShowModal = Boolean(possibleCustomerList.length);

  const customerOptions = possibleCustomerList.map(({ attributes, id }) => {
    const { firstName, lastName, loyalty } = attributes;
    const name = `${firstName} ${lastName}`;
    return {
      value: id,
      label: name,
      loyalty,
    };
  });

  const formatCustomerOptions = ({ label, loyalty }) => (
    <div
      css={customerOptionWrapperStyles}
      data-quid="customer-selection-option"
    >
      <div>{label}</div>
      <div>{loyalty && <LoyaltyIcon css={loyaltyIconStyles} />}</div>
    </div>
  );

  return (
    <Modal open={shouldShowModal}>
      <div css={selectModalStyles}>
        <h2 css={modalTitle}>{t("customer:profile.select.title")}</h2>
        <p>{t("customer:profile.select.message")}</p>
        <Select
          id="customer"
          placeholder={t("customer:profile.select.label")}
          formatOptionLabel={formatCustomerOptions}
          options={customerOptions}
          onChange={({ value }) => dispatch(setSelectedCustomerId(value))}
        />
        <div css={modalActions}>
          <button
            className="btn btn--fill"
            onClick={() => dispatch(resetCustomerPage())}
          >
            {t("shared:action.cancel")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerSelection;

CustomerSelection.propTypes = {
  t: PropTypes.func,
};
