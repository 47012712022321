export const BYO = Object.freeze({
  CATEGORY_CODE: "BuildYourOwn",
  CATEGORY_NAME: "pizzas:build_your_own",
  PRODUCT_CODE: "S_PIZZA",
});

export const SIZES = Object.freeze({
  LARGE: "14",
});

export const BASES = Object.freeze({
  HANDTOSS: "HANDTOSS",
});

export default BYO;
