export const CARSIDE_FIELDS = Object.freeze({
  VEHICLE_COLOR: "color",
  VEHICLE_MODEL: "model",
  VEHICLE_MAKE: "make",
  TEXT_PHONE_NUMBER: "optInPhoneNumber",
  PREFERRED_LOCATION: "orderPlacement",
  IS_TEXT_OPT_IN: "textOptIn",
});

export const PREFERRED_LOCATIONS = Object.freeze({
  DRIVER_SIDE: "Driver Side",
  PASSENGER_SIDE: "Passenger Side",
  TRUNK: "Trunk",
  BACK_SEAT: "Back Seat",
});

export const PREFERRED_LOCATION_OPTIONS = Object.freeze([
  {
    value: PREFERRED_LOCATIONS.DRIVER_SIDE,
    label: "customer:profile.vehicle.preferred_locations.driver_side",
  },
  {
    value: PREFERRED_LOCATIONS.PASSENGER_SIDE,
    label: "customer:profile.vehicle.preferred_locations.passenger_side",
  },
  {
    value: PREFERRED_LOCATIONS.TRUNK,
    label: "customer:profile.vehicle.preferred_locations.trunk",
  },
  {
    value: PREFERRED_LOCATIONS.BACK_SEAT,
    label: "customer:profile.vehicle.preferred_locations.back_seat",
  },
]);

export const VEHICLE_COLORS = Object.freeze({
  BLACK: "Black",
  BLUE: "Blue",
  BROWN: "Brown",
  GOLD: "Gold",
  GRAY: "Gray",
  GREEN: "Green",
  ORANGE: "Orange",
  PURPLE: "Purple",
  RED: "Red",
  SILVER: "Silver",
  TAN: "Tan",
  WHITE: "White",
  YELLOW: "Yellow",
  OTHER: "Other",
});

export const VEHICLE_COLOR_OPTIONS = Object.freeze([
  {
    value: VEHICLE_COLORS.BLACK,
    label: "customer:profile.vehicle.colors.black",
  },
  {
    value: VEHICLE_COLORS.BLUE,
    label: "customer:profile.vehicle.colors.blue",
  },
  {
    value: VEHICLE_COLORS.BROWN,
    label: "customer:profile.vehicle.colors.brown",
  },
  {
    value: VEHICLE_COLORS.GOLD,
    label: "customer:profile.vehicle.colors.gold",
  },
  {
    value: VEHICLE_COLORS.GRAY,
    label: "customer:profile.vehicle.colors.gray",
  },
  {
    value: VEHICLE_COLORS.GREEN,
    label: "customer:profile.vehicle.colors.green",
  },
  {
    value: VEHICLE_COLORS.ORANGE,
    label: "customer:profile.vehicle.colors.orange",
  },
  {
    value: VEHICLE_COLORS.PURPLE,
    label: "customer:profile.vehicle.colors.purple",
  },
  {
    value: VEHICLE_COLORS.RED,
    label: "customer:profile.vehicle.colors.red",
  },
  {
    value: VEHICLE_COLORS.SILVER,
    label: "customer:profile.vehicle.colors.silver",
  },
  {
    value: VEHICLE_COLORS.TAN,
    label: "customer:profile.vehicle.colors.tan",
  },
  {
    value: VEHICLE_COLORS.WHITE,
    label: "customer:profile.vehicle.colors.white",
  },
  {
    value: VEHICLE_COLORS.YELLOW,
    label: "customer:profile.vehicle.colors.yellow",
  },
  {
    value: VEHICLE_COLORS.OTHER,
    label: "customer:profile.vehicle.other",
  },
]);

export const VEHICLE_MAKES = Object.freeze({
  ACURA: "Acura",
  ALFA_ROMEO: "Alfa Romeo",
  ASTON_MARTIN: "Aston Martin",
  AUDI: "Audi",
  BENTLY: "Bentley",
  BMW: "BMW",
  BUGATTI: "Bugatti",
  BUICK: "Buick",
  CADILLAC: "Cadillac",
  CHEVROLET: "Chevrolet",
  CHRYSLER: "Chrysler",
  DODGE: "Dodge",
  FERRARI: "Ferrari",
  FIAT: "Fiat",
  FORD: "Ford",
  GENERAL_MOTORS: "General Motors",
  GMC: "GMC",
  HONDA: "Honda",
  HYUNDAI: "Hyundai",
  INFINITI: "Infiniti",
  JAGUAR: "Jaguar",
  JEEP: "Jeep",
  KIA: "Kia",
  LAND_ROVER: "Land Rover",
  LEXUS: "Lexus",
  LINCOLN: "Lincoln",
  MASERATI: "Maserati",
  MAZDA: "Mazda",
  MERCEDES_BENZ: "Mercedes-Benz",
  MINI: "Mini",
  MITSUBISHI: "Mitsubishi",
  NISSAN: "Nissan",
  OLDSMOBILE: "Oldsmobile",
  PONTIAC: "Pontiac",
  PORSCHE: "Porsche",
  RAM: "Ram",
  SAAB: "Saab",
  SUBARU: "Subaru",
  TESLA: "Tesla",
  TOYOTA: "Toyota",
  VOLKSWAGEN: "Volkswagen",
  VOLVO: "Volvo",
  OTHER: "Other",
});

export const VEHICLE_MAKE_OPTIONS = Object.freeze([
  {
    value: VEHICLE_MAKES.ACURA,
    label: "customer:profile.vehicle.makes.acura",
  },
  {
    value: VEHICLE_MAKES.ALFA_ROMEO,
    label: "customer:profile.vehicle.makes.alfa_romeo",
  },
  {
    value: VEHICLE_MAKES.ASTON_MARTIN,
    label: "customer:profile.vehicle.makes.aston_martin",
  },
  {
    value: VEHICLE_MAKES.AUDI,
    label: "customer:profile.vehicle.makes.audi",
  },
  {
    value: VEHICLE_MAKES.BENTLY,
    label: "customer:profile.vehicle.makes.bentley",
  },
  {
    value: VEHICLE_MAKES.BMW,
    label: "customer:profile.vehicle.makes.bmw",
  },
  {
    value: VEHICLE_MAKES.BUGATTI,
    label: "customer:profile.vehicle.makes.bugatti",
  },
  {
    value: VEHICLE_MAKES.BUICK,
    label: "customer:profile.vehicle.makes.buick",
  },
  {
    value: VEHICLE_MAKES.CADILLAC,
    label: "customer:profile.vehicle.makes.cadillac",
  },
  {
    value: VEHICLE_MAKES.CHEVROLET,
    label: "customer:profile.vehicle.makes.chevrolet",
  },
  {
    value: VEHICLE_MAKES.CHRYSLER,
    label: "customer:profile.vehicle.makes.chrysler",
  },
  {
    value: VEHICLE_MAKES.DODGE,
    label: "customer:profile.vehicle.makes.dodge",
  },
  {
    value: VEHICLE_MAKES.FERRARI,
    label: "customer:profile.vehicle.makes.ferrari",
  },
  {
    value: VEHICLE_MAKES.FIAT,
    label: "customer:profile.vehicle.makes.fiat",
  },
  {
    value: VEHICLE_MAKES.FORD,
    label: "customer:profile.vehicle.makes.ford",
  },
  {
    value: VEHICLE_MAKES.GENERAL_MOTORS,
    label: "customer:profile.vehicle.makes.general_motors",
  },
  {
    value: VEHICLE_MAKES.GMC,
    label: "customer:profile.vehicle.makes.gmc",
  },
  {
    value: VEHICLE_MAKES.HONDA,
    label: "customer:profile.vehicle.makes.honda",
  },
  {
    value: VEHICLE_MAKES.HYUNDAI,
    label: "customer:profile.vehicle.makes.hyundai",
  },
  {
    value: VEHICLE_MAKES.INFINITI,
    label: "customer:profile.vehicle.makes.infiniti",
  },
  {
    value: VEHICLE_MAKES.JAGUAR,
    label: "customer:profile.vehicle.makes.jaguar",
  },
  {
    value: VEHICLE_MAKES.JEEP,
    label: "customer:profile.vehicle.makes.jeep",
  },
  {
    value: VEHICLE_MAKES.KIA,
    label: "customer:profile.vehicle.makes.kia",
  },
  {
    value: VEHICLE_MAKES.LAND_ROVER,
    label: "customer:profile.vehicle.makes.land_rover",
  },
  {
    value: VEHICLE_MAKES.LEXUS,
    label: "customer:profile.vehicle.makes.lexus",
  },
  {
    value: VEHICLE_MAKES.LINCOLN,
    label: "customer:profile.vehicle.makes.lincoln",
  },
  {
    value: VEHICLE_MAKES.MASERATI,
    label: "customer:profile.vehicle.makes.maserati",
  },
  {
    value: VEHICLE_MAKES.MAZDA,
    label: "customer:profile.vehicle.makes.mazda",
  },
  {
    value: VEHICLE_MAKES.MERCEDES_BENZ,
    label: "customer:profile.vehicle.makes.mercedes_benz",
  },
  {
    value: VEHICLE_MAKES.MINI,
    label: "customer:profile.vehicle.makes.mini",
  },
  {
    value: VEHICLE_MAKES.MITSUBISHI,
    label: "customer:profile.vehicle.makes.mitsubishi",
  },
  {
    value: VEHICLE_MAKES.NISSAN,
    label: "customer:profile.vehicle.makes.nissan",
  },
  {
    value: VEHICLE_MAKES.OLDSMOBILE,
    label: "customer:profile.vehicle.makes.oldsmobile",
  },
  {
    value: VEHICLE_MAKES.PONTIAC,
    label: "customer:profile.vehicle.makes.pontiac",
  },
  {
    value: VEHICLE_MAKES.PORSCHE,
    label: "customer:profile.vehicle.makes.porsche",
  },
  {
    value: VEHICLE_MAKES.RAM,
    label: "customer:profile.vehicle.makes.ram",
  },
  {
    value: VEHICLE_MAKES.SAAB,
    label: "customer:profile.vehicle.makes.saab",
  },
  {
    value: VEHICLE_MAKES.SUBARU,
    label: "customer:profile.vehicle.makes.subaru",
  },
  {
    value: VEHICLE_MAKES.TESLA,
    label: "customer:profile.vehicle.makes.tesla",
  },
  {
    value: VEHICLE_MAKES.TOYOTA,
    label: "customer:profile.vehicle.makes.toyota",
  },
  {
    value: VEHICLE_MAKES.VOLKSWAGEN,
    label: "customer:profile.vehicle.makes.volkswagen",
  },
  {
    value: VEHICLE_MAKES.VOLVO,
    label: "customer:profile.vehicle.makes.volvo",
  },
  {
    value: VEHICLE_MAKES.OTHER,
    label: "customer:profile.vehicle.other",
  },
]);
