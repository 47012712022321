import { css } from "@emotion/react";

export const buttonStyles = css`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const iconStyles = (selected) => css`
  width: 1rem;
  height: 1rem;
  color: ${selected ? "white" : "var(--dpzBlue)"};
`;

export const cheeseSelectionWrapperStyles = css`
  display: flex;
`;

export const flexGrowStyle = css`
  flex-grow: 1;
`;

export const marginBottomStyles = css`
  margin-bottom: 1rem;
`;
