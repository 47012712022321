import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import getCurrentCities from "ducks/city/selectors";

import Select from "components/StoreLocator/Strategy/Select";

const CityDropdown = (props) => {
  const { t, handleChange } = props;

  const fetchedCities = useSelector(getCurrentCities);
  const selectFetchedCity = Object.assign(
    { selectCity: t("customer:locations.address.select_city") },
    fetchedCities
  );

  return (
    <Select
      className="grid__cell--1 grid__cell--1/2@desktop"
      handleChange={handleChange}
      label={t("customer:locations.address.city")}
      name="city"
      quidBase="store-locator-address-city"
      source={selectFetchedCity}
      t={t}
      translations={false}
    />
  );
};

CityDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default CityDropdown;
