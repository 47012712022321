/** @jsxImportSource @emotion/react */
import React from "react";

import { css } from "@emotion/react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getNewExperienceFeature } from "ducks/market/selectors";
import {
  getCategories,
  getPizzaCategories,
  getSizes,
  getVariants,
} from "ducks/menu/selectors";
import { getCurrentOrderCoupons } from "ducks/order/selectors";

import { PIZZA_CATEGORY, VARIANT_OVERRIDES } from "constants/menu";

import Tooltip from "components/Tooltip";

const productLinkStyles = css`
  background-color: transparent;
  border-width: 0;
  padding: 0;

  color: var(--dpzBlue);

  &:hover {
    cursor: pointer;
  }
`;

const defaultCursorStyle = css`
  &:hover {
    cursor: default;
  }
`;

export const getNumberOfProductsToFulfill = (unfulfilledGroups) => {
  return unfulfilledGroups.reduce((numProductsToFulfill, unfulfilledGroup) => {
    numProductsToFulfill += unfulfilledGroup[0].requiredQuantity;
    unfulfilledGroup.forEach(
      ({ matchedQuantity }) => (numProductsToFulfill -= matchedQuantity)
    );
    return numProductsToFulfill;
  }, 0);
};

const ProductItem = ({
  categoryCode,
  linkToProduct,
  handleProductClick,
  children,
}) => {
  return (
    <li className="receipt__coupon--product">
      {linkToProduct ? (
        <button
          css={productLinkStyles}
          onClick={handleProductClick(categoryCode)}
        >
          {children}
        </button>
      ) : (
        children
      )}
    </li>
  );
};

const ReceiptCoupon = ({
  couponCode = "",
  couponName = "",
  couponNumber,
  deleteOrderCoupon,
  fulfilled = false,
  goToEverythingElse,
  goToFulfiller,
  goToPizzas,
  orderCouponId,
  resetActiveOrderProduct,
  t,
  unfulfilledGroups = [],
}) => {
  const { newCouponPage: showNewCouponPage, skipReceiptCouponFulfillerPage } =
    useSelector(getNewExperienceFeature);
  const currentOrderCoupons = useSelector(getCurrentOrderCoupons);
  const pizzaCategories = useSelector(getPizzaCategories);
  const activeCoupon = Object.values(currentOrderCoupons).find(
    ({ couponCode: activeCouponCode }) => activeCouponCode === couponCode
  );

  const pizzaCouponPageCodeCategory = activeCoupon?.productGroups?.find(
    (productGroup) => productGroup.Default.CategoryCode === PIZZA_CATEGORY
  )?.Default?.PageCode;

  const pizzaCouponCategory = Object.keys(pizzaCategories).includes(
    pizzaCouponPageCodeCategory
  )
    ? pizzaCouponPageCodeCategory.toLowerCase()
    : null;

  const handleCouponClick = () => {
    if (skipReceiptCouponFulfillerPage) return;

    const nextUnfulfilledGroup = unfulfilledGroups[0] || [];

    if (nextUnfulfilledGroup.length === 0) return;

    if (nextUnfulfilledGroup.length === 1) {
      nextUnfulfilledGroup[0].categoryCode === PIZZA_CATEGORY
        ? goToPizzas(pizzaCouponCategory)
        : goToEverythingElse(
            nextUnfulfilledGroup[0].categoryCode.toLowerCase()
          );
      return;
    }

    resetActiveOrderProduct();
    goToFulfiller(orderCouponId);
  };

  const deleteOrderCouponClick = (evt) => {
    evt.stopPropagation();

    deleteOrderCoupon(orderCouponId);
  };

  const handleProductClick = (categoryCode) => () => {
    if (!categoryCode) return;

    categoryCode === PIZZA_CATEGORY
      ? goToPizzas(pizzaCouponCategory)
      : goToEverythingElse(categoryCode.toLowerCase());
  };

  const categories = useSelector(getCategories);
  const sizes = useSelector(getSizes);
  const variants = useSelector(getVariants);

  const getProductOrCategoryName = (
    categoryCode,
    flavorCode,
    sizeCode,
    requiredQuantity
  ) => {
    // productGroup is sent from power in the get coupon info call
    const singleProductGroup = activeCoupon.productGroups?.find(
      (productGroup) =>
        productGroup.ReAddDefault.CategoryCode === categoryCode &&
        productGroup.ReAddDefault.FlavorCode === flavorCode &&
        productGroup.ReAddDefault.SizeCode === sizeCode &&
        productGroup.RequiredQty === requiredQuantity
    );
    /* if the group has only one specific product code that can fulfill the coupon,
     * we want to display the full product name, found in variants
     */
    if (singleProductGroup?.ProductCodes?.length === 1) {
      const variantCode = singleProductGroup.ProductCodes[0];

      // add override for any broken codes, STJUDE is the only one currently
      if (Object.keys(VARIANT_OVERRIDES).includes(variantCode)) {
        return variants[VARIANT_OVERRIDES[variantCode]].name;
      }

      const product = Object.values(variants)?.find(
        ({ code }) => code === variantCode
      );

      return product?.name;
    } else {
      // if multiple products can fulfill, then display the size if given and category code
      return (
        (sizeCode && sizes[sizeCode] ? sizes[sizeCode].sizeName + " " : "") +
        (categories[categoryCode]?.categoryName ?? "")
      );
    }
  };

  const getListOfProductsNeededPerGroup = (group) => {
    /* if the group only has one product in it, then we want to display multiples of
     * that product if it is needed to fulfill the coupon. For example, if the coupon
     * needs 2 Medium pizzas, we want to display "Medium Pizza" twice in the list
     */
    if (group.length === 1) {
      return group.map(
        ({
          categoryCode,
          flavorCode,
          sizeCode,
          requiredQuantity,
          matchedQuantity,
        }) => {
          let productList = [];
          // remove any instances of the product if it is already in the cart
          for (let i = 0; i < requiredQuantity - matchedQuantity; i++)
            productList.push(
              <ProductItem
                key={`${categoryCode}-${i}`}
                categoryCode={categoryCode}
                handleProductClick={handleProductClick}
                linkToProduct={skipReceiptCouponFulfillerPage}
              >
                {getProductOrCategoryName(
                  categoryCode,
                  flavorCode,
                  sizeCode,
                  requiredQuantity
                )}
              </ProductItem>
            );
          return productList;
        }
      );
    } else {
      /* if the group can be fulfilled with multiple categories or products,
       * display each category or product once in the list
       */
      return group.map(
        ({ categoryCode, flavorCode, sizeCode, requiredQuantity }) => (
          <ProductItem
            key={categoryCode}
            categoryCode={categoryCode}
            handleProductClick={handleProductClick}
            linkToProduct={skipReceiptCouponFulfillerPage}
          >
            {getProductOrCategoryName(
              categoryCode,
              flavorCode,
              sizeCode,
              requiredQuantity
            )}
          </ProductItem>
        )
      );
    }
  };

  const receiptCouponBody = (
    <div
      className={classNames(
        "receipt__coupon",
        fulfilled ? "status--positive" : "status--warning",
        {
          "receipt__coupon--unfulfilled": !fulfilled,
          "receipt__coupon--fulfilled": fulfilled,
        }
      )}
      css={skipReceiptCouponFulfillerPage && defaultCursorStyle}
      data-icon="local_offer"
      data-quid={`receipt-coupon-${couponNumber}`}
      key={orderCouponId}
      onClick={handleCouponClick}
      onKeyDown={(e) => e}
      role="presentation"
      title={
        !showNewCouponPage
          ? t(fulfilled ? "receipt:fulfilled" : "receipt:unfulfilled")
          : null
      }
    >
      <div>
        <p>
          <b>{couponName}</b>
        </p>
        <p>({couponCode})</p>
        {!fulfilled ? (
          <p>
            {showNewCouponPage
              ? t("coupons:products_to_complete", {
                  count: getNumberOfProductsToFulfill(unfulfilledGroups),
                })
              : t("coupons:steps_to_complete", {
                  count: unfulfilledGroups.length,
                })}
          </p>
        ) : null}
        {showNewCouponPage &&
          unfulfilledGroups.map((group) =>
            getListOfProductsNeededPerGroup(group)
          )}
      </div>
      <button
        type="button"
        className="receipt__coupon-delete btn btn--icon btn--icon-only"
        data-icon="close"
        onClick={deleteOrderCouponClick}
      />
    </div>
  );

  return showNewCouponPage && activeCoupon ? (
    <Tooltip
      title={t(
        activeCoupon.fulfilled ? "coupons:complete" : "coupons:incomplete"
      )}
    >
      {receiptCouponBody}
    </Tooltip>
  ) : (
    receiptCouponBody
  );
};

ReceiptCoupon.propTypes = {
  couponCode: PropTypes.string,
  couponName: PropTypes.string,
  couponNumber: PropTypes.number.isRequired,
  deleteOrderCoupon: PropTypes.func.isRequired,
  fulfilled: PropTypes.bool,
  goToEverythingElse: PropTypes.func.isRequired,
  goToFulfiller: PropTypes.func.isRequired,
  goToPizzas: PropTypes.func.isRequired,
  orderCouponId: PropTypes.number.isRequired,
  resetActiveOrderProduct: PropTypes.func.isRequired,
  t: PropTypes.func,
  unfulfilledGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.shape({ categoryCode: PropTypes.string }))
  ),
};

ProductItem.propTypes = {
  categoryCode: PropTypes.string,
  linkToProduct: PropTypes.bool,
  handleProductClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ReceiptCoupon;
