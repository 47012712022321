import moment from "moment";

import flatten from "modules/flatten";

export const padSingleNumber = (n) => (n < 10 ? "0" : "") + n;

export const toISOLocal = (date) =>
  `${date.getFullYear()}-${padSingleNumber(
    date.getMonth() + 1
  )}-${padSingleNumber(date.getDate())}T${padSingleNumber(
    date.getHours()
  )}:${padSingleNumber(date.getMinutes())}`;

const days = Object.freeze(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
export const months = Object.freeze([
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]);

export const getDay = (dayNumber) => days[dayNumber];

export const get12HourTime = (time) => {
  const timeSplit = time.split(":");

  const [, mins] = timeSplit;
  let [hours] = timeSplit;
  hours = parseInt(hours, 10);

  if (hours === 12) return `${time}PM`;
  if (hours > 12) return `${parseInt(hours, 10) - 12}:${mins}PM`;
  if (hours === 0) return `12:${mins}AM`;

  return `${time}AM`;
};

export const getDateWithMonth = (date) =>
  `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;

const storeIsOpen = ({ currentHours, currentMins, closeHours, closeMins }) =>
  currentHours < closeHours || currentMins < closeMins - 15;

export const buildTimeList = (serviceHours) =>
  Array.from(
    new Set(
      serviceHours
        .map((times) => {
          //this is hard to read through, refactor
          const { openTime, closeTime } = times;
          const result = [openTime];

          const parseTime = (num) => parseInt(num, 10);

          let [currentHours, currentMins] = openTime.split(":").map(parseTime);

          const [closeHours, closeMins] = closeTime.split(":").map(parseTime);

          while (
            storeIsOpen({
              currentHours,
              currentMins,
              closeHours,
              closeMins,
            })
          ) {
            if (parseInt(currentMins, 10) + 15 > 45) {
              currentHours = parseInt(currentHours, 10) + 1;
              currentMins = "00";
            } else currentMins = parseInt(currentMins, 10) + 15;

            result.push(`${currentHours}:${currentMins}`);
          }

          return result;
        })
        .reduce(flatten, [])
    )
  ).map((time) => ({ original: time, formatted: get12HourTime(time) }));

export const showValidTimes =
  ({ date, allowFutureOrderTimeMinutesFromNow, storeUTCOffset }) =>
  (time) => {
    const [hours, minutes] = time.original.split(":");
    const newDate = moment(date).utcOffset(storeUTCOffset);
    const MINUTE = 60000;

    newDate.hours(parseInt(hours, 10));
    newDate.minutes(parseInt(minutes, 10));

    const timeFromNowAllowed =
      moment().utcOffset(storeUTCOffset) +
      MINUTE * allowFutureOrderTimeMinutesFromNow;

    return timeFromNowAllowed < +newDate;
  };

export default getDay;
