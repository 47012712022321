/** @jsxImportSource @emotion/react */
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { orderStatusTracker } from "constants/customer";
import { TRACKER_STATUS } from "constants/order";

import styles from "./Tracker.styles";

const STEP_PROGRESS = Object.freeze({
  COMPLETE: "complete",
  PROGRESS: "progress",
  INCOMPLETE: "incomplete",
});

class Tracker extends PureComponent {
  static getStatusIcon(status) {
    switch (status) {
      case STEP_PROGRESS.COMPLETE:
        return "check";
      case STEP_PROGRESS.PROGRESS:
        return "more_horiz";
      default:
        return "";
    }
  }

  constructor(props) {
    super(props);

    this.getStatus = this.getStatus.bind(this);
  }

  getStatus(stepStatus, id) {
    const { status } = this.props;

    if (stepStatus === status) return STEP_PROGRESS.PROGRESS;
    else if (this.props[id]) return STEP_PROGRESS.COMPLETE;
    return STEP_PROGRESS.INCOMPLETE;
  }

  render() {
    const { status, t } = this.props;

    return status === TRACKER_STATUS.UNTRACKED ? null : (
      <div css={styles} className="grid__cell--1 tracker__wrapper">
        <div className="tracker">
          {orderStatusTracker.map(({ className, id, label, value }) => {
            const stepStatus = this.getStatus(value, id);
            const { [id]: time } = this.props;

            return (
              <div
                className={`tracker__step step--${stepStatus} ${className}`}
                key={id}
              >
                <span
                  className="tracker__step--label"
                  data-icon={this.constructor.getStatusIcon(stepStatus)}
                >
                  {t(label)}
                </span>

                {time && <small className="tracker__step--time">@{time}</small>}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

Tracker.propTypes = {
  bakeTime: PropTypes.string,
  checkTime: PropTypes.string,
  placeTime: PropTypes.string,
  prepTime: PropTypes.string,
  readyTime: PropTypes.string,
  status: PropTypes.oneOf(Object.values(TRACKER_STATUS)).isRequired,
};

Tracker.defaultProps = {
  bakeTime: "",
  checkTime: "",
  placeTime: "",
  prepTime: "",
  readyTime: "",
};

export default Tracker;
