/** @jsxImportSource @emotion/react */
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setMessage } from "ducks/message";
import {
  getActiveOrderProduct,
  getActiveOrderProductId,
} from "ducks/order/selectors";
import { updateOrderProductCheese } from "ducks/orderProduct";
import { getSelfLink } from "selectors/related";

import {
  selectIsHalfAndHalf,
  setIsHalfAndHalf,
} from "rtk_redux/slices/pizzaSlice";

import { STATUS } from "constants/message";
import { AREAS, HALF_AND_HALF_AREAS } from "constants/order";
import { WEIGHTS } from "constants/topping";
import kebabCase from "modules/kebabCase";

import { ReactComponent as PizzaPartLeft } from "assets/images/pizza_part_left.svg";
import { ReactComponent as PizzaPartRight } from "assets/images/pizza_part_right.svg";
import { ReactComponent as PizzaPartWhole } from "assets/images/pizza_part_whole.svg";

import {
  buttonStyles,
  cheeseSelectionWrapperStyles,
  flexGrowStyle,
  iconStyles,
  marginBottomStyles,
} from "./Cheese.styles";

const BASES_NO_CHEESE_DISALLOWED = ["NPAN", "BK"]; // HANDMADE PAN, BROOKLYN

const cheeseWeightOptions = [
  { translationKey: "food:toppings.no_cheese", weight: WEIGHTS.ZERO },
  { translationKey: "food:toppings.light", weight: WEIGHTS.LIGHT },
  { translationKey: "food:toppings.normal", weight: WEIGHTS.NORMAL },
  { translationKey: "food:toppings.extra", weight: WEIGHTS.EXTRA },
];

const cheeseAreas = [
  {
    name: "cheese_whole",
    area: AREAS.WHOLE,
    icon: PizzaPartWhole,
    translationKey: "food:whole",
  },
  {
    name: "cheese_left",
    area: AREAS.LEFT,
    icon: PizzaPartLeft,
    translationKey: "food:left",
  },
  {
    name: "cheese_right",
    area: AREAS.RIGHT,
    icon: PizzaPartRight,
    translationKey: "food:right",
  },
];

const halfAndHalfAreas = [AREAS.LEFT, AREAS.RIGHT];

const isSelectedArea = ({ isHalfAndHalf, area }) => {
  const isWhole = area === AREAS.WHOLE;
  return (!isWhole && isHalfAndHalf) || (isWhole && !isHalfAndHalf);
};

const getAreaCheeseWeight = (
  area,
  cheeseToppingId,
  activeOrderProductOptions
) => {
  return area in activeOrderProductOptions?.[cheeseToppingId]
    ? activeOrderProductOptions?.[cheeseToppingId]?.[area]
    : WEIGHTS.NORMAL;
};

const Cheese = ({
  cheese,
  activeOrderProductDefaultOptions,
  activeOrderProductBaseId,
  activeOrderProductOptions,
  t,
}) => {
  const dispatch = useDispatch();
  const activeOrderProduct = useSelector(getActiveOrderProduct);
  const activeOrderProductId = useSelector(getActiveOrderProductId);
  const activeOrderProductLink = getSelfLink(activeOrderProduct);

  const hasSelectedHalfAndHalf = useSelector(
    selectIsHalfAndHalf(activeOrderProductId)
  );

  const getIsHalfAndHalf = () => {
    const defaultCheeseWeight =
      activeOrderProductDefaultOptions.find(
        ({ optionId }) => optionId === cheese.toppingId
      )?.optionWeight || WEIGHTS.NORMAL;

    return (
      halfAndHalfAreas.some(
        (area) =>
          area in activeOrderProductOptions[cheese.toppingId] &&
          activeOrderProductOptions?.[cheese.toppingId]?.[area] !==
            defaultCheeseWeight
      ) || hasSelectedHalfAndHalf
    );
  };

  const isHalfAndHalf = getIsHalfAndHalf();

  const handleCheeseAreaClick = (area) => () => {
    const isWhole = area === AREAS.WHOLE;
    const areasWithWeights = isWhole
      ? { [AREAS.WHOLE]: WEIGHTS.NORMAL }
      : { [AREAS.LEFT]: WEIGHTS.NORMAL, [AREAS.RIGHT]: WEIGHTS.NORMAL };

    dispatch(
      setIsHalfAndHalf({
        productId: activeOrderProductId,
        isHalfAndHalf: !isWhole,
      })
    );

    dispatch(
      updateOrderProductCheese({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        areasWithWeights,
        cheeseToppingCode: cheese.toppingCode,
      })
    );
  };

  const handleCheeseWeightChange = (area) => (e) => {
    const weight = +e.target.value;

    if (
      BASES_NO_CHEESE_DISALLOWED.includes(activeOrderProductBaseId) &&
      weight === WEIGHTS.ZERO
    ) {
      dispatch(
        setMessage({
          message: "negative:cheese_required",
          status: STATUS.WARNING,
        })
      );

      return;
    }

    const areasWithWeights = HALF_AND_HALF_AREAS.includes(area)
      ? {
          [AREAS.LEFT]:
            activeOrderProductOptions[cheese.toppingId]?.[AREAS.LEFT] ||
            WEIGHTS.NORMAL,
          [AREAS.RIGHT]:
            activeOrderProductOptions[cheese.toppingId]?.[AREAS.RIGHT] ||
            WEIGHTS.NORMAL,
          [area]: weight,
        }
      : {
          [AREAS.WHOLE]: weight,
        };

    dispatch(
      updateOrderProductCheese({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        areasWithWeights,
        cheeseToppingCode: cheese.toppingCode,
      })
    );
  };

  return (
    <>
      {cheeseAreas.map(({ area, name, icon, translationKey }) => (
        <div
          key={area}
          css={[cheeseSelectionWrapperStyles, marginBottomStyles]}
        >
          <div css={flexGrowStyle} className="btn-group btn-group--vertical">
            <CheeseAreaButton
              name={name}
              selected={isSelectedArea({
                isHalfAndHalf,
                area,
              })}
              icon={icon}
              text={t(translationKey)}
              handleClick={handleCheeseAreaClick(area)}
            />
          </div>
          <div className="text-field grid__cell--1 grid__cell--1/2@desktop">
            {isSelectedArea({ isHalfAndHalf, area }) && (
              <select
                className="text-field__input"
                data-quid={kebabCase(`${name}-weight-selection`)}
                name={kebabCase(`${name}-weight-selection`)}
                onChange={handleCheeseWeightChange(area)}
                value={getAreaCheeseWeight(
                  area,
                  cheese.toppingId,
                  activeOrderProductOptions
                )}
              >
                {cheeseWeightOptions.map(({ weight, translationKey }) => (
                  <option
                    key={weight}
                    data-quid={kebabCase(`${name}-weight-option-${weight}`)}
                    value={weight}
                  >
                    {t(translationKey)}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const CheeseAreaButton = ({
  name,
  selected,
  icon: Icon,
  text,
  handleClick,
}) => (
  <button
    css={buttonStyles}
    className={classNames("btn", selected && "btn--fill")}
    data-quid={kebabCase(name)}
    onClick={handleClick}
    title={text}
    type="button"
  >
    <Icon css={iconStyles(selected)} />
    {text}
  </button>
);

Cheese.propTypes = {
  cheese: PropTypes.shape({
    toppingCode: PropTypes.string,
    toppingId: PropTypes.string,
  }),
  activeOrderProductDefaultOptions: PropTypes.arrayOf(PropTypes.object),
  activeOrderProductBaseId: PropTypes.string,
  activeOrderProductOptions: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.objectOf(PropTypes.number),
    ])
  ),
};

CheeseAreaButton.propTypes = {
  name: PropTypes.string,
  area: PropTypes.string,
  selected: PropTypes.bool,
  icon: PropTypes.elementType,
  text: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Cheese;
