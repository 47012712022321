/** @jsxImportSource @emotion/react */
import React, {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from "react";

import PropTypes from "prop-types";

const Map = ({ options, onClick, style, children }) => {
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [options, map]);

  // add additional event handlers here
  useEffect(() => {
    if (map) {
      ["click"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );

      // link passed in function on native event
      if (onClick) {
        map.addListener("click", onClick);
      }
    }
  }, [map, onClick]);

  return (
    <>
      <div ref={ref} style={style} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default Map;

Map.propTypes = {
  // If other event handlers are added, those should be linked to native infoWindow events
  // [Complete list of available map events] {@link https://developers.google.com/maps/documentation/javascript/reference/map#Map-Events}
  onClick: PropTypes.func,
  options: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
    zoom: PropTypes.number,
    clickableIcons: PropTypes.bool,
    gestureHandling: PropTypes.oneOf(["cooperative", "greedy", "none", "auto"]),
  }), // google.map.MapOptions interface https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions-Properties
  children: PropTypes.arrayOf(PropTypes.element), // i.e. Marker, InfoWindow
  style: PropTypes.object,
};
