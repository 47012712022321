/** @jsxImportSource @emotion/react */
import { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import RequiredMark from "components/RequiredMark";

const Select = ({
  t,
  label,
  id,
  isInvalid = false,
  handleChange,
  className = "",
  required = false,
  displayError = false,
  showLabel = true,
  shouldTranslate = true,
  options,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const showError = (touched || displayError) && isInvalid;

  const handleFieldChange = (e) => {
    setTouched(true);
    handleChange && handleChange(e);
  };

  const mappedOptions = options.map((option) => (
    <option key={option.value} value={option.value}>
      {shouldTranslate ? t(option.label) : option.label}
    </option>
  ));

  return (
    <div
      className={classNames(
        "form__control-group grid__cell--1",
        className,
        showError && "error"
      )}
      data-quid={id}
    >
      {showLabel && (
        <label
          className="text-field__label"
          htmlFor={id}
          data-quid={`${id}-label`}
        >
          {required ? <RequiredMark /> : null}
          {label}
        </label>
      )}
      <select
        onChange={(e) => handleFieldChange(e)}
        required={required}
        id={id}
        name={id}
        {...props}
      >
        <option value={""}>--</option>
        {mappedOptions}
      </select>
      {showError && (
        <p className="error-message parsley-required">
          {t("negative:please_enter_a_valid_value", {
            value: label.toLowerCase(),
          })}
        </p>
      )}
    </div>
  );
};

export default Select;

Select.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
  displayError: PropTypes.bool,
  showLabel: PropTypes.bool,
  shouldTranslate: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};
