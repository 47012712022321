import { combineEpics, ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";

import { getCurrentLanguage } from "ducks/configuration/selectors";
import { SET_MENU } from "ducks/menu";
import { UPDATE_COUPON_DATA, getCouponDetails } from "ducks/orderCoupon";
import { getOrderCoupons } from "ducks/orderCoupon/selectors";
import { getCurrentStoreId } from "ducks/store/selectors";

import { getCurrentOrderId } from "./order/selectors";

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, productGroups = [], ...action } = {}
) {
  switch (type) {
    case SET_MENU:
      return action.coupons;
    case UPDATE_COUPON_DATA:
      return {
        ...state,
        [action.Code]: {
          ...state[action.Code],
          productGroups,
        },
      };
    default:
      return state;
  }
}

export const getOrderCouponDetails = (action$, { getState }) =>
  action$.pipe(
    ofType(SET_MENU),
    switchMap(() => {
      const state = getState();
      const storeId = getCurrentStoreId(state);
      const language = getCurrentLanguage(state) || "en";
      const orderCoupons = getOrderCoupons(state) || {};
      const currentOrderId = getCurrentOrderId(state);

      return Object.values(orderCoupons).map(({ couponCode, orderId }) =>
        getCouponDetails({
          storeId,
          language,
          couponCode,
          isActiveOrder: orderId === currentOrderId,
        })
      );
    })
  );

export const epics = combineEpics(getOrderCouponDetails);
