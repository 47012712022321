/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { componentWrapper } from "../NewCustomerPage.styles";

import USCustomerInformationForm from "./USCustomerInformationForm";

const CustomerSection = ({ t }) => {
  return (
    <div css={componentWrapper}>
      <header className="title-group">
        <h2 className="title-group__section" data-quid="heading-recent-orders">
          {t("customer:title")}
        </h2>
      </header>
      <USCustomerInformationForm t={t} />
    </div>
  );
};

export default CustomerSection;

CustomerSection.propTypes = {
  t: PropTypes.func,
};
