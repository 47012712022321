import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, filter, map, mergeMap } from "rxjs/operators";

import { updateAddress } from "ducks/address";
import { getAddress } from "ducks/address/selectors";
import { getMarketCode } from "ducks/market/selectors";
import { updateOrder, updateOrderServiceMethod } from "ducks/order";
import { getCurrentOrder } from "ducks/order/selectors";
import { SET_STORE, getStore } from "ducks/store";
import { getLinkFromRelationship } from "selectors/related";

import { setHasUserSelectedStore } from "rtk_redux/slices/customerPageSlice";

import withValidation from "modules/elValidadore";
import removeEmptyProps from "modules/removeEmptyProps";
import { idValidators, urlValidators } from "modules/validators";

const SCOPE = "order-entry/order/";

export const UPDATE_ORDER_STORE = `${SCOPE}UPDATE_ORDER_STORE`;
export const UPDATE_ORDER_STORE_ERROR = `${SCOPE}UPDATE_ORDER_STORE_ERROR`;

export const updateOrderStore = withValidation(
  ({
    addressId = null,
    orderId = null,
    storeId = null,
    url = "",
    updateServiceMethod = false,
    serviceMethodInfo = {},
  } = {}) => ({
    type: UPDATE_ORDER_STORE,
    addressId,
    orderId,
    storeId,
    url,
    updateServiceMethod,
    serviceMethodInfo,
  }),
  {
    orderId: idValidators,
    storeId: idValidators,
    url: urlValidators,
  }
);

export function updateOrderStoreError(error) {
  return {
    type: UPDATE_ORDER_STORE_ERROR,
    error,
  };
}

export const setStoreEpic = (action$) =>
  action$.pipe(
    ofType(SET_STORE),
    filter(({ orderId }) => orderId),
    map(({ addressId, orderId, orderStoreLink, storeId }) =>
      updateOrderStore({
        addressId,
        orderId,
        storeId,
        url: orderStoreLink,
      })
    )
  );

export const updateOrderStoreEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_STORE),
    filter(({ orderId }) => orderId),
    mergeMap(
      ({
        addressId,
        orderId,
        storeId,
        updateServiceMethod,
        serviceMethodInfo,
        ...action
      }) =>
        fetch(
          Object.assign(action, {
            body: {
              data: {
                id: storeId,
              },
            },
          }),
          {
            method: "POST",
          }
        ).pipe(
          map(getState),
          map((state) =>
            Object.assign({}, state, {
              currentOrder: getCurrentOrder(state),
            })
          ),
          map(({ currentOrder, root, storeLocation, ...state }) => {
            const { addressId: currentAddressId } = currentOrder;

            const marketCode = getMarketCode(state);
            addressId = addressId || currentAddressId;
            const oldAddress = getAddress(addressId)(state);

            const {
              stores,
              gslAddress: { City, Region, PostalCode, SectorName } = {},
              ...address
            } = storeLocation;
            const newAddress =
              marketCode === "MY"
                ? Object.assign({}, address, {
                    city: City,
                    regionCode: Region,
                    postalCode: PostalCode,
                    addressLine4: SectorName,
                  })
                : Object.assign({}, address);

            return {
              addressLink: getLinkFromRelationship("address")(currentOrder),
              currentAddressId,
              newAddress,
              oldAddress,
              storesLink: getLinkFromRelationship("stores")(root),
            };
          }),
          mergeMap(
            ({
              addressLink,
              currentAddressId,
              newAddress,
              oldAddress,
              storesLink,
            }) => [
              ...(updateServiceMethod
                ? [
                    updateOrderServiceMethod({
                      ...serviceMethodInfo,
                    }),
                  ]
                : []),
              getStore({
                setAsActive: true,
                url: `${storesLink}/${storeId}`,
              }),
              updateOrder({
                orderId,
                storeId,
              }),
              updateAddress(
                Object.assign(oldAddress, removeEmptyProps(newAddress), {
                  active: true,
                  addressId: currentAddressId,
                  url: addressLink,
                })
              ),
              setHasUserSelectedStore(true),
            ]
          ),
          catchError((error) => of(updateOrderStoreError(error)))
        )
    )
  );
