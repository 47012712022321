import React from "react";

import PropTypes from "prop-types";

import blurOnEnter from "modules/blurOnEnter";

const QuantityButtons = ({
  increment,
  decrement,
  set,
  update,
  quantity,
  minimum,
  maximum,
}) => {
  const getValue = (eventValue) => {
    const value = +eventValue;
    if (Number.isNaN(value))
      return typeof minimum === "undefined" ? 0 : +minimum;

    let qty = value;
    if (typeof minimum !== "undefined") qty = Math.max(qty, +minimum);
    if (typeof maximum !== "undefined") qty = Math.min(qty, +maximum);
    return qty;
  };
  const setQuantity = (evt) => set(getValue(evt.currentTarget.value));

  const updateQuantity = (evt) => update(getValue(evt.currentTarget.value));

  const selectInput = (event) => event.target.select();

  return (
    <div className="btn-group quantity">
      <button
        className="btn btn--primary btn--icon-only quantity__btn"
        data-icon="remove"
        data-quid="decrement-quantity"
        disabled={typeof minimum !== "undefined" && quantity <= minimum}
        onClick={decrement}
      />

      <input
        className="quantity__input"
        data-quid="current-quantity"
        onBlur={updateQuantity}
        onChange={setQuantity}
        onClick={(evt) => {
          evt.stopPropagation();
        }}
        onFocus={selectInput}
        onKeyPress={blurOnEnter}
        type="number"
        value={quantity}
      />

      <button
        className="btn btn--primary btn--icon-only quantity__btn"
        data-icon="add"
        data-quid="increment-quantity"
        disabled={typeof maximum !== "undefined" && quantity >= maximum}
        onClick={increment}
      />
    </div>
  );
};

QuantityButtons.propTypes = {
  decrement: PropTypes.func.isRequired,
  increment: PropTypes.func.isRequired,
  maximum: PropTypes.number,
  minimum: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  set: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

QuantityButtons.defaultProps = {
  maximum: null,
  minimum: null,
  stopClickPropagation: false,
};

export default QuantityButtons;
