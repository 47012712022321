export const ONLINE_STATUS = Object.freeze({
  ONLINE: "Available",
  OFFLINE: "Unavailable",
});

export const STORE_STATUS = Object.freeze({
  OPEN: "Open",
  CLOSED: "Closed",
});

export const SORT_BY = Object.freeze({
  CONNECTIVITY: "connectivity",
  DISTANCE: "distance",
});

export default ONLINE_STATUS;
