/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";

import { element, func, number, object, shape, string } from "prop-types";
import { createPortal } from "react-dom";

const InfoWindow = ({ onCloseClick, children, map, anchor, options = {} }) => {
  const [infoWindow, setInfoWindow] = useState();
  const [contentNode, setContentNode] = useState(null);

  useEffect(() => {
    const windowContent = document.createElement("div");
    setContentNode(windowContent);
  }, []);

  useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new window.google.maps.InfoWindow());
    }
    // remove infoWindow from map on unmount
    return () => {
      if (infoWindow) {
        infoWindow.setMap(null);
      }
    };
  }, [infoWindow]);

  useEffect(() => {
    if (infoWindow) {
      infoWindow.setOptions({ options, map, anchor });
    }
  }, [infoWindow, options, map, anchor]);

  useEffect(() => {
    if (infoWindow && contentNode) {
      infoWindow.setContent(contentNode);
    }
  }, [infoWindow, contentNode]);

  // add additional event handlers here
  useEffect(() => {
    if (infoWindow) {
      ["closeclick"].forEach((eventName) =>
        window.google.maps.event.clearListeners(infoWindow, eventName)
      );

      // link passed in function on native event
      if (onCloseClick) {
        infoWindow.addListener("closeclick", onCloseClick);
      }
    }
  });

  return <>{contentNode !== null && createPortal(children, contentNode)}</>;
};

export default InfoWindow;

InfoWindow.propTypes = {
  onCloseClick: func,
  children: element, // either a child (React component) or content is required
  map: object, // instance of map class {@link https://developers.google.com/maps/documentation/javascript/reference/map#Map}
  anchor: object,
  options: shape({
    position: shape({
      // position required if InfoWindow is not a child of a Marker
      lat: number,
      lng: number,
    }), // infoWindowOptions interface {@link https://developers.google.com/maps/documentation/javascript/reference/info-window#InfoWindowOptions-Properties}
    content: string, // either a child or content is required
  }),
};
