import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, filter, map, mergeMap, pluck } from "rxjs/operators";

import { everythingElseRoute, pizzasRoute } from "routes";

import { setActiveArea } from "ducks/area";
import { RESET_STATE } from "ducks/configuration";
import { getActiveArea, getCategoryType } from "ducks/menu/selectors";
import { setMessage } from "ducks/message";
import { priceValidateOrder, updateOrder } from "ducks/order";
import {
  getCurrentOrder,
  getCurrentOrderId,
  getOrderProducts,
  getProductQualifiesForExtraCheese,
} from "ducks/order/selectors";
import {
  getOrderProductBody,
  getOrderProductCheeseBody,
  getOrderProductToppingBody,
  getOrderProductWithDefaults,
} from "ducks/orderProduct/selectors";
import { CREATE_PART, DELETE_PART, createPart, deletePart } from "ducks/part";
import { getLinkFromRelationship, getSelfLink } from "selectors/related";

import { setIsHalfAndHalf } from "rtk_redux/slices/pizzaSlice";

import { PIZZA_CATEGORY } from "constants/menu";
import { MESSAGE, STATUS } from "constants/message";
import { AREA, defaultArea } from "constants/order";
import { WEIGHTS } from "constants/topping";
import withValidation from "modules/elValidadore";
import getOrderProductSauceBody from "modules/getOrderProductSauceBody";
import uuid from "modules/uuid";
import { idValidators, urlValidators } from "modules/validators";

import { getCheeseToppings } from "../menu/selectors";

const SCOPE = "order-entry/orderProduct/";

//TODO: split into ducklings

export const CREATE_ORDER_PRODUCT = `${SCOPE}CREATE_ORDER_PRODUCT`;
export const CREATE_ORDER_PRODUCT_SUCCESS = `${SCOPE}CREATE_ORDER_PRODUCT_SUCCESS`;
export const CREATE_ORDER_PRODUCT_ERROR = `${SCOPE}CREATE_ORDER_PRODUCT_ERROR`;
export const UPDATE_ORDER_PRODUCT = `${SCOPE}UPDATE_ORDER_PRODUCT`;
export const UPDATE_ORDER_PRODUCT_SUCCESS = `${SCOPE}UPDATE_ORDER_PRODUCT_SUCCESS`;
export const UPDATE_ORDER_PRODUCT_SAUCE = `${SCOPE}UPDATE_ORDER_PRODUCT_SAUCE`;
export const UPDATE_ORDER_PRODUCT_PASTA_SAUCE = `${SCOPE}UPDATE_ORDER_PRODUCT_PASTA_SAUCE`;
export const UPDATE_ORDER_PRODUCT_CHEESE = `${SCOPE}UPDATE_ORDER_PRODUCT_CHEESE`;
export const UPDATE_ORDER_PRODUCT_TOPPING = `${SCOPE}UPDATE_ORDER_PRODUCT_TOPPING`;
export const UPDATE_PRICE_ORDER_PRODUCT_SUCCESS = `${SCOPE}UPDATE_PRICE_ORDER_PRODUCT_SUCCESS`;
export const UPDATE_ORDER_PRODUCT_ERROR = `${SCOPE}UPDATE_ORDER_PRODUCT_ERROR`;
export const DELETE_ORDER_PRODUCT_OPTION = `${SCOPE}DELETE_ORDER_PRODUCT_OPTION`;
export const DELETE_ORDER_PRODUCT = `${SCOPE}DELETE_ORDER_PRODUCT`;
export const DELETE_ORDER_PRODUCT_SUCCESS = `${SCOPE}DELETE_ORDER_PRODUCT_SUCCESS`;
export const DELETE_ORDER_PRODUCT_ERROR = `${SCOPE}DELETE_ORDER_PRODUCT_ERROR`;
export const SET_ACTIVE_ORDER_PRODUCT = `${SCOPE}SET_ACTIVE_ORDER_PRODUCT`;
export const RESET_ACTIVE_ORDER_PRODUCT = `${SCOPE}RESET_ACTIVE_ORDER_PRODUCT`;
export const PRICE_VALIDATE_ORDER_PRODUCT = `${SCOPE}PRICE_VALIDATE_ORDER_PRODUCT`;
export const PRICE_VALIDATE_ORDER_PRODUCT_SUCCESS = `${SCOPE}PRICE_VALIDATE_ORDER_PRODUCT_SUCCESS`;
export const PRICE_VALIDATE_ORDER_PRODUCT_ERROR = `${SCOPE}PRICE_VALIDATE_ORDER_PRODUCT_ERROR`;
export const SET_EXTRA_CHEESE_ERROR = `${SCOPE}SET_EXTRA_CHEESE_ERROR`;
export const CHECK_INVALID_PRODUCTS = `${SCOPE}CHECK_INVALID_PRODUCTS`;

export const createOrderProduct = withValidation(
  ({
    baseId = "",
    categoryId = "",
    itemQuantity = 1,
    orderId = null,
    productId = "",
    sideId = "",
    sideQuantity = 1,
    sizeId = "",
    toppingId = "",
    toppingWeight = WEIGHTS.NORMAL,
    skipSetActiveOrderProduct = false,
    priceOrder = false,
    url = "",
  } = {}) => ({
    type: CREATE_ORDER_PRODUCT,
    baseId,
    categoryId,
    itemQuantity,
    orderId,
    productId,
    sideId,
    sideQuantity,
    sizeId,
    toppingId,
    toppingWeight,
    skipSetActiveOrderProduct,
    priceOrder,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export function createOrderProductError(error) {
  return {
    type: CREATE_ORDER_PRODUCT_ERROR,
    error,
  };
}

export const createOrderProductSuccess = withValidation(
  ({
    active = true,
    baseId = "",
    categoryId = "",
    cookingInstructions = [],
    itemPrice = 0,
    itemQuantity = 1,
    links = {},
    orderId = null,
    orderProductId = uuid(),
    partIds = [],
    parts = {},
    relationships = {},
    sizeId = "",
    skipSetActiveOrderProduct = false,
    priceOrder = false,
  } = {}) => ({
    type: CREATE_ORDER_PRODUCT_SUCCESS,
    active,
    baseId,
    categoryId,
    cookingInstructions,
    itemPrice,
    itemQuantity,
    links,
    orderId,
    orderProductId,
    partIds,
    parts,
    relationships,
    sizeId,
    skipSetActiveOrderProduct,
    priceOrder,
  }),
  {
    orderId: idValidators,
  }
);

export const checkInvalidProducts = ({ newMenuProductCodes }) => ({
  type: CHECK_INVALID_PRODUCTS,
  newMenuProductCodes,
});

export const updateOrderProductSauce = withValidation(
  ({
    sauces,
    orderProductId = null,
    sauceToppingCode = null,
    sauceToppingWeight = WEIGHTS.NORMAL,
    ...orderProduct
  } = {}) => ({
    ...orderProduct,
    type: UPDATE_ORDER_PRODUCT_SAUCE,
    sauces,
    orderProductId,
    sauceToppingCode,
    sauceToppingWeight,
  }),
  {
    orderProductId: idValidators,
    url: urlValidators,
  }
);

export const updateOrderProductPastaSauce = withValidation(
  ({
    sauces,
    orderProductId = null,
    sauceToppingCode = null,
    sauceToppingWeight = WEIGHTS.NORMAL,
    ...orderProduct
  } = {}) => ({
    ...orderProduct,
    type: UPDATE_ORDER_PRODUCT_PASTA_SAUCE,
    sauces,
    orderProductId,
    sauceToppingCode,
    sauceToppingWeight,
  }),
  {
    orderProductId: idValidators,
    url: urlValidators,
  }
);

export const updateOrderProductCheese = withValidation(
  ({
    orderProductId = null,
    areasWithWeights,
    cheeseToppingCode,
    ...orderProduct
  } = {}) => ({
    ...orderProduct,
    type: UPDATE_ORDER_PRODUCT_CHEESE,
    orderProductId,
    areasWithWeights,
    cheeseToppingCode,
  }),
  {
    orderProductId: idValidators,
    url: urlValidators,
  }
);

export const updateOrderProductTopping = withValidation(
  ({
    area = defaultArea,
    baseId = "",
    itemQuantity = 1,
    orderProductId = null,
    productId = "",
    sideId = "",
    sideQuantity = 1,
    sizeId = "",
    toppingId = "",
    toppingWeight = WEIGHTS.NORMAL,
    isDefaultTopping = false,
    url = "",
    ...orderProduct
  } = {}) => ({
    ...orderProduct,
    type: UPDATE_ORDER_PRODUCT_TOPPING,
    area,
    baseId,
    itemQuantity,
    productId,
    orderProductId,
    sideId,
    sideQuantity,
    sizeId,
    toppingId,
    toppingWeight,
    isDefaultTopping,
    url,
  }),
  {
    orderProductId: idValidators,
    url: urlValidators,
  }
);

export const updateOrderProduct = withValidation(
  ({
    baseId = "",
    itemQuantity = 1,
    orderProductId = null,
    productId = "",
    sideId = "",
    sideQuantity = 1,
    sizeId = "",
    toppingId = "",
    toppingWeight = WEIGHTS.NORMAL,
    url = "",
    cookingInstructions = [],
    ...orderProduct
  } = {}) =>
    Object.assign({}, orderProduct, {
      type: UPDATE_ORDER_PRODUCT,
      baseId,
      itemQuantity,
      productId,
      orderProductId,
      sideId,
      sideQuantity,
      sizeId,
      toppingId,
      toppingWeight,
      cookingInstructions,
      url,
    }),
  {
    orderProductId: idValidators,
    url: urlValidators,
  }
);

export function updateOrderProductError(error) {
  return {
    type: UPDATE_ORDER_PRODUCT_ERROR,
    error,
  };
}

export function setExtraCheeseError(error) {
  return {
    type: SET_EXTRA_CHEESE_ERROR,
    error,
  };
}

export const updateOrderProductSuccess = withValidation(
  ({ orderProductId = null, ...orderProduct } = {}) =>
    Object.assign({}, orderProduct, {
      type: UPDATE_ORDER_PRODUCT_SUCCESS,
      orderProductId,
    }),
  {
    orderProductId: idValidators,
  }
);

export const updatePriceOrderProductSuccess = withValidation(
  ({ orderProductId = null, ...orderProduct } = {}) =>
    Object.assign({}, orderProduct, {
      type: PRICE_VALIDATE_ORDER_PRODUCT_SUCCESS,
      orderProductId,
    }),
  {
    orderProductId: idValidators,
  }
);

export const deleteOrderProductOption = withValidation(
  ({
    area = defaultArea,
    defaultOptions = [],
    optionId = "",
    parts = {
      [defaultArea]: {},
    },
    url = "",
    ...orderProduct
  } = {}) =>
    Object.assign({}, orderProduct, {
      type: DELETE_ORDER_PRODUCT_OPTION,
      area,
      defaultOptions,
      optionId,
      parts,
      url,
    }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export const deleteOrderProduct = withValidation(
  ({ orderId = null, orderProductId = null, url = "" } = {}) => ({
    type: DELETE_ORDER_PRODUCT,
    orderId,
    orderProductId,
    url,
  }),
  {
    orderId: idValidators,
    orderProductId: idValidators,
    url: urlValidators,
  }
);

export function deleteOrderProductError(error) {
  return {
    type: DELETE_ORDER_PRODUCT_ERROR,
    error,
  };
}

export const deleteOrderProductSuccess = withValidation(
  ({ orderId = null, orderProductId = null } = {}) => ({
    type: DELETE_ORDER_PRODUCT_SUCCESS,
    orderId,
    orderProductId,
  }),
  {
    orderId: idValidators,
    orderProductId: idValidators,
  }
);

export const setActiveOrderProduct = withValidation(
  ({ active = true, orderProductId = null } = {}) => ({
    type: SET_ACTIVE_ORDER_PRODUCT,
    active,
    orderProductId,
  }),
  {
    orderProductId: idValidators,
  }
);

export const resetActiveOrderProduct = () => ({
  type: RESET_ACTIVE_ORDER_PRODUCT,
});

export const priceValidateOrderProduct = withValidation(
  ({ url = "" } = {}) => ({
    type: PRICE_VALIDATE_ORDER_PRODUCT,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function priceValidateOrderProductError(error) {
  return {
    type: PRICE_VALIDATE_ORDER_PRODUCT_ERROR,
    error,
  };
}

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case CREATE_ORDER_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        [action.orderProductId]: action,
      });
    case UPDATE_ORDER_PRODUCT_SUCCESS:
      return {
        ...state,
        [action.orderProductId]: {
          ...state[action.orderProductId],
          ...action,
          parts: {
            ...action.parts,
          },
        },
      };
    case PRICE_VALIDATE_ORDER_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        [action.orderProductId]: Object.assign(
          {},
          state[action.orderProductId],
          action
        ),
      });
    case DELETE_ORDER_PRODUCT_SUCCESS:
      return Object.keys(state)
        .filter(
          (orderProductId) =>
            orderProductId !== action.orderProductId.toString()
        )
        .reduce(
          (all, orderProductId) =>
            Object.assign(all, {
              [orderProductId]: state[orderProductId],
            }),
          {}
        );
    case RESET_ACTIVE_ORDER_PRODUCT:
      return Object.assign(
        {},
        Object.values(state).reduce(
          (all, { active, orderProductId, ...orderProduct }) =>
            Object.assign(all, {
              [orderProductId]: Object.assign(orderProduct, {
                active: false,
                orderProductId,
              }),
            }),
          {}
        )
      );
    case SET_ACTIVE_ORDER_PRODUCT:
      return Object.assign(
        {},
        Object.values(state).reduce(
          (all, { active, orderProductId, ...orderProduct }) =>
            Object.assign(all, {
              [orderProductId]: Object.assign(orderProduct, {
                active: false,
                orderProductId,
              }),
            }),
          {}
        ),
        {
          [action.orderProductId]: Object.assign(
            {},
            state[action.orderProductId],
            {
              active: action.active,
            }
          ),
        }
      );
    case CREATE_PART:
      return Object.assign({}, state, {
        [action.orderProductId]: Object.assign(
          {},
          state[action.orderProductId],
          {
            partIds: Array.from(
              new Set(
                state[action.orderProductId].partIds.concat(action.partId)
              )
            ),
          }
        ),
      });
    case DELETE_PART:
      return Object.assign(
        {},
        state,
        state[action.orderProductId]
          ? {
              [action.orderProductId]: Object.assign(
                {},
                state[action.orderProductId],
                {
                  partIds: state[action.orderProductId].partIds.filter(
                    (partId) => partId !== action.partId
                  ),
                }
              ),
            }
          : {}
      );
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
const addExtraCheese = ({ productParts, cheeseToppingCode = "C" }) =>
  Object.entries(productParts).reduce(
    (parts, [key, { options, ...part }]) => ({
      ...parts,
      [key]: {
        ...part,
        options: [
          ...options.filter(({ code }) => code === cheeseToppingCode),
          { code: "C", weight: 1.5 },
        ],
      },
    }),
    {}
  );

export const createOrderProductEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(CREATE_ORDER_PRODUCT),
    mergeMap(
      ({
        baseId: suppliedBaseId,
        categoryId,
        itemQuantity: suppliedItemQuantity,
        orderId,
        productId: suppliedProductId,
        sideId,
        sideQuantity,
        sizeId: suppliedSizeId,
        toppingId: suppliedToppingId,
        toppingWeight: suppliedToppingWeight,
        skipSetActiveOrderProduct,
        priceOrder,
        ...action
      }) => {
        const { baseCode, productCode, sizeCode, toppingCode } =
          getOrderProductWithDefaults({
            categoryId,
            suppliedBaseId,
            suppliedItemQuantity,
            suppliedProductId,
            suppliedSizeId,
            suppliedToppingId,
          })(getState());
        const productBody = getOrderProductBody({
          area: defaultArea,
          baseCode,
          itemQuantity: suppliedItemQuantity,
          productCode,
          toppingCode,
          toppingWeight: suppliedToppingWeight,
          sideCode: sideId,
          sideQuantity,
          sizeCode,
        });
        const state = getState();
        const productQualifiesForExtraCheese =
          getProductQualifiesForExtraCheese(productBody.data.attributes)(state);

        if (productQualifiesForExtraCheese) {
          const [{ toppingCode: cheeseToppingCode = "C" } = {}] =
            getCheeseToppings(state);
          productBody.data.attributes.parts = addExtraCheese({
            productParts: productBody.data.attributes.parts,
            cheeseToppingCode,
          });
        }

        return fetch(
          Object.assign(action, {
            body: productBody,
          }),
          {
            method: "POST",
          }
        ).pipe(
          pluck("response", "data"),
          map(
            ({
              attributes: {
                base,
                price,
                quantity,
                sizeCode: sizeId,
                ...orderProduct
              },
              id,
              links,
              relationships,
            }) =>
              createOrderProductSuccess(
                Object.assign({}, orderProduct, {
                  baseId: base,
                  categoryId,
                  itemPrice: price,
                  itemQuantity: quantity,
                  links,
                  relationships,
                  orderId,
                  orderProductId: id,
                  sizeId,
                  skipSetActiveOrderProduct,
                  priceOrder,
                })
              )
          ),
          catchError((error) => of(createOrderProductError(error)))
        );
      }
    )
  );

export const createOrderProductSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(CREATE_ORDER_PRODUCT_SUCCESS),
    mergeMap(
      ({ orderId, skipSetActiveOrderProduct, priceOrder, ...action }) => {
        const state = getState();
        const { categoryId, orderProductId, parts } = action;

        const currentOrder = getCurrentOrder(state);
        const priceValidateOrderLink =
          getLinkFromRelationship("priceOrder")(currentOrder);

        return [].concat(
          skipSetActiveOrderProduct
            ? setActiveOrderProduct({ active: false, orderProductId })
            : setActiveOrderProduct(action),
          updateOrder({
            orderId,
            amountBreakDown: {},
          }),
          Object.entries(parts).map(([area, { name, options, productCode }]) =>
            createPart({
              area,
              categoryType: getCategoryType(categoryId)(state),
              options,
              orderProductId,
              productId: productCode,
              productName: name,
            })
          ),
          priceOrder
            ? priceValidateOrder({
                orderId,
                url: priceValidateOrderLink,
              })
            : []
        );
      }
    )
  );

const fetchProductBody = ({
  fetch,
  action,
  productBody,
  orderId,
  orderProductId,
  categoryId,
  sizeCode,
}) =>
  fetch(
    Object.assign(action, {
      body: productBody,
    }),
    {
      method: "PUT",
    }
  ).pipe(
    pluck("response", "data"),
    mergeMap(
      ({
        attributes: { base, price, quantity, ...orderProduct },
        id,
        links,
        relationships,
      }) =>
        /*
         * TODO: OEPS returns a success response with a different ID if items were combined. The original orderProductId has to be removed from state.
         * This fixes the issue but we should return to discussion this issue on OEPS/CMS side.
         */
        [
          updateOrderProductSuccess(
            Object.assign({}, orderProduct, {
              active: true,
              baseId: base,
              categoryId,
              itemPrice: price,
              itemQuantity: quantity,
              links,
              orderProductId: id,
              relationships,
              sizeId: sizeCode,
            })
          ),
          updateOrder({
            orderId,
            amountBreakDown: {},
          }),
        ].concat(
          id !== orderProductId
            ? deleteOrderProductSuccess({
                orderId,
                orderProductId,
              })
            : []
        )
    ),
    catchError((error) => of(updateOrderProductError(error)))
  );

export const updateOrderProductEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_PRODUCT),
    mergeMap(
      ({
        toppingId,
        toppingWeight,
        baseId: baseCode,
        categoryId,
        itemQuantity,
        orderId,
        orderProductId,
        productId,
        sideId,
        sideQuantity,
        sizeId: sizeCode,
        cookingInstructions,
        parts = {},
        ...action
      }) => {
        //TODO: write selector
        const newParts = JSON.parse(JSON.stringify(parts));
        const { topping, ...state } = getState();
        const { toppingCode = "" } = topping[toppingId] || {};

        const productBody = getOrderProductBody({
          area: (AREA[getActiveArea(state)] || AREA[defaultArea]).value,
          baseCode,
          itemQuantity,
          parts: newParts,
          productCode: productId,
          toppingCode,
          toppingWeight,
          sideCode: sideId,
          sideQuantity,
          sizeCode,
          cookingInstructions,
        });

        if (
          !toppingCode &&
          getProductQualifiesForExtraCheese(productBody.data.attributes)(state)
        ) {
          const [{ toppingCode: cheeseToppingCode = "C" } = {}] =
            getCheeseToppings(state) || [];
          productBody.data.attributes.parts = addExtraCheese({
            productParts: productBody.data.attributes.parts,
            cheeseToppingCode,
          });
        }

        return fetchProductBody({
          fetch,
          action,
          productBody,
          orderId,
          orderProductId,
          categoryId,
          sizeCode,
        });
      }
    )
  );

export const updateOrderProductSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_PRODUCT_SUCCESS),
    mergeMap(({ categoryId, orderProductId, parts }) => {
      //TODO: write selector
      const state = getState();
      const { orderProduct } = state;
      const { partIds } = orderProduct[orderProductId];

      return partIds
        .map((partId) =>
          deletePart({
            orderProductId,
            partId,
          })
        )
        .concat(
          Object.entries(parts).map(([area, { name, options, productCode }]) =>
            createPart({
              area,
              categoryType: getCategoryType(categoryId)(state),
              options,
              orderProductId,
              productId: productCode,
              productName: name,
            })
          )
        );
    })
  );

export const updateOrderProductSauceEpic = (action$, _, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_PRODUCT_SAUCE),
    mergeMap(
      ({
        sauces,
        sauceToppingCode,
        sauceToppingWeight,
        baseId: baseCode,
        categoryId,
        sizeId: sizeCode,
        orderId,
        orderProductId,
        parts = {},
        itemQuantity,
        cookingInstructions = [],
        ...action
      }) => {
        const newParts = JSON.parse(JSON.stringify(parts));
        const productBody = getOrderProductSauceBody({
          sauces,
          baseCode,
          parts: newParts,
          itemQuantity,
          sizeCode,
          sauceToppingCode,
          sauceToppingWeight,
          cookingInstructions,
        });

        return fetchProductBody({
          fetch,
          action,
          productBody,
          orderId,
          orderProductId,
          categoryId,
          sizeCode,
        });
      }
    )
  );

export const updateOrderProductPastaSauceEpic = (action$, _, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_PRODUCT_PASTA_SAUCE),
    mergeMap(
      ({
        sauces,
        sauceToppingCode,
        sauceToppingWeight,
        baseId: baseCode,
        categoryId,
        sizeId: sizeCode,
        orderId,
        orderProductId,
        parts = {},
        itemQuantity,
        cookingInstructions = [],
        ...action
      }) => {
        const newParts = JSON.parse(JSON.stringify(parts));
        const productBody = getOrderProductSauceBody({
          sauces,
          baseCode,
          parts: newParts,
          itemQuantity,
          sizeCode,
          sauceToppingCode,
          sauceToppingWeight,
          cookingInstructions,
          skipRITS: true,
        });

        return fetchProductBody({
          fetch,
          action,
          productBody,
          orderId,
          orderProductId,
          categoryId,
          sizeCode,
        });
      }
    )
  );

export const updateOrderProductCheeseEpic = (action$, _, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_PRODUCT_CHEESE),
    mergeMap(
      ({
        areasWithWeights,
        cheeseToppingCode,
        baseId: baseCode,
        categoryId,
        sizeId: sizeCode,
        orderId,
        orderProductId,
        parts = {},
        cookingInstructions = [],
        ...action
      }) => {
        let newParts = JSON.parse(JSON.stringify(parts));
        const productBody = getOrderProductCheeseBody({
          areasWithWeights,
          cheeseToppingCode,
          baseCode,
          sizeCode,
          parts: newParts,
          cookingInstructions,
          ...action,
        });

        return fetchProductBody({
          fetch,
          action,
          productBody,
          orderId,
          orderProductId,
          categoryId,
          sizeCode,
        });
      }
    )
  );

export const updateOrderProductToppingEpic = (action$, _, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_PRODUCT_TOPPING),
    mergeMap(
      ({
        area,
        toppingCode,
        toppingWeight,
        isDefaultTopping,
        baseId: baseCode,
        categoryId,
        itemQuantity,
        orderId,
        orderProductId,
        sizeId: sizeCode,
        parts = {},
        cookingInstructions = [],
        ...action
      }) => {
        const newParts = JSON.parse(JSON.stringify(parts));

        const productBody = getOrderProductToppingBody({
          area,
          toppingCode,
          toppingWeight,
          isDefaultTopping,
          baseCode,
          itemQuantity,
          parts: newParts,
          sizeCode,
          cookingInstructions,
        });

        return fetchProductBody({
          fetch,
          action,
          productBody,
          orderId,
          orderProductId,
          categoryId,
          sizeCode,
        });
      }
    )
  );

const removeOptions = (removedOptionCode, options, defaultOptions) =>
  options
    .filter(({ code }) => code !== removedOptionCode)
    .concat(
      defaultOptions
        .map(({ optionCode }) => optionCode)
        .includes(removedOptionCode)
        ? [
            {
              code: removedOptionCode,
              weight: WEIGHTS.ZERO,
            },
          ]
        : []
    );

//TODO: improve coverage
export const deleteOrderProductOptionEpic = (action$) =>
  action$.pipe(
    ofType(DELETE_ORDER_PRODUCT_OPTION),
    map(
      ({
        area,
        defaultOptions,
        optionCode,
        url,
        parts,
        type,
        ...orderProduct
      }) => {
        if (area !== defaultArea) {
          const areaParts = parts[area] || {};
          const { options: areaPartOptions = [] } = areaParts;

          parts = Object.assign({}, parts, {
            [area]: Object.assign({}, areaParts, {
              options: removeOptions(
                optionCode,
                areaPartOptions,
                defaultOptions
              ),
            }),
          });
        } else {
          parts = Object.entries(parts).reduce(
            (all, [currentArea, { options = [], ...part }]) =>
              Object.assign(all, {
                [currentArea]: Object.assign(
                  {
                    options: removeOptions(optionCode, options, defaultOptions),
                  },
                  part
                ),
              }),
            {}
          );
        }

        return updateOrderProduct(
          Object.assign({}, orderProduct, {
            parts,
            url,
          })
        );
      }
    )
  );

export const deleteOrderProductEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(DELETE_ORDER_PRODUCT),
    mergeMap(({ orderId, orderProductId, ...action }) =>
      fetch(action, {
        method: "DELETE",
      }).pipe(
        map(getState),
        mergeMap((state) => {
          //TODO: replace with selector
          const { orderProduct = {} } = state;
          const { partIds } = orderProduct[orderProductId];

          return [
            setIsHalfAndHalf({
              productId: orderProductId,
              isHalfAndHalf: false,
            }),
            deleteOrderProductSuccess({ orderId, orderProductId }),
            updateOrder({ orderId, amountBreakDown: {} }),
          ].concat(
            partIds.map((partId) =>
              deletePart({
                orderProductId,
                partId,
              })
            )
          );
        }),
        catchError((error) => of(deleteOrderProductError(error)))
      )
    )
  );

export const deleteOrderProductSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(DELETE_ORDER_PRODUCT_SUCCESS),
    mergeMap(({ orderProductId }) => {
      //TODO: replace with selector
      const { orderProduct } = getState();
      const { partIds } = orderProduct[orderProductId];

      return partIds.map((partId) =>
        deletePart({
          orderProductId,
          partId,
        })
      );
    })
  );

export const setActiveOrderProductEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(SET_ACTIVE_ORDER_PRODUCT),
    filter(({ active }) => active),
    pluck("orderProductId"),
    mergeMap((orderProductId) => {
      //TODO: replace with selector
      const { category = {}, orderProduct = {} } = getState();
      const { categoryId = "" } = orderProduct[orderProductId];
      const { parentCategory = "" } = category[categoryId];
      const payload = categoryId.toLowerCase();

      return [setActiveArea({ area: defaultArea })].concat(
        parentCategory === PIZZA_CATEGORY
          ? pizzasRoute(payload)
          : everythingElseRoute(payload)
      );
    })
  );

export const priceValidateOrderProductEpic = (
  action$,
  { getState },
  { fetch }
) =>
  action$.pipe(
    ofType(PRICE_VALIDATE_ORDER_PRODUCT),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "data"),
        map(
          ({
            attributes: {
              base,
              price,
              quantity,
              sizeCode,
              ...updatedOrderProduct
            },
            id: orderProductId,
            links,
            relationships,
          }) => {
            const { orderProduct } = getState();

            const currentOrderProduct = orderProduct[orderProductId];

            return updatePriceOrderProductSuccess(
              Object.assign({}, currentOrderProduct, updatedOrderProduct, {
                baseId: base,
                itemPrice: price,
                itemQuantity: quantity,
                links,
                relationships,
                sizeId: sizeCode,
              })
            );
          }
        ),
        catchError((error) => of(priceValidateOrderProductError(error)))
      )
    ),
    catchError((error) => of(priceValidateOrderProductError(error)))
  );

export const checkInvalidProductsEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(CHECK_INVALID_PRODUCTS),
    mergeMap(({ newMenuProductCodes }) => {
      const state = getState();
      const orderProducts = getOrderProducts(state);
      const currentOrderId = getCurrentOrderId(state);

      const invalidOrderProductIds = Object.entries(orderProducts).reduce(
        (all, [orderProductId, orderProduct]) =>
          Object.values(orderProduct.parts).some(
            ({ productCode }) => !newMenuProductCodes.includes(productCode)
          )
            ? [...all, orderProductId]
            : all,
        []
      );

      return invalidOrderProductIds.length > 0
        ? invalidOrderProductIds
            .map((invalidOrderProductId) =>
              deleteOrderProduct({
                orderId: currentOrderId,
                orderProductId: invalidOrderProductId,
                url: getSelfLink(orderProducts[invalidOrderProductId]),
              })
            )
            .concat(
              setMessage({
                message: "negative:invalid_products_removed",
                status: STATUS.WARNING,
              })
            )
        : [];
    })
  );

export const epics = combineEpics(
  createOrderProductEpic,
  createOrderProductSuccessEpic,
  deleteOrderProductEpic,
  deleteOrderProductOptionEpic,
  priceValidateOrderProductEpic,
  setActiveOrderProductEpic,
  updateOrderProductEpic,
  updateOrderProductSuccessEpic,
  updateOrderProductSauceEpic,
  updateOrderProductPastaSauceEpic,
  updateOrderProductCheeseEpic,
  updateOrderProductToppingEpic,
  checkInvalidProductsEpic
);
