import React from "react";

import PropTypes from "prop-types";

import exists from "modules/exists";
import toggleCollapsible from "modules/toggleCollapsible";

const TableRow = ({
  children,
  collapsible,
  defaultOpen,
  headings,
  onKeyDown,
  t,
  utilityHeadingCount,
  ...props
}) => {
  const collaspibleClass = collapsible ? "collapsible" : "";
  const contentClass = collapsible ? "collapsible__content" : "";
  const itemClass = collapsible ? "collapsible__item" : "";
  const openClass = defaultOpen ? "is-open" : "";

  const tbodyClasses = [collaspibleClass, openClass].filter(exists).join(" ");

  return (
    <tbody className={tbodyClasses}>
      <tr>
        {headings.map(({ id, label }) => (
          <td data-th={t(label)} key={id}>
            {props[id]}
          </td>
        ))}

        {collapsible && (
          <td
            className="icon--toggle text--center"
            data-hook="toggle-collapsible"
            onClick={toggleCollapsible}
            onKeyDown={onKeyDown}
            role="presentation"
          >
            <span
              className="icon-only text--bigger toggle--open"
              data-icon="keyboard_arrow_up"
            />

            <span
              className="icon-only text--bigger toggle--closed"
              data-icon="keyboard_arrow_down"
            />
          </td>
        )}
      </tr>

      {children && (
        <tr>
          <td colSpan={headings.length + utilityHeadingCount}>
            <div className={itemClass}>
              <div className={contentClass}>{children}</div>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

TableRow.propTypes = {
  children: PropTypes.node,
  collapsible: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onKeyDown: PropTypes.func,
  t: PropTypes.func,
  utilityHeadingCount: PropTypes.number,
};

TableRow.defaultProps = {
  children: null,
  collapsible: false,
  defaultOpen: false,
  headings: [],
  onKeyDown: () => {},
  t: () => {},
  utilityHeadingCount: 0,
};

export default TableRow;
