/** @jsxImportSource @emotion/react */
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./MenuItem.styles";

const MenuItem = ({
  active = false,
  icon = "",
  isExit = false,
  onClick = () => {},
  quidBase,
  text = "",
  disabled = false,
  pageError = false,
}) => (
  <button
    css={styles}
    className={classNames("item", active && "is-active", isExit && "is-exit")}
    data-icon={icon}
    data-quid={quidBase}
    disabled={disabled}
    onClick={onClick}
  >
    <span className={classNames(pageError && "has-error")} />
    <span className="item--label">{text}</span>
  </button>
);

MenuItem.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  isExit: PropTypes.bool,
  onClick: PropTypes.func,
  quidBase: PropTypes.string.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  pageError: PropTypes.bool,
};

export default MenuItem;
