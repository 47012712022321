import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { resetActiveOrderProduct } from "ducks/orderProduct";

import Tab from "components/Tabs/Tab";
import "components/Tabs/Tabs.css";

const Tabs = ({ goTo, modifier, t, tabs }) => {
  const dispatch = useDispatch();

  const clearActiveProductThenRouteTo = (route) => () => {
    dispatch(resetActiveOrderProduct());
    goTo(route);
  };

  return (
    <nav className={classNames("tabs", modifier && `tabs--${modifier}`)}>
      {tabs.map(
        ({ active, disabled, icon, path, quidBase, text, translate }) => (
          <Tab
            active={active}
            disabled={disabled}
            icon={icon}
            key={path}
            onClick={clearActiveProductThenRouteTo(path)}
            quidBase={quidBase}
            text={translate ? t(text) : text}
          />
        )
      )}
    </nav>
  );
};

Tabs.propTypes = {
  goTo: PropTypes.func.isRequired,
  modifier: PropTypes.string,
  t: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      path: PropTypes.string,
      quidBase: PropTypes.string.isRequired,
      text: PropTypes.string,
      translate: PropTypes.bool,
    })
  ).isRequired,
};

Tabs.defaultProps = {
  modifier: "",
  t: () => {},
};

export default Tabs;
