/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getCurrentLanguage } from "ducks/configuration/selectors";
import { getBases, getSizes } from "ducks/menu/selectors";
import { getActiveOrderProductAvailableSizesByBaseId } from "ducks/order/selectors";

import { OPTION, defaultArea } from "constants/order";

import Base from "components/Base";
import Cheese from "components/Cheese";
import Sauce from "components/Sauce";
import SauceWeightSelection from "components/SauceWeightSelection";
import Size from "components/Size";

import { specialtyPizzaAlertStyles } from "./NewPizzaBuilder.styles";
import { sectionMarginBottom } from "./Pizzas.styles";

const NewPizzaBuilder = ({
  activeArea = defaultArea,
  activeOrderProductAvailableOptions = {},
  activeOrderProductBaseId = "",
  activeOrderProductOptionIds = [],
  activeOrderProductOptions = {
    [defaultArea]: {},
  },
  activeOrderProductSizeId = "",
  activeOrderProductAvailableSizesByBase = [],
  activeOrderProductAvailableBasesBySize = [],
  sizes = [],
  bases = [],
  sauces = [],
  cheese = null,
  activeOrderProductDefaultOptions,
  activeOrderIsSpecialtyPizza,
  activeOrderProductSelected,
  currentTabIsSpecialty,
  deleteOrderProductOption,
  setOrderProduct,
  t = () => {},
}) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const allBases = useSelector(getBases);
  const allSizes = useSelector(getSizes);
  const activeOrderProductAvailableSizesByBaseId = useSelector(
    getActiveOrderProductAvailableSizesByBaseId
  );

  const formatter = new Intl.ListFormat(currentLanguage);

  const getBaseNameById = (baseId) => allBases[baseId]?.baseName ?? "";
  const getSizeNameById = (sizeId) => allSizes[sizeId]?.sizeName ?? "";

  const getDisabledSizeTooltipMessage = () => {
    const baseName = getBaseNameById(activeOrderProductBaseId);
    const validSizes = activeOrderProductAvailableSizesByBase.map((sizeId) =>
      getSizeNameById(sizeId)
    );

    return t("negative:invalid_crust_or_size", {
      thing: baseName,
      list: formatter.format(validSizes),
    });
  };

  const getDisabledBaseTooltipMessage = (base) => {
    const validSizes =
      activeOrderProductAvailableSizesByBaseId[base.baseCode]?.map?.((sizeId) =>
        getSizeNameById(sizeId)
      ) ?? [];

    return t("negative:invalid_crust_or_size", {
      thing: base.baseName,
      list: formatter.format(validSizes),
    });
  };

  const mapBases = (bases) =>
    bases.map((base) => {
      const disabled =
        !!activeOrderProductBaseId &&
        !activeOrderProductAvailableBasesBySize.includes(base.baseId);
      return (
        <Base
          activeOrderProductBaseId={activeOrderProductBaseId}
          key={base.baseId}
          setOrderProduct={setOrderProduct}
          disabled={disabled}
          tooltipMessage={disabled ? getDisabledBaseTooltipMessage(base) : ""}
          {...base}
        />
      );
    });

  const mapSizes = (sizes) =>
    sizes.map((size) => {
      const disabled =
        !!activeOrderProductSizeId &&
        !activeOrderProductAvailableSizesByBase.includes(size.sizeId);

      return (
        <Size
          activeOrderProductSizeId={activeOrderProductSizeId}
          key={size.sizeId}
          setOrderProduct={setOrderProduct}
          disabled={disabled}
          tooltipMessage={disabled ? getDisabledSizeTooltipMessage() : ""}
          {...size}
        />
      );
    });

  const activeToppingProps = {
    activeArea,
    activeOrderProductAvailableOptions,
    activeOrderProductOptionIds,
    activeOrderProductOptions,
  };

  const showSizeAndCrust = !currentTabIsSpecialty || activeOrderProductSelected;

  return (
    <>
      {showSizeAndCrust && (
        <>
          <div className="columns shared" css={sectionMarginBottom}>
            <div className="subsection">
              <header className="title-group">
                <h2 data-quid="heading-sizes" id="size">
                  {t("food:sizes")}
                </h2>
              </header>

              <div className="btn-group btn-group--vertical" data-quid="sizes">
                {mapSizes(sizes)}
              </div>
            </div>

            <div className="subsection">
              <header className="title-group">
                <h2 data-quid="heading-crust" id="crust">
                  {t("food:crust")}
                </h2>
              </header>

              <div className="btn-group btn-group--vertical" data-quid="bases">
                {mapBases(bases)}
              </div>
            </div>
          </div>

          {activeOrderIsSpecialtyPizza && !sauces.length && !cheese && (
            <div css={specialtyPizzaAlertStyles}>
              <p>{t("negative:specialty_pizza_not_configurable")}</p>
            </div>
          )}
        </>
      )}

      {sauces.length > 0 && cheese && (
        <>
          <div className="columns shared">
            <div className="subsection">
              <header className="title-group">
                <h2 data-quid="heading-sauces" id="sauce">
                  {t("food:toppings.sauces")}
                </h2>
                <SauceWeightSelection t={t} sauces={sauces} />
              </header>

              <div className="btn-group btn-group--vertical" data-quid="sauces">
                {sauces.map(
                  ({
                    toppingCode,
                    toppingDescription,
                    toppingId,
                    toppingName,
                  }) => (
                    <Sauce
                      activeArea={activeArea}
                      key={toppingId}
                      optionCode={toppingCode}
                      optionDescription={toppingDescription}
                      sauceToppingCode={toppingCode}
                      optionName={toppingName}
                      optionType={OPTION.TOPPING}
                      deleteOrderProductOption={deleteOrderProductOption}
                      setOrderProduct={setOrderProduct}
                      sauces={sauces}
                      {...activeToppingProps}
                    />
                  )
                )}
              </div>
            </div>

            <div className="subsection">
              <header className="title-group">
                <h2 data-quid="heading-cheese" id="cheese">
                  {t("food:toppings.cheese")}
                </h2>
              </header>

              <Cheese
                cheese={cheese}
                activeOrderProductBaseId={activeOrderProductBaseId}
                activeOrderProductDefaultOptions={
                  activeOrderProductDefaultOptions
                }
                t={t}
                {...activeToppingProps}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

NewPizzaBuilder.propTypes = {
  activeArea: PropTypes.string,
  activeOrderProductAvailableOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductBaseId: PropTypes.string,
  activeOrderProductOptionIds: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductOptions: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.number)
  ),
  activeOrderProductSizeId: PropTypes.string,
  activeOrderProductAvailableSizesByBase: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductAvailableBasesBySize: PropTypes.arrayOf(PropTypes.string),
  sizes: PropTypes.arrayOf(PropTypes.object),
  bases: PropTypes.arrayOf(PropTypes.object),
  sauces: PropTypes.arrayOf(PropTypes.object),
  cheese: PropTypes.object,
  activeOrderProductDefaultOptions: PropTypes.arrayOf(PropTypes.object),
  activeOrderIsSpecialtyPizza: PropTypes.bool,
  activeOrderProductSelected: PropTypes.bool,
  currentTabIsSpecialty: PropTypes.bool.isRequired,
  deleteOrderProductOption: PropTypes.func.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default NewPizzaBuilder;
