export default function formatPhone(input = "") {
  if (typeof input !== "string") return null;

  const phone = input.replace(/[^\d]/g, "");

  if (phone.length === 10)
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");

  return null;
}

export const formatPhoneWhileTyping = (value) => {
  const splitNumber = value
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  const maskedValue = !splitNumber[2]
    ? splitNumber[1]
    : "(" +
      splitNumber[1] +
      ") " +
      splitNumber[2] +
      (splitNumber[3] ? "-" + splitNumber[3] : "");
  return maskedValue;
};
