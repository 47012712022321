import moment from "moment";

import { SERVICE_METHOD } from "constants/order";

export default ({
  currentServiceMethod = SERVICE_METHOD.CARRYOUT,
  storeUTCOffset,
}) => {
  const now = moment().utcOffset(storeUTCOffset);

  /**
   * @function isValidCoupon
   * @param {startDate: Date, validDays: String[], startTime: String[], endTime: String[], validServiceMethods: String[]}
   * @return {Boolean}
   *
   * @example
   * // Represents a coupon that is valid after April 19th, 2009. Can only be used on Mondays, Tuesdays,
   * // Thursdays and Fridays. And is only valid during the hours of 11a-1p.
   * // And is only valid for the Carryout and Carside service methods.
   * {
   *   startDate: "2009-04-19",
   *   validDays: ['Mo', 'Tu', 'Th', 'Fr'],
   *   startTime: ['11', '00', '00'],
   *   endTimeTime: ['13', '00', '00']
   *   validServiceMethods: ['Carryout', 'Carside']
   * }
   */
  return ({
    endTime = [],
    startDate,
    startTime = [],
    validDays = [],
    validServiceMethods = [],
  }) => {
    if (validServiceMethods.length) {
      const serviceMethod =
        currentServiceMethod === SERVICE_METHOD.PICKUP_WINDOW
          ? SERVICE_METHOD.CARRYOUT
          : currentServiceMethod;

      if (!validServiceMethods.includes(serviceMethod)) return false;
    }

    if (startDate) {
      const startDateWithTimeZone = moment(startDate).utcOffset(storeUTCOffset);
      if (now.isBefore(startDateWithTimeZone)) return false;
    }

    if (validDays.length) {
      if (!validDays.includes(now.format("dd"))) return false;
    }

    if (startTime.length && endTime.length) {
      const [startHour, startMinute, startSecond] = startTime;
      const [endHour, endMinute, endSecond] = endTime;

      const startDate = moment(now)
        .hour(startHour)
        .minute(startMinute)
        .second(startSecond);
      const endDate = moment(now)
        .hour(endHour)
        .minute(endMinute)
        .second(endSecond);

      if (!now.isBetween(startDate, endDate)) return false;
    }

    return true;
  };
};
