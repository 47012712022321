import { SET_MENU } from "ducks/menu";

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_MENU:
      return action.sizes;
    default:
      return state;
  }
}
