import React, { PureComponent } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { AREA, defaultArea } from "constants/order";
import kebabCase from "modules/kebabCase";

class Area extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAreaClick = this.handleAreaClick.bind(this);
  }

  handleAreaClick() {
    const { area, setActiveArea } = this.props;

    setActiveArea({ area });
  }

  render() {
    const { active, area, t } = this.props;
    const name = (AREA[area] || AREA[defaultArea]).name;
    // Name is expected to be a translation string, i.e. a colon-separated
    // series of keys. Grab the last key in the series to use in our quid,
    // e.g. product:size:extra_large => extra-large.
    const quidSuffix = kebabCase(name.match(/\w+$/)[0]);

    return (
      <button
        className={classNames("btn", active && "btn--primary")}
        key={area}
        onClick={this.handleAreaClick}
        data-quid={`area-${quidSuffix}`}
        type="button"
      >
        {t(name)}
      </button>
    );
  }
}

Area.propTypes = {
  active: PropTypes.bool,
  area: PropTypes.string,
  setActiveArea: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Area.defaultProps = {
  active: true,
  area: defaultArea,
};

export default Area;
