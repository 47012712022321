import { CC_TYPES } from "constants/finish";

export default (cardNumber = "") => {
  if (/^3[47]/.test(cardNumber)) {
    return CC_TYPES.AMEX;
  }
  if (/^3(?:0[0-5]|[68])/.test(cardNumber)) {
    return CC_TYPES.DINERS;
  }
  if (/^6(?:011|5)/.test(cardNumber)) {
    return CC_TYPES.DISCOVER;
  }
  if (/^(?:2131|1800|35)/.test(cardNumber)) {
    return CC_TYPES.JCB;
  }
  if (/^(5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]/.test(cardNumber)) {
    return CC_TYPES.MAESTRO;
  }
  if (/^5[1-5]/.test(cardNumber)) {
    return CC_TYPES.MASTERCARD;
  }
  // taken from https://www.regular-expressions.info/creditcard.html
  if (
    /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)/.test(
      cardNumber
    )
  ) {
    return CC_TYPES.MASTERCARD;
  }
  if (/^3746-22/.test(cardNumber)) {
    return CC_TYPES.OPTIMA;
  }
  if (/^4/.test(cardNumber)) {
    return CC_TYPES.VISA;
  }
  return "";
};
