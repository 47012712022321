import React from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NOT_FOUND } from "redux-first-router";

import {
  COUPONS,
  CUSTOMER,
  EVERYTHING_ELSE,
  EXIT,
  FINISH,
  HOME,
  PIZZAS,
} from "routes";

import { getNewExperienceFeature } from "ducks/market/selectors";
import { resetActiveOrderProduct } from "ducks/orderProduct";

import {
  selectAreAllFieldsValid,
  selectShowAllErrors,
} from "rtk_redux/slices/customerPageSlice";

import MenuItem from "./MenuItem";

export const defaultTabs = [CUSTOMER, HOME, NOT_FOUND];

const Menu = ({
  t,
  tab,
  goToExit,
  goToCustomer,
  goToPizzas,
  goToEverythingElse,
  goToCoupons,
  goToFinish,
  isNavigationDisabled,
  validateRoute,
  hasCoupons,
}) => {
  const dispatch = useDispatch();
  const areAllFieldsValid = useSelector(selectAreAllFieldsValid);
  const showAllErrors = useSelector(selectShowAllErrors);

  const clearActiveProductThenRouteTo = (route) => () => {
    dispatch(resetActiveOrderProduct());
    validateRoute(route);
  };

  const {
    rearrangedNavbar: shouldUseRearrangedNavBar,
    newCustomerPage: showNewCustomerPage,
  } = useSelector(getNewExperienceFeature);

  return (
    // TODO: add status/progress indicators
    <nav className="body__menu" data-quid="primary-nav">
      {!shouldUseRearrangedNavBar && (
        <MenuItem
          active={tab === EXIT}
          icon="exit_to_app"
          onClick={goToExit}
          quidBase="primary-nav-exit"
          text={t("exit:title")}
        />
      )}

      <MenuItem
        active={defaultTabs.includes(tab)}
        icon="person"
        onClick={goToCustomer}
        quidBase="primary-nav-customer"
        text={t("customer:title")}
        pageError={
          showNewCustomerPage &&
          !areAllFieldsValid &&
          showAllErrors &&
          !defaultTabs.includes(tab)
        }
      />

      {hasCoupons && shouldUseRearrangedNavBar && (
        <MenuItem
          active={tab === COUPONS}
          icon="local_offer"
          onClick={clearActiveProductThenRouteTo(goToCoupons)}
          quidBase="primary-nav-coupons"
          text={t("coupons:title")}
          disabled={isNavigationDisabled}
        />
      )}

      <MenuItem
        active={tab === PIZZAS}
        icon="local_pizza"
        onClick={clearActiveProductThenRouteTo(goToPizzas)}
        quidBase="primary-nav-pizzas"
        text={t("pizzas:title")}
        disabled={isNavigationDisabled}
      />

      <MenuItem
        active={tab === EVERYTHING_ELSE}
        icon="local_drink"
        onClick={clearActiveProductThenRouteTo(goToEverythingElse)}
        quidBase="primary-nav-everything-else"
        text={t("everything_else:title")}
        disabled={isNavigationDisabled}
      />

      {hasCoupons && !shouldUseRearrangedNavBar && (
        <MenuItem
          active={tab === COUPONS}
          icon="local_offer"
          onClick={clearActiveProductThenRouteTo(goToCoupons)}
          quidBase="primary-nav-coupons"
          text={t("coupons:title")}
          disabled={isNavigationDisabled}
        />
      )}

      <MenuItem
        active={tab === FINISH}
        icon={shouldUseRearrangedNavBar ? "attach_money" : "check"}
        onClick={clearActiveProductThenRouteTo(goToFinish)}
        quidBase="primary-nav-finish"
        text={t(
          shouldUseRearrangedNavBar ? "finish:place_order" : "finish:title"
        )}
        disabled={isNavigationDisabled}
      />

      {shouldUseRearrangedNavBar && (
        <MenuItem
          active={tab === EXIT}
          icon="exit_to_app"
          isExit={true}
          onClick={goToExit}
          quidBase="primary-nav-exit"
          text={t("exit:exit_order")}
        />
      )}
    </nav>
  );
};

Menu.propTypes = {
  goToExit: PropTypes.func,
  goToCustomer: PropTypes.func,
  goToPizzas: PropTypes.func,
  goToEverythingElse: PropTypes.func,
  goToCoupons: PropTypes.func,
  goToFinish: PropTypes.func,
  isNavigationDisabled: PropTypes.bool,
  validateRoute: PropTypes.func,
  hasCoupons: PropTypes.bool,
  t: PropTypes.func,
  tab: PropTypes.string,
};

export default Menu;
