import { get12HourTime, getDateWithMonth } from "modules/futureTime";

export const formatStoreAddress = (order, t) => (
  <div>
    <p>
      <b>{t("customer:locations.store.store_address")}</b>
    </p>
    <address>
      {order.store.streetName}
      <br />
      {`${order.store.city}, ${order.store.region}`}
      <br />
      {order.store.phone}
    </address>
  </div>
);

export const formatDeliveryAddress = (order, t) => (
  <div className="address">
    <p>
      <b>{t("customer:profile.recent_locations.delivery_address")}</b>
    </p>
    <address>
      {order.deliveryAddress.street}
      <br />
      {`${order.deliveryAddress.city}, ${order.deliveryAddress.region} ${order.deliveryAddress.postalCode}`}
    </address>
  </div>
);

export const formatDateTime = (orderDateTime) => {
  const dateTime = new Date(orderDateTime);
  const hours = dateTime.getHours();
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");

  const date = getDateWithMonth(dateTime);
  const time = get12HourTime(`${hours}:${minutes}`).toLocaleLowerCase();

  return `${date} ${time}`;
};
