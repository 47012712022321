import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import { pizzasRoute } from "routes";

import { buildAddressString } from "ducks/address/helpers";
import { setMessage } from "ducks/message";
import { updateOrderStore } from "ducks/order";
import { locateStores } from "ducks/storeLocation";

import { STATUS } from "constants/message";
import isDelivery from "modules/isDelivery";

export const SET_ORDER_LOCATION = "order-entry/order/SET_ORDER_LOCATION";

export function setOrderLocation({
  address = {},
  isOpen = true,
  orderAddressLink = "",
  orderId = null,
  orderStoreLink = "",
  serviceMethod = "",
  storeId = null,
} = {}) {
  return {
    type: SET_ORDER_LOCATION,
    address,
    isOpen,
    orderAddressLink,
    orderId,
    orderStoreLink,
    serviceMethod,
    storeId,
  };
}

export const setOrderLocationEpic = (action$) =>
  action$.pipe(
    ofType(SET_ORDER_LOCATION),
    mergeMap(
      ({
        address: { addressId, ...query },
        isOpen,
        orderAddressLink,
        orderId,
        orderStoreLink,
        serviceMethod,
        storeId,
      }) => {
        if (isDelivery(serviceMethod)) {
          return [
            locateStores({
              query: Object.assign(query, {
                address: buildAddressString(query),
              }),
              setOrderStore: true,
            }),
          ].concat(
            isOpen
              ? pizzasRoute()
              : setMessage({
                  message: "customer:profile.recent_locations.store_closed",
                  status: STATUS.WARNING,
                })
          );
        }
        return [
          locateStores({
            query: Object.assign(query, {
              address: buildAddressString(query),
            }),
            setOrderStore: false,
          }),
          updateOrderStore({
            addressId,
            orderAddressLink,
            orderId,
            storeId,
            url: orderStoreLink,
          }),
        ].concat(
          isOpen
            ? pizzasRoute()
            : setMessage({
                message: "customer:profile.recent_locations.store_closed",
                status: STATUS.WARNING,
              })
        );
      }
    )
  );
