import { css } from "@emotion/react";

export default css`
  flex: 0 0 auto;
  margin: 0;
  position: initial;

  .nav__item:last-child {
    border-right: 0;
  }

  @media screen and (max-width: 60em) {
    .nav__item--logo a {
      margin-left: 0.5rem;
    }

    .nav__item--button--sidebar-toggle {
      border-right: 1px solid #fff;
    }
  }
`;
