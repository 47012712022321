import React from "react";

import PropTypes from "prop-types";

import { orderHistoryHeadings } from "constants/customer";

import TableHeading from "components/TableHeading";

export const UTILITY_HEADING_COUNT = 1;

const OrderHistoryHeading = ({ t }) => (
  <TableHeading
    headings={orderHistoryHeadings}
    t={t}
    utilityHeadingCount={UTILITY_HEADING_COUNT}
  />
);

OrderHistoryHeading.propTypes = {
  t: PropTypes.func,
};

OrderHistoryHeading.defaultProps = {
  t: () => {},
};

export default OrderHistoryHeading;
