import React from "react";

import PropTypes from "prop-types";

const EstimatedWaitTimes = ({
  currentEstimatedWaitMinutes: { Max = "", Min = "" },
  defaultWaitTime,
  t,
  waitTimeReason,
}) => {
  if (!waitTimeReason && defaultWaitTime)
    return (
      <span>
        {defaultWaitTime} {t("shared:time.minutes")}
      </span>
    );

  return (
    <span>
      {Min}
      &mdash;
      {Max} {t("shared:time.minutes")}{" "}
      {waitTimeReason
        ? t("shared:wait_time_reason", {
            reason: t(
              `shared:wait_time_reasons:${waitTimeReason.replace(/[.-]/g, "_")}`
            ),
          })
        : ""}
    </span>
  );
};

EstimatedWaitTimes.propTypes = {
  currentEstimatedWaitMinutes: PropTypes.shape({
    Max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    Min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  defaultWaitTime: PropTypes.string,
  t: PropTypes.func,
  waitTimeReason: PropTypes.string,
};

EstimatedWaitTimes.defaultProps = {
  currentEstimatedWaitMinutes: { Max: "", Min: "" },
  defaultWaitTime: "",
  t: () => {},
  waitTimeReason: "",
};

export default EstimatedWaitTimes;
