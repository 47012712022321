import is from "is_js";

export const idValidators = Object.freeze([
  is.existy,
  is.not.null,
  is.not.empty,
]);

export const optionalUrlValidators = Object.freeze([is.string, is.not.null]);

export const urlValidators = Object.freeze([is.string, is.not.empty]);

export const stringValidators = urlValidators;

export default idValidators;
