/* Taken from NOLO cc validation, based on 
https://en.wikipedia.org/wiki/Luhn_algorithm */
export default (value) => {
  var nCheck = 0,
    nDigit = 0,
    bEven = false,
    n,
    cDigit;

  for (n = value.length - 1; n >= 0; n--) {
    cDigit = value.charAt(n);
    nDigit = parseInt(cDigit, 10);
    if (bEven) {
      if ((nDigit *= 2) > 9) {
        nDigit -= 9;
      }
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 === 0;
};
