import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ROUTE_TITLES } from "routes";

import { getCustomerSavedCreditCards } from "ducks/customer/selectors";
import { getNewExperienceFeature } from "ducks/market/selectors";
import { priceValidateOrder } from "ducks/order";
import { getCurrentOrder } from "ducks/order/selectors";
import { getCurrentStoreId } from "ducks/store/selectors";
import getLinkFromRelationship from "selectors/related";

import { useLazyTokenizeCardNumberQuery } from "rtk_redux/api/externalApi";
import { useLazyGetTokenizeTemplateQuery } from "rtk_redux/api/paymentGatewayServiceApi";
import { selectAreAllFieldsValid } from "rtk_redux/slices/customerPageSlice";
import {
  getShowTip,
  selectCardType,
  selectExpirationMonth,
  selectExpirationYear,
  selectInvalid,
  selectPaymentType,
  selectPreviousCardType,
  selectSavedCCSecurityCode,
  selectSecurityCode,
  selectTip,
  selectTipAmount,
  selectTipAmountNumeric,
  selectZipCode,
  setCardTypeValue,
  setExpirationMonthValue,
  setExpirationYearValue,
  setInvalidValue,
  setPaymentProcessingErrorValue,
  setPaymentTypeErrorValue,
  setPaymentTypeValue,
  setPreviousCardType,
  setSecurityCodeValue,
  setTip,
  setTipAmount,
  setZipCodeValue,
} from "rtk_redux/slices/finishSlice";
import { selectRequireSavedCreditCardCVV } from "rtk_redux/slices/priceOrderSlice";

import {
  ACI,
  CC,
  CC_Fields,
  PAYMENT_TYPES,
  TIP_AMOUNTS,
  TIP_KEYS,
} from "constants/finish";
import { COUNTRY_CODES } from "constants/market";
import { SERVICE_METHOD } from "constants/order";
import { RNC_FINAL_CONSUMER } from "constants/rnc";
import doValidation from "modules/doValidation";
import { formatOrderInfoCollection } from "modules/formatOrderInfoCollection";
import getCreditCardType from "modules/getCreditCardType";
import lastTwoDigits from "modules/lastTwoDigits";
import { tokenizeCreditCard } from "modules/tokenizeCreditCard";

import Checkout from "components/Finish/Checkout";
import Loader from "components/Loader";
import Page from "components/Page";
import WaterfallUpsell from "components/WaterfallUpsell";

const Finish = ({
  acceptableCreditCards = [],
  acceptablePaymentTypes = [PAYMENT_TYPES.CASH],
  applyWaterfallUpsell,
  currentServiceMethod = SERVICE_METHOD.CARRYOUT,
  currentStoreSummary = "",
  currentOrderId = "",
  customerEmail = "",
  customerTaxCodes = [],
  marketCode,
  orderStarted = false,
  carryoutWaitTimeReason = "",
  currentOrderDeliveryFee = 0,
  currentOrderFoodAndBeverage = 0,
  currentOrderFutureTime = "",
  currentOrderSavings = 0,
  currentOrderTax = 0,
  currentOrderTiming = "",
  currentOrderTotal = 0,
  deliveryWaitTimeReason = "",
  currentDeliveryInstructions = "",
  estimatedWaitMinutes = {},
  isMinimumCustomerDataComplete = false,
  isWaterfallUpsell = false,
  loading = {},
  marketConfigs = {
    SINGLE_LINE_ITEM_MAX_QTY: 25,
  },
  placeOrder,
  productsLink = "",
  profileFields = {},
  rncCompanyName = "",
  rncLookupNumber,
  rncNumber = "",
  rncReceiptType = "",
  rncSetCompanyName,
  rncSetNumber,
  rncSetReceiptType,
  sidebar = null,
  t = () => {},
  updateCustomerEmail,
  updateAddress,
  waterfallUpsells = [],
}) => {
  const dispatch = useDispatch();
  const [creditCardNumber, setCreditCardNumber] = useState("");

  const {
    ALLOWED_PAYMENT_TYPES,
    DELIVERY_INSTRUCTIONS_MAX_LENGTH,
    DEFAULT_CARRYOUT_WAIT_TIME,
    DEFAULT_DELIVERY_WAIT_TIME,
    SINGLE_LINE_ITEM_MAX_QTY,
  } = marketConfigs;

  const paymentList = acceptablePaymentTypes.filter((type) =>
    ALLOWED_PAYMENT_TYPES[currentServiceMethod].includes(type)
  );

  const [deliveryInstructions, setDeliveryInstructions] = useState(
    currentDeliveryInstructions
  );
  const [deliveryRemainingLength, setDeliveryRemainingLength] = useState(
    deliveryInstructions
      ? DELIVERY_INSTRUCTIONS_MAX_LENGTH - deliveryInstructions.length
      : DELIVERY_INSTRUCTIONS_MAX_LENGTH
  );

  const {
    automaticPriceOrder: shouldUseAutomaticPriceOrder,
    newCustomerPage: showNewCustomerPage,
    showCarryoutEstimatedServiceTimeForCarsideAndPickupWindow,
  } = useSelector(getNewExperienceFeature);

  const cardType = useSelector(selectCardType);
  const previousCardType = useSelector(selectPreviousCardType);
  const expirationMonth = useSelector(selectExpirationMonth);
  const expirationYear = useSelector(selectExpirationYear);
  const securityCode = useSelector(selectSecurityCode);
  const zipCode = useSelector(selectZipCode);
  const invalid = useSelector(selectInvalid);
  const paymentType = useSelector(selectPaymentType);

  const showTip = useSelector(getShowTip);
  const tip = useSelector(selectTip);
  const tipAmount = useSelector(selectTipAmount);
  const tipAmountNumeric = useSelector(selectTipAmountNumeric);
  const tipLimitExceeded = tipAmountNumeric > currentOrderTotal * 4;

  const [customerSavedCreditCard] = useSelector(getCustomerSavedCreditCards);
  const savedCCSecurityCode = useSelector(selectSavedCCSecurityCode);

  const storeId = useSelector(getCurrentStoreId);

  const [getTokenizeTemplate] = useLazyGetTokenizeTemplateQuery();
  const [tokenizeCardNumber] = useLazyTokenizeCardNumberQuery();
  const currentOrder = useSelector(getCurrentOrder);
  const priceValidateOrderLink =
    getLinkFromRelationship("priceOrder")(currentOrder);

  const areNewCustomerPageErrors = !useSelector(selectAreAllFieldsValid);

  const requireSavedCreditCardCVV = useSelector(
    selectRequireSavedCreditCardCVV
  );

  useEffect(() => {
    if (shouldUseAutomaticPriceOrder && currentOrderId) {
      dispatch(
        priceValidateOrder({
          orderId: currentOrderId,
          url: priceValidateOrderLink,
        })
      );
    }
  }, [
    shouldUseAutomaticPriceOrder,
    currentOrderId,
    priceValidateOrderLink,
    dispatch,
  ]);

  useEffect(() => {
    const [storePaymentType] = paymentList;
    if (paymentList.length === 1 && storePaymentType !== paymentType)
      dispatch(setPaymentTypeValue(storePaymentType));
  }, [paymentType, paymentList, dispatch]);

  useEffect(() => {
    if (!currentOrderTotal) return;

    if (tip !== TIP_KEYS.CUSTOM) {
      const tipWhole = currentOrderTotal * (TIP_AMOUNTS[tip] / 100);
      const tipRounded = Math.round(tipWhole * 100) / 100;
      dispatch(setTipAmount(tipRounded.toString()));
    }
  }, [tip, currentOrderTotal, dispatch]);

  const canCheckOut = () => {
    if (paymentType === PAYMENT_TYPES.CREDIT_CARD) {
      // check if any cc field is invalid
      const invalidForm = Object.keys(CC).some((key) =>
        invalid.includes(CC[key])
      );
      if (invalidForm) return false;

      // check if any cc field is empty
      const form = document.querySelector("#ccform");
      if (
        !form?.elements[CC.CARD_NUMBER]?.value ||
        !expirationMonth ||
        !expirationYear ||
        !securityCode ||
        !zipCode
      ) {
        return false;
      }
    }

    if (paymentType === PAYMENT_TYPES.SAVED_CREDIT_CARD) {
      if (invalid.includes(CC.SAVED_SECURITY_CODE)) return false;
      if (requireSavedCreditCardCVV && !savedCCSecurityCode) return false;
    }

    if (
      tip === TIP_KEYS.CUSTOM &&
      (paymentType === PAYMENT_TYPES.CREDIT_CARD ||
        paymentType === PAYMENT_TYPES.SAVED_CREDIT_CARD)
    ) {
      if (!tipAmount) return false;
      if (invalid.includes(CC.TIP_AMOUNT)) return false;
    }

    if (
      marketConfigs.FORCE_CUSTOMER_REQUIRED_FIELDS &&
      !isMinimumCustomerDataComplete
    )
      return false;
    if (!orderStarted) return false;
    if (!paymentType) return false;
    if (
      marketCode === COUNTRY_CODES.DOMINICAN_REPUBLIC &&
      rncReceiptType !== RNC_FINAL_CONSUMER &&
      !rncCompanyName
    )
      return false;

    if (showNewCustomerPage && areNewCustomerPageErrors) {
      return false;
    }

    return true;
  };

  const handleCCChange = (value, id) => {
    switch (id) {
      case CC.CARD_NUMBER:
        setCreditCardNumber(value);
        // remove spaces and/or dashes in card number
        const newCardType = getCreditCardType(value);
        if (
          newCardType &&
          previousCardType &&
          newCardType !== previousCardType
        ) {
          dispatch(setExpirationMonthValue(""));
          dispatch(setExpirationYearValue(""));
          dispatch(setSecurityCodeValue(""));
        }
        if (newCardType) {
          dispatch(setPreviousCardType(newCardType));
        }
        dispatch(setCardTypeValue(newCardType));
        break;
      case CC.EXPIRATION_MONTH:
        dispatch(setExpirationMonthValue(value));
        break;
      case CC.EXPIRATION_YEAR:
        dispatch(setExpirationYearValue(value));
        break;
      case CC.SECURITY_CODE:
        dispatch(setSecurityCodeValue(value));
        break;
      case CC.ZIP_CODE:
        dispatch(setZipCodeValue(value));
        break;
    }
  };

  const handleTipChange = (tipKey) => (e) => {
    e.preventDefault();
    dispatch(setTip(tipKey));

    if (tipKey === TIP_KEYS.CUSTOM) {
      dispatch(setTipAmount(""));
    }
  };

  const handleSavedCCBlur = (value, id) => {
    const type = "number";
    const valid = value ? doValidation(value, type, marketCode) : false;

    if (!valid) {
      dispatch(setInvalidValue([...invalid, id]));
    }
  };

  const handleTipAmountBlur = (id) => (value) => {
    const type = "any";
    if (tipLimitExceeded) {
      dispatch(setInvalidValue([...invalid, id]));
      return;
    }

    const valid = value ? doValidation(value, type, marketCode) : false;

    if (valid) {
      dispatch(
        setInvalidValue(invalid.filter((invalidValue) => invalidValue !== id))
      );
    } else {
      dispatch(setInvalidValue([...invalid, id]));
    }
  };

  const handleCCBlur = (value, id) => {
    const { type } = CC_Fields[id];
    const isCardNumber = id === CC.CARD_NUMBER;
    const isExpirationMonth = id === CC.EXPIRATION_MONTH;
    const isExpirationYear = id === CC.EXPIRATION_YEAR;
    const isZipCode = id === CC.ZIP_CODE;

    if (isZipCode || isCardNumber) {
      // ignore spaces and dashes in card number and zip code
      value = value.replace(/-|\s/g, "");
    }
    const cardType = getCreditCardType(value);
    const valid = value
      ? doValidation(
          value,
          type,
          marketCode,
          cardType,
          acceptableCreditCards,
          expirationMonth,
          expirationYear
        )
      : false;

    // store expiration month and year in state even if invalid - need for validation
    if (isExpirationMonth) {
      dispatch(setExpirationMonthValue(value.replace(/[^0-9]/g, "")));
    }
    if (isExpirationYear) {
      dispatch(setExpirationYearValue(value.replace(/[^0-9]/g, "")));
    }

    if (valid) {
      if (isCardNumber) {
        dispatch(setCardTypeValue(cardType));
      }
      // if expiration month or year is valid, mark the other as valid as well
      if (isExpirationMonth || isExpirationYear) {
        dispatch(
          setInvalidValue(
            invalid.filter(
              (prop) =>
                prop !== CC.EXPIRATION_MONTH && prop !== CC.EXPIRATION_YEAR
            )
          )
        );
      }
    } else {
      dispatch(setInvalidValue([...invalid, id]));

      // if expiration month or year is invalid, mark the other as invalid as well
      if (isExpirationMonth || isExpirationYear) {
        dispatch(
          setInvalidValue([...invalid, CC.EXPIRATION_MONTH, CC.EXPIRATION_YEAR])
        );
      }
    }
  };

  const handleDeliveryInstructionsBlur = () => {
    deliveryInstructions && updateAddress({ deliveryInstructions });
  };

  const handleDeliveryInstructionsChange = ({ target: { value } }) => {
    setDeliveryInstructions(value);

    setDeliveryRemainingLength(DELIVERY_INSTRUCTIONS_MAX_LENGTH - value.length);
    currentDeliveryInstructions = "";
  };

  const handlePlaceOrderClick = async (e) => {
    e.preventDefault();
    const orderInfoCollection =
      marketCode === COUNTRY_CODES.DOMINICAN_REPUBLIC
        ? formatOrderInfoCollection({
            rncCompanyName,
            rncNumber,
            rncReceiptType,
          })
        : [];
    const paymentInfo = { paymentType, amount: currentOrderTotal };
    if (paymentType === PAYMENT_TYPES.CREDIT_CARD) {
      paymentInfo[CC.CARD_TYPE] = cardType;
      paymentInfo[CC.EXPIRATION_MONTH] = expirationMonth;
      paymentInfo[CC.EXPIRATION_YEAR] = expirationYear;
      paymentInfo.cvv = securityCode;
      paymentInfo.postalCode = zipCode;

      if (showTip) {
        paymentInfo.tipAmount = tipAmountNumeric;
      }

      const { data } = await tokenizeCreditCard({
        getTokenizeTemplate,
        tokenizeCardNumber,
        storeId,
        cardType,
        failureTokenType: ACI,
        tokenizationRetries: 0,
        cardNum: creditCardNumber,
        expirationMonth,
        expirationYear,
        totalRetries: 0,
      });
      if (data) {
        paymentInfo.tokenType = data.tokenType;
        paymentInfo.token = data.token;
        paymentInfo.maskedCreditCardNumber = data.maskedPan;
        placeOrder({
          orderInfoCollection,
          paymentInfo,
        });
      } else {
        dispatch(setPaymentProcessingErrorValue(true));
      }
    } else if (paymentType === PAYMENT_TYPES.SAVED_CREDIT_CARD) {
      paymentInfo.paymentType = PAYMENT_TYPES.CREDIT_CARD;
      paymentInfo.cardId = customerSavedCreditCard.id;
      paymentInfo.cardType = customerSavedCreditCard.cardType.toLowerCase();
      paymentInfo.expirationMonth = customerSavedCreditCard.expirationMonth;
      paymentInfo.expirationYear = lastTwoDigits(
        customerSavedCreditCard.expirationYear
      );
      paymentInfo.postalCode = customerSavedCreditCard.billingZip;
      paymentInfo.cvv = savedCCSecurityCode;

      if (showTip) {
        paymentInfo.tipAmount = tipAmountNumeric;
      }

      placeOrder({
        orderInfoCollection,
        paymentInfo,
      });
    } else {
      paymentType
        ? placeOrder({
            orderInfoCollection,
            paymentInfo,
          })
        : dispatch(setPaymentTypeErrorValue(true));
    }
  };

  const handleProfileBlur = ({ target: { id, value } }) => {
    if (doValidation(value, profileFields[id].type)) updateCustomerEmail(value);
    else dispatch(setInvalidValue(Array.from(new Set([...invalid, id]))));
  };

  const handleProfileFocus = ({ target: { id } }) => {
    dispatch(setInvalidValue(invalid.filter((prop) => prop !== id)));
  };

  const currentEstimatedWaitMinutes =
    showCarryoutEstimatedServiceTimeForCarsideAndPickupWindow &&
    [SERVICE_METHOD.DCD, SERVICE_METHOD.PICKUP_WINDOW].includes(
      currentServiceMethod
    )
      ? estimatedWaitMinutes[SERVICE_METHOD.CARRYOUT]
      : estimatedWaitMinutes[currentServiceMethod];

  const waitTimeReason =
    currentServiceMethod === "Delivery"
      ? deliveryWaitTimeReason
      : carryoutWaitTimeReason;

  const defaultWaitTime =
    currentServiceMethod === "Delivery"
      ? DEFAULT_DELIVERY_WAIT_TIME
      : DEFAULT_CARRYOUT_WAIT_TIME;

  const footer = (
    <button
      className="btn btn--positive btn--fill"
      data-quid="place-order"
      disabled={!canCheckOut() || loading.placeOrder}
      onClick={handlePlaceOrderClick}
    >
      {t("finish:place_order")}
    </button>
  );

  const title = "finish:title";
  const subtitle = "finish:subtitle";

  const acceptableCreditCardNames =
    acceptableCreditCards.slice(0, -1).join(", ") +
    ", and " +
    acceptableCreditCards.slice(-1);

  if (isWaterfallUpsell)
    return (
      <WaterfallUpsell
        currentOrderId={currentOrderId}
        productsLink={productsLink}
        upsellItems={waterfallUpsells}
        sidebar={sidebar}
        maximumProductQuantity={SINGLE_LINE_ITEM_MAX_QTY}
        applyWaterfallUpsell={applyWaterfallUpsell}
        t={t}
      />
    );

  return (
    <Page
      footer={footer}
      id="exit"
      sidebar={sidebar}
      title={t(title)}
      seoTitle={ROUTE_TITLES.FINISH}
    >
      <form id="ccform" noValidate>
        <Checkout
          currentOrderFoodAndBeverage={currentOrderFoodAndBeverage}
          deliveryInstructionsMaxLength={DELIVERY_INSTRUCTIONS_MAX_LENGTH}
          marketCode={marketCode}
          subtitle={subtitle}
          t={t}
          handleSavedCCBlur={handleSavedCCBlur}
          handleTipAmountBlur={handleTipAmountBlur}
          handleCCBlur={handleCCBlur}
          handleCCChange={handleCCChange}
          handleTipChange={handleTipChange}
          acceptableCreditCardNames={acceptableCreditCardNames}
          handleDeliveryInstructionsBlur={handleDeliveryInstructionsBlur}
          deliveryInstructions={deliveryInstructions}
          deliveryRemainingLength={deliveryRemainingLength}
          handleDeliveryInstructionsChange={handleDeliveryInstructionsChange}
          handleProfileBlur={handleProfileBlur}
          handleProfileFocus={handleProfileFocus}
          currentServiceMethod={currentServiceMethod}
          currentOrderTotal={currentOrderTotal}
          paymentList={paymentList}
          customerTaxCodes={customerTaxCodes}
          rncCompanyName={rncCompanyName}
          rncLookupNumber={rncLookupNumber}
          rncNumber={rncNumber}
          rncReceiptType={rncReceiptType}
          rncSetCompanyName={rncSetCompanyName}
          rncSetNumber={rncSetNumber}
          rncSetReceiptType={rncSetReceiptType}
          COUNTRY_CODES={COUNTRY_CODES}
          customerEmail={customerEmail}
          currentOrderTax={currentOrderTax}
          currentOrderSavings={currentOrderSavings}
          currentOrderDeliveryFee={currentOrderDeliveryFee}
          currentStoreSummary={currentStoreSummary}
          defaultWaitTime={defaultWaitTime}
          currentEstimatedWaitMinutes={currentEstimatedWaitMinutes}
          currentOrderTiming={currentOrderTiming}
          orderStarted={orderStarted}
          currentOrderFutureTime={currentOrderFutureTime}
          waitTimeReason={waitTimeReason}
          customerSavedCreditCard={customerSavedCreditCard}
          showTip={showTip}
          tipLimitExceeded={tipLimitExceeded}
        />
      </form>

      {loading.placeOrder && <Loader />}
    </Page>
  );
};

Finish.propTypes = {
  acceptableCreditCards: PropTypes.arrayOf(PropTypes.string),
  acceptablePaymentTypes: PropTypes.arrayOf(PropTypes.string),
  applyWaterfallUpsell: PropTypes.func.isRequired,
  currentServiceMethod: PropTypes.string,
  currentStoreSummary: PropTypes.string,
  currentOrderId: PropTypes.string,
  customerEmail: PropTypes.string,
  customerTaxCodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  marketCode: PropTypes.string.isRequired,
  orderStarted: PropTypes.bool,
  carryoutWaitTimeReason: PropTypes.string,
  currentOrderDeliveryFee: PropTypes.number,
  currentOrderFoodAndBeverage: PropTypes.number,
  currentOrderFutureTime: PropTypes.string,
  currentOrderSavings: PropTypes.number,
  currentOrderTax: PropTypes.number,
  currentOrderTiming: PropTypes.string,
  currentOrderTotal: PropTypes.number,
  deliveryWaitTimeReason: PropTypes.string,
  currentDeliveryInstructions: PropTypes.string,
  estimatedWaitMinutes: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.number)
  ),
  isMinimumCustomerDataComplete: PropTypes.bool,
  isWaterfallUpsell: PropTypes.bool,
  loading: PropTypes.objectOf(PropTypes.bool),
  marketConfigs: PropTypes.shape({
    ALLOWED_PAYMENT_TYPES: PropTypes.object,
    DEFAULT_CARRYOUT_WAIT_TIME: PropTypes.string,
    DEFAULT_DELIVERY_WAIT_TIME: PropTypes.string,
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: PropTypes.number,
    FORCE_CUSTOMER_REQUIRED_FIELDS: PropTypes.bool,
    SINGLE_LINE_ITEM_MAX_QTY: PropTypes.number,
  }).isRequired,
  placeOrder: PropTypes.func.isRequired,
  productsLink: PropTypes.string,
  profileFields: PropTypes.objectOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  rncCompanyName: PropTypes.string,
  rncLookupNumber: PropTypes.func.isRequired,
  rncNumber: PropTypes.string,
  rncReceiptType: PropTypes.string,
  rncSetCompanyName: PropTypes.func.isRequired,
  rncSetNumber: PropTypes.func.isRequired,
  rncSetReceiptType: PropTypes.func.isRequired,
  sidebar: PropTypes.node,
  t: PropTypes.func,
  updateCustomerEmail: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  waterfallUpsells: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.string,
      productId: PropTypes.string,
      sizeId: PropTypes.string,
    })
  ),
};

export default Finish;
