import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "constants/market";
import { padSingleNumber } from "modules/futureTime";

const formatDateSegments = (date, month, year) => (segment) => {
  let result;

  switch (segment) {
    case "D":
      result = date;
      break;
    case "DD":
      result = padSingleNumber(date);
      break;
    case "M":
      result = month;
      break;
    case "MM":
      result = padSingleNumber(month);
      break;
    case "YY":
      result = year.toString().substring(2, year.length);
      break;
    case "YYYY":
    default:
      result = year;
      break;
  }

  return result;
};

const formatTimeSegments = (hour, minute) => (segment) => {
  let result;

  switch (segment) {
    case "H":
      result = hour;
      break;
    case "HH":
      result = padSingleNumber(hour);
      break;
    case "MM":
    default:
      result = padSingleNumber(minute);
      break;
  }

  return result;
};

export const formatDate = (
  dateTime = new Date(),
  dateFormat = "DD/MM/YYYY"
) => {
  const date = dateTime.getDate();
  const month = dateTime.getMonth() + 1;
  const year = dateTime.getFullYear();

  return dateFormat
    .split("/")
    .map(formatDateSegments(date, month, year))
    .join("/");
};

export const formatTime = (
  dateTime = new Date(),
  timeFormat = "H:MM",
  is24Hour = false
) => {
  let hour = dateTime.getHours();
  const minute = dateTime.getMinutes();
  let isPm = false;

  if (!is24Hour) {
    isPm = hour > 11 || hour === 12;
    hour = isPm && hour !== 12 ? hour - 12 : hour;
  }

  const timeString = timeFormat
    .split(":")
    .map(formatTimeSegments(hour, minute))
    .join(":");

  if (is24Hour) return timeString;

  return `${timeString} ${isPm ? "PM" : "AM"}`;
};

//TODO: improve coverage
export const formatDateTime = ({
  dateTime = new Date(),
  dateFormat = DEFAULT_DATE_FORMAT,
  timeFormat = DEFAULT_TIME_FORMAT,
  timeZoneAware = true,
}) => {
  if (timeZoneAware) {
    const timezoneOffset = dateTime.getTimezoneOffset() * 60000;
    dateTime = new Date(dateTime.getTime() + timezoneOffset);
  }

  const formattedDate = formatDate(dateTime, dateFormat);
  const formattedTime = formatTime(dateTime, timeFormat);

  return [formattedDate, formattedTime].join(", ");
};

export default formatDateTime;
