/** @jsxImportSource @emotion/react */
import { useSelector } from "react-redux";

import {
  getCustomerFormattedPhone,
  getCustomerName,
} from "ducks/customer/selectors";

import {
  selectIsCarplayCustomer,
  selectIsLoyaltyMember,
} from "rtk_redux/slices/customerPageSlice";

import { ReactComponent as PizzaIcon } from "assets/images/loyalty_icon.svg";

import {
  customerHeaderSection,
  loyaltyWrapperStyles,
  rowWithLoyaltyIcon,
} from "./CustomerHeader.styles";

import CarplayIcon from "../../../assets/images/carplay.png";

const HeaderProfile = () => {
  const customerName = useSelector(getCustomerName);
  const customerFormattedPhone = useSelector(getCustomerFormattedPhone);
  const isLoyaltyMember = useSelector(selectIsLoyaltyMember);
  const isCarplayCustomer = useSelector(selectIsCarplayCustomer);

  return (
    <div css={customerHeaderSection}>
      <div css={rowWithLoyaltyIcon}>
        {isCarplayCustomer && customerName && (
          <img src={CarplayIcon} alt="carplay icon" />
        )}
        {isLoyaltyMember && customerName && (
          <div css={loyaltyWrapperStyles}>
            <PizzaIcon />
          </div>
        )}
        <div>
          <p className="customerName">{customerName}</p>
          <address type="phone">{customerFormattedPhone}</address>
        </div>
      </div>
    </div>
  );
};

export default HeaderProfile;
