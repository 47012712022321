import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import {
  catchError,
  concatMap,
  map,
  mapTo,
  merge,
  mergeMap,
  pluck,
  take,
} from "rxjs/operators";

import { finishRoute } from "routes";

import { getNewExperienceFeature } from "ducks/market/selectors";
import { SET_MESSAGE } from "ducks/message";
import { GET_ORDER_FINISH, exitOrder, getOrderSuccess } from "ducks/order";
import { getOrder } from "ducks/order/selectors";
import { getSelfLink } from "selectors/related";

import { setHasUserSelectedStore } from "rtk_redux/slices/customerPageSlice";

import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

import { EXIT_ORDER_SUCCESS } from "./exit";
import { priceValidateOrder } from "./priceValidate";

const SCOPE = "order-entry/order/";

export const REORDER = `${SCOPE}REORDER`;
export const REORDER_ERROR = `${SCOPE}REORDER_ERROR`;
export const REORDER_SUCCESS = `${SCOPE}REORDER_SUCCESS`;

export const reorder = withValidation(
  ({ currentOrderId = null, url = "" } = {}) => ({
    type: REORDER,
    currentOrderId,
    url,
  }),
  {
    currentOrderId: idValidators,
    url: urlValidators,
  }
);

export function reorderError(error) {
  return {
    type: REORDER_ERROR,
    error,
  };
}

export function reorderSuccess(order = {}) {
  return Object.assign({}, order, {
    type: REORDER_SUCCESS,
  });
}

export const reorderEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(REORDER),
    mergeMap(({ currentOrderId, ...action }) =>
      fetch(
        Object.assign(action, {
          query: {
            include: ["address", "coupons", "customer", "products"].join(","),
          },
        }),
        {
          method: "POST",
        }
      ).pipe(
        pluck("response"),
        mergeMap(({ data, included }) =>
          of(getState()).pipe(
            map(getOrder(currentOrderId)),
            map(getSelfLink),
            mergeMap((url) => {
              const state = getState();
              const { newCustomerPage: showNewCustomerPage } =
                getNewExperienceFeature(state);
              return of(
                exitOrder({
                  clearState: false,
                  orderId: currentOrderId,
                  url,
                })
              ).pipe(
                merge(
                  action$.pipe(
                    ofType(EXIT_ORDER_SUCCESS),
                    take(1),
                    mapTo(
                      getOrderSuccess({
                        data,
                        included,
                        skipCustomerLookup: true,
                      })
                    )
                  ),
                  action$.pipe(
                    ofType(GET_ORDER_FINISH),
                    take(1),
                    concatMap(({ orderId, priceOrderLink, skipPriceOrder }) =>
                      []
                        .concat(
                          skipPriceOrder
                            ? []
                            : priceValidateOrder({
                                orderId,
                                url: priceOrderLink,
                              })
                        )
                        .concat(
                          showNewCustomerPage
                            ? [setHasUserSelectedStore(true)]
                            : [finishRoute()]
                        )
                        .concat(
                          data.attributes.productsRemoved
                            ? [
                                {
                                  type: SET_MESSAGE,
                                  interpolation: {},
                                  message:
                                    "finish:order_summary:product_removed",
                                  messageType: "toaster",
                                  status: "negative",
                                },
                              ]
                            : []
                        )
                        .concat(
                          data.attributes.couponsRemoved
                            ? [
                                {
                                  type: SET_MESSAGE,
                                  interpolation: {},
                                  message:
                                    "finish:order_summary:coupon_removed",
                                  messageType: "toaster",
                                  status: "negative",
                                },
                              ]
                            : []
                        )
                    )
                  )
                )
              );
            })
          )
        ),

        catchError((error) => of(reorderError(error)))
      )
    )
  );
