import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { DEFAULT_LOCATOR_STRATEGY } from "constants/locatorStrategy";
import { MARKET_CENTER } from "constants/market";
import { SERVICE_METHOD } from "constants/order";

import RequiredMark from "components/RequiredMark";
import "components/StoreLocator/Market/MY.css";
import DominosTypeAhead from "components/StoreLocator/Strategy/DominosTypeAhead";
import DrivenField from "components/StoreLocator/Strategy/DrivenField";
import GoogleMapsTypeAhead from "components/StoreLocator/Strategy/GoogleMapsTypeAhead";
import Input from "components/StoreLocator/Strategy/Input";
import PinDrop, { MAP_OPTIONS } from "components/StoreLocator/Strategy/PinDrop";
import Select from "components/StoreLocator/Strategy/Select";

const ADDRESS_TYPES = Object.freeze({
  [ADDRESS.LANDED]: ADDRESS_FIELDS[ADDRESS.LANDED].label,
  [ADDRESS.FLAT]: ADDRESS_FIELDS[ADDRESS.FLAT].label,
  [ADDRESS.BUILDING]: ADDRESS_FIELDS[ADDRESS.BUILDING].label,
});

const MARKET_ZOOM = 10;

const placesMethods = Object.freeze(["Carryout", "Delivery"]);

class MY extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streetSelected: false,
      useOtherBuilding: false,
    };

    this.map = React.createRef();

    this.getGeocodedResult = this.getGeocodedResult.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handlePinDrop = this.handlePinDrop.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setDefaultLocatorStrategy = this.setDefaultLocatorStrategy.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateStreet = this.updateStreet.bind(this);
    this.resetStreetSearch = this.resetStreetSearch.bind(this);
  }

  componentDidMount() {
    const { maps, serviceMethod, setUsesPlaces } = this.props;
    this.geocoderService = new maps.Geocoder();
    this.geocoderOK = maps.GeocoderStatus.OK;
    placesMethods.includes(serviceMethod) && setUsesPlaces(true);
  }

  setDefaultLocatorStrategy() {
    this.handleClear();
    this.props.setLocatorStrategy(DEFAULT_LOCATOR_STRATEGY);
  }

  getGeocodedResult(request, callback) {
    this.geocoderService.geocode(request, (response, status) => {
      if (status !== this.geocoderOK) {
        this.props.onError(status);
        return;
      }

      const {
        formatted_address,
        geometry,
        place_id,
        // eslint-disable-next-line camelcase
        address_components = [],
      } = response.find((address) =>
        address.types.includes("street_address")
      ) || response[0];

      const getPoliticalName = (providerField) => {
        const { long_name: longName = "" } =
          address_components.find(({ types = [] }) =>
            types.includes(providerField)
          ) || {};
        return longName;
      };

      const [{ longName: neighborhood = "" } = {}] = [
        "neighborhood",
        "sublocality",
        "sublocality_level_1",
        "political",
      ]
        .map((providerField) => ({
          providerField,
          longName: getPoliticalName(providerField),
        }))
        .filter(({ longName }) => longName);

      callback({
        address: formatted_address.replace(/^(\d*,\s)/, ""),
        geometry,
        neighborhood,
        placeId: place_id,
      });
    });
  }
  handleChange(updates) {
    this.setState({ ...updates });
  }
  handleClear() {
    const { addressType, countryCode } = this.props;
    const addressFields = [
      ADDRESS_FIELDS[ADDRESS.ADDRESS],
      ADDRESS_FIELDS[ADDRESS.BUILDING_NAME],
      ADDRESS_FIELDS[ADDRESS.DELIVERY_INSTRUCTIONS],
      ADDRESS_FIELDS[ADDRESS.STREET_NAME],
      ADDRESS_FIELDS[ADDRESS.ORGANIZATION_NAME],
      ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD],
      ADDRESS_FIELDS[ADDRESS.POSTAL_CODE],
      ADDRESS_FIELDS[ADDRESS.CITY],
    ];

    switch (addressType) {
      case ADDRESS.FLAT:
        addressFields.push(
          ADDRESS_FIELDS[ADDRESS.BLOCK],
          ADDRESS_FIELDS[ADDRESS.LEVEL],
          ADDRESS_FIELDS[ADDRESS.UNIT]
        );
        break;
      case ADDRESS.BUILDING:
        addressFields.push(
          ADDRESS_FIELDS[ADDRESS.UNIT_NUMBER],
          ADDRESS_FIELDS[ADDRESS.BLOCK],
          ADDRESS_FIELDS[ADDRESS.LEVEL],
          ADDRESS_FIELDS[ADDRESS.UNIT]
        );
        break;
      default:
        addressFields.push(ADDRESS_FIELDS[ADDRESS.UNIT_NUMBER]);
    }

    const updates = addressFields.reduce(
      (updatesObject, { mapping: fieldName }) => {
        updatesObject[fieldName] = "";
        return updatesObject;
      },
      {}
    );
    updates.regionCode = countryCode;

    this.setState({
      streetCategory: "",
      streetSelected: false,
      useOtherBuilding: false,
    });
    this.props.handleChange(updates);
  }

  handlePinDrop(latLng) {
    this.getGeocodedResult({ location: latLng }, this.props.handleChange);
  }

  handleSearch() {
    const { address } = this.props;

    this.getGeocodedResult({ address }, ({ geometry }) => {
      this.map.current.dropPin(geometry.location);
    });
  }

  updateStreet({
    city,
    id: streetNameValue,
    neighborhood: neighborhoodValue,
    placesURL,
    postalCode: postalCodeValue,
  }) {
    const { handleChange, getPlaces } = this.props;
    handleChange({
      city,
      streetName: streetNameValue,
      neighborhood: neighborhoodValue,
      postalCode: postalCodeValue,
      organizationName: "",
    });
    getPlaces({ url: placesURL });
    this.setState({
      streetSelected: true,
      useOtherBuilding: false,
    });
  }

  resetStreetSearch() {
    const { handleChange } = this.props;
    this.setState({ streetSelected: false, useOtherBuilding: false });
    handleChange({
      neighborhood: "",
      postalCode: "",
      organizationName: "",
    });
  }

  render() {
    const {
      address,
      addressNickname,
      addressType,
      block,
      deliveryInstructions,
      getStates,
      getStreetCategories,
      getStreets,
      handleChange,
      level,
      maps,
      marketConfigs: { DELIVERY_INSTRUCTIONS_MAX_LENGTH },
      neighborhood,
      organizationName,
      places,
      postalCode,
      regionCode,
      serviceMethod,
      states,
      streetCategories,
      streetName,
      streets,
      t,
      unit,
      unitInfo,
      locatorStrategy,
    } = this.props;

    const { streetCategory, streetSelected, useOtherBuilding } = this.state;
    const isDefaultLocatorStrategy =
      locatorStrategy === DEFAULT_LOCATOR_STRATEGY;
    const areBlockInputsVisible = isDefaultLocatorStrategy || streetSelected;
    const isBuildingVisible = isDefaultLocatorStrategy || streetSelected;
    const isDeliveryInstructionsVisible =
      isDefaultLocatorStrategy || streetSelected;
    const useTextOrganizationalName =
      isDefaultLocatorStrategy || useOtherBuilding;

    const getBuildingNameInput = (className) => (
      <Fragment>
        {!isDefaultLocatorStrategy ? (
          <div className={className}>
            <DrivenField
              className={"grid__cell--1"}
              handleChange={(input) => {
                const value = useOtherBuilding
                  ? input.organizationNameSelector
                  : input.organizationName;
                const isUseOtherBuilding = value === "other";
                if (isUseOtherBuilding) {
                  handleChange({ organizationName: "" });
                  this.setState({ useOtherBuilding: true });
                } else {
                  handleChange({ organizationName: value });
                  this.setState({ useOtherBuilding: false });
                }
              }}
              isReady
              label={t("customer:locations.address.building_complex")}
              name={
                useOtherBuilding
                  ? "organizationNameSelector"
                  : "organizationName"
              }
              quidBase="store-locator-state"
              source={[
                ...places.map((place) => ({
                  key: place,
                  option: place,
                })),
                {
                  key: "other",
                  option: t("customer:locations.address.other"),
                },
              ]}
              type="select"
              value={useOtherBuilding ? "other" : organizationName}
            />
          </div>
        ) : null}
        {useTextOrganizationalName ? (
          <Input
            className={isDefaultLocatorStrategy ? className : "grid__cell--1"}
            handleChange={handleChange}
            label={t(ADDRESS_FIELDS[ADDRESS.BUILDING_NAME].label)}
            name="organizationName"
            quidBase="store-locator-address-field"
            value={organizationName}
            t={t}
          />
        ) : null}
      </Fragment>
    );

    const getBlockInputs = (className, isRequired = false) => (
      <Fragment>
        <Input
          className={`${className}  prepend-slash`}
          handleChange={handleChange}
          label={t(ADDRESS_FIELDS[ADDRESS.BLOCK].label)}
          name="block"
          quidBase="store-locator-address-field"
          value={block}
          t={t}
          labelRequired
          isRequired={isRequired}
        />
        <Input
          className={`${className}  prepend-slash`}
          handleChange={handleChange}
          label={t(ADDRESS_FIELDS[ADDRESS.LEVEL].label)}
          name="level"
          quidBase="store-locator-address-field"
          value={level}
          t={t}
        />
        <Input
          className={className}
          handleChange={handleChange}
          label={t(ADDRESS_FIELDS[ADDRESS.UNIT].label)}
          name="unit"
          quidBase="store-locator-address-field"
          value={unit}
          t={t}
        />
      </Fragment>
    );

    let addressFields;

    switch (addressType) {
      case ADDRESS.FLAT:
        addressFields = (
          <div className="grid__cell--1">
            <div className="locations__buildings grid">
              {areBlockInputsVisible
                ? getBlockInputs("grid__cell--1/6", true)
                : null}

              {isBuildingVisible
                ? getBuildingNameInput("grid__cell--1/2")
                : null}
            </div>
          </div>
        );
        break;
      case ADDRESS.BUILDING:
        addressFields = (
          <div className="grid__cell--1">
            <div className="locations__buildings grid">
              {areBlockInputsVisible ? (
                <Fragment>
                  <Input
                    className="grid__cell--1/5"
                    handleChange={handleChange}
                    label={t(ADDRESS_FIELDS[ADDRESS.UNIT_INFO].label)}
                    name="unitInfo"
                    quidBase="store-locator-address-field"
                    value={unitInfo}
                    t={t}
                    isRequired={!block && !level && !unit}
                    labelRequired
                  />
                  <div className="form__control-group grid__cell address-or-text">
                    {t("customer:locations.address.or")}
                  </div>
                </Fragment>
              ) : null}

              {areBlockInputsVisible
                ? getBlockInputs("grid__cell--1/8", !unitInfo)
                : null}
              {isBuildingVisible
                ? getBuildingNameInput("grid__cell--3/8")
                : null}
            </div>
          </div>
        );
        break;
      default:
        addressFields = (
          <div className="grid__cell--1">
            <div className="locations__buildings grid">
              {areBlockInputsVisible ? (
                <Input
                  className="grid__cell--1/2 grid__cell--1/4@desktop"
                  handleChange={handleChange}
                  label={t(ADDRESS_FIELDS[ADDRESS.UNIT_INFO].label)}
                  name="unitInfo"
                  quidBase="store-locator-address-field"
                  value={unitInfo}
                  t={t}
                  isRequired
                />
              ) : null}

              {isBuildingVisible
                ? getBuildingNameInput(
                    "grid__cell--1/2 grid__cell--3/4@desktop"
                  )
                : null}
            </div>
          </div>
        );
        break;
    }

    switch (serviceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return (
          <div className="grid">
            {isDefaultLocatorStrategy && (
              <div className="grid__cell--1 grid__cell--1/2@desktop margin__bottom--1_25rem">
                <PinDrop
                  mapOptions={Object.assign({}, MAP_OPTIONS, {
                    mapTypeControl: true,
                  })}
                  mapCenter={MARKET_CENTER.MALAYSIA}
                  mapZoom={MARKET_ZOOM}
                  maps={maps}
                  onChange={this.handlePinDrop}
                  ref={this.map}
                />
              </div>
            )}
            <div className="grid__cell--1 grid__cell--1/2@desktop">
              <div className="grid">
                {!isDefaultLocatorStrategy ? (
                  <div className="grid__cell--1">
                    <button
                      type="button"
                      className="btn btn--link btn--default-locator-strategy-toggler"
                      onClick={this.setDefaultLocatorStrategy}
                    >
                      {t("customer:locations.back_to_map")}
                    </button>
                  </div>
                ) : null}
                <Select
                  className="grid__cell--1"
                  handleChange={handleChange}
                  label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS_TYPE].label)}
                  name="addressType"
                  quidBase="store-locator-address-type"
                  source={ADDRESS_TYPES}
                  t={t}
                  value={addressType}
                />
                {isDefaultLocatorStrategy && (
                  <Fragment>
                    <GoogleMapsTypeAhead
                      className="grid__cell--1"
                      input={address}
                      label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
                      maps={maps}
                      name="address"
                      onChange={({ address: addressValue, placeId }) =>
                        handleChange({
                          address: addressValue,
                          placeId,
                        })
                      }
                      options={{
                        componentRestrictions: {
                          country:
                            process.env.NODE_ENV !== "production"
                              ? ["MY", "US"]
                              : ["MY"],
                        },
                        types: ["geocode"],
                      }}
                      quidBase="store-locator-address"
                      required
                    />
                    <Input
                      className="grid__cell--1"
                      handleChange={handleChange}
                      label={t(ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD].label)}
                      name="neighborhood"
                      quidBase="store-locator-address-field"
                      value={neighborhood}
                      t={t}
                    />
                  </Fragment>
                )}
                {!isDefaultLocatorStrategy && (
                  <Fragment>
                    <div className="grid__cell--1">
                      <DrivenField
                        className="grid__cell--1"
                        handleChange={handleChange}
                        initializeSource={getStates}
                        isReady
                        label={t(ADDRESS_FIELDS[ADDRESS.STATE].label)}
                        name="regionCode"
                        quidBase="store-locator-state"
                        disabled={streetSelected}
                        source={states.map(({ id }) => ({
                          key: id,
                          option: id,
                        }))}
                        type="select"
                        value={regionCode}
                      />
                    </div>
                    {!streetSelected ? (
                      <div className="grid__cell--1/4">
                        <DrivenField
                          className="grid__cell--1"
                          handleChange={this.handleChange}
                          initializeSource={getStreetCategories}
                          isReady
                          label={t(
                            "customer:locations.address.street_name_prefix"
                          )}
                          name="streetCategory"
                          quidBase="store-locator-street-name-prefix"
                          source={streetCategories}
                          t={t}
                          value={streetCategory}
                        />
                      </div>
                    ) : null}
                    <DominosTypeAhead
                      className={
                        streetSelected ? "grid__cell--1" : "grid__cell--3/4"
                      }
                      debounce={250}
                      disabled={
                        streetSelected || !streetCategory || !regionCode
                      }
                      onChange={({ streetName: streetNameValue }) => {
                        handleChange({
                          streetName: streetNameValue,
                        });
                      }}
                      onSelect={this.updateStreet}
                      fetchPredictions={(streetNameValue) => {
                        getStreets({
                          state: regionCode,
                          street: streetNameValue,
                          streetCategory,
                        });
                      }}
                      label={t(ADDRESS_FIELDS[ADDRESS.STREET_NAME].label)}
                      name="streetName"
                      quidBase="store-locator-address-field"
                      suggestions={streets}
                      input={streetName}
                      t={t}
                    />
                    {streetSelected ? (
                      <Fragment>
                        <Input
                          className="grid__cell--1"
                          disabled
                          handleChange={handleChange}
                          label={t(ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD].label)}
                          name="neighborhood"
                          quidBase="store-locator-address-field"
                          value={neighborhood}
                          t={t}
                        />

                        <Input
                          className="grid__cell--1"
                          disabled
                          handleChange={handleChange}
                          label={t(ADDRESS_FIELDS[ADDRESS.POSTAL_CODE].label)}
                          name="postalCode"
                          quidBase="store-locator-address-field"
                          value={postalCode}
                          t={t}
                        />
                      </Fragment>
                    ) : null}
                  </Fragment>
                )}
                {addressFields}
                {isDeliveryInstructionsVisible ? (
                  <Input
                    className="grid__cell--1"
                    handleChange={handleChange}
                    label={t(
                      ADDRESS_FIELDS[ADDRESS.DELIVERY_INSTRUCTIONS].label
                    )}
                    name="deliveryInstructions"
                    quidBase="store-locator-address-field"
                    value={deliveryInstructions}
                    maxLength={DELIVERY_INSTRUCTIONS_MAX_LENGTH}
                    t={t}
                  />
                ) : null}
                <Input
                  className="grid__cell--1"
                  handleChange={handleChange}
                  label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME].label)}
                  name="addressNickname"
                  quidBase="store-locator-address-field"
                  value={addressNickname}
                  t={t}
                />
                <p className="grid__cell--1">
                  <RequiredMark /> {t("shared:forms.indicates_required")}
                </p>
                <div className="grid__cell--1">
                  <div className="grid form-controls">
                    <button
                      className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                      data-icon="clear"
                      data-quid="store-locator-clear"
                      onClick={this.handleClear}
                      type="button"
                    >
                      {t("shared:action.clear_form")}
                    </button>
                    {isDefaultLocatorStrategy ? (
                      <button
                        className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                        data-icon="pin_drop"
                        data-quid="store-locator-search"
                        onClick={this.handleSearch}
                        type="button"
                      >
                        {t("shared:action.drop_pin")}
                      </button>
                    ) : null}
                    {streetSelected ? (
                      <button
                        className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                        data-icon="edit"
                        data-quid="store-locator-edit"
                        onClick={this.resetStreetSearch}
                        type="button"
                      >
                        {t("customer:locations.edit")}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case SERVICE_METHOD.CARRYOUT:
      default:
        return (
          <GoogleMapsTypeAhead
            className="grid__cell--1"
            input={address}
            label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
            maps={maps}
            name="address"
            onChange={({ address: addressValue, placeId }) =>
              handleChange({
                address: addressValue,
                placeId,
              })
            }
            options={{
              componentRestrictions: {
                country:
                  process.env.NODE_ENV !== "production" ? ["MY", "US"] : ["MY"],
              },
              types: ["geocode"],
            }}
            quidBase="store-locator-address"
          />
        );
    }
  }
}

MY.propTypes = {
  address: PropTypes.string,
  addressNickname: PropTypes.string,
  addressType: PropTypes.string,
  block: PropTypes.string,
  countryCode: PropTypes.string,
  getPlaces: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getStreetCategories: PropTypes.func.isRequired,
  getStreets: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  deliveryInstructions: PropTypes.string,
  level: PropTypes.string,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  marketConfigs: PropTypes.shape({
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: PropTypes.number,
  }).isRequired,
  neighborhood: PropTypes.string,
  onError: PropTypes.func,
  organizationName: PropTypes.string,
  places: PropTypes.arrayOf(PropTypes.string),
  postalCode: PropTypes.string,
  regionCode: PropTypes.string,
  serviceMethod: PropTypes.oneOf(["Carryout", "Delivery", "Dine In"]),
  setUsesPlaces: PropTypes.func.isRequired,
  unit: PropTypes.string,
  unitInfo: PropTypes.string,
  locatorStrategy: PropTypes.string.isRequired,
  setLocatorStrategy: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
    .isRequired,
  streetCategories: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, option: PropTypes.string })
  ).isRequired,
  streetName: PropTypes.string,
  streets: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      placeId: PropTypes.string,
    })
  ).isRequired,
};

MY.defaultProps = {
  address: "",
  addressNickname: "",
  addressType: ADDRESS.LANDED,
  block: "",
  buildingName: "",
  countryCode: "",
  deliveryInstructions: "",
  level: "",
  neighborhood: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    process.env.NODE_ENV !== "production" && console.error(error);
  },
  organizationName: "",
  postalCode: "",
  places: [],
  regionCode: "",
  serviceMethod: "Carryout",
  streetName: "",
  unit: "",
  unitInfo: "",
};

export default MY;
