import { RESET_STATE } from "ducks/configuration";

import { OPTION } from "constants/order";
import { WEIGHT } from "constants/topping";
import withValidation from "modules/elValidadore";
import uuid from "modules/uuid";
import { idValidators } from "modules/validators";

const SCOPE = "order-entry/partOption/";

export const CREATE_PART_OPTION = `${SCOPE}CREATE_PART_OPTION`;
export const UPDATE_PART_OPTION = `${SCOPE}UPDATE_PART_OPTION`;
export const DELETE_PART_OPTION = `${SCOPE}DELETE_PART_OPTION`;

export const createPartOption = withValidation(
  ({
    isDefault = false,
    optionId = "",
    optionType = OPTION.TOPPING,
    optionWeight = WEIGHT[1].value,
    partId = null,
    partOptionId = uuid(),
  } = {}) => ({
    type: CREATE_PART_OPTION,
    isDefault,
    optionId,
    optionType,
    optionWeight,
    partId,
    partOptionId,
  }),
  {
    partId: idValidators,
  }
);

export const updatePartOption = withValidation(
  ({ partOptionId = null, ...partOption } = {}) =>
    Object.assign({}, partOption, {
      type: UPDATE_PART_OPTION,
      partOptionId,
    }),
  {
    partOptionId: idValidators,
  }
);

export const deletePartOption = withValidation(
  ({ partId = null, partOptionId = null } = {}) => ({
    type: DELETE_PART_OPTION,
    partId,
    partOptionId,
  }),
  {
    partId: idValidators,
    partOptionId: idValidators,
  }
);

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case CREATE_PART_OPTION:
      return Object.assign({}, state, {
        [action.partOptionId]: action,
      });
    case UPDATE_PART_OPTION:
      return Object.assign({}, state, {
        [action.partOptionId]: Object.assign(
          {},
          state[action.partOptionId],
          action
        ),
      });
    case DELETE_PART_OPTION:
      return Object.keys(state)
        .filter((partOptionId) => partOptionId !== action.partOptionId)
        .reduce(
          (all, partOptionId) =>
            Object.assign(all, {
              [partOptionId]: state[partOptionId],
            }),
          {}
        );
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
