/* No slice for Tealium since nothing needs to be stored in state */
import { createAction } from "@reduxjs/toolkit";

export const sendTealiumOrderStarted = createAction(
  "tealium/orderStarted",
  () => ({
    payload: {},
  })
);

export const sendTealiumUpsellProductAdded = createAction(
  "tealium/upsellProductAdded",
  ({ productName, quantity }) => ({
    payload: {
      productName,
      quantity,
    },
  })
);

export const sendTealiumPlaceOrderSuccess = createAction(
  "tealium/placeOrderSuccess",
  ({ ecommOrderId }) => ({
    payload: {
      ecommOrderId,
    },
  })
);

export const sendTealiumExitReason = createAction(
  "tealium/exitReason",
  ({ exitReason }) => ({
    payload: {
      exitReason,
    },
  })
);
