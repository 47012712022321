/** @jsxImportSource @emotion/react */
import { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import RequiredMark from "components/RequiredMark";

const Switch = ({
  t,
  label,
  id,
  isInvalid = false,
  handleChange,
  className = "",
  displayError = false,
  checked,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const showError = (touched || displayError) && isInvalid;

  const handleFieldChange = (e) => {
    setTouched(true);
    handleChange && handleChange(e);
  };

  return (
    <div
      className={classNames(
        "form__control-group grid__cell--1",
        "toggle",
        className,
        showError && "error"
      )}
      data-quid={id}
    >
      <label>
        <input
          id={id}
          name={id}
          type="checkbox"
          className="switch__input"
          checked={checked}
          onChange={handleFieldChange}
          {...props}
        />
        <span className="switch__toggle"></span> <RequiredMark />
        {label}
      </label>
    </div>
  );
};

export default Switch;

Switch.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  displayError: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
};
