import React, { useReducer } from "react";

import PropTypes from "prop-types";

import { ROUTE_TITLES } from "routes";

import Page from "components/Page";
import Footer from "components/WaterfallUpsell/Footer";
import Upsell from "components/WaterfallUpsell/UpsellItem";

import "./WaterfallUpsell.css";

const INCREMENT_ITEM_COUNT = "increment-item-count";
const DECREMENT_ITEM_COUNT = "decrement-item-count";
const SET_ITEM_COUNT = "set-item-count";

const upsellItemsCountReducer = (
  upsellItemCount,
  { type, productId, quantity }
) => {
  switch (type) {
    case DECREMENT_ITEM_COUNT:
      return {
        ...upsellItemCount,
        [productId]: upsellItemCount[productId] - 1,
      };
    case INCREMENT_ITEM_COUNT:
      return {
        ...upsellItemCount,
        [productId]: upsellItemCount[productId] + 1,
      };
    case SET_ITEM_COUNT:
      return { ...upsellItemCount, [productId]: quantity };
    default:
      return upsellItemCount;
  }
};

const WaterfallUpsell = ({
  applyWaterfallUpsell,
  currentOrderId,
  marketConfig: { SINGLE_LINE_ITEM_MAX_QTY },
  productsLink,
  sidebar,
  t,
  upsellItems,
}) => {
  const [upsellItemsCount, updateItemCount] = useReducer(
    upsellItemsCountReducer,
    upsellItems.reduce(
      (upsellItemCountMap, { productId }) => ({
        ...upsellItemCountMap,
        [productId]: 0,
      }),
      {}
    )
  );

  const increaseItemCount = (productId) => () =>
    updateItemCount({ type: INCREMENT_ITEM_COUNT, productId });
  const decrementItemCount = (productId) => () =>
    updateItemCount({ type: DECREMENT_ITEM_COUNT, productId });
  const setItemCount = (productId) => (quantity) =>
    updateItemCount({ type: SET_ITEM_COUNT, productId, quantity });

  const applyUpsell = () => {
    const orderId = currentOrderId;
    const url = productsLink;
    const upsells = upsellItems
      .map(({ categoryId, productId, sizeId, baseId }) => ({
        categoryId,
        productId,
        sizeId,
        baseId,
        itemQuantity: upsellItemsCount[productId],
      }))
      .filter(({ itemQuantity }) => itemQuantity > 0);

    applyWaterfallUpsell({
      upsellItems: upsells,
      orderId,
      url,
    });
  };

  return (
    <Page
      footer={<Footer applyUpsell={applyUpsell} t={t} />}
      id="exit"
      sidebar={sidebar}
      title={t("finish:waterfall_upsell:title")}
      seoTitle={ROUTE_TITLES.FINISH}
    >
      <div className="waterfall-upsell">
        <p>{t("finish:waterfall_upsell:subtitle")}</p>
        <div className="grid">
          {upsellItems.map(({ productId, productName }) => (
            <div
              key={productId}
              className="grid__cell--1 grid__cell--1/2@desktop"
            >
              <Upsell
                productId={productId}
                productName={productName}
                quantity={upsellItemsCount[productId]}
                increaseItemCount={increaseItemCount(productId)}
                decrementItemCount={decrementItemCount(productId)}
                setItemCount={setItemCount(productId)}
                maximum={SINGLE_LINE_ITEM_MAX_QTY}
                minimum={0}
              />
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

WaterfallUpsell.propTypes = {
  applyWaterfallUpsell: PropTypes.func.isRequired,
  currentOrderId: PropTypes.string,
  marketConfig: PropTypes.shape({
    SINGLE_LINE_ITEM_MAX_QTY: PropTypes.number,
  }),
  productsLink: PropTypes.string,
  sidebar: PropTypes.node.isRequired,
  t: PropTypes.func,
  upsellItems: PropTypes.arrayOf(
    PropTypes.shape({
      categoryId: PropTypes.string,
      productId: PropTypes.string,
      sizeId: PropTypes.string,
    })
  ).isRequired,
};

WaterfallUpsell.defaultProps = {
  currentOrderId: "",
  marketConfig: { SINGLE_LINE_ITEM_MAX_QTY: 25 },
  productsLink: "",
  t: () => {},
  upsellItems: [],
};

export default WaterfallUpsell;
