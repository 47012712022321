import { css } from "@emotion/react";

export const customerFormRow = css`
  display: flex;
  .prefix {
    width: 2.5rem;
  }
  .small {
    width: 7rem;
  }
  .toggle {
    position: relative;
    margin-left: 2rem;
    margin-top: 1rem;
  }
`;

export const buttonRow = css`
  display: flex;
  column-gap: 2rem;
  margin: 0 1rem 2rem 1rem;
  justify-content: flex-end;
`;

export const componentWrapper = css`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
`;

export const visuallyHidden = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin-bottom: 16px;
`;

export const labelSpace = css`
  height: 1.1rem;
`;

export const storeListStyles = css`
  padding: 1rem;
`;

export const optionsWrapper = css`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  margin-bottom: 0.5rem;
  button {
    flex-grow: 1;
    background-color: var(--interactive-bg);
    border-color: var(--interactive-bg);
    color: var(--dpzBlue);
    font-weight: bold;
    &.selected {
      background-color: var(--dpzBlue);
      color: white;
    }
  }
`;

export const storeWrapper = css`
  .warning {
    margin-top: 0.5rem;
    font-weight: bold;
    color: var(--dpzRed);
  }
  margin-bottom: 1rem;
`;

export const storeMapItemStyles = css`
  button {
    border: none;
    padding: 0;
    margin-bottom: 0.5rem;
    text-decoration: none;
    background: none;
    cursor: pointer;
    text-align: left;
    color: var(--dpzBlue);
  }
`;

export const storeItemStyles = css`
  border-left: 0px solid;
  padding-left: 0;
  border-bottom: solid 1px var(--border);
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  &.currentStore {
    border-left: 5px solid var(--dpzRed);
    padding-left: 1rem;
  }
  p {
    margin: 0;
  }
`;

export const customerPageWrapper = css`
  display: flex;
  column-gap: 2rem;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const singleColumn = css`
  width: calc(50% - 1rem);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-top: 2rem;
  }
`;

export const nonEditableFieldStyles = css`
  dt {
    font-size: 0.9rem;
    font-weight: normal;
    color: #5e5e5e;
  }
  dd {
    margin: 0;
    font-size: 1.2rem;
  }
`;

export const storeHours = css`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  dt {
    font-weight: bold;
    color: var(--dpzBlue);
  }
  dd {
    margin: 0;
  }
`;

export const moreInfoStyles = css`
  button {
    display: inline-block;
    border: none;
    padding: 1rem 0;
    margin: 0;
    text-decoration: none;
    background: none;
    color: var(--dpzBlue);
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
  }

  button:hover,
  button:focus {
    color: var(--dpzRed);
  }
`;

export const DCDMessageWrapper = css`
  margin: 1rem 0;
  background-color: var(--attentionBg);
  padding: 1rem;
  display: flex;
  align-items: center;
`;
