/* The <div> element has a child <button>, <a>, or <input> element that allows keyboard interaction */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useRef } from "react";

import PropTypes from "prop-types";

// This div wraps a child that includes one clickable element and will make the entire div clickable
// If the button wraps other elements, move them visually outside as in ExpandableToggleIcon
const ExpandedClickableArea = ({ children, ...props }) => {
  const clickableElemTypes = ["a", "button", "input"];
  const refExpandedArea = useRef();

  const handleClick = (e) => {
    const clickableElems = [
      ...refExpandedArea.current.querySelectorAll(clickableElemTypes),
    ];
    if (clickableElems.length !== 1) {
      throw new Error(
        `Expected one clickable element but found ${clickableElems.length}`
      );
    }
    const clickableElem = clickableElems[0];
    const targetIsClickable = clickableElemTypes.includes(
      e.target.tagName.toLowerCase()
    );
    if (clickableElem !== e.target && !targetIsClickable) {
      clickableElem.click();
    }
  };
  return (
    <div ref={refExpandedArea} onClick={handleClick} {...props}>
      {children}
    </div>
  );
};

export default ExpandedClickableArea;

ExpandedClickableArea.propTypes = {
  children: PropTypes.element,
};
