const baseRoute = `order-entry/route`;
export const AUTH = `${baseRoute}/AUTH`;
export const HOME = `${baseRoute}/HOME`;
export const EXIT = `${baseRoute}/EXIT`;
export const CUSTOMER = `${baseRoute}/CUSTOMER`;
export const PIZZAS = `${baseRoute}/PIZZAS`;
export const EVERYTHING_ELSE = `${baseRoute}/EVERYTHING_ELSE`;
export const COUPONS = `${baseRoute}/COUPONS`;
export const FINISH = `${baseRoute}/FINISH`;
export const FULFILLER = `${baseRoute}/FULFILLER`;

export const WATERFALL_UPSELL_SECTION = "waterfall-upsell";

export const routesMap = {
  [AUTH]: "/auth",
  [HOME]: "/",
  [EXIT]: "/exit",
  [CUSTOMER]: "/customer/:section?",
  [PIZZAS]: "/pizzas/:category?",
  [EVERYTHING_ELSE]: "/everything-else/:category?",
  [COUPONS]: "/coupons/:category?",
  [FINISH]: "/finish/:section?",
  [FULFILLER]: "/fulfiller/:couponId",
};

const DEFAULT_TITLE = "Call Center Web";

export const ROUTE_TITLES = {
  HOME: DEFAULT_TITLE,
  CUSTOMER: `${DEFAULT_TITLE} - Customer`,
  COUPONS: `${DEFAULT_TITLE} - Coupons`,
  PIZZAS: `${DEFAULT_TITLE} - Pizza`,
  EVERYTHING_ELSE: `${DEFAULT_TITLE} - Everything Else`,
  FINISH: `${DEFAULT_TITLE} - Place Order`,
  EXIT: `${DEFAULT_TITLE} - Exit Order`,
};

export default routesMap;

export function authRoute() {
  return {
    type: AUTH,
  };
}

export function homeRoute(query) {
  return {
    type: HOME,
    query,
  };
}

export function exitRoute() {
  return {
    type: EXIT,
  };
}

export function customerRoute(section) {
  return {
    type: CUSTOMER,
    payload: {
      section,
    },
  };
}

export function pizzasRoute(category) {
  return {
    type: PIZZAS,
    payload: {
      category,
    },
  };
}

export function everythingElseRoute(category) {
  return {
    type: EVERYTHING_ELSE,
    payload: {
      category,
    },
  };
}

export function couponsRoute(category) {
  return {
    type: COUPONS,
    payload: {
      category,
    },
  };
}

export function finishRoute(section) {
  return {
    type: FINISH,
    payload: {
      section,
    },
  };
}

export function fulfillerRoute(couponId) {
  return {
    type: FULFILLER,
    payload: {
      couponId,
    },
  };
}
