import React from "react";

import PropTypes from "prop-types";

import uuid from "modules/uuid";

const TableHeading = ({ headings, t, utilityHeadingCount }) => (
  <thead>
    <tr>
      {headings.map(({ id, label }) => (
        <th id={id} key={id}>
          {t(label)}
        </th>
      ))}

      {Array(utilityHeadingCount)
        .fill(uuid())
        .map((key) => (
          <th key={key} />
        ))}
    </tr>
  </thead>
);

TableHeading.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  t: PropTypes.func,
  utilityHeadingCount: PropTypes.number,
};

TableHeading.defaultProps = {
  headings: [],
  t: () => {},
  utilityHeadingCount: 0,
};

export default TableHeading;
