/** @jsxImportSource @emotion/react */
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";

import { getNewExperienceFeature } from "ducks/market/selectors";
import getMarketCode from "ducks/market/selectors";
import {
  getCurrentOrderDeliveryFee,
  getCurrentOrderFoodAndBeverage,
  getCurrentOrderSavings,
  getCurrentOrderTax,
  getCurrentServiceMethod,
} from "ducks/order/selectors";

import {
  getShowTip,
  selectTipAmountNumeric,
} from "rtk_redux/slices/finishSlice";
import { selectPriceOrderLoading } from "rtk_redux/slices/priceOrderSlice";

import { SERVICE_METHOD } from "constants/order";
import formatCurrency from "modules/formatCurrency";

import DotLoader from "components/DotLoader";

import { receiptListStyles } from "./PriceBreakdown.styles";

const PriceBreakdown = ({ t = () => {} }) => {
  const { automaticPriceOrder: shouldUseAutomaticPriceOrder } = useSelector(
    getNewExperienceFeature
  );
  const priceOrderLoading = useSelector(selectPriceOrderLoading);
  const marketCode = useSelector(getMarketCode);

  const currentOrderFoodAndBeverage = useSelector(
    getCurrentOrderFoodAndBeverage
  );
  const currentServiceMethod = useSelector(getCurrentServiceMethod);
  const currentOrderDeliveryFee = useSelector(getCurrentOrderDeliveryFee);
  const currentOrderTax = useSelector(getCurrentOrderTax);

  const showTip = useSelector(getShowTip);
  const tipAmountNumeric = useSelector(selectTipAmountNumeric);

  const currentOrderSavings = useSelector(getCurrentOrderSavings);

  const priceWithCurrency = (price) =>
    shouldUseAutomaticPriceOrder && priceOrderLoading ? (
      <DotLoader />
    ) : (
      formatCurrency(marketCode, price)
    );

  return (
    <ul className="list" data-quid="receipt-order-summary">
      {!!currentOrderFoodAndBeverage && (
        <li
          className="list__item"
          data-quid="food-and-beverage"
          css={receiptListStyles}
        >
          <span data-icon="local_pizza">
            {t("finish:order_summary.food_and_beverage")}:{" "}
          </span>
          <strong>{priceWithCurrency(currentOrderFoodAndBeverage)}</strong>
        </li>
      )}

      {currentOrderDeliveryFee !== null &&
        currentServiceMethod === SERVICE_METHOD.DELIVERY && (
          <li
            className="list__item"
            data-quid="delivery-charge"
            css={receiptListStyles}
          >
            <span data-icon="directions_car">
              {t("finish:order_summary.delivery_charge")}:{" "}
            </span>
            <strong>{priceWithCurrency(currentOrderDeliveryFee)}</strong>
          </li>
        )}

      {!!currentOrderTax && (
        <li className="list__item" data-quid="tax" css={receiptListStyles}>
          <span data-icon="add_circle_outline">
            {t("finish:order_summary.tax")}:{" "}
          </span>
          <strong>{priceWithCurrency(currentOrderTax)}</strong>
        </li>
      )}

      {showTip && (
        <li className="list__item" data-quid="tip" css={receiptListStyles}>
          <span data-icon="redeem">{t("finish:payment.tip.label")}: </span>
          <strong>{priceWithCurrency(tipAmountNumeric)}</strong>
        </li>
      )}

      {currentOrderSavings > 0 && shouldUseAutomaticPriceOrder && (
        <li
          className="list__item status--positive"
          data-quid="tip"
          css={receiptListStyles}
        >
          <span data-icon="attach_money">
            {t("finish:order_summary.savings")}:{" "}
          </span>
          <strong>{priceWithCurrency(currentOrderSavings)}</strong>
        </li>
      )}
    </ul>
  );
};

PriceBreakdown.propTypes = {
  t: PropTypes.func,
};

export default PriceBreakdown;
