import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// TODO: set market dynamically
const dynamicBaseQuery = async (args, api, extraOptions) => {
  const baseUrl = window.ENVCONFIG.serviceRoot;
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const token = getState().session.token;
      const marketCode = "UNITED_STATES";
      const languageCode = "EN";
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("dpz-market", marketCode);
        headers.set("dpz-language", languageCode);
      }
      return headers;
    },
  });
  // provide the amended url and other params to the raw base query
  return rawBaseQuery(args, api, extraOptions);
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
});
