import { useEffect, useRef } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";

import { AUTOCOMPLETE } from "constants/launchDarkly";

export default function useAutocomplete({ handlePlaceChange }) {
  const { [AUTOCOMPLETE]: enableAutocomplete } = useFlags();
  const inputRef = useRef(null);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (!enableAutocomplete) return;
    if (!inputRef.current) return;

    autocompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { fields: ["address_components"] }
    );
  }, [inputRef.current, enableAutocomplete]);

  useEffect(() => {
    if (!autocompleteRef.current) return;

    autocompleteRef.current.addListener("place_changed", onPlaceChange);
  }, [autocompleteRef.current]);

  function onPlaceChange() {
    const place = autocompleteRef.current.getPlace();
    let address = "",
      city = "",
      state = "",
      zipCode = "";

    if (!place?.address_components) return;

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address = component.long_name;
          break;
        }

        case "route": {
          address += " " + component.long_name;
          break;
        }

        case "locality": {
          city = city || component.long_name;
          break;
        }

        case "administrative_area_level_1": {
          state = component.short_name;
          break;
        }

        case "postal_code": {
          zipCode = component.long_name;
          break;
        }

        case "postal_code_suffix": {
          zipCode = `${zipCode}-${component.long_name}`;
          break;
        }
      }
    }

    handlePlaceChange({ address, city, state, zipCode });
  }

  return [inputRef];
}
