import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

const SCOPE = "order-entry/city/";

export const GET_CITIES = `${SCOPE}GET_CITIES`;
export const GET_CITIES_ERROR = `${SCOPE}GET_CITIES_ERROR`;
export const SET_CITIES = `${SCOPE}SET_CITIES`;

const STORE_LOCATOR_CITY_URL = "/store-locator-international/locations/city";

export function getCities({ query = {} } = {}) {
  return {
    type: GET_CITIES,
    power: true,
    query,
    url: STORE_LOCATOR_CITY_URL,
  };
}

export function getCitiesError(error) {
  return {
    type: GET_CITIES_ERROR,
    error,
  };
}

export function setCities(cities = {}) {
  return {
    type: SET_CITIES,
    cities,
  };
}

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_CITIES:
      return action.cities;
    default:
      return state;
  }
}

export const getCitiesEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_CITIES),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response"),
        map((cities) =>
          setCities(
            cities.reduce(
              (all, { name }) =>
                Object.assign(all, {
                  [name]: name,
                }),
              {}
            )
          )
        ),
        catchError((error) => of(getCitiesError(error)))
      )
    )
  );

export const epics = combineEpics(getCitiesEpic);
