import { css } from "@emotion/react";

export default css`
  .order-status-error {
    margin-top: 1rem;
  }

  .order-status-form {
    display: flex;
    flex-flow: row wrap;
  }

  .order-status-phone-label {
    flex: 0 0 100%;
  }

  .order-status-phone {
    flex: 1;
  }

  .order-status-phone-submit {
    flex: 0;
    margin-left: 1rem;
  }

  .order {
    align-items: flex-start;
    padding: 1rem;

    > * {
      flex: 1;

      &.tracker__wrapper {
        flex: none;
      }
    }
  }

  .order-details {
    margin-left: 1rem;
  }

  .order-description,
  .order-details {
    background: #fff;
    border: 1px solid var(--border);
    margin-bottom: 0;
  }

  .order-description__item,
  .order-details__item {
    &.list__item {
      &,
      &:last-child {
        padding: 1rem;
        box-shadow: unset;
      }
    }
  }

  .order-description__coupon [data-icon] {
    margin: 0 0.25em;

    &::before {
      color: var(--dom-blue);
    }
  }

  .order-line-item {
    display: flex;

    & [data-icon]::before {
      color: var(--dom-blue);
    }
  }

  .item__quantity {
    background: var(--dom-blue);
    border-radius: 100%;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 0;
    font-weight: bold;
    height: 1rem;
    margin-right: 1rem;
    padding: 0.5rem;
    text-align: center;
    width: 1rem;
  }

  .item__description {
    flex-grow: 1;
  }

  .item__description__name {
    font-weight: bold;
  }

  .item__options {
    display: block;
  }

  .reorder {
    margin: 0 0 1rem 1rem;
  }

  @media (max-width: 45rem) {
    .order {
      display: block;

      > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
`;
