import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pastOrders: [],
  formattedOrderHistory: [],
};

const orderHistorySlice = createSlice({
  name: "orderHistory",
  initialState,
  reducers: {
    setOrderHistory: (state, { payload }) => {
      state.pastOrders = payload;
    },
    setFormattedOrderHistory: (state, { payload }) => {
      state.formattedOrderHistory = payload;
    },
    resetOrderHistory: () => initialState,
  },
});

export const { setOrderHistory, setFormattedOrderHistory, resetOrderHistory } =
  orderHistorySlice.actions;

export default orderHistorySlice.reducer;

export const selectOrderHistory = (state) =>
  state.orderHistory.formattedOrderHistory;

export const selectPastOrders = (state) => state.orderHistory.pastOrders;
