import { useDispatch, useSelector } from "react-redux";

import { getActiveOrderProduct } from "ducks/order/selectors";
import { updateOrderProduct } from "ducks/orderProduct";
import { getActiveOrderProductCookingInstructions } from "ducks/orderProduct/selectors";
import { getSelfLink } from "selectors/related";

import combineCookingInstructions from "modules/combineCookingInstructions";

import CookingInstructionButton from "./CookingInstructionButton";

const CookingInstructions = () => {
  const dispatch = useDispatch();
  const activeOrderProduct = useSelector(getActiveOrderProduct);
  const activeOrderProductLink = getSelfLink(activeOrderProduct);
  const {
    cookingInstructionOptions,
    allowedCookingInstructionGroups,
    defaultCookingInstructions,
    cookingInstructions,
  } = useSelector(getActiveOrderProductCookingInstructions);

  const cookingInstructionCodes = cookingInstructions.map(({ code }) => code);

  const handleCookingInstructionClick = (code) => () => {
    const newCookingInstructionCodes = combineCookingInstructions(
      defaultCookingInstructions,
      cookingInstructions,
      [cookingInstructionOptions[code]]
    ).map(({ code }) => code);

    dispatch(
      updateOrderProduct({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        cookingInstructions: newCookingInstructionCodes,
      })
    );
  };

  if (!Object.values(allowedCookingInstructionGroups).length) return null;

  return (
    <div className="columns shared">
      {Object.values(allowedCookingInstructionGroups).map(
        ({ name, code, instructions }) => (
          <div key={code} className="subsection">
            <header className="title-group">
              <h2 data-quid="heading-cooking-instructions" id={name}>
                {name}
              </h2>
            </header>
            <div
              className="btn-group btn-group--vertical"
              data-quid="cooking-instructions"
            >
              {instructions.map((instruction) => (
                <CookingInstructionButton
                  key={instruction.code}
                  instruction={instruction}
                  isSelected={cookingInstructionCodes.includes(
                    instruction.code
                  )}
                  handleCookingInstructionClick={handleCookingInstructionClick(
                    instruction.code
                  )}
                />
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CookingInstructions;
