import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

import {
  getDefaultLanguage,
  getMarketHeader,
  getMarketName,
} from "ducks/market/selectors";

import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

import marketConfigs from "./marketSpecificConfig";

const SCOPE = "order-entry/market/";

export const GET_MARKETS = `${SCOPE}GET_MARKETS`;
export const GET_MARKETS_ERROR = `${SCOPE}GET_MARKETS_ERROR`;
export const SET_MARKET = `${SCOPE}SET_MARKET`;

export const getMarkets = withValidation(
  ({ url = "" } = {}) => ({
    type: GET_MARKETS,
    url,
  }),
  {
    url: urlValidators,
  }
);

export function getMarketsError(error) {
  return {
    type: GET_MARKETS_ERROR,
    error,
  };
}

export const setMarket = withValidation(
  ({
    links = {},
    marketCode = "",
    marketConfigs: config = {},
    marketHeader = "",
    marketId = null,
    marketName = "",
    nationalStoreId = "",
    relationships = {},
  } = {}) => ({
    type: SET_MARKET,
    links,
    marketCode,
    marketConfigs: config,
    marketHeader,
    marketId,
    marketName,
    nationalStoreId,
    relationships,
  }),
  {
    marketId: idValidators,
  }
);

export const initialState = {
  links: {},
  marketCode: "",
  marketHeader: "",
  marketId: "",
  marketName: "",
  marketConfigs: {},
  nationalStoreId: "",
  relationships: {},
};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_MARKET:
      return action;
    default:
      return state;
  }
}

export const marketMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (action.url) {
      const state = getState();

      const [defaultLanguage, marketHeader, marketName] = [
        getDefaultLanguage,
        getMarketHeader,
        getMarketName,
      ].map((selector) => selector(state));

      const { language = defaultLanguage } = action;

      action.headers = {
        "DPZ-Language": language.toUpperCase(),
        "DPZ-Market": marketHeader,
        Market: marketName,
      };
    }

    return next(action);
  };

export const getMarketsEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_MARKETS),
    mergeMap((action) =>
      fetch(action).pipe(
        pluck("response", "data", "0"),
        map(({ attributes, id, links, relationships }) =>
          setMarket(
            Object.assign(
              {},
              attributes,
              {
                links,
                marketId: id,
                relationships,
              },
              {
                marketConfigs: marketConfigs(attributes.marketCode),
              }
            )
          )
        ),
        catchError((error) => of(getMarketsError(error)))
      )
    )
  );

export const epics = combineEpics(getMarketsEpic);
