import cn from "classnames";
import PropTypes from "prop-types";

import kebabCase from "modules/kebabCase";

const CookingInstructionButton = ({
  instruction,
  isSelected = false,
  handleCookingInstructionClick,
}) => (
  <button
    className={cn("btn", { ["btn--fill"]: isSelected })}
    data-quid={kebabCase(`${instruction.name}-cooking-instruction`)}
    title={instruction.name}
    type="button"
    onClick={handleCookingInstructionClick}
  >
    {instruction.name}
  </button>
);

CookingInstructionButton.propTypes = {
  instruction: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  handleCookingInstructionClick: PropTypes.func.isRequired,
};

export default CookingInstructionButton;
