import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

import {
  getMarketConfigs,
  getNewExperienceFeature,
} from "ducks/market/selectors";
import { setMessage } from "ducks/message";
import { updateOrder, updateOrderStore } from "ducks/order";
import {
  getOrderProducts,
  getRemovedOrderCouponIds,
  getRemovedOrderProductIds,
} from "ducks/order/selectors";
import {
  deleteOrderCouponSuccess,
  updatePriceOrderCoupon,
} from "ducks/orderCoupon";
import {
  deleteOrderProductSuccess,
  priceValidateOrderProduct,
} from "ducks/orderProduct";
import { getCurrentStoreId } from "ducks/store/selectors";
import {
  getDataIdFromRelationship,
  getDataIdsFromRelationship,
  getLinkFromRelationship,
  getSelfLink,
} from "selectors/related";

import { setPaymentTypeValue } from "rtk_redux/slices/finishSlice";
import {
  setPriceOrderLoading,
  setRequireSavedCreditCardCVV,
} from "rtk_redux/slices/priceOrderSlice";

import { STATUS } from "constants/message";
import { ERROR_PREFIX, MESSAGE } from "constants/message";
import { ORDER_META } from "constants/order";
import withValidation from "modules/elValidadore";
import { buildErrorFromStatusItems } from "modules/errorMessageHelpers";
import { idValidators, urlValidators } from "modules/validators";

const SCOPE = "order-entry/order/";

export const PRICE_VALIDATE_ORDER = `${SCOPE}PRICE_VALIDATE_ORDER`;
export const PRICE_VALIDATE_ORDER_ERROR = `${SCOPE}PRICE_VALIDATE_ORDER_ERROR`;

export const priceValidateOrder = withValidation(
  ({ orderId = null, url = "" } = {}) => ({
    type: PRICE_VALIDATE_ORDER,
    orderId,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export function priceValidateOrderError(error) {
  return {
    type: PRICE_VALIDATE_ORDER_ERROR,
    error,
  };
}

export const setPriceOrderLoadingMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    /* The action can be sending a priceOrder request or a validate order request.
     * We only want to set priceOrderLoading to true if it is a priceOrder request.
     */
    if (
      action.type === PRICE_VALIDATE_ORDER &&
      action.url.endsWith("priceOrder")
    ) {
      dispatch(setPriceOrderLoading(true));
    }

    return next(action);
  };

export const checkEmptyCartMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      case PRICE_VALIDATE_ORDER: {
        const state = getState();
        const orderProducts = getOrderProducts(state);
        if (!Object.values(orderProducts).length) {
          dispatch(
            setMessage({
              message: "negative:price_order-6",
              status: STATUS.ERROR,
            })
          );
          dispatch(setPriceOrderLoading(false));
          return;
        }
      }
      default: {
        return next(action);
      }
    }
  };

export const priceValidateOrderEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(PRICE_VALIDATE_ORDER),
    mergeMap(({ url, ...action }) => {
      const state = getState();
      const { skipCombineLikeProducts } = getNewExperienceFeature(state);
      const skipUrl =
        url + (skipCombineLikeProducts ? "?combineLikeProducts=false" : "");
      const { SOURCE_ORG_URI } = getMarketConfigs(state);

      return fetch(
        Object.assign(action, {
          url: skipUrl,
          body: {
            data: {
              attributes: {
                ...ORDER_META,
                sourceOrgUri: SOURCE_ORG_URI,
              },
            },
          },
        }),
        {
          method: "PUT",
        }
      ).pipe(
        pluck("response", "data"),
        mergeMap(
          ({
            attributes: { amountBreakDown, statusItems, status, mitigations },
            id,
            ...order
          }) => {
            if (status < 0)
              return of(
                priceValidateOrderError(
                  buildErrorFromStatusItems({
                    statusItems,
                    errorPrefix: ERROR_PREFIX.PRICE_ORDER,
                  })
                )
              );

            const state = getState();
            const { orderProduct, orderCoupon } = state;
            const currentStoreId = getCurrentStoreId(state);
            const orderAddressId = getDataIdFromRelationship("address")(order);
            const storeId = getDataIdFromRelationship("store")(order);
            const orderStoreLink = getLinkFromRelationship("store")(order);

            const [orderOrderProductIds, orderOrderCouponIds] = [
              "products",
              "coupons",
            ].map((relationship) =>
              getDataIdsFromRelationship(relationship)(order)
            );

            const removedOrderProductIds =
              getRemovedOrderProductIds(orderOrderProductIds)(state);

            const removedOrderCouponIds =
              getRemovedOrderCouponIds(orderOrderCouponIds)(state);

            return [
              setRequireSavedCreditCardCVV(
                mitigations?.includes("CVV") ?? false
              ),
              updateOrder({
                amountBreakDown: amountBreakDown || {},
                orderId: id,
                statusItems,
              }),
              setPriceOrderLoading(false),
            ]
              .concat(
                removedOrderProductIds.map((orderProductId) =>
                  deleteOrderProductSuccess({
                    orderId: id,
                    orderProductId,
                  })
                )
              )
              .concat(
                removedOrderCouponIds.map((orderCouponId) =>
                  deleteOrderCouponSuccess({
                    orderCouponId,
                    orderId: id,
                  })
                )
              )
              .concat(
                orderOrderProductIds.map((orderProductId) =>
                  priceValidateOrderProduct({
                    url: getSelfLink(orderProduct[orderProductId]),
                  })
                )
              )
              .concat(
                orderOrderCouponIds.map((orderCouponId) =>
                  updatePriceOrderCoupon({
                    url: getSelfLink(orderCoupon[orderCouponId]),
                  })
                )
              )
              .concat(
                currentStoreId !== storeId
                  ? [
                      updateOrderStore({
                        addressId: orderAddressId,
                        orderId: id,
                        storeId,
                        url: orderStoreLink,
                      }),
                    ]
                  : []
              )
              .concat(setPaymentTypeValue(""));
          }
        ),
        catchError((error) => of(priceValidateOrderError(error)))
      );
    })
  );

export const priceValidateOrderErrorEpic = (action$) =>
  action$.pipe(
    ofType(PRICE_VALIDATE_ORDER_ERROR),
    map(() => setPriceOrderLoading(false))
  );
