import React from "react";

import PropTypes from "prop-types";

const Footer = ({ applyUpsell, t }) => (
  <button type="button" className="btn btn--primary" onClick={applyUpsell}>
    {t("finish:waterfall_upsell:continue")}
  </button>
);

Footer.propTypes = {
  applyUpsell: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Footer;
