/** @jsxImportSource @emotion/react */
import useAutocomplete from "hooks/useAutocomplete";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { updateAddress } from "ducks/address";
import {
  getCurrentOrder,
  getCurrentOrderAddress,
  getOrderStarted,
} from "ducks/order/selectors";
import { getLinkFromRelationship } from "selectors/related";

import { setHasUserSelectedStore } from "rtk_redux/slices/customerPageSlice";
import {
  selectCustomerApt,
  selectCustomerCity,
  selectCustomerState,
  selectCustomerStreet,
  selectCustomerZipcode,
  setCustomerApt,
  setCustomerCity,
  setCustomerPosition,
  setCustomerState,
  setCustomerStreet,
  setCustomerZipcode,
} from "rtk_redux/slices/serviceMethodSlice";

import { SERVICE_METHOD } from "constants/order";
import isDelivery from "modules/isDelivery";

import { buttonRow, customerFormRow } from "../NewCustomerPage.styles";

import FormField from "../utils/FormField";
import { useSetCurrentOrderStoreAndMethod } from "../utils/hooks";

const StoreLocationForm = ({ fetchStoreList, t }) => {
  const dispatch = useDispatch();
  const isOrderStarted = useSelector(getOrderStarted);
  const currentOrder = useSelector(getCurrentOrder);
  const addressLink = getLinkFromRelationship("address")(currentOrder);
  const address = useSelector(getCurrentOrderAddress);

  const customerStreet = useSelector(selectCustomerStreet);
  const customerApt = useSelector(selectCustomerApt);
  const customerCity = useSelector(selectCustomerCity);
  const customerState = useSelector(selectCustomerState);
  const customerZipcode = useSelector(selectCustomerZipcode);

  const { setCurrentOrderStoreAndServiceMethod, setServiceMethod } =
    useSetCurrentOrderStoreAndMethod();

  const handleFindStores = (type) => {
    geocodeCustomerLocation();
    dispatch(setHasUserSelectedStore(false));
    dispatchUpdateAddress();

    // change back to default method
    setServiceMethod(SERVICE_METHOD.CARRYOUT);
    const params = {
      type: type,
      s: `${customerStreet} ${customerApt}`,
      c: `${customerCity} ${customerState} ${customerZipcode}`,
      isOrderStarted: isOrderStarted,
      wasChangedByUser: true,
      ignoreWarnings: false,
    };

    fetchStoreList(params).then(({ data }) => {
      const deliveryStore =
        data?.Stores?.filter((store) => store.IsDeliveryStore)[0] || null;

      // We only want to automatically set the delivery store if
      // 1. The user wanted delivery
      // 2. There is a delivery store for the address
      // 3. The delivery store is currently open

      const shouldAutoSetStore =
        isDelivery(data.serviceType) && deliveryStore && deliveryStore?.IsOpen;

      if (shouldAutoSetStore) {
        setCurrentOrderStoreAndServiceMethod({
          storeId: deliveryStore.StoreID,
          serviceMethod: SERVICE_METHOD.DELIVERY,
        });
      }
    });
  };

  const geocodeCustomerLocation = () => {
    const address = encodeURI(
      `${customerStreet},${customerCity},${customerState},${customerZipcode}`
    );
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address }).then((response) => {
      const lat = response?.results?.[0]?.geometry?.location?.lat() ?? null;
      const lng = response?.results?.[0]?.geometry?.location?.lng() ?? null;

      dispatch(
        setCustomerPosition({
          lat,
          lng,
        })
      );
    });
  };

  const dispatchUpdateAddress = () => {
    dispatch(
      updateAddress({
        ...address,
        street: customerStreet,
        unitNumber: customerApt,
        city: customerCity,
        regionCode: customerState,
        postalCode: customerZipcode,
        url: addressLink,
      })
    );
  };

  const handlePlaceChange = ({ address, city, state, zipCode }) => {
    dispatch(setCustomerStreet(address));
    dispatch(setCustomerCity(city));
    dispatch(setCustomerState(state));
    dispatch(setCustomerZipcode(zipCode));
  };
  const [addressRef] = useAutocomplete({
    handlePlaceChange,
  });

  const hasCityAndState = customerCity.length && customerState.length;
  const hasZipcode = customerZipcode.length;
  const hasStreetAddress = customerStreet.length;

  return (
    <div>
      <form>
        <div css={customerFormRow}>
          <FormField
            t={t}
            id="customerStreet"
            name="customerStreet"
            label="Street"
            value={customerStreet}
            handleChange={(e) => {
              dispatch(setCustomerStreet(e.target.value));
            }}
            ref={addressRef}
          />
          <FormField
            t={t}
            id="customerApt"
            name="customerApt"
            label="Apartment"
            value={customerApt}
            className="small"
            handleChange={(e) => {
              dispatch(setCustomerApt(e.target.value));
            }}
          />
        </div>
        <div css={customerFormRow}>
          <FormField
            t={t}
            id="customerCity"
            name="customerCity"
            label="City"
            value={customerCity}
            handleChange={(e) => {
              dispatch(setCustomerCity(e.target.value));
            }}
          />

          <FormField
            t={t}
            id="customerState"
            name="customerState"
            label="State"
            value={customerState}
            handleChange={(e) => dispatch(setCustomerState(e.target.value))}
          />

          <FormField
            t={t}
            id="customerPostalCode"
            name="customerPostalCode"
            label="Zipcode"
            value={customerZipcode}
            handleChange={(e) => dispatch(setCustomerZipcode(e.target.value))}
          />
        </div>
      </form>
      <div css={buttonRow}>
        <button
          disabled={!(hasCityAndState || hasZipcode)}
          onClick={() => handleFindStores(SERVICE_METHOD.CARRYOUT)}
          className="btn btn--fill btn-primary"
        >
          {t("customer:locations.find_carryout_stores")}
        </button>

        <button
          disabled={!(hasCityAndState && hasZipcode && hasStreetAddress)}
          onClick={() => handleFindStores(SERVICE_METHOD.DELIVERY)}
          className="btn btn--fill btn-primary"
        >
          {t("customer:locations.find_delivery_store")}
        </button>
      </div>
    </div>
  );
};

export default StoreLocationForm;

StoreLocationForm.propTypes = {
  fetchStoreList: PropTypes.func,
  t: PropTypes.func.isRequired,
  isDeliveryForm: PropTypes.bool,
  setServiceMethod: PropTypes.func,
};
