import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, mergeMap, pluck } from "rxjs/operators";

import { updateOrder } from "ducks/order";
import { locateStoresAddressReset } from "ducks/storeLocation";

import { MESSAGE } from "constants/message";
import { SERVICE_METHOD } from "constants/order";
import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

const SCOPE = "order-entry/order/";

export const UPDATE_ORDER_SERVICE_METHOD = `${SCOPE}UPDATE_ORDER_SERVICE_METHOD`;
export const UPDATE_ORDER_SERVICE_METHOD_ERROR = `${SCOPE}UPDATE_ORDER_SERVICE_METHOD_ERROR`;

export const updateOrderServiceMethod = withValidation(
  ({
    orderId = null,
    serviceMethod = SERVICE_METHOD.CARRYOUT,
    url = "",
  } = {}) => ({
    type: UPDATE_ORDER_SERVICE_METHOD,
    orderId,
    serviceMethod,
    url,
  }),
  {
    orderId: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: idValidators,
    },
    url: urlValidators,
  }
);

export function updateOrderServiceMethodError(error) {
  return {
    type: UPDATE_ORDER_SERVICE_METHOD_ERROR,
    error,
  };
}

export const updateOrderServiceMethodEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(UPDATE_ORDER_SERVICE_METHOD),
    mergeMap(({ orderId, serviceMethod, ...action }) =>
      fetch(
        Object.assign(action, {
          body: {
            data: {
              id: serviceMethod,
            },
          },
        }),
        {
          method: "POST",
        }
      ).pipe(
        pluck("response", "data", "id"),
        mergeMap((id) => [
          updateOrder({
            orderId,
            serviceMethod: id,
          }),
          locateStoresAddressReset(),
        ]),
        catchError((error) => of(updateOrderServiceMethodError(error)))
      )
    )
  );
