import React, { Fragment, PureComponent } from "react";

import PropTypes from "prop-types";

import getKeysAndSortByNewest from "modules/getKeysAndSortByNewest";

import OrderStatusesHeading from "./OrderStatusesHeading";
import OrderStatusesRow from "./OrderStatusesRow";

class OrderStatuses extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      phone: props.phone,
      submitted: false,
    };

    this.getOrderStatuses = this.getOrderStatuses.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.updatePhone = this.updatePhone.bind(this);
  }

  componentDidMount() {
    this.getOrderStatuses();
  }

  //TODO: upgrade react & swap to static getDerivedStateFromProps
  componentWillReceiveProps(nextProps) {
    const { phone } = nextProps;
    const { phone: currentPhone } = this.state;

    if (currentPhone)
      this.setState({
        submitted: true,
      });
    else if (phone) {
      this.getOrderStatuses({
        phone,
      });

      this.setState({
        phone,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearOrderStatuses();
  }

  getOrderStatuses({ phone } = {}) {
    if (!phone) ({ phone } = this.state);
    const { getOrderStatuses } = this.props;

    if (phone.length >= 10) getOrderStatuses(phone);
  }

  doSubmit(event) {
    event.preventDefault();
    this.getOrderStatuses();
  }

  updatePhone({ target: { value: phone } }) {
    this.setState({ phone, submitted: false });
  }

  render() {
    const { orderStatuses, t } = this.props;
    const { phone, submitted } = this.state;

    //TODO: look into Reflect API for later refactor
    const hasOrders = Object.keys(orderStatuses).length > 0;

    return (
      <Fragment>
        <header className="title-group">
          <h2
            className="title-group__section"
            data-quid="heading-active-orders"
          >
            {t("customer:orders:order_status:active_orders")}
          </h2>
        </header>

        <div className="grid grid--center">
          <div className="order-status-form-group form__control-group grid__cell--1 grid__cell--1/3@desktop">
            <form
              className="order-status-form"
              data-quid="order-status"
              onSubmit={this.doSubmit}
            >
              <label
                className="order-status-phone-label"
                data-quid="order-status-phone-label"
                htmlFor="order-status-phone"
              >
                {t("customer:orders.order_status.phone_number")}
              </label>

              <input
                className="order-status-phone"
                data-quid="order-status-phone-input"
                id="order-status-phone"
                onChange={this.updatePhone}
                placeholder={t("customer:orders.order_status.phone_number")}
                type="text"
                value={phone}
              />

              <button
                className="btn btn--primary icon-only order-status-phone-submit"
                data-quid="order-status-phone-submit"
                data-icon="refresh"
                type="submit"
              />
            </form>

            {submitted && !hasOrders && (
              <div
                className="msg msg--block order-status-error status--warning"
                data-quid="active-orders-not-found"
                data-icon="error_outline"
              >
                {t("customer:orders.order_status.not_found")}
              </div>
            )}
          </div>
        </div>

        {hasOrders && (
          <table
            className="table--no-stripe table--border-bottom"
            data-quid="active-orders"
          >
            <OrderStatusesHeading t={t} />

            {getKeysAndSortByNewest(orderStatuses).map((id) => (
              <OrderStatusesRow key={id} t={t} {...orderStatuses[id]} />
            ))}
          </table>
        )}
      </Fragment>
    );
  }
}

OrderStatuses.propTypes = {
  clearOrderStatuses: PropTypes.func.isRequired,
  getOrderStatuses: PropTypes.func.isRequired,
  orderStatuses: PropTypes.objectOf(PropTypes.any),
  phone: PropTypes.string,
  t: PropTypes.func,
};

OrderStatuses.defaultProps = {
  orderStatuses: {},
  phone: "",
  t: () => {},
};

export default OrderStatuses;
