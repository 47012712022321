import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import {
  catchError,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
} from "rxjs/operators";

import { resetState } from "ducks/configuration";
import { setMessage } from "ducks/message";

import { resetCustomerPage } from "rtk_redux/slices/customerPageSlice";

import { MESSAGE } from "constants/message";
import withValidation from "modules/elValidadore";
import { urlValidators } from "modules/validators";

const SCOPE = "order-entry/order/";

export const EXIT_ORDER = `${SCOPE}EXIT_ORDER`;
export const EXIT_ORDER_SUCCESS = `${SCOPE}EXIT_ORDER_SUCCESS`;
export const EXIT_ORDER_ERROR = `${SCOPE}EXIT_ORDER_ERROR`;

export const exitOrder = withValidation(
  ({ clearState = true, orderId, url = "" } = {}) => ({
    type: EXIT_ORDER,
    clearState,
    orderId,
    url,
  }),
  {
    url: {
      message: MESSAGE.ORDER_NOT_STARTED,
      validator: urlValidators,
    },
  }
);

export function exitOrderSuccess({ clearState, orderId } = {}) {
  return {
    type: EXIT_ORDER_SUCCESS,
    clearState,
    orderId,
  };
}

export function exitOrderError(error) {
  return {
    type: EXIT_ORDER_ERROR,
    error,
  };
}

export const exitOrderEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(EXIT_ORDER),
    mergeMap(({ clearState, orderId, ...action }) =>
      fetch(action, {
        method: "DELETE",
      }).pipe(
        mapTo({ clearState, orderId }),
        map(exitOrderSuccess),
        catchError((error) => of(exitOrderError(error)))
      )
    )
  );

export const exitOrderSuccessEpic = (action$) =>
  action$.pipe(
    ofType(EXIT_ORDER_SUCCESS),
    pluck("clearState"),
    filter((clearState) => clearState),
    mergeMap(() => [
      resetState(),
      resetCustomerPage(),
      setMessage({
        message: MESSAGE.ORDER_EXITED,
      }),
    ])
  );
