import { combineEpics, ofType } from "redux-observable";
import { mapTo } from "rxjs/operators";

import {
  PLACE_ORDER,
  PLACE_ORDER_ERROR,
  PLACE_ORDER_SUCCESS,
} from "ducks/order/ducklings/place";

const SCOPE = "order-entry/loading/";

export const SET_LOADING = `${SCOPE}SET_LOADING`;

export const setLoadingPlaceOrderEpic = (action$) =>
  action$.pipe(
    ofType(PLACE_ORDER),
    mapTo({ type: SET_LOADING, placeOrder: true })
  );

export const removeLoadingPlaceOrderEpic = (action$) =>
  action$.pipe(
    ofType(PLACE_ORDER_SUCCESS, PLACE_ORDER_ERROR),
    mapTo({ type: SET_LOADING, placeOrder: false })
  );

export const initialState = { placeOrder: false };

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_LOADING:
      return { ...state, ...action };
    default:
      return state;
  }
}

export const epics = combineEpics(
  setLoadingPlaceOrderEpic,
  removeLoadingPlaceOrderEpic
);
