import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getActiveOrderProduct } from "ducks/order/selectors";
import { updateOrderProductSauce } from "ducks/orderProduct";
import { getActiveOrderProductSauce } from "ducks/orderProduct/selectors";
import { getSelfLink } from "selectors/related";

import { WEIGHTS } from "constants/topping";
import kebabCase from "modules/kebabCase";

const Sauce = ({
  sauces,
  sauceToppingCode,
  optionDescription = "",
  optionName,
}) => {
  const dispatch = useDispatch();
  const activeOrderProduct = useSelector(getActiveOrderProduct);
  const activeOrderProductLink = getSelfLink(activeOrderProduct);

  const { code: activeSauceToppingCode, weight: activeSauceToppingWeight } =
    useSelector(getActiveOrderProductSauce(sauces));

  const handleSauceOptionClick = () =>
    dispatch(
      updateOrderProductSauce({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        sauces,
        sauceToppingCode,
        sauceToppingWeight: WEIGHTS.NORMAL,
      })
    );

  const isActive =
    sauceToppingCode === activeSauceToppingCode && activeSauceToppingWeight > 0;

  return (
    <button
      className={classNames("btn", isActive && "btn--fill")}
      data-quid={kebabCase(optionName)}
      key={sauceToppingCode}
      onClick={handleSauceOptionClick}
      title={optionDescription}
      type="button"
    >
      {optionName}
    </button>
  );
};

Sauce.propTypes = {
  optionCode: PropTypes.string.isRequired,
  optionDescription: PropTypes.string,
  sauceToppingCode: PropTypes.string.isRequired,
  sauces: PropTypes.array.isRequired,
  optionName: PropTypes.string.isRequired,
};

export default Sauce;
