/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getCategories } from "ducks/menu/selectors";

import { PIZZA_CATEGORY } from "constants/menu";

import Page from "components/Page";

import { categoryButtonStyles } from "./Fulfiller.styles";

const Fulfiller = ({
  goToEverythingElse,
  goToPizzas,
  orderCoupon,
  resetActiveOrderProduct,
  sidebar = null,
  t,
}) => {
  const routeToCategory = (category) => {
    resetActiveOrderProduct();
    if (category === PIZZA_CATEGORY) goToPizzas();
    else goToEverythingElse(category.toLowerCase());
  };
  const categories = useSelector(getCategories);

  return (
    <Page id="fulfiller" sidebar={sidebar} title={t("fulfiller:title")}>
      <p>
        <b>{t("fulfiller:subtitle")}</b>
      </p>
      <div className="grid">
        {orderCoupon.unfulfilledGroups && orderCoupon.unfulfilledGroups[0]
          ? orderCoupon.unfulfilledGroups[0].map(({ categoryCode }) => (
              <button
                key={categoryCode}
                type="button"
                css={categoryButtonStyles}
                className="btn grid__cell--1/2 grid__cell--1/3@desktop"
                onClick={() => routeToCategory(categoryCode)}
              >
                {categories[categoryCode].categoryName}
              </button>
            ))
          : null}
      </div>
    </Page>
  );
};

Fulfiller.propTypes = {
  goToEverythingElse: PropTypes.func.isRequired,
  goToPizzas: PropTypes.func.isRequired,
  orderCoupon: PropTypes.shape({
    unfulfilledGroups: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.shape({ categoryCode: PropTypes.string }))
    ),
  }).isRequired,
  resetActiveOrderProduct: PropTypes.func.isRequired,
  sidebar: PropTypes.node,
  t: PropTypes.func.isRequired,
};

export default Fulfiller;
