/** @jsxImportSource @emotion/react */
import { useState } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getCurrentOrderId } from "ducks/order/selectors";

import { selectOrderHistory } from "rtk_redux/slices/orderHistorySlice";

import { ordersWrapper } from "./NewOrderHistory.styles";

import PastOrder from "./PastOrder";

const NewOrderHistory = ({ t }) => {
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const currentOrderId = useSelector(getCurrentOrderId);
  const orderHistory = useSelector(selectOrderHistory);

  const handleExpandOrder = (storeId) => {
    storeId === expandedOrderId
      ? setExpandedOrderId(null)
      : setExpandedOrderId(storeId);
  };

  const orders = orderHistory.map((order, index) => (
    <PastOrder
      order={order}
      index={index}
      key={order.id}
      t={t}
      currentOrderId={currentOrderId}
      expandedOrderId={expandedOrderId}
      handleExpandOrder={handleExpandOrder}
    />
  ));

  return (
    <>
      <header className="title-group">
        <h2 className="title-group__section" data-quid="heading-recent-orders">
          {t("customer:orders:order_history:recent_orders")}
        </h2>
      </header>
      {orderHistory.length ? (
        <div css={ordersWrapper} data-quid="historical-orders">
          {orders}
        </div>
      ) : (
        <p>{t("customer:orders:order_history:not_found")}</p>
      )}
    </>
  );
};

export default NewOrderHistory;

NewOrderHistory.propTypes = {
  t: PropTypes.func.isRequired,
};
