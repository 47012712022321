/** @jsxImportSource @emotion/react */
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getActiveOrderProduct } from "ducks/order/selectors";
import { updateOrderProductTopping } from "ducks/orderProduct";
import { getSelfLink } from "selectors/related";

import { AREAS } from "constants/order";
import { WEIGHT, WEIGHTS } from "constants/topping";
import kebabCase from "modules/kebabCase";

import { ReactComponent as PizzaPartLeft } from "assets/images/pizza_part_left.svg";
import { ReactComponent as PizzaPartRight } from "assets/images/pizza_part_right.svg";
import { ReactComponent as PizzaPartWhole } from "assets/images/pizza_part_whole.svg";

import {
  areaButtonStyles,
  toppingAreaButtonWrapperStyles,
  toppingAreaWrapperStyles,
  toppingButtonStyles,
  toppingOptionWrapperStyles,
} from "./ToppingOption.styles";

const areaOptions = [
  { area: AREAS.LEFT, icon: PizzaPartLeft },
  { area: AREAS.WHOLE, icon: PizzaPartWhole },
  { area: AREAS.RIGHT, icon: PizzaPartRight },
];

const weightOptions = [
  { translationKey: "food:toppings.light", weight: WEIGHT[0.5].value },
  { translationKey: "food:toppings.normal", weight: WEIGHT[1].value },
  { translationKey: "food:toppings.extra", weight: WEIGHT[1.5].value },
];

const getSelectedArea = ({ activeOrderProductOptions, optionId }) => {
  const areaWeights = activeOrderProductOptions[optionId];

  if (!areaWeights) return null;

  if (Object.keys(areaWeights).length === 1) {
    const [selectedArea] = Object.keys(areaWeights);
    return areaWeights[selectedArea] > 0 ? selectedArea : null;
  }

  if (areaWeights?.[AREAS.WHOLE] === WEIGHTS.ZERO) return null;
  if (areaWeights?.[AREAS.LEFT] === WEIGHTS.ZERO) return AREAS.RIGHT;
  if (areaWeights?.[AREAS.RIGHT] === WEIGHTS.ZERO) return AREAS.LEFT;

  if (areaWeights) return AREAS.WHOLE;

  return null;
};

const isSelectedArea = ({ activeOrderProductOptions, optionId, area }) =>
  getSelectedArea({ activeOrderProductOptions, optionId }) === area;

const getToppingWeight = ({ activeOrderProductOptions, optionId }) => {
  const selectedArea = getSelectedArea({ activeOrderProductOptions, optionId });
  if (!selectedArea) return WEIGHTS.NORMAL;

  return activeOrderProductOptions[optionId][selectedArea];
};

const getIsToppingSelected = ({ activeOrderProductOptions, optionId }) =>
  getSelectedArea({ activeOrderProductOptions, optionId }) !== null;

const ToppingOption = ({
  optionId,
  optionCode,
  optionName,
  optionDescription,
  activeOrderProductOptions,
  deleteOrderProductOption,
  isDefaultTopping = false,
  t,
}) => {
  const dispatch = useDispatch();
  const activeOrderProduct = useSelector(getActiveOrderProduct);
  const activeOrderProductLink = getSelfLink(activeOrderProduct);

  const handleOptionClickAddRemove = (selected) => () => {
    selected
      ? deleteOrderProductOption(optionCode)
      : dispatch(
          updateOrderProductTopping({
            ...activeOrderProduct,
            url: activeOrderProductLink,
            toppingCode: optionCode,
            isDefaultTopping,
          })
        );
  };

  const handleOptionAreaClick = (area) => () => {
    dispatch(
      updateOrderProductTopping({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        area,
        toppingCode: optionCode,
        toppingWeight: getToppingWeight({
          activeOrderProductOptions,
          optionId,
        }),
        isDefaultTopping,
      })
    );
  };

  const handleOptionWeightClick = (e) => {
    dispatch(
      updateOrderProductTopping({
        ...activeOrderProduct,
        url: activeOrderProductLink,
        area: getSelectedArea({ activeOrderProductOptions, optionId }),
        toppingCode: optionCode,
        toppingWeight: e.target.value,
        isDefaultTopping,
      })
    );
  };

  const selected = getIsToppingSelected({
    activeOrderProductOptions,
    optionId,
  });

  return (
    <div css={toppingOptionWrapperStyles}>
      <button
        css={toppingButtonStyles}
        className={classNames("btn", { ["btn--fill"]: selected })}
        data-quid={kebabCase(optionName)}
        key={optionId}
        onClick={handleOptionClickAddRemove(selected)}
        title={optionDescription}
        type="button"
      >
        {optionName}
      </button>
      {selected && (
        <div className="grid" css={toppingAreaWrapperStyles}>
          <div
            className="grid__cell--1 grid__cell--1/2@tablet"
            css={toppingAreaButtonWrapperStyles}
          >
            {areaOptions.map(({ icon: Icon, area }) => (
              <button
                key={area}
                data-quid={kebabCase(`${optionName}-topping-area-${area}`)}
                css={areaButtonStyles(
                  isSelectedArea({ activeOrderProductOptions, optionId, area })
                )}
                onClick={handleOptionAreaClick(area)}
              >
                <Icon />
              </button>
            ))}
          </div>
          <div className="text-field grid__cell--1 grid__cell--1/2@tablet">
            <select
              className="text-field__input"
              name={kebabCase(`${optionName}-weight-selection`)}
              data-quid={kebabCase(`${optionName}-weight-selection`)}
              value={getToppingWeight({ activeOrderProductOptions, optionId })}
              onChange={handleOptionWeightClick}
            >
              {weightOptions.map(({ weight, translationKey }) => (
                <option
                  key={weight}
                  data-quid={kebabCase(`${optionName}-weight-option-${weight}`)}
                  value={weight}
                >
                  {t(translationKey)}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

ToppingOption.propTypes = {
  optionId: PropTypes.string.isRequired,
  optionCode: PropTypes.string.isRequired,
  optionName: PropTypes.string.isRequired,
  optionDescription: PropTypes.string,
  activeOrderProductOptions: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.objectOf(PropTypes.number),
    ])
  ),
  deleteOrderProductOption: PropTypes.func.isRequired,
  isDefaultTopping: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default ToppingOption;
