import React from "react";

import { orderStatusHeadings } from "constants/customer";

import TableRow from "components/TableRow";

import OrderStatus from "./OrderStatus";
import { UTILITY_HEADING_COUNT } from "./OrderStatusesHeading";

const OrderStatusesRow = (props) => (
  <TableRow
    collapsible
    defaultOpen
    headings={orderStatusHeadings}
    utilityHeadingCount={UTILITY_HEADING_COUNT}
    {...props}
  >
    <OrderStatus {...props} />
  </TableRow>
);

export default OrderStatusesRow;
