import { css } from "@emotion/react";

export default css`
  @media (orientation: landscape) (max-width: 45rem) {
    .app {
      .app__body {
        .body__page {
          .page__sidebar {
            display: block;
            overflow: auto;
          }
        }
      }
    }
  }
`;
