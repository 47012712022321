import React, { Fragment } from "react";

import PropTypes from "prop-types";

import getKeysAndSortByNewest from "modules/getKeysAndSortByNewest";

import OrderHistoryHeading from "./OrderHistoryHeading";
import OrderHistoryRow from "./OrderHistoryRow";

const OrderHistory = ({ orderHistory, reorder, t }) => {
  const hasHistory = Object.keys(orderHistory).length > 0;

  return (
    <Fragment>
      <header className="title-group">
        <h2 className="title-group__section" data-quid="heading-recent-orders">
          {t("customer:orders:order_history:recent_orders")}
        </h2>
      </header>

      {hasHistory ? (
        <table
          className="table--no-stripe table--border-bottom"
          data-quid="historical-orders"
        >
          <OrderHistoryHeading t={t} />

          {getKeysAndSortByNewest(orderHistory).map((id) => (
            <OrderHistoryRow
              key={id}
              reorder={reorder}
              t={t}
              {...orderHistory[id]}
            />
          ))}
        </table>
      ) : (
        <span>{t("customer:orders:order_history:not_found")}</span>
      )}
    </Fragment>
  );
};

OrderHistory.propTypes = {
  orderHistory: PropTypes.objectOf(PropTypes.any),
  reorder: PropTypes.func.isRequired,
  t: PropTypes.func,
};

OrderHistory.defaultProps = {
  orderHistory: {},
  t: () => {},
};

export default OrderHistory;
