import React from "react";

import PropTypes from "prop-types";

import { ROUTE_TITLES } from "routes";

import uuid from "modules/uuid";

import CouponEntry from "components/Coupons/CouponEntry";
import CouponGroup from "components/Coupons/CouponGroup";
import Page from "components/Page";
import Tabs from "components/Tabs";

import NewCouponGroups from "./NewCouponGroups";

const Coupons = ({
  coupons = [],
  createOrderCoupon = () => {},
  currentTabId,
  goToCoupons,
  manualCouponEntryTab,
  marketCode,
  showNewCouponPage,
  sidebar = null,
  t = () => {},
  tabs = [
    {
      active: true,
      icon: "star",
      id: uuid(),
      path: "coupons",
      text: "Coupons",
    },
  ],
}) => {
  const enteringCouponManually = currentTabId === manualCouponEntryTab.id;
  const tabbar = <Tabs goTo={goToCoupons} t={t} tabs={tabs} />;

  const generatedPageTitle = showNewCouponPage
    ? t(`coupons:${currentTabId.toLowerCase()}_title`)
    : t("coupons:title");
  const pageTitle = enteringCouponManually
    ? t(manualCouponEntryTab.text)
    : generatedPageTitle;

  const couponsList = Object.values(coupons);

  const generatedCouponGroup = showNewCouponPage ? (
    <NewCouponGroups
      coupons={couponsList}
      createOrderCoupon={createOrderCoupon}
      currentTabId={currentTabId}
      marketCode={marketCode}
      t={t}
    />
  ) : (
    <CouponGroup
      coupons={couponsList}
      createOrderCoupon={createOrderCoupon}
      marketCode={marketCode}
    />
  );

  return (
    <Page
      id="coupons"
      sidebar={sidebar}
      tabs={tabbar}
      title={pageTitle}
      seoTitle={ROUTE_TITLES.COUPONS}
    >
      {enteringCouponManually ? (
        <CouponEntry createOrderCoupon={createOrderCoupon} t={t} />
      ) : (
        generatedCouponGroup
      )}
    </Page>
  );
};

Coupons.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.object),
  createOrderCoupon: PropTypes.func,
  currentTabId: PropTypes.string.isRequired,
  goToCoupons: PropTypes.func.isRequired,
  manualCouponEntryTab: PropTypes.object,
  marketCode: PropTypes.string.isRequired,
  showNewCouponPage: PropTypes.bool,
  sidebar: PropTypes.node,
  t: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      icon: PropTypes.string,
      id: PropTypes.string,
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default Coupons;
