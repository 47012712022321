import { createListenerMiddleware } from "@reduxjs/toolkit";

import { FINISH } from "routes";

import { baseApi } from "rtk_redux/api/baseApi";
import { resetCarsideValues } from "rtk_redux/slices/carsideInformationSlice";
import { resetCustomerFormState } from "rtk_redux/slices/customerInformationFormSlice";
import { resetCustomerPage } from "rtk_redux/slices/customerPageSlice";
import { setPaymentTypeValue } from "rtk_redux/slices/finishSlice";
import { resetFutureTimedOrderState } from "rtk_redux/slices/futureTimedOrderSlice";
import { resetOrderHistory } from "rtk_redux/slices/orderHistorySlice";
import { resetServiceMethodState } from "rtk_redux/slices/serviceMethodSlice";

import { customerMiddleware } from "./customerMiddleware";
import { orderHistoryMiddleware } from "./orderHistoryMiddleware";
import { tealiumMiddleware } from "./tealiumMiddleware";

export const listenerMiddleware = createListenerMiddleware();

// resets everything to do with customer information
listenerMiddleware.startListening({
  actionCreator: resetCustomerPage,
  effect: ({}, { dispatch }) => {
    dispatch(resetServiceMethodState());
    dispatch(resetCustomerFormState());
    dispatch(resetFutureTimedOrderState());
    dispatch(resetCarsideValues());
    dispatch(resetOrderHistory());
    dispatch(baseApi.util.resetApiState());
  },
});

// clear the selected payment type when leaving FINISH PAGE
listenerMiddleware.startListening({
  predicate: (_action, currState, prevState) => {
    const isRouteChange =
      prevState.location.pathname !== currState.location.pathname;
    const isLeavingFinishPage = prevState.location.type === FINISH;
    return isRouteChange && isLeavingFinishPage;
  },
  effect: ({}, { dispatch }) => {
    dispatch(setPaymentTypeValue(""));
  },
});

customerMiddleware(listenerMiddleware);
orderHistoryMiddleware(listenerMiddleware);
tealiumMiddleware(listenerMiddleware);
