import React, { PureComponent } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import blurOnEnter from "modules/blurOnEnter";

import RequiredMark from "components/RequiredMark";

class ProfileField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.onChange = this.onChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidUpdate(props) {
    this.handleUpdate(props);
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });

    this.props.handleChange(e);
  }

  handleUpdate(props) {
    const { value } = this.props;

    if (props.value !== value)
      this.setState({
        value: value === null ? "" : value,
      });
  }

  render() {
    const {
      className,
      handleBlur,
      handleFocus,
      id,
      inputType,
      isInvalid,
      label,
      max,
      noLabel,
      quidBase,
      required,
      t,
    } = this.props;

    let { value } = this.state;
    value = value || "";

    const inputTypeMap = {
      phone: "tel",
      email: "email",
    };

    const type = inputTypeMap[inputType] || "text";

    return (
      <div
        className={classNames(
          "form__control-group grid__cell--1",
          className,
          isInvalid && "error"
        )}
        data-quid={quidBase}
      >
        {!noLabel && (
          <label
            className="text-field__label"
            htmlFor={id}
            data-quid={`${quidBase}-label`}
          >
            {required ? <RequiredMark /> : null} {label}
          </label>
        )}

        {inputType === "textarea" ? (
          <textarea
            className={isInvalid ? "parsley-error" : undefined}
            data-quid={`${quidBase}-input`}
            id={id}
            onBlur={handleBlur}
            onChange={this.onChange}
            onFocus={handleFocus}
            onKeyPress={blurOnEnter}
            placeholder={label}
            maxLength={max}
            value={value}
          />
        ) : (
          <input
            className={isInvalid ? "parsley-error" : undefined}
            data-quid={`${quidBase}-input`}
            id={id}
            maxLength={max}
            onBlur={handleBlur}
            onChange={this.onChange}
            onFocus={handleFocus}
            onKeyPress={blurOnEnter}
            placeholder={label}
            type={type}
            value={value}
            required={required}
          />
        )}

        {isInvalid && (
          <p className="error-message parsley-required">
            {t("negative:please_enter_a_valid_value", {
              value: label.toLowerCase(),
            })}
          </p>
        )}
      </div>
    );
  }
}

ProfileField.propTypes = {
  className: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  max: PropTypes.number,
  noLabel: PropTypes.bool,
  quidBase: PropTypes.string.isRequired,
  required: PropTypes.bool,
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

ProfileField.defaultProps = {
  className: "",
  handleChange: () => {},
  inputType: "text",
  isInvalid: false,
  max: 524288, // default value of maxLength
  noLabel: false,
  required: false,
  value: "",
};

export default ProfileField;
