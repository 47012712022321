import React, { PureComponent } from "react";

import PropTypes from "prop-types";

class OrderLocation extends PureComponent {
  constructor(props) {
    super(props);

    this.setOrderLocation = this.setOrderLocation.bind(this);
    this.propExists = this.propExists.bind(this);
  }

  setOrderLocation() {
    const {
      isOpen,
      orderId,
      setOrderLocation,
      currentServiceMethod: serviceMethod,
    } = this.props;
    setOrderLocation({ isOpen, orderId, serviceMethod });
  }

  propExists(prop) {
    return !!this.props[prop];
  }

  render() {
    const { disabled, mappings, t, ...order } = this.props;

    return (
      <tbody>
        <tr
          className="interactive"
          disabled={disabled}
          onClick={!disabled ? this.setOrderLocation : undefined}
        >
          {mappings.map(({ key, label, values }) => (
            <td data-th={t(label)} key={key}>
              {values
                ? values
                    .filter(this.propExists)
                    .map((prop) => <div key={prop}>{order[prop]}</div>)
                : order[key]}
            </td>
          ))}
        </tr>
      </tbody>
    );
  }
}

OrderLocation.propTypes = {
  currentServiceMethod: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  mappings: PropTypes.arrayOf(PropTypes.object),
  orderId: PropTypes.string,
  setOrderLocation: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

OrderLocation.defaultProps = {
  currentServiceMethod: "",
  disabled: false,
  isOpen: true,
  orderId: "",
  mappings: [],
};

export default OrderLocation;
