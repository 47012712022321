import React from "react";

import { orderHistoryHeadings } from "constants/customer";

import TableRow from "components/TableRow";

import HistoricalOrder from "./HistoricalOrder";
import { UTILITY_HEADING_COUNT } from "./OrderHistoryHeading";

const OrderHistoryRow = (props) => (
  <TableRow
    collapsible
    headings={orderHistoryHeadings}
    utilityHeadingCount={UTILITY_HEADING_COUNT}
    {...props}
  >
    <HistoricalOrder {...props} />
  </TableRow>
);

export default OrderHistoryRow;
