import React, { useState } from "react";

import PropTypes from "prop-types";

import TypeAhead from "components/StoreLocator/Strategy/TypeAhead";

const DominosTypeAhead = ({
  className = "",
  debounce = 500,
  disabled = false,
  fetchPredictions,
  input = "",
  label = "",
  name = "",
  quidBase,
  onChange,
  onSelect,
  suggestions,
  required = false,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  const clearSuggestions = () => {
    setShowSuggestions(false);
  };

  const fetchingPredictions = () => {
    if (input.length) {
      fetchPredictions(input);
    }
    setShowSuggestions(!!input.length);
  };

  const handleSelect = (suggestion) => {
    setShowSuggestions(false);
    onSelect(suggestion);
  };

  return (
    <TypeAhead
      className={className}
      debounce={debounce}
      input={input}
      label={label}
      fetchPredictions={fetchingPredictions}
      onChange={onChange}
      clearSuggestions={clearSuggestions}
      handleSelect={handleSelect}
      name={name}
      quidBase={quidBase}
      suggestions={showSuggestions ? suggestions : []}
      disabled={disabled}
      required={required}
    />
  );
};

DominosTypeAhead.propTypes = {
  className: PropTypes.string,
  debounce: PropTypes.number,
  input: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  quidBase: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchPredictions: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({ description: PropTypes.string.isRequired })
  ).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default DominosTypeAhead;
