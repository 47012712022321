/** @jsxImportSource @emotion/react */
import React from "react";

import { css } from "@emotion/react";
import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import {
  Tooltip as ReakitTooltip,
  TooltipArrow,
  TooltipReference,
  useTooltipState,
} from "reakit/Tooltip";

const styles = css`
  text-align: center;
  max-width: 45ch;
  color: white;
  background-color: rgba(33, 33, 33, 0.9);
  font-weight: bold;
  padding: 8px;
  border-radius: 4px;
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;
  [data-enter] & {
    opacity: 1;
  }
`;

const Tooltip = ({ children, title, ...props }) => {
  const tooltip = useTooltipState({ animated: 250 });
  return (
    <>
      <TooltipReference {...tooltip} ref={children.ref} {...children.props}>
        {(referenceProps) => React.cloneElement(children, referenceProps)}
      </TooltipReference>
      <ReakitTooltip
        {...tooltip}
        {...props}
        style={{ background: "none", padding: 0 }}
      >
        <div css={styles} data-quid="tooltip">
          <TooltipArrow
            {...tooltip}
            style={{ fill: "rgba(33, 33, 33, 0.9)" }}
          />
          {DOMPurify.sanitize(title)}
        </div>
      </ReakitTooltip>
    </>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Tooltip;
