import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showFutureTimeError: false,
  date: new Date(),
  time: "",
  timeList: [],
};

const futureTimedOrderSlice = createSlice({
  name: "futureTimedOrder",
  initialState,
  reducers: {
    setShowFutureTimeError: (state, { payload }) => {
      state.showFutureTimeError = payload;
    },
    setDate: (state, { payload }) => {
      state.date = payload;
    },
    setTime: (state, { payload }) => {
      state.time = payload;
    },
    setTimeList: (state, { payload }) => {
      state.timeList = payload;
    },
    resetFutureTimedOrderState: () => initialState,
  },
});

export const {
  setShowFutureTimeError,
  setDate,
  setTime,
  setTimeList,
  resetFutureTimedOrderState,
} = futureTimedOrderSlice.actions;

export default futureTimedOrderSlice.reducer;

// Selectors
export const selectShowFutureTimeError = (state) =>
  state.futureTimedOrder.showfutureTimeError;
export const selectDate = (state) => state.futureTimedOrder.date;
export const selectTime = (state) => state.futureTimedOrder.time;
export const selectTimeList = (state) => state.futureTimedOrder.timeList;
