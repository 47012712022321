import React from "react";

import PropTypes from "prop-types";

import OrderDetail from "./OrderDetail";

const OrderDetails = ({ headings, storeOrderId, ...props }) => (
  <ul
    className="grid__cell--1 grid__cell--1/2@desktop list order-details"
    data-quid={`order-details-${storeOrderId}`}
  >
    {headings.map(({ id, ...heading }) => (
      <OrderDetail
        id={id}
        key={id}
        storeOrderId={storeOrderId}
        {...props}
        {...heading}
      />
    ))}
  </ul>
);

OrderDetails.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  storeOrderId: PropTypes.string.isRequired,
};

OrderDetails.defaultProps = {
  headings: [],
};

export default OrderDetails;
