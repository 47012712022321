import { createSelector } from "reselect";

export const getStreetCategories = ({ streetCategories = {} } = {}) =>
  streetCategories;

export const getStreetCategoriesValues = createSelector(
  [getStreetCategories],
  ({
    data: { attributes: { streetCategories } } = {
      attributes: { streetCategories: {} },
    },
  }) =>
    Object.entries(streetCategories).map(([option, key]) => ({
      key,
      option,
    }))
);
