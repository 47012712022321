import React from "react";

import { useSelector } from "react-redux";

import { getNewExperienceFeature } from "ducks/market/selectors";

import { default as OldEverythingElse } from "./EverythingElse";
import NewEverythingElse from "./NewEverythingElse";

const EverythingElse = (props) => {
  const { newEverythingElse } = useSelector(getNewExperienceFeature);

  return newEverythingElse ? (
    <NewEverythingElse {...props} />
  ) : (
    <OldEverythingElse {...props} />
  );
};

export default EverythingElse;
