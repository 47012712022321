//TODO: write test
export default (toppings = []) =>
  toppings.reduce(
    (all, topping) => {
      const { isMeat, isSauce } = topping;

      if (isMeat) all.meats.push(topping);
      else if (isSauce) all.sauces.push(topping);
      else all.nonMeats.push(topping);

      return all;
    },
    { meats: [], nonMeats: [], sauces: [] }
  );
