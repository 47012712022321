import { css } from "@emotion/react";

export default css`
  html,
  body,
  .app {
    height: 100%;
  }

  body,
  .app {
    overflow: hidden;
  }

  body {
    font-weight: normal;
  }

  address {
    font-style: normal;
  }

  h2.heading__level_one {
    font-size: 2.5rem;
    line-height: 2.571428571rem;
  }

  .btn-group:not(.quantity) .btn[disabled] {
    border-color: var(--dpzBlue);
  }

  .app {
    display: flex;
    flex-direction: column;
  }

  .app__header {
    border-bottom: 1px solid #fff;
  }

  .app__body {
    background: #fff;
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .body__menu {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .body__page {
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
  }

  .page__sidebar {
    background-color: #fff;
    display: flex;
    flex: 0.75;
    min-width: 22rem;
    transition: transform 0.3s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  }

  .page__section {
    display: flex;
    flex-direction: column;
    flex: 2;
    overflow: hidden;

    &,
    > * {
      width: 100%;
    }
  }

  .section__banner,
  .section__footer {
    background: var(--bg);
    box-shadow: inset 0 0 0 1px var(--border);
    box-sizing: border-box;
    flex: 0;
  }

  .section__footer {
    border-top: 1px solid var(--border);
    padding: 1rem;
  }

  .section__content {
    display: flex;
    flex: 1;
    height: 100%;
    overflow: auto;
  }

  .content__pane {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 2;
    height: 100%;

    &.content__pane--split {
      .pane__content,
      .pane__footer {
        padding: 1rem;
      }

      .pane__content {
        height: 100%;
        overflow: auto;
      }
    }

    &:not(.content__pane--split) {
      overflow: auto;
      padding: 1rem;
    }
  }

  .subsection {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem 0 0 1rem;

    .split {
      display: flex;

      &.even {
        align-items: center;

        > .split__left,
        > .split__middle,
        > .split__right {
          flex: 1;
        }
      }

      &:not(.even) {
        > .split__left,
        > .split__right {
          display: flex;
        }

        > .split__left {
          align-items: flex-start;
          flex: 0;
          padding-right: 1rem;
        }

        > .split__middle {
          flex: 1;
        }

        > .split__right {
          align-items: flex-end;
          flex: 0;
          padding-left: 1rem;
        }
      }
    }
  }

  .subsection__header {
    flex: 0;
    padding: 0 1rem 0 0;
  }

  .subsection__body {
    flex: 1;
    overflow: auto;
    padding-right: 1rem;
  }

  .subsection__footer {
    flex: 0;
    padding: 1rem 1rem 1rem 0;
  }

  .app__footer {
    background: var(--bg);
    border-top: 1px solid var(--default-bg);
    box-sizing: border-box;
    flex: 0 0 auto;
    padding: 1rem;
    width: 100%;
    z-index: 1;
  }

  // Utility Classes

  .wrapper {
    height: 100%;
    width: 100%;
  }

  .interactive {
    &:not(.is-active) {
      cursor: pointer;

      &:hover,
      &:active {
        background-color: var(--interactive-hover);
      }
    }

    &[disabled] {
      background-color: var(--bg) !important;
    }
  }

  // Generic HTML Element Styles

  b {
    font-weight: bold;
  }

  hr {
    border: 0;
    border-bottom: 1px solid var(--h-border);
    margin: 0;
    margin-bottom: 1rem;
  }

  input {
    text-overflow: ellipsis;
  }

  table {
    border-collapse: unset;
    border-color: var(--border);
  }

  thead {
    th:empty {
      background-color: var(--bg);
    }
  }

  th,
  td {
    padding: 1rem;
  }

  tbody {
    .is-active {
      background-color: var(--interactive-bg);
    }

    tr {
      &:nth-child(2) {
        td {
          background-color: var(--bg);
          padding: 0;

          .collapsible-content {
            padding: 1rem;
          }
        }
      }
    }
  }

  .table--border-bottom {
    border-bottom: 0;

    thead {
      background-color: var(--bg);
    }

    th,
    td {
      border-color: var(--border);
      border-style: solid;
      border-width: 0;
    }

    tr:first-child {
      th,
      td {
        border-bottom-width: 1px;
      }
    }

    tr:not(:first-child) {
      th,
      td {
        border-left-width: 1px;
        box-shadow: inset 0 -1px 0 0 var(--border);
      }
    }
  }

  // Background Classes

  .bg--dominos-red {
    background-color: var(--dom-red);
  }

  // Text Classes

  .text--bigger {
    font-size: 1.25em;
    line-height: 1;

    &[data-icon]::before {
      font-size: 1.5em;
      line-height: 1;
    }
  }

  .text--left {
    text-align: left;
  }

  .text--center {
    text-align: center;
  }

  .text--right {
    text-align: right;
  }

  .text--justify {
    text-align: justify;
  }

  .text--red {
    color: #f00;
  }

  .no-gutter {
    padding: 0;
  }

  // Spacing Adjustments

  .margin__bottom--1_25rem {
    margin-bottom: 1.25rem;
  }

  .margin__left--1_25rem {
    margin-left: 1.25rem;
  }

  // FO Adjustments

  .title-group {
    border-color: var(--border);
  }

  .list__badge--inline {
    margin-left: 1rem;
  }

  .list__badge--block {
    margin-top: 0.5rem;
  }

  .msg__body {
    word-wrap: break-word;
    max-width: calc(100% - 8rem);
  }

  .grid__cell,
  [class*="grid__cell"] {
    letter-spacing: normal;
  }

  .collapsible {
    &.is-open {
      .toggle--closed {
        display: none;
      }

      .collapsible__item {
        max-height: 60rem;
      }
    }

    &:not(.is-open) {
      .toggle--open {
        display: none;
      }

      .collapsible__item {
        max-height: 0;
      }
    }

    .collapsible__item {
      border-top: unset;
      margin: 0;
      padding: 0;
      transform-origin: bottom left;

      ul li {
        box-shadow: inset 0 -1px 0 var(--border);
      }
    }
  }

  .icon-only {
    &[data-icon] {
      padding-left: 0.5em;
      padding-right: 0.5em;

      &::before {
        margin: 0;
        width: auto;
      }
    }
  }

  .list {
    .list__item-content {
      font-size: unset;
    }

    .list__item--with-controls {
      display: flex;
      flex-flow: row-reverse wrap;
      padding: 0;

      .list__item-content {
        flex: 1;
        margin-right: 1rem;
        padding: 1rem;
      }

      .list__item-controls {
        margin-right: 1rem;

        &:checked ~ .list__item-content {
          font-weight: bold;
        }
      }

      .list__item--addendum {
        font-size: 1rem;
        margin: 0 2rem;
      }
    }
  }

  .btn {
    box-sizing: border-box;
  }

  .btn--fill {
    background-color: var(--dpzBlue);
    border-color: var(--dpzBlue);

    &[disabled] {
      border-color: var(--disabledBg);
    }

    &.fill--left {
      background-image: var(--fill-left);
      border-image: var(--fill-left);
      border-width: 1px 0;
    }

    &.fill--right {
      background-image: var(--fill-right);
      border-image: var(--fill-right);
      border-width: 1px 0;
    }
  }

  .btn--link {
    border-width: 0;
    text-decoration: underline;
    font-weight: bold;
  }

  .btn--default-locator-strategy-toggler {
    padding-left: 0;
    margin-bottom: 0.5rem;
  }

  .btn-group {
    &.btn-group--white {
      background-color: #fff;
    }

    &.btn-group--justified {
      align-items: stretch;
      display: flex;

      .btn {
        padding: 1rem;
      }

      &:not(.btn-group--unlimited) .btn:not(:last-child) {
        border-right-width: 0;
      }

      > * {
        flex-basis: auto;
        flex-grow: 1;
        transition: background-color 0.1s ease;

        &:first-child:last-child {
          border-radius: 3px;
        }
      }
    }

    &.btn-group--unlimited {
      flex-wrap: wrap;

      .btn {
        margin-left: unset;
      }

      > * {
        flex-basis: 25%;
        max-width: 25%;

        &:not(:first-child):not(:last-child),
        &.btn,
        & {
          margin-left: unset;
        }
      }

      &.toggleable {
        border: 1px solid var(--dpzBlue);
        border-bottom-width: 0;
        border-radius: 3px;
        border-right-width: 0;
        margin-top: -1px;
        overflow: hidden;

        > * {
          border-radius: unset;
          border-width: 0 1px 1px 0;

          &:first-child:last-child {
            flex-basis: 100%;
            max-width: 100%;
          }

          &:first-child:nth-last-child(2) {
            &.btn,
            & ~ .btn {
              flex-basis: 50%;
              max-width: 50%;
            }
          }

          &:first-child:nth-last-child(3) {
            &.btn,
            & ~ .btn {
              flex-basis: 33.33333333%;
              max-width: 33.33333333%;
            }
          }

          &:last-child {
            border-bottom-right-radius: 3px;
          }
        }
      }

      &:not(.toggleable) {
        > * {
          flex-basis: calc(25% - 0.75rem);
          max-width: calc(25% - 0.75rem);

          &:not(:first-child):not(:last-child),
          & {
            border-radius: 3px;
            margin: 0 1rem 1rem 0;

            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.btn-group--nested {
      display: inline-flex;
      justify-content: center;
      padding: 0;

      .btn:first-child:last-child {
        border-radius: 3px;
      }

      .btn {
        margin-left: unset;
      }

      > * {
        min-width: 0;
        white-space: normal;
        word-wrap: break-word;

        &,
        &.btn {
          padding: 1rem 0;
        }

        &.btn--flex {
          flex: 1;
        }

        &.btn--icon-only {
          padding: 0 0.5rem;
        }
      }
    }

    &.btn-group--vertical {
      display: flex;
      flex-direction: column;

      .btn-group--nested:not(:first-child):not(:last-child) .btn {
        border-radius: unset;
      }

      .btn {
        margin-left: unset;
      }

      > * {
        border-radius: unset;
        margin: 0;

        &,
        > * {
          border-width: 1px 1px 0;
        }

        &[disabled] {
          border-color: inherit;
        }

        &:first-child:last-child {
          border-width: 1px;
          border-radius: 3px;
        }

        &:first-child:not(:last-child) {
          border-radius: 3px 3px 0 0;

          &,
          > * {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &:last-child:not(:first-child) {
          border-radius: 0 0 3px 3px;
          border-width: 1px;

          &,
          > * {
            border-bottom-width: 1px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }

        > *:first-child {
          border-right-width: 0;
        }
      }
    }
  }

  .btn--small {
    padding: 0.5rem;
  }

  .button-field label {
    margin-bottom: 0.5rem;
  }

  .required-mark {
    color: var(--negative-fg);
  }

  .toaster {
    position: fixed;
    right: 1rem;
    top: 1rem;
    width: 30rem;
    z-index: 11;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & .msg--notify {
      position: static;
      width: auto;
      max-width: 100%;
    }
  }

  // Pizzas Page
  .pizzas.body__page .page__section .section__content .content__pane {
    &.content__pane--split .pane__content {
      padding-bottom: unset;
    }

    .btn-group:not(.btn-group--nested) {
      > *:not(.btn-group--nested) {
        padding: 1rem;
      }
    }

    .subsection {
      padding-left: 0;
      padding-top: 0;
    }

    .columns {
      display: flex;

      .subsection {
        flex: 1;
        min-width: unset;

        &:not(:first-child) {
          padding-left: 1rem;
        }
      }

      @media screen and (max-width: 70em) {
        & {
          flex-direction: column;

          .subsection:not(:first-child) {
            padding-left: 0;
            padding-top: 1rem;
          }
        }
      }
    }
  }

  // Finish Styles
  .delivery-instructions {
    margin-top: 2rem;

    .title-group__section {
      label {
        font-size: inherit;
      }
    }
  }
`;
