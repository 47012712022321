import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { DEFAULT_LOCATOR_STRATEGY } from "constants/locatorStrategy";
import { MARKET_CENTER } from "constants/market";
import { SERVICE_METHOD } from "constants/order";

import RequiredMark from "components/RequiredMark";
import "components/StoreLocator/Market/KE.css";
import DominosTypeAhead from "components/StoreLocator/Strategy/DominosTypeAhead";
import GoogleMapsTypeAhead from "components/StoreLocator/Strategy/GoogleMapsTypeAhead";
import Input from "components/StoreLocator/Strategy/Input";
import PinDrop, { MAP_OPTIONS } from "components/StoreLocator/Strategy/PinDrop";
import Select from "components/StoreLocator/Strategy/Select";

const ADDRESS_TYPES = Object.freeze({
  [ADDRESS.HOUSE]: {
    map: {
      fields: [],
      key: ADDRESS.HOUSE,
    },
    stdf: {
      fields: [ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD_KE]],
      key: ADDRESS.HOUSE,
    },
  },
  [ADDRESS.APARTMENT]: {
    map: {
      fields: [
        ADDRESS_FIELDS[ADDRESS.APARTMENT_NAME],
        ADDRESS_FIELDS[ADDRESS.APARTMENT_NUMBER],
      ],
      key: ADDRESS.APARTMENT,
    },
    stdf: {
      fields: [
        ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD_KE],
        ADDRESS_FIELDS[ADDRESS.APARTMENT_NAME],
        ADDRESS_FIELDS[ADDRESS.APARTMENT_NUMBER],
      ],
      key: ADDRESS.APARTMENT,
    },
  },
  [ADDRESS.OFFICE]: {
    map: {
      fields: [ADDRESS_FIELDS[ADDRESS.SUITE_NUMBER]],
      key: ADDRESS.APARTMENT,
    },
    stdf: {
      fields: [
        ADDRESS_FIELDS[ADDRESS.NEIGHBORHOOD_KE],
        ADDRESS_FIELDS[ADDRESS.OFFICE_NAME],
        ADDRESS_FIELDS[ADDRESS.SUITE_NUMBER],
      ],
      key: ADDRESS.APARTMENT,
    },
  },
});

const MARKET_ZOOM = 10;

const placesMethods = Object.freeze(["Carryout", "Delivery"]);

const optionalFields = [
  ADDRESS.NEIGHBORHOOD,
  ADDRESS.ADDRESS_NICKNAME,
  ADDRESS.STREET_NUMBER,
  ADDRESS.LOCATION_NAME,
];

class KE extends Component {
  constructor(props) {
    super(props);

    this.state = {
      streetSelected: false,
      invalid: [],
    };

    this.map = React.createRef();

    this.getAddressFields = this.getAddressFields.bind(this);
    this.getGeocodedResult = this.getGeocodedResult.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handlePinDrop = this.handlePinDrop.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setDefaultLocatorStrategy = this.setDefaultLocatorStrategy.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateStreet = this.updateStreet.bind(this);
    this.resetStreetSearch = this.resetStreetSearch.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
  }

  componentDidMount() {
    const { maps, serviceMethod, setUsesPlaces } = this.props;
    this.geocoderService = new maps.Geocoder();
    this.geocoderOK = maps.GeocoderStatus.OK;
    placesMethods.includes(serviceMethod) && setUsesPlaces(true);

    this.props.getRegions("KE");
  }

  setDefaultLocatorStrategy() {
    this.handleClear();
    this.props.setLocatorStrategy(DEFAULT_LOCATOR_STRATEGY);
  }

  transformAddress(address) {
    const newAddress = {};
    address.forEach((component) => {
      const { types, long_name, short_name } = component;
      types.forEach((type) => {
        if (!newAddress[type]) {
          newAddress[type] = {};
        }
        newAddress[type] = {
          long_name,
          short_name,
        };
      });
    });

    return newAddress;
  }

  getGeocodedResult(request, callback) {
    this.geocoderService.geocode(request, (response, status) => {
      if (status !== this.geocoderOK) {
        this.props.onError(status);
        return;
      }

      // If we have an address of type "route", use it.
      // If not, use the first available address component.
      const { address_components, geometry, place_id } =
        response.find((address) => address.types.includes("route")) ||
        response[0];
      const formattedAddress = this.transformAddress(address_components);
      const { locality, route, sublocality } = formattedAddress;
      const streetName = route?.long_name || "";
      const streetNameUnnamed = !streetName.match(/unnamed/i)
        ? route?.long_name
        : sublocality?.short_name || "";

      const address =
        `${streetNameUnnamed}, ${locality?.short_name}, KE`.trim();
      callback({
        address,
        addressComponents: address_components,
        geometry,
        placeId: place_id,
      });
    });
  }

  mapAddressComponentsToQueryKE(addressComponents, query = {}) {
    return addressComponents.reduce(
      (all, { long_name: longName, short_name: shortName, types }) => {
        let key;
        let value = longName;

        const { regions } = this.props;

        function swapKeysAndValuesWithMap(obj) {
          const result = new Map();
          for (const [key, value] of Object.entries(obj)) {
            result.set(value, key);
          }
          return result;
        }

        const regionMap = swapKeysAndValuesWithMap(regions);

        const formattedAddress = this.transformAddress(addressComponents);
        const { locality, route, sublocality } = formattedAddress;
        const streetName = route?.long_name || "";
        const streetNameUnnamed = !streetName.match(/unnamed/i)
          ? route?.long_name
          : sublocality?.short_name || "";

        const address =
          `${streetNameUnnamed}, ${locality?.short_name}, KE`.trim();

        if (types.includes("street_number")) key = "streetNumber";
        else if (types.includes("route")) key = "streetName";
        else if (types.includes("sublocality")) key = "neighborhood";
        else if (types.includes("locality")) {
          key = "regionCode";
          value = regionMap[shortName] || shortName;
        } else if (types.includes("postal_code")) key = "postalCode";
        else return all;

        return Object.assign(all, {
          [key]: value,
          address,
        });
      },
      query
    );
  }

  handlePinDrop(latLng) {
    this.getGeocodedResult(
      { location: latLng },
      ({ addressComponents, placeId, geometry }) => {
        const updateObject = Object.assign(
          {},
          ...Object.keys(ADDRESS_FIELDS)
            .filter((field) => field !== ADDRESS.ADDRESS_TYPE)
            .map((field) => ({ [field]: "" })),
          this.mapAddressComponentsToQueryKE(addressComponents, placeId),
          {
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
          },
          {
            placeId,
          }
        );
        this.props.handleChange(updateObject);
      }
    );
  }

  handleSearch() {
    const { address } = this.props;

    this.getGeocodedResult({ address }, ({ geometry }) => {
      this.map.current.dropPin(geometry.location);
    });
  }

  handleChange(updates) {
    this.setState({ ...updates });
  }

  handleClear() {
    const addressFields = this.getAddressFields(this.props.addressType);
    const updates = addressFields.reduce(
      (updatesObject, { mapping: fieldName }) => {
        updatesObject[fieldName] = "";
        return updatesObject;
      },
      {
        city: "",
        neighborhood: "",
        streetName: "",
        streetNumber: "",
        LocationName: "",
      }
    );

    this.props.handleChange(updates);
  }

  //eslint-disable-next-line class-methods-use-this
  getAddressFields(addressType) {
    const { locatorStrategy } = this.props;

    switch (locatorStrategy) {
      case DEFAULT_LOCATOR_STRATEGY:
        return ADDRESS_TYPES[addressType]
          ? ADDRESS_TYPES[addressType].map.fields
          : ADDRESS_TYPES[ADDRESS.HOUSE].map.fields;
      default:
        return ADDRESS_TYPES[addressType]
          ? ADDRESS_TYPES[addressType].stdf.fields
          : ADDRESS_TYPES[ADDRESS.HOUSE].stdf.fields;
    }
  }

  updateStreet({
    city,
    id: streetNameValue,
    neighborhood: neighborhoodValue,
    placesURL,
    postalCode: postalCodeValue,
  }) {
    const { handleChange, getPlaces } = this.props;
    handleChange({
      city,
      streetName: streetNameValue,
      neighborhood: neighborhoodValue,
      postalCode: postalCodeValue,
    });
    getPlaces({ url: placesURL });
    this.setState({
      streetSelected: true,
    });
  }

  resetStreetSearch() {
    const { handleChange } = this.props;
    this.setState({ streetSelected: false });
    handleChange({
      neighborhood: "",
      postalCode: "",
    });
  }

  handleFocus(update) {
    const [fieldName] = Object.keys(update);

    this.setState({
      invalid: this.state.invalid.filter(
        (invalidFieldName) => fieldName !== invalidFieldName
      ),
    });
  }

  validateInputs() {
    const propKeys = Object.keys(this.props);

    // All input fields except for neighborhood are required.
    this.setState({
      invalid: Object.keys(ADDRESS_FIELDS).filter(
        (field) =>
          propKeys.includes(field) &&
          this.props[field]?.length === 0 &&
          !optionalFields.includes(field)
      ),
    });
  }

  handleCityChange({ city }) {
    const { setRegionAsCity } = this.props;
    setRegionAsCity(city, "N/A");
  }

  render() {
    const {
      address,
      addressType,
      city,
      deliveryInstructions,
      regions,
      getStreets,
      handleChange,
      maps,
      marketConfigs: { DELIVERY_INSTRUCTIONS_MAX_LENGTH },
      serviceMethod,
      streetName,
      streets,
      t,
      locatorStrategy,
    } = this.props;
    const { handleFocus, validateInputs } = this;

    const fieldClasses = "grid__cell--1 grid__cell--1/2@desktop";
    const fieldClassesFull = "grid__cell--1";

    const { invalid } = this.state;

    const { streetSelected } = this.state;
    const isDefaultLocatorStrategy =
      locatorStrategy === DEFAULT_LOCATOR_STRATEGY;

    const suggestedStreets = streets
      .map(({ id, ...streetComponents }) => ({
        ...streetComponents,
        id,
        description: id,
      }))
      .slice(0, 4);

    const countryCodeList =
      process.env.NODE_ENV === "production" ? ["KE"] : ["KE", "US"];

    switch (serviceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return (
          <div className="grid">
            {isDefaultLocatorStrategy && (
              <div className="grid__cell--1 grid__cell--1/2@desktop margin__bottom--1_25rem">
                <PinDrop
                  mapOptions={Object.assign({}, MAP_OPTIONS, {
                    mapTypeControl: true,
                  })}
                  mapCenter={MARKET_CENTER.KENYA}
                  mapZoom={MARKET_ZOOM}
                  maps={maps}
                  onChange={this.handlePinDrop}
                  ref={this.map}
                />
              </div>
            )}
            <div className="grid__cell--1 grid__cell--1/2@desktop">
              <div className="grid">
                {!isDefaultLocatorStrategy && (
                  <div className="grid__cell--1">
                    <button
                      type="button"
                      className="btn btn--link btn--default-locator-strategy-toggler"
                      onClick={this.setDefaultLocatorStrategy}
                    >
                      {t("customer:locations.back_to_map")}
                    </button>
                  </div>
                )}

                <Select
                  className="grid__cell--1"
                  handleChange={handleChange}
                  label={t("customer:locations.address.address_type")}
                  name="addressType"
                  quidBase="store-locator-address-type"
                  source={Object.keys(ADDRESS_TYPES).reduce((types, type) => {
                    const { key, label } = ADDRESS_FIELDS[type];
                    types[key] = label;
                    return types;
                  }, {})}
                  t={t}
                  value={addressType}
                />

                {isDefaultLocatorStrategy && (
                  <Fragment>
                    <GoogleMapsTypeAhead
                      className="grid__cell--1"
                      input={address}
                      label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
                      maps={maps}
                      name="address"
                      onChange={({ address: addressValue, placeId }) =>
                        handleChange({
                          address: addressValue,
                          placeId,
                        })
                      }
                      options={{
                        componentRestrictions: {
                          country: countryCodeList,
                        },
                        types: ["geocode"],
                      }}
                      quidBase="store-locator-address"
                      required
                    />
                  </Fragment>
                )}
                {!isDefaultLocatorStrategy && (
                  <Fragment>
                    <Select
                      className="grid__cell--1"
                      handleChange={this.handleCityChange}
                      label={t("customer:locations.address.city")}
                      name="city"
                      quidBase="store-locator-city"
                      source={Object.freeze({
                        selectCity: "customer:locations.address.select_city",
                        ...regions,
                      })}
                      t={t}
                      value={city}
                      required={true}
                    />

                    <DominosTypeAhead
                      className="grid__cell--1"
                      debounce={250}
                      onChange={({ streetName }) => {
                        handleChange({
                          streetName,
                        });
                      }}
                      onSelect={this.updateStreet}
                      fetchPredictions={(streetNameValue) => {
                        getStreets({
                          countryCode: "KE",
                          customHref:
                            "/store-locator-typeahead-service/search/address",
                          street: streetNameValue,
                          streetLimit: "5",
                          power: true,
                        });
                      }}
                      label={t(ADDRESS_FIELDS[ADDRESS.STREET_NAME].label)}
                      removePredictionOnBodyClick={true}
                      name="streetName"
                      quidBase="store-locator-address-field"
                      input={streetName}
                      suggestions={suggestedStreets}
                      t={t}
                      required={true}
                    />

                    {this.getAddressFields(addressType).map(
                      ({ label, mapping: key }) => (
                        <Input
                          className={
                            key === "locationName"
                              ? fieldClassesFull
                              : fieldClasses
                          }
                          handleBlur={validateInputs}
                          handleChange={handleChange}
                          handleFocus={handleFocus}
                          isInvalid={
                            invalid.includes(key) &&
                            this.props[key].length === 0
                          }
                          isRequired={!optionalFields.includes(key)}
                          key={key}
                          label={t(label)}
                          name={key}
                          quidBase="store-locator-address-field"
                          t={t}
                          value={this.props[key]}
                        />
                      )
                    )}
                  </Fragment>
                )}
                {isDefaultLocatorStrategy && (
                  <Fragment>
                    {this.getAddressFields(addressType).map(
                      ({ label, mapping: key }) => (
                        <Input
                          className={
                            key === "locationName"
                              ? fieldClassesFull
                              : fieldClasses
                          }
                          handleBlur={validateInputs}
                          handleChange={handleChange}
                          handleFocus={handleFocus}
                          isInvalid={
                            invalid.includes(key) &&
                            this.props[key].length === 0
                          }
                          isRequired={!optionalFields.includes(key)}
                          key={key}
                          label={t(label)}
                          name={key}
                          quidBase="store-locator-address-field"
                          t={t}
                          value={this.props[key]}
                        />
                      )
                    )}
                  </Fragment>
                )}
                <Input
                  className="grid__cell--1"
                  handleChange={handleChange}
                  label={t(ADDRESS_FIELDS[ADDRESS.DELIVERY_INSTRUCTIONS].label)}
                  name="deliveryInstructions"
                  quidBase="store-locator-address-field"
                  value={deliveryInstructions}
                  maxLength={DELIVERY_INSTRUCTIONS_MAX_LENGTH}
                  t={t}
                />

                <p className="grid__cell--1">
                  <RequiredMark /> {t("shared:forms.indicates_required")}
                </p>
                <div className="grid__cell--1">
                  <div className="grid form-controls">
                    <button
                      className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                      data-icon="clear"
                      data-quid="store-locator-clear"
                      onClick={this.handleClear}
                      type="button"
                    >
                      {t("shared:action.clear_form")}
                    </button>
                    {isDefaultLocatorStrategy && (
                      <button
                        className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                        data-icon="pin_drop"
                        data-quid="store-locator-search"
                        onClick={this.handleSearch}
                        type="button"
                      >
                        {t("shared:action.drop_pin")}
                      </button>
                    )}
                    {streetSelected && (
                      <button
                        className="btn grid__cell--1/2 grid__cell--1/4@widescreen"
                        data-icon="edit"
                        data-quid="store-locator-edit"
                        onClick={this.resetStreetSearch}
                        type="button"
                      >
                        {t("customer:locations.edit")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case SERVICE_METHOD.CARRYOUT:
      default:
        return (
          <GoogleMapsTypeAhead
            className="grid__cell--1"
            input={address}
            label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
            maps={maps}
            name="address"
            onChange={({ address: addressValue, placeId }) =>
              handleChange({
                address: addressValue,
                placeId,
              })
            }
            options={{
              componentRestrictions: {
                country: countryCodeList,
              },
              types: ["geocode"],
            }}
            quidBase="store-locator-address"
          />
        );
    }
  }
}

KE.propTypes = {
  address: PropTypes.string,
  addressType: PropTypes.string,
  countryCode: PropTypes.string,
  city: PropTypes.string,
  regions: PropTypes.object,
  getPlaces: PropTypes.func.isRequired,
  getStreets: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  deliveryInstructions: PropTypes.string,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  marketConfigs: PropTypes.shape({
    DELIVERY_INSTRUCTIONS_MAX_LENGTH: PropTypes.number,
    REGION_MAPPER: PropTypes.object,
  }).isRequired,
  neighborhood: PropTypes.string,
  onError: PropTypes.func,
  postalCode: PropTypes.string,
  serviceMethod: PropTypes.oneOf(["Carryout", "Delivery", "Dine In"]),
  setUsesPlaces: PropTypes.func.isRequired,
  unitInfo: PropTypes.string,
  locatorStrategy: PropTypes.string.isRequired,
  setLocatorStrategy: PropTypes.func.isRequired,
  setRegionAsCity: PropTypes.func.isRequired,
  streetName: PropTypes.string,
  streets: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      placeId: PropTypes.string,
    })
  ).isRequired,
};

KE.defaultProps = {
  address: "",
  addressType: ADDRESS.LANDED,
  buildingName: "",
  city: "",
  countryCode: "",
  deliveryInstructions: "",
  neighborhood: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    process.env.NODE_ENV !== "production" && console.error(error);
  },
  postalCode: "",
  serviceMethod: "Carryout",
  streetName: "",
  unitInfo: "",
};

export default KE;
