import { useEffect } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getNewExperienceFeatureOptions } from "ducks/market/selectors";
import {
  updateOrder,
  updateOrderFutureTime,
  updateOrderTiming,
} from "ducks/order";
import {
  getCurrentOrder,
  getCurrentOrderId,
  getCurrentOrderTiming,
  getCurrentServiceMethod,
} from "ducks/order/selectors";
import {
  getCurrentStoreIsOpen,
  getCurrentStoreUTCOffset,
  getServiceHours,
} from "ducks/store/selectors";
import getLinkFromRelationship from "selectors/related";

import {
  selectDate,
  selectShowFutureTimeError,
  selectTime,
  selectTimeList,
  setDate,
  setShowFutureTimeError,
  setTime,
  setTimeList,
} from "rtk_redux/slices/futureTimedOrderSlice";

import { PROFILE } from "constants/customer";
import { ORDER_TIMING } from "constants/order";
import {
  buildTimeList,
  getDay,
  showValidTimes,
  toISOLocal,
} from "modules/futureTime";
import kebabCase from "modules/kebabCase";

import DatePicker from "components/DatePicker";

const SERVICE_HOURS_MAP = {
  PickupWindow: "Carryout",
  Carside: "DriveUpCarryout",
};

const AVAILABLE_ORDER_TIMINGS = [
  {
    optionType: ORDER_TIMING.NOW,
    title: "shared:order_timing.now",
  },
  {
    optionType: ORDER_TIMING.LATER,
    title: "shared:order_timing.later",
  },
];

const FutureTimedOrderSelection = ({ dateFormat, t = () => {} }) => {
  const dispatch = useDispatch();
  const selectedDate = useSelector(selectDate);
  const selectedTime = useSelector(selectTime);
  const timeList = useSelector(selectTimeList);
  const storeUTCOffset = useSelector(getCurrentStoreUTCOffset);

  const { allowFutureOrderTimeMinutesFromNow } = useSelector(
    getNewExperienceFeatureOptions
  );

  const currentOrderId = useSelector(getCurrentOrderId);
  const serviceHours = useSelector(getServiceHours);
  const isStoreOpen = useSelector(getCurrentStoreIsOpen);
  const currentServiceMethod = useSelector(getCurrentServiceMethod);
  const currentOrderTiming = useSelector(getCurrentOrderTiming);
  const currentOrder = useSelector(getCurrentOrder);
  const showFutureTimeError = useSelector(selectShowFutureTimeError);
  const futureTimeLink = getLinkFromRelationship("futureTime")(currentOrder);

  const updateFutureTime = () => {
    if (!(selectedTime && selectedDate)) return;

    const [hours, minutes] = selectedTime.split(":");
    const newDate = new Date(selectedDate.getTime());

    hours && newDate.setHours(parseInt(hours, 10));
    minutes && newDate.setMinutes(parseInt(minutes, 10));

    const futureTime = toISOLocal(newDate);
    dispatch(
      updateOrderFutureTime({
        orderId: currentOrderId,
        futureTime,
        url: futureTimeLink,
      })
    );
  };

  const updateTimeList = () => {
    const day = getDay(selectedDate.getDay());
    const serviceMethodKey =
      SERVICE_HOURS_MAP[currentServiceMethod] ?? currentServiceMethod;

    const newTimeList = buildTimeList(
      serviceHours?.[serviceMethodKey]?.[day] ?? []
    ).filter(
      showValidTimes({
        date: selectedDate,
        allowFutureOrderTimeMinutesFromNow,
        storeUTCOffset,
      })
    );

    dispatch(updateOrder({ timeList: newTimeList }));
    dispatch(setTimeList(newTimeList));
  };

  const handleDateChange = (date) => {
    dispatch(
      updateOrder({
        orderId: currentOrderId,
        date,
      })
    );

    updateTimeList();
    dispatch(setDate(date));
  };

  const handleTimeChange = ({ target: { value: time } }) => {
    dispatch(updateOrder({ orderId: currentOrderId, time }));
    dispatch(setShowFutureTimeError(false));
    dispatch(setTime(time));
  };

  const setOrderTiming = (orderTiming) => {
    dispatch(
      updateOrderTiming({
        currentOrderTiming,
        orderId: currentOrderId,
        orderTiming,
        url: futureTimeLink,
      })
    );
  };

  const getOrderTimingDisabled = (optionType) =>
    !isStoreOpen && optionType === ORDER_TIMING.NOW;

  useEffect(() => {
    isStoreOpen
      ? setOrderTiming(ORDER_TIMING.NOW)
      : setOrderTiming(ORDER_TIMING.LATER);
  }, [isStoreOpen]);

  useEffect(() => {
    updateFutureTime();
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    updateTimeList();
  }, [selectedDate, serviceHours, currentServiceMethod]);

  return (
    <div className="form__control-group button-field text--center">
      <label
        className="button-field__label"
        data-quid={`order-timing-label`}
        htmlFor={PROFILE.ORDER_TIMING}
      >
        {t("customer:profile.order_timing.title")}
      </label>
      <div className="btn-group" id={PROFILE.ORDER_TIMING}>
        {AVAILABLE_ORDER_TIMINGS.map(({ optionType, title }) => (
          <button
            className={classNames(
              "btn",
              currentOrderTiming === optionType && "btn--primary"
            )}
            data-quid={`order-timing-${kebabCase(optionType)}`}
            key={title}
            onClick={() => setOrderTiming(optionType)}
            type="button"
            disabled={getOrderTimingDisabled(optionType)}
          >
            {t(title)}
          </button>
        ))}
      </div>

      {currentOrderTiming === ORDER_TIMING.LATER && (
        <div>
          <div className="grid__cell--1 grid__cell--1/2@desktop">
            <DatePicker
              dateFormat={dateFormat}
              defaultDate={selectedDate}
              onSelect={handleDateChange}
              label={t("shared:select_day")}
              quidBase="future-order-date"
            />
          </div>

          <div
            className="grid__cell--1 grid__cell--1/2@desktop"
            data-quid="future-order-time"
          >
            <label data-quid="future-order-time-label" htmlFor="time-select">
              {t("shared:select_time")}
            </label>

            <select
              id="time-select"
              name="time-select"
              data-quid="future-order-time-select"
              className={classNames(
                "text-field__input text-field--time__period",
                showFutureTimeError && "error parsley-error"
              )}
              onChange={handleTimeChange}
              value={selectedTime}
            >
              <option value="" disabled />

              {
                //TODO: localize date format for market
              }

              {timeList &&
                timeList.map(({ formatted, original }) => (
                  <option
                    data-quid={`future-order-time-${kebabCase(formatted)}`}
                    key={formatted}
                    value={original}
                  >
                    {formatted}
                  </option>
                ))}
            </select>

            {showFutureTimeError && (
              <p className="error-message">
                {t("customer:profile:order_timing:error")}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

FutureTimedOrderSelection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  t: PropTypes.func,
};

export default FutureTimedOrderSelection;
