export const FOOD_CATEGORY = "Food";
export const COUPON_CATEGORY = "Coupons";
export const PASTA_CATEGORY = "Pasta";
export const PIZZA_CATEGORY = "Pizza";
export const SANDWICH_CATEGORY = "Sandwich";

export const STJUDE_PRODUCT_CODE = "F_STJUDE";
export const STJUDE_ROUND_UP_SIZE_CODE = "STJUDERU";
export const STJUDE_CATEGORY_ID = "Sides";

export const VARIANT_OVERRIDES = {
  STJUDE: STJUDE_PRODUCT_CODE,
};

export default FOOD_CATEGORY;
