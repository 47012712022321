import {
  setFormattedOrderHistory,
  setOrderHistory,
} from "rtk_redux/slices/orderHistorySlice";

import { SERVICE_METHOD } from "constants/order";
import { PART_NAMES, WEIGHT } from "constants/topping";
import exists from "modules/exists";

export const orderHistoryMiddleware = (middleware) => {
  middleware.startListening({
    actionCreator: setOrderHistory,
    effect: ({ payload }, { getState, dispatch }) => {
      const state = getState();
      const { topping: toppingList } = state;
      const getTopping = (code) =>
        Object.values(toppingList).find(
          ({ toppingCode }) => toppingCode === code
        );

      const parseAndFormatParts = ([part, { options }]) => {
        const getToppings = ({ code, weight }) => {
          return {
            toppingName: getTopping(code)?.toppingName || "",
            weight: WEIGHT[weight].name,
          };
        };

        return {
          part: PART_NAMES[part],
          toppings: options.map(getToppings),
        };
      };

      const parseAndFormatProduct = ({
        base,
        parts = {},
        sizeCode,
        ...props
      }) => {
        const { baseName } = state.base[base] || {};
        const { sizeName } = state.size[sizeCode] || {};

        if (!Object.entries(parts).length) {
          return {
            productName: "",
            options: [],
            ...props,
          };
        }

        // If all the parts have the same name, we know all the parts belong to the same product
        // And only need that single product name
        const [{ name: firstPartName }, ...productParts] = Object.values(parts);
        const isSameProduct = productParts.every(
          ({ name }) => name === firstPartName
        );

        // If any of the parts has customization, we will have to list the toppings
        const hasCustomization = Object.values(parts).some(
          ({ options = [] }) => options.length
        );

        // e.g. Large Hand-Tossed Pacific Veggie
        const getProductName = (name) =>
          [sizeName, baseName, name].filter(exists).join(" ");

        const productName = isSameProduct ? getProductName(firstPartName) : "";
        const options =
          isSameProduct && !hasCustomization
            ? []
            : Object.entries(parts).map(parseAndFormatParts);
        return {
          productName,
          options,
          ...props,
        };
      };

      const formattedPayload = payload.map(({ id, attributes, links }) => {
        const { serviceMethod, products, coupons, ...props } = attributes;
        return {
          id: id,
          serviceMethod: SERVICE_METHOD[serviceMethod],
          products: products.map((product) => parseAndFormatProduct(product)),
          orderCoupons: coupons.map((cpn) => `${cpn.name} ${cpn.price}`),
          reorderLink: links.self.href,
          ...props,
        };
      });

      dispatch(setFormattedOrderHistory(formattedPayload));
    },
  });
};
