import { translate } from "react-i18next";
import { connect } from "react-redux";

import {
  couponsRoute,
  customerRoute,
  everythingElseRoute,
  exitRoute,
  finishRoute,
  fulfillerRoute,
  homeRoute,
  pizzasRoute,
} from "routes";

import { getAgentId } from "ducks/agent/selectors";
import { setLanguage } from "ducks/configuration";
import { lookupCustomer } from "ducks/customer";
import {
  getCustomer,
  getCustomerFormattedPhone,
  getCustomerName,
  getIsMinimumCustomerDataComplete,
} from "ducks/customer/selectors";
import {
  getMarket,
  getMarketCode,
  getMarketConfigs,
  getMarketProfileFields,
  getNewExperienceFeature,
} from "ducks/market/selectors";
import { getCoupons } from "ducks/menu/selectors";
import { clearMessage } from "ducks/message";
import {
  getOverlayMessages,
  getToasterMessages,
} from "ducks/message/selectors";
import { priceValidateOrder, startOrder } from "ducks/order";
import {
  getActiveOrderProduct,
  getCurrentAddressLine1,
  getCurrentAddressLine2,
  getCurrentDeliveryInstructions,
  getCurrentOrder,
  getCurrentOrderCoupons,
  getCurrentOrderFutureTime,
  getCurrentOrderId,
  getCurrentOrderProducts,
  getCurrentOrderSavings,
  getCurrentOrderTotal,
  getCurrentServiceMethod,
  getCurrentStreetAddress,
  getOrderStarted,
  getValidOrderTiming,
} from "ducks/order/selectors";
import { deleteOrderCoupon } from "ducks/orderCoupon";
import {
  deleteOrderProduct,
  resetActiveOrderProduct,
  setActiveOrderProduct,
  updateOrderProduct,
} from "ducks/orderProduct";
import { getReceiptSummary } from "ducks/orderProduct/selectors";
import { clearSession } from "ducks/session";
import {
  getCurrentStoreId,
  getCurrentStoreName,
  getCurrentStorePhone,
  getCurrentStoreRegion,
  getCurrentStoreStreet,
} from "ducks/store/selectors";
import {
  getLocationType,
  getPayloadSection,
  getQueryPhone,
  getQueryPrefix,
} from "selectors/location";
import { getLinkFromRelationship, getSelfLink } from "selectors/related";

import { PROFILE } from "constants/customer";
import doValidation from "modules/doValidation";

import App from "components/App";

const mapStateToProps = ({ root, ...state }) => ({
  agentId: getAgentId(state),
  activeOrderProduct: getActiveOrderProduct(state),
  addressLine1: getCurrentAddressLine1(state),
  streetAddress: getCurrentStreetAddress(state),
  addressLine2: getCurrentAddressLine2(state),
  deliveryInstructions: getCurrentDeliveryInstructions(state),
  coupons: getCoupons(state),
  currentOrder: getCurrentOrder(state),
  currentOrderCoupons: getCurrentOrderCoupons(state),
  currentOrderFutureTime: getCurrentOrderFutureTime(state),
  currentOrderId: getCurrentOrderId(state),
  currentOrderProducts: getCurrentOrderProducts(state),
  currentOrderSavings: getCurrentOrderSavings(state),
  currentOrderTotal: getCurrentOrderTotal(state),
  currentServiceMethod: getCurrentServiceMethod(state),
  customersLink: getLinkFromRelationship("customers")(root),
  customerName: getCustomerName(state),
  customerPhone: getCustomerFormattedPhone(state),
  initialPhone: getQueryPhone(state),
  initialPrefix: getQueryPrefix(state),
  isMinimumCustomerDataComplete: getIsMinimumCustomerDataComplete(
    getCustomer(state)
  )(state),
  market: getMarket(state),
  marketCode: getMarketCode(state),
  marketConfigs: getMarketConfigs(state),
  orderStarted: getOrderStarted(state),
  overlayMessages: getOverlayMessages(state),
  profileFields: getMarketProfileFields(state),
  receiptSummary: getReceiptSummary(state),
  storeId: getCurrentStoreId(state),
  storeName: getCurrentStoreName(state),
  storePhone: getCurrentStorePhone(state),
  storeRegion: getCurrentStoreRegion(state),
  storeStreet: getCurrentStoreStreet(state),
  subTab: getPayloadSection(state),
  tab: getLocationType(state),
  toasterMessages: getToasterMessages(state),
  validOrderTiming: getValidOrderTiming(state),
  newExperienceFeatures: getNewExperienceFeature(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearMessage: (id) => dispatch(clearMessage(id)),
  clearSession: () => dispatch(clearSession()),
  deleteOrderCoupon: (coupon) => dispatch(deleteOrderCoupon(coupon)),
  deleteOrderProduct: (orderProduct) =>
    dispatch(deleteOrderProduct(orderProduct)),
  goToCoupons: () => dispatch(couponsRoute()),
  goToCustomer: () => dispatch(customerRoute()),
  goToEverythingElse: (category) => dispatch(everythingElseRoute(category)),
  goToExit: () => dispatch(exitRoute()),
  goToFinish: () => dispatch(finishRoute()),
  goToFulfiller: (couponId) => dispatch(fulfillerRoute(couponId)),
  goToHome: () => dispatch(homeRoute()),
  goToPizzas: (category) => dispatch(pizzasRoute(category)),
  lookupCustomer: (customer) => dispatch(lookupCustomer(customer)),
  priceValidateOrder: (order) => dispatch(priceValidateOrder(order)),
  resetActiveOrderProduct: () => dispatch(resetActiveOrderProduct()),
  setActiveOrderProduct: (orderProduct) =>
    dispatch(setActiveOrderProduct(orderProduct)),
  setLanguage: (language) => {
    dispatch(setLanguage({ language }));
  },
  startOrder: (order) => dispatch(startOrder(order)),
  updateOrderProduct: (orderProduct) =>
    dispatch(updateOrderProduct(orderProduct)),
});

const mergeProps = (
  {
    activeOrderProduct,
    currentOrder,
    currentOrderCoupons,
    currentOrderFutureTime,
    currentOrderId,
    currentOrderProducts,
    customersLink,
    initialPhone,
    initialPrefix,
    isMinimumCustomerDataComplete,
    marketCode,
    marketConfigs,
    profileFields,
    ...state
  },
  {
    deleteOrderCoupon: dispatchDeleteCoupon,
    deleteOrderProduct: dispatchDeleteOrderProduct,
    lookupCustomer: dispatchLookupCustomer,
    priceValidateOrder: dispatchPriceValidateOrder,
    startOrder: dispatchStartOrder,
    updateOrderProduct: dispatchUpdateOrderProduct,
    setLanguage: dispatchSetLanguage,
    ...dispatch
  },
  props
) => {
  const priceValidateOrderLink =
    getLinkFromRelationship("priceOrder")(currentOrder);
  const { FORCE_CUSTOMER_REQUIRED_FIELDS } = marketConfigs;
  const { orderProductId } = activeOrderProduct;
  const activeOrderProductLink = getSelfLink(activeOrderProduct);
  const isNavigationDisabled =
    FORCE_CUSTOMER_REQUIRED_FIELDS && !isMinimumCustomerDataComplete;
  const initialPhoneNumber =
    initialPhone && initialPrefix
      ? `+${initialPrefix}${initialPhone}`
      : initialPhone;

  return Object.assign(
    {
      currentOrderCoupons,
      currentOrderProducts,
      marketCode,
      marketConfigs,
      isNavigationDisabled,
      deleteOrderCoupon: (orderCouponId) =>
        dispatchDeleteCoupon({
          orderCouponId,
          orderId: currentOrderId,
          url: getSelfLink(currentOrderCoupons[orderCouponId]),
        }),
      deleteOrderProduct: () =>
        dispatchDeleteOrderProduct({
          orderId: currentOrderId,
          orderProductId,
          url: getSelfLink(currentOrderProducts[orderProductId]),
        }),
      priceValidateOrder: () =>
        dispatchPriceValidateOrder({
          orderId: currentOrderId,
          url: priceValidateOrderLink,
        }),
      profileFields,
      setLanguage: (language) => dispatchSetLanguage(language),
      setOrderProduct: (orderProduct) =>
        dispatchUpdateOrderProduct(
          Object.assign({}, activeOrderProduct, orderProduct, {
            url: activeOrderProductLink,
          })
        ),
      startOrder: () =>
        !currentOrderId &&
        initialPhone &&
        doValidation(
          initialPhoneNumber,
          profileFields[PROFILE.PHONE].type,
          marketCode
        ) &&
        dispatchLookupCustomer({
          phone: initialPhone,
          prefix: initialPrefix,
          url: customersLink,
        }),
    },
    state,
    dispatch,
    props
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(translate(["application", "shared"])(App));
