import React, { PureComponent } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { homeRoute } from "routes";

import {
  getLanguages,
  getLanguagesSet,
  getMarketConfigured,
  getRootConfigured,
} from "ducks/configuration/selectors";
import { clearSession, setSession } from "ducks/session";
import { getLogout, getToken } from "ducks/session/selectors";

import { parseParams } from "modules/ajax";
import { getLoginUrl, getLogoutUrl, getParamFromHash } from "modules/session";

import Loader from "components/Loader";

const mapStateToProps = (state) => ({
  authToken: getToken(state),
  languages: getLanguages(state),
  languagesSet: getLanguagesSet(state),
  logout: getLogout(state),
  market: getMarketConfigured(state),
  root: getRootConfigured(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearSession: () => dispatch(clearSession()),
  goToHome: (path) => dispatch(homeRoute(path)),
  setSession: (token) => dispatch(setSession(token)),
});

const mergeProps = (
  { languagesSet, market, root, ...state },
  dispatch,
  props
) =>
  Object.assign(
    {
      initialized: languagesSet && market && root,
    },
    state,
    dispatch,
    props
  );

const HASH = Object.freeze({
  STATE: "state",
  TOKEN: "access_token",
  ERROR: "error",
});

export default function AuthContainer(Configuration) {
  class Auth extends PureComponent {
    static doLogoutRedirect() {
      window.location = getLogoutUrl();
    }

    static doLoginRedirect() {
      const { search } = window.location;

      window.location = getLoginUrl({
        state: btoa(search),
      });
    }

    static getState() {
      const { hash } = window.location;
      const encodedState = getParamFromHash(hash, HASH.STATE);
      const decodedState = atob(encodedState);

      return parseParams(decodedState);
    }

    static getToken() {
      const { hash } = window.location;
      const token = getParamFromHash(hash, HASH.TOKEN);

      return token;
    }

    constructor(props) {
      super(props);

      this.state = {
        newToken: this.constructor.getToken(),
        query: this.constructor.getState(),
      };

      this.setToken = this.setToken.bind(this);
      this.removeHash = this.removeHash.bind(this);
    }

    componentDidMount() {
      const { newToken, query } = this.state;
      newToken && this.setToken(newToken);

      this.removeHash(query);
    }

    setToken(token) {
      this.props.setSession({ token });
    }

    removeHash(query) {
      if (Object.keys(query).length > 0) this.props.goToHome(query);
      else {
        const { pathname, search } = window.location;
        window.history.replaceState("", pathname, pathname + search);
      }
    }

    render() {
      const { doLoginRedirect, doLogoutRedirect } = this.constructor;
      const { authToken, logout, initialized } = this.props;
      const { newToken } = this.state;

      if (logout) doLogoutRedirect();
      else if (authToken)
        return <Configuration hasBeenInitialized={initialized} />;
      else if (!newToken) doLoginRedirect();

      return <Loader />;
    }
  }

  Auth.propTypes = {
    authToken: PropTypes.string,
    goToHome: PropTypes.func.isRequired,
    initialized: PropTypes.bool,
    logout: PropTypes.bool,
    setSession: PropTypes.func.isRequired,
  };

  Auth.defaultProps = {
    authToken: "",
    initialized: false,
    logout: false,
  };

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Auth);
}
