export const WEIGHT = Object.freeze({
  0: { value: 0, name: "food:toppings:no" },
  0.5: { value: 0.5, name: "food:toppings:light" },
  1: { value: 1, name: "food:toppings:normal" },
  1.5: { value: 1.5, name: "food:toppings:extra" },
  2: { value: 2, name: "food:toppings:double" },
  3: { value: 3, name: "food:toppings:triple" },
});

export const defaultWeight = WEIGHT[1].value;

export const WEIGHTS = {
  ZERO: 0,
  LIGHT: 0.5,
  NORMAL: 1,
  EXTRA: 1.5,
};

export const ROBUST_INSPIRED_TOMATO_SAUCE_CODE = "X";

export default WEIGHT;

export const PART_NAMES = Object.freeze({
  "1/1": "food:whole",
  "1/2": "food:left",
  "2/2": "food:right",
});
