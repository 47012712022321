import React from "react";

import PropTypes from "prop-types";

import QuantityButtons from "components/QuantityButtons";

const UpsellItem = ({
  decrementItemCount,
  increaseItemCount,
  maximum,
  minimum,
  productName,
  quantity,
  setItemCount,
}) => (
  <div className="waterfall-upsell__item">
    <p className="product-title">{productName}</p>
    <div className="actions">
      <QuantityButtons
        decrement={decrementItemCount}
        increment={increaseItemCount}
        maximum={maximum}
        minimum={minimum}
        quantity={quantity}
        set={setItemCount}
        update={setItemCount}
      />
    </div>
  </div>
);

UpsellItem.propTypes = {
  decrementItemCount: PropTypes.func.isRequired,
  increaseItemCount: PropTypes.func.isRequired,
  maximum: PropTypes.number,
  minimum: PropTypes.number,
  productName: PropTypes.string,
  quantity: PropTypes.number,
  setItemCount: PropTypes.func.isRequired,
};

UpsellItem.defaultProps = {
  maximum: 25,
  minimum: 0,
  productName: "",
  quantity: 0,
};

export default UpsellItem;
