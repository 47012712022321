/** @jsxImportSource @emotion/react */
import { Fragment } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { ROUTE_TITLES } from "routes";

import { getNewExperienceFeatureOptions } from "ducks/market/selectors";
import { getPizzaCategories } from "ducks/menu/selectors";
import {
  getActiveOrderProduct,
  getActiveOrderProductAvailableBasesBySize,
  getActiveOrderProductAvailableSizesByBase,
  getCurrentOrder,
} from "ducks/order/selectors";
import { createOrderProduct, updateOrderProduct } from "ducks/orderProduct";
import { resetActiveOrderProduct } from "ducks/orderProduct";
import { getLinkFromRelationship, getSelfLink } from "selectors/related";

import { defaultArea } from "constants/order";
import hasProps from "modules/hasProps";
import uuid from "modules/uuid";

import CookingInstructions from "components/CookingInstructions";
import Page from "components/Page";
import NewPizzaBuilder from "components/Pizzas/NewPizzaBuilder";
import Product from "components/Product";
import Tabs from "components/Tabs";
import ToppingOption from "components/ToppingOption";

import { nonMeatsWrapperStyles, spaceBetweenY } from "./NewPizzas.styles";
import { sectionMarginBottom } from "./Pizzas.styles";

const splitCheeseAndOtherToppings = ({ toppings }) => {
  const cheeseIndex = toppings.findIndex((topping) => topping.isCheese);

  if (cheeseIndex !== -1) {
    const [cheese] = toppings.splice(cheeseIndex, 1);
    return [cheese, toppings];
  }

  return [null, toppings];
};

const getIsDefaultTopping = ({ activeOrderProductDefaultOptions, toppingId }) =>
  activeOrderProductDefaultOptions.some(
    (option) => option.optionId === toppingId
  );

const alphaSort = (key) => (a, b) => a[key].localeCompare(b[key]);
const alphaToppingSort = alphaSort("toppingName");
const alphaProductSort = alphaSort("productName");

const NewPizzas = ({
  activeArea = defaultArea,
  activeOrderProductDefaultOptions = [],
  activeOrderProductAvailableOptions = {},
  activeOrderProductBaseId = "",
  activeOrderProductOptionIds = [],
  activeOrderProductProducts = {},
  activeOrderProductSizeId = "",
  activeOrderProductToppings = {},
  bases = {},
  meats = [],
  nonMeats = [],
  sauces = [],
  sidebar = null,
  sizes = {},
  orderId,
  currentParentCategory,
  currentCategoryId,
  deleteOrderProductOption,
  t = () => {},
  tabs = [
    {
      active: true,
      icon: "local_pizza",
      id: uuid(),
      path: "pizzas",
      text: "Pizzas",
    },
  ],
  goToPizzas,
  title = "",
}) => {
  const dispatch = useDispatch();

  const activeOrderProduct = useSelector(getActiveOrderProduct);
  const activeOrderProductAvailableSizesByBase = useSelector(
    getActiveOrderProductAvailableSizesByBase
  );
  const activeOrderProductAvailableBasesBySize = useSelector(
    getActiveOrderProductAvailableBasesBySize
  );

  const activeOrderProductSelected = hasProps(activeOrderProduct);

  const currentOrder = useSelector(getCurrentOrder);
  const categories = useSelector(getPizzaCategories);
  const { newPizzaBuilder } = useSelector(getNewExperienceFeatureOptions);
  const {
    DEFAULT_PIZZA_SIZEID,
    DEFAULT_PIZZA_BASEID,
    DEFAULT_SPECIALTY_CATEGORYID,
    DEFAULT_SPECIALTY_SIZEID,
  } = newPizzaBuilder;

  const {
    categoryId: activeOrderProductCategoryId,
    orderProductId: activeOrderProductId,
  } = activeOrderProduct;

  const { parentCategory: activeOrderProductParentCategory } =
    categories[activeOrderProductCategoryId] || {};

  const activeOrderProductLink = getSelfLink(activeOrderProduct);
  const productsLink = getLinkFromRelationship("products")(currentOrder);

  const setOrderProduct = (orderProduct) =>
    dispatch(
      activeOrderProductId &&
        activeOrderProductParentCategory === currentParentCategory
        ? updateOrderProduct({
            ...activeOrderProduct,
            categoryId: currentCategoryId,
            ...orderProduct,
            orderProductId: activeOrderProductId,
            url: activeOrderProductLink,
          })
        : createOrderProduct({
            baseId:
              orderProduct.sizeId === DEFAULT_PIZZA_SIZEID
                ? DEFAULT_PIZZA_BASEID
                : null,
            sizeId:
              orderProduct.baseId === DEFAULT_PIZZA_BASEID
                ? DEFAULT_PIZZA_SIZEID
                : null,
            categoryId: currentCategoryId,
            ...orderProduct,
            orderId,
            url: productsLink,
          })
    );

  const tabbar = <Tabs goTo={goToPizzas} t={t} tabs={tabs} />;

  const actions = (
    <button
      className="btn btn--fill btn--primary"
      data-icon="add"
      data-quid="add-pizza"
      disabled={!activeOrderProductSelected}
      onClick={() => dispatch(resetActiveOrderProduct())}
    >
      {t("shared:action.add_to_order")}
    </button>
  );

  const activeToppingProps = {
    activeOrderProductAvailableOptions,
    activeOrderProductOptionIds,
    activeOrderProductOptions: activeOrderProductToppings,
  };

  const activePizzaBuilderProps = {
    activeOrderProductBaseId,
    activeOrderProductSizeId,
    activeOrderProductAvailableSizesByBase,
    activeOrderProductAvailableBasesBySize,
    ...activeToppingProps,
  };
  const hasMeats = meats.length > 0;
  const hasNonMeats = nonMeats.length > 0;
  const hasToppings = hasMeats || hasNonMeats;

  const [cheese, otherNonMeats] = splitCheeseAndOtherToppings({
    toppings: nonMeats,
  });

  const sortedMeats = meats.sort(alphaToppingSort);
  const sortedOtherNonMeats = otherNonMeats.sort(alphaToppingSort);

  const leftOtherNonMeats = sortedOtherNonMeats.slice(
    0,
    Math.ceil(sortedOtherNonMeats.length / 2)
  );
  const rightOtherNonMeats = sortedOtherNonMeats.slice(
    Math.ceil(sortedOtherNonMeats.length / 2)
  );

  const specialtyPizzas =
    categories[DEFAULT_SPECIALTY_CATEGORYID]?.products?.sort(alphaProductSort);
  const activeOrderIsSpecialtyPizza =
    activeOrderProductCategoryId === DEFAULT_SPECIALTY_CATEGORYID;

  const currentTabIsSpecialty =
    currentCategoryId === DEFAULT_SPECIALTY_CATEGORYID;

  return (
    <Page
      footer={actions}
      className="text--right"
      id="pizzas"
      sidebar={sidebar}
      tabs={tabbar}
      title={title}
      seoTitle={ROUTE_TITLES.PIZZAS}
    >
      {currentTabIsSpecialty && (
        <div css={sectionMarginBottom} className="products columns shared">
          <div className="subsection">
            <div
              className="btn-group btn-group--justified btn-group--unlimited toggleable"
              data-quid="products"
            >
              {specialtyPizzas.map((product) => (
                <Product
                  categoryId={DEFAULT_SPECIALTY_CATEGORYID}
                  isSelected={Object.values(activeOrderProductProducts).some(
                    (activeOrderProductId) =>
                      activeOrderProductId === product.productId
                  )}
                  key={product.productId}
                  setOrderProduct={setOrderProduct}
                  addSize={true}
                  {...product}
                  sizeIds={[DEFAULT_SPECIALTY_SIZEID]}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <NewPizzaBuilder
        activeArea={activeArea}
        bases={bases}
        deleteOrderProductOption={deleteOrderProductOption}
        sauces={sauces}
        cheese={cheese}
        activeOrderProductDefaultOptions={activeOrderProductDefaultOptions}
        activeOrderIsSpecialtyPizza={activeOrderIsSpecialtyPizza}
        activeOrderProductSelected={activeOrderProductSelected}
        currentTabIsSpecialty={currentTabIsSpecialty}
        setOrderProduct={setOrderProduct}
        sizes={sizes}
        t={t}
        {...activePizzaBuilderProps}
      />

      <br />

      <div className="products">
        {hasToppings && (
          <Fragment>
            <br />

            <header className="title-group">
              <h2 data-quid="heading-toppings">{t("food:toppings.title")}</h2>
            </header>

            <div className="columns shared" data-quid="toppings">
              {hasMeats && (
                <div className="subsection">
                  <header className="title-group">
                    <h3 data-quid="heading-meats">
                      {t("food:toppings.meats")}
                    </h3>
                  </header>

                  <div css={spaceBetweenY} data-quid="meats">
                    {sortedMeats.map(
                      ({
                        toppingId,
                        toppingCode,
                        toppingName,
                        toppingDescription,
                      }) => (
                        <ToppingOption
                          key={toppingId}
                          optionId={toppingId}
                          optionCode={toppingCode}
                          optionName={toppingName}
                          optionDescription={toppingDescription}
                          activeOrderProductOptions={activeOrderProductToppings}
                          deleteOrderProductOption={deleteOrderProductOption}
                          isDefaultTopping={getIsDefaultTopping({
                            activeOrderProductDefaultOptions,
                            toppingId,
                          })}
                          t={t}
                        />
                      )
                    )}
                  </div>
                </div>
              )}

              <br />

              {hasNonMeats && (
                <div css={nonMeatsWrapperStyles}>
                  <header className="title-group">
                    <h3 data-quid="heading-non-meats">
                      {t("food:toppings.non_meats")}
                    </h3>
                  </header>
                  <div className="columns shared">
                    <div className="subsection">
                      <div css={spaceBetweenY} data-quid="non-meats">
                        {leftOtherNonMeats.map(
                          ({
                            toppingId,
                            toppingCode,
                            toppingName,
                            toppingDescription,
                          }) => (
                            <ToppingOption
                              key={toppingId}
                              optionId={toppingId}
                              optionCode={toppingCode}
                              optionName={toppingName}
                              optionDescription={toppingDescription}
                              activeOrderProductOptions={
                                activeOrderProductToppings
                              }
                              deleteOrderProductOption={
                                deleteOrderProductOption
                              }
                              isDefaultTopping={getIsDefaultTopping({
                                activeOrderProductDefaultOptions,
                                toppingId,
                              })}
                              t={t}
                            />
                          )
                        )}
                      </div>
                    </div>

                    <div className="subsection">
                      <div css={spaceBetweenY} data-quid="non-meats">
                        {rightOtherNonMeats.map(
                          ({
                            toppingId,
                            toppingCode,
                            toppingName,
                            toppingDescription,
                          }) => (
                            <ToppingOption
                              key={toppingId}
                              optionId={toppingId}
                              optionCode={toppingCode}
                              optionName={toppingName}
                              optionDescription={toppingDescription}
                              activeOrderProductOptions={
                                activeOrderProductToppings
                              }
                              deleteOrderProductOption={
                                deleteOrderProductOption
                              }
                              isDefaultTopping={getIsDefaultTopping({
                                activeOrderProductDefaultOptions,
                                toppingId,
                              })}
                              t={t}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <br />
            <CookingInstructions />
          </Fragment>
        )}
      </div>
    </Page>
  );
};

NewPizzas.propTypes = {
  activeArea: PropTypes.string,
  activeOrderProductDefaultOptions: PropTypes.arrayOf(PropTypes.object),
  activeOrderProductAvailableOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductBaseId: PropTypes.string,
  activeOrderProductOptionIds: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductProducts: PropTypes.objectOf(PropTypes.string),
  activeOrderProductSizeId: PropTypes.string,
  activeOrderProductToppings: PropTypes.objectOf(PropTypes.object),
  bases: PropTypes.arrayOf(PropTypes.object),
  createDefaultOrderProduct: PropTypes.func.isRequired,
  meats: PropTypes.arrayOf(PropTypes.object),
  nonMeats: PropTypes.arrayOf(PropTypes.object),
  deleteOrderProductOption: PropTypes.func.isRequired,
  sauces: PropTypes.arrayOf(PropTypes.object),
  currentCategoryId: PropTypes.string,
  orderId: PropTypes.string,
  currentParentCategory: PropTypes.string,
  setActiveArea: PropTypes.func.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  sidebar: PropTypes.node,
  sizes: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      icon: PropTypes.string,
      id: PropTypes.string,
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  goToPizzas: PropTypes.func.isRequired,
};

export default NewPizzas;
