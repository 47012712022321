import { LDProvider } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getMarketHeader } from "ducks/market/selectors";

import * as LD_FLAGS from "constants/launchDarkly";

// Limit LD flags for app, default to false
const flags = Object.values(LD_FLAGS).reduce(
  (all, key) => ({
    ...all,
    [key]: false,
  }),
  {}
);

const LDWrapper = ({ children }) => {
  const marketHeader = useSelector(getMarketHeader);

  const ldProps = {
    clientSideID: window.ENVCONFIG.launchDarklyClient,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    flags,
    context: {
      key: "CALL-CENTER-APP",
      custom: {
        market: marketHeader,
      },
    },
  };

  return <LDProvider {...ldProps}>{children}</LDProvider>;
};

LDWrapper.propTypes = {
  children: PropTypes.node,
};

export default LDWrapper;
