/** @jsxImportSource @emotion/react */
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from "react";

import {
  bool,
  element,
  func,
  number,
  object,
  oneOfType,
  shape,
} from "prop-types";

const Marker = ({ onClick, children, map, options }) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({ options, map });
    }
  }, [marker, options, map]);

  // add additional event handlers here
  useEffect(() => {
    if (marker) {
      ["click"].forEach((eventName) =>
        window.google.maps.event.clearListeners(marker, eventName)
      );

      // link passed in function on native event
      if (onClick) {
        marker.addListener("click", onClick);
      }
    }
  }, [marker, onClick]);

  return (
    <>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          return cloneElement(child, { anchor: marker, map });
        }
      })}
    </>
  );
};

export default Marker;

Marker.propTypes = {
  // If other event handlers are added, they should be assigned to Marker event listeners
  // [Complete list of marker events] {@link https://developers.google.com/maps/documentation/javascript/reference/marker#Marker-Constants}
  onClick: func,
  children: oneOfType([bool, element]), //i.e. InfoWindow
  map: object, // instance of map class {@link https://developers.google.com/maps/documentation/javascript/reference/map#Map}
  options: shape({
    // markerOptions interface {@link https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions-Properties}
    position: shape({
      // position required if InfoWindow is not a child of a Marker
      lat: number,
      lng: number,
    }),
  }),
};
