export const PAYMENT_TYPES = Object.freeze({
  CASH: "Cash",
  CREDIT_CARD: "CreditCard",
  SAVED_CREDIT_CARD: "SavedCreditCard",
  DOOR_CREIDT: "DoorCredit",
  DOOR_DEBIT: "DoorDebit",
});

export const AMEX_SECURITY_CODE_LENGTH = "4";

export const CC = Object.freeze({
  CARD_TYPE: "cardType",
  CARD_NUMBER: "cardNumber",
  EXPIRATION_MONTH: "expirationMonth",
  EXPIRATION_YEAR: "expirationYear",
  SECURITY_CODE: "securityCode",
  SAVED_SECURITY_CODE: "savedCCSecurityCode",
  TIP_AMOUNT: "tipAmount",
  ZIP_CODE: "zipCode",
});

export const CC_Fields = Object.freeze({
  [CC.CARD_NUMBER]: {
    key: "cardNumber",
    label: "finish:payment.card_number",
    type: "cardNumber",
    required: true,
    className: "grid__cell--1",
    maxLength: "19",
  },
  [CC.EXPIRATION_MONTH]: {
    key: "expirationMonth",
    label: "finish:payment.expiration_month",
    type: "expirationMonth",
    required: true,
    className: "grid__cell--1/2",
    placeHolder: "MM",
    maxLength: "2",
  },
  [CC.EXPIRATION_YEAR]: {
    key: "expirationYear",
    label: "finish:payment.expiration_year",
    type: "expirationYear",
    required: true,
    className: "grid__cell--1/2",
    placeHolder: "YY",
    maxLength: "2",
  },
  [CC.SECURITY_CODE]: {
    key: "securityCode",
    label: "finish:payment.security_code",
    type: "number",
    required: true,
    className: "grid__cell--1 grid__cell--1/2@desktop",
    maxLength: "3",
  },
  [CC.ZIP_CODE]: {
    key: "zipCode",
    label: "finish:payment.billing_zip_code",
    type: "number",
    required: true,
    className: "grid__cell--1 grid__cell--1/2@desktop",
    maxLength: "10",
  },
});

export const TIP_KEYS = Object.freeze({
  THIRTY: "thirty",
  TWENTY_FIVE: "twentyFive",
  TWENTY: "twenty",
  FIFTEEN: "fifteen",
  CUSTOM: "custom",
  NONE: "none",
});

export const TIP_AMOUNTS = Object.freeze({
  [TIP_KEYS.THIRTY]: 30,
  [TIP_KEYS.TWENTY_FIVE]: 25,
  [TIP_KEYS.TWENTY]: 20,
  [TIP_KEYS.FIFTEEN]: 15,
  [TIP_KEYS.NONE]: 0,
});

export const TIP_PERCENTAGES = Object.freeze([
  { amount: 30, key: TIP_KEYS.THIRTY },
  { amount: 25, key: TIP_KEYS.TWENTY_FIVE },
  { amount: 20, key: TIP_KEYS.TWENTY },
  { amount: 15, key: TIP_KEYS.FIFTEEN },
]);

export const CC_TYPES = Object.freeze({
  AMEX: "amex",
  DINERS: "diners",
  DISCOVER: "discover",
  JCB: "jcb",
  MASTERCARD: "mastercard",
  MAESTRO: "maestro",
  OPTIMA: "optima",
  VISA: "visa",
});

export const CC_TYPES_FULL_NAME = Object.freeze({
  [CC_TYPES.AMEX]: "American Express",
  [CC_TYPES.DINERS]: "Diner's Club",
  [CC_TYPES.DISCOVER]: "Discover Card",
  [CC_TYPES.JCB]: "JCB",
  [CC_TYPES.MASTERCARD]: "Mastercard",
  [CC_TYPES.MAESTRO]: "Maestro",
  [CC_TYPES.OPTIMA]: "Optima",
  [CC_TYPES.VISA]: "Visa",
});

export const CC_TYPES_STORE_TO_CUSTOMER_MAP = Object.freeze({
  "American Express": "AMEX",
  "Diner's Club": "DINERS",
  "Discover Card": "DISCOVER",
  JCB: "JCB",
  Mastercard: "MASTERCARD",
  Maestro: "MAESTRO",
  Optima: "OPTIMA",
  Visa: "VISA",
});

export const DELIVERY_INSTRUCTIONS_MAX_LENGTH = 128;

export const ACI = "ACI";
