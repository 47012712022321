const useLocalStorage = window.ENVCONFIG.oauth.params.client_id === "cca_us_ui";

export const getLoginUrl = (extraParams = {}) => {
  if (!window.ENVCONFIG) throw new Error("ENVCONFIG is not set");

  const { params, login } = window.ENVCONFIG.oauth;

  return `${login}?${Object.entries(params)
    .concat(Object.entries(extraParams))
    .map((param) => param.join("="))
    .join("&")}`;
};

export const getLogoutUrl = () => window.ENVCONFIG.oauth.logout;

export function getParamFromHash(hash, param) {
  const paramRegex = new RegExp(`[#&]${param}=([^&]+)`);
  const paramMatch = typeof hash === "string" && hash.match(paramRegex);

  return paramMatch ? decodeURIComponent(paramMatch[1]) : "";
}

export const setSessionInStorage = (token) => {
  useLocalStorage
    ? window.localStorage.setItem("auth_token", token)
    : window.sessionStorage.setItem("auth_token", token);
};

export const getSessionFromStorage = () =>
  useLocalStorage
    ? window.localStorage.getItem("auth_token")
    : window.sessionStorage.getItem("auth_token");

export const clearSessionFromStorage = () => {
  window.sessionStorage.clear();
  window.localStorage.clear();
};

export default getLoginUrl;
