import React, { Component } from "react";

import PropTypes from "prop-types";

import {
  RNC_ADD_NEW_NUMBER,
  RNC_FINAL_CONSUMER,
  RNC_GOVERNMENT,
  RNC_SPECIAL_REGIME,
  RNC_THIRD_PARTY,
  RNC_USE_SAVED_NUMBER,
} from "constants/rnc";

class Rnc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rncInput: this.props.rncNumber,
      rncInputError: false,
      rncNumberType:
        this.props.customerTaxCodes.length > 0
          ? RNC_USE_SAVED_NUMBER
          : RNC_ADD_NEW_NUMBER,
    };

    this.handleRncNumberInputBlur = this.handleRncNumberInputBlur.bind(this);
    this.handleRncNumberInputChange =
      this.handleRncNumberInputChange.bind(this);
    this.handleRncNumberSelectChange =
      this.handleRncNumberSelectChange.bind(this);
    this.handleRncNumberTypeChange = this.handleRncNumberTypeChange.bind(this);
    this.handleRncReceiptTypeChange =
      this.handleRncReceiptTypeChange.bind(this);
    this.rncNumberIsCorrectlyFormatted =
      this.rncNumberIsCorrectlyFormatted.bind(this);
  }

  handleRncNumberInputBlur() {
    const { rncInput } = this.state;
    const inputIsValid = this.rncNumberIsCorrectlyFormatted(rncInput);

    this.props.rncSetCompanyName("");
    this.props.rncSetNumber("");

    this.setState({ rncInputError: !inputIsValid });

    if (inputIsValid) this.props.rncLookupNumber(rncInput);
  }

  handleRncNumberInputChange({ target: { value } }) {
    this.setState({
      rncInput: value,
    });
  }

  handleRncNumberSelectChange({ target: { value } }) {
    const { customerTaxCodes, rncSetCompanyName, rncSetNumber } = this.props;
    const { number, companyName } = customerTaxCodes.find(
      ({ number: savedNumber }) => savedNumber === value
    );
    rncSetCompanyName(companyName);
    rncSetNumber(number);
  }

  handleRncNumberTypeChange({ target: { value } }) {
    this.props.rncSetCompanyName("");
    this.props.rncSetNumber("");
    this.setState({
      rncInput: "",
      rncNumberType: value,
    });
  }

  handleRncReceiptTypeChange({ target: { value } }) {
    this.props.rncSetReceiptType(value);
    this.setState({
      rncInput: "",
    });
  }

  //eslint-disable-next-line class-methods-use-this
  rncNumberIsCorrectlyFormatted(rncNumber) {
    return !/\D/g.test(rncNumber) && [9, 11].includes(rncNumber.length);
  }

  render() {
    const { customerTaxCodes, rncCompanyName, rncReceiptType, t } = this.props;

    const { rncInput, rncInputError, rncNumberType } = this.state;

    const {
      handleRncNumberInputBlur,
      handleRncNumberInputChange,
      handleRncNumberSelectChange,
      handleRncNumberTypeChange,
      handleRncReceiptTypeChange,
    } = this;

    const rncNumberIsRequired = [
      RNC_GOVERNMENT,
      RNC_SPECIAL_REGIME,
      RNC_THIRD_PARTY,
    ].includes(rncReceiptType);

    return (
      <div>
        <header className="title-group">
          <h2 className="title-group__section" data-quid="heading-rnc">
            {t("finish:rnc.title")}
          </h2>
        </header>
        <div className="form__control-group">
          <label data-quid="rnc-receipt-type-label" htmlFor="rnc-receipt-type">
            {t("finish:rnc.receipt_type")}
          </label>
          <select
            data-quid="rnc-receipt-type-select"
            id="rnc-receipt-type"
            name="rnc-receipt-type"
            onChange={handleRncReceiptTypeChange}
            value={rncReceiptType}
          >
            <option value="" disabled />
            <option data-quid="final-consumer" value={RNC_FINAL_CONSUMER}>
              {t("finish:rnc.final_consumer")}
            </option>
            <option data-quid="valid-for-fiscal-credit" value={RNC_THIRD_PARTY}>
              {t("finish:rnc.valid_for_fiscal_credit")}
            </option>
            <option data-quid="government" value={RNC_GOVERNMENT}>
              {t("finish:rnc.government")}
            </option>
            <option data-quid="special-regime" value={RNC_SPECIAL_REGIME}>
              {t("finish:rnc.special_regime")}
            </option>
          </select>
        </div>
        {rncNumberIsRequired && (
          <div>
            <div className="form__control-group">
              <label
                data-quid="rnc-number-type-label"
                htmlFor="rnc-number-type"
              >
                {t("finish:rnc.number_type")}
              </label>
              <select
                data-quid="rnc-number-type-select"
                id="rnc-number-type"
                name="rnc-number-type"
                onChange={handleRncNumberTypeChange}
                value={rncNumberType}
              >
                <option
                  data-quid="use-saved-number"
                  disabled={this.props.customerTaxCodes.length === 0}
                  value={RNC_USE_SAVED_NUMBER}
                >
                  {t("finish:rnc.use_saved_number")}
                </option>
                <option data-quid="add-new-number" value={RNC_ADD_NEW_NUMBER}>
                  {t("finish:rnc.add_new_number")}
                </option>
              </select>
            </div>

            {rncNumberType === RNC_USE_SAVED_NUMBER ? (
              <div className="form__control-group">
                <label
                  data-quid="rnc-number-picker-label"
                  htmlFor="rnc-number-picker"
                >
                  {t("finish:rnc.rnc_number")}
                </label>
                <select
                  data-quid="rnc-number-picker-select"
                  id="rnc-number-picker"
                  name="rnc-number-picker"
                  onChange={handleRncNumberSelectChange}
                  value={this.props.rncNumber}
                >
                  <option value="" disabled />
                  {customerTaxCodes.map(({ number }) => (
                    <option
                      data-quid={`saved-number-${number}`}
                      value={number}
                      key={number}
                    >
                      {number}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="form__control-group">
                <label data-quid="rnc-number-entry-label" htmlFor="rnc-number">
                  {t("finish:rnc.rnc_number")}
                </label>
                <input
                  className={rncInputError ? "parsley-error" : ""}
                  data-quid="rnc-number-entry-input"
                  id="rnc-number"
                  maxLength="11"
                  name="rnc-number"
                  onBlur={handleRncNumberInputBlur}
                  onChange={handleRncNumberInputChange}
                  placeholder={t("finish:rnc.rnc_number_placeholder")}
                  type="text"
                  value={rncInput}
                />
                {rncInputError && (
                  <p
                    className="error-message parsley-required"
                    data-quid="rnc-number-entry-error"
                  >
                    {t("finish:rnc.rnc_number_is_invalid")}
                  </p>
                )}
              </div>
            )}
            {rncCompanyName && (
              <div className="form__control-group">
                <label
                  data-quid="rnc-company-name-label"
                  htmlFor="rnc-company-name"
                >
                  {t("finish:rnc.company_name")}
                </label>
                <input
                  data-quid="rnc-company-name-input"
                  id="rnc-company-name"
                  name="rnc-company-name"
                  readOnly
                  type="text"
                  value={rncCompanyName}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

Rnc.propTypes = {
  customerTaxCodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  rncCompanyName: PropTypes.string,
  rncLookupNumber: PropTypes.func.isRequired,
  rncNumber: PropTypes.string,
  rncReceiptType: PropTypes.string,
  rncSetCompanyName: PropTypes.func.isRequired,
  rncSetNumber: PropTypes.func.isRequired,
  rncSetReceiptType: PropTypes.func.isRequired,
  t: PropTypes.func,
};

Rnc.defaultProps = {
  customerTaxCodes: [],
  rncCompanyName: "",
  rncNumber: "",
  rncReceiptType: "",
  sidebar: null,
  t: () => {},
};

export default Rnc;
