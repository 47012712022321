export const RESOURCE_TYPE = Object.freeze({
  ADDRESS: "addresses",
  COUPON: "coupons",
  CUSTOMER: "customers",
  FUTURE_TIME: "orderFutureTimes",
  MARKET: "markets",
  ORDER: "orders",
  ORDER_STORE: "orderStores",
  PRODUCT: "products",
  STORE: "stores",
  SERVICE_METHOD: "serviceMethods",
});

export default RESOURCE_TYPE;
