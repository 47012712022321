import { SET_MENU } from "ducks/menu";
import { getAreaNames } from "ducks/menu/helpers";

export const initialState = {};

export default function reducer(
  state = initialState,
  { type, ...action } = {}
) {
  switch (type) {
    case SET_MENU:
      return Object.entries(action.products).reduce(
        (all, [productId, { partCount, ...product }]) =>
          Object.assign(all, {
            [productId]: Object.assign(product, {
              areas: getAreaNames(partCount),
            }),
          }),
        {}
      );
    default:
      return state;
  }
}
