import { createSelector } from "reselect";

export const getConfiguration = ({ configuration = {} } = {}) => configuration;

export const getLanguages = createSelector(
  [getConfiguration],
  ({ languages = {} }) => languages
);

export const getLanguagesSet = createSelector(
  [getConfiguration],
  ({ languagesSet = false }) => languagesSet
);

export const getCurrentLanguage = createSelector(
  [getConfiguration],
  ({ currentLanguage = "en" }) => currentLanguage
);

export const getMarketConfigured = createSelector(
  [getConfiguration],
  ({ market = false }) => market
);

export const getRootConfigured = createSelector(
  [getConfiguration],
  ({ root = false }) => root
);

export default getConfiguration;
