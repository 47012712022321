import React from "react";

import PropTypes from "prop-types";

const OrderDetail = ({ icon, id, label, storeOrderId, t, ...props }) =>
  props[id] ? (
    <li
      className="list__item order-details__item"
      data-icon={icon}
      data-quid={`order-details-${storeOrderId}-${id}`}
      id={id}
    >
      {t(label)}: <b>{props[id]}</b>
    </li>
  ) : null;

OrderDetail.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  storeOrderId: PropTypes.string.isRequired,
  t: PropTypes.func,
};

OrderDetail.defaultProps = {
  icon: "",
  id: "",
  label: "",
  t: () => {},
};

export default OrderDetail;
