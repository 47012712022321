import React, { Component } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { SERVICE_METHOD } from "constants/order";

import "components/StoreLocator/Market/NG.css";
import GoogleMapsTypeAhead from "components/StoreLocator/Strategy/GoogleMapsTypeAhead";
import Input from "components/StoreLocator/Strategy/Input";
import Select from "components/StoreLocator/Strategy/Select";

import CityDropdown from "../Strategy/CityDropDown/CityDropDown";
import DominosTypeAhead from "../Strategy/DominosTypeAhead";

const ADDRESS_TYPES = Object.freeze({
  [ADDRESS.HOUSE]: {
    fields: [
      ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
      ADDRESS_FIELDS[ADDRESS.UNIT_NUMBER],
    ],
    key: ADDRESS.HOUSE,
  },
  [ADDRESS.APARTMENT]: {
    fields: [
      ADDRESS_FIELDS[ADDRESS.STREET_NUMBER],
      ADDRESS_FIELDS[ADDRESS.APARTMENT_NAME],
      ADDRESS_FIELDS[ADDRESS.APARTMENT_NUMBER],
    ],
    key: ADDRESS.APARTMENT,
  },
});

const placesMethods = Object.freeze(["Carryout"]);

const optionalFields = [
  ADDRESS.NEIGHBORHOOD,
  ADDRESS.ADDRESS_NICKNAME,
  ADDRESS.UNIT_NUMBER,
];

class NG extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalid: [],
    };

    this.map = React.createRef();

    this.getAddressFields = this.getAddressFields.bind(this);
    this.getFormattedAddress = this.getFormattedAddress.bind(this);
    this.getGeocodedResult = this.getGeocodedResult.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.updateStreet = this.updateStreet.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
  }

  updateStreet({ city, description, neighborhood }) {
    const { handleChange } = this.props;
    handleChange({
      city,
      neighborhood,
      streetName: description,
    });
  }

  componentDidMount() {
    const { maps, serviceMethod, setUsesPlaces } = this.props;

    this.geocoderService = new maps.Geocoder();
    this.geocoderOK = maps.GeocoderStatus.OK;

    placesMethods.includes(serviceMethod) && setUsesPlaces(true);

    this.props.getCities({
      countryCode: "NG",
      state: "",
    });
  }

  //eslint-disable-next-line class-methods-use-this
  getAddressFields(addressType) {
    return ADDRESS_TYPES[addressType]
      ? ADDRESS_TYPES[addressType].fields
      : ADDRESS_TYPES[ADDRESS.HOUSE].fields;
  }

  //eslint-disable-next-line class-methods-use-this
  getFormattedAddress({ city, neighborhood, streetName, streetNumber }) {
    const unnumberedAddress = [streetName, neighborhood, city].reduce(
      (formattedAddress, field) => {
        if (!field) return formattedAddress;

        return formattedAddress ? `${formattedAddress}, ${field}` : field;
      },
      ""
    );

    return streetNumber
      ? `${streetNumber} ${unnumberedAddress}`
      : unnumberedAddress;
  }

  getGeocodedResult(request, callback) {
    this.geocoderService.geocode(request, (response, status) => {
      if (status !== this.geocoderOK) {
        this.props.onError(status);
        return;
      }

      // If we have an address of type "street_address", use it.
      // If not, use the first available address component.
      const { address_components, geometry } =
        response.find((address) => address.types.includes("street_address")) ||
        response[0];

      callback({ addressComponents: address_components, geometry });
    });
  }

  handleClear() {
    const addressFields = this.getAddressFields(this.props.addressType);
    const updates = addressFields.reduce(
      (updatesObject, { mapping: fieldName }) => {
        updatesObject[fieldName] = "";
        return updatesObject;
      },
      {
        city: "",
        streetName: "",
      }
    );

    this.props.handleChange(updates);
  }

  handleFocus(update) {
    const [fieldName] = Object.keys(update);

    this.setState({
      invalid: this.state.invalid.filter(
        (invalidFieldName) => fieldName !== invalidFieldName
      ),
    });
  }

  handleSearch() {
    const { city, neighborhood, streetName, streetNumber } = this.props;
    const formattedAddress = this.getFormattedAddress({
      city,
      neighborhood,
      streetName,
      streetNumber,
    });

    this.getGeocodedResult({ address: formattedAddress }, ({ geometry }) => {
      this.map.current.dropPin(geometry.location);
    });
  }

  validateInputs() {
    const propKeys = Object.keys(this.props);

    // All input fields except for neighborhood are required.
    this.setState({
      invalid: Object.keys(ADDRESS_FIELDS).filter(
        (field) =>
          propKeys.includes(field) &&
          this.props[field]?.length === 0 &&
          !optionalFields.includes(field)
      ),
    });
  }

  render() {
    const {
      address,
      addressType,
      city,
      getStreets,
      handleChange,
      maps,
      serviceMethod,
      streetName,
      streets,
      t,
    } = this.props;

    const { handleFocus, validateInputs } = this;
    const { invalid } = this.state;
    const fieldClasses = "grid__cell--1 grid__cell--1/2@desktop";
    const suggestedStreets = streets
      .map(({ id, ...streetComponents }) => ({
        ...streetComponents,
        id,
        description: id,
      }))
      .slice(0, 4);
    switch (serviceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return (
          <div className="grid">
            <div className="grid__cell--1 grid__cell--1@desktop">
              <Select
                className="grid__cell--1"
                handleChange={handleChange}
                label={t("customer:locations.address.address_type")}
                name="addressType"
                quidBase="store-locator-address-type"
                source={Object.keys(ADDRESS_TYPES).reduce((types, type) => {
                  const { key, label } = ADDRESS_FIELDS[type];
                  types[key] = label;
                  return types;
                }, {})}
                t={t}
                value={addressType}
              />

              <CityDropdown t={t} handleChange={handleChange} />

              {this.getAddressFields(addressType).map(
                ({ label, mapping: key }) => (
                  <Input
                    className={fieldClasses}
                    handleBlur={validateInputs}
                    handleChange={handleChange}
                    handleFocus={handleFocus}
                    isInvalid={
                      invalid.includes(key) && this.props[key].length === 0
                    }
                    isRequired={!optionalFields.includes(key)}
                    key={key}
                    label={t(label)}
                    name={key}
                    quidBase="store-locator-address-field"
                    t={t}
                    value={this.props[key]}
                  />
                )
              )}

              <DominosTypeAhead
                className="grid__cell--1 grid__cell--1/2@desktop"
                debounce={250}
                onChange={({ streetName }) => {
                  handleChange({
                    streetName,
                  });
                }}
                onSelect={this.updateStreet}
                fetchPredictions={(streetNameValue) => {
                  getStreets({
                    countryCode: "NG",
                    customHref:
                      "/store-locator-typeahead-service/search/address",
                    city,
                    street: streetNameValue,
                    streetLimit: "5",
                    power: true,
                  });
                }}
                label={t(ADDRESS_FIELDS[ADDRESS.STREET_NAME].label)}
                name="streetName"
                quidBase="store-locator-address-field"
                input={streetName}
                suggestions={suggestedStreets}
                t={t}
              />

              <div className="grid__cell--1">
                <div className="grid form-controls">
                  <button
                    className="btn grid__cell--1 grid__cell--1/4@widescreen"
                    data-icon="clear"
                    data-quid="store-locator-clear"
                    onClick={this.handleClear}
                    type="button"
                  >
                    {t("shared:action.clear_form")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case SERVICE_METHOD.CARRYOUT:
      default:
        return (
          <GoogleMapsTypeAhead
            className="grid__cell--1"
            input={address}
            label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
            maps={maps}
            name="address"
            onChange={({ address: addressValue, placeId }) =>
              handleChange({
                address: addressValue,
                placeId,
              })
            }
            options={{
              componentRestrictions: { country: ["NG"] },
              types: ["geocode"],
            }}
            quidBase="store-locator-address"
          />
        );
    }
  }
}

NG.propTypes = {
  address: PropTypes.string,
  addressType: PropTypes.string,
  city: PropTypes.string,
  getCities: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  getStreets: PropTypes.func.isRequired,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  neighborhood: PropTypes.string,
  onError: PropTypes.func,
  //eslint-disable-next-line react/no-unused-prop-types
  organizationName: PropTypes.string,
  serviceMethod: PropTypes.oneOf(["Carryout", "Delivery", "Dine In"]),
  setUsesPlaces: PropTypes.func.isRequired,
  streetName: PropTypes.string,
  streetNumber: PropTypes.string,
  streets: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  t: PropTypes.func.isRequired,
  //eslint-disable-next-line react/no-unused-prop-types
  unitNumber: PropTypes.string,
};

NG.defaultProps = {
  address: "",
  addressType: ADDRESS.HOUSE,
  city: "",
  neighborhood: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    process.env.NODE_ENV !== "production" && console.error(error);
  },
  organizationName: "",
  serviceMethod: "Carryout",
  streetName: "",
  streetNumber: "",
  unitNumber: "",
};

export default NG;
