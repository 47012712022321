/** @jsxImportSource @emotion/react */
// None of the arrays with change and this is display only, so it's safe to use index

/* eslint-disable react/no-array-index-key */
import PropTypes from "prop-types";

const OrderProduct = ({ t, product }) => {
  const formatToppings = (toppings) => {
    const toppingsString = toppings
      .map((topping) => `${t(topping.weight)} ${topping.toppingName}`)
      .join(", ");
    return toppingsString;
  };

  const formatOptions = (options) =>
    options.map((option, index) => (
      <p key={index} className="toppings">{`${t(option.part)}: ${formatToppings(
        option.toppings
      )}`}</p>
    ));
  return (
    <div className="product">
      <b>
        <p>
          {product.quantity && <span>{`${product.quantity} - `}</span>}
          {product.productName}
        </p>
      </b>
      {formatOptions(product.options)}
    </div>
  );
};

export default OrderProduct;

OrderProduct.propTypes = {
  t: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
};
