import React, { PureComponent } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      sidebarElement: null,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick);
  }

  handleClick({ target }) {
    const targetIsOutsideOfSidebar =
      !this.state.sidebarElement.contains(target) && !target.dataset.hook;

    this.props.showSidebar &&
      targetIsOutsideOfSidebar &&
      this.props.toggleSidebar();
  }

  render() {
    const { children, showSidebar } = this.props;

    return (
      <div
        className={classNames("page__sidebar", showSidebar && "is-open")}
        ref={(el) =>
          this.setState({
            sidebarElement: el,
          })
        }
      >
        {children}
      </div>
    );
  }
}

Sidebar.propTypes = {
  children: PropTypes.node,
  showSidebar: PropTypes.bool,
  toggleSidebar: PropTypes.func,
};

Sidebar.defaultProps = {
  children: null,
  showSidebar: false,
  toggleSidebar: () => {},
};

export default Sidebar;
