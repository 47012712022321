import tealium from "tealium-adapter";

import {
  sendTealiumExitReason,
  sendTealiumOrderStarted,
  sendTealiumPlaceOrderSuccess,
  sendTealiumUpsellProductAdded,
} from "rtk_redux/actions/tealium";

export const tealiumMiddleware = (middleware) => {
  middleware.startListening({
    actionCreator: sendTealiumOrderStarted,
    effect: () => {
      tealium.link({
        event_name: "Order Started",
      });
    },
  });

  middleware.startListening({
    actionCreator: sendTealiumUpsellProductAdded,
    effect: ({ payload: { productName, quantity } }) => {
      tealium.link({
        event_name: "Upsell Product Added",
        product_name: productName,
        quantity,
      });
    },
  });

  middleware.startListening({
    actionCreator: sendTealiumPlaceOrderSuccess,
    effect: ({ payload: { ecommOrderId } }) => {
      tealium.link({
        event_name: "Place Order Success",
        ecomm_order_id: ecommOrderId,
      });
    },
  });

  middleware.startListening({
    actionCreator: sendTealiumExitReason,
    effect: ({ payload: { exitReason } }) => {
      tealium.link({
        event_label: "Exit Reason",
        event_name: exitReason,
      });
    },
  });
};
