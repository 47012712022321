import { createSelector } from "reselect";

export const getDataId = ({ id = "" } = {}) => id;

export const getLinks = ({ links = {} } = {}) => links;

export const getSelfLink = createSelector(
  [getLinks],
  ({ self: { href = "" } = {} } = {}) => href
);

export const getRelationship =
  (name) =>
  ({ relationships = {} } = {}) =>
    relationships[name] || {};

export const getLinkFromRelationship = (name = {}) =>
  createSelector(
    [getRelationship(name)],
    ({ links: { related: { href = "" } = {} } = {} }) => href
  );

export const getDataFromRelationship = (name = "") =>
  createSelector([getRelationship(name)], ({ data = {} }) => data);

export const getDataIdsFromRelationship = (name = "") =>
  createSelector([getDataFromRelationship(name)], (data = []) => {
    if (Array.isArray(data)) return data.map(({ id }) => id);
    return data && data.id ? [data.id] : [];
  });

export const getDataIdFromRelationship = (name = "") =>
  createSelector([getDataIdsFromRelationship(name)], ([id = ""]) => id);

export const getIncludedResourcesByType = (resources = [], type = "") =>
  resources.filter(({ type: resourceType = "" } = {}) => resourceType === type);

export const getIncludedResourceByType = (resources = [], type = "") =>
  resources.find(({ type: resourceType = "" } = {}) => resourceType === type) ||
  {};

export const getIncludedResourceById = (resources = [], id = "") =>
  resources.find(({ id: resourceId = "" } = {}) => resourceId === id) || {};

export default getLinkFromRelationship;
