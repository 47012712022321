import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";

import { ADDRESS, ADDRESS_FIELDS } from "constants/address";
import { MARKET_CENTER } from "constants/market";
import { SERVICE_METHOD } from "constants/order";

import "components/StoreLocator/Market/ZA.css";
import DrivenField from "components/StoreLocator/Strategy/DrivenField";
import GoogleMapsTypeAhead from "components/StoreLocator/Strategy/GoogleMapsTypeAhead";
import Input from "components/StoreLocator/Strategy/Input";
import PinDrop from "components/StoreLocator/Strategy/PinDrop";
import Select from "components/StoreLocator/Strategy/Select";

const ADDRESS_TYPES = Object.freeze({
  [ADDRESS.HOUSE]: ADDRESS_FIELDS[ADDRESS.HOUSE].label,
  [ADDRESS.COMPLEX]: ADDRESS_FIELDS[ADDRESS.COMPLEX].label,
  [ADDRESS.PLACE]: ADDRESS_FIELDS[ADDRESS.PLACE].label,
});

const MARKET_ZOOM = 10;

const placesMethods = Object.freeze(["Delivery"]);

class ZA extends Component {
  constructor(props) {
    super(props);

    this.map = React.createRef();

    this.getGeocodedResult = this.getGeocodedResult.bind(this);
    this.handlePinDrop = this.handlePinDrop.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    const { maps, serviceMethod, setUsesPlaces } = this.props;

    this.geocoderService = new maps.Geocoder();
    this.geocoderOK = maps.GeocoderStatus.OK;

    placesMethods.includes(serviceMethod) && setUsesPlaces(true);
  }

  getGeocodedResult(request, callback) {
    this.geocoderService.geocode(request, (response, status) => {
      if (status !== this.geocoderOK) {
        this.props.onError(status);
        return;
      }

      const { formatted_address, geometry, place_id } =
        response.find((address) => address.types.includes("street_address")) ||
        response[0];

      callback({
        address: formatted_address,
        geometry,
        placeId: place_id,
      });
    });
  }

  handlePinDrop(latLng) {
    this.getGeocodedResult({ location: latLng }, this.props.handleChange);
  }

  handleSearch() {
    const { address } = this.props;

    this.getGeocodedResult({ address }, ({ geometry }) => {
      this.map.current.dropPin(geometry.location);
    });
  }

  render() {
    const {
      address,
      addressNickname,
      addressType,
      cities,
      city,
      getCities,
      getRegions,
      handleChange,
      maps,
      organizationName,
      regionCode,
      regions,
      serviceMethod,
      t,
      unitNumber,
    } = this.props;

    const fieldClasses = "grid__cell--1";

    let addressFields;
    switch (addressType) {
      case ADDRESS.COMPLEX:
        addressFields = (
          <Fragment>
            <Input
              className="grid__cell--1 grid__cell--1/2@desktop"
              handleChange={handleChange}
              label={t(ADDRESS_FIELDS[ADDRESS.COMPLEX_NAME].label)}
              name="organizationName"
              quidBase="store-locator-address-field"
              value={organizationName}
            />

            <Input
              className="grid__cell--1 grid__cell--1/2@desktop"
              handleChange={handleChange}
              label={t(ADDRESS_FIELDS[ADDRESS.COMPLEX_NUMBER].label)}
              name="unitNumber"
              quidBase="store-locator-address-field"
              value={unitNumber}
            />

            <Input
              className="grid__cell--1"
              handleChange={handleChange}
              label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME].label)}
              name="addressNickname"
              quidBase="store-locator-address-field"
              value={addressNickname}
              t={t}
            />
          </Fragment>
        );
        break;
      case ADDRESS.PLACE:
        addressFields = (
          <Fragment>
            <Input
              className={fieldClasses}
              handleChange={handleChange}
              label={t(ADDRESS_FIELDS[ADDRESS.PLACE_NAME].label)}
              name="organizationName"
              quidBase="store-locator-address-field"
              value={organizationName}
            />

            <Input
              className="grid__cell--1"
              handleChange={handleChange}
              label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS_NICKNAME].label)}
              name="addressNickname"
              quidBase="store-locator-address-field"
              value={addressNickname}
              t={t}
            />
          </Fragment>
        );
        break;
      default:
        break;
    }

    switch (serviceMethod) {
      case SERVICE_METHOD.DELIVERY:
        return (
          <div className="grid">
            <div className="grid__cell--1 grid__cell--1/2@desktop margin__bottom--1_25rem">
              <PinDrop
                mapCenter={MARKET_CENTER.SOUTH_AFRICA}
                mapZoom={MARKET_ZOOM}
                maps={maps}
                onChange={this.handlePinDrop}
                ref={this.map}
              />
            </div>
            <div className="grid__cell--1 grid__cell--1/2@desktop">
              <Select
                className={fieldClasses}
                handleChange={handleChange}
                label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS_TYPE].label)}
                name="addressType"
                quidBase="store-locator-address-type"
                source={ADDRESS_TYPES}
                t={t}
                value={addressType}
              />

              <GoogleMapsTypeAhead
                className={fieldClasses}
                input={address}
                label={t(ADDRESS_FIELDS[ADDRESS.ADDRESS].label)}
                maps={maps}
                name="address"
                onChange={({ address: addressValue, placeId }) =>
                  handleChange({
                    address: addressValue,
                    placeId,
                  })
                }
                options={{
                  componentRestrictions: { country: ["ZA"] },
                  types: ["geocode"],
                }}
                quidBase="store-locator-address"
              />

              {addressFields}

              <div className="grid__cell--1">
                <div className="grid form-controls">
                  <button
                    className="btn grid__cell"
                    data-icon="pin_drop"
                    data-quid="store-locator-search"
                    onClick={this.handleSearch}
                    type="button"
                  >
                    {t("shared:action.drop_pin")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case SERVICE_METHOD.CARRYOUT:
      default:
        return (
          <DrivenField
            className={fieldClasses}
            handleChange={handleChange}
            initializeSource={getRegions}
            label={t(ADDRESS_FIELDS[ADDRESS.REGION].label)}
            name="regionCode"
            quidBase="store-locator-region"
            source={regions}
            type="select"
            updateSource={getRegions}
            value={regionCode}
            isRoot
          >
            {({ drivers: cityDrivers, ...cityProps }) => (
              <DrivenField
                className={fieldClasses}
                drivers={cityDrivers}
                handleChange={handleChange}
                initializeSource={getCities}
                label={t(ADDRESS_FIELDS[ADDRESS.CITY].label)}
                name="city"
                quidBase="store-locator-city"
                source={cities}
                type="select"
                updateSource={(newValue) =>
                  getCities(Object.assign({}, cityDrivers, newValue))
                }
                value={city}
                {...cityProps}
                isReady
              />
            )}
          </DrivenField>
        );
    }
  }
}

ZA.propTypes = {
  address: PropTypes.string,
  addressNickname: PropTypes.string,
  addressType: PropTypes.string,
  cities: PropTypes.objectOf(PropTypes.string).isRequired,
  city: PropTypes.string,
  getCities: PropTypes.func.isRequired,
  getRegions: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  onError: PropTypes.func,
  organizationName: PropTypes.string,
  regionCode: PropTypes.string,
  regions: PropTypes.objectOf(PropTypes.string).isRequired,
  serviceMethod: PropTypes.oneOf(["Carryout", "Delivery", "Dine In"]),
  setUsesPlaces: PropTypes.func.isRequired,
  unitNumber: PropTypes.string,
};

ZA.defaultProps = {
  address: "",
  addressNickname: "",
  addressType: ADDRESS.HOUSE,
  city: "",
  onError: (error) => {
    //eslint-disable-next-line no-console
    process.env.NODE_ENV !== "production" && console.error(error);
  },
  organizationName: "",
  regionCode: "",
  serviceMethod: "Carryout",
  unitNumber: "",
};

export default ZA;
