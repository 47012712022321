import { ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, filter, mergeMap, pluck } from "rxjs/operators";

import { createAddress } from "ducks/address";
import { updateCustomer } from "ducks/customer";
import { getIsMinimumCustomerDataComplete } from "ducks/customer/selectors";
import { getMarketConfigs } from "ducks/market/selectors";
import { setMessage } from "ducks/message";
import { createOrder, updateOrderStore } from "ducks/order";
import { createOrderProduct } from "ducks/orderProduct";
import { getCurrentStoreId } from "ducks/store/selectors";
import { getLinkFromRelationship } from "selectors/related";

import { sendTealiumOrderStarted } from "rtk_redux/actions/tealium";
import {
  setHasUserSelectedStore,
  setStoreQueryLastArgs,
} from "rtk_redux/slices/customerPageSlice";
import {
  setCustomerCity,
  setCustomerState,
  setCustomerZipcode,
} from "rtk_redux/slices/serviceMethodSlice";

import {
  STJUDE_CATEGORY_ID,
  STJUDE_PRODUCT_CODE,
  STJUDE_ROUND_UP_SIZE_CODE,
} from "constants/menu";
import { MESSAGE } from "constants/message";
import { ORDER_COMPLETION_STATUS, SERVICE_METHOD } from "constants/order";
import { RESOURCE_TYPE } from "constants/resource";
import withValidation from "modules/elValidadore";
import { idValidators, urlValidators } from "modules/validators";

const SCOPE = "order-entry/order/";

export const START_ORDER = `${SCOPE}START_ORDER`;
export const START_ORDER_ERROR = `${SCOPE}START_ORDER_ERROR`;
export const START_ORDER_SUCCESS = `${SCOPE}START_ORDER_SUCCESS`;

export const startOrder = withValidation(
  ({
    customer = undefined,
    orderStatus = ORDER_COMPLETION_STATUS.UNFULFILLED,
    storeId = null,
    currentLocationPostalCode,
    currentLocationCity,
    currentLocationState,
    addStJudeRoundUp,
    url = "",
  } = {}) => ({
    type: START_ORDER,
    customer,
    orderStatus,
    storeId,
    currentLocationPostalCode,
    currentLocationCity,
    currentLocationState,
    addStJudeRoundUp,
    url,
  }),
  {
    storeId: idValidators,
    url: urlValidators,
  }
);

export function startOrderError(error) {
  return {
    type: START_ORDER_ERROR,
    error,
  };
}

export function startOrderSuccess(order = {}) {
  return Object.assign(
    {
      type: START_ORDER_SUCCESS,
    },
    order
  );
}

export const startOrderEpic = (action$, { getState }, { fetch }) =>
  action$.pipe(
    ofType(START_ORDER),
    filter(({ customer = {} }) => {
      const state = getState();
      return getIsMinimumCustomerDataComplete(customer)(state);
    }),
    mergeMap(
      ({
        storeId,
        customer,
        currentLocationPostalCode,
        currentLocationCity,
        currentLocationState,
        addStJudeRoundUp,
        ...action
      }) => {
        const state = getState();
        const { SOURCE_ORG_URI } = getMarketConfigs(state);
        return fetch(
          Object.assign(action, {
            body: {
              data: {
                attributes: {
                  sourceOrgUri: SOURCE_ORG_URI,
                },
                relationships: {
                  store: {
                    data: {
                      id: storeId,
                      type: RESOURCE_TYPE.STORE,
                    },
                  },
                },
              },
            },
          }),
          {
            method: "POST",
          }
        ).pipe(
          pluck("response", "data"),
          mergeMap(({ id, links, relationships }) =>
            [
              createOrder({
                links,
                orderId: id,
                relationships,
              }),
              startOrderSuccess({
                orderId: id,
                relationships,
                storeId,
              }),
              sendTealiumOrderStarted(),
            ].concat(
              customer
                ? updateCustomer(
                    Object.assign(
                      {
                        url: getLinkFromRelationship("customer")({
                          relationships,
                        }),
                      },
                      customer
                    )
                  )
                : [],
              currentLocationPostalCode
                ? setStoreQueryLastArgs({
                    type: SERVICE_METHOD.CARRYOUT,
                    s: "",
                    c: currentLocationPostalCode,
                  })
                : [],
              currentLocationPostalCode
                ? setCustomerZipcode(currentLocationPostalCode)
                : [],
              currentLocationCity ? setCustomerCity(currentLocationCity) : [],
              currentLocationState
                ? setCustomerState(currentLocationState)
                : [],
              addStJudeRoundUp
                ? createOrderProduct({
                    orderId: id,
                    categoryId: STJUDE_CATEGORY_ID,
                    productId: STJUDE_PRODUCT_CODE,
                    sizeId: STJUDE_ROUND_UP_SIZE_CODE,
                    skipSetActiveOrderProduct: true,
                    url: getLinkFromRelationship("products")({ relationships }),
                  })
                : []
            )
          ),
          catchError((error) => of(startOrderError(error)))
        );
      }
    )
  );

export const startOrderSuccessEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(START_ORDER_SUCCESS),
    mergeMap(({ orderId, storeId, ...order }) => {
      const state = getState();
      const currentStoreId = getCurrentStoreId(state);
      const shouldUpdateStore =
        currentStoreId !== storeId && storeId !== undefined;
      return [
        createAddress({
          active: true,
          orderId,
          url: getLinkFromRelationship("address")(order),
        }),
        setMessage({ message: MESSAGE.ORDER_STARTED }),
      ].concat(
        shouldUpdateStore
          ? updateOrderStore({
              orderId,
              storeId,
              url: getLinkFromRelationship("store")(order),
            })
          : [],
        shouldUpdateStore ? setHasUserSelectedStore(true) : []
      );
    })
  );
