import classNames from "classnames";
import PropTypes from "prop-types";

import "components/DotLoader/DotLoader.css";

const DotLoader = ({ className }) => {
  const dots = [
    { id: "dot-1", color: "blue" },
    { id: "dot-2", color: "blue" },
    { id: "dot-3", color: "red" },
  ];
  return (
    <span className={classNames("dot-loader", "bouncy", className)}>
      {dots.map(({ id, color }) => (
        <span key={id} className={`bouncy__dot bouncy__dot--${color}`} />
      ))}
    </span>
  );
};

DotLoader.propTypes = {
  className: PropTypes.string,
};

export default DotLoader;
