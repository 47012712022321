/** @jsxImportSource @emotion/react */
import { DotLoader } from "@dpz/frankensteiner";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
  LOOKUP_STATUS,
  selectCustomerLookupStatus,
} from "rtk_redux/slices/customerPageSlice";

import { customerHeaderWrapper } from "./CustomerHeader.styles";

import HeaderProfile from "./HeaderProfile";
import HeaderStoreInformation from "./HeaderStoreInformation";
import Welcome from "./Welcome";

// TODO: Wording from product
export const CustomerHeader = ({ t }) => {
  const customerLookupStatus = useSelector(selectCustomerLookupStatus);

  const headerDisplay = () => {
    switch (customerLookupStatus) {
      case LOOKUP_STATUS.INITAL:
        return <Welcome t={t} />;
      case LOOKUP_STATUS.PENDING:
        return <DotLoader />;
      case LOOKUP_STATUS.FINISHED:
        return (
          <>
            <HeaderProfile t={t} />
            <HeaderStoreInformation t={t} />
          </>
        );
    }
  };

  return <div css={customerHeaderWrapper}>{headerDisplay()}</div>;
};

export default CustomerHeader;

CustomerHeader.propTypes = {
  t: PropTypes.func,
};
