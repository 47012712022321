import { AREAS } from "constants/order";

export default ({ orderProductToppings, weightedDefaultOptions }) =>
  Object.entries(orderProductToppings).reduce(
    (allToppingAreas, [area, topping]) => {
      Object.entries(topping).forEach(([toppingName, toppingWeight]) => {
        let newArea = area;
        let newWeight = toppingWeight;

        // if default topping, not whole, and weight is zero - swap area and use default weight
        if (
          toppingName in weightedDefaultOptions &&
          area !== AREAS.WHOLE &&
          toppingWeight === 0
        ) {
          newArea = area === AREAS.LEFT ? AREAS.RIGHT : AREAS.LEFT;
          newWeight = weightedDefaultOptions[toppingName];
        }

        // create area if it doesn't exist
        if (!allToppingAreas[newArea]) {
          allToppingAreas[newArea] = {};
        }

        // add topping/weight to area
        allToppingAreas[newArea][toppingName] = newWeight;
      });

      return allToppingAreas;
    },
    {}
  );
