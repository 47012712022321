import React from "react";

import PropTypes from "prop-types";

import { AREA, OPTION, defaultArea } from "constants/order";
import { WEIGHT } from "constants/topping";
import kebabCase from "modules/kebabCase";
import { getOptionClasses } from "modules/optionHelpers";

import RangedButton from "components/RangedButton";

const Option = ({
  activeArea = AREA[defaultArea],
  activeOrderProductAvailableOptions = {},
  activeOrderProductOptionIds = [],
  activeOrderProductOptions = {
    [defaultArea]: {},
  },
  deleteOrderProductOption,
  disabled = false,
  optionCode,
  optionDescription = "",
  optionId,
  optionName,
  optionType = OPTION.TOPPING,
  setOrderProduct,
  customSideQuantityRange = [],
}) => {
  const handleOptionClickAdd = () => {
    const option =
      optionType === OPTION.SIDE
        ? { sideId: optionId }
        : { toppingId: optionId };

    setOrderProduct(option);
  };

  const handleOptionClickRemove = () => {
    deleteOrderProductOption(optionCode);
  };

  const handleOptionWeightClick = (optionWeight) => {
    const option =
      optionType === OPTION.SIDE
        ? {
            sideId: optionId,
            sideQuantity: optionWeight,
          }
        : {
            toppingId: optionId,
            toppingWeight: optionWeight,
          };

    optionWeight === WEIGHT[0].value
      ? deleteOrderProductOption(optionCode)
      : setOrderProduct(option);
  };

  if (activeOrderProductOptionIds.includes(optionId)) {
    const option = activeOrderProductOptions[optionId];

    const areaValue = typeof option === "object" ? option[activeArea] : option;

    const hasValueForArea = !!areaValue;
    const isDefaultArea = activeArea === defaultArea;

    return (
      <RangedButton
        className={getOptionClasses(activeOrderProductOptions[optionId])}
        currentValue={areaValue}
        defaultValue={WEIGHT["1"].value}
        key={optionId}
        range={
          customSideQuantityRange.length > 0
            ? customSideQuantityRange
            : activeOrderProductAvailableOptions[optionId]
        }
        onClick={
          hasValueForArea || isDefaultArea
            ? handleOptionClickRemove
            : handleOptionClickAdd
        }
        quidBase={kebabCase(optionName)}
        text={optionName}
        updateValue={handleOptionWeightClick}
        disabled={disabled}
      />
    );
  }

  return (
    <button
      className={getOptionClasses(activeOrderProductOptions[optionId])}
      data-quid={kebabCase(optionName)}
      key={optionId}
      onClick={handleOptionClickAdd}
      title={optionDescription}
      type="button"
      disabled={disabled}
    >
      {optionName}
    </button>
  );
};

Option.propTypes = {
  activeArea: PropTypes.string,
  activeOrderProductAvailableOptions: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.number)
  ),
  activeOrderProductOptionIds: PropTypes.arrayOf(PropTypes.string),
  activeOrderProductOptions: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.objectOf(PropTypes.number),
    ])
  ),
  customSideQuantityRange: PropTypes.arrayOf(PropTypes.number),
  deleteOrderProductOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  optionCode: PropTypes.string.isRequired,
  optionDescription: PropTypes.string,
  optionId: PropTypes.string.isRequired,
  optionName: PropTypes.string.isRequired,
  optionType: PropTypes.string,
  setOrderProduct: PropTypes.func.isRequired,
};

export default Option;
