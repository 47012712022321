// Map an address_components object from Google Maps to the corresponding
// query values for a Store Locator International service call.

export default (addressComponents, query = {}) =>
  addressComponents.reduce(
    (all, { long_name: longName, short_name: shortName, types }) => {
      let key;
      let value = longName;

      if (types.includes("street_number")) key = "streetNumber";
      else if (types.includes("route")) key = "streetName";
      else if (types.includes("sublocality_level_1")) key = "neighborhood";
      else if (types.includes("locality")) key = "city";
      else if (types.includes("administrative_area_level_1")) {
        key = "regionCode";
        value = shortName;
      } else if (types.includes("postal_code")) key = "postalCode";
      else return all;

      return Object.assign(all, {
        [key]: value,
      });
    },
    query
  );
