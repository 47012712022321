import { css } from "@emotion/react";

export default css`
  align-items: center;
  border-color: #fff;
  border-width: 0;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-style: solid;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 auto;
  font-weight: bold;
  justify-content: center;
  outline: 0;
  padding: 1rem;
  transition: color 0.1s ease;

  &.is-active {
    background-color: var(--interactive-fg);
    color: #fff;
  }

  &.is-exit {
    flex: 0.5;
  }

  &:not(.is-active) {
    background-color: var(--interactive-bg);
    color: var(--interactive-fg);

    &:hover {
      color: var(--dom-red);
    }
  }

  .has-error {
    background: transparent
      url("data:image/svg+xml,%3Csvg fill='%23a23f3d' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'/%3E%3C/svg%3E")
      no-repeat 0.142857143rem center !important;
    height: 2rem;
    width: 2rem;
  }

  &[data-icon]::before {
    display: block;
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-right: unset;
    text-align: center;
    width: 100%;
  }

  @media screen and (max-width: 40em), screen and (max-height: 35em) {
    &[data-icon]::before {
      margin: 0;
      padding: 1rem;
    }

    .item--label {
      display: none;
    }
  }
`;
