import { AREA, AREA_SORT, defaultArea } from "constants/order";

export const getMaxPartCount = (partCounts = []) => Math.max(...partCounts);

export const getAreaNames = (partCounts = [], initialize = true) =>
  Array.from(
    new Set(
      [
        Array.isArray(partCounts) ? getMaxPartCount(partCounts) : partCounts,
      ].reduce(
        (areas, maxPartCount) => {
          for (let i = 1; i <= maxPartCount; i++) {
            areas.push(`${i}/${maxPartCount}`);
          }
          return areas;
        },
        initialize ? [AREA[defaultArea].value] : []
      )
    )
  ).sort((a, b) => AREA_SORT[a] - AREA_SORT[b]);

export default getAreaNames;
