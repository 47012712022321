/** @jsxImportSource @emotion/react */
import { isValidNumber } from "libphonenumber-js";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  addInvalidValue,
  removeInvalidValue,
  selectExtension,
  selectFirstName,
  selectInvalidValues,
  selectLastName,
  selectNumericPhoneNumber,
  selectPhoneNumber,
  selectPrefix,
  setExtensionValue,
  setFirstNameValue,
  setLastNameValue,
  setPhoneNumberValue,
  setPrefixValue,
} from "rtk_redux/slices/customerInformationFormSlice";
import { selectShowAllErrors } from "rtk_redux/slices/customerPageSlice";

import { PROFILE, PROFILE_FIELDS } from "constants/customer";
import { ONLY_LETTERS_INCLUDING_ACCENTS_REGEX } from "constants/customer";
import { COUNTRY_CODES } from "constants/market";
import { formatPhoneWhileTyping } from "modules/formatPhone";

import CustomerSelection from "components/CustomerSelection";

import { customerFormRow } from "../NewCustomerPage.styles";

import FormField from "../utils/FormField";
import {
  useHandleCustomerLookup,
  useHandleCustomerUpdate,
} from "../utils/hooks";

const CustomerInformationForm = ({ t }) => {
  const dispatch = useDispatch();
  const firstName = useSelector(selectFirstName);
  const lastName = useSelector(selectLastName);
  const prefix = useSelector(selectPrefix);
  const extension = useSelector(selectExtension);
  const phoneNumber = useSelector(selectPhoneNumber);
  const numericPhoneNumber = useSelector(selectNumericPhoneNumber);
  const handleCustomerLookup = useHandleCustomerLookup();
  const handleCustomerUpdate = useHandleCustomerUpdate();
  const invalidValues = useSelector(selectInvalidValues);
  const showAllErrors = useSelector(selectShowAllErrors);

  const handleProfileBlur = (e) => {
    const { id, value } = e.target;
    handleCustomerUpdate({ [id]: value });
    updateInvalidValues(id, e.target.checkValidity());
  };

  const handleProfileNameBlur =
    (set) =>
    ({ target: { id, value } }) => {
      const trimmedValue = value.trim();
      dispatch(set(trimmedValue));
      handleCustomerUpdate({ [id]: trimmedValue });
      updateInvalidValues(id, trimmedValue !== "");
    };

  const handlePhoneNumberBlur = () => {
    validatePhoneNumber().then((isPossible) => {
      isPossible && handleCustomerLookup();
      updateInvalidValues(PROFILE.PHONE, isPossible);
    });
  };

  const handleProfileNameChange =
    (set) =>
    ({ target: { value } }) => {
      if (ONLY_LETTERS_INCLUDING_ACCENTS_REGEX.test(value)) {
        dispatch(set(value));
      }
    };

  const validatePhoneNumber = async () => {
    return isValidNumber(numericPhoneNumber, COUNTRY_CODES.UNITED_STATES);
  };

  const handlePhoneNumberChange = (e) => {
    dispatch(setPhoneNumberValue(formatPhoneWhileTyping(e.target.value)));
  };

  const updateInvalidValues = (field, isValid) => {
    isValid
      ? dispatch(removeInvalidValue(field))
      : dispatch(addInvalidValue(field));
  };

  return (
    <form>
      <CustomerSelection t={t} />
      <div css={customerFormRow}>
        <FormField
          t={t}
          id={PROFILE.PREFIX}
          label={t(PROFILE_FIELDS[PROFILE.PREFIX].label)}
          value={prefix}
          className="prefix"
          hideLabel
          handleBlur={handleProfileBlur}
          handleChange={(e) => dispatch(setPrefixValue(e.target.value))}
        />
        <FormField
          t={t}
          id={PROFILE.PHONE}
          label={t(PROFILE_FIELDS[PROFILE.PHONE].label)}
          required
          value={phoneNumber}
          displayError={showAllErrors}
          isInvalid={invalidValues.has(PROFILE.PHONE)}
          handleChange={handlePhoneNumberChange}
          handleBlur={handlePhoneNumberBlur}
        />
        <FormField
          t={t}
          id="extension"
          label={t("customer:profile.extension")}
          value={extension}
          className="small"
          handleBlur={handleProfileBlur}
          handleChange={(e) => dispatch(setExtensionValue(e.target.value))}
        />
      </div>

      <div css={customerFormRow}>
        <FormField
          t={t}
          id={PROFILE.FIRST_NAME}
          label={t(PROFILE_FIELDS[PROFILE.FIRST_NAME].label)}
          required
          isInvalid={invalidValues.has(PROFILE.FIRST_NAME)}
          displayError={showAllErrors}
          value={firstName}
          handleBlur={handleProfileNameBlur(setFirstNameValue)}
          handleChange={handleProfileNameChange(setFirstNameValue)}
        />
        <FormField
          t={t}
          id={PROFILE.LAST_NAME}
          label={t(PROFILE_FIELDS[PROFILE.LAST_NAME].label)}
          required
          isInvalid={invalidValues.has(PROFILE.LAST_NAME)}
          displayError={showAllErrors}
          value={lastName}
          handleBlur={handleProfileNameBlur(setLastNameValue)}
          handleChange={handleProfileNameChange(setLastNameValue)}
        />
      </div>
    </form>
  );
};

export default CustomerInformationForm;

CustomerInformationForm.propTypes = {
  t: PropTypes.func,
};
