import { css } from "@emotion/react";

export default css`
  background: #fff;

  .receipt__coupon,
  .receipt__item {
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 0.5rem;

    &[data-icon] {
      align-items: top;
      display: flex;
    }
  }

  .receipt__coupon {
    position: relative;
    padding-right: 3em;
  }

  .receipt__coupon--fulfilled {
    cursor: default;

    & .receipt__coupon-delete {
      color: #44750f;
      border-color: #44750f;
    }
  }

  .receipt__coupon-delete {
    position: absolute;
    top: 0.25em;
    right: 0.25em;
    color: #995c00;
    border-color: #995c00;

    &::before {
      font-size: 1em;
    }
  }

  .receipt__item {
    transition: 0.2s background-color;

    &.is-active {
      background-color: var(--interactive-bg);
    }

    &:not(.is-active):hover {
      background-color: var(--interactive-hover);
    }
  }

  .split {
    flex-wrap: wrap;

    > .split__footer {
      flex: 0 0 100%;
      padding-top: 1rem;
      text-align: right;

      & > * {
        display: flex;
        align-items: flex-end;

        .split__footer--left {
          flex: 0;
          text-align: left;
        }

        .split__footer--right {
          flex: 1;
          text-align: right;
        }

        :not(.btn-group) > .btn {
          margin-left: 1rem;
        }
      }
    }
  }

  .total-line {
    margin: 0.5rem 0;

    &:first-child {
      margin-top: inherit;
    }

    &:last-child {
      margin-bottom: inherit;
    }
  }

  /* stylelint-disable-next-line selector-no-qualifying-type */
  input.quantity__input[type="number"] {
    background: #fff;
    border: 0;
    font-size: 1rem;
    padding: 0;
    text-align: center;
    width: 3rem;
  }

  .price-loading {
    flex: 0.25 1 0%;
  }

  .receipt__coupon--product {
    text-transform: capitalize;
  }
`;

export const zeroMarginStyle = css`
  margin: 0;
`;

export const boldStyle = css`
  font-weight: bold;
`;
