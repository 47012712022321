/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { storeMapItemStyles } from "../NewCustomerPage.styles";

import { useSetCurrentOrderStoreAndMethod } from "../utils/hooks";
import StoreWaitTimes from "./StoreWaitTimes";
import { formatStoreAddress } from "./helpers";

const StoreMapItem = ({ scrollToStore, closeInfoWindow, store, t }) => {
  const { setCurrentOrderStore } = useSetCurrentOrderStoreAndMethod();

  const handleOnClick = () => {
    store.IsOpen && setCurrentOrderStore(store.StoreID);
    scrollToStore(store.StoreID);
    closeInfoWindow();
  };

  return (
    <div css={storeMapItemStyles}>
      <button onClick={handleOnClick}>
        {formatStoreAddress(store.AddressDescription)}
      </button>
      {store.IsOpen ? (
        <StoreWaitTimes store={store} t={t} />
      ) : (
        <p className="warning">{t("customer:locations.store.store_closed")}</p>
      )}
    </div>
  );
};

export default StoreMapItem;

StoreMapItem.propTypes = {
  store: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  scrollToStore: PropTypes.func,
  closeInfoWindow: PropTypes.func,
};
