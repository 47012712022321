import { createSelector } from "reselect";

export const getAgent = ({ agent = {} } = {}) => agent;

export const getAgentId = createSelector(
  [getAgent],
  ({ agentId = "" }) => agentId
);

export default getAgentId;
