import React, { Component } from "react";

import PropTypes from "prop-types";

import { STATUS } from "constants/message";

import "components/Toaster/Toaster.css";

const STATUS_ICON = Object.freeze({
  negative: "error",
  positive: "thumb_up",
  attention: "warning",
});

const STATUS_TIMING = Object.freeze({
  negative: 10000,
  positive: 5000,
  attention: 5000,
});

class Toaster extends Component {
  constructor() {
    super();

    this.state = {
      hidden: true,
      hiding: null,
      mounting: null,
    };

    this.showMessage = this.showMessage.bind(this);
    this.hideMessage = this.hideMessage.bind(this);
  }

  componentDidMount() {
    //eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      hiding: setTimeout(
        this.hideMessage,
        this.props.duration || STATUS_TIMING[this.props.status]
      ),
      mounting: setTimeout(this.showMessage, 100),
    });
  }

  componentWillUnmount() {
    clearTimeout(this.state.mounting);
  }

  showMessage() {
    this.setState({
      hidden: false,
    });
  }

  hideMessage() {
    clearTimeout(this.state.hiding);

    this.setState({
      hidden: true,
      hiding: null,
      mounting: setTimeout(this.props.unrender, 100),
    });
  }

  render() {
    const { children, messageNumber, title, name, status } = this.props;
    const { hidden } = this.state;

    return (
      <div data-hook={`${name}-notify-container`}>
        <div
          className={`msg msg--toaster msg--notify status--${status} ${
            hidden ? "msg--notify--hidden" : ""
          }`}
          data-hook="notify"
          data-icon={STATUS_ICON[status]}
          data-quid={`toaster-message-${messageNumber}`}
        >
          <div className="msg__body">
            {title && <strong data-quid="title">{title}</strong>}
            <p data-quid="message">{children}</p>
          </div>

          <button
            className="msg__close btn btn--icon-only"
            data-hook="close-notify"
            data-icon="close"
            data-quid="close"
            onClick={this.hideMessage}
            type="button"
          />
        </div>
      </div>
    );
  }
}

Toaster.propTypes = {
  children: PropTypes.node,
  duration: PropTypes.number,
  messageNumber: PropTypes.number.isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  unrender: PropTypes.func,
};

Toaster.defaultProps = {
  children: null,
  duration: null,
  name: "msg",
  status: STATUS.SUCCESS,
  title: null,
  unrender: () => {},
};

export default Toaster;
