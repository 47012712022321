import React, { PureComponent } from "react";

import Pikaday from "pikaday";
import PropTypes from "prop-types";

import { DEFAULT_DATE_FORMAT } from "constants/market";

class DatePicker extends PureComponent {
  constructor(props) {
    super(props);

    this.setDatePickerRef = this.setDatePickerRef.bind(this);
  }

  componentDidMount() {
    const maxDate = new Date();
    //TODO: investigate 21?
    maxDate.setDate(maxDate.getDate() + 21);

    //TODO: investigate this eslint disable
    //eslint-disable-next-line no-new
    this.datepicker = new Pikaday({
      field: this.datepicker,
      format: this.props.dateFormat,
      onSelect: this.props.onSelect,
      theme: "triangle-theme",
      minDate: new Date(),
      maxDate,
      defaultDate: this.props.defaultDate || new Date(),
      setDefaultDate: true,
    });

    !this.props.defaultDate && this.props.onSelect(new Date());
  }

  componentWillUnmount() {
    this.datepicker.destroy();
  }

  setDatePickerRef(element) {
    this.datepicker = element;
  }

  render() {
    const { setDatePickerRef } = this;
    const { label, quidBase } = this.props;

    return (
      <div
        className="text-field text-field--date example-date-input"
        data-quid={quidBase}
      >
        <label
          data-quid={`${quidBase}-label`}
          htmlFor="date-picker-input"
          className="text-field__label"
        >
          {label}
        </label>

        <input
          className="text-field__input"
          data-quid={`${quidBase}-input`}
          type="text"
          id="date-picker-input"
          name="date-picker-input"
          ref={setDatePickerRef}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  defaultDate: PropTypes.instanceOf(Date),
  quidBase: PropTypes.string.isRequired,
};

DatePicker.defaultProps = {
  dateFormat: DEFAULT_DATE_FORMAT,
  label: "",
  onSelect: () => {},
  defaultDate: null,
};

export default DatePicker;
