import { css } from "@emotion/react";

export default css`
  .body__menu {
    order: -1;
  }

  .page__sidebar {
    order: 1;
  }
`;
