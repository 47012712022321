import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs/observable/of";
import { catchError, map, mergeMap, pluck } from "rxjs/operators";

import { getMenuState } from "ducks/menu/selectors/structuredMenu";
import { setMessage } from "ducks/message";
import { checkInvalidProducts } from "ducks/orderProduct";

import withValidation from "modules/elValidadore";
import { idValidators } from "modules/validators";

const SCOPE = "order-entry/menu/";

export const GET_MENU = `${SCOPE}GET_MENU`;
export const GET_MENU_ERROR = `${SCOPE}GET_MENU_ERROR`;
export const SET_MENU = `${SCOPE}SET_MENU`;

export const getMenu = withValidation(
  ({ defaultMenu = false, language = "en", storeId = null } = {}) => ({
    type: GET_MENU,
    defaultMenu,
    language,
    power: true,
    storeId,
    url: `/power/store/${storeId}/menu`,
  }),
  {
    storeId: idValidators,
  }
);

export function getMenuError(error) {
  return {
    type: GET_MENU_ERROR,
    error,
  };
}

export function setMenu({
  bases = {},
  categories = {},
  cookingInstructionGroups = {},
  cookingInstructionOptions = {},
  coupons = {},
  products = {},
  sides = {},
  sizes = {},
  toppings = {},
  variants = {},
  weightedOptions = {},
} = {}) {
  return {
    type: SET_MENU,
    bases,
    categories,
    cookingInstructionGroups,
    cookingInstructionOptions,
    coupons,
    products,
    sides,
    sizes,
    toppings,
    variants,
    weightedOptions,
  };
}

export const getMenuEpic = (action$, redux, { fetch }) =>
  action$.pipe(
    ofType(GET_MENU),
    mergeMap(({ defaultMenu, language, storeId, ...action }) =>
      fetch(
        Object.assign(action, {
          query: {
            lang: language,
            structured: true,
          },
        })
      ).pipe(
        pluck("response"),
        map((menu) => getMenuState(menu)),
        mergeMap((menuState) =>
          [
            setMenu(menuState),
            checkInvalidProducts({
              newMenuProductCodes: Object.keys(menuState.products),
            }),
          ].concat(
            defaultMenu
              ? []
              : setMessage({
                  message: `shared:messages.store_loaded`,
                  interpolation: { storeId },
                })
          )
        ),
        catchError((error) => of(getMenuError(error)))
      )
    )
  );

export const epics = combineEpics(getMenuEpic);
