/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { customerHeaderSection } from "./CustomerHeader.styles";

const Welcome = ({ t }) => {
  return (
    <div css={customerHeaderSection}>
      <p className="warningText">
        {t("customer:orders.order_status.start_order_warning")}
      </p>
    </div>
  );
};

export default Welcome;

Welcome.propTypes = {
  t: PropTypes.func,
};
