import {
  CARSIDE_FIELDS,
  PREFERRED_LOCATION_OPTIONS,
  VEHICLE_COLOR_OPTIONS,
  VEHICLE_MAKE_OPTIONS,
} from "constants/carside";

export const CarsideLookupTable = {
  [CARSIDE_FIELDS.VEHICLE_MAKE]: {
    label: "customer:profile.vehicle.make",
    options: VEHICLE_MAKE_OPTIONS,
  },
  [CARSIDE_FIELDS.VEHICLE_MODEL]: {
    label: "customer:profile.vehicle.model",
  },
  [CARSIDE_FIELDS.VEHICLE_COLOR]: {
    label: "customer:profile.vehicle.color",
    options: VEHICLE_COLOR_OPTIONS,
  },
  [CARSIDE_FIELDS.TEXT_PHONE_NUMBER]: {
    label: "customer:profile.vehicle.text_phone_number",
  },
  [CARSIDE_FIELDS.PREFERRED_LOCATION]: {
    label: "customer:profile.vehicle.preferred_location",
    options: PREFERRED_LOCATION_OPTIONS,
  },
  [CARSIDE_FIELDS.IS_TEXT_OPT_IN]: {
    label: "customer:profile.vehicle.opts_into_texts",
  },
};
